import React, { useContext, useEffect, useMemo, useState } from "react"
import ICampaign from "../../../interfaces/campaign"
import IOrganisation from "../../../interfaces/organisation"
import { IUser } from "../../../interfaces/user"
import FeeSection from "./FeeSection"
import LicenceBundleSection from "./LicenceBundleSection"
import OrganisationDetailsSection from "./OrganisationDetailsSection"
import "./organisationSettings.scss"
import StaffSection from "./StaffSection"
import { RouteComponentProps } from "react-router"
import { Button } from "@mui/material"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, ORGANISATIONS_API_PATHS } from "../../../common/ApiPaths"
import { useHistory } from "react-router-dom"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import useOrganisationValidation from "./useOrganisationValidation"
import { UserContext } from "../../../context/UserContext"
import { OrganisationContext, OrganisationDispatchContext } from "../../../context/OrganisationContext"
import IntegrationsSection from "./IntegrationsSection"
import { UserRole } from "../../../common/enums/UserRole"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import TechnicalConfigurationSection from "./TechnicalConfigurationSection"
import BrochureToolPurchasesSection from "./BrochureToolPurchasesSection"

interface InputProps {
	campaignList: ICampaign[]
	userList: IUser[]
	getUserList: () => void
}

const OrganisationSettings: React.FunctionComponent<InputProps & RouteComponentProps<any>> = (props: InputProps) => {
	const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "", errorDetails: "" })

	const organisationsApi = useFetch(API_PATHS.ORGANISATIONS)
	let history = useHistory()

	const organisation: IOrganisation = useContext(OrganisationContext)
	const setOrganisation = useContext(OrganisationDispatchContext)
	let organisationValidation = useOrganisationValidation(organisation)
	const isNewOrganisation = useMemo(() => !organisation._id || !organisation._id.length, [organisation])

	const { dBUser } = useContext(UserContext)
	const commonFunctions = useCommonFunctions()

	const createOrganisation = async (): Promise<void> => {
		if (organisationValidation.validateInputs()) {
			try {
				const data: IOrganisation = await organisationsApi.post(`${ORGANISATIONS_API_PATHS.CREATE_ORGANISATION}`, { ...organisation, userId: dBUser._id })
				setDialogProperties({
					isOpen: true,
					type: "SUCCESS",
					title: "Organisation Created.",
					message: "Your organisation has successfully been created.",
				})
				organisationValidation.clearValidation()
				history.push(`/organisations/${data.slug}`)
			} catch (err: any) {
				setDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: "Create failed.",
					message: `Your organisation failed with some error`,
					errorDetails: err.message,
				})
			}
		}
	}

	const updateOrganisation = async (): Promise<void> => {
		if (organisationValidation.validateInputs()) {
			try {
				const data: IOrganisation = await organisationsApi.put(
					`${ORGANISATIONS_API_PATHS.UPDATE_ORGANISATION.replace("{organisationId}", organisation._id)}`,
					{ ...organisation, userId: dBUser._id },

				)
				setDialogProperties({
					isOpen: true,
					type: "SUCCESS",
					title: "Organisation Updated.",
					message: "Your organisation has successfully been updated.",
				})
				setOrganisation(data)
				organisationValidation.clearValidation()
			} catch (err: any) {
				setDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: "Update failed.",
					message: `Your update failed with some error`,
					errorDetails: err.message,
				})
			}
		}
	}

	const handleChange = (name, value) => {
		setOrganisation({ ...organisation, [name]: value })
	}

	useEffect(() => {
		if (!organisation._id || !organisation._id.length) {
			setOrganisation({
				_id: "",
				name: "",
				slug: "",
				currency: "GBP",
				licenceFeeDiscountPercentage: 0,
				logoUrl: "",
				revenueFeePercentage: 1,
				stripeConnectedAccountId: "",
				licenceBundleList: [],
				contactEmail: "",
				contactPhone: "",
				isActive: true,
				integrationList: [],
				domain: "",
				sendgridTemplateId: "",
				sendgridVerifiedEmail: "",
			} as IOrganisation)
		} else {
			setOrganisation({ ...organisation })
		}
	}, [])

	return (
		<div className="organisation-settings-page">
			<OrganisationDetailsSection
				validationObject={organisationValidation.validationObject}
				handleChange={handleChange}
			/>
			{dBUser.role === UserRole.SUPER_ADMIN && (
				<>
					<TechnicalConfigurationSection
						validationObject={organisationValidation.validationObject}
						handleChange={handleChange}
						isNewOrganisation={isNewOrganisation}
					/>
					<FeeSection handleChange={handleChange} />
				</>
			)}

			{!isNewOrganisation && (
				<>
					<LicenceBundleSection campaignList={props.campaignList ? props.campaignList : []} setDialogProperties={setDialogProperties} />
					<BrochureToolPurchasesSection campaignList={props.campaignList ? props.campaignList : []} />
					<StaffSection userList={props.userList ? props.userList : []} getUserList={props.getUserList} isCampaignView={false} />
					<IntegrationsSection integrationList={organisation._id && organisation.integrationList ? organisation.integrationList : []} />
				</>
			)}

			{dBUser.role !== UserRole.OPERATOR && (
				<div className="fixed-action-section">
					<div></div>
					<div className="save-button-wrapper">
						{!organisationValidation.isValidationPassed && (
							<span className="validation-text">Errors highlighted in form - please resolve before saving.</span>
						)}
						{dBUser.role === UserRole.SUPER_ADMIN ? (
							<>
								{isNewOrganisation ? (
									<Button className="gg-button" onClick={createOrganisation}>
										Create Organisation
									</Button>
								) : (
									<Button className="gg-button" onClick={updateOrganisation}>
										Save Organisation
									</Button>
								)}
							</>
						) : (
							<Button className="gg-button" onClick={updateOrganisation}>
								Save Organisation
							</Button>
						)}
					</div>
				</div>
			)}

			<ConfirmationDialog
				handleClose={() => setDialogProperties({ isOpen: false })}
				isDialogOpen={dialogProperties.isOpen}
				message={dialogProperties.message}
				errorDetails={dialogProperties.errorDetails}
				title={dialogProperties.title}
				type={dialogProperties.type}
				label={dialogProperties.label}
				primaryButton={
					<Button
						onClick={() => setDialogProperties({ isOpen: false })}
						className="gg-button"
						style={commonFunctions.determineButtonColor(dialogProperties.type)}
					>
						Ok
					</Button>
				}
			/>
		</div>
	)
}

export default OrganisationSettings
