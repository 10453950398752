import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import CustomisableButton from './CustomisableButton'
import { string } from 'zod'

export default Node.create({
    name: 'customisableButtonReactComponent',

    group: 'block',

    atom: true,

    draggable: true,

    addAttributes() {
        return {
			backgroundColor: string,
			color: string,
			text: string,
			link: string,
			border: string,
			borderRadius: string,
			padding: string,
			fontSize: string,
			fontWeight: string,
			fontFamily: string,
            alignment: string,
            width: string,
            isOpenInNewTab: string,
        }
    },

    parseHTML() {
        return [
            {
                tag: 'customisable-button'
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['customisable-button', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(CustomisableButton)
    },
})