import React from 'react'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import { IWinner } from '../../../interfaces/winner'
import './winnerDetailsSection.scss'

interface InputProps {
	winner: IWinner
}

const WinnerDetailsSection = (props: InputProps) => {
	const commonFunctions = useCommonFunctions()

	const determineIfAllItemsArePaid = (): boolean => {
		let allItemsArePaid = true
		props.winner.winnerItems.forEach((item) => {
			if (!item.bidAndLotList.every((bidAndLot) => bidAndLot.winningBid.paymentRefId)) {
				allItemsArePaid = false
			}
		})
		return allItemsArePaid
	}

	return (
		<div className='winner-details-section'>
			<table>
				<tbody>
					<tr>
						<td className='label'>Name:</td>
						<td className='value'>{props.winner.bidderName ? props.winner.bidderName : `${commonFunctions.capitalize(props.winner.campaignUser.firstName)} ${commonFunctions.capitalize(props.winner.campaignUser.surname)}`}</td>
					</tr>
					<tr>
						<td className='label'>Email:</td>
						<td className='value'>{props.winner.campaignUser ? props.winner.campaignUser.email : props.winner.bidderId}</td>
					</tr>
					<tr>
						<td className='label'>Phone:</td>
						<td className='value'>{props.winner.campaignUser.phone}</td>
					</tr>
					{props.winner.stripePaymentLink && props.winner.stripePaymentLink.paymentLinkId &&
						<tr>
							<td className='label'>Stripe Payment Link:</td>
							<td className='value'>
								{determineIfAllItemsArePaid() ?
									<span>
										<span style={{ textDecoration: "line-through" }}>{props.winner.stripePaymentLink.paymentLinkUrl}</span>
										{` (Paid)`}
									</span>
									:
									<a href={props.winner.stripePaymentLink.paymentLinkUrl} target="_blank" rel="noreferrer">{props.winner.stripePaymentLink.paymentLinkUrl}</a>
								}
							</td>
						</tr>
					}

				</tbody>
			</table>
		</div>
	)
}

export default WinnerDetailsSection