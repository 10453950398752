import './lotReport.scss'
import React, { useContext, useEffect, useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent, Button } from "@mui/material"
import useExcel from '../../../hooks/useExcel'
import ICampaign from '../../../interfaces/campaign'
import { CampaignContext, CampaignLoadingContext } from '../../../context/CampaignContext'
import IGetWinnerResponse, { IWinnerBidAndLot } from '../../../interfaces/winningBidResponse'
import { API_PATHS, BID_API_PATHS } from '../../../common/ApiPaths'
import IBid from '../../../interfaces/bid'
import { IUser } from '../../../interfaces/user'
import ILot from '../../../interfaces/lot'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import { useErrorBoundary } from 'react-error-boundary'
import SummaryFigures from '../shared/SummaryFiguresSection/SummaryFigures'
import { ICampaignUser } from '../../../interfaces/campaignUser'

interface IBidForLotReport {
    bid?: IBid
    lot: ILot
    campaignUser?: IUser | ICampaignUser
}

const LotReport = ({ match, ...props }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    const [winnerList, setWinnerList] = useState<IGetWinnerResponse[]>([])
    const [winningBidList, setWinningBidList] = useState<IBidForLotReport[]>([] as IBidForLotReport[])

    const campaign: ICampaign = useContext(CampaignContext)
    const campaignLoading = useContext(CampaignLoadingContext)
    const bidsApi = useFetch(API_PATHS.BIDS)

    const excel = useExcel();
    const commonFunctions = useCommonFunctions()
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (!campaignLoading && !(campaign && campaign.slug)) {
            showBoundary('Error while fetching campaign')
            setLoading(false)
        }
        else if (campaign.slug) {
            setError('')
            setLoading(false)
            getWinnerList()
        }
    }, [campaign, campaignLoading])

    const getWinnerList = async (): Promise<void> => {
        setLoading(true)
        try {
            let bids: IBidForLotReport[] = []
            let winnerList: IGetWinnerResponse[] = await bidsApi.get(`${BID_API_PATHS.GET_WINNING_BIDS.replace('{campaignId}', campaign._id)}?setWinners=false`)

            winnerList.forEach((winner: IGetWinnerResponse) => {
                winner.bidAndLotList = winner.bidAndLotList.sort((a, b) => a.winningLot.orderNumber - b.winningLot.orderNumber)
                winner.bidAndLotList.forEach(bidAndLot => {
                    if (bidAndLot.winningBid.bidList) {
                        bidAndLot.winningBid.bidList.forEach(bid => {
                            bids.push({
                                bid: bid,
                                lot: bidAndLot.winningLot,
                                campaignUser: winner.winningUser
                            })
                        })
                    }
                    else {
                        bids.push({
                            bid: undefined,
                            lot: bidAndLot.winningLot,
                            campaignUser: undefined
                        })
                    }
                })
            });
            setWinningBidList(bids.sort((a, b) => a.lot.orderNumber - b.lot.orderNumber))
            setWinnerList(winnerList)
            props.setPadding()
            setLoading(false)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const getParentTitle = (parentLotId: string): string => {
        let title = ''
        winnerList.forEach((winner: IGetWinnerResponse) => {
            winner.bidAndLotList.forEach(bidAndLot => {
                if (bidAndLot.winningLot._id === parentLotId) {
                    title = bidAndLot.winningLot.title
                }
            })
        })
        return title
    }

    const calculateTotalCostPrice = (): number => {
        let totalCostPrice = 0;
        winnerList.forEach(w => {
            w.bidAndLotList.forEach(winnerItem => {
                if (winnerItem.winningBid.totalValue > 0) {
                    let count = winnerItem.winningBid.bidList && winnerItem.winningBid.bidList.length > 0 ? winnerItem.winningBid.bidList.map((x) => x.count).reduce((a, b) => a + b) : 0
                    totalCostPrice += (winnerItem.winningLot.costPrice * count)
                }
            })
        });

        return totalCostPrice
    }

    const calculateTotalProfit = (): number => {
        let totalProfit = 0
        winnerList.forEach(w => {
            w.bidAndLotList.forEach(winnerItem => {
                totalProfit += winnerItem.winningBid.totalValue ? (winnerItem.winningBid.totalValue - (winnerItem.winningLot.costPrice * winnerItem.winningBid.totalCount)) : 0
            });
        });
        return totalProfit
    }

    const calculateTotalWinningBids = (): number => {
        let totalWinningBids = 0
        winnerList.forEach(w => {
            w.bidAndLotList.forEach(winnerItem => {
                if (winnerItem.winningBid.totalValue) {
                    totalWinningBids += winnerItem.winningBid.totalValue
                }
            })
        });
        return totalWinningBids
    }

    const renderBidRow = (winnerItem: IBidForLotReport, winnerItemIndex: number, bidCount: number) => {
        if (winnerItem.lot.type === "PARENT") {
            return null
        }
        let rows = [] as JSX.Element[];
        if (bidCount === 0) {
            rows.push(
                <TableRow key={winnerItemIndex + 'no-bid'}>
                    <TableCell>{winnerItem.lot.orderNumber}</TableCell>
                    <TableCell>{winnerItem.lot.parentLotId ? `${getParentTitle(winnerItem.lot.parentLotId)} - ${winnerItem.lot.title}` : winnerItem.lot.title}</TableCell>
                    <TableCell>{new Intl.NumberFormat('en-US', { style: 'currency', currency: campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(winnerItem.lot.reservePrice)}
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell >-</TableCell>
                    <TableCell >-</TableCell>
                </TableRow>
            )
        }
        for (let i = 0; i < bidCount; i++) {
            rows.push(
                <TableRow key={winnerItemIndex + i}>
                    <TableCell>{winnerItem.lot.orderNumber}</TableCell>
                    <TableCell>{winnerItem.lot.parentLotId ? `${getParentTitle(winnerItem.lot.parentLotId)} - ${winnerItem.lot.title}` : winnerItem.lot.title}</TableCell>
                    <TableCell>{new Intl.NumberFormat('en-US', { style: 'currency', currency: campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(winnerItem.lot.reservePrice)}
                    </TableCell>
                    <TableCell>
                        {winnerItem.campaignUser ? (winnerItem.campaignUser.firstName ? `${commonFunctions.capitalize(winnerItem.campaignUser.firstName)} ${commonFunctions.capitalize(winnerItem.campaignUser.surname)}` : winnerItem.campaignUser.email) : winnerItem.bid?.userId}
                    </TableCell>
                    <TableCell>
                        {winnerItem.bid?.value &&
                            // new Intl.NumberFormat('en-US', { style: 'currency', currency: campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(winnerItem.bid?.value)
                            commonFunctions.getDisplayedBid(winnerItem.bid, campaign.currency)
                        }
                    </TableCell>
                    <TableCell >
                        {winnerItem.bid?.value &&
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(winnerItem.lot.costPrice)
                        }
                    </TableCell>
                    <TableCell >
                        {winnerItem.bid?.value &&
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format((winnerItem.bid.value - winnerItem.lot.costPrice))
                        }
                    </TableCell>
                </TableRow>
            )
        }
        return rows
    }

    let currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: campaign.currency ?? "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div className="lot-report-page">
            {campaign.currency && winnerList.length > 0 ?
                <>
                    <SummaryFigures nameValuePairList={[
                        { name: "Total Winning Bid Value", value: `${currencyFormatter.format(calculateTotalWinningBids() || 0)}` },
                        { name: "Total Cost", value: `${currencyFormatter.format(calculateTotalCostPrice() || 0)}` },
                        { name: "Total Profit", value: `${currencyFormatter.format(calculateTotalProfit() || 0)}` }
                    ]} />

                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Lot Number</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell" style={{ minWidth: 100 }}>
                                                <span>Lot Name</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Reserve</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Winning Bidder</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Winning Bid</span>
                                            </div>
                                        </TableCell>
                                        <TableCell >
                                            <div className="table-header-cell">
                                                <span>Cost Price</span>
                                            </div>
                                        </TableCell>
                                        <TableCell >
                                            <div className="table-header-cell">
                                                <span>Profit</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {winningBidList.map((winningItem: IBidForLotReport, wIndex: number) => (
                                        winningItem.bid ?
                                            renderBidRow(winningItem, wIndex, winningItem.bid.count)
                                            :
                                            renderBidRow(winningItem, wIndex, 0)

                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card >
                </>
                : <Card><CardContent><div className="no-entries">No lots configured</div></CardContent></Card>}

            {winnerList.length > 0 &&
                <div className="fixed-action-section">
                    <div></div>
                    <Button onClick={() => excel.generateLotReport(campaign, winnerList)} className="gg-button">Download Lot Report</Button>
                </div>
            }
        </div >
    )
}

export default LotReport