import React from 'react';
import './layoutAnonymous.scss';

const LayoutAnonymous = props => {
    return (
        <div className="layout-anonymous">
            <div className="full-height-content">
                {props.children}
            </div>
        </div>
    )
}

export default LayoutAnonymous