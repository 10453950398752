import { Typography, Switch, FormControlLabel } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import './ggSwitch.scss'

interface InputProps {
    id: string
    name: string
    defaultValue: boolean
    label: string
    helperText: string
    onChange: (event: any, value: any) => void
}

const GGSwitch = (props: InputProps) => {

    const isCancelled = useRef(false)

    useEffect(() => {

        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='gg-switch'>
            <FormControlLabel
                control={
                    <Switch
                        checked={props.defaultValue}
                        onChange={props.onChange}
                        name={props.name}
                    />
                }
                label={props.label}
            />
            <Typography>
                {props.helperText}
            </Typography>
        </div>
    )
}

export default GGSwitch