import React from "react";

import "./customTabs.scss";
import { Box, Tabs } from "@mui/material";

const CustomTabs = ({ route, tabValue, children, tabChangeHandler }) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={tabValue}
        onChange={tabChangeHandler}
        aria-label="basic tabs example"
      >
        {children}
      </Tabs>
    </Box>
  );
};

export default CustomTabs;
