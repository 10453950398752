import './lotDescriptionSection.scss'
import React from 'react'
import { Paper } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Tiptap from '../../shared/tiptap/Tiptap'


interface InputProps {
    object: any
    setObject: (object: any) => void,
    isReadOnly: boolean
    objectName: string
}

const LotDescriptionSection = (props: InputProps) => {
    return (
        <div className="lot-description-section">
            <h2 className="subtitle">{props.objectName} Description.</h2>
            <span className="description">Set the detailed description of the {props.objectName.toLowerCase()}.</span>
            <Paper className="input-wrapper">
                <div className="tiptap">
                    <Tiptap
                        value={props.object.description || ""}
                        onChange={(value) => props.setObject({ ...props.object, description: value, isTipTapDescriptionText: true })}
                        backgroundColor={"#FFFFFF"}
                    />
                </div>
            </Paper>
        </div>
    )
}

export default LotDescriptionSection