import { Paper, TextField } from "@mui/material"
import React, { useContext } from "react"
import { OrganisationContext } from "../../../context/OrganisationContext"
import IOrganisation from "../../../interfaces/organisation"

interface InputProps{
	handleChange: (name: string, value: string | number) => void
}

const FeeSection = (props: InputProps) => {
	const organisation: IOrganisation = useContext(OrganisationContext)

	return (
		<section className="fee-section">
			<h2 className="subtitle">Fees</h2>
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="revenueFeePercentage"
						name="revenueFeePercentage"
						label="Revenue Fee %"
						variant="outlined"
						type="number"
						onWheel={e => (e.target as any).blur()}
						value={organisation.revenueFeePercentage || 0}
						onChange={event => {
							event.target.value = Number(event.target.value).toString()
							props.handleChange(event.target.name, Number(event.target.value))
						}}
						required
						InputProps={{ inputProps: { min: 0 } }}
						// error={!getValidation("availabilityCount").isValid}
						// helperText={
						// 	getValidation("availabilityCount").isValid
						// 		? "The number of the lot that are available to be sold."
						// 		: getValidation("availabilityCount").validationMessage
						// }
						// disabled={props.isAuctionClosed}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="licenceFeeDiscountPercentage"
						name="licenceFeeDiscountPercentage"
						label="Licence Fee Discount %"
						variant="outlined"
						type="number"
						onWheel={e => (e.target as any).blur()}
						value={organisation.licenceFeeDiscountPercentage || 0}
						onChange={event => {
							event.target.value = Number(event.target.value).toString()
							props.handleChange(event.target.name, Number(event.target.value))
						}}
						required
						InputProps={{ inputProps: { min: 0 } }}
					/>
				</div>
			</Paper>
		</section>
	)
}

export default FeeSection