import { ToggleButton, Tooltip } from "@mui/material"
import { type Editor } from "@tiptap/react"
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import ColorButton from "./ColorButton";
import './toolbar.scss'
import FontSizeComboBox from "./FontSizeComboBox";
import FontStyleComboBox from "./FontStyleComboBox";
import InsertButtonButton from "./InsertButtonButton";
import FormatClearIcon from '@mui/icons-material/FormatClear';
import LinkButton from "./LinkButton";
import LinkOffIcon from '@mui/icons-material/LinkOff';



const Toolbar = ({ editor }: { editor: Editor | null}) => {

    if (!editor) { return <></> }

    return (
        <div className="format-toolbar">
            <Tooltip title="Bold" enterDelay={600}>
                <ToggleButton value="" selected={editor.isActive("bold")} aria-label="bold" onClick={() => editor!.chain().focus().toggleBold().run()}>
                    <FormatBoldIcon />
                </ToggleButton>
            </Tooltip>
            <Tooltip title="Italic" enterDelay={600}>
                <ToggleButton value="" selected={editor.isActive("italic")} aria-label="italic" onClick={() => editor!.chain().focus().toggleItalic().run()}>
                    <FormatItalicIcon />
                </ToggleButton>
            </Tooltip>
            <Tooltip title="Underline" enterDelay={600}>
                <ToggleButton value="" selected={editor.isActive("underline")} aria-label="underlined" onClick={() => editor!.chain().focus().toggleUnderline().run()}>
                    <FormatUnderlinedIcon />
                </ToggleButton>
            </Tooltip>
            <Tooltip title="Left Align" enterDelay={600}>
                <ToggleButton value="" selected={editor.isActive({ textAlign: 'left' })} aria-label="left" onClick={() => editor!.chain().focus().setTextAlign('left').run()}>
                    <FormatAlignLeftIcon />
                </ToggleButton>
            </Tooltip>
            <Tooltip title="Center Align" enterDelay={600}>
                <ToggleButton value="" selected={editor.isActive({ textAlign: 'center' })} aria-label="center" onClick={() => editor!.chain().focus().setTextAlign('center').run()}>
                    <FormatAlignCenterIcon />
                </ToggleButton>
            </Tooltip>
            <Tooltip title="Right Align" enterDelay={600}>
                <ToggleButton value="" selected={editor.isActive({ textAlign: 'right' })} aria-label="right" onClick={() => editor!.chain().focus().setTextAlign('right').run()}>
                    <FormatAlignRightIcon />
                </ToggleButton>
            </Tooltip>
            <Tooltip title="Clear formatting" enterDelay={600}>
                <ToggleButton value="" aria-label="clear" onClick={() => editor!.chain().focus().unsetAllMarks().clearNodes().run()}>
                    <FormatClearIcon />
                </ToggleButton>
            </Tooltip>
            <ColorButton editor={editor} />
            <FontSizeComboBox editor={editor}
                activeFontSize={editor.getAttributes('textStyle').fontSize}
            />
            <FontStyleComboBox editor={editor}
                activeFontStyle={editor.getAttributes('textStyle').fontFamily}
            />
            <InsertButtonButton editor={editor} />
            <LinkButton editor={editor} />
            <Tooltip title="Remove Link" enterDelay={600}>
                <ToggleButton value="" aria-label="Remove link" onClick={() => editor!.chain().focus().unsetLink().run()}>
                    <LinkOffIcon />
                </ToggleButton>
            </Tooltip>
        </div>
    )
}

export default Toolbar