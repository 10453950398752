import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import React, { useContext, useState } from "react"
import IOrganisation from "../../../interfaces/organisation"
import EditIcon from "@mui/icons-material/Edit"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, ORGANISATIONS_API_PATHS } from "../../../common/ApiPaths"
import { OrganisationContext, OrganisationDispatchContext } from "../../../context/OrganisationContext"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import { IIntegration } from "../../../interfaces/integration"
import IntegrationDialog from "./IntegrationDialog"
import RemoveIntegrationDialog from "./RemoveIntegrationDialog"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import { IUser } from "../../../interfaces/user"
import { UserContext } from "../../../context/UserContext"
import { UserRole } from "../../../common/enums/UserRole"

interface InputProps {
	integrationList: IIntegration[]
}

interface IIntegtaionDialogProperties {
	isOpen: boolean
	integration: IIntegration
}

interface RemoveIntegrationDialogProperties {
	isOpen: boolean
	integrationId?: string
}

const IntegrationsSection = (props: InputProps) => {
	const [integrationDialogProperties, setIntegrationDialogProperties] = useState<IIntegtaionDialogProperties>({ isOpen: false, integration: {} } as IIntegtaionDialogProperties)
	const [removeIntegrationDialogProperties, setRemoveIntegrationDialogProperties] = useState<RemoveIntegrationDialogProperties>({ isOpen: false })
	const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "", errorDetails: "" })
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);

	const organisationApi = useFetch(API_PATHS.ORGANISATIONS)
	const { dBUser } = useContext(UserContext)
	const organisation: IOrganisation = useContext(OrganisationContext)
	const setOrganisation = useContext(OrganisationDispatchContext)
	const commonFunctions = useCommonFunctions()

	const openRemoveIntegrationDialog = integrationId => {
		setRemoveIntegrationDialogProperties({ isOpen: true, integrationId: integrationId })
	}

	const closeRemoveIntegrationDialog = (integrationId: string = '') => {
		setRemoveIntegrationDialogProperties({ isOpen: false })
		if (integrationId && integrationId.length) {
			removeIntegration(integrationId)
		}
	}


	const removeIntegration = async (integrationId: string) => {
		try {
			const data: IOrganisation = await organisationApi.put(`${ORGANISATIONS_API_PATHS.UPDATE_ORGANISATION.replace('{organisationId}', organisation._id)}`, { ...organisation, integrationList: props.integrationList.filter(x => x.id.toString() !== integrationId) })
			setOrganisation(data)
		}
		catch (err: any) {
			console.log(err)
		}
	}

	const addEditIntegration = async (integration: IIntegration) => {
		try {
			if (integration.id) {
				const data: IOrganisation = await organisationApi.put(`${ORGANISATIONS_API_PATHS.UPDATE_ORGANISATION.replace('{organisationId}', organisation._id)}`, { ...organisation, integrationList: props.integrationList.map(x => x.id.toString() === integration.id.toString() ? integration : x) })
				setOrganisation(data)
			}
			else {
				var ObjectID = require("bson-objectid");
				const integrationInfo: IIntegration = {
					...integration,
					id: ObjectID(),
				}
				let integrations = [...props.integrationList, integrationInfo]
				const data: IOrganisation = await organisationApi.put(`${ORGANISATIONS_API_PATHS.UPDATE_ORGANISATION.replace('{organisationId}', organisation._id)}`, { ...organisation, integrationList: integrations })
				setOrganisation(data)
			}
		}
		catch (err: any) {
			setDialogProperties({ type: "ERROR", title: "Adding/Editing Integration failed", message: `Adding/Editing integration failed with some error`, errorDetails: err.message })
			setIsConfirmationDialogOpen(true)
		}
	}

	return (
		<section className="integration-section">
			<h2>Integrations</h2>
			<Paper className="input-wrapper">
				{props.integrationList && props.integrationList.length > 0 ? (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<div className="table-header-cell">
										<span>Name</span>
									</div>
								</TableCell>
								<TableCell>
									<div className="table-header-cell">
										<span>Provider</span>
									</div>
								</TableCell>
								<TableCell className="hide-on-mobile">
									<div className="table-header-cell">
										<span>API Key</span>
									</div>
								</TableCell>
								<TableCell>
									<div className="table-header-cell"></div>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.integrationList.map((integration, index) => {
								return (
									<TableRow key={index}>
										<TableCell>{integration.name}</TableCell>
										<TableCell>{commonFunctions.enumToText(integration.provider)}</TableCell>
										<TableCell className="hide-on-mobile">{integration.apiKey}</TableCell>
										{dBUser.role !== UserRole.OPERATOR && (
											<TableCell>
												<div className="action-cell">
													<div onClick={() => setIntegrationDialogProperties({ isOpen: true, integration: integration })}>
														<Tooltip title="Edit Integration" enterDelay={500}>
															<EditIcon />
														</Tooltip>
													</div>
													<div onClick={() => openRemoveIntegrationDialog(integration.id)}>
														<Tooltip title="Remove Integration" enterDelay={500}>
															<HighlightOffIcon />
														</Tooltip>
													</div>
												</div>
											</TableCell>
										)}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				) : (
					<div className="no-entries">No integration configured.</div>
				)}

				{dBUser.role !== UserRole.OPERATOR && (
					<div className="button-wrapper">
						<Button className="gg-button" onClick={() => setIntegrationDialogProperties({ isOpen: true, integration: {} as IIntegration })}>
							Add Integration
						</Button>
					</div>
				)}
			</Paper>

			{dBUser.role !== UserRole.OPERATOR && (
				<>
					<IntegrationDialog
						handleClose={() => setIntegrationDialogProperties({ isOpen: false, integration: {} as IIntegration })}
						isDialogOpen={integrationDialogProperties.isOpen}
						integration={integrationDialogProperties.integration}
						handleSave={addEditIntegration}
						organisationId={organisation._id}
					/>
					<RemoveIntegrationDialog
						handleClose={closeRemoveIntegrationDialog}
						isDialogOpen={removeIntegrationDialogProperties.isOpen}
						integrationId={removeIntegrationDialogProperties.integrationId || ''}
					/>

					<ConfirmationDialog
						handleClose={() => setIsConfirmationDialogOpen(false)}
						isDialogOpen={isConfirmationDialogOpen}
						message={dialogProperties.message}
						errorDetails={dialogProperties.errorDetails}
						title={dialogProperties.title}
						type={dialogProperties.type}
						label={dialogProperties.label}
						primaryButton={<Button onClick={() => setIsConfirmationDialogOpen(false)} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
							Ok
						</Button>}
					/>
				</>
			)}
		</section>
	)
}

export default IntegrationsSection
