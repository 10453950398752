import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, FormHelperText, InputAdornment, InputLabel, ListSubheader, MenuItem, Paper, Select, TextField } from '@mui/material'
import ICampaign from '../../../../interfaces/campaign'
import useCommonFunctions from '../../../../hooks/useCommonFunctions';
import ReactCountryFlag from "react-country-flag"
import SearchIcon from "@mui/icons-material/Search";

interface InputProps {
    campaign: ICampaign
    setCampaign: (campaign: ICampaign) => void
}

const LocalisationSettingsSection = (props: InputProps) => {
    const [selectedCountryCode, setSelectedCountryCode] = useState(props.campaign.defaultPhone || "GB");
    const [selectedCurrency, setSelectedCurrency] = useState(props.campaign.currency || "GBP");

    const commonFunctions = useCommonFunctions();
    const allowedCountries = commonFunctions.getAllowedCountries();
    const allCountriesData = commonFunctions.getAllCountriesData();

    const [searchText, setSearchText] = useState("");

    const displayedCountries = useMemo(() => {
        let lowercaseSearchText = searchText.toLowerCase()
        return allCountriesData.filter((option) => option.name.toLowerCase().includes(lowercaseSearchText) || option.isd_code.includes(lowercaseSearchText) || `${option.name} (${option.isd_code})`.toLowerCase().includes(lowercaseSearchText))
    }, [searchText]
    );

    useEffect(() => {
        props.setCampaign({ ...props.campaign, defaultPhone: selectedCountryCode })
    }, [selectedCountryCode])

    useEffect(() => {
        props.setCampaign({ ...props.campaign, currency: selectedCurrency })
    }, [selectedCurrency])

    const renderCurrencyDropdown = () => {
        let finalCurrencyCountries = allowedCountries.filter(x => x.currencyCode !== 'EUR');
        finalCurrencyCountries.push(
            {
                name: 'European Union',
                countryCode: 'EU',
                currencyCode: 'EUR',
                isd_code: '',
                currency: 'European Euro'
            }
        );
        finalCurrencyCountries = finalCurrencyCountries.sort((a, b) => (a.currencyCode > b.currencyCode) ? 1 : -1);
        return (
            finalCurrencyCountries.map((country, index) => (
                <MenuItem value={country.currencyCode} key={index}>
                    <ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: '8px' }} />
                    {` ${country.currency} (${country.currencyCode})`}
                </MenuItem>
            ))
        );
    }

    return (
        <div className="localization-details-section">
            <h2 className="subtitle">Localisation Settings.</h2>
            <span className="description">The localisation details of the campaign.</span>
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    {/* <ReactFlagsSelect
                        selected={selectedCountry}
                        onSelect={(code) => setSelectedCountry(code)}
                        searchable
                        className=''
                        showSelectedLabel
                    /> */}
                    <FormControl variant="outlined">
                        <InputLabel id="default-country-phone-label">Default Phone Country Code</InputLabel>
                        <Select
                            labelId="default-country-phone-label"
                            id="defaultPhone"
                            name="defaultPhone"
                            value={selectedCountryCode}
                            onChange={(event) => setSelectedCountryCode(event.target.value)}
                            label="Default Phone Country Code"
                            MenuProps={{ autoFocus: false }}
                            onClose={() => setSearchText("")}
                        >
                            <ListSubheader>
                                <TextField
                                    size="small"
                                    autoFocus
                                    placeholder="Search..."
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </ListSubheader>
                            {displayedCountries.map((country, index) => (
                                <MenuItem value={country.countryCode} key={index}>
                                    <ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: '8px' }} />
                                    {` ${country.name} (${country.isd_code})`}
                                </MenuItem>
                            ))}

                        </Select>
                        <FormHelperText>The default telephone country code set for bidders while making bids.</FormHelperText>
                    </FormControl>
                </div>


                <div className="field-wrapper">
                    {/* <SelectCurrency
                        value={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                        style={{ width: '100%' }}   
                    /> */}
                    <FormControl variant="outlined">
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            labelId="currency-label"
                            id="currency"
                            name="currency"
                            value={selectedCurrency}
                            onChange={(event) => setSelectedCurrency(event.target.value)}
                            label="currency"

                        >
                            {renderCurrencyDropdown()}
                        </Select>
                        <FormHelperText>The currency set for bidders while making bids.</FormHelperText>
                    </FormControl>
                </div>
            </Paper>
        </div>
    )
}

export default LocalisationSettingsSection