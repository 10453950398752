import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import ILot from '../../../interfaces/lot'
import { useContext, useEffect, useState } from 'react'
import { ICustomPage } from '../../../interfaces/customPage'
import useFetch from '../../../hooks/useFetch'
import { CUSTOM_PAGES_API_PATH, CUSTOM_PAGES_PATHS } from '../../../common/CustomPageApiPaths'
import ICampaign from '../../../interfaces/campaign'
import { CampaignContext } from '../../../context/CampaignContext'

interface InputProps {
    existingCustomPageId?: string
    handleChange: (event: any) => void
    isNewLot: boolean
}

const CustomPageSelect = (props: InputProps) => {

    const [customPageList, setCustomPageList] = useState<ICustomPage[]>([])

    const campaign: ICampaign = useContext(CampaignContext)

    const customPageApi = useFetch(CUSTOM_PAGES_API_PATH);

    const getCustomPages = async () => {
        try {
            const customPageList: ICustomPage[] = await customPageApi.get(CUSTOM_PAGES_PATHS.GET_CUSTOM_PAGES.replace("{campaignId}", campaign._id))

            const lotPage: ICustomPage = {
                orderNumber: campaign.lotPageOrderNumber,
                label: "Lot List",
                path: "lots",
                isHidden: campaign.isLotsPageHidden,
                ctaText: campaign.buttonText,
                _id: "lot-page",
                campaignId: campaign._id,
                isLotpage: true
            }

            if (customPageList.length > 0) {
                customPageList.splice(lotPage.orderNumber - 1, 0, lotPage)
                setCustomPageList(customPageList)
            } else {
                setCustomPageList([lotPage])
            }

            //check for deleted custom pages adn default them to lot page
            // if (props.lot && props.setLot && customPageList.find((customPage: ICustomPage) => customPage._id === props.lot!.customPageId) === undefined) {
            //     props.setLot!({ ...props.lot, customPageId: "" })
            // }

        } catch (err: any) {
            console.log(err.message)
        }
    }

    useEffect(() => {
        if (campaign._id) {
            getCustomPages()
        }
    }, [campaign])

    if (customPageList.length === 0 ) {
        return (<span>{JSON.stringify(customPageList)}</span>)
        // return null
    }

    return (
        <FormControl
            variant="outlined"
            required
            fullWidth
        >
            <InputLabel id="custom-page-label">Page</InputLabel>
            <Select
                labelId="custom-page-label"
                id="custom-page"
                defaultValue={props.isNewLot ? "" : props.existingCustomPageId ? props.existingCustomPageId : "lot-page"}
                onChange={props.handleChange}
                label="Page *"
            >
                {customPageList.sort((a, b) => a.orderNumber - b.orderNumber).map((customPage: ICustomPage, index: number) => {
                    return (
                        <MenuItem key={index} value={customPage._id}>{customPage.label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default CustomPageSelect