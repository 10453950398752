import React, { useState } from "react"
import { Dialog, DialogContent } from "@mui/material"
import '../admin/dialog.scss'
import LoginSignupTabs from "./LoginSignupTabs"
import './loginSignup.scss'
import '../admin/login/login.scss'

interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
}

const LoginDialog = (props: InputProps) => {
    const [isForgotPasswordView, setIsForgotPasswordView] = useState<boolean>(false)

    const handleClose = () => {
        props.handleClose()
        localStorage.setItem('redirectUri', '')
        setIsForgotPasswordView(false)
    }

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={handleClose}
                className="login-dialog login"
            >
                <DialogContent>
                    <LoginSignupTabs
                        isForgotPasswordView={isForgotPasswordView}
                        setIsForgotPasswordView={setIsForgotPasswordView}
                        isAdminView={false}
                        handleClose={handleClose} 
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default LoginDialog