import React, { useEffect, useRef } from 'react'
import './youTubeThumbnail.scss'
import YouTubeLogo from '../../images/youtube-play-icon.png'

interface InputProps {
    className: string
    imageSrc: string
    isInSwiper: boolean
    passThroughRef?: any
    onLoadHandler?: () => void
}

const YouTubeThumbnail = (props: InputProps) => {

    const isCancelled = useRef(false)

    useEffect(() => {

        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`${props.className} youtube-thumbnail-wrapper`} ref={props.passThroughRef}>
        {/* <div className='youtube-thumbnail-wrapper'> */}
            <img src={YouTubeLogo} alt="youtube-icon" className='youtube-icon'/>
            {props.isInSwiper? 
            <img key={props.imageSrc} data-src={props.imageSrc} alt={`thumbnail`} className="swiper-lazy" />
            :    
            <img key={props.imageSrc} src={props.imageSrc} alt={`thumbnail`} className="non-swiper-image" onLoad={props.onLoadHandler && props.onLoadHandler} />
        }
        </div>
    )
}

export default YouTubeThumbnail