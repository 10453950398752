import './socialLinkSection.scss'
import React, { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import SocialLinkDialog from '../dialogs/SocialLinkDialog'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactComponent as FacebookLogo } from '../../../../images/facebook_icon.svg'
import { ReactComponent as InstagramLogo } from '../../../../images/instagram_icon.svg'
import { ReactComponent as LinkedInLogo } from '../../../../images/linkedin_icon.svg'
import { ReactComponent as TwitterLogo } from '../../../../images/twitter_icon.svg'
import { ReactComponent as YoutubeLogo } from '../../../../images/youtube_icon.svg'
import ICampaign from '../../../../interfaces/campaign'
import ISocialLink from '../../../../interfaces/socialLink'

interface InputProps {
    campaign: ICampaign
    setCampaign: (campaign: ICampaign) => void,
}

const SocialLinkSection = (props: InputProps) => {

    const [isSocialLinkDialogOpen, setIsSocialLinkDialogOpen] = useState<boolean>(false);
    const [tempSocialLink, setTempSocialLink] = useState<ISocialLink>({} as ISocialLink);
    const [tempSocialLinkIndex, setTempSocialLinkIndex] = useState<number>(-1);

    const closeDialog = () => {
        setIsSocialLinkDialogOpen(false)
    }
    const openDialog = (socialLink: ISocialLink, index: number) => {
        setTempSocialLink(socialLink)
        setTempSocialLinkIndex(index)
        setIsSocialLinkDialogOpen(true)
    }

    const deleteSocialLink = (index: number) => {
        let tempSocialLinkObject: any[] = [...props.campaign.socialLinkList]
        tempSocialLinkObject.splice(index, 1)
        props.setCampaign({ ...props.campaign, socialLinkList: tempSocialLinkObject })
    }

    const promote = (index: number) => {
        // let index = props.campaign.socialLinkList.findIndex(e => e.socialLinkId === socialLinkId);
        if (index > 0) {
            let tempsocialLinks = [...props.campaign.socialLinkList]
            let el = tempsocialLinks[index];
            tempsocialLinks[index] = tempsocialLinks[index - 1];
            tempsocialLinks[index - 1] = el;
            props.setCampaign({ ...props.campaign, socialLinkList: tempsocialLinks })
        }
    }

    const demote = (index: number) => {
        // let index = props.campaign.socialLinkList.findIndex(e => e.socialLinkId === socialLinkId);
        if (index !== -1 && index < props.campaign.socialLinkList.length - 1) {
            let tempsocialLinks = [...props.campaign.socialLinkList]
            let el = tempsocialLinks[index];
            tempsocialLinks[index] = tempsocialLinks[index + 1];
            tempsocialLinks[index + 1] = el;
            props.setCampaign({ ...props.campaign, socialLinkList: tempsocialLinks })
        }
    }

    return (
        <div className="home-page-section social-links-section">
            <h2 className="subtitle">Social Links.</h2>
            <span className="description">Select which social icons and hyperlinks should be shown on the home page.</span>
            <Paper className="input-wrapper">
                {props.campaign.socialLinkList.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="icon-cell">
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Platform</span>
                                    </div>
                                </TableCell>
                                <TableCell className="table-header-cell hide-on-mobile">
                                    <div className="table-header-cell">
                                        <span>URL</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.campaign.socialLinkList.map((socialLink, index) => (
                                <TableRow key={new Date().getTime() + index}>
                                    <TableCell className="icon-cell">
                                        {socialLink.platform === 'facebook' && <FacebookLogo />}
                                        {socialLink.platform === 'instagram' && <InstagramLogo />}
                                        {socialLink.platform === 'linkedIn' && <LinkedInLogo />}
                                        {socialLink.platform === 'twitter' && <TwitterLogo />}
                                        {socialLink.platform === 'youtube' && <YoutubeLogo />}
                                    </TableCell>
                                    <TableCell onClick={() => openDialog(socialLink, index)}>
                                        {socialLink.platform === "facebook" && <span>Facebook</span>}
                                        {socialLink.platform === "twitter" && <span>Twitter</span>}
                                        {socialLink.platform === "youtube" && <span>YouTube</span>}
                                        {socialLink.platform === "instagram" && <span>Instagram</span>}
                                        {socialLink.platform === "linkedIn" && <span>LinkedIn</span>}
                                    </TableCell>
                                    <TableCell className="hide-on-mobile">{socialLink.url}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => promote(index)}>
                                                <Tooltip title="Promote"><ExpandLessIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => demote(index)}>
                                                <Tooltip title="Demote"><ExpandMoreIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => openDialog(socialLink, index)}><Tooltip title="Edit Social Link"><CreateIcon /></Tooltip></div>
                                            <div onClick={() => deleteSocialLink(index)}><Tooltip title="Delete Social Link"><DeleteIcon /></Tooltip></div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className="no-entries">No social links added.</div>
                }
                <div className="button-wrapper">
                    <Button className="gg-button" onClick={() => openDialog({} as ISocialLink, -1)}>Add New Social Link</Button>
                </div>
            </Paper>
            <SocialLinkDialog
                handleClose={closeDialog}
                isDialogOpen={isSocialLinkDialogOpen}
                setCampaign={props.setCampaign}
                campaign={props.campaign}
                socialLink={tempSocialLink}
                index={tempSocialLinkIndex}
            />
        </div>
    )
}

export default SocialLinkSection