import React, { ChangeEvent, useMemo } from "react";
import ReactCountryFlag from "react-country-flag";

import SearchIcon from "@mui/icons-material/Search";

import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import ICountryData from "../../../../../interfaces/countryData";
import ISMSFormValues from "../../../../../interfaces/smsFormValues";
import useCommonFunctions from "../../../../../hooks/useCommonFunctions";
import IPhoneNumberError from "../../../../../interfaces/phoneNumberError";
import ITestMessageResponseState from "../../../../../interfaces/testMessageResponse";
import IValidationObject from "../../../../../interfaces/validationObject";
import { isValidPhoneNumber } from "../../../../../utils/validators";

interface ISMSFormProps {
  smsValues: ISMSFormValues;
  phoneError: IPhoneNumberError;
  searchText: string;
  setSMSValues: React.Dispatch<React.SetStateAction<ISMSFormValues>>;
  setPhoneError: React.Dispatch<React.SetStateAction<IPhoneNumberError>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleSMSChange(event: ChangeEvent<HTMLInputElement>): void;
  allCountriesData: ICountryData[];
  getTestValidation: (args: string) => IValidationObject;
  selectedCountryCode: string;
  setSelectedCountryCode: React.Dispatch<React.SetStateAction<string>>;
  testMessageResponse: ITestMessageResponseState;
}

const SMSForm = (props: ISMSFormProps) => {
  // custom hooks
  const commonFunctions = useCommonFunctions();

  // react hooks
  const displayedCountries = useMemo(() => {
    let lowercaseSearchText = props.searchText.toLowerCase();
    return props.allCountriesData.filter(
      (option) =>
        option.name.toLowerCase().includes(lowercaseSearchText) ||
        option.isd_code.includes(lowercaseSearchText) ||
        `${option.name} (${option.isd_code})`
          .toLowerCase()
          .includes(lowercaseSearchText)
    );
  }, [props.searchText]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "5px 0px",
        }}
      >
        <FormControl variant="outlined">
          <InputLabel id="country-phone-code-label">Country Code</InputLabel>
          <Select
            id="country_phone_code"
            name="country_phone_code"
            value={props.selectedCountryCode}
            onChange={(event) =>
              props.setSelectedCountryCode(event.target.value)
            }
            label="Country Code"
            variant="outlined"
            onClose={() => props.setSearchText("")}
            MenuProps={{ autoFocus: false }}
            renderValue={(value) => {
              const selected = props.allCountriesData.filter(
                (x) => x.isd_code === value
              )[0].countryCode;
              return (
                <>
                  <ReactCountryFlag
                    countryCode={selected}
                    style={{ marginRight: "8px" }}
                  />
                  {value}
                </>
              );
            }}
          >
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                placeholder="Search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => props.setSearchText(e.target.value)}
              />
            </ListSubheader>
            {displayedCountries.map((country, index) => (
              <MenuItem value={country.isd_code} key={index}>
                <ReactCountryFlag
                  countryCode={country.countryCode}
                  style={{ marginRight: "8px" }}
                />
                {` ${country.name} (${country.isd_code})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          id="phoneNumber"
          name="phoneNumber"
          value={commonFunctions.getNumberWithoutCountryCode(
            props.smsValues.phoneNumber
          )}
          onChange={props.handleSMSChange}
          onKeyPress={(event) => {
            if (!isValidPhoneNumber(event.key)) {
              event.preventDefault();
            }
          }}
          variant="outlined"
          label="Your Phone number"
          required
          inputProps={{ maxLength: 20 }}
          helperText={
            props.phoneError.display
              ? props.phoneError.message
              : props.getTestValidation("phoneNumber").isValid
              ? ""
              : props.getTestValidation("phoneNumber").validationMessage
          }
          error={
            props.phoneError.display ||
            !props.getTestValidation("phoneNumber").isValid
          }
          onBlur={(e) => {
            let phone = props.selectedCountryCode + props.smsValues.phoneNumber;
            let parsed = commonFunctions.getParsedPhone(phone);

            if (!commonFunctions.isNumberCountryCode(phone)) {
              props.setSMSValues({
                ...props.smsValues,
                phoneNumber: commonFunctions.getNumberWithoutCountryCode(
                  parsed.phone
                ),
              });
              props.setPhoneError({
                display: parsed.error,
                message: parsed.errorMessage,
              });
            } else {
              props.setPhoneError({ display: false, message: "" });
            }
          }}
          fullWidth
          sx={[{ paddingTop: "0px", marginTop: "6px", width: "80%" }]}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <TextField
          id="firstName"
          name="firstName"
          label="User First Name"
          fullWidth
          helperText={
            props.getTestValidation("firstName").isValid
              ? "This is the value that will be inserted instead of the tag {{firstName}} if used."
              : props.getTestValidation("firstName").validationMessage
          }
          onChange={props.handleSMSChange}
          error={!props.getTestValidation("firstName").isValid}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <TextField
          id="surname"
          name="surname"
          label="User Surname"
          fullWidth
          helperText={
            props.getTestValidation("surname").isValid
              ? "This is the value that will be inserted instead of the tag {{surname}} if used."
              : props.getTestValidation("surname").validationMessage
          }
          error={!props.getTestValidation("surname").isValid}
          onChange={props.handleSMSChange}
        />
      </div>
      {/* render success or fail response */}
      {props?.testMessageResponse?.successMessage?.length ? (
        <>
          <Typography variant="subtitle1">Send Status</Typography>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            {props?.testMessageResponse?.icon}
            <Box sx={{ width: "10px" }} />
            <Typography variant="subtitle2">
              {props?.testMessageResponse?.successMessage}
            </Typography>
          </div>
        </>
      ) : (
        <></>
      )}
      {props?.testMessageResponse?.errorMessage?.length ? (
        <>
          <Typography variant="subtitle1">Send Status</Typography>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            {props?.testMessageResponse?.icon}
            <Box sx={{ width: "10px" }} />
            <Typography variant="subtitle2">
              {props?.testMessageResponse?.errorMessage}
            </Typography>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SMSForm;
