import React, { useEffect, useState } from "react"
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
} from "@mui/material"
import useMediaValidation from "./useMediaValidation"
import "../../dialog.scss"
import CloudinaryUploadWidget from "../../shared/CloudinaryUploadWidget"
import IMedia from "../../../../interfaces/media"
import { LotMediaType } from "../../../../common/enums/LotMediaType"

interface InputProps {
	isDialogOpen: boolean
	handleClose: (isSaveObject: boolean, objectToBeSaved?: any) => void
	setObject: (obj: any) => void
	media: IMedia
	object: any
	index: number
}

const MediaDialog = (props: InputProps) => {
	const [media, setMedia] = useState<IMedia>({ ...props.media })
	const mediaValidation = useMediaValidation(media)

	const handleChange = event => {
		const { name, value } = event.target
		setMedia({ ...media, [name]: value })
	}

	const saveMedia = () => {
		if (mediaValidation.validateInputs()) {
			let tempMediaObject: IMedia[] = [...props.object.mediaList]

			if (props.index > -1) {
				tempMediaObject[props.index] = media
			} else {
				tempMediaObject.push(media)
			}

			props.setObject({ ...props.object, mediaList: tempMediaObject })
			setMedia({} as IMedia)
            mediaValidation.generateInitialValidationObject()
			props.handleClose(true, { ...props.object, mediaList: tempMediaObject })
		}
	}

    const handleClose = (boolean: boolean) => {
        mediaValidation.generateInitialValidationObject()
        props.handleClose(boolean)
    }

	const addImagesToMediaObject = (imageUrlList: string[]) => {
		if (!props.object.mediaList) {
			props.object.mediaList=[]
		}
		imageUrlList.forEach(imageUrl => {
			props.object.mediaList.push({
				src: imageUrl,
				mediaType: LotMediaType.IMAGE,
			})
		})
		props.handleClose(true, props.object)
	}

	useEffect(() => {
		setMedia({ ...props.media })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.media])

	return (
		<>
			<Dialog open={props.isDialogOpen} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					{props.media.src ? "Edit " : "Add "}
					{props.media.mediaType === LotMediaType.YOUTUBE ? "Video" : "Image(s)"}
				</DialogTitle>
				<DialogContent>
					{props.media.mediaType === LotMediaType.YOUTUBE && (
						<div className="intro-text">Please enter the link to a video hosted on YouTube.</div>
					)}
					<TextField
						variant="outlined"
						id="src"
						name="src"
						label="Source"
						type="text"
						style={{ marginTop: 6 }}
						fullWidth
						value={media.src}
						onChange={handleChange}
						required
						helperText={!mediaValidation.getValidation("src").isValid && mediaValidation.getValidation("src").validationMessage}
						error={!mediaValidation.getValidation("src").isValid}
						InputProps={
							props.media.mediaType === LotMediaType.IMAGE
								? {
										endAdornment: (
											<InputAdornment position="start">
												<CloudinaryUploadWidget
													folderName={`${props.object.campaignId ?? props.object.organisationId}/${props.object.title}`}
													allowMultipleUploads={true}
													functionOnComplete={addImagesToMediaObject}
													uploadPreset="going_gone_preset"
												/>
											</InputAdornment>
										),
								  }
								: undefined
						}
					/>
				</DialogContent>
				<DialogActions>
					<div className="action-section">

					<div className="save-button-wrapper">
						{!mediaValidation.isValidationPassed && (
							<span className="validation-text">Errors highlighted in form - please resolve before saving.</span>
						)}
						<div className="buttons">
							<Button id="cancel-button" onClick={() => handleClose(false)}>
								Cancel
							</Button>
							<Button id="save-button" onClick={saveMedia} className="gg-button">
								Save
							</Button>
						</div>
					</div>
					</div>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default MediaDialog
