import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { ILogEntry } from '../../../interfaces/logEntry'
import ErrorIcon from '@mui/icons-material/Error'
import './auditLogSection.scss'



interface InputProps {
    auditLogList: ILogEntry[]
}

const AuditLogSection = (props: InputProps) => {

    return (
        <div className='audit-log-section'>
            {props.auditLogList.length > 0 ?
                // <div className='audit-log-section'>
                    <Table className='audit-log-table'>
                        <TableHead>
                            <TableRow>
                                <TableCell><span>Timestamp</span></TableCell>
                                <TableCell><span>Type</span></TableCell>
                                <TableCell><span>Action</span></TableCell>
                                <TableCell><span>Reference</span></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.auditLogList.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()).map((logEntry: ILogEntry, index) => (
                                <TableRow key={index}>
                                    <TableCell><span>{moment(logEntry.timestamp).format('HH:mm:ss DD/MM/yyyy')}</span></TableCell>
                                    <TableCell><span className='type-wrapper'>{logEntry.isError && <ErrorIcon className='error-icon' />}{logEntry.category}</span></TableCell>
                                    <TableCell><span>{logEntry.description}</span></TableCell>
                                    <TableCell><span>{logEntry.reference}</span></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                // </div>
                :
                <span className='no-entries'>No entries</span>
            }
        </div>

    )
}

export default AuditLogSection