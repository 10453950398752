import "./winnerReport.scss"
import React, { useState, useEffect, useContext } from "react"
import useFetch from "../../../hooks/useFetch"
import Loading from "../../shared/Loading"
import { Button, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material"
import useExcel from "../../../hooks/useExcel"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import ICampaign from "../../../interfaces/campaign"
import CloseCampaignSection from "./CloseCampaignSection"
import ILot from "../../../interfaces/lot"
import moment from "moment"
import { IWinner } from "../../../interfaces/winner"
import WinnerListSection from "./WinnerListSection"
import { v4 as uuidv4 } from "uuid"
import { CampaignContext, CampaignDispatchContext, CampaignLoadingContext } from "../../../context/CampaignContext"
import {
	API_PATHS,
	AUDIT_LOG_API_PATHS,
	BID_API_PATHS,
	CAMPAIGN_API_PATHS,
	getFilteredAuditLogQueryString,
	getFilteredLotsQueryString,
	LOT_API_PATHS,
	STRIPE_API_PATHS,
} from "../../../common/ApiPaths"
import IGetWinnerResponse from "../../../interfaces/winningBidResponse"
import { ILogEntry } from "../../../interfaces/logEntry"
import SummarizeIcon from "@mui/icons-material/Summarize"
import AssessmentIcon from "@mui/icons-material/Assessment"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"
import PaidIcon from "@mui/icons-material/Paid"
import EmailIcon from '@mui/icons-material/Email';
import IOrganisation from "../../../interfaces/organisation"
import { OrganisationContext } from "../../../context/OrganisationContext"
import IStripePaymentLinkObject from "../../../interfaces/stripePaymentLinkObject"
import { UserContext } from "../../../context/UserContext"
import { IUser } from "../../../interfaces/user"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import { LotType } from "../../../common/enums/LotType"
import { useErrorBoundary } from 'react-error-boundary'

const WinnerReport = ({ match }) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<string>("")
	const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", label: "", message: "", version: "" })
	const [isLotsStillOpen, setIsLotsStillOpen] = useState<boolean>(false)
	const [lotList, setLotList] = useState<ILot[]>([])
	const [isSendWinnerNotifications, setIsSendWinnerNotifications] = useState<boolean>(false)
	const [handleAllUsersPaymentsClicked, setHandleAllUsersPaymentsClicked] = useState<number>(0)
	const [generateAllUsersVouchersClicked, setGenerateAllUsersVouchersClicked] = useState<number>(0)
	const [winnerList, setWinnerList] = useState<IWinner[]>([])
	const [isPurchaseReport] = useState<boolean>(window.location.href.includes('purchase-report'))

	const excel = useExcel()
	const auditLogApi = useFetch(API_PATHS.AUDITLOG)
	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	const lotApi = useFetch(API_PATHS.LOTS)
	const bidsApi = useFetch(API_PATHS.BIDS)
	const communicationsApi = useFetch(API_PATHS.COMMUNICATIONS)
	const stripeApi = useFetch(API_PATHS.STRIPE)

	const commonFunctions = useCommonFunctions()
	const campaign: ICampaign = useContext(CampaignContext)
	const { dBUser } = useContext(UserContext)
	const campaignLoading: boolean = useContext(CampaignLoadingContext)
	const organisation: IOrganisation = useContext(OrganisationContext)
	const { showBoundary } = useErrorBoundary();

	const setCampaign = useContext(CampaignDispatchContext)

	useEffect(() => {
		if (!campaignLoading && !(campaign && campaign.slug)) {
			showBoundary("Error while fetching campaign")
			setLoading(false)
		} else if (campaign && campaign.slug) {
			setError("")
			getLotsAndWinnerList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campaign, campaignLoading])

	const getLotsAndWinnerList = () => {
		setLoading(true)
		let promises: Promise<any>[] = []
		promises.push(getLots())
		if (campaign.isClosed || isPurchaseReport) {
			promises.push(generateWinnerList(false))
		}
		Promise.all(promises).then(() => {
			setLoading(false)
		})
	}

	const getLots = async (): Promise<void> => {
		try {
			let lots: ILot[] = await lotApi.get(`${LOT_API_PATHS.GET_LOTS.replace("{campaignId}", campaign._id)}${getFilteredLotsQueryString(undefined, true)}`)
			setLotList(lots)
			let isOpenLotFound = false
			lots.forEach(lot => {
				if (moment(lot.endDateTime).isAfter(moment())) {
					isOpenLotFound = true
				}
			})
			setIsLotsStillOpen(isOpenLotFound)
		} catch (err: any) {
			showBoundary(err)
			setLoading(false)
		}
	}

	const closeCampaign = async (): Promise<any> => {
		setLoading(true)
		let generatedWinnerList = await generateWinnerList(true)

		try {
			const updatedCampaign: ICampaign = await campaignsApi.put(`${CAMPAIGN_API_PATHS.UPDATE_CAMPAIGN.replace("{campaignId}", campaign._id)}`, {
				...campaign,
				isClosed: true,
			},
				dBUser._id
			)
			if (campaign.paymentMethod === "PAYMENT-LINK") {
				createStripePaymentLinks(updatedCampaign, generatedWinnerList)
			} else {
				if (isSendWinnerNotifications) {
					sendWinnerNotifications(updatedCampaign, true, generatedWinnerList)
					setCampaign(updatedCampaign)
				} else {
					setCampaign(updatedCampaign)
				}

				setLoading(false)
			}
		} catch (err: any) {
			showBoundary(err)
			setLoading(false)
		}
	}

	const createStripePaymentLinksForWinnerList = async (campaign: ICampaign, generatedWinnerList: IWinner[]) => {
		try {
			let data: IWinner[] = await stripeApi.post(STRIPE_API_PATHS.CREATE_MULTIPLE_PAYMENT_LINKS, {
				winnerList: generatedWinnerList,
				campaignId: campaign._id,
				organisationId: organisation._id,
				currency: campaign.currency,
				stripeConnectedAccountId: organisation.stripeConnectedAccountId,
				revenueFeePercentage: organisation.revenueFeePercentage
			})
			return data
		} catch (err: any) {
			showBoundary(err)
		}
	}

	const sendWinnerNotifications = (campaign, isCloseCampaign: boolean, updatedWinnerList: IWinner[] = []) => {
		let buyItNowExists = false
		updatedWinnerList.forEach((winner) => {
			winner.winnerItems.forEach((winnerItem) => {
				buyItNowExists = buyItNowExists || winnerItem.bidAndLotList.some(x => x.winningLot.type === LotType.BUYITNOW)
			})
		})
		let winningBidList = (updatedWinnerList.length > 0 ? updatedWinnerList : winnerList).map(winner => {
			return winner.winnerItems[0]
		})

		let newWinningBidList = JSON.parse(JSON.stringify(winningBidList))
		newWinningBidList.forEach((x) => {
			x.bidAndLotList = x.bidAndLotList.filter(y => y.winningLot.type !== LotType.BUYITNOW)
		})
		newWinningBidList = newWinningBidList.filter(x => x.bidAndLotList.length > 0)
		// TODO: if no winners - red no winners found
		if (newWinningBidList.length > 0) {
			communicationsApi
				.post(`/sendWinnerNotifications`, {
					campaignId: campaign._id,
					organisationId: campaign.organisationId,
					campaignName: campaign.name,
					campaignPaymentMethod: campaign.paymentMethod,
					campaignCustomWinnerConfirmationSuffix: campaign.customWinnerConfirmationSuffix,
					winningBidList: winningBidList,
					notificationType: campaign.notificationType,
					smsSender: campaign.smsSender,
					sendgridVerifiedEmail: organisation.sendgridVerifiedEmail,
					sendgridTemplateId: organisation.sendgridTemplateId
				})
				.then((data: any) => {
					// setCampaign(updatedCampaign)	
					setLoading(false)
					if (!isCloseCampaign)
						setDialogProperties({
							isOpen: true,
							type: "SUCCESS",
							title: "Notifications Sent Successfully",
							message: "All notifications were sent successfully.",
						})
				})
				.catch((err: Error) => {
					showBoundary(err)
					setLoading(false)
					if (!isCloseCampaign)
						setDialogProperties({
							isOpen: true,
							type: "ERROR",
							title: "Notificaions Error",
							message: "There was an issue when sending the winner notifications.",
						})
				})
		}
		else {
			if (buyItNowExists) { // TODO: check for bins then show error
				setDialogProperties({
					isOpen: true,
					type: "PARTIAL",
					title: "Notifications Cannot be Sent",
					message: "Only purchasers found (no winners). No notifications sent.",
				})
			}
			else {
				setDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: "Notifications Cannot be Sent",
					message: "No winners found. No notifications sent.",
				})
			}
		}
	}

	const createStripePaymentLinks = async (campaign: ICampaign, generatedWinnerList: IWinner[]): Promise<any> => {
		createStripePaymentLinksForWinnerList(campaign, generatedWinnerList)
			.then(updatedWinnerList => {
				if (isSendWinnerNotifications) {
					sendWinnerNotifications(campaign, true, updatedWinnerList)
				}
				setCampaign(campaign)
			})
			.catch((err: Error) => {
				showBoundary(err)
				setLoading(false)
			})
	}

	const checkLotsForCampaignClose = async () => {
		setLoading(true)

		if (!isLotsStillOpen) {
			closeCampaign()
		} else {
			setDialogProperties({
				isOpen: true,
				type: "AREYOUSURE",
				title: "Lots Still Open",
				message:
					"There are lots in this campaign that are still open. Closing the campaign will immediately close any open lots. Are you sure you want to close the campaign?",
			})
			setLoading(false)
		}
	}

	const handleCloseDialog = async (isConfirmed: boolean) => {
		if (dialogProperties.type === "AREYOUSURE") {
			if (isConfirmed) {
				closeCampaign().then((updatedCampaign: ICampaign) => {
					closeAllLots()
				})
			}
		}

		setDialogProperties({ isOpen: false, type: "", title: "", message: "" })
	}

	const closeAllLots = async () => {
		let tempLotList = [...lotList]

		tempLotList.forEach(lot => {
			if (moment(lot.endDateTime).isAfter(moment())) {
				lot.endDateTime = new Date()
			}
		})

		try {
			setLoading(true)
			const lotsToUpdate: any[] = tempLotList.map((x) => {
				return { _id: x._id, endDateTime: x.endDateTime }
			})
			await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT}?setSpecificValues=true`, lotsToUpdate, dBUser._id)
			getLots()
		} catch (err: any) {
			showBoundary(err)
		}
	}

	const generateWinnerList = async (setWinners: boolean = false): Promise<IWinner[]> => {
		let winnerList: IWinner[] = []
		setWinners = setWinners ? setWinners : !campaign.isClosed
		try {
			let winningBidList: IGetWinnerResponse[] = await bidsApi.get(
				`${BID_API_PATHS.GET_WINNING_BIDS.replace("{campaignId}", campaign._id)}?setWinners=${setWinners}&isPurchaseReport=${isPurchaseReport}`
			)

			let userIds = [...new Set(winningBidList.map(x => x.winningUser._id))]
			let auditLogsForAllUsers: ILogEntry[] = await auditLogApi.get(
				`${AUDIT_LOG_API_PATHS.GET_AUDIT_LOG_LIST}${getFilteredAuditLogQueryString(
					undefined,
					undefined,
					campaign._id,
					userIds
				)}`
			)
			winningBidList = winningBidList.filter(x => x.winningUser._id)
			for (let winningBid of winningBidList) {
				let indexFound = winnerList.findIndex(x => x.bidderId === winningBid.winningUser._id)

				if (indexFound < 0) {
					let auditLog: ILogEntry[] = auditLogsForAllUsers.filter(x => x.userId === winningBid.winningUser._id)
					let newWinner: IWinner = {
						id: uuidv4(),
						bidderName: `${commonFunctions.capitalize(winningBid.winningUser.firstName)} ${commonFunctions.capitalize(winningBid.winningUser.surname)}`,
						bidderId: winningBid.winningUser._id,
						winnerItems: [winningBid],
						stripePaymentRefId: winningBid.bidAndLotList[0].winningBid.bidList[0].stripePayment ? winningBid.bidAndLotList[0].winningBid.bidList[0].stripePayment.paymentRefId : "",
						stripePaymentLink: winningBid.bidAndLotList[0].winningBid.bidList[0].stripePayment ? winningBid.bidAndLotList[0].winningBid.bidList[0].stripePayment.stripePaymentLink : {} as IStripePaymentLinkObject,
						auditLog: auditLog,
						campaignUser: winningBid.winningUser,
						paymentMethod: campaign.paymentMethod,
					}
					winnerList.push(newWinner)
				} else {
					winnerList[indexFound].winnerItems.push(winningBid)
				}
			}
			setWinnerList(winnerList)
			return winnerList
		} catch (err: any) {
			console.error(err)
			showBoundary(err)
			setLoading(false)
		}
		return winnerList
	}

	const handleSelectAction = event => {
		if (event.target.value === "TAKE-ALL-PAYMENTS") {
			setHandleAllUsersPaymentsClicked(handleAllUsersPaymentsClicked + 1)
		} else if (event.target.value === "GENERATE-VOUCHERS") {
			setGenerateAllUsersVouchersClicked(generateAllUsersVouchersClicked + 1)
		} else if (event.target.value === "DOWNLOAD-PL-REPORT") {
			excel.generateProfitLossReport(campaign, winnerList)
		} else if (event.target.value === "DOWNLOAD-WINNER-REPORT") {
			excel.generateWinnerReport(campaign, winnerList, isPurchaseReport)
		} else if (event.target.value === "SEND-ALL-NOTIFICATIONS") {
			sendWinnerNotifications(campaign, false)
		}
	}

	if (loading) {
		return <Loading />
	}

	// if (error) {
	// 	return <p>{error}</p>
	// }

	return (
		<div className="winner-report-page">
			{campaign.isClosed || isPurchaseReport ? (
				<>
					<WinnerListSection
						campaign={campaign}
						organisation={organisation}
						setCampaign={setCampaign}
						winnerList={winnerList}
						setWinnerList={setWinnerList}
						generateWinnerList={generateWinnerList}
						handleAllUsersPaymentsClicked={handleAllUsersPaymentsClicked}
						generateAllUsersVouchersClicked={generateAllUsersVouchersClicked}
						dialogProperties={dialogProperties}
						setDialogProperties={setDialogProperties}
						isPurchaseReport={isPurchaseReport}
					/>
					{winnerList.length > 0 && (
						<div className="fixed-action-section">
							<div></div>
							<FormControl className="all-winner-actions hide-on-mobile">
								<InputLabel>{`All ${isPurchaseReport ? 'Purchaser' : 'Winner'} Actions`}</InputLabel>
								<Select
									id="allWinnerActions"
									name="allWinnerActions"
									value={""}
									onChange={event => handleSelectAction(event)}
									label={`All ${isPurchaseReport ? 'Purchaser' : 'Winner'} Actions`}
								>
									{!isPurchaseReport && (
										<MenuItem value={"TAKE-ALL-PAYMENTS"} disabled={commonFunctions.determineAllWinnersPaidStatus(winnerList) === 'PAID'}>
											<ListItemIcon>
												<PaidIcon />
											</ListItemIcon>
											<ListItemText primary="Take Payments for all Winners" />
										</MenuItem>
									)}
									<MenuItem value={"GENERATE-VOUCHERS"} disabled={commonFunctions.determineAllWinnersPaidStatus(winnerList) === 'NOT PAID' || commonFunctions.determineAllWinnersVoucherToProcessCount(winnerList) === 0}>
										<ListItemIcon>
											<ConfirmationNumberIcon />
										</ListItemIcon>
										<ListItemText primary="Generate Vouchers for all Winners" />
									</MenuItem>
									<MenuItem value={"DOWNLOAD-PL-REPORT"}>
										<ListItemIcon>
											<AssessmentIcon />
										</ListItemIcon>
										<ListItemText primary="Download P&L Report" />
									</MenuItem>
									<MenuItem value={"DOWNLOAD-WINNER-REPORT"}>
										<ListItemIcon>
											<SummarizeIcon />
										</ListItemIcon>
										<ListItemText primary={`Download ${isPurchaseReport ? 'Purchaser' : 'Winner'} Report`} />
									</MenuItem>
									{!isPurchaseReport && (
										<MenuItem value={"SEND-ALL-NOTIFICATIONS"}>
											<ListItemIcon>
												<EmailIcon />
											</ListItemIcon>
											<ListItemText primary="Send All Winner Notifications" />
										</MenuItem>
									)}
								</Select>
							</FormControl>
						</div>
					)}
				</>
			) : (
				<CloseCampaignSection
					closeCampaign={checkLotsForCampaignClose}
					isSendWinnerNotifications={isSendWinnerNotifications}
					setIsSendWinnerNotifications={setIsSendWinnerNotifications}
					campaign={campaign}
				/>
			)}

			<ConfirmationDialog
				handleClose={handleCloseDialog}
				isDialogOpen={dialogProperties.isOpen}
				message={dialogProperties.message}
				title={dialogProperties.title}
				type={dialogProperties.type}
				label={dialogProperties.label}
				primaryButton={<Button onClick={() => handleCloseDialog(true)} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
					Ok
				</Button>}
			/>
		</div>
	)
}

export default WinnerReport
