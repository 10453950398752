import './bidReport.scss'
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent, Tooltip, Button, Checkbox, FormControl, InputLabel, Select, MenuItem, TablePagination } from "@mui/material"
import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import useExcel from '../../../hooks/useExcel'
import BidDetailsDialog from '../../shared/BidDetailsDialog'
import InfoIcon from '@mui/icons-material/Info'
import BidFilterSection from './BidFilterSection'
import { CampaignContext, CampaignLoadingContext } from '../../../context/CampaignContext'
import { API_PATHS, BID_API_PATHS, getFilteredBidWithDataQueryString, getPaginatedBidsQueryString } from '../../../common/ApiPaths'
import IBidWithData from '../../../interfaces/bidWithData'
import { BidStatus } from '../../../common/enums/BidStatus'
import { UserContext } from '../../../context/UserContext'
import { OrganisationContext } from '../../../context/OrganisationContext'
import IAuditLogEntryInfo from '../../../interfaces/auditLogEntryInfo'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import DeviceIcon from '../shared/DeviceIcon'
import ErrorButtonFab from '../shared/ErrorButtonFab'
import { useErrorBoundary } from 'react-error-boundary'
import SummaryFiguresSection from './SummaryFiguresSection'
import MobileBidCard from './MobileBidCard'
import SummaryFigures from '../shared/SummaryFiguresSection/SummaryFigures'
import IGetWinnerResponse from '../../../interfaces/winningBidResponse'



const BidReport = ({ match, ...props }) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    // const [fullBidList, setFullBidList] = useState<IBidWithData[]>([])
    const [bidList, setBidList] = useState<IBidWithData[]>([])
    const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "" })
    const [selected, setSelected] = useState<string[]>([])
    const [selectedBulkAction] = useState<string>("")
    const [adminError, setAdminError] = useState<string>("")
    const [addBidsTimer, setAddBidsTimer] = useState(0);
    const isCancelled = useRef(false)
    const [bidDetailsDialogObject, setBidDetailsDialogObject] = useState<any>({
        isOpen: false,
        backgroundColor: "",
        fontColor: ""
    })
    const [filter, setFilter] = useState<string>("")
    const [bidToBeDeleted, setBidToBeDeleted] = useState<IBidWithData>({} as IBidWithData)
    const [winnerList, setWinnerList] = useState<IGetWinnerResponse[]>([])
    const [paginationPage, setPaginationPage] = useState<number>(0)
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(25)
    const [totalBidCount, setTotalBidCount] = useState<number>(0)
    const [succeededBidCount, setSucceededBidCount] = useState<number>(0)
    const [pendingBidCount, setPendingBidCount] = useState<number>(0)
    const [failedBidCount, setFailedBidCount] = useState<number>(0)

    const campaign = useContext(CampaignContext)
    const { dBUser } = useContext(UserContext)
    const organisation = useContext(OrganisationContext)
    const campaignLoading = useContext(CampaignLoadingContext)

    const bidsApi = useFetch(API_PATHS.BIDS)
    const excel = useExcel()
    const commonFunctions = useCommonFunctions()
    const { showBoundary } = useErrorBoundary();


    const getBids = async (filter: string, isFirstLoad: boolean = false): Promise<void> => {
        setAdminError('')
        //only set loading on the first fetch
        if (bidList.length === 0) {
            setLoading(true)
        }
        try {
            let paginatedBidObject = await bidsApi.get(`${BID_API_PATHS.GET_PAGINATED_BIDS}${getPaginatedBidsQueryString(campaign._id, paginationRowsPerPage, paginationPage, filter)}`)

            console.log("paginatedBidObject", paginatedBidObject)

            setTotalBidCount(paginatedBidObject.totalCount)
            setSucceededBidCount(paginatedBidObject.succeededCount)
            setPendingBidCount(paginatedBidObject.pendingCount)
            setFailedBidCount(paginatedBidObject.failedCount)

            if (bidList.length === 0) {
                props.setPadding()
            }

            setBidList(paginatedBidObject.bidList.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()))
            setLoading(false)
        } catch (err: any) {
            if (isFirstLoad) {
                showBoundary(err)
            } else {
                setAdminError(err.message)
            }
            setLoading(false)
        }
    }

    const openDeleteConfirmationDialog = (bid: IBidWithData) => {
        setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Delete", message: "Are you sure you want to delete this bid?", label: "DELETE" })
        // setIsConfirmationDialogOpen(true)
        setBidToBeDeleted(bid)
    }

    const deleteBid = async (bidId: string) => {
        try {
            await bidsApi.del(`${BID_API_PATHS.DELETE_BID}`, {
                bidIds: [bidId],
                campaignId: campaign._id,
                auditLogEntryInfo: {
                    currentUserId: dBUser._id,
                    campaignId: campaign._id,
                    organisationId: organisation._id,
                } as IAuditLogEntryInfo
            })
            setDialogProperties({ isOpen: true, type: "SUCCESS", title: "Bid Deleted.", message: "The bid has successfully been deleted." })
            getBids(filter)
            setBidToBeDeleted({} as IBidWithData)
        }
        catch (err: any) {
            setError(err.message)
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = bidList.map((bid) => bid._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isSelected = (name: any) => selected.indexOf(name) !== -1;

    const handleClick = (name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleBulkActionsChange = (event) => {
        if (event.target.value === "BULKDELETE") {
            openBulkDeleteConfirmationDialog()
        } else {
            console.log(`unknown value: ${event.target.value}`)
        }
    };

    const openBulkDeleteConfirmationDialog = () => {
        setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Bulk Delete", message: "Are you sure you want to delete the selected bids?", label: "BULKDELETE" })

    }

    const handleCloseDialog = (isConfirmed: boolean) => {
        if (dialogProperties.label === "BULKDELETE") {
            if (isConfirmed) {
                bulkDelete()
            }
        } else if (dialogProperties.label === "DELETE") {
            if (isConfirmed) {
                deleteBid(bidToBeDeleted._id)
            } else {
                setBidToBeDeleted({} as IBidWithData)
            }
        }
        setDialogProperties({ isOpen: false })

    }

    const bulkDelete = async () => {
        try {
            await bidsApi.del(`${BID_API_PATHS.DELETE_BID}`, {
                bidIds: selected,
                campaignId: campaign._id,
                auditLogEntryInfo: {
                    currentUserId: dBUser._id,
                    campaignId: campaign._id,
                    organisationId: organisation._id
                } as IAuditLogEntryInfo
            })
            setDialogProperties({ isOpen: true, type: "SUCCESS", title: "Bids Deleted", message: "Bids deleted successfully.", label: "SUCCESS" })
            setSelected([])
            getBids(filter)
        }
        catch (err: any) {
            setError(err.message)
        }
    }

    const handleFilterClick = (filter: string) => {
        setFilter(filter)
        getBids(filter)
    }

    useEffect(() => {
        if (!campaign.isClosed) {
            let timer = setTimeout(() => {
                if (!window.navigator.onLine) {
                    setAdminError('No internet connection')
                    setAddBidsTimer(addBidsTimer + 1)
                }
                else {
                    getBids(filter)
                }
            }, 5000);

            return () => {
                isCancelled.current = true;
                clearTimeout(timer);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [bidList, addBidsTimer]);

    useEffect(() => {
        if (campaign._id) {
            getWinnerList();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [bidList, campaign])

    useEffect(() => {
        if (!campaignLoading && !(campaign && campaign.slug)) {
            setError('Error while fetching campaign')
            setLoading(false)
        }
        else if (campaign.slug) {
            setError('')
            setLoading(false)
            getBids(filter, true)
        }
    }, [paginationPage, paginationRowsPerPage, campaign, campaignLoading])

    const getWinnerList = async (): Promise<void> => {
        try {
            let winnerList: IGetWinnerResponse[] = await bidsApi.get(`${BID_API_PATHS.GET_WINNING_BIDS.replace('{campaignId}', campaign._id)}?setWinners=false`)
            setWinnerList(winnerList)
        }
        catch (err: any) {
            showBoundary(err)
        }
    }

    const calculateTotalWinningBids = (): number => {
        let totalWinningBids = 0
        if (filter === "SUCCEEDED" || filter === "") {
            winnerList.forEach(w => {
                w.bidAndLotList.forEach(winnerItem => {
                    if (winnerItem.winningBid.totalValue) {
                        totalWinningBids += winnerItem.winningBid.totalValue
                    }
                })
            });
        }

        return totalWinningBids
    }

    const handlePaginationChangePage = (event: unknown, newPage: number) => {
        setPaginationPage(newPage)
        setSelected([])
    }

    const handlePaginationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationRowsPerPage(parseInt(event.target.value, 10))
        setPaginationPage(0)
        setSelected([])
    }

    const determineTotalRowCount = (): number => {
        if (filter === "SUCCEEDED") {
            return succeededBidCount
        } else if (filter === "PENDING") {
            return pendingBidCount
        } else if (filter === "FAILED") {
            return failedBidCount
        } else {
            return totalBidCount
        }
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    // if (error) {
    //     return (
    //         <p>{error}</p>
    //     )
    // }


    return (
        <div className="bid-report-page">
            {campaign.slug && bidList.length > 0 ?
                <>
                    <BidFilterSection
                        totalCount={totalBidCount}
                        succeededCount={succeededBidCount}
                        pendingCount={pendingBidCount}
                        failedCount={failedBidCount}
                        handleFilterClick={handleFilterClick}
                        filter={filter}
                    />
                    <SummaryFigures nameValuePairList={[
                        { name: "Bid Count", value: String(totalBidCount || 0) },
                        { name: "Winning Bid Value", value: `${commonFunctions.currencyFormatter(campaign.currency).format(calculateTotalWinningBids() || 0)}` },

                    ]} />
                    <Card className='desktop-bid-table'>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {!campaign.isClosed &&
                                            <TableCell padding="checkbox" className="hide-on-mobile">
                                                <Checkbox
                                                    indeterminate={selected.length > 0 && selected.length < bidList.length}
                                                    checked={bidList.length > 0 && selected.length === bidList.length}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{ 'aria-label': 'select all bids' }}
                                                />
                                            </TableCell>
                                        }
                                        <TableCell>
                                            <div className="table-header-cell center">
                                                <span>Device</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Timestamp</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Bidder Name</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Bid</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Lot</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Status</span>
                                            </div>
                                        </TableCell>
                                        {/* {!campaign.isClosed && */}
                                        <TableCell>
                                        </TableCell>
                                        {/* } */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bidList.map((bid: IBidWithData) => (
                                        <TableRow key={bid._id}>
                                            {!campaign.isClosed &&
                                                <TableCell padding="checkbox" className="hide-on-mobile">
                                                    <Checkbox
                                                        checked={isSelected(bid._id)}
                                                        inputProps={{ 'aria-labelledby': bid._id }}
                                                        onClick={() => handleClick(bid._id)}
                                                    />
                                                </TableCell>
                                            }
                                            <TableCell align="center">
                                                <DeviceIcon device={bid.device} color="#858585" />
                                            </TableCell>
                                            <TableCell>{moment(bid.createdAt).format("HH:mm:ss DD/MM/YYYY")}</TableCell>
                                            <TableCell>
                                                {bid.campaignUser ? (bid.campaignUser.firstName ? `${commonFunctions.capitalize(bid.campaignUser.firstName)} ${commonFunctions.capitalize(bid.campaignUser.surname)}` : bid.campaignUser.email) : null}
                                                {bid.isAnonymous && <span style={{ fontSize: "0.7rem" }}> (Anon.)</span>}
                                            </TableCell>
                                            <TableCell>
                                                {commonFunctions.getDisplayedBid(bid, campaign.currency)}
                                            </TableCell>
                                            <TableCell>{bid.lot.title} <span style={{ fontSize: "0.7rem" }}>({bid.lot.type})</span></TableCell>
                                            <TableCell>
                                                {bid.status === BidStatus.FAILED && <span className='failed'>Failed</span>}
                                                {bid.status === BidStatus.PENDING && <span className='pending'>Pending</span>}
                                                {bid.status === BidStatus.SUCCEEDED && <span className='succeeded'>Succeeded</span>}
                                            </TableCell>
                                            <TableCell>
                                                <div className="action-cell">
                                                    <div onClick={() => {
                                                        setBidDetailsDialogObject({
                                                            isOpen: true,
                                                            backgroundColor: campaign.headerBackgroundColor,
                                                            fontColor: campaign.headerFontColor,
                                                            bid: bid,
                                                            auctionTitle: campaign.title
                                                        })
                                                    }}>
                                                        <Tooltip title="View bid details"><InfoIcon /></Tooltip>
                                                    </div>
                                                    {!campaign.isClosed &&
                                                        <div onClick={() => { if (bid.status !== BidStatus.PENDING) openDeleteConfirmationDialog(bid) }}>
                                                            {/* <div onClick={() => {if (bid.status !== "PENDING") deleteBid(bid.lot.lotId, bid.bidId)}}> */}
                                                            <Tooltip title="Delete Bid"><DeleteIcon className={bid.status === BidStatus.PENDING ? 'disabled' : ''} /></Tooltip>
                                                        </div>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={determineTotalRowCount()}
                                rowsPerPage={paginationRowsPerPage}
                                page={paginationPage}
                                onPageChange={handlePaginationChangePage}
                                onRowsPerPageChange={handlePaginationChangeRowsPerPage}
                                labelRowsPerPage="Rows/page"
                            />
                        </CardContent>
                    </Card >

                    <div className='mobile-bid-wrapper'>
                        {bidList.map((bid: IBidWithData, index: number) => (
                            <div key={index} onClick={() => {
                                setBidDetailsDialogObject({
                                    isOpen: true,
                                    backgroundColor: campaign.headerBackgroundColor,
                                    fontColor: campaign.headerFontColor,
                                    bid: bid,
                                    auctionTitle: campaign.title
                                })
                            }}>
                                <MobileBidCard
                                    bid={bid}
                                    campaign={campaign}
                                />
                            </div>
                        ))}
                    </div>
                </>
                :
                <Card><CardContent><div className="no-entries">No bids found</div></CardContent></Card>
            }

            {campaign.slug && bidList.length > 0 &&

                <div className="fixed-action-section">
                    {!campaign.isClosed ?
                        <div style={{ display: "flex" }}>
                            <div style={{ width: 240 }} className="hide-on-mobile"></div>
                            <FormControl
                                className="bulk-actions hide-on-mobile"
                                disabled={selected.length < 1}
                            >
                                <InputLabel id="bulk-actions-label">Bulk Actions</InputLabel>
                                <Select
                                    labelId="bulk-actions-label"
                                    id="bulkActions"
                                    name="bulkActions"
                                    value={selectedBulkAction}
                                    onChange={(event) => handleBulkActionsChange(event)}
                                    label="Bulk Actions?"
                                >
                                    <MenuItem value={'BULKDELETE'}>Delete Selected</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        :
                        <div style={{ display: "flex" }}>
                            <div style={{ width: 240 }} className="hide-on-mobile"></div>
                            <span className='campaign-closed-notice'>Campaign closed - bids can no longer be edited.</span>
                        </div>
                    }
                    <div className="right-hand-buttons">
                        <Button onClick={() => excel.generateBidReport(campaign, bidList)} className="gg-button">Download Bid Report</Button>
                    </div>
                </div>
            }

            <ConfirmationDialog
                handleClose={handleCloseDialog}
                isDialogOpen={dialogProperties.isOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
                primaryButton={<Button onClick={() => handleCloseDialog(true)} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
                    Ok
                </Button>}
            />

            {bidDetailsDialogObject.bid &&
                <BidDetailsDialog
                    isDialogOpen={bidDetailsDialogObject.isOpen}
                    handleClose={() => setBidDetailsDialogObject({
                        isOpen: false
                    })}
                    bid={bidDetailsDialogObject.bid}
                    lot={bidDetailsDialogObject.bid.lot}
                    campaign={{ ...campaign, menuBarBackgroundColor: "#26c0b9", menuBarFontColor: "#FFFFFF" }}
                />
            }

            {adminError && (
                <ErrorButtonFab onClickFunction={() => {
                    setDialogProperties({ isOpen: true, type: "ERROR", title: "Error", message: adminError })
                }} />
            )}
        </div >
    )
}

export default BidReport