import React, { useEffect, useRef } from 'react'
import './loadingSpinner.scss'
import { ThreeCircles } from 'react-loader-spinner'

interface InputProps {
    text: string
}

const LoadingSpinner = (props: InputProps) => {

    const isCancelled = useRef(false)

    useEffect(() => {

        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='loading-spinner'>
            <ThreeCircles
                ariaLabel="loading-indicator"
                height={50}
                width={50}
                color="#26C0B9"
            />
            <span>{props.text}</span>
        </div>
    )
}

export default LoadingSpinner