import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Popover, Select, TextField } from "@mui/material";
import './insertPopover.scss'
import useStringFunctions from "../../../../hooks/useStringFunctions";
import ICampaign from "../../../../interfaces/campaign";
import { useContext, useState } from "react";
import { CampaignContext } from "../../../../context/CampaignContext";

interface InputProps {
	open: boolean;
	handleClose: () => void;
	save: () => void;
	anchorEl: any
	linkConfig: any,
	setLinkConfig: (linkConfig: any) => void
	saveButtonText: string
}

const InsertLinkPopover = (props: InputProps) => {
	const { handleClose, open, anchorEl, save, linkConfig, setLinkConfig, saveButtonText } = props;
	const [linkType, setLinkType] = useState<"INTERNAL" | "EXTERNAL">("INTERNAL");

	return (
		<Popover className="insert-popover" onClose={handleClose} open={open} anchorEl={anchorEl} anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}}>
			<div className='popover-content'>
				<span><b>Insert Link Settings</b></span>
				<div className="input-wrapper">

					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="link-type-label">Link Type</InputLabel>
							<Select
								labelId="link-type-label"
								defaultValue={linkType || ""}
								onChange={event => setLinkType(event.target.value as "INTERNAL" | "EXTERNAL")}
								label="Link Type"
							>
								<MenuItem value={"INTERNAL"}>Internal (this campaign)</MenuItem>
								<MenuItem value={"EXTERNAL"}>External (3rd party website)</MenuItem>
							</Select>
						</FormControl>
					</div>
					{linkType === "INTERNAL" ?
						<div className="field-wrapper">
							<FormControl variant="outlined">
								<InputLabel id="page-label">Page</InputLabel>
								<Select
									labelId="page-label"
									defaultValue={"lots"}
									onBlur={event => setLinkConfig({ ...linkConfig, link: `/%%campaign-slug%%/${event.target.value}` })}
									label="Page"
								>
									<MenuItem value={"faqs"}>FAQs</MenuItem>
									<MenuItem value={"live-auction"}>Live Auction</MenuItem>
									<MenuItem value={"lots"}>Lots Page</MenuItem>
									<MenuItem value={"privacy-policy"}>Privacy Policy</MenuItem>
									<MenuItem value={"terms-and-conditions"}>Terms and Conditions</MenuItem>
								</Select>
							</FormControl>
						</div>
						:
						<div className="field-wrapper">
							<TextField
								label="URL"
								variant="outlined"
								defaultValue={linkConfig.link || ""}
								onBlur={event => setLinkConfig({ ...linkConfig, link: `https://${event.target.value}`})}
								InputProps={{
									startAdornment: <InputAdornment position="start">https://</InputAdornment>,
								  }}
								// helperText="Use %%campaign-slug%% to insert the campaign slug into the link."
							/>
						</div>

					}




					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="open-in-new-tab-label">Open Link In New Tab?</InputLabel>
							<Select
								labelId="open-in-new-tab-label"
								defaultValue={linkConfig.isOpenInNewTab || ""}
								onBlur={event => setLinkConfig({ ...linkConfig, isOpenInNewTab: event.target.value })}
								label="Open Link In New Tab?"
							>
								<MenuItem value={"true"}>Yes</MenuItem>
								<MenuItem value={"false"}>No</MenuItem>
							</Select>
						</FormControl>
					</div>



				</div>

				<div className="action-section">
					<div className="save-button-wrapper">
						<div className="buttons">
							<Button id="cancel-button" onClick={handleClose} >
								Cancel
							</Button>
							<Button className="gg-button" onClick={() => save()}>{saveButtonText}</Button>
						</div>
					</div>
				</div>
			</div>
		</Popover>
	)
}

export default InsertLinkPopover