import { useState } from "react";
import ISocialLink from "../../../../interfaces/socialLink";
import IValidationObject from "../../../../interfaces/validationObject";

const useSocialLinkValidation = (socialLink: ISocialLink) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([
        { name: "platform", isValid: true, validationMessage: "" },
        { name: "url", isValid: true, validationMessage: "" }
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "platform", isValid: true, validationMessage: "" },
            { name: "url", isValid: true, validationMessage: "" }
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (socialLink.platform === "" || typeof (socialLink.platform) === "undefined") {
            setFailedValidation("platform", "Please select the social platform.")
        }

        if (socialLink.url === "" || typeof (socialLink.url) === "undefined") {
            setFailedValidation("url", "Please select the url to the social page.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default useSocialLinkValidation