import './lotDetailsSection.scss'
import React from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField, InputAdornment } from '@mui/material'
import ILot from '../../../../interfaces/lot'
import IValidationObject from '../../../../interfaces/validationObject'
import ICampaign from '../../../../interfaces/campaign'
import useCommonFunctions from '../../../../hooks/useCommonFunctions'
import CloudinaryUploadWidget from '../../shared/CloudinaryUploadWidget'
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from 'dayjs'

interface InputProps {
    lot: ILot
    setLot: (lot: ILot) => void
    campaign: ICampaign
    parentImageSrc: string
    setParentImageSrc: (src: string) => void
    isChildLot: boolean
    validationObject: IValidationObject[]
    isCampaignClosed: boolean
    fromVMS: boolean
}

const LotDetailsSection = (props: InputProps) => {

    const commonFunctions = useCommonFunctions()


    const handleBlur = (event) => {
        let { name, value } = event.target
        props.setLot({ ...props.lot, [name]: value })
    }

    const handleManageBooleanDropdown = (event, name) => {
        const { value } = event.target
        if (value === "true") {
            props.setLot({ ...props.lot, [name]: true })
        } else {
            props.setLot({ ...props.lot, [name]: false })
        }
    }
    // const handleIsShowAvailabilityCount = (event) => {
    //     const { value } = event.target
    //     if (value === "true") {
    //         props.setLot({ ...props.lot, isShowAvailabilityCount: true })
    //     } else {
    //         props.setLot({ ...props.lot, isShowAvailabilityCount: false })
    //     }
    // }

    const handleDateChange = (name, date) => {
        props.setLot({ ...props.lot, [name]: date })
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        props.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return (
        <div className="lot-details-section">
            <h2 className="subtitle">Lot Details.</h2>
            <span className="description">Set the detailed information about the Lot.</span>
            <Paper className="input-wrapper">
                {props.lot.type === "BUYITNOW" &&
                    <>
                        <div className="field-wrapper">
                            <FormControl variant="outlined" required>
                                <InputLabel id="user-defined-price-label">User Defined Price?</InputLabel>
                                <Select
                                    labelId="user-defined-price-label"
                                    id="isUserDefinedPrice"
                                    name="isUserDefinedPrice"
                                    value={props.lot.isUserDefinedPrice}
                                    onChange={(event) => handleManageBooleanDropdown(event, "isUserDefinedPrice")}
                                    label="User Defined Price?"
                                    disabled={props.isCampaignClosed}
                                >
                                    <MenuItem value={'true'}>Yes</MenuItem>
                                    <MenuItem value={'false'}>No</MenuItem>

                                </Select>
                                <FormHelperText>Can a user decide how much they want to pay for the item?</FormHelperText>
                            </FormControl>
                        </div>
                    </>
                }
                {props.lot.type !== "PARENT" &&
                    <>
                        {/*props.lot.type !== "CUMULATIVE" &&*/ props.lot.type !== "VIEWONLY" &&
                            <div className="field-wrapper">
                                <TextField
                                    id="reservePrice"
                                    name="reservePrice"
                                    label="Reserve / Start Price"
                                    variant="outlined"
                                    type="number"
                                    onWheel={(e) => (e.target as any).blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{commonFunctions.getCurrencySymbol(props.campaign.currency ?? 'GBP')}</InputAdornment>,
                                        inputProps: { min: 1 }
                                    }}
                                    defaultValue={props.lot.reservePrice}
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        event.target.value = Number(event.target.value).toString()
                                    }}
                                    required
                                    error={!getValidation("reservePrice").isValid}
                                    helperText={getValidation("reservePrice").isValid ? "The price at or above which, bids will be accepted." : getValidation("reservePrice").validationMessage}
                                    disabled={props.isCampaignClosed}
                                />
                            </div>
                        }

                        {props.lot.type !== "VIEWONLY" &&
                            <div className="field-wrapper">
                                <TextField
                                    id="costPrice"
                                    name="costPrice"
                                    label="Cost Price"
                                    variant="outlined"
                                    type="number"
                                    onWheel={(e) => (e.target as any).blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{commonFunctions.getCurrencySymbol(props.campaign.currency ?? 'GBP')}</InputAdornment>,
                                    }}
                                    defaultValue={props.lot.costPrice}
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        event.target.value = Number(event.target.value).toString()
                                    }}
                                    required
                                    error={!getValidation("costPrice").isValid}
                                    helperText={getValidation("costPrice").isValid ? "The cost price of the lot." : getValidation("costPrice").validationMessage}
                                    disabled={props.isCampaignClosed || props.fromVMS}
                                />
                            </div>
                        }
                        {/*props.lot.type !== "CUMULATIVE" && */props.lot.type !== "VIEWONLY" &&
                            <div className="field-wrapper">
                                <TextField
                                    id="availabilityCount"
                                    name="availabilityCount"
                                    label="Number Available"
                                    variant="outlined"
                                    type="number"
                                    onWheel={(e) => (e.target as any).blur()}
                                    defaultValue={props.lot.availabilityCount}
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        event.target.value = Number(event.target.value) > 0 ? Number(event.target.value).toString() : ''
                                    }}
                                    required
                                    InputProps={{ inputProps: { min: 1 } }}
                                    error={!getValidation("availabilityCount").isValid}
                                    helperText={getValidation("availabilityCount").isValid ? "The number of the lot that are available to be sold." : getValidation("availabilityCount").validationMessage}
                                    disabled={props.isCampaignClosed}
                                />
                            </div>
                        }
                        {props.lot.type === "BUYITNOW" &&
                            <>
                                <div className="field-wrapper">
                                    <FormControl variant="outlined" required>
                                        <InputLabel id="availability-label">Show Availability?</InputLabel>
                                        <Select
                                            labelId="availability-label"
                                            id="isShowAvailabilityCount"
                                            name="isShowAvailabilityCount"
                                            value={props.lot.isShowAvailabilityCount}
                                            onChange={(event) => handleManageBooleanDropdown(event, "isShowAvailabilityCount")}
                                            label="Show Availability?"
                                            disabled={props.isCampaignClosed}
                                        >
                                            <MenuItem value={'true'}>Yes</MenuItem>
                                            <MenuItem value={'false'}>No</MenuItem>

                                        </Select>
                                        <FormHelperText>Is the number of items available shown to the bidders?</FormHelperText>
                                    </FormControl>
                                </div>
                            </>
                        }
                        {props.lot.type !== "BUYITNOW" && props.lot.type !== "VIEWONLY" && props.lot.type !== "SEALED" &&
                            <div className="field-wrapper">
                                <TextField
                                    id="bidIncrement"
                                    name="bidIncrement"
                                    label="Bid Increment"
                                    variant="outlined"
                                    type="number"
                                    onWheel={(e) => (e.target as any).blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{commonFunctions.getCurrencySymbol(props.campaign.currency ?? 'GBP')}</InputAdornment>,
                                        inputProps: { min: 1 }
                                    }}
                                    value={props.lot.bidIncrement}
                                    onChange={(event) => {
                                        event.target.value = Number(event.target.value).toString()
                                        handleBlur(event)
                                    }}
                                    required
                                    error={!getValidation("bidIncrement").isValid}
                                    helperText={getValidation("bidIncrement").isValid ? `The next highest bid value that will be accepted. Eg current bid ${commonFunctions.getCurrencySymbol(props.campaign.currency ?? 'GBP')}10, bid increment of ${commonFunctions.getCurrencySymbol(props.campaign.currency ?? 'GBP')}5, next acceptable bid ${commonFunctions.getCurrencySymbol(props.campaign.currency ?? 'GBP')}15.` : getValidation("bidIncrement").validationMessage}
                                    disabled={props.isCampaignClosed}
                                />
                            </div>
                        }
                    </>
                }
                {!props.isChildLot &&
                    <>
                        <div className="field-wrapper">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Lot Start Date"
                                    // inputVariant="outlined"
                                    value={dayjs(props.lot.startDateTime)}
                                    onChange={(date) => handleDateChange("startDateTime", date)}
                                    ampm={false}
                                    // hideTabs
                                    // showTodayButton
                                    format="HH:mm DD/MM/YYYY"
                                    // renderInput={(params) => <TextField {...params} />}
                                    disabled={props.isCampaignClosed}
                                    slotProps={{
                                        textField: {
                                            disabled: props.isCampaignClosed
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="field-wrapper">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Lot End Date"
                                    // inputVariant="outlined"
                                    value={dayjs(props.lot.endDateTime)}
                                    onChange={(date) => handleDateChange("endDateTime", date)}
                                    ampm={false}
                                    // hideTabs
                                    // showTodayButton
                                    format="HH:mm DD/MM/YYYY"
                                    disabled={props.isCampaignClosed}
                                    slotProps={{
                                        textField: {
                                            disabled: props.isCampaignClosed
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </>
                }

                {props.lot.type === "PARENT" &&
                    <div className="field-wrapper">
                        <TextField
                            id="parentImageSrc"
                            name="parentImageSrc"
                            label="Image Source"
                            variant="outlined"
                            value={props.parentImageSrc}
                            onChange={(event) => props.setParentImageSrc(event.target.value)}
                            helperText="The image that will be used for the parent lot."
                            disabled={props.isCampaignClosed}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="start">
                                        <CloudinaryUploadWidget
                                            folderName={`${props.campaign.slug}/${props.lot.title}`}
                                            allowMultipleUploads={false}
                                            functionOnComplete={(uploadedUrlList) => {
                                                if (uploadedUrlList.length) {
                                                    props.setParentImageSrc(uploadedUrlList[uploadedUrlList.length - 1])
                                                }
                                            }}
                                            uploadPreset="unsigned"
                                        />
                                    </InputAdornment>
                            }}
                        />
                    </div>
                }
            </Paper>
        </div>
    )
}

export default LotDetailsSection