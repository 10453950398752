import React, { useState, useEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  TablePagination,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import HistoryDialog from "./HistoryDialog";
import IHistoryDetailsModalState from "../../../../interfaces/historyDetailModal";
import {
  API_PATHS,
  COMMUNICATIONS_API_PATHS,
  getPaginatedCommunicationHistoryQueryString,
} from "../../../../common/ApiPaths";
import useFetch from "../../../../hooks/useFetch";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import moment from "moment";

interface CommunicationsRowData {
  historyId: string;
  date: string;
  message: string;
  audience: string;
  messageType: string;
  successRate: number;
  from: string;
  bcc: string;
  subject: string;
  icon: JSX.Element;
}

const History = ({ campaignId }) => {
  // custom hooks:
  // fetch:
  // communications:
  const communicationsApi = useFetch(API_PATHS.COMMUNICATIONS);

  // react hooks:
  // history:
  const [history, setHistory] = useState({
    dataRows: [
      {
        historyId: "",
        date: "",
        message: "",
        from: "",
        audience: "",
        bcc: "",
        messageType: "",
        successRate: 0.0,
        subject: "",
        icon: <></>,
      },
    ],
    loading: false,
    totalHistoryCount: 0,
  });

  // history details modal:
  const [historyModalState, setHistoryModalState] =
    useState<IHistoryDetailsModalState>({
      visible: false,
      historyId: "",
      messageType: "",
      data: {
        recipeintList: "",
        successRate: 0.0,
        message: "",
        from: "",
        bcc: "",
        subject: "",
        recipients: [
          {
            date: "",
            firstName: "",
            surname: "",
            phone: "",
            success: false,
            errorMessage: "",
          },
        ],
      },
    });

  // pagination props:
  const [paginationProps, setPaginationProps] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  // create data objects
  function createData(
    historyId: string,
    date: string,
    message: string,
    audience: string,
    messageType: string,
    successRate: number,
    from: string,
    bcc: string,
    subject: string,
    icon: JSX.Element
  ) {
    return {
      historyId,
      date,
      message,
      audience,
      messageType,
      successRate,
      from,
      bcc,
      subject,
      icon,
    };
  }

  // fetch the history details
  const fetchCommunications = async () => {
    setHistory({ ...history, loading: true });
    const fetchedCommunicationData = await communicationsApi.get(
      `${COMMUNICATIONS_API_PATHS.GET_COMMUNICATIONS.replace(
        "{campaignId}",
        campaignId
      )}${getPaginatedCommunicationHistoryQueryString(
        paginationProps.page, // page
        paginationProps.rowsPerPage // limit
      )}`
    );

    const rows: CommunicationsRowData[] = [];

    for (let communication of fetchedCommunicationData.communications) {
      const rowsData = createData(
        communication?._id,
        communication?.sentDate,
        communication?.message,
        communication?.recipientsCategory,
        communication?.messageType,
        communication?.successRate,
        communication?.from,
        communication?.bcc,
        communication?.subject,
        <div className="action-cell">
          <Tooltip title={`View ${communication?.messageType} Details`}>
            <InfoIcon />
          </Tooltip>
        </div>
      );
      rows.push(rowsData);
    }

    setHistory({
      loading: false,
      dataRows: rows,
      totalHistoryCount: fetchedCommunicationData.totalCount,
    });
  };

  // default side effect:
  useEffect(() => {
    if (campaignId) {
      fetchCommunications();
    }
  }, [paginationProps.page, paginationProps.rowsPerPage, campaignId]);

  // custom logic:
  const renderCommunicationHistoryDetails = (
    historyId,
    messageType,
    audience,
    successRate,
    message,
    from,
    bcc,
    subject
  ) => {
    setHistoryModalState({
      ...historyModalState,
      visible: true,
      messageType: messageType,
      historyId: historyId,
      data: {
        ...historyModalState.data,
        recipeintList: audience,
        successRate: successRate,
        message: message,
        from: from,
        bcc: bcc,
        subject: subject,
      },
    });
  };

  const closeHistoryDialogHandler = () => {
    setHistoryModalState({
      ...historyModalState,
      visible: false,
      historyId: "",
      messageType: "",
    });
  };

  const handlePaginationChangePage = (event: unknown, newPage: number) => {
    setPaginationProps({ ...paginationProps, page: newPage });
  };

  const handlePaginationChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaginationProps({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  return (
    <>
      <Typography variant="subtitle1">
        A list of all communications send for this campaign
      </Typography>
      <Box sx={[{ margin: "10px 0px" }]} />
      {history.loading && (
        <Card>
          <CardContent>
            <LoadingSpinner text="Retrieving Communication History" />
          </CardContent>
        </Card>
      )}
      {!history.dataRows.length && (
        <Card>
          <CardContent>
            <Typography className="no-entries" variant="subtitle1" gutterBottom>
              No History Found
            </Typography>
          </CardContent>
        </Card>
      )}
      {!history.loading && history?.dataRows?.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead sx={[{ backgroundColor: "primary" }]}>
              <TableRow>
                <TableCell>
                  <b>Date</b>
                </TableCell>
                <TableCell align="left">
                  <b>Audience</b>
                </TableCell>
                <TableCell align="left">
                  <b>Message Type</b>
                </TableCell>
                {/* <TableCell align="left">
                  <b>Success Rate (%)</b>
                </TableCell> */}
                <TableCell align="left">
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history?.dataRows?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {moment(row.date).format("HH:mm DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="left">{row.audience}</TableCell>
                  <TableCell align="left">{row.messageType}</TableCell>
                  {/* <TableCell align="left">
                    {(row.successRate * 100).toFixed(2)}
                  </TableCell> */}
                  <TableCell align="left">
                    <IconButton
                      onClick={() =>
                        renderCommunicationHistoryDetails(
                          row?.historyId,
                          row?.messageType,
                          row?.audience,
                          row?.successRate,
                          row?.message,
                          row?.from,
                          row?.bcc,
                          row?.subject
                        )
                      }
                      color="primary"
                      aria-label="action icon"
                    >
                      {row.icon}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={history.totalHistoryCount}
            rowsPerPage={paginationProps.rowsPerPage}
            page={paginationProps.page}
            onPageChange={handlePaginationChangePage}
            onRowsPerPageChange={handlePaginationChangeRowsPerPage}
            labelRowsPerPage="Rows/page"
          />
        </TableContainer>
      )}
      <div>
        <HistoryDialog
          historyModalState={historyModalState}
          handleClose={closeHistoryDialogHandler}
        />
      </div>
    </>
  );
};

export default History;
