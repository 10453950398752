import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Tab,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HistoryDetails from "../../../shared/communicationDialogs/historyTable/HistoryDetails";
import IHistoryDetailsModalState from "../../../../interfaces/historyDetailModal";
import useFetch from "../../../../hooks/useFetch";
import {
  API_PATHS,
  COMMUNICATION_LOGS_API_PATHS,
} from "../../../../common/ApiPaths";

import HTMLToComponent from "../../shared/HTMLToComponent";
import CustomTabs from "../../../shared/customTabs/CustomTabs";
import { CommunicationHistoryDetailTabs } from "../../../../common/enums/CommunicationHistoryDetailTabs";

interface IEmailFormProps {
  handleClose: () => void;
  historyModalState: IHistoryDetailsModalState;
}

//conditonally create a new interface incase of email and use it below conditionally
interface ICommunicationLogsRowData {
  date: string;
  firstName: string;
  surname: string;
  contact: string;
  success: boolean;
  errorMessage: string;
}

const HistoryDialog = (props: IEmailFormProps) => {
  // custom hooks:
  // fetch:
  // communications:
  const communicationLogsApi = useFetch(API_PATHS.COMMUNICATIONLOGS);

  // react hooks:
  // history:
  const [communicationLogs, setCommunicationLogs] = useState({
    dataRows: [
      {
        date: "",
        firstName: "",
        surname: "",
        contact: "",
        success: false,
        errorMessage: "",
      },
    ],
    loading: false,
  });

  // tab:
  const [tabValue, setTabValue] = useState<number>(0);

  // create data objects
  function createData(
    date: string,
    firstName: string,
    surname: string,
    contact: string,
    success: boolean,
    errorMessage: string
  ) {
    return {
      date,
      firstName,
      surname,
      contact,
      success,
      errorMessage,
    };
  }

  // fetch the history details
  const fetchCommunicationLogs = async () => {
    setCommunicationLogs({ ...communicationLogs, loading: true });
    const fetchedCommunicationLogsData = await communicationLogsApi.get(
      `${COMMUNICATION_LOGS_API_PATHS.GET_COMMUNICATION_LOGS.replace(
        "{communicationId}",
        props?.historyModalState?.historyId
      )}`
    );

    const rows: ICommunicationLogsRowData[] = [];

    for (let communicationLog of fetchedCommunicationLogsData) {
      const rowsData = createData(
        communicationLog?.sentDate,
        communicationLog?.firstName,
        communicationLog?.surname,
        communicationLog?.contact,
        communicationLog?.success,
        communicationLog?.errorMessage
      );
      rows.push(rowsData);
    }

    setCommunicationLogs({ dataRows: rows, loading: false });
  };

  // default useEffect hook
  useEffect(() => {
    if (props.historyModalState.historyId) {
      fetchCommunicationLogs();
    }
  }, [props?.historyModalState?.historyId]);

  const tabChangeHandler = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number, tab: string) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Dialog
        onClose={props.handleClose}
        open={props.historyModalState.visible}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "100%",
            },
          },
        }}
      >
        <DialogTitle>
          <b>Communication Log</b>
        </DialogTitle>
        <DialogContent>
          {props?.historyModalState?.messageType === "Email" ? (
            <>
              <Typography variant="subtitle1">
                <b>Subject:</b> {props?.historyModalState?.data?.subject}
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Typography variant="subtitle1">
            <b>Recipient List:</b>{" "}
            {props?.historyModalState?.data?.recipeintList}
          </Typography>
          {/* <Typography variant="subtitle1">
            <b>Success Rate:</b>{" "}
            {(props?.historyModalState?.data?.successRate * 100).toFixed(2)}%
          </Typography> */}
          {props?.historyModalState?.messageType === "Email" ? (
            <>
              <Typography variant="subtitle1">
                <b>From:</b> {props?.historyModalState?.data?.from}
              </Typography>
              <Typography variant="subtitle1">
                <b>Bcc:</b> {props?.historyModalState?.data?.bcc}
              </Typography>
            </>
          ) : props?.historyModalState?.messageType === "SMS" ? (
            <>
              <Typography variant="subtitle1">
                <b>From:</b> {props?.historyModalState?.data?.from}
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Box sx={{ width: "100%", marginTop: "10px" }}>
            <CustomTabs
              tabValue={tabValue}
              route={"communications/history"}
              tabChangeHandler={tabChangeHandler}
            >
              {Object.values(CommunicationHistoryDetailTabs)?.map(
                (tab, index) => (
                  <Tab label={tab} {...a11yProps(index, tab)} />
                )
              )}
            </CustomTabs>
          </Box>
          {tabValue === 0 && (
            <>
              <HistoryDetails
                communicationLogs={communicationLogs}
                messageType={props.historyModalState.messageType}
              />
            </>
          )}

          {tabValue === 1 && (
            <>
              <Box sx={[{ marginTop: "10px" }]} />
              {props?.historyModalState?.data?.message && (
                <HTMLToComponent
                  htmlString={props?.historyModalState?.data?.message}
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HistoryDialog;
