import ICampaign from '../../../../interfaces/campaign'
import { InputAdornment, Paper, TextField } from '@mui/material'
import CloudinaryUploadWidget from '../../shared/CloudinaryUploadWidget'
import ColorInput from '../../shared/colorInput/ColorInput'

interface InputProps {
	campaign: ICampaign
	setCampaign: (campaign: ICampaign) => void
}

const BrandingSection = (props: InputProps) => {

	const handleChange = (name, value) => {
		props.setCampaign({ ...props.campaign, [name]: value })
	}

	return (
		<div className="core-settings-section">
			<h2 className="subtitle">Branding.</h2>
			<span className="description">The look and feel the campaign.</span>
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="faviconUrl"
						name="faviconUrl"
						label="Favicon URL"
						variant="outlined"
						value={props.campaign.faviconUrl || ""}
						onChange={event => handleChange(event.target.name, event.target.value)}
						helperText={
							"The url for the favicon logo. Either use the icon to upload a new file or provide the url of an existing file hosted online. "
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<CloudinaryUploadWidget
										folderName={props.campaign.slug}
										allowMultipleUploads={false}
										functionOnComplete={uploadedUrlList => {
											if (uploadedUrlList.length) {
												handleChange("faviconUrl", uploadedUrlList[uploadedUrlList.length - 1])
											}
										}
										}
										uploadPreset="unsigned"
									/>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="hostingOrganisationLogoUrl"
						name="hostingOrganisationLogoUrl"
						label="Menu Bar Logo Url"
						variant="outlined"
						value={props.campaign.hostingOrganisationLogoUrl || ""}
						onChange={(event) => handleChange(event.target.name, event.target.value)}
						helperText="This is the logo in the header bar. Either use the icon to upload a new file or provide the url of an existing file hosted online. Suggested size: w120px x h40px."
						InputProps={{
							endAdornment:
								<InputAdornment position="start">
									<CloudinaryUploadWidget
										folderName={props.campaign.slug}
										allowMultipleUploads={false}
										functionOnComplete={(uploadedUrlList) => {
											if (uploadedUrlList.length) {
												handleChange("hostingOrganisationLogoUrl", uploadedUrlList[uploadedUrlList.length - 1])
											}
										}}
										uploadPreset="unsigned"
									/>
								</InputAdornment>
						}}
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="menuBarBackgroundColor"
						label="Menu Bar Background Colour"
						value={props.campaign.menuBarBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the background colour of the menu bar (not the header section)."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="menuBarFontColor"
						label="Menu Bar Font Colour"
						value={props.campaign.menuBarFontColor || ""}
						handleChange={handleChange}
						helperText="This will set the font colour of the menu bar (not the header section)."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="buttonBackgroundColor"
						label="CTA Button Background Colour"
						value={props.campaign.buttonBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the colour of the main 'VIEW THE LOTS' button."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="buttonFontColor"
						label="CTA Button Font Colour"
						value={props.campaign.buttonFontColor || ""}
						handleChange={handleChange}
						helperText="This will set the colour of the main 'VIEW THE LOTS' button."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="headerBackgroundColor"
						label="Header Background Colour"
						value={props.campaign.headerBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the background colour of the header section."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="headerFontColor"
						label="Header Font Colour"
						value={props.campaign.headerFontColor || ""}
						handleChange={handleChange}
						helperText="This will set the font colour of the header section."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="lotNumbersBackgroundColor"
						label="Lot Numbers Background Colour"
						value={props.campaign.lotNumbersBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the background colour of the lot numbers on the lots page."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="lotNumbersFontColor"
						label="Lot Numbers Font Colour"
						value={props.campaign.lotNumbersFontColor || ""}
						handleChange={handleChange}
						helperText="This will set the font colour of the lot numbers on the lots page."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="bodyBackgroundColor"
						label="Home Page Body Background Colour"
						value={props.campaign.bodyBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the background colour of the body of the home page."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="lotPageBackgroundColor"
						label="Lot Page Background Colour"
						value={props.campaign.lotPageBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the background color of the lot page."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="footerBackgroundColor"
						label="Footer Background Colour"
						value={props.campaign.footerBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the background colour of the footer."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="footerFontColor"
						label="Footer Font Colour"
						value={props.campaign.footerFontColor || ""}
						handleChange={handleChange}
						helperText="This will set the font colour of the footer."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="lotIconColor"
						label="Lot Icon Colour"
						value={props.campaign.lotIconColor || ""}
						handleChange={handleChange}
						helperText="This will set the font colour of the lot icons."
					/>
				</div>
			</Paper>
		</div>
	)
}

export default BrandingSection