import {  Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material"
import { IWinner } from "../../../../interfaces/winner"
import "./lotsWonSection.scss"
import ICampaign from "../../../../interfaces/campaign"
import useCommonFunctions from "../../../../hooks/useCommonFunctions"
import { Oval } from "react-loader-spinner"
import IGetWinnerResponse, { IWinnerBidAndLot } from "../../../../interfaces/winningBidResponse"
import IOrganisation from "../../../../interfaces/organisation"
import LotsWonActions from "./lotsWonActions.tsx/LotsWonActions"

interface InputProps {
	winner: IWinner
	campaign: ICampaign
	organisation: IOrganisation
	setCampaign: (campaign: ICampaign) => void
	winnerList: IWinner[]
	setWinnerList: (winnerList: IWinner[]) => void
	generateWinnerList: () => Promise<IWinner[]>
	isPaymentInProgress: boolean
	setIsPaymentInProgress: (boolean: boolean) => void
	isVoucherInProgress: boolean
	setIsVoucherInProgress: (boolean: boolean) => void
	dialogProperties: any
	setDialogProperties: (any: any) => void
	isPurchaseReport: boolean
}

const LotsWonSection = (props: InputProps) => {
	const commonFunctions = useCommonFunctions()

	return (
		<div className="lots-won-section">
			<Table className="lots-won-table">
				<TableHead>
					<TableRow>
						<TableCell>
							<span>Payment Status</span>
						</TableCell>
						<TableCell>
							<span>Voucher Status</span>
						</TableCell>
						<TableCell>
							<span className="center">{`${props.isPurchaseReport ? 'Item' : 'Lot'} #`} </span>
						</TableCell>
						<TableCell>
							<span>{`${props.isPurchaseReport ? 'Item' : 'Lot'} Name`}</span>
						</TableCell>
						<TableCell>
							<span className="center">Unit Cost</span>
						</TableCell>
						<TableCell>
							<span className="center">Optional Service Fee</span>
						</TableCell>
						{props.isPurchaseReport && (
							<TableCell>
								<span className="center"># Purchased</span>
							</TableCell>
						)}
						<TableCell>
							<span className="center">Total</span>
						</TableCell>
						<TableCell>
							<span className="center">Actions</span>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.winner.winnerItems.map((winnerItem: IGetWinnerResponse, winnerItemIndex) => (
							winnerItem.bidAndLotList.map((bidAndLot: IWinnerBidAndLot, index: number) => (
								<TableRow key={index + winnerItemIndex} >
									<TableCell>
										<span>
											<div className="payment-status-wrapper">
												{bidAndLot.isLoading === true ? (
													<Oval
														ariaLabel="loading-indicator"
														height={20}
														width={20}
														strokeWidth={5}
														color="#26C0B9"
														secondaryColor="#26C0B9"
													/>
												) : (
													commonFunctions.determineLotPaidStatusIcon(bidAndLot.winningBid.bidList[0])
												)}
											</div>
										</span>
									</TableCell>
									<TableCell>
										<span>
											<div className="payment-status-wrapper">
												{bidAndLot.isVoucherLoading === true ? (
													<Oval
														ariaLabel="loading-indicator"
														height={20}
														width={20}
														strokeWidth={5}
														color="#26C0B9"
														secondaryColor="#26C0B9"
													/>
												) : (
													commonFunctions.determineVoucherGeneratedStatusIcon(bidAndLot.winningBid.bidList[0])
												)}
											</div>
										</span>
									</TableCell>
									<TableCell>
										<span className={bidAndLot.winningBid.isCancelled ? "center cancelled" : "center"}>
											{bidAndLot.winningLot.orderNumber}
										</span>
									</TableCell>
									<TableCell>
										<span className={bidAndLot.winningBid.isCancelled ? "cancelled" : ""}>{bidAndLot.winningLot.title}</span>
									</TableCell>
									<TableCell>
										<span className={bidAndLot.winningBid.isCancelled ? "center cancelled" : "center"}>
											{new Intl.NumberFormat("en-US", {
												style: "currency",
												currency: props.campaign.currency ?? "GBP",
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(
												bidAndLot.winningBid.bidList[0].value
											)}
										</span>
									</TableCell>
									<TableCell>
										<span className={bidAndLot.winningBid.isCancelled ? "center cancelled" : "center"}>
											{bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage
												? bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage
												: 0}
											%
										</span>
									</TableCell>
									{props.isPurchaseReport && (
										<TableCell>
											<span className={bidAndLot.winningBid.isCancelled ? "center cancelled" : "center"}>
												{bidAndLot.winningBid.totalCount}
											</span>
										</TableCell>
									)}
									<TableCell>
										<span className={bidAndLot.winningBid.isCancelled ? "center cancelled" : "center"}>
											{bidAndLot.winningBid.totalCount > 1
												? new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: props.campaign.currency ?? "GBP",
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}).format(
													bidAndLot.winningBid.totalValue +
													commonFunctions.calculateServiceFee(
														bidAndLot.winningBid.totalValue,
														bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage
													)
												)
												: new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: props.campaign.currency ?? "GBP",
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}).format(
													bidAndLot.winningBid.bidList[0].value +
													commonFunctions.calculateServiceFee(
														bidAndLot.winningBid.bidList[0].value,
														bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage
													)
												)}
										</span>
									</TableCell>
									<TableCell>
										<LotsWonActions
											bidAndLot={bidAndLot}
											isPaymentInProgress={props.isPaymentInProgress}
											campaign={props.campaign}
											isPurchaseReport={props.isPurchaseReport}
											setIsPaymentInProgress={props.setIsPaymentInProgress}
											setDialogProperties={props.setDialogProperties}
											setIsVoucherInProgress={props.setIsVoucherInProgress}
											generateWinnerList={props.generateWinnerList}
											isVoucherInProgress={props.isVoucherInProgress}
											setWinnerList={props.setWinnerList}
											winner={props.winner}
											winnerList={props.winnerList}
										/>
									</TableCell>
								</TableRow>
							))
					))}
					<TableRow className="totals-row">
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						{props.isPurchaseReport && (<TableCell></TableCell>)}
						<TableCell></TableCell>
						<TableCell style={{ textAlign: "right" }}>
							<span>Total:</span>
						</TableCell>
						<TableCell>
							<span className="center">
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: props.campaign.currency ?? "GBP",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).format(commonFunctions.calculateTotalWinningBidValue(props.winner, true))}
							</span>
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</div>
	)
}

export default LotsWonSection