
import { useEditor, EditorContent } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import Toolbar from "./Toolbar"
import Underline from '@tiptap/extension-underline'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import CustomisableButton from './Extension'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fontSize: {
            /**
             * Set the font size
             */
            setFontSize: (size: string) => ReturnType;
            /**
             * Unset the font size
             */
            unsetFontSize: () => ReturnType;
        };
    }
}

export const TextStyleExtended = TextStyle.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            fontSize: {
                default: null,
                parseHTML: (element) => element.style.fontSize.replace('px', ''),
                renderHTML: (attributes) => {
                    if (!attributes['fontSize']) {
                        return {};
                    }
                    return {
                        style: `font-size: ${attributes['fontSize']}px`
                    };
                }
            }
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            setFontSize:
                (fontSize) =>
                    ({ commands }) => {
                        return commands.setMark(this.name, { fontSize: fontSize });
                    },
            unsetFontSize:
                () =>
                    ({ chain }) => {
                        return chain()
                            .setMark(this.name, { fontSize: null })
                            .removeEmptyTextStyle()
                            .run();
                    }
        };
    }
});

const Tiptap = ({ value, onChange, backgroundColor, isIncludeButtonOption }:
     { value: string, onChange: (richText: string) => void, backgroundColor: string, isIncludeButtonOption?: boolean }) => {

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Color,
            TextStyleExtended,
            FontFamily,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Link.configure({
                HTMLAttributes: {
                    style: "cursor: text",
                    class: "hyperlink"
                },
                openOnClick: false,
            }),
                
            CustomisableButton
        ],
        content: value,
        editorProps: {
            attributes: {
                style: `border: 1px solid #ccc; padding: 1rem; background-color: ${backgroundColor}`
            }
        },
        onUpdate({ editor }) {
            // onChange(editor.view.dom.innerHTML)
            onChange(editor.getHTML())
            // console.log(editor.getHTML())
        }

        //dependency array for editor, will update based on background color being changed.
    }, [backgroundColor])

    return (
        <div>
            <Toolbar editor={editor} />
            <EditorContent editor={editor} />

        </div>
    )
}

export default Tiptap