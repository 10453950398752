import './lotSettings.scss'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Button } from '@mui/material'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import { useHistory } from "react-router-dom";
import LotConfigSection from './sections/LotConfigSection'
import LotDescriptionSection from './sections/LotDescriptionSection'
import LotDetailsSection from './sections/LotDetailsSection'
import MediaSection from './sections/MediaSection'
import ChildLotsSection from './sections/ChildLotsSection'
import useLotValidation from './useLotValidation'
import TagsSection from './sections/TagsSection'
import ILot from '../../../interfaces/lot'
import ICampaign from '../../../interfaces/campaign'
import { CampaignContext } from '../../../context/CampaignContext'
import { API_PATHS, getFilteredLotsQueryString, getLotQueryString, LOT_API_PATHS } from '../../../common/ApiPaths'
import { LotMediaType } from '../../../common/enums/LotMediaType'
import { LotNumberUpdateOperation } from '../../../common/enums/LotNumberUpdateOperation'
import IOrganisation from '../../../interfaces/organisation'
import { OrganisationContext } from '../../../context/OrganisationContext'
import moment from "moment"
import { UserContext } from '../../../context/UserContext'
import IAuditLogEntryInfo from '../../../interfaces/auditLogEntryInfo'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import BackHyperlink from '../../shared/BackHyperLink'
import { useErrorBoundary } from 'react-error-boundary'

const LotSettings = ({ match, location }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    const [lot, setLot] = useState<ILot>({
        bidIncrement: 1,
        reservePrice: 1,
        costPrice: 0,
        availabilityCount: 1,
        startDateTime: new Date(),
        endDateTime: (moment(new Date()).add(1, 'days')).toDate()
    } as ILot)
    const [parentLot, setParentLot] = useState<ILot>({} as ILot)
    const [campaignLotList, setCampaignLotList] = useState<ILot[]>([])
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "", errorDetails: "" })
    // const [tagCsv, setTagCsv] = useState<string>("")
    const [orderNumberSetting, setOrderNumberSetting] = useState<string>('')
    const [parentImageSrc, setParentImageSrc] = useState<string>('')
    const [isChildLot, setIsChildLot] = useState<boolean>(false)
    const [tags, setTags] = useState<string[] | null>(null)
    const [isCampaignClosed, setIsCampaignClosed] = useState<boolean>(true)
    const [childLots, setChildLots] = useState<ILot[]>([])

    const lotApi = useFetch(API_PATHS.LOTS);
    let history = useHistory();
    const lotValidation = useLotValidation(lot, orderNumberSetting)
    const { showBoundary } = useErrorBoundary();

    const campaign: ICampaign = useContext(CampaignContext)
    const organisation: IOrganisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)
    const commonFunctions = useCommonFunctions()
    const fromVMS = useMemo(() => lot.catalogue && lot.catalogue.integrationId ? true : false, [lot]);

    const getLot = async (): Promise<void> => {
        setLoading(true)
        try {
            let data: ILot = await lotApi.get(`${LOT_API_PATHS.GET_LOT_BY_ID}${getLotQueryString(undefined, encodeURIComponent(match.params.lotSlug))}`)
            // setTagCsv(createTagCsv(data.tags))
            if (data.mediaList.length && data.mediaList[0]) {
                setParentImageSrc(data.mediaList[0].src);
            }
            setLot(data)
            setLoading(false)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const getCampaignLotList = async (): Promise<void> => {
        setLoading(true)
        try {
            let data: ILot[] = await lotApi.get(`${LOT_API_PATHS.GET_LOTS.replace('{campaignId}', campaign._id)}${getFilteredLotsQueryString(undefined, true)}`)
            setCampaignLotList(data)
            data.forEach((l: ILot) => {
                if (l.slug === match.params.parentLotSlug) {
                    setParentLot(l)
                }
                const fetchedTags: string[] = data.flatMap(x => x.tagList)
                setTags(fetchedTags)
                setLoading(false)
            })
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const childLotOnDragEnd = async ({ destination, source }) => {
        if (!destination) {
            return;
        }
        if (destination.index === source.index) {
            return;
        }

        const draggedLot = childLots[source.index];
        const newLots = [...childLots];
        newLots.splice(source.index, 1);
        newLots.splice(destination.index, 0, draggedLot);

        const lotsToBeUpdated = destination.index < source.index ? newLots.slice(destination.index, newLots.length) : newLots.slice(source.index, destination.index + 1);
        const sequenceStart = destination.index < source.index ? childLots[destination.index].orderNumber : childLots[source.index].orderNumber;
        // lots updated on view only
        let currentOrderNumber = sequenceStart;
        lotsToBeUpdated.forEach((lot) => {
            newLots[newLots.findIndex(x => x._id === lot._id)].orderNumber = currentOrderNumber;
            lot.orderNumber = currentOrderNumber;
            currentOrderNumber = (Math.round((currentOrderNumber + 0.1) * 10) / 10)
        });
        setChildLots(newLots);

        try {
            await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT_ORDER_SEQUENTIALLY}`,
                {
                    lotIds: lotsToBeUpdated.map(x => x._id),
                    sequenceStartNumber: (+sequenceStart),
                    auditLogEntryInfo: {
                        currentUserId: dBUser._id,
                        campaignId: campaign._id,
                        organisationId: organisation._id,
                    } as IAuditLogEntryInfo
                })
        }
        catch (err: any) {
            setDialogProperties({
                type: "ERROR",
                title: "Error",
                message: `Error in updating child lots order`,
                label: "FAILURE"
            })
            setIsConfirmationDialogOpen(true);
            getLot();
        }
    }

    const createLot = async (): Promise<void> => {

        if (lotValidation.validateInputs(fromVMS)) {
            let lotToCreate: ILot = {
                ...lot,
                // slug: `a${uuidv4()}`,
                campaignId: campaign._id,
                isHidden: false,
                isActive: true
            }

            if (lot.type === "PARENT") {
                if (parentImageSrc) {
                    lotToCreate.mediaList = [{ src: parentImageSrc, mediaType: LotMediaType.IMAGE }]
                } else {
                    lotToCreate.mediaList = []
                }
            } else if (isChildLot) {
                lotToCreate.parentLotId = parentLot._id
            }

            if (orderNumberSetting === "END") {
                let topOrderNumber = 0;

                if (isChildLot) {
                    parentLot && childLots.forEach(element => {
                        if (element.orderNumber > topOrderNumber) {
                            topOrderNumber = element.orderNumber
                        }
                    });
                } else {
                    campaignLotList.forEach(element => {
                        if (element.orderNumber > topOrderNumber) {
                            topOrderNumber = element.orderNumber
                        }
                    });
                }

                lotToCreate.orderNumber = isChildLot ? Math.round(((topOrderNumber === 0 ? parentLot.orderNumber : topOrderNumber) + (1 / 10)) * 10) / 10 /*Math.round((topOrderNumber===0 ? parentLot.orderNumber : topOrderNumber) + (1/10)) parseFloat(`${parentLot.orderNumber}.${topOrderNumber + 1}`)*/ : topOrderNumber + 1;
            }

            try {
                await lotApi.post(LOT_API_PATHS.CREATE_LOT, [lotToCreate], dBUser._id)
                if (isChildLot) {
                    history.push(`/organisations/${organisation.slug}/campaigns/${campaign.slug}/lots/${parentLot.slug}?isChildLotCreated=true`)
                } else {
                    history.push(`/organisations/${organisation.slug}/campaigns/${campaign.slug}/lots?isLotCreated=true`)
                }
            }
            catch (err: any) {
                setDialogProperties({ type: "ERROR", title: "Create failed.", message: `Your lot creation failed with some error`, errorDetails: err.message })
                setIsConfirmationDialogOpen(true)
            }
        } else {
            console.log("Create Lot - FAILED VALIDATION")
        }
    }

    const saveLot = async (): Promise<void> => {

        if (lotValidation.validateInputs(fromVMS)) {

            let lotToSave: ILot = { ...lot }

            if (lot.type === "PARENT") {
                if (parentImageSrc) {
                    lotToSave.mediaList = [{ src: parentImageSrc, mediaType: LotMediaType.IMAGE }]
                } else {
                    lotToSave.mediaList = []
                }
            }

            try {
                await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT}`, [lotToSave], dBUser._id)
                if (isChildLot) {
                    history.push(`/organisations/${organisation.slug}/campaigns/${campaign.slug}/lots/${parentLot.slug}?isChildLotSaved=true`)
                } else {
                    history.push(`/organisations/${organisation.slug}/campaigns/${campaign.slug}/lots?isLotSaved=true`)
                }
            }
            catch (err: any) {
                setDialogProperties({ type: "ERROR", title: "Save failed.", message: `Your lot save failed with some error`, errorDetails: err.message })
                setIsConfirmationDialogOpen(true)
            }
        } else {
            console.log("Save Lot - FAILED VALIDATION")
        }
    }

    const closeDialog = () => {
        const query = new URLSearchParams(location.search);
        if (query.get("isChildLotCreated") || query.get("isChildLotSaved")) {
            history.push(`/organisations/${organisation.slug}/campaigns/${campaign.slug}/lots/${lot.slug}`)
        }
        setIsConfirmationDialogOpen(false)
    }

    const increaseChildLotOrder = async (childLot: ILot): Promise<void> => {
        var lotToBeMoved: ILot = childLot;
        var lotToBeReplaced: ILot = childLots.filter(lotToBeReplaced => lotToBeReplaced.orderNumber === (Math.round((lotToBeMoved.orderNumber + 0.1) * 10) / 10))[0];

        if (lotToBeReplaced) {
            try {
                await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT_ORDER.replace('{operation}', LotNumberUpdateOperation.INCREASE)}`, {
                    "lotToBeMovedLotId": lotToBeMoved._id,
                    "lotToBeMovedOrderNumber": lotToBeMoved.orderNumber,
                    "lotToBeReplacedLotId": lotToBeReplaced._id,
                    "lotToBeReplacedOrderNumber": lotToBeReplaced.orderNumber,
                    "auditLogEntryInfo": {
                        currentUserId: dBUser._id,
                        campaignId: campaign._id,
                        organisationId: organisation._id,
                    } as IAuditLogEntryInfo
                })
                //Updating lot numbers in memory rather than GetLots() so no page refresh
                let updatedChildLotList = childLots.map((obj: ILot) => obj._id === lotToBeMoved._id ? { ...lotToBeMoved, orderNumber: (Math.round((lotToBeMoved.orderNumber + 0.1) * 10) / 10) } : obj)
                updatedChildLotList = updatedChildLotList.map((obj: ILot) => obj._id === lotToBeReplaced._id ? { ...lotToBeReplaced, orderNumber: (Math.round((lotToBeReplaced.orderNumber - 0.1) * 10) / 10) } : obj)
                setChildLots(updatedChildLotList.sort((a, b) => a.orderNumber - b.orderNumber))
            }
            catch (err: any) {
                showBoundary(err)
            }
        } else {
            try {
                lotToBeMoved.orderNumber = ((Math.round((lotToBeMoved.orderNumber + 0.1) * 10) / 10))
                await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT}`, [childLot], dBUser._id)
                let updatedChildLotList = childLots.map((obj: ILot) => obj._id === lotToBeMoved._id ? { ...lotToBeMoved, orderNumber: lotToBeMoved.orderNumber } : obj)
                setChildLots(updatedChildLotList.sort((a, b) => a.orderNumber - b.orderNumber))
            }
            catch (err: any) {
                showBoundary(err)
            }
        }
    }

    const decreaseChildLotOrder = async (childLot: ILot): Promise<void> => {
        var lotToBeMoved: ILot = childLot;
        var lotToBeReplaced: ILot = childLots.filter(lotToBeReplaced => lotToBeReplaced.orderNumber === ((Math.round((lotToBeMoved.orderNumber - 0.1) * 10) / 10)))[0];

        if (lotToBeReplaced) {
            try {
                await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT_ORDER.replace('{operation}', LotNumberUpdateOperation.DECREASE)}`, {
                    "lotToBeMovedLotId": lotToBeMoved._id,
                    "lotToBeMovedOrderNumber": lotToBeMoved.orderNumber,
                    "lotToBeReplacedLotId": lotToBeReplaced._id,
                    "lotToBeReplacedOrderNumber": lotToBeReplaced.orderNumber,
                    "auditLogEntryInfo": {
                        currentUserId: dBUser._id,
                        campaignId: campaign._id,
                        organisationId: organisation._id,
                    } as IAuditLogEntryInfo
                })
                let updatedChildLotList = childLots.map((obj: ILot) => obj._id === lotToBeMoved._id ? { ...lotToBeMoved, orderNumber: (Math.round((lotToBeMoved.orderNumber - 0.1) * 10) / 10) } : obj)
                updatedChildLotList = updatedChildLotList.map((obj: ILot) => obj._id === lotToBeReplaced._id ? { ...lotToBeReplaced, orderNumber: (Math.round((lotToBeReplaced.orderNumber + 0.1) * 10) / 10) } : obj)
                setChildLots(updatedChildLotList.sort((a, b) => a.orderNumber - b.orderNumber))
            }
            catch (err: any) {
                showBoundary(err)
            }
        } else {
            if (parseFloat((lotToBeMoved.orderNumber % 1).toFixed(1)) > 0.1) {
                lotToBeMoved.orderNumber = ((Math.round((lotToBeMoved.orderNumber - 0.1) * 10) / 10))
                try {
                    await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT}`, [childLot], dBUser._id)

                    let updatedChildLotList = childLots.map((obj: ILot) => obj._id === lotToBeMoved._id ? { ...lotToBeMoved, orderNumber: lotToBeMoved.orderNumber } : obj)
                    setChildLots(updatedChildLotList.sort((a, b) => a.orderNumber - b.orderNumber))
                }
                catch (err: any) {
                    showBoundary(err)
                }
            } else {
                console.log("Order number must be greater than 0");
            }
        }
    }

    const duplicateChildLot = async (lotId: string): Promise<void> => {
        let lotToBeCopied: ILot = childLots.find(x => x._id === lotId) || {} as ILot
        try {
            await lotApi.post(LOT_API_PATHS.CREATE_LOT, [{ ...lotToBeCopied, _id: null }], dBUser._id)
            getCampaignLotList()
        }
        catch (err: any) {
            showBoundary(err)
        }
    }

    const deleteChildLot = async (lotId: string, lotTitle:string): Promise<void> => {
        try {
            await lotApi.del(`${LOT_API_PATHS.DELETE_LOT}`, {
                lotIdAndTitleList: [{id:lotId, title:lotTitle}],
                campaignId: campaign._id
            }, dBUser._id)
            setDialogProperties({ type: "SUCCESS", title: "Child Lot Deleted", message: "Child lot deleted successfully.", label: "LOT DELETED" })
            setIsConfirmationDialogOpen(true)
            let tempChildLots: any[] = [...childLots]
            tempChildLots.splice(childLots.findIndex(x => x._id === lotId), 1)
            setChildLots(tempChildLots)
        }
        catch (err: any) {
            showBoundary(err)
        }
    }

    useEffect(() => {
        if (lot && lot.type === "PARENT") {
            let children = campaignLotList.filter(x => x.parentLotId && x.parentLotId !== null && x.parentLotId === lot._id)
            setChildLots(children)
        }
        if (match.params.parentLotSlug) {
            let children = campaignLotList.filter(x => x.parentLotId && x.parentLotId !== null && x.parentLotId === parentLot._id)
            setChildLots(children)
        }
    }, [campaignLotList, lot, parentLot])

    useEffect(() => {
        if (!loading && !(campaign && campaign.slug)) {
            showBoundary('Error while fetching campaign')
            setLoading(false)
        }
        else if (campaign.slug) {
            setIsCampaignClosed(campaign.isClosed)
            setError('')
            getCampaignLotList()
            setLoading(false)

            if (match.params.parentLotSlug) {
                setIsChildLot(true)
            }

            if (match.params.lotSlug === "new") {
                setOrderNumberSetting("END")
                //initialise the lists so that the empty sections display.
                setLot({ ...lot, slug: 'new', campaignId: campaign._id, mediaList: [] })
            } else {
                setOrderNumberSetting("EXISTING")
                getLot()
            }

            const query = new URLSearchParams(location.search);
            if (query.get("isChildLotCreated")) {
                setDialogProperties({ type: "SUCCESS", title: "Child Lot Created.", message: "A new child lot has successfully been created.", label: "SUCCESS" })
                setIsConfirmationDialogOpen(true)
            } else if (query.get("isChildLotSaved")) {
                setDialogProperties({ type: "SUCCESS", title: "Child Lot Saved.", message: "Your child lot has successfully been saved.", label: "SUCCESS" })
                setIsConfirmationDialogOpen(true)
            }
        }
    }, [campaign])

    if (loading) {
        return (
            <Loading />
        )
    }

    // if (error) {
    //     return (
    //         <p>{error}</p>
    //     )
    // }

    return (
        <div className="lot-settings-page">
            {isChildLot &&
                <BackHyperlink onClickFunction={() => history.push(`${`/organisations/${organisation.slug}/campaigns/${match.params.campaignSlug}/lots/${match.params.parentLotSlug}`}`)} text={'Back to parent lot'} />
            }
            {lot.slug &&
                <>
                    <LotConfigSection
                        lot={lot}
                        campaignLotList={campaignLotList}
                        setLot={setLot}
                        isNewLot={match.params.lotSlug === "new"}
                        orderNumberSetting={orderNumberSetting}
                        setOrderNumberSetting={setOrderNumberSetting}
                        isChildLot={isChildLot}
                        validationObject={lotValidation.validationObject}
                        isCampaignClosed={isCampaignClosed}
                        fromVMS={fromVMS}
                    />

                    {lot.type &&
                        <>
                            <LotDetailsSection
                                lot={lot}
                                setLot={setLot}
                                campaign={campaign}
                                parentImageSrc={parentImageSrc}
                                setParentImageSrc={setParentImageSrc}
                                isChildLot={isChildLot}
                                validationObject={lotValidation.validationObject}
                                isCampaignClosed={isCampaignClosed}
                                fromVMS={fromVMS}
                            />

                            {lot && tags && !isChildLot &&
                                <TagsSection
                                    tags={tags}
                                    object={lot}
                                    setObject={setLot}
                                    isCampaignClosed={isCampaignClosed}
                                    objectName={'Lot'}
                                    fromVMS={fromVMS}
                                />
                            }

                        </>
                    }

                    {lot.type && lot.type !== "PARENT" &&
                        <>
                            <LotDescriptionSection
                                object={lot}
                                setObject={setLot}
                                isReadOnly={isCampaignClosed || fromVMS}
                                objectName={'Lot'}
                            />

                            {lot.mediaList &&
                                <MediaSection
                                    lot={lot}
                                    campaign={match.params.campaignSlug}
                                    setLot={setLot}
                                    isNewLot={match.params.lotSlug === "new"}
                                    lotValidation={lotValidation}
                                    isCampaignClosed={isCampaignClosed}
                                    fromVMS={fromVMS}
                                    parentLotSlug={parentLot.slug}
                                />
                            }
                        </>
                    }
                    {lot.type && lot.type === "PARENT" && match.params.lotSlug !== "new" &&
                        <ChildLotsSection
                            lot={lot}
                            childLots={childLots}
                            parentLotSlug={match.params.lotSlug}
                            campaignSlug={campaign.slug}
                            setLot={setLot}
                            decreaseChildLotOrder={decreaseChildLotOrder}
                            increaseChildLotOrder={increaseChildLotOrder}
                            deleteChildLot={deleteChildLot}
                            duplicateChildLot={duplicateChildLot}
                            isCampaignClosed={isCampaignClosed}
                            childLotOnDragEnd={childLotOnDragEnd}
                        />
                    }
                </>
            }

            {isCampaignClosed ? (
                <div className="fixed-action-section">
                    <div style={{ width: 240 }} className="hide-on-mobile"></div>
                    <span className="campaign-closed-notice">Campaign closed - lots can no longer be edited.</span>
                </div>
            ) : (
                <div className="fixed-action-section">
                    <div></div>
                    <div className="save-button-wrapper">
                        {!lotValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                        {match.params.lotSlug !== "new" && <Button className="gg-button" onClick={saveLot}>Save Lot</Button>}
                        {match.params.lotSlug === "new" && <Button className="gg-button" onClick={createLot}>Create Lot</Button>}
                    </div>
                </div>
            )}

            <ConfirmationDialog
                handleClose={closeDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                errorDetails={dialogProperties.errorDetails}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
                primaryButton={<Button onClick={() => closeDialog()} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
                    Ok
                </Button>}
            />
        </div>
    )
}

export default LotSettings