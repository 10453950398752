import React, { useContext } from "react"
import "./adminFooter.scss"
import moment from "moment"
import { OrganisationContext } from "../../../context/OrganisationContext"
interface InputProps {

}

const AdminFooter = (props: InputProps) => {
    const organisation = useContext(OrganisationContext)
    return (
        <section className="admin-footer-section">
            <div className="left-content">
                {/* <div className="ghost-div"></div> */}
                {/* <span>The Marriage Bureau is a brand that is wholly owned and operated by <a target="_blank" rel="noreferrer" href='https://www.wealthholdings.co.uk'>Wealth Holdings Inc Ltd</a>.</span> */}
                {/* <a target="_blank" rel="noreferrer" href='https://www.wealthholdings.co.uk'>Wealth Holdings Inc Lts.</a> */}
                {/* <Link to={'/privacy-policy'}>Privacy Policy</Link> */}


            </div>
            <div className="middle-content">
                {/* <Link to={'/'}>
                    <img alt="" src={Logo} />
                </Link> */}
                <span>© {moment().format('YYYY')} Going Gone</span>
                {/* <br/> */}
                {/* <span>Connecting Global & Local Entrepreneurs with Angel Investors</span>  */}

            </div>
            <div className="right-content">
                <div className="social-wrapper">
                    {/* <span>© 2021 Going Gone</span> */}
                    {/* <a href='https://www.facebook.com' target="_blank" rel="noreferrer"><img className="social-icon" alt="" src={facebookImage} /></a> */}
                    {/* <a href='https://www.instagram.com' target="_blank" rel="noreferrer"><img className="social-icon" alt="" src={instagramImage} /></a> */}
                    {/* <a href='https://www.linkedin.com/company/70573670' target="_blank" rel="noreferrer"><img className="social-icon" alt="" src={linkedInImage} /></a> */}
                </div>

                <div className="terms-wrapper">
                    <a href={`/organisations/${organisation.slug}/privacyPolicy`} rel="noreferrer">Privacy Policy</a>
                </div>

            </div>
        </section>
    )
}

export default AdminFooter