import React, { useContext, useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import useFetch from "../../../../hooks/useFetch"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { API_PATHS, CAMPAIGN_API_PATHS, getValidCampaignsQueryString } from "../../../../common/ApiPaths";
import useCommonFunctions from "../../../../hooks/useCommonFunctions";
import { UserContext } from "../../../../context/UserContext";
interface InputProps {
    isDialogOpen: boolean,
    handleClose: (isChanges: boolean, campaignId: string) => void,
    currentCampaignId: string
}

const CopyLotsDialog = (props: InputProps) => {

    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
    const { auth0User } = useContext(UserContext);

    const commonFunctions = useCommonFunctions()

    const handleChange = (campaignOption) => {
        setError(campaignOption === null);
        setSelectedCampaignId(campaignOption?.value || '');
    }

    const getCampaigns = async (): Promise<void> => {
        const userId = await commonFunctions.getUserIdFromAuth0Id(encodeURIComponent(String(auth0User!.sub)))
        const result = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_VALID_CAMPAIGNS}${getValidCampaignsQueryString(userId, undefined, 'true')}`)
        const filteredCampaigns = result.filter((a) => a._id !== props.currentCampaignId)
        let options = filteredCampaigns.map(
            (a) => {
                return {
                    value: a._id,
                    label: a.name,
                    slug: a.slug
                };
            }
        );
        setCampaigns(options)
    }

    const handleClose = (isChanges: boolean) => {
        if (isChanges) {
            if (selectedCampaignId === '') {
                setError(true);
            }
            else {
                setError(false);
                props.handleClose(isChanges, selectedCampaignId);
            }
        }
        else {
            setError(false);
            props.handleClose(isChanges, selectedCampaignId);
        }
    }

    useEffect(() => {
        if (props.currentCampaignId) {
            getCampaigns();
        }
    }, [props.currentCampaignId]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={() => props.handleClose(false, selectedCampaignId)}
                aria-labelledby="form-dialog-title"
                className="lot-number-dialog"
            >
                <DialogTitle id="form-dialog-title">Copy Lots</DialogTitle>
                <DialogContent>
                    <span className="intro-text">Please select the campaign to which you want to copy the selected lots.</span>
                    <Autocomplete
                        id="combo-box-demo"
                        onChange={(event, value) => handleChange(value)}
                        options={campaigns}
                        renderInput={(params) => <TextField {...params} label="Campaign" error={error} helperText={error ? 'No Campaign selected' : ''} />}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} >
                        Cancel
                    </Button>
                    <Button onClick={() => handleClose(true)} className="gg-button">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CopyLotsDialog;