import React from 'react'
import './errorButtonFab.scss'
import Fab from "@mui/material/Fab"
import ErrorIcon from '@mui/icons-material/Error'

const ErrorButtonFab = ({ onClickFunction }) => {

    return (
        <div className='error-button-fab-wrapper show'>
            <Fab aria-label="add" style={{ backgroundColor: '#FF6B6B' }} onClick={() => onClickFunction()}>
                <ErrorIcon style={{ color: 'white' }} />
            </Fab>

        </div>
    )
}

export default ErrorButtonFab