export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isValidPhoneNumber(phoneNumber: string) {
  return /^[0-9()]*$/.test(phoneNumber);
}

export function isValidName(firstName: string) {
  // Regular expression to match alphanumeric characters
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  // Regular expression to match a phone number without a leading '+'
  const phoneNumberRegex = /^[^+]\d+$/;
  // Emoji regex
  const emojiRegex =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
  // Special character regex (matches any non-alphanumeric character)
  const specialCharRegex = /[^a-zA-Z0-9]/;

  // Check if the input contains an emoji or a special character
  if (emojiRegex.test(firstName) || specialCharRegex.test(firstName)) {
    return false; // Invalid input
  }

  // Check if the input is between 4 to 11 characters and contains at least one letter
  if (/[a-zA-Z]/.test(firstName)) {
    // Check if the input consists of alphanumeric characters only
    if (alphanumericRegex.test(firstName)) {
      return true; // Valid input
    }
  }

  // Check if the input is a phone number without a leading '+'
  if (phoneNumberRegex.test(firstName)) {
    return false; // Valid input
  }

  return false; // Invalid input
}

export function isValidCommunicationFromField(from: string) {
  // Regular expression to match alphanumeric characters
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  // Regular expression to match a phone number without a leading '+'
  const phoneNumberRegex = /^[^+]\d+$/;
  // Emoji regex
  const emojiRegex =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

  // Check if input is less than 4 characters or greater than 11
  if (from.length < 4 || from.length > 11) {
    return false;
  }

  // Check if the input contains an emoji
  if (emojiRegex.test(from)) {
    return false; // Invalid input
  }

  // Check if the input is between 4 to 11 characters and contains at least one letter
  if (/[a-zA-Z]/.test(from)) {
    // Check if the input consists of alphanumeric characters only
    if (alphanumericRegex.test(from)) {
      return true; // Valid input
    }
  }

  // Check if the input is a phone number without a leading '+'
  if (phoneNumberRegex.test(from)) {
    return true; // Valid input
  }

  return false; // Invalid input
}
