import React, { useState } from "react"
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import DoneSharpIcon from '@mui/icons-material/DoneSharp'
import './copyToClipboardLink.scss'

interface InputProps {
    textToCopy: string
}

const CopyToClipboardLink = (props: InputProps) => {
    const [clicked, setClicked] = useState<boolean>(false)

    const clickClipboardIcon = () => {
        navigator.clipboard.writeText(props.textToCopy).then(() => {
            setClicked(true)
            setTimeout(() => {
                setClicked(false)
            }, 1500);
        })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <span className="copyable-icon-and-text" onClick={clickClipboardIcon}>
            <span className='copyable-text'>{`${props.textToCopy}`}</span>
            &nbsp;
            {clicked ?
                <DoneSharpIcon className='green' />
                :
                <ContentPasteIcon className='clipboard-icon' />
            }
        </span>
    )
}

export default CopyToClipboardLink;