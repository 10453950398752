import React, { useState } from "react";

import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import IRecipientsList from "../../../../interfaces/fetchedRecipientsList";

import { RecipientCategories } from "../../../../common/enums/CommunicationRecipientCategories";

import RecipientsDialog from "./recipients/RecipientsDialog";
import IValidationObject from "../../../../interfaces/validationObject";
import "./messaging.scss";

interface IMessagingProps {
  messageLabel: string;
  recipientsLabel: string;
  getValidation: (args: string) => IValidationObject;
  fetchedRecipients: IRecipientsList;
  selectedMessageService: string;
  setSelectedMessageService: React.Dispatch<React.SetStateAction<string>>;
  selectedRecipientsCategory: string;
  fetchRecipientsCategoryHandler: (arg: string) => void;
  communicationValidation: {
    validationFields: IValidationObject[];
    setValidationFields: React.Dispatch<
      React.SetStateAction<IValidationObject[]>
    >;
    isValidationPassed: boolean;
    setIsValidationPassed: React.Dispatch<React.SetStateAction<boolean>>;
    validateInputs: () => boolean;
    resetValidationFields: () => void;
    getFieldValidation: (name: string, value: string) => void;
  };
  testCommunicationValidation: {
    validationFields: IValidationObject[];
    setValidationFields: React.Dispatch<
      React.SetStateAction<IValidationObject[]>
    >;
    isValidationPassed: boolean;
    setIsValidationPassed: React.Dispatch<React.SetStateAction<boolean>>;
    validateInputs: () => boolean;
    resetValidationFields: () => void;
    getFieldValidation: (name: string, value: string) => void;
    preValidateInputs: () => void;
  };
}

const Messaging = (props: IMessagingProps) => {
  // react hooks:
  // states
  const [userListModalState, setUserListModalState] = useState({
    list: [],
    visible: false,
  });

  // custom logic
  // update communication type
  const updateMessageTypeHandler = (event: SelectChangeEvent) => {
    props.communicationValidation.resetValidationFields();
    props.testCommunicationValidation.resetValidationFields();
    props.setSelectedMessageService(event.target.value as string);
  };

  // update recipients based on selected category
  const updateRecipientsHandler = (event: SelectChangeEvent) => {
    setUserListModalState({ ...userListModalState, visible: false });
    props.fetchRecipientsCategoryHandler(event?.target?.value as string);
  };

  // display users list
  const renderUsersListModal = () => {
    if (props.selectedRecipientsCategory.length) {
      setUserListModalState({ ...userListModalState, visible: true });
    }
  };

  // close recipients modal
  const handleClose = () => {
    setUserListModalState({ ...userListModalState, visible: false });
  };

  // conditional checks
  // recipients data
  const recipientsData =
    props.selectedRecipientsCategory ===
      RecipientCategories.ALLUSERS
      ? props.fetchedRecipients.allUsers
      : props.selectedRecipientsCategory ===
        RecipientCategories.CURRENTWINNERS
        ? props.fetchedRecipients.campaignWinners
        : props.selectedRecipientsCategory ===
          RecipientCategories.CURRENTOUTBIDUSERS
          ? props.fetchedRecipients.usersWithLosingBids
          : [];

  return (
    <>
      <Box sx={{ width: "50%" }}>
        <FormControl fullWidth>
          <InputLabel id="message-type-select-label">
            {props.messageLabel}
          </InputLabel>
          <Select
            label={props.messageLabel}
            id="message-type-select"
            value={props.selectedMessageService}
            labelId="message-type-select-label"
            onChange={updateMessageTypeHandler}
          >
            <MenuItem value={"SMS"}>SMS</MenuItem>
            <MenuItem value={"Email"}>Email</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <br />
      <Box sx={{ width: "50%" }}>
        <FormControl
          fullWidth
          error={!props.getValidation("recipients-category").isValid}
        >
          <InputLabel id="recipients-category">
            {props.recipientsLabel}
          </InputLabel>
          <Select
            id="recipients-category"
            name="recipients-category"
            label="Recipients"
            value={props.selectedRecipientsCategory}
            labelId="recipients-category"
            onChange={updateRecipientsHandler}
          >
            {props.selectedMessageService === "Email"
              ? Object.values(RecipientCategories)?.map(
                (recipientCategory, index) => {
                  return (
                    <MenuItem value={recipientCategory} key={index}>
                      {recipientCategory}
                    </MenuItem>
                  );
                }
              )
              : Object.values(RecipientCategories)
                .map((recipientCategory, index) => {
                  return (
                    <MenuItem value={recipientCategory} key={index}>
                      {recipientCategory}
                    </MenuItem>
                  );
                })}
          </Select>
          <Typography
            sx={[
              !props.getValidation("recipients-category").isValid
                ? {
                  color: "#d32f2f",
                  fontWeight: 400,
                  fontSize: "0.75rem",
                  lineHeight: 1.66,
                  letterSpacing: "0.03333em",
                  textAlign: "left",
                  marginTop: "3px",
                  marginRight: "14px",
                  marginBottom: 0,
                  marginLeft: "14px",
                }
                : {},
            ]}
            variant="caption"
          >
            {props.getValidation("recipients-category").isValid
              ? ""
              : props.getValidation("recipients-category").validationMessage}
          </Typography>
        </FormControl>
        {props.selectedRecipientsCategory ? (
          recipientsData.length > 0 ? (
            <LoadingButton
              variant="text"
              loadingIndicator="Fetching…"
              onClick={renderUsersListModal}
              loading={
                props.fetchedRecipients.allUsersLoading ||
                props.fetchedRecipients.campaignWinnersLoading ||
                props.fetchedRecipients.usersWithLosingBidsLoading
              }
            >
              View current list
            </LoadingButton>
          ) : props.selectedRecipientsCategory &&
            recipientsData.length === 0 &&
            !props.fetchedRecipients.allUsersLoading &&
            !props.fetchedRecipients.campaignWinnersLoading &&
            !props.fetchedRecipients.usersWithLosingBidsLoading ? (
            <Box className="no-recipients-found-box">
              <Typography variant="caption">
                No recipients found for the selected category
              </Typography>
            </Box>
          ) : (
            <></>
          )
        ) : null}
      </Box>
      <div>
        <RecipientsDialog
          handleClose={handleClose}
          recipients={recipientsData}
          visible={userListModalState.visible}
          selectedRecipientsCategory={props.selectedRecipientsCategory}
        />
      </div>
    </>
  );
};

export default Messaging;
