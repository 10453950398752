import { useState } from "react";
import { LotType } from "../../../common/enums/LotType";
import { PricingOption } from "../../../common/enums/PricingOption";
import ICatalogueItem from "../../../interfaces/catalogueItem";
import { ITransferDetail } from "../../../interfaces/transferDetails";
import IValidationObject from "../../../interfaces/validationObject";


const useTransferDetailsValidation = (transferDetail: ITransferDetail, currency: string) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const clearValidation = () => {
        setValidationObject([
            { name: "campaignId", isValid: true, validationMessage: "" },
            { name: "lotType", isValid: true, validationMessage: "" },
            { name: "pricing", isValid: true, validationMessage: "" },
            { name: "fixedAmount", isValid: true, validationMessage: "" },
            { name: "startLotOrderNumber", isValid: true, validationMessage: "" },
            { name: "availabilityCount", isValid: true, validationMessage: "" },
            { name: "bidIncrement", isValid: true, validationMessage: "" },
        ])
        setIsValidationPassed(true)
    }

    const validateInputs = (items: ICatalogueItem[]): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "campaignId", isValid: true, validationMessage: "" },
            { name: "lotType", isValid: true, validationMessage: "" },
            { name: "pricing", isValid: true, validationMessage: "" },
            { name: "fixedAmount", isValid: true, validationMessage: "" },
            { name: "startLotOrderNumber", isValid: true, validationMessage: "" },
            { name: "availabilityCount", isValid: true, validationMessage: "" },
            { name: "bidIncrement", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (transferDetail.campaignId === "" || typeof (transferDetail.campaignId) === "undefined") {
            setFailedValidation("campaignId", "Please select the campaign.")
        }

        if (!Object.keys(LotType).includes(transferDetail.lotType) || typeof (transferDetail.lotType) === "undefined") {
            setFailedValidation("lotType", "Please select lot type.")
        }

        if (!Object.keys(PricingOption).includes(transferDetail.pricing) || typeof (transferDetail.pricing) === "undefined") {
            setFailedValidation("pricing", "Please select the type of pricing.")
        }

        if (typeof (transferDetail.fixedAmount) === "undefined") {
            setFailedValidation("fixedAmount", "Please enter a fixed amount.")
        }

        if (typeof (transferDetail.startLotOrderNumber) === "undefined" || transferDetail.startLotOrderNumber <= 0) {
            setFailedValidation("startLotOrderNumber", "Lot order number should be greater than or equal to 1.")
        }

        if (transferDetail.lotType !== "BUYITNOW" && transferDetail.lotType !== "PARENT" && transferDetail.lotType !== "SEALED" && (typeof (transferDetail.bidIncrement) === "undefined" || transferDetail.bidIncrement.toString().length < 1 || transferDetail.bidIncrement < 1)) {
            setFailedValidation("bidIncrement", "Please set a bid increment for the lot.")
        }

        if ((transferDetail.lotType === "BUYITNOW" || transferDetail.lotType === "SEALED" || transferDetail.lotType === "COMPETITIVE") && (typeof (transferDetail.availabilityCount) === "undefined" || transferDetail.availabilityCount && transferDetail.availabilityCount.toString().length < 1 || transferDetail.availabilityCount < 1)) {
            setFailedValidation("availabilityCount", "Please select the number of items available to sell.")
        }

        if (transferDetail.pricing === PricingOption.FIXED) {
            const maxCP = Math.max(...items.map(x => x.costPrice))
            if (transferDetail.fixedAmount < maxCP) {
                setFailedValidation("fixedAmount", `Fixed amount must be greater than the highest cost price of the selected items (${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(maxCP)}).`)
            }
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        clearValidation,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default useTransferDetailsValidation