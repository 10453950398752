import BigScreenSettings from "../components/admin/bigScreenSettings/BigScreenSettings";
import AdminHome from "../components/admin/adminHome/AdminHome";
import UserReport from "../components/admin/userReport/UserReport";
import OrganisationList from "../components/admin/organisationList/OrganisationList";
import OrganisationSettings from "../components/admin/organisationSettings/OrganisationSettings";
import AuditLog from "../components/admin/auditLog/AuditLog";
import PrivacyPolicy from "../components/shared/PrivacyPolicy";
import Communications from "../components/admin/communications/Communications";
import CampaignSettings from "../components/admin/campaignSettings/CampaignSettings";
import LotList from "../components/admin/lotList/LotList";
import LotSettings from "../components/admin/lotSettings/LotSettings";
import BidReport from "../components/admin/bidReport/BidReport";
import WinnerReport from "../components/admin/winnerReport/WinnerReport";
import BidderReport from "../components/admin/bidderReport/BidderReport";
import LotReport from "../components/admin/lotReport/LotReport";
import CustomPage from "../components/customPage/CustomPage";
import CustomPages from "../components/admin/customPages/CustomPages";

export const organisationRoutes = [
    {
        path: "/organisations",
        component: OrganisationList,
        exact: true,
        title: "Campaign Settings",
    },
    {
        path: "/organisations/new",
        component: OrganisationSettings,
        exact: true,
        title: "New Organisation",
    },
    {
        path: "/organisations/:organisationSlug",
        component: AdminHome,
        exact: true,
        title: "Organisation Home",
    },
    {
        path: "/organisations/:organisationSlug/privacyPolicy",
        component: PrivacyPolicy,
        exact: false,
        title: "Privacy Policy",
    },
];

export const adminRoutes = [
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug",
        component: CampaignSettings,
        exact: true,
        title: "Campaign Settings",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/lots",
        component: LotList,
        exact: true,
        title: "Lot List",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/custom-pages",
        component: CustomPages,
        exact: true,
        title: "Custom Pages",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/lots/:lotSlug",
        component: LotSettings,
        exact: true,
        title: "Lot Settings",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/lots/:parentLotSlug/children/:lotSlug",
        component: LotSettings,
        exact: true,
        title: "Child Lot Settings",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/bid-report",
        component: BidReport,
        exact: true,
        title: "Bid Report",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/lot-report",
        component: LotReport,
        exact: true,
        title: "Lot Report",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/winner-report",
        component: WinnerReport,
        exact: true,
        title: "Winner Report",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/purchase-report",
        component: WinnerReport,
        exact: true,
        title: "Purchaser Report"
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/bidder-report",
        component: BidderReport,
        exact: true,
        title: "Bidder Report",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/user-report",
        component: UserReport,
        exact: true,
        title: "User Report",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/big-screen/settings",
        component: BigScreenSettings,
        exact: false,
        title: "Big Screen",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/audit-log",
        component: AuditLog,
        exact: false,
        title: "Audit Log",
    },
    {
        path: "/organisations/:organisationSlug/campaigns/:campaignSlug/communications",
        component: Communications,
        exact: false,
        title: "Communications",
    },
];