import { MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material"
import { Editor } from '@tiptap/react';
import { useEffect, useState } from "react";

const FontStyleComboBox = ({ editor, activeFontStyle }: { editor: Editor | null, activeFontStyle: string }) => {
    const [fontStyle, setFontStyle] = useState<string>("arial");

    const handleChange = (event: SelectChangeEvent) => {
        setFontStyle(event.target.value);
    };

    useEffect(() => {
        if (activeFontStyle){
            setFontStyle(activeFontStyle)
        }
    }, [activeFontStyle]);


    return (
        <Tooltip title="Font Family" enterDelay={600}>
            <Select
                id="select-font-style"
                value={fontStyle}
                onChange={handleChange}
            >
                <MenuItem
                    value={"arial"}
                    onClick={() => editor!.chain().focus().setFontFamily('arial').run()}
                    style={{ fontFamily: "Arial" }}
                >
                    Arial
                </MenuItem>
                <MenuItem
                    value={"arial black, sans-serif"}
                    onClick={() => editor!.chain().focus().setFontFamily('arial black, sans-serif').run()}
                    style={{ fontFamily: "arial black, sans-serif" }}
                >
                    Arial Black
                </MenuItem>
                <MenuItem
                    value={"Comic Sans MS, Comic Sans"}
                    onClick={() => editor!.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run()}
                    style={{ fontFamily: "Comic Sans MS, Comic Sans" }}
                >
                    Comic Sans
                </MenuItem>

                <MenuItem
                    value={"Helvetica, sans-serif"}
                    onClick={() => editor!.chain().focus().setFontFamily('Helvetica, sans-serif').run()}
                    style={{ fontFamily: "Helvetica, sans-serif" }}
                >
                    Helvetica
                </MenuItem>

                <MenuItem
                    value={"impact, sans-serif"}
                    onClick={() => editor!.chain().focus().setFontFamily('impact, sans-serif').run()}
                    style={{ fontFamily: "impact, sans-serif" }}
                >
                    Impact
                </MenuItem>
                <MenuItem
                    value={"monospace"}
                    onClick={() => editor!.chain().focus().setFontFamily('monospace').run()}
                    style={{ fontFamily: "monospace" }}
                >
                    Monospace
                </MenuItem>
                <MenuItem
                    value={"poppins, helvetica, sans-serif"}
                    onClick={() => editor!.chain().focus().setFontFamily('poppins, helvetica, sans-serif').run()}
                    style={{ fontFamily: "poppins, helvetica, sans-serif" }}
                >
                    Poppins
                </MenuItem>
                <MenuItem
                    value={"times new roman, sans-serif"}
                    onClick={() => editor!.chain().focus().setFontFamily('times new roman, sans-serif').run()}
                    style={{ fontFamily: "times new roman, sans-serif" }}
                >
                    Times New Roman
                </MenuItem>
                <MenuItem
                    value={"verdana, sans-serif"}
                    onClick={() => editor!.chain().focus().setFontFamily('verdana, sans-serif').run()}
                    style={{ fontFamily: "verdana, sans-serif" }}
                >
                    Verdana
                </MenuItem>
            </Select>
        </Tooltip>
    )
}

export default FontStyleComboBox