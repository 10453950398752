import React from 'react'
import SummaryFigures from '../shared/SummaryFiguresSection/SummaryFigures'

interface InputProps {
	nameValuePairList: { name: string, value: any }[]
}

const SummaryFiguresSection = (props: InputProps) => {
  return (
    <section className='summary-figures-section'>
        <h2>Summary Figures</h2>
        <SummaryFigures nameValuePairList={props.nameValuePairList} />
    </section>
  )
}

export default SummaryFiguresSection