import "./mediaSection.scss"
import React, { useContext, useState } from "react"
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MediaDialog from "../dialogs/MediaDialog"
import ILot from "../../../../interfaces/lot"
import ICampaign from "../../../../interfaces/campaign"
import IMedia from "../../../../interfaces/media"
import getYouTubeID from "get-youtube-id"
import useFetch from "../../../../hooks/useFetch"
import ConfirmationDialog from "../../../shared/ConfirmationDialog"
import { LotMediaType } from "../../../../common/enums/LotMediaType"
import { API_PATHS, LOT_API_PATHS } from "../../../../common/ApiPaths"
import { UserContext } from "../../../../context/UserContext"
import useCommonFunctions from "../../../../hooks/useCommonFunctions"

interface InputProps {
	lot: ILot
	campaign: ICampaign
	setLot: (lot: ILot) => void
	isNewLot: boolean
	// saveLot: () => void
	lotValidation: any
	isCampaignClosed: boolean
	fromVMS: boolean
	parentLotSlug?: string
}

const MediaSection = (props: InputProps) => {
	const [isMediaDialogOpen, setIsMediaDialogOpen] = useState<boolean>(false)
	const [tempMedia, setTempMedia] = useState<IMedia>({} as IMedia)
	const [tempLotMediaIndex, setTempLotMediaIndex] = useState<number>(-1);
	const [confirmationDialogProperties, setConfirmationDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "", errorDetails: "" })

	const lotApi = useFetch(API_PATHS.LOTS)
	const { dBUser } = useContext(UserContext)
	const commonFunctions = useCommonFunctions()

	const closeDialog = async (isSaveLot: boolean, lotToBeSaved?: ILot) => {
		//add write lot to DB
		if (isSaveLot) {
			try {
				await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT}`, [lotToBeSaved], dBUser._id)
				if (props.lotValidation.validateInputs()) {
					setConfirmationDialogProperties({
						isOpen: true,
						type: "SUCCESS",
						title: "Lot Media Saved.",
						message: `Lot media saved correctly.`,
					})
				} else {
					setConfirmationDialogProperties({
						isOpen: true,
						type: "ERROR",
						title: "Save failed.",
						message: `Your lot did not pass validation, please update and save again.`,
					})
				}
			}
			catch (err: any) {
				setConfirmationDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: "Save failed.",
					message: `Your lot save failed with some error`,
					errorDetails: err.message
				})
			}
		}

		setIsMediaDialogOpen(false)
	}
	const openDialog = (media: IMedia, index: number) => {
		setTempMedia(media)
		setTempLotMediaIndex(index)
		setIsMediaDialogOpen(true)
	}

	const deleteMedia = (index: number) => {
		let tempMediaObject: any[] = [...props.lot.mediaList]
		tempMediaObject.splice(index, 1)
		props.setLot({ ...props.lot, mediaList: tempMediaObject })
	}

	const increaseMediaOrder = (index: number) => {
		let tempMediaObject: any[] = [...props.lot.mediaList]

		if (index !== 0) {
			let mediaItemToSwap: IMedia = props.lot.mediaList[index]
			let mediaItemToBeSwapped: IMedia = props.lot.mediaList[index - 1]

			tempMediaObject[index] = mediaItemToBeSwapped
			tempMediaObject[index - 1] = mediaItemToSwap

			props.setLot({ ...props.lot, mediaList: tempMediaObject })
		} else {
			console.log("Can't promote - media item already first in the array")
		}
	}

	const decreaseMediaOrder = (index: number) => {
		let tempMediaObject: any[] = [...props.lot.mediaList]

		if (index !== props.lot.mediaList.length - 1) {
			let mediaItemToSwap: IMedia = props.lot.mediaList[index]
			let mediaItemToBeSwapped: IMedia = props.lot.mediaList[index + 1]

			tempMediaObject[index] = mediaItemToBeSwapped
			tempMediaObject[index + 1] = mediaItemToSwap

			props.setLot({ ...props.lot, mediaList: tempMediaObject })
		} else {
			console.log("Can't demote - media item already last in the array")
		}
	}

	const isVMSImage = (src: string) => {
		return !src.startsWith('https://res.cloudinary.com/going-gone') && props.fromVMS
	}

	return (
		<div className="media-section">
			<h2 className="subtitle">Lot Media.</h2>
			<span className="description">Select the images and/or videos for the lot.</span>
			<Paper className="input-wrapper">
				{props.lot.mediaList.length > 0 ? (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<div className="table-header-cell">
										<span>Source</span>
									</div>
								</TableCell>
								<TableCell className="hide-on-mobile">
									<div className="table-header-cell">
										<span>Type</span>
									</div>
								</TableCell>
								<TableCell className="hide-on-mobile"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.lot.mediaList.map((media: IMedia, index) => (
								<TableRow key={new Date().getTime() + index}>
									<TableCell>
										{media.mediaType === LotMediaType.IMAGE ? (
											<img alt={`lotMedia-${index}`} onClick={() => props.isCampaignClosed ? {} : openDialog(media, index)} className="media-thumbnail" src={media.src} />
										) : (
											<img
												alt={`lotMedia-${index}`}
												onClick={() => props.isCampaignClosed ? {} : openDialog(media, index)}
												className="media-thumbnail"
												src={`http://img.youtube.com/vi/${getYouTubeID(media.src)}/0.jpg`}
											/>
										)}
									</TableCell>
									{/* <TableCell><span>{media.src}</span></TableCell> */}
									<TableCell className="hide-on-mobile">{media.mediaType}</TableCell>
									{!props.isCampaignClosed && (
										<TableCell className="hide-on-mobile">
											<div className="action-cell">
												<div onClick={() => increaseMediaOrder(index)}>
													<Tooltip title="Promote Media">
														<ExpandLessIcon />
													</Tooltip>
												</div>
												<div onClick={() => decreaseMediaOrder(index)}>
													<Tooltip title="Demote Media">
														<ExpandMoreIcon />
													</Tooltip>
												</div>
												{isVMSImage(media.src) ? (
													<>
														<div>
															<Tooltip title="Cannot Edit VMS Media">
																<CreateIcon className="disabled" />
															</Tooltip>
														</div>
														<div>
															<Tooltip title="Cannot Delete VMS Media">
																<DeleteIcon className="disabled" />
															</Tooltip>
														</div>
													</>
												) : (
													<>
														<div onClick={() => openDialog(media, index)}>
															<Tooltip title="Edit Media">
																<CreateIcon />
															</Tooltip>
														</div>
														<div onClick={() => deleteMedia(index)}>
															<Tooltip title="Delete Media">
																<DeleteIcon />
															</Tooltip>
														</div>
													</>
												)}

											</div>
										</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
				) : (
					<div className="no-entries">No media defined.</div>
				)}

				{!props.isCampaignClosed && (
					<div className="button-wrapper">
						<Button id="add-video-button" className="gg-button" onClick={() => openDialog({ mediaType: LotMediaType.YOUTUBE } as IMedia, -1)}>
							Add Video
						</Button>
						<Button id="add-image-button" className="gg-button" onClick={() => openDialog({ mediaType: LotMediaType.IMAGE } as IMedia, -1)}>
							Add Image(s)
						</Button>
					</div>
				)}
			</Paper>
			<MediaDialog
				handleClose={closeDialog}
				isDialogOpen={isMediaDialogOpen}
				setObject={props.setLot}
				object={{...props.lot}}
				// object={{...props.lot, slug:`${props.parentLotSlug ? props.parentLotSlug+'/' : ''}${props.lot.slug}`}}
				media={tempMedia}
				index={tempLotMediaIndex}
			/>
			<ConfirmationDialog
				handleClose={() => setConfirmationDialogProperties({ isOpen: false })}
				isDialogOpen={confirmationDialogProperties.isOpen}
				message={confirmationDialogProperties.message}
				errorDetails={confirmationDialogProperties.errorDetails}
				title={confirmationDialogProperties.title}
				type={confirmationDialogProperties.type}
				label={confirmationDialogProperties.label}
				primaryButton={<Button onClick={() => setConfirmationDialogProperties({ isOpen: false })} className="gg-button" style={commonFunctions.determineButtonColor(confirmationDialogProperties.type)}>
					Ok
				</Button>}
			/>
		</div>
	)
}

export default MediaSection
