import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import ICatalogueItem from "../../../interfaces/catalogueItem"
import MediaCarousel from "../../shared/MediaCarousel"
import "./itemDetailsDialog.scss"

interface InputProps {
	isDialogOpen: boolean
	handleClose: () => void
	item: ICatalogueItem
	currency: string
	setTransferDialogProperties: (props: ITransferDialogProperties) => void
}

interface ITransferDialogProperties {
	isOpen: boolean
	items: ICatalogueItem[]
}

const ItemDetailsDialog = (props: InputProps) => {
	const addToCampaign = () => {
		props.handleClose()
		props.setTransferDialogProperties({ isOpen: true, items: [props.item] })
	}

	return (
		<Dialog
			open={props.isDialogOpen}
			onClose={props.handleClose}
			aria-labelledby="form-dialog-title"
			className="item-details-dialog"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>Item Details</DialogTitle>

			<DialogContent>
				<div className="content-wrapper">
					<div className="first-row">
						{props.item.mediaList && props.item.mediaList.length > 0 ? (
							<MediaCarousel
								mediaList={props.item.mediaList}
								accentColor="#26C0B9"
								index={0}
								isShowYouTubeThumbnails={false}
								isDisabled={false}
								isVideoPlaying={false}
								setIsVideoPlaying={() => { }}
							/>
						) : (
							<img className="placeholder-image" src={"/placeholder_image.jpg"} alt={"Placeholder image"} style={{ width: 400 }} />
						)}

						<div className="item-details-wrapper">
							<h2 className="title">{props.item.title}</h2>
							{props.item.subtitle && <span className="sub-title">{props.item.subtitle}</span>}
							<div className="lot-specifics-wrapper">
								<div>
									<div className="label">Cost Price: &nbsp;</div>
									<div className="value">
										{`${new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: props.currency ?? "GBP",
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										}).format(props.item.costPrice)}`}
										<span style={{ fontSize: "0.7rem" }}>{props.item.isVatApplicable === true ? " (VAT inclusive)" : " (+ VAT)"}</span>
									</div>
								</div>
								<div>
									<div className="label">Typically sells for: &nbsp;</div>
									<div className="value">
										{new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: props.currency ?? "GBP",
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										}).format(props.item.typicallySellsFor)}
									</div>
								</div>
								<div>
									<div className="label">Suggested Reserve Price: &nbsp;</div>
									<div className="value">
										{new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: props.currency ?? "GBP",
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										}).format(props.item.suggestedReservePrice)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{props.item.description &&
						<div className="description">
							<div dangerouslySetInnerHTML={{ __html: props.item.description }}></div>
						</div>
					}
				</div>
				<DialogActions>
					<div className="save-button-wrapper">
						<div className="buttons">
							<Button id="cancel-button" onClick={props.handleClose}>
								Close
							</Button>
							<Button id="save-button" className="gg-button" onClick={() => addToCampaign()}>
								Add to Campaign
							</Button>
						</div>
					</div>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}

export default ItemDetailsDialog
