import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, ListSubheader } from "@mui/material"
import React, { useContext, useEffect, useMemo, useState } from "react"
import ReactCountryFlag from "react-country-flag"
import IOrganisation from "../../../interfaces/organisation"
import CloudinaryUploadWidget from "../shared/CloudinaryUploadWidget"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import IValidationObject from "../../../interfaces/validationObject"
import { OrganisationContext, OrganisationDispatchContext } from "../../../context/OrganisationContext"
import { UserContext } from "../../../context/UserContext"
import { UserRole } from "../../../common/enums/UserRole"
import SearchIcon from "@mui/icons-material/Search"

interface InputProps {
	validationObject: IValidationObject[]
	handleChange: (name: string, value: string) => void
}

const OrganisationDetailsSection = (props: InputProps) => {
	const organisation: IOrganisation = useContext(OrganisationContext)
	const setOrganisation = useContext(OrganisationDispatchContext)
	const { dBUser } = useContext(UserContext)

	const commonFunctions = useCommonFunctions()
	const allowedCountries = commonFunctions.getAllowedCountries()
	const allCountriesData = commonFunctions.getAllCountriesData()


	const [phoneNumber, setPhoneNumber] = useState<string>('')
	const [selectedCountryCode, setSelectedCountryCode] = useState<string>(commonFunctions.findIsdCodeByCountryCode("GB"))
	const [countryCodeAdded, setCountryCodeAdded] = useState<boolean>(false)
	const [selectedCurrency, setSelectedCurrency] = useState(organisation.currency || "GBP")
	const [searchText, setSearchText] = useState("")
	const [phoneError, setPhoneError] = useState<boolean>(false)
	const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>("")

	const displayedCountries = useMemo(() => {
		let lowercaseSearchText = searchText.toLowerCase()
		return allCountriesData.filter(
			option =>
				option.name.toLowerCase().includes(lowercaseSearchText) ||
				option.isd_code.includes(lowercaseSearchText) ||
				`${option.name} (${option.isd_code})`.toLowerCase().includes(lowercaseSearchText)
		)
	}, [searchText])

	const handlePhoneChange = (e) => {
		let phone = selectedCountryCode + e.target.value
		let parsed = commonFunctions.getParsedPhone(phone)
		if (!commonFunctions.isNumberCountryCode(phone)) {
			props.handleChange(e.target.name, parsed.phone)
			if (commonFunctions.canNumberBeParsed(parsed.phone)) {
				setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
			}
			setPhoneError(parsed.error)
			setPhoneErrorMessage(parsed.errorMessage)
		} else {
			setPhoneError(false)
			setPhoneErrorMessage("")
			// setCountryCodeAdded(false)
			props.handleChange(e?.target.name, selectedCountryCode + e.target.value)
			if (commonFunctions.canNumberBeParsed(parsed.phone)) {
				setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
			}
		}
	}

	// TODO move this into reusable service
	const renderCurrencyDropdown = () => {
		let finalCurrencyCountries = allowedCountries.filter(x => x.currencyCode !== "EUR")
		finalCurrencyCountries.push({
			name: "European Union",
			countryCode: "EU",
			currencyCode: "EUR",
			isd_code: "",
			currency: "European Euro",
		})
		finalCurrencyCountries = finalCurrencyCountries.sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1))
		return finalCurrencyCountries.map((country, index) => (
			<MenuItem value={country.currencyCode} key={index}>
				<ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: "8px" }} />
				{` ${country.currency} (${country.currencyCode})`}
			</MenuItem>
		))
	}

	const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		props.validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	useEffect(() => {
		setOrganisation({ ...organisation, currency: selectedCurrency })
	}, [selectedCurrency])

	useEffect(() => {
		if (organisation && organisation._id && !countryCodeAdded) {
			let code = organisation.contactPhone && organisation.contactPhone.length > 0
				? commonFunctions.getIsdCode(organisation.contactPhone)
				: commonFunctions.findIsdCodeByCountryCode(commonFunctions.findIsdCodeByCountryCode("GB"))
			setSelectedCountryCode(code)
			setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(organisation.contactPhone) || '')
			setCountryCodeAdded(true)
		}
	}, [organisation])

	return (
		<section className="organisation-details-section">
			<h2 className="subtitle">Organisation Details</h2>
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="name"
						name="name"
						label="Organisation Name"
						variant="outlined"
						required
						value={organisation.name || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText={
							getValidation("organisationName").isValid
								? "The name of the organisation."
								: getValidation("organisationName").validationMessage
						}
						error={!getValidation("organisationName").isValid}
						disabled={dBUser.role === UserRole.OPERATOR}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="hostingLogoUrl"
						name="logoUrl"
						label="Logo URL"
						variant="outlined"
						value={organisation.logoUrl || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText="This is the logo in the header bar. Either use the icon to upload a new file or provide the url of an existing file hosted online. Suggested size: w120px x h40px."
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<CloudinaryUploadWidget
										folderName={organisation.name}
										allowMultipleUploads={false}
										functionOnComplete={uploadedUrlList => {
											if (uploadedUrlList.length) {
												props.handleChange("logoUrl", uploadedUrlList[0])
											}
										}}
										uploadPreset="unsigned"
									/>
								</InputAdornment>
							),
						}}
						disabled={dBUser.role === UserRole.OPERATOR}
					/>
				</div>
				<div className="field-wrapper">
					<FormControl variant="outlined">
						<InputLabel id="currency-label">Currency</InputLabel>
						<Select
							labelId="currency-label"
							id="currency"
							name="currency"
							value={selectedCurrency}
							onChange={event => setSelectedCurrency(event.target.value)}
							label="currency"
							disabled
						// disabled={currentUser.role === UserRole.OPERATOR}
						>
							{renderCurrencyDropdown()}
						</Select>
						<FormHelperText>The currency of the organisation.</FormHelperText>
					</FormControl>
				</div>
				<div className="field-wrapper">
					<TextField
						id="contactEmail"
						name="contactEmail"
						label="Contact Email"
						variant="outlined"
						required
						value={organisation.contactEmail || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText={
							getValidation("contactEmail").isValid
								? "The contact email address of the organisation."
								: getValidation("contactEmail").validationMessage
						}
						error={!getValidation("contactEmail").isValid}
						disabled={dBUser.role === UserRole.OPERATOR}
					/>
				</div>
				<div className="field-wrapper">
					<FormControl variant="outlined" >
						<div style={{ display: 'flex', gap: '5px' }}>
							<InputLabel id="country-code-label">Country Code</InputLabel>
							<Select
								id="countryCode"
								name="countryCode"
								value={selectedCountryCode}
								onChange={event => setSelectedCountryCode(event.target.value)}
								onBlur={event => props.handleChange('contactPhone', event.target.value + commonFunctions.getNumberWithoutCountryCode(organisation.contactPhone) || '')}
								label="Country Code"
								variant="outlined"
								onClose={() => setSearchText("")}
								MenuProps={{ autoFocus: false }}
								renderValue={value => {
									const selected = allCountriesData.filter(x => x.isd_code === value)[0].countryCode
									return (
										<>
											<ReactCountryFlag countryCode={selected} style={{ marginRight: "8px" }} />
											{value}
										</>
									)
								}}
								disabled={dBUser.role === UserRole.OPERATOR}
								style={{ height: 'max-content' }}
							>
								<ListSubheader>
									<TextField
										size="small"
										autoFocus
										placeholder="Search..."
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
										onChange={e => setSearchText(e.target.value)}
									/>
								</ListSubheader>
								{displayedCountries.map((country, index) => (
									<MenuItem value={country.isd_code} key={index}>
										<ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: "8px" }} />
										{` ${country.name} (${country.isd_code})`}
									</MenuItem>
								))}
							</Select>
							<TextField
								id="contactPhone"
								name="contactPhone"
								value={phoneNumber.startsWith('+') ? commonFunctions.getNumberWithoutCountryCode(phoneNumber) : phoneNumber}
								onChange={event => setPhoneNumber(event.target.value)}
								// onChange={event => props.handleChange(event.target.name, `${selectedCountryCode} ${(event.target.value) || ''}`)}
								variant="outlined"
								label="Contact Phone"
								required
								helperText={
									!getValidation("contactPhone").isValid || phoneError
										? getValidation("contactPhone").validationMessage || phoneErrorMessage
										: ""
								}
								disabled={dBUser.role === UserRole.OPERATOR}
								error={!getValidation("contactPhone").isValid || phoneError}
								inputProps={{ maxLength: 20 }}
								onBlur={e => handlePhoneChange(e)}
								style={{ marginBottom: "0px" }}
							/>
						</div>
						<FormHelperText
							className={!getValidation("contactPhone").isValid || phoneError ? "d-none" : ""}
							style={{ paddingLeft: "8px" }}
						>
							{
								getValidation("contactPhone").isValid
									? "The contact phone number of the organisation."
									: getValidation("contactPhone").validationMessage
							}
						</FormHelperText>
					</FormControl>
				</div>
			</Paper>
		</section>
	)
}

export default OrganisationDetailsSection
