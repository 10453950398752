import { Card } from "@mui/material"
import React from "react"
import "./summaryFigureCard.scss"

interface InputProps {
	label: string
	value: string
}

const SummaryFigureCard = (props: InputProps) => {
	return (
        <Card className="summary-figure-card">
            <span className="label">{props.label}</span>
            <span className="value">{props.value}</span>
        </Card>
    )

}

export default SummaryFigureCard