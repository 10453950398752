import {
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Paper,
	TextField,
} from "@mui/material"
import React, { useContext } from "react"
import ICatalogueItem from "../../../interfaces/catalogueItem"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import { OrganisationContext } from "../../../context/OrganisationContext"
import IValidationObject from "../../../interfaces/validationObject"

interface InputProps {
	handleChange: (name: string, value: any) => void
	catalogueItem: ICatalogueItem
	setCatalogueItem: (item: ICatalogueItem) => void
	validationObject: IValidationObject[]
}

const CatalogueItemDetailsSection = (props: InputProps) => {
	const commonFunctions = useCommonFunctions()
	const organisation = useContext(OrganisationContext)

	const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		props.validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	return (
		<section className="item-details-section">
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="title"
						name="title"
						label="Title"
						variant="outlined"
						required
						value={props.catalogueItem.title || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText={getValidation("title").isValid ? "The title of the catalogue item." : getValidation("title").validationMessage}
						error={!getValidation("title").isValid}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="subtitle"
						name="subtitle"
						label="Subtitle"
						variant="outlined"
						value={props.catalogueItem.subtitle || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText={"The subtitle of the catalogue item."}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="costPrice"
						name="costPrice"
						label="Cost Price"
						variant="outlined"
						required
						value={Number(props.catalogueItem.costPrice || 0).toString()}
						onChange={event => props.handleChange(event.target.name, Number(event.target.value))}
						type="number"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{commonFunctions.getCurrencySymbol(organisation.currency ?? "GBP")}</InputAdornment>
							),
						}}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="typicallySellsFor"
						name="typicallySellsFor"
						label="Typically sells for"
						variant="outlined"
						required
						value={Number(props.catalogueItem.typicallySellsFor || 0).toString()}
						onChange={event => props.handleChange(event.target.name, Number(event.target.value))}
						type="number"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{commonFunctions.getCurrencySymbol(organisation.currency ?? "GBP")}</InputAdornment>
							),
						}}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="suggestedReservePrice"
						name="suggestedReservePrice"
						label="Suggested Reserve Price"
						variant="outlined"
						required
						value={Number(props.catalogueItem.suggestedReservePrice || 0).toString()}
						onChange={event => props.handleChange(event.target.name, Number(event.target.value))}
						type="number"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{commonFunctions.getCurrencySymbol(organisation.currency ?? "GBP")}</InputAdornment>
							),
						}}
					/>
				</div>
				<br/>
				<div className="field-wrapper" style={{ justifyContent: "flex-start" }}>
					<FormControlLabel
						className="cost-price-checkbox"
						control={
							<Checkbox
								checked={Boolean(props.catalogueItem.isVatApplicable)}
								onChange={(event) => {
									props.handleChange("isVatApplicable", event.target.checked)
								}
								}
							/>
						}
						label={"Cost Price Includes VAT"}
					/>
				</div>
			</Paper>
		</section>
	)
}

export default CatalogueItemDetailsSection
