import React, { useEffect, useRef } from "react"
import "./campaignFooter.scss"
import LanguageIcon from '@mui/icons-material/Language'
import MailIcon from '@mui/icons-material/Mail'
import moment from "moment"
import { useParams } from "react-router-dom"
import ICampaign from "../../interfaces/campaign"

interface InputProps {
    campaign: ICampaign
    isFixedBidSection?: boolean
    setFooterHeight?: any
}

const CampaignFooter = (props: InputProps) => {
    const refContainer = useRef<any>(null);
    let { campaignSlug } = useParams()

    useEffect(() => {
        if (refContainer && props.setFooterHeight) {
            props.setFooterHeight(refContainer.current?.offsetHeight || 0);
        }
    }, [])

    return (
        <div ref={refContainer} className="footer" >
            {props.campaign &&
                <section className="footer-section" style={{ backgroundColor: props.campaign.footerBackgroundColor, color: props.campaign.footerFontColor }}>
                    <div className="left-content">
                        {props.campaign.hostingOrganisationWebsite &&
                            <div className="website-wrapper">
                                <LanguageIcon />
                                <a href={`https://${props.campaign.hostingOrganisationWebsite}`}>{props.campaign.hostingOrganisationWebsite}</a>
                            </div>
                        }
                        {props.campaign.hostingOrganisationEmail &&
                            <div className="email-wrapper">
                                <MailIcon />
                                <a href={`mailto:${props.campaign.hostingOrganisationEmail}`}>{props.campaign.hostingOrganisationEmail}</a>
                            </div>
                        }
                    </div>
                    <div className="middle-content">
                        <span>© Copyright {moment().format('YYYY')} {props.campaign.hostingOrganisationName} </span>
                    </div>
                    <div className="right-content">
                        {props.campaign.termsAndConditionsHtml &&
                            <>
                                <div className="terms-wrapper">
                                    <a href={`/${campaignSlug}/terms-and-conditions`} rel="noreferrer">Terms & Conditions</a>
                                </div>
                            </>
                        }
                        <div className="terms-wrapper">
                            <a href={`/${campaignSlug}/privacy-policy`} rel="noreferrer">Privacy Policy</a>
                        </div>
                    </div>
                </section>
            }
        </div>
    )
}

export default CampaignFooter