import { Button } from '@mui/material';
import ILot from '../../../interfaces/lot';
import { useContext, useState } from 'react';
import { OrganisationContext } from '../../../context/OrganisationContext';
import { API_PATHS, CAMPAIGN_API_PATHS, STRIPE_API_PATHS } from '../../../common/ApiPaths';
import useFetch from '../../../hooks/useFetch';
import { CampaignContext, CampaignDispatchContext } from '../../../context/CampaignContext';
import { UserContext } from '../../../context/UserContext';
import BrochureConfigDialog from './dialogs/BrochureConfigDialog';
import { IBrochureConfig } from '../../../interfaces/brochureToolPurchaseInfo';
import ICampaign from '../../../interfaces/campaign';
import { UserRole } from '../../../common/enums/UserRole';
import ConfirmationDialog from '../../shared/ConfirmationDialog';
import useCommonFunctions from '../../../hooks/useCommonFunctions';

interface InputProps {
	lotList: ILot[]
	isPurchased: boolean;
	className: string
}

const PdfBrochureButton = (props: InputProps) => {
	const [isBrochureConfigDialogOpen, setIsBrochureConfigDialogOpen] = useState<boolean>(false)
	const [isPurchased, setIsPurchased] = useState<boolean>(props.isPurchased)
	const [confirmationDialogProperties, setConfirmationDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", label: "", message: "" })

	const organisation = useContext(OrganisationContext)
	const campaign = useContext(CampaignContext)
	const { dBUser } = useContext(UserContext)

	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	const stripeApi = useFetch(API_PATHS.STRIPE)

	const commonFunctions = useCommonFunctions()

	const purchaseBrochurePDF = async () => {

		try {

			if (dBUser.role === UserRole.SUPER_ADMIN) {

				await campaignsApi.put(CAMPAIGN_API_PATHS.UPDATE_CAMPAIGNS_WITH_BROCHURE_INFO, {
					campaignIds: [campaign._id],
					brochureInfo:
					{
						paymentRefId: "Super_Admin_Free",
						receiptUrl: null,
						brochureConfig: {
							backgroundColor: '#26c0b9',
							backPageImageUrl: '',
							fontColor: '#ffffff',
							footerText: '',
							frontPageImageUrl: '',
							headerText: '',
						},
						allCampaignsInfo: [],
						timestamp: new Date(),
						totalCost: 0,
					},
					organisationId: campaign.organisationId,
					currentUserId: dBUser._id
				})
				setConfirmationDialogProperties({
					isOpen: true, type: "SUCCESS", title: "Success",
					message: "Brochure tool purchased. (No charge for Super Admin user).",
				})



			} else {

				let data = await stripeApi.post(STRIPE_API_PATHS.CREATE_CHECKOUT_SESSION, {
					productName: `Brochure Generation Tool - ${campaign.name}`,
					price: 60,
					currency: organisation.currency,
					organisationId: organisation._id,
					organisationSlug: organisation.slug,
					organisationEmail: organisation.contactEmail,
					campaignIdsForBrochure: [campaign._id],
					userId: dBUser._id,
					baseUrl: window.location.href,
					tabNumber: null,
					description: `Going Gone - Brochure Generation Tool Purchase`
				})

				window.location.href = data.url
			}

		} catch (err) {
			setConfirmationDialogProperties({ isOpen: true, type: "ERROR", title: "Something went wrong", message: String(err) })
		}
	}


	const saveCampaign = async (updatedConfig: IBrochureConfig): Promise<void> => {

		try {
			const data: ICampaign = await campaignsApi.put(`${CAMPAIGN_API_PATHS.UPDATE_CAMPAIGN.replace('{campaignId}', campaign._id)}`, { ...campaign, brochureToolPurchaseInfo: { ...campaign.brochureToolPurchaseInfo, brochureConfig: updatedConfig } }, dBUser._id)
			// setCampaign(data) 
		}
		catch (err: any) {
			console.log(err)
		}
	}

	const handleCloseDialog = (isConfirmed: boolean) => {

		if (confirmationDialogProperties.type !== "ERROR"){
			setIsPurchased(true)
			setIsBrochureConfigDialogOpen(true)
		}

		setConfirmationDialogProperties({ isOpen: false })
		
	}

	return (
		<>
			{isPurchased ? (
				<Button className={`gg-button ${props.className}`} onClick={() => setIsBrochureConfigDialogOpen(true)}>Generate Brochure</Button>

			) : (
				<Button className={`gg-button ${props.className}`} onClick={() => purchaseBrochurePDF()}>Purchase Brochure</Button>
			)}

			<BrochureConfigDialog
				isDialogOpen={isBrochureConfigDialogOpen}
				setIsDialogOpen={setIsBrochureConfigDialogOpen}
				lotList={props.lotList}
				campaign={campaign}
				saveCampaign={saveCampaign}
			/>

			<ConfirmationDialog
				handleClose={handleCloseDialog}
				isDialogOpen={confirmationDialogProperties.isOpen}
				message={confirmationDialogProperties.message}
				title={confirmationDialogProperties.title}
				type={confirmationDialogProperties.type}
				label={confirmationDialogProperties.label}
				primaryButton={<Button id="save-button" onClick={() => handleCloseDialog(true)} className="gg-button" style={commonFunctions.determineButtonColor(confirmationDialogProperties.type)}>
					Ok
				</Button>}
			/>
		</>
	)
}
export default PdfBrochureButton