import './userList.scss'
import React, { useState, useEffect, useContext } from 'react'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent, TablePagination, CircularProgress } from "@mui/material"
import moment from 'moment'
import _ from "lodash"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IUser } from '../../../interfaces/user'
import { API_PATHS, CAMPAIGN_API_PATHS} from '../../../common/ApiPaths'
import { OrganisationContext } from '../../../context/OrganisationContext'
import { UserContext } from '../../../context/UserContext'
import { UserRole } from '../../../common/enums/UserRole'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import { useErrorBoundary } from 'react-error-boundary'
import { CAMPAIGN_USERS_API_PATH, CAMPAIGN_USERS_PATHS} from '../../../common/CampaignUserApiPaths'

const UserList = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [campaignsloading, setCampaignsLoading] = useState<boolean>(false)
    const [userList, setUserList] = useState<IUser[]>([])
    const [columnToSort, setColumnToSort] = useState("latestLoginDateTime")
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
    const [paginationPage, setPaginationPage] = useState<number>(0)
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(25)
    const [totalUsersCount, setTotalUsersCount] = useState<number>(0)

    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
    const campaignUserApi = useFetch(CAMPAIGN_USERS_API_PATH)

    const organisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)

    const commonFunctions = useCommonFunctions()
    const { showBoundary } = useErrorBoundary();

    const getUsers = async (): Promise<void> => {
        setLoading(true)

        try {
            const data = await campaignUserApi.get(`${CAMPAIGN_USERS_PATHS.GET_PAGINATED_CAMPAIGN_USERS}?organisationId=${organisation._id}&limit=${paginationRowsPerPage}&pageNo=${paginationPage}${dBUser.role === UserRole.OPERATOR ? `&currentUserId=${dBUser._id}` : ''}`)
            setTotalUsersCount(data.totalRecords)
            setUserList(data.users)
            setLoading(false)
            getCampaignList(data.users)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const getCampaignList = async (users): Promise<void> => {
        setCampaignsLoading(true)
        try {
            const result = await campaignsApi.get(
                `${CAMPAIGN_API_PATHS.GET_VALID_CAMPAIGNS}`
            )
            const updatedUsers = users.map((u) => {
                return {
                    ...u,
                    signUpCampaignId: u.signUpCampaignId !== 'admin' ? (result.find(x => x._id === u.signUpCampaignId)?.slug || '-') : u.signUpCampaignId
                }
            })
            setUserList(updatedUsers)
            setCampaignsLoading(false)
        } catch (err: any) {
            showBoundary(err)
            setCampaignsLoading(false)
        }
    }

    const handlePaginationChangePage = (event: unknown, newPage: number) => {
        setPaginationPage(newPage)
    }

    const handlePaginationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationRowsPerPage(parseInt(event.target.value, 10))
        setPaginationPage(0)
    }

    const invertDirection = (currentDirection: string) => {
        if (currentDirection === "asc") {
            return "desc"
        } else if (currentDirection === "desc") {
            return "asc"
        }
    }

    const handleSort = (columnName) => {
        setColumnToSort(columnName)
        let sortDirect: any = columnToSort === columnName ? invertDirection(sortDirection) : 'desc'
        setSortDirection(sortDirect)
    }

    useEffect(() => {
        setUserList(_.orderBy(userList, columnToSort, sortDirection))
    }, [columnToSort, sortDirection])


    useEffect(() => {
        if (organisation._id && dBUser._id) {
            getUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [organisation, dBUser, paginationPage, paginationRowsPerPage])

    if (loading) {
        return (
            <Loading />
        )
    }

    // if (error) {
    //     return (
    //         <p>{error}</p>
    //     )
    // }

    return (
        <div className="user-list">
            {/* <h1>User List.</h1> */}
            {userList && userList.length > 0 ? (
                <Card>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="hide-on-mobile">
                                        <div onClick={() => handleSort("latestLoginDateTime")} className="table-header-cell sortable">
                                            <span>Latest Login Date</span>
                                            {
                                                columnToSort === "latestLoginDateTime" ? (
                                                    sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                ) : null
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div onClick={() => handleSort("name")} className="table-header-cell sortable">
                                            <span>Name</span>
                                            {
                                                columnToSort === "name" ? (
                                                    sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                ) : null
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div onClick={() => handleSort("email")} className="table-header-cell sortable">
                                            <span>Email Address</span>
                                            {
                                                columnToSort === "email" ? (
                                                    sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                ) : null

                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div onClick={() => handleSort("signUpDateTime")} className="table-header-cell sortable">
                                            <span>Sign Up Date</span>
                                            {
                                                columnToSort === "signUpDateTime" ? (
                                                    sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                ) : null
                                            }
                                        </div>
                                    </TableCell>

                                    <TableCell className="hide-on-mobile">
                                        <div onClick={() => handleSort("signUpCampaignId")} className="table-header-cell sortable">
                                            <span>Sign Up Campaign</span>
                                            {
                                                columnToSort === "signUpCampaignId" ? (
                                                    sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                ) : null
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map((user: IUser, index) => (
                                    <TableRow key={index}>
                                        <TableCell className="hide-on-mobile">{moment(user.latestLoginDateTime).format("HH:mm DD/MM/YYYY")}</TableCell>
                                        <TableCell>{(user.firstName && user.surname) ? commonFunctions.titleCase(`${user.firstName} ${user.surname}`) : user.email}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell className="hide-on-mobile">{moment(user.signUpDateTime).format("HH:mm DD/MM/YYYY")}</TableCell>
                                        <TableCell>{campaignsloading ?
                                            <span className="small-font"><CircularProgress size='1rem' /></span>
                                            :
                                            user.signUpCampaignId}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={totalUsersCount}
                            rowsPerPage={paginationRowsPerPage}
                            page={paginationPage}
                            onPageChange={handlePaginationChangePage}
                            onRowsPerPageChange={handlePaginationChangeRowsPerPage}
                            labelRowsPerPage="Rows/page"
                        />
                    </CardContent>
                </Card >
            ) : (
                <Card>
                    <CardContent>
                        <div className="no-entries">
                            No users added
                        </div>
                    </CardContent>
                </Card>
            )}


        </div >
    )
}

export default UserList