import "../lotSettings/sections/mediaSection.scss"
import React, { useState } from "react"
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MediaDialog from "../lotSettings/dialogs/MediaDialog"
import getYouTubeID from "get-youtube-id"
import ICatalogueItem from "../../../interfaces/catalogueItem"
import IMedia from "../../../interfaces/media"
import { LotMediaType } from "../../../common/enums/LotMediaType"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import useCommonFunctions from "../../../hooks/useCommonFunctions"

interface InputProps {
	catalogueItem: ICatalogueItem
	setCatalogueItem: (item: ICatalogueItem) => void
	catalogueItemValidation: any
}

const MediaSection = (props: InputProps) => {
	const [isMediaDialogOpen, setIsMediaDialogOpen] = useState<boolean>(false)
	const [tempMedia, setTempMedia] = useState<IMedia>({} as IMedia)
	const [tempItemMediaIndex, setTempItemMediaIndex] = useState<number>(-1);
	const [confirmationDialogProperties, setConfirmationDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "" })
	const commonFunctions=useCommonFunctions()
	
	const closeDialog = async (isSaveItem: boolean, itemToBeSaved?: ICatalogueItem) => {
		if(isSaveItem) {
			props.setCatalogueItem(itemToBeSaved ?? props.catalogueItem)
		}
		setIsMediaDialogOpen(false)
	}
	const openDialog = (media: IMedia, index: number) => {
		setTempMedia(media)
		setTempItemMediaIndex(index)
		setIsMediaDialogOpen(true)
	}

	const deleteMedia = (index: number) => {
		let tempMediaObject: any[] = [...props.catalogueItem.mediaList]
		tempMediaObject.splice(index, 1)
		props.setCatalogueItem({ ...props.catalogueItem, mediaList: tempMediaObject })
	}

	const increaseMediaOrder = (index: number) => {
		let tempMediaObject: any[] = [...props.catalogueItem.mediaList]

		if (index !== 0) {
			let mediaItemToSwap: IMedia = props.catalogueItem.mediaList[index]
			let mediaItemToBeSwapped: IMedia = props.catalogueItem.mediaList[index - 1]

			tempMediaObject[index] = mediaItemToBeSwapped
			tempMediaObject[index - 1] = mediaItemToSwap

			props.setCatalogueItem({ ...props.catalogueItem, mediaList: tempMediaObject })
		} else {
			console.log("Can't promote - media item already first in the array")
		}
	}

	const decreaseMediaOrder = (index: number) => {
		let tempMediaObject: any[] = [...props.catalogueItem.mediaList]

		if (index !== props.catalogueItem.mediaList.length - 1) {
			let mediaItemToSwap: IMedia = props.catalogueItem.mediaList[index]
			let mediaItemToBeSwapped: IMedia = props.catalogueItem.mediaList[index + 1]

			tempMediaObject[index] = mediaItemToBeSwapped
			tempMediaObject[index + 1] = mediaItemToSwap

			props.setCatalogueItem({ ...props.catalogueItem, mediaList: tempMediaObject })
		} else {
			console.log("Can't demote - media item already last in the array")
		}
	}

	return (
		<div className="media-section">
			<h2 className="subtitle">Media.</h2>
			<span className="description">Select the images and/or videos for the item.</span>
			<Paper className="input-wrapper">
				{props.catalogueItem.mediaList && props.catalogueItem.mediaList.length > 0 ? (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<div className="table-header-cell">
										<span>Source</span>
									</div>
								</TableCell>
								<TableCell className="hide-on-mobile">
									<div className="table-header-cell">
										<span>Type</span>
									</div>
								</TableCell>
								<TableCell className="hide-on-mobile"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.catalogueItem.mediaList.map((media: IMedia, index) => (
								<TableRow key={new Date().getTime() + index}>
									<TableCell>
										{media.mediaType === LotMediaType.IMAGE ? (
											<img alt={`media-${index}`} onClick={() => openDialog(media, index)} className="media-thumbnail" src={media.src} />
										) : (
											<img
												alt={`media-${index}`}
												onClick={() => openDialog(media, index)}
												className="media-thumbnail"
												src={`http://img.youtube.com/vi/${getYouTubeID(media.src)}/0.jpg`}
											/>
										)}
									</TableCell>
									{/* <TableCell><span>{media.src}</span></TableCell> */}
									<TableCell className="hide-on-mobile">{media.mediaType}</TableCell>
									<TableCell className="hide-on-mobile">
										<div className="action-cell">
											<div onClick={() => increaseMediaOrder(index)}>
												<Tooltip title="Promote Media">
													<ExpandLessIcon />
												</Tooltip>
											</div>
											<div onClick={() => decreaseMediaOrder(index)}>
												<Tooltip title="Demote Media">
													<ExpandMoreIcon />
												</Tooltip>
											</div>
											<div onClick={() => openDialog(media, index)}>
												<Tooltip title="Edit Media">
													<CreateIcon />
												</Tooltip>
											</div>
											<div onClick={() => deleteMedia(index)}>
												<Tooltip title="Delete Media">
													<DeleteIcon />
												</Tooltip>
											</div>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				) : (
					<div className="no-entries">No media defined.</div>
				)}

				<div className="button-wrapper">
					<Button className="gg-button" style={{ marginRight: 12 }} onClick={() => openDialog({ mediaType: LotMediaType.YOUTUBE } as IMedia, -1)}>
						Add Video
					</Button>
					<Button className="gg-button" onClick={() => openDialog({ mediaType: LotMediaType.IMAGE } as IMedia, -1)}>
						Add Image(s)
					</Button>
				</div>
			</Paper>
			<MediaDialog
				handleClose={closeDialog}
				isDialogOpen={isMediaDialogOpen}
				setObject={props.setCatalogueItem}
				object={props.catalogueItem}
				media={tempMedia}
				index={tempItemMediaIndex}
			/>
			<ConfirmationDialog
				handleClose={() => setConfirmationDialogProperties({ isOpen: false })}
				isDialogOpen={confirmationDialogProperties.isOpen}
				message={confirmationDialogProperties.message}
				title={confirmationDialogProperties.title}
				type={confirmationDialogProperties.type}
				label={confirmationDialogProperties.label}
				primaryButton={<Button onClick={() => setConfirmationDialogProperties({ isOpen: false })} className="gg-button" style={commonFunctions.determineButtonColor(confirmationDialogProperties.type)}>
                    Ok
                </Button>}
			/>
		</div>
	)
}

export default MediaSection
