import React, { useContext, useEffect, useState } from "react"
import AdminMenuBar from "../components/admin/shared/AdminMenuBar"
import AdminFooter from "../components/admin/shared/AdminFooter"
import "./layoutAuthenticatedNoMenu.scss"
import { UserContext } from "../context/UserContext"
import { OrganisationProvider } from "../context/OrganisationContext"
import { useLocation, useHistory } from 'react-router-dom'
import RoleProtectedRoute from "../auth/RoleProtectedRoute"
import { UserRole } from "../common/enums/UserRole"

interface InputProps {
	children?: any
}

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LayoutAuthenticatedNoMenu = (props: InputProps) => {
	const [footerPadding, setFooterPadding] = useState<string>('0px')
	const [contentPadding, setContentPadding] = useState<string>('')
	let query = useQuery()
	let history = useHistory()

	const {dBUser}=useContext(UserContext)

	const setFooterAndContentPadding = () => {
		try {
			if (![1, 5, 6].includes(parseInt(query.get('tabNumber') ?? '1')) && (!(dBUser.role === UserRole.OPERATOR && (parseInt(query.get('tabNumber') ?? '1')) === 3))) {
				setFooterPadding(`${parseInt(query.get('tabNumber') ?? '') === 4 ? 80 : 60}px`)
				const x = document.getElementsByClassName('full-height-content')[0]
				let contentPadding = window.getComputedStyle(x, null).getPropertyValue('padding-bottom')
				if (parseInt(contentPadding) > 96) {
					setContentPadding(`calc( ${contentPadding} )`)
				}
				else {
					setContentPadding(`calc( ${contentPadding} + 60px )`)
				}
			}
			else {
				setFooterPadding('0px')
				setContentPadding('')
			}
		}
		catch (err) {
			setFooterPadding('0px')
			setContentPadding('')
		}
	}

	useEffect(() => {
		if (dBUser && dBUser._id) {
			setFooterAndContentPadding()
			const unlisten = history.listen(() => {
				setFooterAndContentPadding()
			})

			return () => {
				unlisten()
			  }
		}
	}, [props.children, history, query, dBUser])



	return (
		<div className="layout-authenticated-no-menu" id="layout-authenticated-no-menu">
			<OrganisationProvider>
					<RoleProtectedRoute>
						<AdminMenuBar />
						<div className="root">
							<main>
								<div className="full-height-content" style={{ paddingBottom: contentPadding }}>
									{props.children}
								</div>
								<footer style={{ paddingBottom: footerPadding }}>
									<AdminFooter />
								</footer>
							</main>
						</div>
					</RoleProtectedRoute>
			</OrganisationProvider>
		</div>
	)
}

export default LayoutAuthenticatedNoMenu
