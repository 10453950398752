import { LicenceStatus } from "../common/enums/LicenceStatus"
import ICampaign from "../interfaces/campaign"
import ILicenceBundle from "../interfaces/licenceBundle"
import IOrganisation from "../interfaces/organisation"

const useOrganisationCommonFunctions = () => {

	const determineActiveLicences = (totalEvents: number, organisation: IOrganisation): number => {
		const activeLicences = determineTotalLicences(organisation.licenceBundleList) - totalEvents
		return isNaN(activeLicences) ? 0 : activeLicences
	}

	const determineTotalLicences = (licenceBundleList: ILicenceBundle[]): number => {
		let totalLicences = 0
		licenceBundleList && licenceBundleList.filter(bundle => bundle.status === LicenceStatus.SUCCEEDED && bundle.isActive).forEach(element => {
			totalLicences += element.licenceCount
		})
		return totalLicences
	}


	const determineTotalFees = (organisation: IOrganisation, campaignList: ICampaign[] = []): number => {
		// let organisation = organisationList.filter(org => org._id === organisationId)[0]
		let totalFees = 0
		organisation.licenceBundleList.filter(x => x.isActive && x.status === "SUCCEEDED").forEach(element => {
			totalFees += element.bundlePrice * (1 - element.discountPercentage / 100)
		})

		const campaignsWithUniquePaymentRefIds: ICampaign[] =
			Object.values(
				campaignList.reduce(
					(acc, obj) => ({
						...acc,
						[obj.brochureToolPurchaseInfo?.paymentRefId]: obj,
					}),
					{},
				),
			)

		campaignsWithUniquePaymentRefIds.forEach(campaign => {
			if (campaign.brochureToolPurchaseInfo && campaign.brochureToolPurchaseInfo?.paymentRefId) {
				totalFees += (campaign.brochureToolPurchaseInfo?.totalCost ?? 0)
			}
		});
		return totalFees + ((organisation.totalRevenue ?? 0) - (organisation.netFundsCollected ?? 0))
	}

	return {
		determineActiveLicences,
		determineTotalLicences,
		determineTotalFees,
	}
}
export default useOrganisationCommonFunctions