import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogContent,
} from "@mui/material";
import "./confirmationDialog.scss";
import TickImage from "../../images/tick.gif";
// import TickImage from '../../images/tick.png'
import AlertIcon from "../../images/alert.png";
import CrossImage from "../../images/cross.png";
import QuestionMarkImage from "../../images/question.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom"

interface InputProps {
	isDialogOpen: boolean;
	handleClose: (boolean: boolean) => void;
	type: "AREYOUSURE" | "SUCCESS" | "ERROR" | "PARTIAL";
	label: string; //so that specific actions can be called from the component calling the dialog
	title: string;
	message: string;
	errorDetails?: string;
	primaryButton: JSX.Element;
	secondaryButton?: JSX.Element;
	countdownTimerSeconds?: number | null;
	redirectUrl?: string | null;
}


const ConfirmationDialog = (props: InputProps) => {
	const [secondsRemaining, setSecondsRemaining] = useState(3);

	const history = useHistory()

	const determineBackgroundColor = (): any => {
		if (props.type === "SUCCESS") {
			return { backgroundColor: "#9FFFA8" };
		} else if (props.type === "ERROR") {
			return { backgroundColor: "#FF6B6B" };
		} else if (props.type === "PARTIAL") {
			return { backgroundColor: "#F9AF52" };
		} else {
			return { backgroundColor: "#8896AB" };
		}
	};

	const determineIcon = (): any => {
		if (window.navigator.onLine) {
			if (props.type === "SUCCESS") {
				return <img alt="success" src={TickImage} />;
			} else if (props.type === "ERROR") {
				return <img alt="error" src={CrossImage} />;
			} else if (props.type === "PARTIAL") {
				return <img alt="partially-successful" src={AlertIcon} />;
			} else if (props.type === "AREYOUSURE") {
				return <img alt="confirmation" src={QuestionMarkImage} />;
			}
		}
		else {
			return <></>
		}
	};


	useEffect(() => {
		if (props.countdownTimerSeconds) {
			const timeout = setInterval(() => {
				if (secondsRemaining > 1) {
					setSecondsRemaining(secondsRemaining - 1)
					let timerBtn = document.getElementById('timer-button')
					if (timerBtn) {
						timerBtn.innerText = `Ok (${secondsRemaining - 1})`
					}
				} else {
					clearInterval(timeout)
					props.handleClose(false)
					if (props.redirectUrl) {
						history.push(props.redirectUrl)
					}
				}
			}, 1000)

			return () => {
				clearInterval(timeout)
			}
		}
	}, [secondsRemaining, props.countdownTimerSeconds])

	return (
		<>

			{props.type && props.title && props.message && (

				<Dialog
					open={props.isDialogOpen}
					onClose={() => props.handleClose(false)}
					aria-labelledby="dialog-title"
					className="confirmation-dialog"
				>
					<DialogContent>
						<div
							className="coloured-section"
							style={determineBackgroundColor()}
						>
							{determineIcon()}
						</div>
						<div className="content-section">
							<h1>{props.title}</h1>
							<span>{props.message}</span>

							{props.errorDetails && (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "15px",
									}}
								>
									<Accordion style={{ width: "90%" }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											View Error Details
										</AccordionSummary>
										<AccordionDetails>{props.errorDetails}</AccordionDetails>
									</Accordion>
								</div>
							)}
						</div>


						{props.secondaryButton ? (
							<div
								className="button-section"
								style={{ display: "flex", flexDirection: "column" }}
							>
								{props.primaryButton}
								{props.type === "AREYOUSURE" && (
									<Button
										className="w-100"
										onClick={() => props.handleClose(false)}
									>
										Cancel
									</Button>
								)}
								{props.secondaryButton}
							</div>
						) : (
							<div className="button-section">
								{props.type === "AREYOUSURE" && (
									<Button id="cancel-button" onClick={() => props.handleClose(false)}>
										Cancel
									</Button>
								)}
								{props.primaryButton}
							</div>
						)}
					</DialogContent>
				</Dialog>
			)}
		</>
	);
};

export default ConfirmationDialog;
