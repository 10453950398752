import { useState } from "react";
import { IIntegration } from "../../../interfaces/integration";
import IValidationObject from "../../../interfaces/validationObject";


const useIntegrationValidation = (integration: IIntegration) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const clearValidation = () => {
        setValidationObject([
            { name: "name", isValid: true, validationMessage: "" },
            { name: "provider", isValid: true, validationMessage: "" },
            { name: "apiKey", isValid: true, validationMessage: "" },
            { name: "apiUrl", isValid: true, validationMessage: "" },
        ])
    }

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "name", isValid: true, validationMessage: "" },
            { name: "provider", isValid: true, validationMessage: "" },
            { name: "apiKey", isValid: true, validationMessage: "" },
            { name: "apiUrl", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (integration.name === "" || typeof (integration.name) === "undefined") {
            setFailedValidation("name", "Please enter name for the integration.")
        }

        if (integration.apiKey === "" || typeof (integration.apiKey) === "undefined") {
            setFailedValidation("apiKey", "Please enter the API key for the integration.")
        }

        if (integration.apiUrl === "" || typeof (integration.apiUrl) === "undefined") {
            setFailedValidation("apiUrl", "Please enter the API URL for the integration.")
        }

        if (integration.provider === "" || typeof (integration.provider) === "undefined") {
            setFailedValidation("provider", "Please select a provider for the integration.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        clearValidation,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default useIntegrationValidation