import { Autocomplete, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignsBySearchStringQueryString } from '../../../common/ApiPaths'
import { LotType } from '../../../common/enums/LotType'
import { PricingOption } from '../../../common/enums/PricingOption'
import { OrganisationContext } from '../../../context/OrganisationContext'
import { UserContext } from '../../../context/UserContext'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import useFetch from '../../../hooks/useFetch'
import ICampaign from '../../../interfaces/campaign'
import ICatalogueItem from '../../../interfaces/catalogueItem'
import { ITransferDetail } from '../../../interfaces/transferDetails'
import IValidationObject from '../../../interfaces/validationObject'
import useTransferDetailsValidation from './useTransferDetailsValidation'

interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    items: ICatalogueItem[]
    handleSave: (details: ITransferDetail, items: ICatalogueItem[]) => void,
    defaultCampaign: ICampaign | null
}

const TransferDetailsDialog = (props: InputProps) => {
    const organisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)
    const [campaignList, setCampaignList] = useState<ICampaign[]>([])
    const [transferDetails, setTransferDetails] = useState<ITransferDetail>(
        {
            userId: dBUser._id,
            campaignId: '',
            campaignSlug: '',
            lotType: LotType.COMPETITIVE,
            pricing: PricingOption.SUGGESTED_RESERVE_PRICE,
            fixedAmount: 1,
            organisationId: organisation._id,
            startLotOrderNumber: 1,
            availabilityCount: 1,
            bidIncrement: 1
        })

    const transferDetailsValidation = useTransferDetailsValidation(transferDetails, organisation.currency)
    const commonFunctions = useCommonFunctions()
    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)

    const getCampaignList = async (input: string) => {
        if (input.length > 2) {
            let filteredCampaignList: ICampaign[] = await campaignsApi.get(
                `${CAMPAIGN_API_PATHS.GET_BY_SEARCH_STRING.replace('{searchString}', input)}${getCampaignsBySearchStringQueryString(dBUser._id, dBUser.role, true, undefined, dBUser.organisationId)}`
            )

            setCampaignList([...filteredCampaignList])
        } else {
            setCampaignList([])
        }
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        transferDetailsValidation.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    const getHelperTextForAmount = () => {
        switch (transferDetails.pricing) {
            case (PricingOption.FIXED_PLUS_CP):
                return "The amount to be added to the cost price of each lot."

            case (PricingOption.FIXED):
                return "The price for lot."

            case (PricingOption.PERCENTAGE):
                return "The percentage of cost price to be added for each lot."

            default:
                return ""
        }
    }

    const save = () => {
        if (transferDetailsValidation.validateInputs(props.items)) {
            transferDetailsValidation.clearValidation()
            transferDetailsValidation.setIsValidationPassed(true)
            props.handleSave(transferDetails, props.items)
            setTransferDetails({ ...transferDetails, lotType: LotType.COMPETITIVE, pricing: PricingOption.SUGGESTED_RESERVE_PRICE, campaignId: props.defaultCampaign?._id ?? '', campaignSlug: props.defaultCampaign?.slug ?? '', fixedAmount: 0, startLotOrderNumber: 1 })
            props.handleClose()
        }
    }

    const handleClose = () => {
        transferDetailsValidation.clearValidation()
        transferDetailsValidation.setIsValidationPassed(true)
        setCampaignList([])
        setTransferDetails({ ...transferDetails, lotType: LotType.COMPETITIVE, pricing: PricingOption.SUGGESTED_RESERVE_PRICE, campaignId: props.defaultCampaign?._id ?? '', campaignSlug: props.defaultCampaign?.slug ?? '', fixedAmount: 0, startLotOrderNumber: 1 })
        props.handleClose()
    }

    useEffect(() => {
        transferDetailsValidation.clearValidation()
    }, [organisation])

    useEffect(() => {
        setTransferDetails({ ...transferDetails, campaignId: props.defaultCampaign?._id ?? '', campaignSlug: props.defaultCampaign?.slug ?? '' })
    }, [props.defaultCampaign])

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className="item-details-dialog"
        >

            <DialogContent>
                <div className='coloured-section' >
                    <h2 className='title'>Transfer Details</h2>
                </div>
                <div className='content-wrapper'>
                    <Autocomplete
                        style={{ width: "100%" }}
                        disablePortal
                        options={campaignList}
                        noOptionsText="Please enter 3 characters to search..."
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} error={!getValidation('campaignId').isValid} label="Campaign" helperText={getValidation('campaignId').isValid ? "Enter 3 characters to see a list of available campaigns" : getValidation("campaignId").validationMessage} />}
                        onInputChange={(e, value) => getCampaignList(value)}
                        onChange={(event: any, newValue: ICampaign | null) => {
                            setTransferDetails({ ...transferDetails, campaignId: newValue?._id ?? '', campaignSlug: newValue?.slug ?? '' })
                        }}
                        clearOnEscape
                        defaultValue={props.defaultCampaign}
                    />

                    <FormControl
                        variant="outlined"
                        error={!getValidation("lotType").isValid}
                        style={{ width: "100%" }}
                    >
                        <InputLabel id="lot-type-label">Lot Type</InputLabel>
                        <Select
                            labelId="lot-type-label"
                            id="lotType"
                            name="lotType"
                            value={transferDetails.lotType}
                            label="Lot Type"
                            onChange={(event) => setTransferDetails({ ...transferDetails, lotType: LotType[event.target.value] })}
                        >
                            <MenuItem key={1} value={LotType.COMPETITIVE}>Competitive</MenuItem>
                            <MenuItem key={3} value={LotType.SEALED}>Sealed</MenuItem>
                            <MenuItem key={4} value={LotType.BUYITNOW}>Buy it Now</MenuItem>
                            <MenuItem key={5} value={LotType.VIEWONLY}>View Only</MenuItem>
                        </Select>
                        <FormHelperText>{getValidation("lotType").isValid ? 'What type of lot would you like this selection to be added to the campaign as?' : getValidation("lotType").validationMessage}</FormHelperText>
                    </FormControl>

                    <FormControl
                        variant="outlined"
                        error={!getValidation("pricing").isValid}
                        style={{ width: "100%" }}
                    >
                        <InputLabel id="pricing-label">Pricing</InputLabel>
                        <Select
                            labelId="pricing-label"
                            id="pricing"
                            name="pricing"
                            value={transferDetails.pricing}
                            label="Pricing"
                            onChange={(event) => {
                                setTransferDetails({ ...transferDetails, fixedAmount: PricingOption[event.target.value] === PricingOption.FIXED ? Math.max(...props.items.map(x => x.costPrice)) : 1, pricing: PricingOption[event.target.value] })
                                transferDetailsValidation.clearValidation()
                            }}
                        >
                            <MenuItem key={1} value={PricingOption.SUGGESTED_RESERVE_PRICE}>Suggested Reserve Price</MenuItem>
                            <MenuItem key={2} value={PricingOption.TYPICALLY_SELLS_FOR}>Typically Sells For</MenuItem>
                            <MenuItem key={3} value={PricingOption.FIXED_PLUS_CP}>Cost price Plus Fixed</MenuItem>
                            <MenuItem key={4} value={PricingOption.PERCENTAGE}>Cost Price Plus Percentage</MenuItem>
                            <MenuItem key={5} value={PricingOption.FIXED}>User Defined</MenuItem>
                        </Select>
                        <FormHelperText>{getValidation("pricing").isValid ? 'Pricing for the lot' : getValidation("pricing").validationMessage}</FormHelperText>
                    </FormControl>

                    {(transferDetails.pricing !== PricingOption.TYPICALLY_SELLS_FOR && transferDetails.pricing !== PricingOption.SUGGESTED_RESERVE_PRICE) && (
                        <TextField
                            id="fixedAmount"
                            style={{ width: "100%" }}
                            name="fixedAmount"
                            label={`${transferDetails.pricing === PricingOption.PERCENTAGE ? 'Percentage' : transferDetails.pricing === PricingOption.FIXED ? 'Start / Reserve Price' : 'Fixed Amount'}`}
                            variant="outlined"
                            type="number"
                            onWheel={(e) => (e.target as any).blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{transferDetails.pricing === PricingOption.PERCENTAGE ? '%' : commonFunctions.getCurrencySymbol(organisation.currency ?? 'GBP')}</InputAdornment>,
                                inputProps: { min: 1 }
                            }}
                            onChange={(event) => {
                                setTransferDetails({ ...transferDetails, fixedAmount: Number(event.target.value) })
                                event.target.value = Number(event.target.value).toString()
                            }}
                            value={Number(transferDetails.fixedAmount)}
                            error={!getValidation("fixedAmount").isValid}
                            helperText={getValidation("fixedAmount").isValid ? getHelperTextForAmount() : getValidation("fixedAmount").validationMessage}
                        />
                    )}

                    <TextField
                        id="startLotOrderNumber"
                        style={{ width: "100%" }}
                        name="startLotOrderNumber"
                        label={'Start Lot order number'}
                        variant="outlined"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        InputProps={{
                            inputProps: { min: 1 }
                        }}
                        onChange={(event) => {
                            setTransferDetails({ ...transferDetails, startLotOrderNumber: Number(event.target.value ?? 1) })
                            event.target.value = Number(event.target.value ?? 1).toString()
                        }}
                        value={Number(transferDetails.startLotOrderNumber)}
                        error={!getValidation("startLotOrderNumber").isValid}
                        helperText={getValidation("startLotOrderNumber").isValid ? 'The lot number to where the new lots will be inserted' : getValidation("startLotOrderNumber").validationMessage}
                    />

                    {transferDetails.lotType !== "VIEWONLY" &&
                        <TextField
                            id="availabilityCount"
                            style={{ width: "100%" }}
                            name="availabilityCount"
                            label="Number Available"
                            variant="outlined"
                            type="number"
                            onWheel={(e) => (e.target as any).blur()}
                            value={Number(transferDetails.availabilityCount)}
                            onChange={(event) => {
                                setTransferDetails({ ...transferDetails, availabilityCount: Number(event.target.value ?? 1) })
                                event.target.value = Number(event.target.value ?? 1).toString()
                            }}
                            InputProps={{ inputProps: { min: 1 } }}
                            error={!getValidation("availabilityCount").isValid}
                            helperText={getValidation("availabilityCount").isValid ? "The number of the lot that are available to be sold." : getValidation("availabilityCount").validationMessage}
                        />
                    }

                    {transferDetails.lotType !== "BUYITNOW" && transferDetails.lotType !== "VIEWONLY" && transferDetails.lotType !== "SEALED" &&
                        <TextField
                            id="bidIncrement"
                            name="bidIncrement"
                            label="Bid Increment"
                            variant="outlined"
                            type="number"
                            onWheel={(e) => (e.target as any).blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{commonFunctions.getCurrencySymbol(organisation.currency ?? 'GBP')}</InputAdornment>,
                                inputProps: { min: 1 }
                            }}
                            value={transferDetails.bidIncrement}
                            onChange={(event) => {
                                setTransferDetails({ ...transferDetails, bidIncrement: Number(event.target.value ?? 1) })
                                event.target.value = Number(event.target.value ?? 1).toString()
                            }}
                            fullWidth
                            error={!getValidation("bidIncrement").isValid}
                            helperText={getValidation("bidIncrement").isValid ? `The next highest bid value that will be accepted. Eg current bid ${commonFunctions.getCurrencySymbol(organisation.currency ?? 'GBP')}10, bid increment of ${commonFunctions.getCurrencySymbol(organisation.currency ?? 'GBP')}5, next acceptable bid ${commonFunctions.getCurrencySymbol(organisation.currency ?? 'GBP')}15.` : getValidation("bidIncrement").validationMessage}
                        />
                    }

                </div>

            </DialogContent>
            <DialogActions>
                <div className="action-section">
                    <>{!transferDetailsValidation.isValidationPassed && <p className="validation-text">Something's not right, please check highlighted fields above.</p>}</>

                    <div className="button-wrapper">
                        <Button onClick={() => handleClose()} className="cancel">
                            Cancel
                        </Button>

                        <Button
                            onClick={() => save()}
                            className="gg-button"
                        >
                            <span>Transfer</span>
                        </Button>

                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default TransferDetailsDialog