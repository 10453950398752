import React, { useEffect, useState } from 'react'
import useFetch from '../../../hooks/useFetch';
import './bigScreen.scss'
import { IBigScreenPayload } from '../../../interfaces/bigScreenPayload';
import BigScreenCarousel from './BigScreenCarousel';
import { IBigScreenLot } from '../../../interfaces/bigScreenLot';
import BidNotificationDialog from './BidNotificationDialog';
import BigScreenCountdownTimer from './BigScreenCountdownTimer';
import AdHocDialog from './AdHocDialog';
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import { useParams } from "react-router-dom"


const BigScreen = ({ match }) => {
    const [bigScreenPayload, setBigScreenPayload] = useState<IBigScreenPayload>({} as IBigScreenPayload)
    const [isBigScreenFound, setIsBigScreenFound] = useState<any>(true)
    const [isBidNotificationDialogOpen, setIsBidNotificationDialogOpen] = useState<boolean>(false)
    const [newBidQueue, setNewBidQueue] = useState<IBigScreenLot[]>([])
    const [currentLatestBid, setCurrentLatestBid] = useState<IBigScreenLot>({} as IBigScreenLot)
    let { campaignSlug } = useParams()

    const bigScreenApi = useFetch("big-screens");
    const commonFunctions = useCommonFunctions()

    const getBigScreenPayload = (): void => {
        bigScreenApi.get(`/${campaignSlug}/payload`).then((data: IBigScreenPayload) => {
            if (data) {

                //remove child lots
                for (let i = 0; i < data.lotList.length; i++) {
                    if (data.lotList[i].parentLotId) {
                        data.lotList.splice(i, 1)
                        i--
                    }
                }
                checkForNewBid(data.latestBid)
                setBigScreenPayload(data)
            } else {
                setIsBigScreenFound(false)
            }
        }).catch((err: Error) => {
            console.log(err)
        })
    }

    const checkForNewBid = (newBid: IBigScreenLot) => {
        if (newBid && newBid.winningBidId !== currentLatestBid.winningBidId) {
            if (currentLatestBid.winningBidId) {
                let tempNewBidQueue = [...newBidQueue]
                tempNewBidQueue.push(newBid)
                setNewBidQueue(tempNewBidQueue)
            }
            setCurrentLatestBid(newBid)
        }
    }

    const determineBidType = (bidType: string): string => {
        if (bidType === "CUMULATIVE") {
            return "pledge"
        } else if (bidType === "BUYITNOW") {
            return "purchase"
        } else {
            return "bid"
        }
    }

    useEffect(() => {
        if (newBidQueue.length > 0) {
            setIsBidNotificationDialogOpen(true)
            newBidQueue.splice(0, 1)
            let timer = setTimeout(() => {
                setIsBidNotificationDialogOpen(false)
            }, (bigScreenPayload.bidPopupTime * 1000));

            return () => {
                clearTimeout(timer);
            }
        }
    }, [newBidQueue])

    useEffect(() => {
        if (campaignSlug) {
            getBigScreenPayload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignSlug])

    useEffect(() => {
        let timer = setTimeout(() => {
            getBigScreenPayload()
        }, 10000);

        return () => {
            clearTimeout(timer);
        }
    }, [bigScreenPayload])



    return (
        <>
            {
                bigScreenPayload.campaignId &&
                <div className='big-screen'>
                    <div className='top-section-wrapper'>
                        <section className='header' style={{ backgroundColor: bigScreenPayload.headerBackgroundColor, color: bigScreenPayload.headerFontColor }}>
                            <div className='logo-wrapper' style={{ width: `${180 * bigScreenPayload.headerFontSize}px` }}>
                                {bigScreenPayload.logoUrlList[0] && <img alt="logo" src={bigScreenPayload.logoUrlList[0]} />}
                            </div>

                            {bigScreenPayload.isCountdownTimer ?
                                <BigScreenCountdownTimer
                                    countdownStartDate={bigScreenPayload.countdownStartDate}
                                    countdownEndDate={bigScreenPayload.countdownEndDate}
                                    campaignNotYetOpenTitle={bigScreenPayload.campaignNotYetOpenTitle}
                                    countdownClosedMessage={bigScreenPayload.countdownClosedMessage}
                                    campaignLiveTitle={bigScreenPayload.campaignLiveTitle}
                                />
                                :
                                <div className='title-wrapper' style={{ fontSize: `${2.5 * bigScreenPayload.headerFontSize}rem` }}>
                                    {bigScreenPayload.title}
                                </div>
                            }

                            <div className='qr-wrapper' style={{ width: `${120 * bigScreenPayload.headerFontSize}px`, marginLeft: `${60 * bigScreenPayload.headerFontSize}px` }}>
                                {bigScreenPayload.qrCodeUrlList[0] && <img alt="qr-code" src={bigScreenPayload.qrCodeUrlList[0]} />}
                            </div>
                        </section>
                        <section className='lots'>
                            <BigScreenCarousel bigScreenPayload={bigScreenPayload} />
                        </section>
                    </div>
                    <section className='footer'>
                        {bigScreenPayload.latestBid &&
                            <>
                                <span>Latest {determineBidType(bigScreenPayload.latestBid.type)}: {bigScreenPayload.latestBid.title}</span>
                                {(bigScreenPayload.latestBid.type !== "SEALED") &&
                                    <>
                                        <span> - {new Intl.NumberFormat('en-US', { style: 'currency', currency: bigScreenPayload.currencyCode, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(bigScreenPayload.latestBid.winningBid)}</span>
                                        <span> - {commonFunctions.titleCase(bigScreenPayload.latestBid.winningBidderName)}</span>
                                    </>
                                }
                            </>
                        }
                    </section>
                </div>
            }

            <BidNotificationDialog
                isDialogOpen={isBidNotificationDialogOpen}
                lot={currentLatestBid}
                bigScreenPayload={bigScreenPayload}
            />

            <AdHocDialog
                isShowAdHocPopup={bigScreenPayload.isShowAdHocPopup}
                adHocPopupWidthPercentage={bigScreenPayload.adHocPopupWidthPercentage}
                adHocPopupTitle={bigScreenPayload.adHocPopupTitle}
                adHocPopupImageUrl={bigScreenPayload.adHocPopupImageUrl}
                adHocPopupImageSize={bigScreenPayload.adHocPopupImageSize}
                adHocPopupisShowCountdown={bigScreenPayload.adHocPopupisShowCountdown}
                adHocPopupBody={bigScreenPayload.adHocPopupBody}
                countdownStartDate={bigScreenPayload.countdownStartDate}
                countdownEndDate={bigScreenPayload.countdownEndDate}
                campaignNotYetOpenTitle={bigScreenPayload.campaignNotYetOpenTitle}
                countdownClosedMessage={bigScreenPayload.countdownClosedMessage}
                campaignLiveTitle={bigScreenPayload.campaignLiveTitle}
                closeAction={() => { }}
            />
        </>
    )
}

export default BigScreen