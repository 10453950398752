import React, { useContext, useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { API_PATHS, CAMPAIGN_API_PATHS } from "../common/ApiPaths"
import { UserRole } from "../common/enums/UserRole"
import Loading from "../components/shared/Loading"
import { OrganisationContext } from "../context/OrganisationContext"
import { UserContext } from "../context/UserContext"
import useFetch from "../hooks/useFetch"
import ICampaign from "../interfaces/campaign"
import IOrganisation from "../interfaces/organisation"
import { useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

const RoleProtectedRoute = (props) => {
  const { dBUser } = useContext(UserContext)
  const organisation: IOrganisation = useContext(OrganisationContext)
  const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)

  const [isAuthorized, setIsAuthorized] = useState('undefined')
  const [authorizedCampaigns, setAuthorizedCampaigns] = useState<ICampaign[]>([])
  // const { isAuthenticated, isLoading } = useAuth0()

  let { campaignSlug } = useParams()

  const getCampaignsForUser = async () => {
    if (dBUser && dBUser._id && (dBUser.role !== UserRole.SUPER_ADMIN && dBUser.role !== UserRole.ADMIN)) {
      try {
        let campaigns: ICampaign[] = await campaignsApi.get(CAMPAIGN_API_PATHS.FIND_CAMPAIGNS_BY_USERID.replace('{userId}', dBUser._id))
        setAuthorizedCampaigns(campaigns)
      }
      catch (err: any) {
        setAuthorizedCampaigns([])

      }
    }
  }

  useEffect(() => {
    if (campaignSlug && dBUser && dBUser._id && (dBUser.role !== UserRole.SUPER_ADMIN && dBUser.role !== UserRole.ADMIN)) {
      if (authorizedCampaigns.find(x => x.slug === campaignSlug)) {
        setIsAuthorized('true')
      }
      else {
        setIsAuthorized('false')
      }
    }
  }, [authorizedCampaigns])


  // useEffect(() => {
  //   if (!isLoading && !isAuthenticated) {
  //     setIsAuthorized('false')
  //   }
  // }, [isLoading, isAuthenticated])

  useEffect(() => {
    if (dBUser._id) {
      let authorized = isAuthorized
      getCampaignsForUser()


      if (window.location.href.startsWith(`${window.location.origin}/authenticate`)) {
        authorized = 'true'
      }

      else if (dBUser && dBUser.role === UserRole.SUPER_ADMIN) {
        authorized = 'true'
      }

      else if (dBUser && dBUser.role === UserRole.NONE) {
        authorized = 'false'
      }

      else if (dBUser && dBUser._id && organisation && organisation._id) {
        if (dBUser.role === UserRole.ADMIN) {
          if (dBUser.organisationId !== organisation._id) {
            authorized = 'false'
          }
          else if (window.location.href === `https://${organisation.domain}/organisations` || window.location.href === `https://${organisation.domain}/organisations/`) {
            authorized = 'false'
          }
          if (authorized === 'undefined') {
            authorized = 'true'
          }
        }

        else if (dBUser.role === UserRole.OPERATOR) {
          if (dBUser.organisationId !== organisation._id) {
            authorized = 'false'
          }
          else {
            if (window.location.href === `https://${organisation.domain}/organisations` || window.location.href === `https://${organisation.domain}/organisations/`) {
              authorized = 'false'
            }
            const query = new URLSearchParams(window.location.search)
            if (query.get("tabNumber") === '2') {
              authorized = 'false'
            }
            if (window.location.href.endsWith('campaigns/new') || window.location.href.endsWith('campaigns/new/')) {
              authorized = 'false'
            }
          }
          if (authorized === 'undefined') {
            authorized = 'true'
          }
        }
        else {
          authorized = 'false'
        }
      }

      else if (window.location.href === `https://${organisation.domain}/organisations` || window.location.href === `https://${organisation.domain}/organisations/`) {
        if (dBUser._id && dBUser.role !== UserRole.SUPER_ADMIN) {
          authorized = 'false'
        }
      }
      setIsAuthorized(authorized)
    }
  }, [dBUser, organisation])

  return (
    <React.Fragment>
      {/* {dBUser.role && (dBUser.role === UserRole.SUPER_ADMIN || organisation._id) ? ( */}
      {isAuthorized !== 'undefined' ? (
        <>
          {
            isAuthorized === 'true' ? (
              props.component ? props.component : props.children
            ) : (
              <Redirect to={{ pathname: "/", search: "unauthorised" }} />
            )
          }
        </>
      ) : (
        <>
          <Loading />
        </>
      )}

    </React.Fragment>
  )
}
export default RoleProtectedRoute

