import React, { useContext, useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { auth } from "./auth0.service"
import { Auth0ParseHashError, Auth0DecodedHash, Auth0UserProfile, Auth0Error } from 'auth0-js'
import { UserContext } from "../context/UserContext"
import { UserRole } from "../common/enums/UserRole"
import { useHistory } from "react-router-dom"
import { API_PATHS, ORGANISATIONS_API_PATHS } from "../common/ApiPaths"
import useFetch from "../hooks/useFetch"
import IOrganisation from "../interfaces/organisation"
import LoadingSpinner from "../components/shared/LoadingSpinner"
import "./postAuthenticate.scss"

const PostAuthenticate = () => {
    const location = useLocation()
    const { userLoading, dBUser, login, logout } = useContext(UserContext)
    const [loggedInUser, setLoggedInUser] = useState({} as Auth0UserProfile)
    let history = useHistory()

    const organisationApi = useFetch(API_PATHS.ORGANISATIONS)

    useEffect(() => {
        if (loggedInUser && dBUser && dBUser._id) {
            const redirectUri = localStorage.getItem('redirectUri')
            if (!redirectUri?.length) {

                if (dBUser.role === UserRole.SUPER_ADMIN) {
                    history.push(`/organisations`)
                } else if (dBUser.role === UserRole.ADMIN || dBUser.role === UserRole.OPERATOR) {
                    getOrganisationSlug(dBUser.organisationId).then((organisationSlug) => {
                        history.push(`/organisations/${organisationSlug}?tabNumber=0`)
                    })
                } else {
                    console.log('not admin')
                    history.push('/?not-admin')
                    logout()
                }
            }
            else {
                if (!redirectUri.includes('?')) {
                    history.push(`${redirectUri}?loggedIn`)
                }
                else {
                    history.push(`${redirectUri}`)
                }
            }
            localStorage.setItem('redirectUri', '')
        }else {
            if (loggedInUser && !userLoading && !dBUser._id) {
                history.push(`/?unauthorised`)
                logout()
            }
        }
    }, [loggedInUser, dBUser, userLoading])

    const getOrganisationSlug = async (organisationId: string): Promise<string> => {
        const org: IOrganisation = await organisationApi.get(`${ORGANISATIONS_API_PATHS.GET_ORGANISATION}?organisationId=${organisationId}`)
        return org.slug
    }

    const processHash = (hash: string) => {
        auth.parseHash({
            hash
        }, (error: Auth0ParseHashError | null, result: Auth0DecodedHash | null) => {
            if (error) {
                console.log(error)
            }else {
                const { accessToken } = result
                if (accessToken) {
                    auth.client.userInfo(accessToken, (error: Auth0Error | null, result: Auth0UserProfile) => {
                        if (error) {
                            console.log(error)
                            history.push(`/?unauthorised`)
                            logout()
                        }else {
                            localStorage.setItem('accessToken', accessToken)
                            login(result)
                            setLoggedInUser(result)
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (location.hash) {
            processHash(location.hash)
        }

    }, [location])


    return (
        <div className="centered-container">
            <div className="centered-content">
                <LoadingSpinner
                    text={'Logging In'}
                />
            </div>
        </div>
    )
}

export default PostAuthenticate