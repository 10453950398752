import {
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	FormGroup,
	InputAdornment,
	Slider,
	TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import ILotCatalogueFilters from "../../../interfaces/lotCatalogueFilters"
import SearchIcon from "@mui/icons-material/Search"
import TuneIcon from "@mui/icons-material/Tune"
import "./itemCatalogueFilterSection.scss"
import TagButton from "../../shared/TagButton"

interface InputProps {
	lotCatalogueFilters: ILotCatalogueFilters
	setLotCatalogueFilters: (obj: ILotCatalogueFilters) => void
	sourcesList: string[]
	tagsList: string[]
	minAndMaxCostPrice: number[]
	itemNameList: string[]
	setItemNameList: (obj: string[]) => void
	resetStartIds: () => void
	disableFilters: boolean
	currency: string
}

const ItemCatalogueFilterSection = (props: InputProps) => {
	const [isClearFilters, setIsClearFilters] = useState<boolean>(false)
	const [name, setName] = useState<string>(props.lotCatalogueFilters.name ?? "")
	const [costPrice, setCostPrice] = useState<number[]>([0, 0])
	const [isShowFilterSection, setIsShowFilterSection] = useState<boolean>(false)

	const handleSelectAllTagsClick = event => {
		props.resetStartIds()
		if (
			props.lotCatalogueFilters.tags.length < props.tagsList.length &&
			!(props.lotCatalogueFilters.tags.length === 1 && props.lotCatalogueFilters.tags[0] === "all")
		) {
			props.setLotCatalogueFilters({ ...props.lotCatalogueFilters, tags: [...props.tagsList] })
		} else {
			props.setLotCatalogueFilters({ ...props.lotCatalogueFilters, tags: [] })
		}
	}

	const clearFilters = () => {
		props.resetStartIds()
		props.setLotCatalogueFilters({
			name: null,
			costPrice: [props.minAndMaxCostPrice[0], props.minAndMaxCostPrice[1]],
			sources: ["all"],
			tags: ["all"],
		} as ILotCatalogueFilters)
		props.setItemNameList([])
		setName("")
		setCostPrice([props.minAndMaxCostPrice[0], props.minAndMaxCostPrice[1]])
		setIsClearFilters(!isClearFilters)
	}

	const handleClick = event => {
		let target: any = event.target

		let isSearchFilterAreaFound = false
		while (target) {
			if (target.id === "item-catalogue-filter-section") {
				isSearchFilterAreaFound = true
			}
			target = target.parentElement
		}

		if (!isSearchFilterAreaFound) {
			if (isShowFilterSection) {
				setIsShowFilterSection(false)
			}
		}
	}

	useEffect(() => {
		window.addEventListener("click", handleClick)

		// cleanup
		return () => {
			window.removeEventListener("click", handleClick)
		}
	}, [isShowFilterSection])

	useEffect(() => {
		if (props.minAndMaxCostPrice) {
			setCostPrice([props.minAndMaxCostPrice[0], props.minAndMaxCostPrice[1]])
		}
	}, [props.minAndMaxCostPrice])

	return (
		<>
			<div className="item-catalogue-filter-section" id="item-catalogue-filter-section">
				<TextField
					className="search-input"
					label="Search for Items"
					disabled={props.disableFilters}
					onKeyPress={(event) => {
						if (event.key === '\\') {
							event.preventDefault();
						}
					}}
					onChange={(event: any) => {
						setName(event.target.value)
						if (event.target.value.length >= 3) {
							props.resetStartIds()
							props.setLotCatalogueFilters({ ...props.lotCatalogueFilters, name: event.target.value })
						}
					}}
					value={name}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment
								className={props.disableFilters ? "" : "clickable-input-icon"}
								position="end"
								// onClick={() => {isShowFilterSection.current = true}}
								onClick={() => props.disableFilters ? {} : setIsShowFilterSection(!isShowFilterSection)}
							>
								<TuneIcon />
							</InputAdornment>
						),
					}}
				/>

				<Card className="item-filter-section" style={{ display: isShowFilterSection ? "block" : "none" }}>
					<CardContent>
						<div className="sub-section">
							<span className="title">Sources</span>
							<FormGroup>
								<div style={{ display: "inline-flex" }}>
									{props.sourcesList.map((source, index) => (
										<FormControlLabel
											key={index}
											control={
												<Checkbox
													onChange={e => {
														props.resetStartIds()
														const sourcesSet =
															props.lotCatalogueFilters.sources.length === 1 &&
																props.lotCatalogueFilters.sources[0] === "all"
																? props.sourcesList
																: [...props.lotCatalogueFilters.sources]
														if (e.target.checked) {
															props.setLotCatalogueFilters({
																...props.lotCatalogueFilters,
																sources: [...sourcesSet, source],
															})
														} else {
															let updated = sourcesSet.filter(x => x !== source)
															props.setLotCatalogueFilters({ ...props.lotCatalogueFilters, sources: updated })
														}
													}}
													checked={
														(props.lotCatalogueFilters.sources.length === 1 &&
															props.lotCatalogueFilters.sources[0] === "all") ||
														props.lotCatalogueFilters.sources.indexOf(source) !== -1
													}
												/>
											}
											label={source}
										/>
									))}
								</div>
							</FormGroup>
						</div>

						<div className="sub-section">
							<span className="title">Cost Price</span>
							<div className="field-wrapper" style={{ width: "90%", marginLeft: "20px", marginRight: "20px", marginTop: 36 }}>
								<Slider
									getAriaLabel={() => "Cost Price"}
									valueLabelFormat={value =>
										`${new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: props.currency ?? "GBP",
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										}).format(value)}`
									}
									value={costPrice}
									step={100}
									min={props.minAndMaxCostPrice[0]}
									max={props.minAndMaxCostPrice[1]}

									valueLabelDisplay="on"
									onChange={(event, value) => {
										setCostPrice(value as number[])
									}}
									onChangeCommitted={(event, value) => {
										props.resetStartIds()
										props.setLotCatalogueFilters({ ...props.lotCatalogueFilters, costPrice: value })
									}}
								/>
							</div>
						</div>

						{props.tagsList.length > 0 && (
							<div className="sub-section">
								<div className="title-wrapper" style={{ display: "flex", flexDirection: "row" }}>
									<span className="title">Tags</span>
									<div className="clear-filter-wrapper">
										<a className="hyperlink" style={{ fontSize: "0.8rem" }} href="#" onClick={handleSelectAllTagsClick}>
											{props.lotCatalogueFilters.tags.length < props.tagsList.length &&
												!(props.lotCatalogueFilters.tags.length === 1 && props.lotCatalogueFilters.tags[0] === "all")
												? "Select All"
												: "Unselect All"}
										</a>
									</div>
								</div>
								<FormGroup>
									<div style={{ marginTop: 12 }}>
										{props.tagsList.map((tag, index) => (
											<TagButton
												key={`${index}${tag}`}
												isSelected={
													(props.lotCatalogueFilters.tags.length === 1 &&
														props.lotCatalogueFilters.tags[0] === "all") ||
													props.lotCatalogueFilters.tags.indexOf(tag) !== -1
												}
												isDiabled={false}
												text={tag}
												onClick={() => {
													props.resetStartIds()
													const tagsSet =
														props.lotCatalogueFilters.tags.length === 1 && props.lotCatalogueFilters.tags[0] === "all"
															? props.tagsList
															: [...props.lotCatalogueFilters.tags]

													if (tagsSet.indexOf(tag) === -1) {
														props.setLotCatalogueFilters({ ...props.lotCatalogueFilters, tags: [...tagsSet, tag] })
													} else {
														let updated = tagsSet.filter(x => x !== tag)
														props.setLotCatalogueFilters({ ...props.lotCatalogueFilters, tags: updated })
													}
												}}
											/>
										))}
									</div>
								</FormGroup>
							</div>
						)}
						{(props.lotCatalogueFilters.name ||
							(props.lotCatalogueFilters.costPrice && props.lotCatalogueFilters.costPrice[0] !== props.minAndMaxCostPrice[0]) ||
							(props.lotCatalogueFilters.costPrice && props.lotCatalogueFilters.costPrice[1] !== props.minAndMaxCostPrice[1]) ||
							(props.lotCatalogueFilters.sources.length >= 1 && props.lotCatalogueFilters.sources[0] !== "all") ||
							(props.lotCatalogueFilters.sources.length === 0) ||
							(props.lotCatalogueFilters.tags.length === 0) ||
							(((props.lotCatalogueFilters.tags.length >= 1 && props.lotCatalogueFilters.tags[0] !== "all")
								&& props.lotCatalogueFilters.tags.length !== props.tagsList.length))

						) && (
								<div className="clear-filter-wrapper">
									<a className="hyperlink" style={{ fontSize: "0.8rem" }} href="#" onClick={() => clearFilters()}>
										Clear filters
									</a>
								</div>
							)}
					</CardContent>
				</Card>


				{(props.lotCatalogueFilters.name ||
					(props.lotCatalogueFilters.costPrice && props.lotCatalogueFilters.costPrice[0] !== props.minAndMaxCostPrice[0]) ||
					(props.lotCatalogueFilters.costPrice && props.lotCatalogueFilters.costPrice[1] !== props.minAndMaxCostPrice[1]) ||
					(props.lotCatalogueFilters.sources.length >= 1 && props.lotCatalogueFilters.sources[0] !== "all") ||
					(props.lotCatalogueFilters.tags.length >= 1 && props.lotCatalogueFilters.tags[0] !== "all")) && (
						<div className="clear-filter-wrapper">
							<a className="hyperlink" style={{ fontSize: "0.8rem" }} href="#" onClick={() => clearFilters()}>
								Clear filters
							</a>
						</div>
					)}
			</div>
		</>
	)
}

export default ItemCatalogueFilterSection