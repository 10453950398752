import React from "react"
import SummaryFigureCard from "./SummaryFigureCard"
import './summaryFigures.scss'

interface InputProps {
	nameValuePairList: { name: string, value: any }[]
}

const SummaryFigures = (props: InputProps) => {
	return (
		<div className="summary-figures">
			{props.nameValuePairList.map((nameValuePair, index) => {
				return (
					<SummaryFigureCard label={nameValuePair.name} value={nameValuePair.value} key={index} />
				)
			})}
		</div>
	)
}

export default SummaryFigures
