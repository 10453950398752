import React, { useContext, useEffect, useRef, useState } from "react"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import CampaignList from "../campaignList/CampaignList"
import UserList from "../userList/UserList"
import OrganisationSettings from "../organisationSettings/OrganisationSettings"
import { IUser } from "../../../interfaces/user"
import AuditLog from "../auditLog/AuditLog"
import ICampaign from "../../../interfaces/campaign"
import OrganisationSummary from "../organisationSummary/OrganisationSummary"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, CAMPAIGN_API_PATHS, getFilteredUserListQueryString, getValidCampaignsQueryString, USER_API_PATHS } from "../../../common/ApiPaths"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import { useHistory } from "react-router-dom"
import { UserRole } from "../../../common/enums/UserRole"
import { OrganisationContext } from "../../../context/OrganisationContext"
import LotCatalogue from "../itemCatalogue/ItemCatalogue"
import { UserContext } from "../../../context/UserContext"
import Button from "@mui/material/Button/Button"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import './adminHome.scss'
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const AdminHome = () => {

	const organisationMenuItems = [
		"Organisation Summary",
		"Organisation Settings",
		"Campaign List",
		"Item Catalogue",
		"User List",
		"Audit Log",
	]

	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string>("")
	const [value, setValue] = useState("1")
	const isCancelled = useRef(false)
	const [campaignList, setCampaignList] = useState<ICampaign[]>([])
	const [userList, setUserList] = useState<IUser[]>([])
	const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "" })

	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	const usersApi = useFetch(API_PATHS.USERS)
	let history = useHistory()
	const organisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)
	const commonFunctions = useCommonFunctions()



	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue)
		history.push(`${organisation.slug}?tabNumber=${newValue}`)
	}

	const handleSelectChange = (event) => {
		const { value } = event.target
		setValue(value)
		history.push(`${organisation.slug}?tabNumber=${value}`)
	}

	const getCampaignList = async (): Promise<void> => {
		setLoading(true)
		try {
			const result = await campaignsApi.get(
				`${CAMPAIGN_API_PATHS.GET_VALID_CAMPAIGNS}${getValidCampaignsQueryString(undefined, organisation._id)}`
			)
			setCampaignList(result)
			setLoading(false)
		} catch (err: any) {
			setError(err.message)
			setLoading(false)
		}
	}

	const getUserList = async (): Promise<void> => {
		const result: IUser[] = await usersApi.get(`${USER_API_PATHS.GET_USERS_LIST}${getFilteredUserListQueryString(undefined, organisation._id)}`)
		setUserList(result.filter(x => x.role !== UserRole.NONE && x.role !== UserRole.SUPER_ADMIN))
	}

	const handleCloseDialog = () => {
		setDialogProperties({ isOpen: false })
		history.push(`${organisation.slug}?tabNumber=${value}`)
	}

	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search)
		const tabNumber = query.get("tabNumber")
		if (query.get("tabNumber")) {
			setValue(String(query.get("tabNumber")))
		}

		if (query.get("success")) {
			setDialogProperties({
				isOpen: true,
				type: "SUCCESS",
				title: tabNumber === '2' ? "PDF Brochure Generation Purchase Success" : "Licence Bundle Purchase Success.",
				message: tabNumber === '2' ? "Your PDF brochure generation purchase was successful" : "Your licence bundle purchase was successful.",
			})
		}

		if (query.get("cancelled")) {
			setDialogProperties({
				isOpen: true,
				type: "ERROR",
				title: tabNumber === '2' ? "PDF Brochure Generation Purchase Incomplete" : "Licence Bundle Purchase Incomplete.",
				message: `Your ${tabNumber === '2' ? 'PDF brochure generation tool' : 'licence bundle'} purchase was incomplete. Please try again or contact an administrator for support.`,
			})
		}

		// getOrganisation()

		return () => {
			isCancelled.current = true
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (organisation._id) {
			getCampaignList()
			getUserList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation])

	return (
		<div className="admin-home">
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList onChange={handleTabChange}	>
						<Tab label={organisationMenuItems[0]} value="0" />
						{dBUser.role !== UserRole.OPERATOR && (
							<Tab label={organisationMenuItems[1]} value="1" />
						)}
						<Tab label={organisationMenuItems[2]} value="2" />
						<Tab label={organisationMenuItems[3]} value="3" />
						<Tab label={organisationMenuItems[4]} value="4" />
						<Tab label={organisationMenuItems[5]} value="5" />
					</TabList>

				</Box>
				<FormControl
					variant="outlined"
					fullWidth
					id="mobile-select-menu"
				>
					<InputLabel id="mobile-select-nav-label">Menu</InputLabel>
					<Select
						labelId="mobile-select-nav-label"
						id="mobile-select-nav"
						name="Menu"
						fullWidth
						onChange={handleSelectChange}
						label="Menu"
						value={value}
					>
						<MenuItem value={'0'}>{organisationMenuItems[0]}</MenuItem>
						{dBUser.role !== UserRole.OPERATOR && (
							<MenuItem value={'1'}>{organisationMenuItems[1]}</MenuItem>
						)}
						<MenuItem value={'2'}>{organisationMenuItems[2]}</MenuItem>
						{/* <MenuItem value={'3'}>{organisationMenuItems[3]}</MenuItem> */}
						<MenuItem value={'4'}>{organisationMenuItems[4]}</MenuItem>
						<MenuItem value={'5'}>{organisationMenuItems[5]}</MenuItem>

					</Select>
				</FormControl>
				<TabPanel value="0">
					<OrganisationSummary campaignList={campaignList} />
				</TabPanel>
				<TabPanel value="1">
					<OrganisationSettings campaignList={campaignList} userList={userList} getUserList={getUserList} />
				</TabPanel>
				<TabPanel value="2">
					<CampaignList />
				</TabPanel>
				<TabPanel value="3">
					<LotCatalogue />
				</TabPanel>
				<TabPanel value="4">
					<UserList />
				</TabPanel>
				<TabPanel value="5">
					<AuditLog />
				</TabPanel>
			</TabContext>

			<ConfirmationDialog
				handleClose={() => handleCloseDialog()}
				isDialogOpen={dialogProperties.isOpen}
				message={dialogProperties.message}
				title={dialogProperties.title}
				type={dialogProperties.type}
				label={dialogProperties.label}
				primaryButton={
					<Button
						onClick={() => handleCloseDialog()}
						className="gg-button"
						style={commonFunctions.determineButtonColor(dialogProperties.type)}
					>
						Ok
					</Button>
				}
			/>
		</div>
	)
}

export default AdminHome