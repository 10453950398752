import './customBidderDetailSection.scss'
import React, { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomBidFieldDialog from '../dialogs/CustomBidderDetailDialog'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ICampaign from '../../../../interfaces/campaign'
import ICustomBidderDetail from '../../../../interfaces/customBidderDetail'

interface InputProps {
    campaign: ICampaign
    setCampaign: (campaign: ICampaign) => void,
}

const CustomBidFieldSection = (props: InputProps) => {

    const [isCustomBidderDetailDialogOpen, setIsCustomBidderDetailDialogOpen] = useState<boolean>(false);
    const [tempCustomBidderDetail, setTempCustomBidderDetail] = useState<ICustomBidderDetail>({} as ICustomBidderDetail);

    const closeDialog = () => {
        setIsCustomBidderDetailDialogOpen(false)
    }
    const openDialog = (customBidderDetail: ICustomBidderDetail) => {
        setTempCustomBidderDetail(customBidderDetail)
        setIsCustomBidderDetailDialogOpen(true)
    }

    const deleteCustomBidderDetail = (index: number) => {
        let tempCustomBidderDetailObject: ICustomBidderDetail[] = [...props.campaign.customBidDetailList]
        tempCustomBidderDetailObject.splice(index, 1)
        props.setCampaign({ ...props.campaign, customBidDetailList: tempCustomBidderDetailObject })
    }

    const promote = (detailId: string) => {
        let index = props.campaign.customBidDetailList.findIndex(e => e.detailId === detailId);
        if (index > 0) {
            let tempCustomBidderDetails = [...props.campaign.customBidDetailList]
            let el = tempCustomBidderDetails[index];
            tempCustomBidderDetails[index] = tempCustomBidderDetails[index - 1];
            tempCustomBidderDetails[index - 1] = el;
            props.setCampaign({ ...props.campaign, customBidDetailList: tempCustomBidderDetails })
        }
    }

    const demote = (detailId: string) => {
        let index = props.campaign.customBidDetailList.findIndex(e => e.detailId === detailId);
        if (index !== -1 && index < props.campaign.customBidDetailList.length - 1) {
            let tempCustomBidderDetails = [...props.campaign.customBidDetailList]
            let el = tempCustomBidderDetails[index];
            tempCustomBidderDetails[index] = tempCustomBidderDetails[index + 1];
            tempCustomBidderDetails[index + 1] = el;
            props.setCampaign({ ...props.campaign, customBidDetailList: tempCustomBidderDetails })
        }
    }

    return (
        <div className="custom-bidder-detail-section">
            <h2 className="subtitle">Custom Bid Fields.</h2>
            <span className="description">When making a bid each bidder will always be asked for: Name, Email and Phone Number. Below, please specify what further information bidders must enter to make a bid.</span>
            <Paper className="input-wrapper">
                {props.campaign.customBidDetailList.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                <div className="table-header-cell">
                                        <span>Field Name</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Field Type</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Mandatory?</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.campaign.customBidDetailList.map((detail: ICustomBidderDetail, index) => (
                                <TableRow key={detail.detailId}>
                                    <TableCell onClick={() => openDialog(detail)}>{detail.label}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        {detail.type === "SELECT" && <span>Dropdown List</span>}
                                        {detail.type === "INPUT" && <span>Text Input</span>}
                                    </TableCell>
                                    <TableCell className="hide-on-mobile">{String(detail.isRequired)}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => promote(detail.detailId)}>
                                                <Tooltip title="Promote"><ExpandLessIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => demote(detail.detailId)}>
                                                <Tooltip title="Demote"><ExpandMoreIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => openDialog(detail)}><Tooltip title="Edit Detail"><CreateIcon /></Tooltip></div>
                                            <div onClick={() => deleteCustomBidderDetail(index)}><Tooltip title="Delete Detail"><DeleteIcon /></Tooltip></div>
                                        </div>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className="no-entries">No custom bid fields defined.</div>
                }
                <div className="button-wrapper">
                    <Button className="gg-button" onClick={() => openDialog({} as ICustomBidderDetail)}>Add New Field</Button>
                </div>
            </Paper>
            <CustomBidFieldDialog
                handleClose={closeDialog}
                isDialogOpen={isCustomBidderDetailDialogOpen}
                setCampaign={props.setCampaign}
                campaign={props.campaign}
                customBidderDetail={tempCustomBidderDetail}
            />
        </div>
    )
}

export default CustomBidFieldSection