import React, { useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Tooltip } from '@mui/material'
import './cloudinaryUploadWidget.scss'

interface InputProps {
    folderName: string
    allowMultipleUploads: boolean
    functionOnComplete: (uploadedUrlList: string[]) => void
    uploadPreset: string
}

const CloudinaryUploadWidget = (props: InputProps) => {

    const [uploadedUrlList, setUploadedUrlList] = useState<string[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const sanitiseFolderName = (folderName: string): string => {
        return folderName.trim().replace(/[?&#%<>/]/g, '')
    }

    function showUploadWidget() {
        setIsOpen(true)
        if (!isOpen) {
            // @ts-ignore
            cloudinary.openUploadWidget({
                cloudName: "going-gone",
                uploadPreset: props.uploadPreset,
                folder: sanitiseFolderName(props.folderName),
                sources: [
                    "local",
                ],
                googleApiKey: "AIzaSyAJRYSde0Y5_YiQrUhUzHtWvrODL0Zv2jQ",
                showAdvancedOptions: false,
                cropping: false,
                multiple: props.allowMultipleUploads,
                defaultSource: "local",
                styles: {
                    palette: {
                        window: "#FFFFFF",
                        windowBorder: "#90A0B3",
                        tabIcon: "#0078FF",
                        menuIcons: "#5A616A",
                        textDark: "#000000",
                        textLight: "#FFFFFF",
                        link: "#0078FF",
                        action: "#FF620C",
                        inactiveTabIcon: "#0E2F5A",
                        error: "#F44235",
                        inProgress: "#0078FF",
                        complete: "#20B832",
                        sourceBg: "#E4EBF1"
                    },
                    fonts: {
                        default: null,
                        "sans-serif": {
                            url: null,
                            active: true
                        }
                    }
                }
            },
                (err, result) => {
                    if (!err) {
                        if (result.event === "success") {
                            uploadedUrlList.push(result.info.secure_url)
                            setUploadedUrlList(uploadedUrlList)

                            if (!props.allowMultipleUploads) {
                                props.functionOnComplete(uploadedUrlList)
                            }
                        }

                        if (result.event === "close") {
                            setIsOpen(false)
                            props.functionOnComplete(uploadedUrlList)
                        }
                    } else {
                        console.log(err)
                    }
                });
        }
    }

    return (
        <Tooltip title={"Upload file"} enterDelay={500} leaveDelay={200}>
            <CloudUploadIcon className='clickable-input-icon' onClick={showUploadWidget} />
        </Tooltip>
    )
}

export default CloudinaryUploadWidget
