import { Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField, ListSubheader } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import { API_PATHS, ORGANISATIONS_API_PATHS } from '../../../common/ApiPaths'
import useFetch from '../../../hooks/useFetch'
import { useHistory } from "react-router-dom"
import { UserContext } from '../../../context/UserContext'
import IValidationObject from '../../../interfaces/validationObject'
import IOrganisation from '../../../interfaces/organisation'
import useOrganisationValidation from '../organisationSettings/useOrganisationValidation'
import ReactCountryFlag from 'react-country-flag'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import SearchIcon from "@mui/icons-material/Search"

interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
}

const CreateOrganisationDialog = (props: InputProps) => {

    const [organisation, setOrganisation] = useState<IOrganisation>({
        _id: "",
        name: "",
        slug: "",
        currency: "GBP",
        licenceFeeDiscountPercentage: 0,
        logoUrl: "",
        revenueFeePercentage: 1,
        stripeConnectedAccountId: "",
        licenceBundleList: [],
        contactEmail: "",
        contactPhone: "",
        isActive: true,
        integrationList: [],
        domain: '',
        sendgridTemplateId: '',
        sendgridVerifiedEmail: ''
    } as IOrganisation)

    const commonFunctions = useCommonFunctions()
    const allCountriesData = commonFunctions.getAllCountriesData()

    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [countryCodeAdded, setCountryCodeAdded] = useState<boolean>(false)
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>(commonFunctions.findIsdCodeByCountryCode("GB"))
    const [searchText, setSearchText] = useState("")
    const [phoneError, setPhoneError] = useState<boolean>(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>("")

	const { dBUser } = useContext(UserContext)
    const organisationsApi = useFetch(API_PATHS.ORGANISATIONS)
    let history = useHistory()
    const organisationValidation = useOrganisationValidation(organisation)

    const displayedCountries = useMemo(() => {
        let lowercaseSearchText = searchText.toLowerCase()
        return allCountriesData.filter(
            option =>
                option.name.toLowerCase().includes(lowercaseSearchText) ||
                option.isd_code.includes(lowercaseSearchText) ||
                `${option.name} (${option.isd_code})`.toLowerCase().includes(lowercaseSearchText)
        )
    }, [searchText])


    const handlePhoneChange = (e) => {
        let phone = selectedCountryCode + e.target.value
        let parsed = commonFunctions.getParsedPhone(phone)

        if (!commonFunctions.isNumberCountryCode(phone)) {
            setOrganisation({ ...organisation, contactPhone: parsed.phone })
            setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
            setPhoneError(parsed.error)
            setPhoneErrorMessage(parsed.errorMessage)
        } else {
            setPhoneError(false)
            setPhoneErrorMessage("")
            setCountryCodeAdded(false)
            setOrganisation({ ...organisation, contactPhone: selectedCountryCode + e.target.value })
            setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
        }
    }

    const createOrganisation = async (): Promise<void> => {
        if (organisationValidation.validateInputs()) {
            try {
                const data: IOrganisation = await organisationsApi.post(`${ORGANISATIONS_API_PATHS.CREATE_ORGANISATION}`, { ...organisation, userId: dBUser._id })
                history.push(`/organisations/${data.slug}`)
            }
            catch (err: any) {
                console.log(err)
            }
        }
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        organisationValidation.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        })
        return returnValue
    }

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
        >

            <DialogContent>
                <div className='coloured-section' >
                    <h2 className='title'>Organisation Details</h2>
                </div>
                <div className='content-wrapper'>
                    <TextField
                        id="name"
                        name="name"
                        label="Organisation Name"
                        variant="outlined"
                        defaultValue={organisation.name || ""}
                        onChange={event => setOrganisation({ ...organisation, name: event.target.value })}
                        helperText={
                            getValidation("organisationName").isValid
                                ? "The name of the organisation - this will be displayed at the top of invoices."
                                : getValidation("organisationName").validationMessage
                        }
                        required
                        fullWidth
                        error={!getValidation("organisationName").isValid}
                    />
                    <TextField
                        id="stripeConnectedAccountId"
                        name="stripeConnectedAccountId"
                        label="Stripe Connected Account Id"
                        variant="outlined"
                        required
                        value={organisation.stripeConnectedAccountId || ""}
                        onChange={event => setOrganisation({ ...organisation, stripeConnectedAccountId: event.target.value })}
                        helperText={
                            getValidation("stripeConnectedAccountId").isValid
                                ? "The Stripe connected account that all payment actions will be associated with."
                                : getValidation("stripeConnectedAccountId").validationMessage
                        }
                        error={!getValidation("stripeConnectedAccountId").isValid}
                        fullWidth
                    />
                    <TextField
                        id="contactEmail"
                        name="contactEmail"
                        label="Contact Email"
                        variant="outlined"
                        required
                        fullWidth
                        value={organisation.contactEmail || ""}
                        onChange={event => setOrganisation({ ...organisation, contactEmail: event.target.value })}
                        helperText={
                            getValidation("contactEmail").isValid
                                ? "The contact email address of the organisation."
                                : getValidation("contactEmail").validationMessage
                        }
                        error={!getValidation("contactEmail").isValid}
                    />
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <InputLabel id="country-code-label">Country Code</InputLabel>
                            <Select
                                id="countryCode"
                                name="countryCode"
                                value={selectedCountryCode}
                                onChange={event => setSelectedCountryCode(event.target.value)}
                                onBlur={event => setOrganisation({ ...organisation, contactPhone: event.target.value + commonFunctions.getNumberWithoutCountryCode(organisation.contactPhone) || '' })}
                                label="Country Code"
                                variant="outlined"
                                onClose={() => setSearchText("")}
                                MenuProps={{ autoFocus: false }}
                                renderValue={value => {
                                    const selected = allCountriesData.filter(x => x.isd_code === value)[0].countryCode
                                    return (
                                        <>
                                            <ReactCountryFlag countryCode={selected} style={{ marginRight: "8px" }} />
                                            {value}
                                        </>
                                    )
                                }}
                                style={{ height: 'max-content' }}
                            >
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Search..."
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={e => setSearchText(e.target.value)}
                                    />
                                </ListSubheader>
                                {displayedCountries.map((country, index) => (
                                    <MenuItem value={country.isd_code} key={index}>
                                        <ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: "8px" }} />
                                        {` ${country.name} (${country.isd_code})`}
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                id="contactPhone"
                                name="contactPhone"
                                value={commonFunctions.getNumberWithoutCountryCode(phoneNumber)}
                                onChange={event => setPhoneNumber(event.target.value)}
                                // onChange={event => props.handleChange(event.target.name, `${selectedCountryCode} ${(event.target.value) || ''}`)}
                                variant="outlined"
                                label="Contact Phone"
                                required
                                helperText={
                                    !getValidation("contactPhone").isValid || phoneError
                                        ? getValidation("contactPhone").validationMessage || phoneErrorMessage
                                        : ""
                                }
                                error={!getValidation("contactPhone").isValid || phoneError}
                                inputProps={{ maxLength: 20 }}
                                onBlur={e => handlePhoneChange(e)}
                                style={{ marginBottom: "0px", width: '100%' }}
                            />
                        </div>
                        <FormHelperText
                            className={!getValidation("contactPhone").isValid || phoneError ? "d-none" : ""}
                            style={{ paddingLeft: "8px" }}
                        >
                            {
                                getValidation("contactPhone").isValid
                                    ? "The contact phone number of the organisation."
                                    : getValidation("contactPhone").validationMessage
                            }
                        </FormHelperText>
                    </FormControl>
                </div>

            </DialogContent>
            <DialogActions>
                <div className="action-section">
                    <>{!organisationValidation.isValidationPassed && <p className="validation-text">Something's not right, please check highlighted fields above.</p>}</>

                    <div className="button-wrapper">
                        <Button onClick={props.handleClose} className="cancel">
                            Cancel
                        </Button>

                        <Button
                            onClick={() => createOrganisation()}
                            className="gg-button"
                        >
                            <span>CREATE</span>
                        </Button>

                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default CreateOrganisationDialog