import React, { useEffect } from 'react'
import "./bidFilterSection.scss"
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import IBidWithData from '../../../interfaces/bidWithData'

interface InputProps {
    // fullBidList: IBidWithData[]
    filter: string
    totalCount: number,
    succeededCount: number, 
    pendingCount: number,
    failedCount: number,
    handleFilterClick: (filter: string) => void
}

const BidFilterSection = (props: InputProps) => {

    // const countBidsWithStatus = (status: string): number => {
    //     return props.fullBidList.filter(bid => bid.status === status).length
    // }

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    return (
        <>
            {/* {props.fullBidList.length > 0 && */}
            
                <div className="bid-filter-section">
                    <div className="filter-dropdown-wrapper">

                        <FormControl variant="outlined">
                            <InputLabel id="bid-filter-label">Filter</InputLabel>
                            <Select
                                labelId="bid-filter-label"
                                id="bidFilter"
                                name="bidFilter"
                                value={props.filter}
                                onChange={(event) => props.handleFilterClick((event.target as HTMLInputElement).value)}
                                label="Filter"
                            >
                                <MenuItem value={""}>All ({props.totalCount})</MenuItem>
                                {props.succeededCount > 0 && <MenuItem value={"SUCCEEDED"}>Succeeded ({props.succeededCount})</MenuItem>}
                                {props.pendingCount > 0 && <MenuItem value={"PENDING"}>Pending ({props.pendingCount})</MenuItem>}
                                {props.failedCount > 0 && <MenuItem value={"FAILED"}>Failed ({props.failedCount})</MenuItem>}
                            </Select>
                        </FormControl>
                        {props.filter && <div className="clear-filter" onClick={() => props.handleFilterClick("")}>Clear Filter</div>}
                    </div>
                </div>
            {/* } */}
        </>
    )
}

export default BidFilterSection