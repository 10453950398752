import React, { useContext, useEffect, useState } from 'react'
import './userReport.scss'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Table, TableBody, TableCell, TableHead, TableRow, Card, Button, CardContent } from "@mui/material"
import moment from 'moment'
import _ from "lodash"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IUser } from '../../../interfaces/user'
import { RouteComponentProps } from 'react-router'
import useExcel from '../../../hooks/useExcel'
import { API_PATHS, BID_API_PATHS, CAMPAIGN_API_PATHS, getFilteredBidWithDataQueryString, getFilteredUserListQueryString, USER_API_PATHS } from '../../../common/ApiPaths'
import { CampaignContext, CampaignLoadingContext } from '../../../context/CampaignContext'
import IBidWithData from '../../../interfaces/bidWithData'
import { OrganisationContext } from '../../../context/OrganisationContext'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import { useErrorBoundary } from 'react-error-boundary'
import { ICampaignUser } from '../../../interfaces/campaignUser'
import { CAMPAIGN_USERS_API_PATH, CAMPAIGN_USERS_PATHS } from '../../../common/CampaignUserApiPaths'

interface InputProps {

}

const UserReport: React.FunctionComponent<InputProps & RouteComponentProps<any>> = (props) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    const [campaignUserList, setCampaignUserList] = useState<ICampaignUser[]>([])
    const [bidList, setBidList] = useState<IBidWithData[]>([])
    const [columnToSort, setColumnToSort] = useState("latestLoginDateTime")
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

    const userApi = useFetch(API_PATHS.USERS)
    const bidsApi = useFetch(API_PATHS.BIDS)
    const campaignUsersApi = useFetch(CAMPAIGN_USERS_API_PATH)
    const excel = useExcel();
    const campaign = useContext(CampaignContext);
    const organisation = useContext(OrganisationContext);
    const campaignLoading = useContext(CampaignLoadingContext)
    const commonFunctions = useCommonFunctions()
    const { showBoundary } = useErrorBoundary();

    const getUsers = async (): Promise<void> => {
        setLoading(true)

        try {
            const data: ICampaignUser[] = await campaignUsersApi.get(`${CAMPAIGN_USERS_PATHS.GET_CAMPAIGN_USERS_LIST}${getFilteredUserListQueryString(campaign._id)}`)
            // const data: IUser[] = await userApi.get(`${USER_API_PATHS.GET_USERS_LIST}${getFilteredUserListQueryString(campaign._id)}`)
            setCampaignUserList(data.sort((a, b) => new Date(b.latestLoginDateTime).getTime() - new Date(a.latestLoginDateTime).getTime()))
            props.setPadding()
            setLoading(false)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const getBids = async (): Promise<void> => {
        setLoading(true)
        try {
            let data: IBidWithData[] = await bidsApi.get(`${BID_API_PATHS.GET_BIDS_WITH_USER_AND_LOT}${getFilteredBidWithDataQueryString(campaign._id)}`)
            setBidList(data)
            setLoading(false)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }

        // auctionApi.get(`/${props.match.params.campaignSlug}/bids`).then((data: IBid[]) => {
        //     setBidList(data)
        //     setLoading(false)
        // }).catch((err: Error) => {
        //     setError(err.message)
        //     setLoading(false)
        // })
    }

    const invertDirection = (currentDirection: string) => {
        if (currentDirection === "asc") {
            return "desc"
        } else if (currentDirection === "desc") {
            return "asc"
        }
    }

    const handleSort = (columnName) => {
        setColumnToSort(columnName)
        let sortDirect: any = columnToSort === columnName ? invertDirection(sortDirection) : 'desc'
        setSortDirection(sortDirect)
    }

    const countBids = (campaignUser: ICampaignUser, status: "SUCCEEDED" | "FAILED/PENDING"): number => {
        let bidCount = 0
        // const stripeCustomerId = campaignUser.stripeConnectedAccountList.find(x => x.organisationId === organisation._id)?.customerId
        if (status === "SUCCEEDED") {
            bidList.forEach(bid => {
                if (bid.campaignUser && campaignUser._id === bid.campaignUser._id && bid.status === "SUCCEEDED") {
                    bidCount++
                }
            });
        } else {
            bidList.forEach(bid => {
                if (bid.campaignUser && campaignUser._id === bid.campaignUser._id && bid.status !== "SUCCEEDED") {
                    bidCount++
                }
            });
        }

        return bidCount
    }

    useEffect(() => {

        if (columnToSort === "isNewSignUp") {
            setCampaignUserList(_.orderBy(campaignUserList,
                function (user: IUser) {
                    return (String(user.signUpCampaignId === props.match.params.campaignSlug));
                },
                sortDirection))
        } else if (columnToSort === "successfulBids") {
            setCampaignUserList(_.orderBy(campaignUserList,
                function (campaignUser: ICampaignUser) {
                    return (countBids(campaignUser, "SUCCEEDED"));
                },
                sortDirection))
        } else if (columnToSort === "failedPendingBids") {
            setCampaignUserList(_.orderBy(campaignUserList,
                function (campaignUser: ICampaignUser) {
                    return (countBids(campaignUser, "FAILED/PENDING"));
                },
                sortDirection))
        } else {
            setCampaignUserList(_.orderBy(campaignUserList, columnToSort, sortDirection))
        }


    }, [columnToSort, sortDirection])


    useEffect(() => {
        if (!campaignLoading && !(campaign && campaign.slug)) {
            showBoundary('Error while fetching campaign')
            setLoading(false)
        }
        else if (campaign.slug) {
            setError('')
            setLoading(false)
            getUsers()
            getBids()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [campaign, campaignLoading]);

    if (loading) {
        return (
            <Loading />
        )
    }

    // if (error) {
    //     return (
    //         <p>{error}</p>
    //     )
    // }

    return (
        <div className="user-report">
            <h2 className="subtitle">User Report.</h2>
            <div className="description">A list of all the users that have logged in to this campaign.</div>
            {campaignUserList.length > 0 ?
                <>
                    <Card >
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div onClick={() => handleSort("latestLoginDateTime")} className="table-header-cell sortable">
                                                <span>Latest Login Date</span>
                                                {
                                                    columnToSort === "latestLoginDateTime" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div onClick={() => handleSort("name")} className="table-header-cell sortable">
                                                <span>Name</span>
                                                {
                                                    columnToSort === "name" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div onClick={() => handleSort("email")} className="table-header-cell sortable">
                                                <span>Email Address</span>
                                                {
                                                    columnToSort === "email" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null

                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell >
                                            <div onClick={() => handleSort("isNewSignUp")} className="table-header-cell sortable center">
                                                <span>New Sign Up?</span>
                                                {
                                                    columnToSort === "isNewSignUp" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div onClick={() => handleSort("successfulBids")} className="table-header-cell sortable center">
                                                <span>Successful Bids</span>
                                                {
                                                    columnToSort === "successfulBids" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell >
                                            <div onClick={() => handleSort("failedPendingBids")} className="table-header-cell sortable center">
                                                <span>Failed / Pending Bids</span>
                                                {
                                                    columnToSort === "failedPendingBids" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaignUserList.map((campaignUser: ICampaignUser, index) => (
                                        <TableRow key={index}>
                                            <TableCell >{moment(campaignUser.latestLoginDateTime).format("HH:mm DD/MM/YYYY")}</TableCell>
                                            <TableCell>{campaignUser.firstName ? `${commonFunctions.capitalize(campaignUser.firstName)} ${commonFunctions.capitalize(campaignUser.surname)}` : campaignUser.email}</TableCell>
                                            <TableCell>{campaignUser.email}</TableCell>
                                            <TableCell>
                                                <div className="center">{String(campaignUser.signUpCampaignId === campaign._id)}</div>
                                            </TableCell>
                                            <TableCell >
                                                <div className="center">{countBids(campaignUser, "SUCCEEDED")}</div>
                                            </TableCell>
                                            <TableCell >
                                                <div className="center">{countBids(campaignUser, "FAILED/PENDING")}</div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card >
                    <div className="button-wrapper space-between">
                        <div></div>
                        <div className="fixed-action-section">
                            <div></div>
                            <Button className="gg-button" onClick={() => excel.generateUserReport(campaignUserList, bidList, props.match.params.campaignSlug, organisation._id)}>Download User Report</Button>
                        </div>
                    </div>
                </>
                :
                <Card><CardContent><div className="no-entries">No users found</div></CardContent></Card>
            }
        </div >
    )
}

export default UserReport