import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import './revokeLicenceBundleDialog.scss'

interface InputProps {
	isDialogOpen: boolean
	handleClose: (userId?: string) => void
	integrationId: string
}

const RemoveIntegrationDialog = (props: InputProps) => {

	return (
		<Dialog open={props.isDialogOpen} onClose={() => props.handleClose()} className="remove-integration-dialog">
			<DialogTitle>Remove Integration</DialogTitle>
			<DialogContent>
				<div className="intro">
					<span>Are you sure you want to remove this integration?</span>
				</div>
			</DialogContent>
			
			<DialogActions>
				<Button onClick={() => props.handleClose()}>Cancel</Button>
				<Button onClick={() => props.handleClose(props.integrationId)} className="gg-button">
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default RemoveIntegrationDialog