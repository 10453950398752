import {
	Box,
	Card,
	CardContent,
	Collapse,
	FormControl,
	IconButton,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import React, { SyntheticEvent, useContext, useEffect, useState } from "react"
import ICampaign from "../../../interfaces/campaign"
import "./winnerListSection.scss"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import LotsWonSection from "./lotsWonSection/LotsWonSection"
import AuditLogSection from "./AuditLogSection"
import { IWinner } from "../../../interfaces/winner"
import WinnerDetailsSection from "./WinnerDetailsSection"
import useFetch from "../../../hooks/useFetch"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import ErrorIcon from "@mui/icons-material/Error"
import PaymentProgressDialog from "./PaymentProgressDialog"
import { API_PATHS, BID_API_PATHS, STRIPE_API_PATHS } from "../../../common/ApiPaths"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import PaidIcon from "@mui/icons-material/Paid"
import EmailIcon from "@mui/icons-material/Email"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"
import IOrganisation from "../../../interfaces/organisation"
import { IWinnerBidAndLot } from "../../../interfaces/winningBidResponse"
import moment from "moment"
import { LotType } from "../../../common/enums/LotType"
import { OrganisationContext } from "../../../context/OrganisationContext"
import MoneyIcon from '@mui/icons-material/Money';
import ManualPaymentDialog from "./lotsWonSection/lotsWonActions.tsx/ManualPaymentDialog"
import IStripePayment from "../../../interfaces/stripePayment"
import IWinnerLot from "../../../interfaces/winnerLot"
import IBid from "../../../interfaces/bid"
import { AuditLogCategories } from "../../../common/enums/AuditLogCategories"
import { AuditLogDescriptions } from "../../../common/enums/AuditLogDescriptions"
import { UserContext } from "../../../context/UserContext"

interface InputProps {
	campaign: ICampaign
	setCampaign: (campaign: ICampaign) => void
	winnerList: IWinner[]
	setWinnerList: (winnerList: IWinner[]) => void
	generateWinnerList: () => Promise<IWinner[]>
	handleAllUsersPaymentsClicked: number
	generateAllUsersVouchersClicked: number
	organisation: IOrganisation
	dialogProperties: any
	setDialogProperties: (any: any) => void
	isPurchaseReport: boolean
}

interface IPaymentProgressDialogProperties {
	isOpen: boolean
	toProcess: '' | 'vouchers' | 'payments'
}

const WinnerListSection = (props: InputProps) => {
	const [tabStates, setTabStates] = useState<any[]>([])
	const [rowMaximisedStateList, setRowMaximisedStateList] = useState<Map<string, boolean>>(new Map<string, boolean>())
	const [isPaymentInProgress, setIsPaymentInProgress] = useState<boolean>(false)
	const [isVoucherInProgress, setIsVoucherInProgress] = useState<boolean>(false)
	const [isAllUsersPaymentInProgress, setIsAllUsersPaymentInProgress] = useState<boolean>(false)
	const [isAllVoucherGenerationInProgress, setIsAllVoucherGenerationInProgress] = useState<boolean>(false)
	const [paymentProgressDialogProperties, setPaymentProgressDialogProperties] = useState<IPaymentProgressDialogProperties>({
		isOpen: false,
		toProcess: ""
	})
	const [processedPaymentWinners, setProcessedPaymentWinners] = useState<number>(0)
	const [noOfErrors, setNoOfErrors] = useState<number>(0)
	const [currentProcessingPaymentWinner, setCurrentProcessingPaymentWinner] = useState<string>("")
	const [processedVouchers, setProcessedVouchers] = useState<number>(0)
	const [currentProcessingVoucherWinner, setCurrentProcessingVoucherWinner] = useState<string>("")
	const [error, setError] = useState<string>('')
	const [isManualPaymentDialogOpen, setIsManualPaymentDialogOpen] = useState<boolean>(false)
	const [winnerToBePaidManually, setWinnerToBePaidManually] = useState<IWinner>({} as IWinner)
	const cancelRef = React.useRef(false)

	const communicationsApi = useFetch(API_PATHS.COMMUNICATIONS)
	const stripeApi = useFetch(API_PATHS.STRIPE)
	const voucherApi = useFetch(API_PATHS.VOUCHER)
	const bidsApi = useFetch(API_PATHS.BIDS)
	const auditLogApi = useFetch(API_PATHS.AUDITLOG)
	const commonFunctions = useCommonFunctions()
	const organisation: IOrganisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)

	const handleChange = (event: SyntheticEvent, indexValue: string) => {
		let tempTabStates = { ...tabStates }
		let index = indexValue.split("-")[0]
		let value = indexValue.split("-")[1]

		tempTabStates[index] = value
		setTabStates(tempTabStates)
	}

	const handleSelectAction = (event, winner: IWinner) => {
		console.log("winner", winner)
		if (event.target.value === "SEND-NOTIFICATION") {

			let newWinner = JSON.parse(JSON.stringify(winner))
			newWinner.winnerItems.forEach((winnerItem) => {
				winnerItem.bidAndLotList = winnerItem.bidAndLotList.filter(x => x.winningLot.type !== LotType.BUYITNOW)
			})
			newWinner.winnerItems = newWinner.winnerItems.filter(x => x.bidAndLotList.length > 0)
			if (newWinner.winnerItems.length > 0) {
				communicationsApi
					.post(`/sendWinnerNotification`, {
						campaignId: props.campaign._id,
						organisationId: props.campaign.organisationId,
						campaignName: props.campaign.name,
						campaignPaymentMethod: props.campaign.paymentMethod,
						campaignCustomWinnerConfirmationSuffix: props.campaign.customWinnerConfirmationSuffix ?? "",
						bidderEmail: winner.campaignUser.email,
						winningBid: winner.winnerItems[0],
						notificationType: props.campaign.notificationType,
						smsSender: props.campaign.smsSender,
						sendgridVerifiedEmail: organisation.sendgridVerifiedEmail,
						sendgridTemplateId: organisation.sendgridTemplateId
					})
					.then((updatedCampaign: any) => {
						// props.setCampaign(updatedCampaign)
						//TODO - Add method to get latest audit log entries
						props.setDialogProperties({
							isOpen: true,
							type: "SUCCESS",
							title: "Notifications Sent Successfully",
							message: "All notifications were sent successfully.",
						})
					})
					.catch((err: Error) => {
						props.setDialogProperties({
							isOpen: true,
							type: "ERROR",
							title: "Notifications Error",
							message: "There was an issue when sending the winner notifications.",
						})
					})
			}
			else {
				props.setDialogProperties({
					isOpen: true,
					type: "PARTIAL",
					title: "Notifications Cannot be Sent",
					message: "No winners found. No notifications sent.",
				})
			}
		} else if (event.target.value === "TAKE-PAYMENT") {
			takePaymentForAllPreAuthLotsWon(winner)
		} else if (event.target.value === "MANUAL-PAYMENT") {
			setIsManualPaymentDialogOpen(true)
			setWinnerToBePaidManually(winner)
		} else if (event.target.value === "GENERATE-VOUCHER") {
			generateVouchersForAllPaidLots(winner)
		}
	}
	const handleAllUsersPayments = async () => {
		let updatedCampaign = props.campaign
		const countProcessed = props.winnerList.filter(w => commonFunctions.determineWinnerPaidStatus(w.winnerItems) === "PAID").length
		setProcessedPaymentWinners(countProcessed)
		let pWinners = countProcessed
		setCurrentProcessingPaymentWinner("")
		setIsAllUsersPaymentInProgress(true)
		for (let winner of props.winnerList) {
			if (cancelRef.current) {
				cancelRef.current = false
				break
			}
			if (commonFunctions.determineWinnerPaidStatus(winner.winnerItems) !== "PAID") {
				setCurrentProcessingPaymentWinner(
					winner.bidderName
						? winner.bidderName
						: winner.campaignUser?.firstName
							? `${commonFunctions.capitalize(winner.campaignUser?.firstName)} ${commonFunctions.capitalize(winner.campaignUser?.surname)}`
							: winner.campaignUser?.email
				)

				await takePaymentForAllPreAuthLotsWon(winner, true, updatedCampaign)
				pWinners++
				setProcessedPaymentWinners(pWinners)
			}
		}
		setIsAllUsersPaymentInProgress(false)
	}

	const handleAllUsersVouchers = async () => {
		let countProcessedVouchers = 0
		let countProcessedWinners = 0
		props.winnerList.forEach(w => {
			let foundGenerated = false
			w.winnerItems.forEach(item => {
				item.bidAndLotList.forEach(bidAndLot => {
					let isGenerated = commonFunctions.determineVoucherGeneratedStatus(bidAndLot.winningBid.bidList[0]) === "Generated"
					foundGenerated = foundGenerated || isGenerated
					countProcessedVouchers = isGenerated ? countProcessedVouchers + 1 : countProcessedVouchers
				})
			})
			countProcessedWinners = foundGenerated ? countProcessedWinners + 1 : countProcessedWinners
		})
		setProcessedVouchers(countProcessedVouchers)
		let pWinners = countProcessedWinners
		setCurrentProcessingVoucherWinner("")
		setIsAllVoucherGenerationInProgress(true)
		for (let winner of props.winnerList) {
			if (cancelRef.current) {
				cancelRef.current = false
				break
			}
			if (commonFunctions.determineWinnerVoucherGeneratedStatus(winner.winnerItems) !== "Resolved") {
				setCurrentProcessingVoucherWinner(
					winner.bidderName
						? winner.bidderName
						: winner.campaignUser?.firstName
							? `${commonFunctions.capitalize(winner.campaignUser?.firstName)} ${commonFunctions.capitalize(winner.campaignUser?.surname)}`
							: winner.campaignUser?.email
				)

				let res = await generateVouchersForAllPaidLots(winner, true, countProcessedVouchers)
				if (typeof res === 'number') {
					countProcessedVouchers = res
				}

				pWinners++
				// setProcessedVouchers(countProcessedVouchers)
			}

		}
		props.generateWinnerList()
		setIsAllVoucherGenerationInProgress(false)
	}

	useEffect(() => {
		if (paymentProgressDialogProperties.isOpen) {
			if (paymentProgressDialogProperties.toProcess === 'payments') {
				handleAllUsersPayments()
			}
			if (paymentProgressDialogProperties.toProcess === 'vouchers') {
				handleAllUsersVouchers()
			}
		}
	}, [paymentProgressDialogProperties])

	useEffect(() => {
		if (props.handleAllUsersPaymentsClicked > 0) {
			setPaymentProgressDialogProperties({ isOpen: true, toProcess: 'payments' })
		}
	}, [props.handleAllUsersPaymentsClicked])

	useEffect(() => {
		if (props.generateAllUsersVouchersClicked > 0) {
			setPaymentProgressDialogProperties({ isOpen: true, toProcess: 'vouchers' })
		}
	}, [props.generateAllUsersVouchersClicked])

	useEffect(() => {
		setTabStates(props.winnerList.map(() => "0"))
	}, [props.winnerList])


	const generateVoucher = async (bidAndLot: IWinnerBidAndLot, winner: IWinner, allUsersProcessing: boolean = false, countProcessedVouchers: number = 0
	) => {
		let catalogue = props.organisation.integrationList.find(integration => integration.id === bidAndLot.winningLot.catalogue.integrationId)
		let bidsWithVoucherIds = bidAndLot.winningBid.bidList.filter(b => b.catalogue && b.catalogue?.voucherId && b.catalogue.voucherId !== null)

		if (catalogue && bidsWithVoucherIds.length === 0) {
			try {
				let response = await voucherApi.post(``, {
					itemId: bidAndLot.winningLot.catalogue.itemId,
					firstName: winner.campaignUser.firstName,
					lastName: winner.campaignUser.surname,
					email: winner.campaignUser.email,
					telephone: winner.campaignUser.phone,
					amountBid: bidAndLot.winningBid.totalValue,
					apiUrl: catalogue.apiUrl,
					apiKey: catalogue.apiKey,
					organisationId: props.organisation._id,
					campaignId: props.campaign._id,
					eventName: `Going Gone - ${props.organisation.name} - ${props.campaign.name} - ${moment(props.campaign.campaignDate).format("DD/MM/yyyy")}`,
					userId: bidAndLot.winningBid.userId
				})

				if (response.voucher_id) {
					await bidsApi.put(BID_API_PATHS.UPDATE_BID.replace("{bidId}", bidAndLot.winningBid.bidList[0]._id), [{
						...bidAndLot.winningBid.bidList[0],
						catalogue: {
							...bidAndLot.winningBid.bidList[0].catalogue,
							voucherId: response.voucher_id,
						},
					}])
				}
				else if (response.Error) {
					setNoOfErrors(noOfErrors + 1)
					setError(`${winner.campaignUser.firstName} ${winner.campaignUser.surname}, ${bidAndLot.winningLot.title} : ${response.Error}`)
					await timeout(2000) // dont wait if last
					setError('')
				}
				setProcessedVouchers(countProcessedVouchers + 1)
			} catch (err) {
				props.setDialogProperties({
					isOpen: true,
					type: "ERROR",
					version: "",
					title: "Error",
					message: err,
				})
			}
			finally {
				props.generateWinnerList()
			}
		}
	}

	function timeout(delay: number) {
		return new Promise(res => setTimeout(res, delay));
	}

	const generateVouchersForAllPaidLots = async (
		winner: IWinner,
		allUsersProcessing: boolean = false,
		countProcessedVouchers = 0
	) => {
		let canProcessVoucher = allUsersProcessing ? true : !isVoucherInProgress
		if (canProcessVoucher) {
			setIsVoucherInProgress(true)
			//set loading for items that have integration id and their voucher is not generated yet
			let updatedWinnerItems = winner.winnerItems.map(winnerItem => {
				winnerItem.bidAndLotList.forEach(b => {
					if (b.winningBid.paymentRefId && b.winningLot.catalogue && b.winningLot.catalogue.itemId && b.winningLot.catalogue.integrationId) {
						b.winningBid.bidList.forEach(bid => {
							if (!bid.catalogue || !bid.catalogue.voucherId) {
								b.isVoucherLoading = true
							}
						})
					}
				})
				return winnerItem
			})

			let updatedWinnerList = props.winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
			props.setWinnerList(updatedWinnerList)

			try {
				let unfilteredSelectedWinnerBidsAndLots = winner.winnerItems
					.flatMap(x => x.bidAndLotList)
					.filter(a => a.winningBid.paymentRefId && a.winningLot.catalogue && a.winningLot.catalogue.itemId && a.winningLot.catalogue.integrationId)

				let selectedWinnerBidsAndLots: IWinnerBidAndLot[] = []
				for (const bidAndLot of unfilteredSelectedWinnerBidsAndLots) {
					let bids = bidAndLot.winningBid.bidList.filter(x => x.catalogue && x.catalogue.voucherId === null)
					if (bids.length > 0) {
						selectedWinnerBidsAndLots.push({ ...bidAndLot, winningBid: { ...bidAndLot.winningBid, bidList: bids } })
					}
				}

				for (const bidAndLot of selectedWinnerBidsAndLots) {
					await generateVoucher(bidAndLot, winner, allUsersProcessing, countProcessedVouchers)
					countProcessedVouchers++;
				}

				let updatedWinnerItems = winner.winnerItems.map(winnerItem => {
					winnerItem.bidAndLotList.forEach(b => {
						if (b.winningBid.paymentRefId && b.winningLot.catalogue && b.winningLot.catalogue.itemId && b.winningLot.catalogue.integrationId) {
							b.winningBid.bidList.forEach(bid => {
								if (!bid.catalogue || !bid.catalogue.voucherId) {
									b.isVoucherLoading = false
								}
							})
						}
					})
					return winnerItem
				})

				let updatedWinnerList = props.winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
				props.setWinnerList(updatedWinnerList)

				setIsVoucherInProgress(false)
			} catch (err) {
				console.log(err)
				let updatedWinnerItems = winner.winnerItems.map(winnerItem => {
					winnerItem.bidAndLotList.forEach(b => {
						if (b.winningBid.paymentRefId && b.winningLot.catalogue && b.winningLot.catalogue.itemId && b.winningLot.catalogue.integrationId) {
							b.winningBid.bidList.forEach(bid => {
								if (!bid.catalogue || !bid.catalogue.voucherId) {
									b.isVoucherLoading = false
								}
							})
						}
					})
					return winnerItem
				})

				let updatedWinnerList = props.winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
				props.setWinnerList(updatedWinnerList)
				props.setDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: "Error",
					message: "Check the audit log for details",
				})
				setIsVoucherInProgress(false)
			}
			finally {
				if (!allUsersProcessing) {
					props.generateWinnerList()
				}
			}
		}
		return countProcessedVouchers
	}


	const takePaymentForAllPreAuthLotsWon = async (
		winner: IWinner,
		allUsersProcessing: boolean = false,
		updatedCampaign: ICampaign = props.campaign
	) => {
		let canProcessPayment = allUsersProcessing ? true : !isPaymentInProgress
		if (canProcessPayment) {
			setIsPaymentInProgress(true)
			//set loading for items that do not have a paymentId - therefore have not yet been paid for or are already cancelled
			let updatedWinnerItems = winner.winnerItems.map(winnerItem => {
				winnerItem.bidAndLotList.forEach(b => {
					if (!(b.winningBid.paymentRefId || b.winningBid.isCancelled)) {
						b.isLoading = true
					}
				})
				return winnerItem
			})

			let updatedWinnerList = props.winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
			props.setWinnerList(updatedWinnerList)

			try {
				let selectedBidIds = winner.winnerItems
					.flatMap(x => x.bidAndLotList)
					.map(y => y.winningBid)
					.flatMap(z => z.bidList)
					.filter(a => (!a.stripePayment || !a.stripePayment?.paymentRefId) && !a.isCancelled)
					.map(b => b._id)

				selectedBidIds = selectedBidIds.filter((value, index, self) => self.indexOf(value) === index)
				let resUpdatedWinner: IWinner = await stripeApi.post(STRIPE_API_PATHS.CHARGE_SAVED_CARDS, {
					paymentRequestBidIdList: selectedBidIds,
					winner: winner,
					campaignId: updatedCampaign._id,
					organisationId: updatedCampaign.organisationId,
					campaignName: updatedCampaign.name,
				})

				if (resUpdatedWinner) {
					props.generateWinnerList()
				}

				setIsPaymentInProgress(false)
			} catch (err) {
				console.log(err)
				setNoOfErrors(noOfErrors + 1)
				setError(`${winner.campaignUser.firstName} ${winner.campaignUser.surname}: ${err}`)
				let updatedWinnerItems = winner.winnerItems.map(winnerItem => {
					winnerItem.bidAndLotList.forEach(b => {
						if (!(b.winningBid.paymentRefId || b.winningBid.isCancelled)) {
							b.isLoading = false
						}
					})
					return winnerItem
				})
				let updatedWinnerList = props.winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
				props.setWinnerList(updatedWinnerList)
				props.setDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: "Error",
					message: "Check the audit log for details",
				})
				setIsPaymentInProgress(false)
			}
		}
	}

	const handleCloseManualPaymentDialog = (boolean: boolean, manualPaymentRef: string) => {
		if (boolean) {
			markUnpaidAsPaidManually(winnerToBePaidManually, manualPaymentRef)
		}

		setIsManualPaymentDialogOpen(false)
	}

	const markUnpaidAsPaidManually = async (
		winner: IWinner,
		manualPaymentRef: string
	) => {
		setIsPaymentInProgress(true)
		const fullManualPaymentRef = `MANUAL_${new Date().getTime()}${manualPaymentRef && `_${manualPaymentRef}`}`

		let bidsToBePersisted: IBid[] = []

		let updatedWinnerItems = winner.winnerItems.map(winnerItem => {
			winnerItem.bidAndLotList.forEach(b => {
				if (!(b.winningBid.paymentRefId || b.winningBid.isCancelled)) {
					b.winningBid.paymentRefId = fullManualPaymentRef
					b.winningBid.bidList = b.winningBid.bidList.map(x => { return { ...x, stripePayment: { ...x.stripePayment, paymentRefId: fullManualPaymentRef } as IStripePayment } })
					bidsToBePersisted.push(b.winningBid.bidList[0])
				}
			})
			return winnerItem
		})

		let updatedWinnerList = props.winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
		props.setWinnerList(updatedWinnerList)

		try{
			 
			await bidsApi.put(
				BID_API_PATHS.UPDATE_BID,
				bidsToBePersisted
			)
	
			const auditLogEntry = await auditLogApi.post("", {
				userId: winner.bidderId,
				campaignId: winner.winnerItems[0].bidAndLotList[0].winningLot.campaignId,
				organisationId: organisation._id,
				category: AuditLogCategories.PAYMENT,
				description: AuditLogDescriptions.PAYMENT_MULTIPLE_MANUAL_CREATE,
				reference: `Ref: ${fullManualPaymentRef}, Winner: ${winner.bidderName}, Actioned By: ${dBUser.firstName} ${dBUser.surname}`,
				timestamp: new Date(),
				isError: false
			})

			//add the audit log entry to memory
            winner.auditLog.push(auditLogEntry)
            updatedWinnerList = updatedWinnerList.map(x => (x.id === winner.id ? { ...winner} : x))
            props.setWinnerList(updatedWinnerList)


			setIsPaymentInProgress(false)


		}catch(err){
			props.setDialogProperties({
				isOpen: true,
				type: "ERROR",
				title: "Error",
				message: "Check the audit log for details",
			})
			setIsPaymentInProgress(false)
		}



	}

	const updateRowMaximisedStateList = (index: string) => {
		let updatedMap = new Map(rowMaximisedStateList)
		if (updatedMap.has(index)) {
			updatedMap.set(index, !updatedMap.get(index))
		}
		else {
			updatedMap.set(index, true)
		}
		setRowMaximisedStateList(updatedMap)
	}

	const getUnprocessedVouchersCount = (): number => {
		let totalCount = 0
		let count = 0
		props.winnerList.forEach(winner => {
			let counts = commonFunctions.determineWinnerVoucherCount(winner.winnerItems)
			count += (counts.totalVouchers - counts.voucherCount)
			totalCount += counts.canBeProcessedVoucherCount
		})
		return totalCount
	}

	const Row = ({ winner, index }) => {
		return (
			<>
				<TableRow sx={{ "& > *": { borderBottom: "unset" } }} className="clickable-row" onClick={() => updateRowMaximisedStateList(index.toString())}>
					<TableCell>
						<IconButton aria-label="expand row" size="small">
							{rowMaximisedStateList.get(index.toString()) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell component="th" scope="row">
						{winner.bidderName
							? winner.bidderName
							: winner.user?.firstName
								? `${commonFunctions.capitalize(winner.user?.firstName)} ${commonFunctions.capitalize(winner.user?.surname)}`
								: winner.user?.email}
					</TableCell>
					<TableCell>{winner.winnerItems[0].bidAndLotList.length}</TableCell>
					<TableCell>
						{new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: props.campaign.currency ?? "GBP",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(commonFunctions.calculateTotalWinningBidValue(winner, true))}
					</TableCell>
					<TableCell>
						<div className="payment-status-wrapper">{commonFunctions.determineWinnerPaidStatusIcon(winner.winnerItems)}</div>
					</TableCell>
					<TableCell>
						<div className="payment-status-wrapper">{commonFunctions.determineWinnerVoucherGeneratedStatusIcon(winner.winnerItems)}</div>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Collapse in={rowMaximisedStateList.get(index.toString())} timeout="auto" unmountOnExit>
							<Box sx={{ margin: 1 }}>
								{tabStates && (
									<TabContext value={`${index}-${tabStates[index]}`}>
										<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
											<TabList onChange={handleChange} aria-label="tabs">
												<Tab label={`${props.isPurchaseReport ? 'Items Purchased' : 'Lots Won'} (${winner.winnerItems[0].bidAndLotList.length})`} value={`${index}-0`} />
												<Tab label={`${props.isPurchaseReport ? 'Purchaser' : 'Winner'} Details`} value={`${index}-1`} />
												<Tab
													label={
														<span>
															{`Audit Log (${winner.auditLog ? winner.auditLog.length : 0})`}{" "}
															{commonFunctions.isAuditLogErrors(winner) && <ErrorIcon className="error-icon" />}
														</span>
													}
													value={`${index}-2`}
												/>
											</TabList>
										</Box>

										<TabPanel value={`${index}-0`}>
											<LotsWonSection
												winner={winner}
												campaign={props.campaign}
												organisation={props.organisation}
												setCampaign={props.setCampaign}
												winnerList={props.winnerList}
												setWinnerList={props.setWinnerList}
												generateWinnerList={props.generateWinnerList}
												isPaymentInProgress={isPaymentInProgress}
												setIsPaymentInProgress={setIsPaymentInProgress}
												isVoucherInProgress={isVoucherInProgress}
												setIsVoucherInProgress={setIsVoucherInProgress}
												dialogProperties={props.dialogProperties}
												setDialogProperties={props.setDialogProperties}
												isPurchaseReport={props.isPurchaseReport}
											/>
										</TabPanel>
										<TabPanel value={`${index}-1`}>
											<WinnerDetailsSection winner={winner} />
										</TabPanel>
										<TabPanel value={`${index}-2`}>
											<AuditLogSection auditLogList={winner.auditLog} />
										</TabPanel>
									</TabContext>
								)}
								<div className="button-wrapper">
									<></>
									<FormControl className="winner-actions hide-on-mobile">
										<InputLabel>{`${props.isPurchaseReport ? 'Purchaser' : 'Winner'} Actions`}</InputLabel>
										<Select
											id="winnerActions"
											name="winnerActions"
											value={""}
											onChange={event => handleSelectAction(event, winner)}
											label={`${props.isPurchaseReport ? 'Purchaser' : 'Winner'} Actions`}
										>
											{props.campaign.paymentMethod === "PRE-AUTH" && !props.isPurchaseReport && (
												<MenuItem
													value={"TAKE-PAYMENT"}
													disabled={
														commonFunctions.determineWinnerPaidStatus(winner.winnerItems) === "PAID" ||
														isPaymentInProgress
													}
												>
													<ListItemIcon>
														<PaidIcon />
													</ListItemIcon>
													<ListItemText primary="Take Payment for All Unpaid" />
												</MenuItem>
											)}
											{!props.isPurchaseReport && (
												<MenuItem
													value={"MANUAL-PAYMENT"}
													disabled={
														commonFunctions.determineWinnerPaidStatus(winner.winnerItems) === "PAID" ||
														isPaymentInProgress
													}
												>
													<ListItemIcon>
														<MoneyIcon />
													</ListItemIcon>
													<ListItemText primary="Mark All Unpaid as Paid (Manual)" />
												</MenuItem>
											)}
											<MenuItem
												value={"GENERATE-VOUCHER"}
												disabled={
													commonFunctions.determineWinnerVoucherGeneratedStatus(winner.winnerItems) === "Resolved" ||
													isVoucherInProgress || commonFunctions.determineWinnerPaidStatus(winner.winnerItems) === "NOT PAID"
												}
											>
												<ListItemIcon>
													<ConfirmationNumberIcon />
												</ListItemIcon>
												<ListItemText primary="Generate Voucher(s)" />
											</MenuItem>
											{!props.isPurchaseReport && (
												<MenuItem value={"SEND-NOTIFICATION"}>
													<ListItemIcon>
														<EmailIcon />
													</ListItemIcon>
													<ListItemText primary="Send Winner Notification(s)" />
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</div>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</>
		)
	}

	return (
		<div className="winner-list-section">
			{/* // TODO Payments */}
			{props.winnerList.length > 0 ? (
				<>
					<h2>{`${props.isPurchaseReport ? 'Purchasers' : 'Winners'}: ${props.winnerList.length}`}</h2>
					<p>{props.isPurchaseReport ? "This report contains details about purchasers of 'Buy It Now' items only" : "This report contains details about winners of all types of lots"}</p>
					{/* <p>{JSON.stringify(tabStates)}</p> */}
					<TableContainer component={Paper}>
						<Table className="winners-table">
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>{`${props.isPurchaseReport ? 'Purchaser' : 'Winner'} Name`}</TableCell>
									<TableCell>{`${props.isPurchaseReport ? 'Items Purchased' : 'Lots Won'}`}</TableCell>
									<TableCell>{`${props.isPurchaseReport ? 'Total Purchase Value' : 'Total Winning Bid Value'}`}</TableCell>
									<TableCell>Payment Status</TableCell>
									<TableCell>Voucher Status</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.winnerList.map((winner, index) => {
									return <Row key={index} winner={winner} index={index} />
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			) : (
				<Card>
					<CardContent>
						<div className="no-entries">{`No ${props.isPurchaseReport ? 'purchasers' : 'winners'} found`}</div>
					</CardContent>
				</Card>
			)}

			<PaymentProgressDialog
				isDialogOpen={paymentProgressDialogProperties.isOpen}
				totalUsers={paymentProgressDialogProperties.toProcess === "vouchers" ? getUnprocessedVouchersCount() : props.winnerList.length}
				processedWinners={paymentProgressDialogProperties.toProcess === "vouchers" ? processedVouchers : processedPaymentWinners}
				currentProcessingWinner={paymentProgressDialogProperties.toProcess === "vouchers" ? currentProcessingVoucherWinner : currentProcessingPaymentWinner}
				handleCancel={(cancelled: boolean = false) => {
					if (cancelled) {
						cancelRef.current = true
					}
					setPaymentProgressDialogProperties({
						isOpen: false,
						toProcess: ''
					})
				}}
				isPaymentInProgress={isAllUsersPaymentInProgress || isAllVoucherGenerationInProgress}
				processingVouchers={paymentProgressDialogProperties.toProcess === "vouchers"}
				error={error}
				noOfErrors={noOfErrors}
			/>
			<ManualPaymentDialog
				isDialogOpen={isManualPaymentDialogOpen}
				handleClose={handleCloseManualPaymentDialog}
			/>
		</div>
	)
}

export default WinnerListSection
