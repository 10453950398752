import WysiwygEditor from '../../../shared/WysiwygEditor';
import { Paper } from '@mui/material';
import ICampaign from '../../../../interfaces/campaign';

interface InputProps {
    campaign: ICampaign;
    setCampaign: (campaign: ICampaign) => void;
    termsAndConditionsHtml: string;
    setTermsAndConditionsHtml: (terms: string) => void;
}

const TermsAndConditionsSection = (props: InputProps) => {
    return (
        <section className='terms-and-conditions-section'>
            <h2>Terms & Conditions</h2>
            <Paper className="input-wrapper">
                <div>
                    <WysiwygEditor
                        placeholder=""
                        value={props.termsAndConditionsHtml}
                        onChange={(value) => {
                            props.setTermsAndConditionsHtml(value)
                        }}
                        onBlur={() => {
                            props.setCampaign({ ...props.campaign, termsAndConditionsHtml: props.termsAndConditionsHtml })
                        }}
                    />
                </div>
            </Paper>
        </section>
    )
}

export default TermsAndConditionsSection