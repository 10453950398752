import React, { useContext, useEffect, useState } from "react"
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from "@mui/material"
import "../dialog.scss"
import IOrganisation from "../../../interfaces/organisation"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { IIntegration } from "../../../interfaces/integration"
import { IntegrationProvider } from "../../../common/enums/IntegrationProvider"
import useIntegrationValidation from "./useIntegrationValidation"
import useFetch from "../../../hooks/useFetch"
import md5 from "md5"
import moment from "moment-timezone"
import { IntegrationCostPrice } from "../../../common/enums/IntegrationCostPrice"
import { IUser } from "../../../interfaces/user"
import { UserContext } from "../../../context/UserContext"
import { UserRole } from "../../../common/enums/UserRole"
interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    integration: IIntegration
    handleSave: (integration: IIntegration) => void
    organisationId: string
}

enum ConnectionStatus {
    NOTCONNECTED = "NOTCONNECTED",
    CONNECTED = "CONNECTED",
    FAILED = "FAILED",
}

const IntegrationDialog = (props: InputProps) => {

    const [integrationToEdit, setIntegrationToEdit] = useState<IIntegration>({ ...props.integration })
    const integrationValidation = useIntegrationValidation(integrationToEdit)
    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.NOTCONNECTED)

    const organisation: IOrganisation = useContext(OrganisationContext)
    const { dBUser } = useContext(UserContext)
    const customFetchApi = useFetch('')

    const save = () => {
        if (integrationValidation.validateInputs()) {
            integrationValidation.clearValidation()
            integrationValidation.setIsValidationPassed(true)
            props.handleSave(integrationToEdit)
            setConnectionStatus(ConnectionStatus.NOTCONNECTED)
            props.handleClose()
        }
    }

    const handleClose = () => {
        setIntegrationToEdit({} as IIntegration)
        integrationValidation.clearValidation()
        integrationValidation.setIsValidationPassed(true)
        setConnectionStatus(ConnectionStatus.NOTCONNECTED)
        props.handleClose()
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setIntegrationToEdit({ ...integrationToEdit, [name]: value })
    }

    const checkConnection = async () => {
        if (integrationToEdit.apiUrl && integrationToEdit.apiKey) {
            try {
                let ukTime = moment().tz('GMT-0').format("YYYY-MM-DD HH:mm")
                let response = await customFetchApi.customFetch(`${integrationToEdit.apiUrl}?test_endpoint&token=${md5(`${integrationToEdit.apiKey}${ukTime}`)}`, 'GET', null, {})
                setConnectionStatus(response.success ? ConnectionStatus.CONNECTED : ConnectionStatus.FAILED)
            }
            catch (err: any) {
                setConnectionStatus(ConnectionStatus.FAILED)
            }
        }
    }


    useEffect(() => {
        if (props.integration) {
            setIntegrationToEdit({ ...props.integration, costPrice: props.integration.costPrice ?? IntegrationCostPrice.TRADE_PRICE })
        }
    }, [props.integration])

    useEffect(() => {
        integrationValidation.clearValidation()
    }, [organisation])

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={handleClose}
                className="integration-dialog"
            >
                <DialogTitle id="form-dialog-title">Integration</DialogTitle>
                <DialogContent>


                    <TextField
                        variant="outlined"
                        id="name"
                        name="name"
                        label="Integration name"
                        type="text"
                        fullWidth
                        value={integrationToEdit.name}
                        onChange={handleChange}
                        required
                        helperText={!integrationValidation.getValidation("name").isValid && integrationValidation.getValidation("name").validationMessage}
                        error={!integrationValidation.getValidation("name").isValid}
                    />

                    <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={!integrationValidation.getValidation("provider").isValid}
                    >
                        <InputLabel id="provider-label">Provider</InputLabel>
                        <Select
                            labelId="provider-label"
                            id="provider"
                            name="provider"
                            value={integrationToEdit.provider}
                            onChange={handleChange}
                            label="Provider"
                        >
                            <MenuItem value={IntegrationProvider.BLUEBOX_ONLINE}>BlueBox Online</MenuItem>
                        </Select>
                        {!integrationValidation.getValidation("provider").isValid && <FormHelperText>{integrationValidation.getValidation("provider").validationMessage} </FormHelperText>}
                    </FormControl>

                    {integrationToEdit.provider === IntegrationProvider.BLUEBOX_ONLINE && dBUser.role === UserRole.SUPER_ADMIN && (
                        <FormControl
                            variant="outlined"
                            fullWidth
                        >
                            <InputLabel id="costPrice-label">Cost Price</InputLabel>
                            <Select
                                labelId="costPrice-label"
                                id="costPrice"
                                name="costPrice"
                                value={integrationToEdit.costPrice}
                                onChange={handleChange}
                                label="Cost Price"
                            >
                                <MenuItem value={IntegrationCostPrice.TRADE_PRICE}>Trade Price</MenuItem>
                                <MenuItem value={IntegrationCostPrice.RETAIL_SUPPLY_PRICE}>Retail Supply Price</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        variant="outlined"
                        id="apiKey"
                        name="apiKey"
                        label="API Key"
                        type="text"
                        fullWidth
                        value={integrationToEdit.apiKey}
                        onChange={handleChange}
                        required
                        helperText={!integrationValidation.getValidation("apiKey").isValid && integrationValidation.getValidation("apiKey").validationMessage}
                        error={!integrationValidation.getValidation("apiKey").isValid}
                    />
                    <TextField
                        variant="outlined"
                        id="apiUrl"
                        name="apiUrl"
                        label="API URL"
                        type="text"
                        fullWidth
                        value={integrationToEdit.apiUrl}
                        onChange={handleChange}
                        required
                        helperText={!integrationValidation.getValidation("apiUrl").isValid && integrationValidation.getValidation("apiUrl").validationMessage}
                        error={!integrationValidation.getValidation("apiUrl").isValid}
                    />
                    <span> <a href="javascript:void(0)" rel="noreferrer" onClick={checkConnection}>Test API Settings</a>
                        <h4 style={{ display: 'inline' }}>
                            {connectionStatus === ConnectionStatus.NOTCONNECTED ? <></> : connectionStatus === ConnectionStatus.CONNECTED ? <p className="success">API Connection Successful</p> : <p className="danger">API Connection Failed</p>}
                        </h4>
                    </span>

                </DialogContent>
                <DialogActions>
                    <div className="action-section">
                        <>{!integrationValidation.isValidationPassed && <p className="validation-text">Something's not right, please check highlighted fields above.</p>}</>

                        <div className="save-button-wrapper">
                            <div className="buttons">
                                <Button onClick={() => handleClose()} id="cancel-button">
                                    Cancel
                                </Button>

                                <Button
                                    onClick={() => save()}
                                    className="gg-button"
                                    id="save-button"
                                >
                                    <span>Save </span>
                                </Button>
                            </div>

                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default IntegrationDialog
