import React, { useContext, useEffect, useMemo, useState } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, FormHelperText, RadioGroup, FormControlLabel, Radio, Autocomplete, Paper, InputAdornment, ListSubheader } from "@mui/material"
import '../dialog.scss'
import { IUser } from "../../../interfaces/user"
import useStaffValidation from "./useStaffValidation"
import { UserRole } from "../../../common/enums/UserRole"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, getFilteredUserListQueryString, ORGANISATIONS_API_PATHS, USER_API_PATHS } from "../../../common/ApiPaths"
import IOrganisation from "../../../interfaces/organisation"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { UserContext } from "../../../context/UserContext"
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import ReactCountryFlag from "react-country-flag"
import SearchIcon from "@mui/icons-material/Search"

interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    user: IUser
    handleSave: (user: IUser) => void
    organisationId: string
    isCampaignView: boolean
    campaignUsers?: IUser[]
}

enum View {
    EXISTING = "EXISTING",
    NEW = "NEW",
}

const StaffDialog = (props: InputProps) => {
    let commonFunctions = useCommonFunctions()
    const allCountriesData = commonFunctions.getAllCountriesData()

    const [userToEdit, setUserToEdit] = useState<IUser>({ ...props.user })
    const [userList, setUserList] = useState<IUser[]>([])
    const [userOptions, setUserOptions] = useState<any[]>([])
    const [view, setView] = useState<View>(View.EXISTING)
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [phoneError, setPhoneError] = useState<boolean>(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>("")
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>(commonFunctions.findIsdCodeByCountryCode("GB"))
    const [searchText, setSearchText] = useState("")

    const staffValidation = useStaffValidation(userToEdit)
    const organisationApi = useFetch(API_PATHS.ORGANISATIONS)
    const usersApi = useFetch(API_PATHS.USERS)

    const organisation: IOrganisation = useContext(OrganisationContext)
    const { dBUser } = useContext(UserContext)

    const displayedCountries = useMemo(() => {
        let lowercaseSearchText = searchText.toLowerCase()
        return allCountriesData.filter(
            option =>
                option.name.toLowerCase().includes(lowercaseSearchText) ||
                option.isd_code.includes(lowercaseSearchText) ||
                `${option.name} (${option.isd_code})`.toLowerCase().includes(lowercaseSearchText)
        )
    }, [searchText])

    const save = () => {
        if (staffValidation.validateInputs(props.isCampaignView)) {
            staffValidation.clearValidation()
            staffValidation.setIsValidationPassed(true)
            props.handleSave(userToEdit)
            props.handleClose()
        }
    }

    const handleClose = () => {
        setUserToEdit({} as IUser)
        staffValidation.clearValidation()
        staffValidation.setIsValidationPassed(true)
        setView(View.EXISTING)
        props.handleClose()
    }

    const handlePhoneChange = (e) => {
        let phone = selectedCountryCode + e.target.value
        let parsed = commonFunctions.getParsedPhone(phone)
        if (!parsed.error) {
            if (!commonFunctions.isNumberCountryCode(phone)) {
                setUserToEdit({ ...userToEdit, phone: parsed.phone })
                setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
                setPhoneError(parsed.error)
                setPhoneErrorMessage(parsed.errorMessage)
            } else {
                setPhoneError(false)
                setPhoneErrorMessage("")
                setUserToEdit({ ...userToEdit, phone: selectedCountryCode + e.target.value })
                setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
            }
        }
    }

    const selectUser = (userId: string) => {
        const selectedUser = userList.find(x => x._id === userId) ?? ({} as IUser)
        setUserToEdit({ ...selectedUser, organisationId: props.organisationId, role: userToEdit.role })
    }
    const handleChange = (event) => {
        let { name, value } = event.target
        if (['firstName', 'surname'].includes(name)) {
            value = value.replace(/[^a-z]/gi, '')
        }
        setUserToEdit({ ...userToEdit, [name]: value })
    }

    const getUsersList = async (input: string) => {
        if (input.length > 2 || props.isCampaignView) {
            try {
                const result: IUser[] = !props.isCampaignView ?
                    await organisationApi.get(
                        `${ORGANISATIONS_API_PATHS.GET_USERS_FOR_ORGANISATION_CAMPAIGNS.replace('{organisationId}', organisation._id).replace("{searchString}", input)}?currentUserRole=${dBUser.role}`,
                    ) :
                    await usersApi.get(`${USER_API_PATHS.GET_USERS_LIST}${getFilteredUserListQueryString(undefined, organisation._id)}`)

                let filteredUsers = result.filter((a) => a._id.toString() !== dBUser._id.toString())
                if (props.isCampaignView) {
                    let campaignUserIds = props.campaignUsers?.map(x => x._id)
                    filteredUsers = filteredUsers.filter(x => x.role !== UserRole.NONE && x.role !== UserRole.SUPER_ADMIN && !campaignUserIds?.includes(x._id))
                }
                let options = filteredUsers.map(
                    (a) => {
                        return {
                            value: a._id,
                            label: `${a.firstName} ${a.surname} (${a.email})`,
                        }
                    }
                );
                setUserList(filteredUsers)
                setUserOptions(options)
            }
            catch (err: any) {
                console.log(err)
            }
        }
        else {
            setUserList([])
        }
    }

    useEffect(() => {
        if (props.user) {
            setUserToEdit(props.user)
        }
    }, [props.user])

    useEffect(() => {
        staffValidation.clearValidation()
    }, [organisation])

    useEffect(() => {
        if (organisation && dBUser && props.isCampaignView) {
            getUsersList('')
        }
    }, [organisation, dBUser, props.isCampaignView, props.campaignUsers])

    const togglePasswordVisibility = () => {
        var x = document.getElementById("password")
        if (x) {
            setIsPasswordVisible(!isPasswordVisible)
            if (x.getAttribute('type') === "password") {
                x.setAttribute('type', 'text')
            } else {
                x.setAttribute('type', 'password')
            }
        }
    }

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={handleClose}
                className="staff-dialog"
            >
                <DialogTitle id="form-dialog-title">Staff</DialogTitle>
                <DialogContent>
                    <form autoComplete='off'>
                        {!userToEdit._id && !props.isCampaignView && (
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label"
                                value={view}
                                name="radio-buttons-group"
                                onChange={(x) => {
                                    setView(View[x.target.value])
                                }}
                            >
                                <FormControlLabel value={View.EXISTING} control={<Radio />} label="Add Existing User" />
                                <FormControlLabel value={View.NEW} control={<Radio />} label="Add New User" />
                            </RadioGroup>
                        )}

                        {view === View.EXISTING && !props.user._id && (
                            <div className="field-wrapper">
                                {props.isCampaignView ? (
                                    <FormControl style={{ width: "100%" }}>
                                        <InputLabel id="select-user-label">User</InputLabel>
                                        <Select
                                            labelId="select-user-label"
                                            id="users-list"
                                            value={userToEdit._id}
                                            onChange={(event) => selectUser(event.target.value)}
                                            label="User"
                                            style={{ width: "100%" }}
                                            className="user-autocomplete"
                                            error={!staffValidation.getValidation("email").isValid}
                                        >
                                            {userOptions.map((u: any, index: number) => (
                                                <MenuItem key={index} value={u.value}>{u.label}</MenuItem>
                                            ))}
                                        </Select>
                                        {!staffValidation.getValidation("email").isValid && <FormHelperText error>{staffValidation.getValidation("email").validationMessage}</FormHelperText>}
                                    </FormControl>
                                ) : (
                                    <Autocomplete
                                        id="users-list"
                                        className="user-autocomplete"
                                        disablePortal
                                        options={userOptions}
                                        noOptionsText="Please enter 3 characters to search..."
                                        renderInput={params => <TextField {...params} label="User Name" helperText="Enter 3 characters to display options." />}
                                        onInputChange={(e, value) => getUsersList(value)}
                                        onChange={(event, selected) => selectUser(selected.value)}
                                        clearOnEscape
                                        style={{ width: "100%" }}
                                        PaperComponent={(props) => {
                                            return <Paper {...props} style={{ width: "100%" }} />;
                                        }}
                                    />
                                )}

                            </div>
                        )}
                        {view === View.NEW && !props.isCampaignView && (
                            <>
                                <TextField
                                    variant="outlined"
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    type="text"
                                    fullWidth
                                    value={userToEdit.firstName}
                                    onChange={handleChange}
                                    required
                                    helperText={!staffValidation.getValidation("firstName").isValid && staffValidation.getValidation("firstName").validationMessage}
                                    error={!staffValidation.getValidation("firstName").isValid}
                                />
                                <TextField
                                    variant="outlined"
                                    id="surname"
                                    name="surname"
                                    label="Surname"
                                    type="text"
                                    fullWidth
                                    value={userToEdit.surname}
                                    onChange={handleChange}
                                    required
                                    helperText={!staffValidation.getValidation("surname").isValid && staffValidation.getValidation("surname").validationMessage}
                                    error={!staffValidation.getValidation("surname").isValid}
                                />
                                <TextField
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="text"
                                    fullWidth
                                    value={userToEdit.email}
                                    onChange={handleChange}
                                    required
                                    helperText={!staffValidation.getValidation("email").isValid && staffValidation.getValidation("email").validationMessage}
                                    error={!staffValidation.getValidation("email").isValid}
                                />

                                {/*  */}
                                <FormControl variant="outlined" style={{ width: '100%' }} >
                                    <div style={{ display: 'flex', gap: '5px' }}>
                                        <InputLabel id="country-code-label">Country Code</InputLabel>
                                        <Select
                                            id="countryCode"
                                            name="countryCode"
                                            value={selectedCountryCode}
                                            onChange={event => setSelectedCountryCode(event.target.value)}
                                            onBlur={event => setUserToEdit({ ...userToEdit, phone: event.target.value + commonFunctions.getNumberWithoutCountryCode(userToEdit.phone) || '' })}
                                            label="Country Code"
                                            variant="outlined"
                                            onClose={() => setSearchText("")}
                                            MenuProps={{ autoFocus: false }}
                                            renderValue={value => {
                                                const selected = allCountriesData.filter(x => x.isd_code === value)[0].countryCode
                                                return (
                                                    <>
                                                        <ReactCountryFlag countryCode={selected} style={{ marginRight: "8px" }} />
                                                        {value}
                                                    </>
                                                )
                                            }}
                                            style={{ height: 'max-content' }}
                                        >
                                            <ListSubheader>
                                                <TextField
                                                    size="small"
                                                    autoFocus
                                                    placeholder="Search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={e => setSearchText(e.target.value)}
                                                />
                                            </ListSubheader>
                                            {displayedCountries.map((country, index) => (
                                                <MenuItem value={country.isd_code} key={index}>
                                                    <ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: "8px" }} />
                                                    {` ${country.name} (${country.isd_code})`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <TextField
                                            id="phone"
                                            name="phone"
                                            value={phoneNumber}
                                            onChange={event => setPhoneNumber(event.target.value)}
                                            // onChange={event => props.handleChange(event.target.name, `${selectedCountryCode} ${(event.target.value) || ''}`)}
                                            variant="outlined"
                                            label="Contact Phone"
                                            required
                                            fullWidth
                                            helperText={
                                                !staffValidation.getValidation("phone").isValid || phoneError
                                                    ? staffValidation.getValidation("phone").validationMessage || phoneErrorMessage
                                                    : ""
                                            }
                                            error={!staffValidation.getValidation("phone").isValid || phoneError}
                                            inputProps={{ maxLength: 20 }}
                                            onBlur={e => handlePhoneChange(e)}
                                            style={{ marginBottom: "0px" }}
                                        />
                                    </div>
                                    <FormHelperText
                                        className={!staffValidation.getValidation("phone").isValid || phoneError ? "d-none" : ""}
                                        style={{ paddingLeft: "8px" }}
                                    >
                                        {
                                            staffValidation.getValidation("phone").isValid
                                                ? ""
                                                : staffValidation.getValidation("phone").validationMessage
                                        }
                                    </FormHelperText>
                                </FormControl>

                                {/*  */}

                                {!userToEdit._id && (
                                    <>
                                        <TextField
                                            variant="outlined"
                                            id="password"
                                            name="password"
                                            label="Password"
                                            type="password"
                                            fullWidth
                                            value={userToEdit.password}
                                            onChange={handleChange}
                                            required
                                            helperText={!staffValidation.getValidation("password").isValid ? staffValidation.getValidation("password").validationMessage : 'Your password should be a combination of lowercase letters, uppercase letters, numbers & special characters'}
                                            error={!staffValidation.getValidation("password").isValid}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="start">
                                                        {!isPasswordVisible ?
                                                            <VisibilityIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                                            :
                                                            <VisibilityOffIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                                        }
                                                    </InputAdornment>
                                            }}
                                        />

                                    </>
                                )}
                            </>
                        )}
                        {!props.isCampaignView && (
                            <FormControl variant="outlined" fullWidth required error={!staffValidation.getValidation("role").isValid}>
                                <InputLabel id="role-label">Role</InputLabel>
                                <Select labelId="role-label" id="role" name="role" value={userToEdit.role} onChange={handleChange} label="Role">
                                    <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
                                    <MenuItem value={UserRole.OPERATOR}>Operator</MenuItem>
                                </Select>
                                {!staffValidation.getValidation("role").isValid && (
                                    <FormHelperText>{staffValidation.getValidation("role").validationMessage} </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <div className="action-section">
                        <>
                            {!staffValidation.isValidationPassed && (
                                <p className="validation-text">Something's not right, please check highlighted fields above.</p>
                            )}
                        </>

                        <div className="save-button-wrapper">
                            <div className="buttons">
                                <Button onClick={() => handleClose()} id="cancel-button">
                                    Cancel
                                </Button>

                                <Button onClick={() => save()} className="gg-button" id="save-button">
                                    <span>Save</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default StaffDialog
