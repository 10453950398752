import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    Link,
    ListSubheader,
    MenuItem,
    Select,
    Tab,
    TextField,
} from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { IUser } from "../../interfaces/user"
import { auth } from "../../auth/auth0.service"
import { Auth0Error } from "auth0-js"
import useLoginSignupValidation from "./useLoginSignupValidation"
import IValidationObject from "../../interfaces/validationObject"
import { UserRole } from "../../common/enums/UserRole"
import { API_PATHS, USER_API_PATHS } from "../../common/ApiPaths"
import useFetch from "../../hooks/useFetch"
import "./loginSignup.scss"
import ReactCountryFlag from "react-country-flag"
import SearchIcon from "@mui/icons-material/Search"
import useCommonFunctions from "../../hooks/useCommonFunctions"
import ForgotPassword from "./ForgotPassword"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { Oval } from "react-loader-spinner"
import BackHyperlink from "../shared/BackHyperLink"
import { CampaignContext } from "../../context/CampaignContext"
import { useLocation } from "react-router-dom"
import PinField from '../admin/shared/PinField'

enum Tabs {
    LOGIN = "LOGIN",
    SIGNUP = "SIGNUP",
}

interface ILoginSignupTabsProps {
    isForgotPasswordView: boolean
    setIsForgotPasswordView: (val: boolean) => void
    isAdminView: boolean
    handleClose: () => void
}

const LoginSignupTabs = (props: ILoginSignupTabsProps) => {
    const [user, setUser] = useState({} as IUser)
    const [activeTab, setActiveTab] = useState(Tabs.LOGIN)
    const loginSignUpValidation = useLoginSignupValidation(user)

    const commonFunctions = useCommonFunctions()
    const allCountriesData = commonFunctions.getAllCountriesData()
    const location = useLocation()

    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [loginError, setLoginError] = useState<string>('')
    const [signupError, setSignupError] = useState<string>('')
    const [isInProgress, setIsInProgress] = useState<boolean>(false)
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>(commonFunctions.findIsdCodeByCountryCode("GB"))
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
    const [searchText, setSearchText] = useState("")
    const [phoneError, setPhoneError] = useState<boolean>(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>("")
    const [isUnauthorised, setIsUnauthorised] = useState<boolean>(false)
    const [isNonAdminUser, setIsNonAdminUser] = useState<boolean>(false)
    const [isLoginFailed, setIsLoginFailed] = useState<boolean>(false)
    const [pin, setPin] = useState<string>('')
    const [isPinValid, setIsPinValid] = useState<boolean>(true)

    const usersApi = useFetch(API_PATHS.USERS)
    const campaign = useContext(CampaignContext)

    const displayedCountries = useMemo(() => {
        let lowercaseSearchText = searchText.toLowerCase()
        return allCountriesData.filter(
            option =>
                option.name.toLowerCase().includes(lowercaseSearchText) ||
                option.isd_code.includes(lowercaseSearchText) ||
                `${option.name} (${option.isd_code})`.toLowerCase().includes(lowercaseSearchText)
        )
    }, [searchText])


    useEffect(() => {
        if (location.search.split("?")[1] === "unauthorised") {
            setIsUnauthorised(true)
        } else if (location.search.split("?")[1] === "not-admin") {
            setIsNonAdminUser(true)
        } else if (location.search.split("?")[1] === "unknown-user") {
            setIsLoginFailed(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const togglePasswordVisibility = () => {
        var x = document.getElementById("password")
        if (x) {
            setIsPasswordVisible(!isPasswordVisible)
            if (x.getAttribute('type') === "password") {
                x.setAttribute('type', 'text')
            } else {
                x.setAttribute('type', 'password')
            }
        }
    }

    const handlePhoneChange = (e) => {
        let phone = selectedCountryCode + e.target.value
        let parsed = commonFunctions.getParsedPhone(phone)
        if (!parsed.error) {
            if (!commonFunctions.isNumberCountryCode(phone)) {
                handleChange(e.target.name, parsed.phone)
                setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
                setPhoneError(parsed.error)
                setPhoneErrorMessage(parsed.errorMessage)
            } else {
                setPhoneError(false)
                setPhoneErrorMessage("")
                handleChange(e?.target.name, selectedCountryCode + e.target.value)
                setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
            }
        }
    }

    const validatePin = (): boolean => {
        const isValid = pin && pin.length === 4 ? true : false
        console.log('is pin Valid: ', isValid)
        setIsPinValid(isValid)
        return isValid
    }


    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    const handleChange = (name, value) => {
        setUser({ ...user, [name]: value })
    }

    const login = () => {
        if (loginSignUpValidation.validateInputs(Tabs.LOGIN)) {
            setIsInProgress(true)
            if (window.location.href.includes('/')) {
                localStorage.setItem('redirectUri', '')
            }
            auth.login({
                username: user.email,
                password: user.password,
                realm: 'Username-Password-Authentication',
                redirectUri: `${window.location.origin}/authenticate`,
                responseType: 'token'
            }, (error: Auth0Error | null, result: any) => {
                if (error) {
                    console.log(error.description)
                    setLoginError(error.description)
                    setIsInProgress(false)
                }
                else {
                    console.log('User logged in: ', result)
                    setIsInProgress(false)
                    // currentUser.login(result)
                    // localStorage.setItem('currentUser', JSON.stringify(result))
                }

            })
        }
    }

    const registerUser = () => {
        const pinValid = validatePin()
        if (loginSignUpValidation.validateInputs(Tabs.SIGNUP) && pinValid) {
            setIsInProgress(true)
            auth.signup({
                email: user.email,
                password: user.password,
                given_name: user.firstName,
                family_name: user.surname,
                name: user.firstName + ' ' + user.surname,
                connection: 'Username-Password-Authentication'
            }, async (error: Auth0Error | null, result: any) => {
                if (error) {
                    console.log(error.description)
                    setSignupError(error.description)
                    setIsInProgress(false)
                }
                else {
                    const userInfo = {
                        organisationId: null,
                        role: UserRole.NONE,
                        auth0Id: `auth0|${result.Id}`,
                        firstName: result.givenName,
                        surname: result.familyName,
                        email: result.email,
                        campaignId: campaign && campaign._id ? campaign._id : '',
                        password: result.password,
                        stripeConnectedAccountId: null,
                        phone: user.phone,
                        pin: pin
                    }
                    await usersApi.post(`${USER_API_PATHS.CREATE_USER}`, userInfo)
                    localStorage.setItem('redirectUri', `${window.location.pathname}?signedUp`)
                    login()
                    setIsInProgress(false)
                }
            })
        }
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        loginSignUpValidation.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        })
        return returnValue
    }

    return (
        <TabContext value={activeTab} /*style={{ width: '100%' }} variant="fullWidth"*/>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <TabList onChange={(e, val) => handleTabClick(val)} aria-label="loginSignup tabs">
                    <Tab label="Login" value={Tabs.LOGIN} />
                    {!props.isAdminView && (
                        <Tab label="Signup" value={Tabs.SIGNUP} />
                    )}
                </TabList>
            </Box>


            <TabPanel value={Tabs.LOGIN}>
                {!props.isForgotPasswordView ? (
                    <>
                        <form>
                            <div className="field-wrapper">
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    variant="outlined"
                                    fullWidth
                                    onChange={event => handleChange(event.target.name, event.target.value)}
                                    required
                                    helperText={
                                        getValidation("email").isValid
                                            ? ""
                                            : getValidation("email").validationMessage
                                    }
                                    error={!getValidation("email").isValid}
                                />
                            </div>
                            <div className="field-wrapper">
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    onChange={event => handleChange(event.target.name, event.target.value)}
                                    required
                                    helperText={!getValidation("password").isValid ? getValidation("password").validationMessage : ''}
                                    error={!getValidation("password").isValid}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="start">
                                                {!isPasswordVisible ?
                                                    <VisibilityIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                                    :
                                                    <VisibilityOffIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                                }
                                            </InputAdornment>
                                    }}
                                />
                            </div>
                            <div className="field-wrapper forgot-password-link">
                                <Link onClick={() => props.setIsForgotPasswordView(true)}>Forgot password</Link>
                            </div>

                            <div className="save-button-wrapper">


                                {isUnauthorised && <span className="validation-text">You must be logged in to access the portal.</span>}
                                {isNonAdminUser && <span className="validation-text">You must be an admin user to access the portal.</span>}
                                {isLoginFailed && <span className="validation-text">Unknown User.</span>}
                                {!loginSignUpValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before continuing.</span>}
                                {loginError && loginError.length > 0 && <span className="validation-text">{loginError}</span>}
                                <div className="buttons">
                                    <Button id="cancel-button" onClick={props.handleClose} >
                                        Cancel
                                    </Button>
                                    <Button id="login-button" variant="contained" className="gg-button" onClick={login}>
                                        {isInProgress ? (
                                            <Oval ariaLabel="loading-indicator" height={20} width={20} strokeWidth={5} color="white" secondaryColor="white" />
                                        )
                                            :
                                            (
                                                'Login'
                                            )}
                                    </Button>
                                </div>


                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <BackHyperlink onClickFunction={() => props.setIsForgotPasswordView(false)} text={'Back to Login'} />
                        <ForgotPassword handleClose={props.handleClose} />
                    </>
                )}

            </TabPanel>

            {!props.isAdminView && (
                <TabPanel value={Tabs.SIGNUP}>
                    <form>
                        <TextField
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            required
                            helperText={
                                getValidation("firstName").isValid
                                    ? ""
                                    : getValidation("firstName").validationMessage
                            }
                            error={!getValidation("firstName").isValid}
                        />
                        <TextField
                            id="surname"
                            name="surname"
                            label="Surname"
                            variant="outlined"
                            fullWidth
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            required
                            helperText={
                                getValidation("surname").isValid
                                    ? ""
                                    : getValidation("surname").validationMessage
                            }
                            error={!getValidation("surname").isValid}
                        />

                        <FormControl variant="outlined" style={{ width: '100%' }} >
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <InputLabel id="country-code-label">Country Code</InputLabel>
                                <Select
                                    id="countryCode"
                                    name="countryCode"
                                    value={selectedCountryCode}
                                    onChange={event => setSelectedCountryCode(event.target.value)}
                                    onBlur={event => handleChange('phone', event.target.value + commonFunctions.getNumberWithoutCountryCode(user.phone) || '')}
                                    label="Country Code"
                                    variant="outlined"
                                    onClose={() => setSearchText("")}
                                    MenuProps={{ autoFocus: false }}
                                    renderValue={value => {
                                        const selected = allCountriesData.filter(x => x.isd_code === value)[0].countryCode
                                        return (
                                            <>
                                                <ReactCountryFlag countryCode={selected} style={{ marginRight: "8px" }} />
                                                {value}
                                            </>
                                        )
                                    }}
                                    style={{ height: 'max-content' }}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={e => setSearchText(e.target.value)}
                                        />
                                    </ListSubheader>
                                    {displayedCountries.map((country, index) => (
                                        <MenuItem value={country.isd_code} key={index}>
                                            <ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: "8px" }} />
                                            {` ${country.name} (${country.isd_code})`}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    id="phone"
                                    name="phone"
                                    value={phoneNumber}
                                    onChange={event => setPhoneNumber(event.target.value)}
                                    // onChange={event => props.handleChange(event.target.name, `${selectedCountryCode} ${(event.target.value) || ''}`)}
                                    variant="outlined"
                                    label="Contact Phone"
                                    required
                                    fullWidth
                                    helperText={
                                        !getValidation("phone").isValid || phoneError
                                            ? getValidation("phone").validationMessage || phoneErrorMessage
                                            : ""
                                    }
                                    error={!getValidation("phone").isValid || phoneError}
                                    inputProps={{ maxLength: 20 }}
                                    onBlur={e => handlePhoneChange(e)}
                                    style={{ marginBottom: "0px" }}
                                />
                            </div>
                            <FormHelperText
                                className={!getValidation("phone").isValid || phoneError ? "d-none" : ""}
                                style={{ paddingLeft: "8px" }}
                            >
                                {
                                    getValidation("phone").isValid
                                        ? ""
                                        : getValidation("phone").validationMessage
                                }
                            </FormHelperText>
                        </FormControl>

                        <TextField
                            id="email"
                            name="email"
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            required
                            helperText={
                                getValidation("email").isValid
                                    ? ""
                                    : getValidation("email").validationMessage
                            }
                            error={!getValidation("email").isValid}
                        />
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            required
                            helperText={!getValidation("password").isValid ? getValidation("password").validationMessage : 'Your password should be a combination of lowercase letters, uppercase letters, numbers & special characters'}
                            error={!getValidation("password").isValid}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="start">
                                        {!isPasswordVisible ?
                                            <VisibilityIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                            :
                                            <VisibilityOffIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                        }
                                    </InputAdornment>
                            }}
                        />

                        <PinField
                            label={'PIN'}
                            pin={pin}
                            setPin={setPin}
                            helperText={isPinValid ? 'Used for events using fundraising tablets' : 'Please enter a valid PIN'}
                            pinId={'sign-up-pin'}
                            isValid={isPinValid}
                        />

                        <div className="save-button-wrapper" style={{ marginTop: 12 }}>
                            {!loginSignUpValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before continuing.</span>}
                            {signupError && signupError.length > 0 && <span className="validation-text">{signupError}</span>}

                            <div className="buttons">
                                <Button id="cancel-button" onClick={props.handleClose} >
                                    Cancel
                                </Button>
                                <Button className="gg-button" onClick={registerUser}>
                                    {isInProgress ? (
                                        <Oval ariaLabel="loading-indicator" height={20} width={20} strokeWidth={5} color="white" secondaryColor="white" />
                                    ) : (
                                        'Sign up'
                                    )}
                                </Button>
                            </div>



                        </div>
                    </form>
                </TabPanel>
            )}
        </TabContext>
    )
}

export default LoginSignupTabs
