import "./itemCatalogue.scss"
import React, { useContext, useEffect, useState } from "react"
import _ from "lodash"
import IOrganisation from "../../../interfaces/organisation"
import { Link } from "react-router-dom"
import ICatalogueItem from "../../../interfaces/catalogueItem"
import CatalogueItemDetailsSection from "./CatalogueItemDetailsSection"
import useCatalogueItemValidation from "./useCatalogueItemValidation"
import MediaSection from "./MediaSection"
import LotDescriptionSection from "../lotSettings/sections/LotDescriptionSection"
import TagsSection from "../lotSettings/sections/TagsSection"
import { Button } from "@mui/material"
import { UserContext } from "../../../context/UserContext"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, CATALOGUE_API_PATHS } from "../../../common/ApiPaths"

interface InputProps {
	organisation: IOrganisation
	setIsNewItemView: (value: boolean) => void
	setDialogProperties: (properties: any) => void
	tagsList: string[]
	catalogueItemToBeEdited: ICatalogueItem | null
	updateItem: (item: ICatalogueItem) => void
}

const NewCatalogueItem = (props: InputProps) => {

	const [catalogueItem, setCatalogueItem] = useState({} as ICatalogueItem)

	let catalogueItemValidation = useCatalogueItemValidation(catalogueItem)
	const { dBUser } = useContext(UserContext)
	const catalogueApi = useFetch(API_PATHS.CATALOGUE)

	const handleChange = (name, value) => {
		setCatalogueItem({ ...catalogueItem, [name]: value })
	}

	useEffect(() => {
		if (props.catalogueItemToBeEdited?._id) {
			setCatalogueItem({ ...props.catalogueItemToBeEdited })
		}
	}, [props.catalogueItemToBeEdited])

	const showTagsSection = () => {
		if (props.catalogueItemToBeEdited && catalogueItem._id) {
			return true
		}
		else if (props.catalogueItemToBeEdited === null) {
			return true
		}
		else {
			return false
		}
	}

	const saveItem = async () => {
		if (catalogueItemValidation.validateInputs()) {
			let itemToBeCreated = {
				_id: props.catalogueItemToBeEdited ? props.catalogueItemToBeEdited._id : null,
				organisationId: props.organisation._id,
				title: catalogueItem.title,
				subtitle: catalogueItem.subtitle,
				description: catalogueItem.description,
				costPrice: Number(catalogueItem.costPrice),
				typicallySellsFor: Number(catalogueItem.typicallySellsFor),
				suggestedReservePrice: Number(catalogueItem.suggestedReservePrice),
				tagList: catalogueItem.tagList ?? [],
				mediaList: catalogueItem.mediaList ?? [],
				userId: dBUser._id,
				isVatApplicable: Boolean(catalogueItem.isVatApplicable)
			}
			try {
				if (props.catalogueItemToBeEdited) {
					let updatedItem = await catalogueApi.put(CATALOGUE_API_PATHS.UPDATE_CATALOGUE_ITEM, itemToBeCreated)
					props.updateItem(updatedItem)
					props.setDialogProperties({
						isOpen: true,
						type: "SUCCESS",
						title: "Item Saved.",
						message: "Your catalogue item has successfully been saved.",
					})
				} else {
					await catalogueApi.post(CATALOGUE_API_PATHS.CREATE_CATALOGUE_ITEM, itemToBeCreated)
					props.setDialogProperties({
						isOpen: true,
						type: "SUCCESS",
						title: "Item Created.",
						message: "Your catalogue item has successfully been created.",
					})
				}
				props.setIsNewItemView(false)
			}
			catch (err: any) {
				props.setDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: "Create failed.",
					message: `Item creation failed with the following error: ${err}`,
				})
			}
		}
	}

	return (
		<div className="lot-catalogue">
			<Link to={{ search: 'tabNumber=4' }} onClick={() => props.setIsNewItemView(false)}>
				{'< Back to Catalogue'}
			</Link>
			<h1>Create Catalogue Item</h1>
			<span className="description">Create a new item that will be added to the organisation catalogue</span>
			{/* <p>{JSON.stringify(catalogueItem)}</p> */}

			<CatalogueItemDetailsSection
				handleChange={handleChange}
				catalogueItem={catalogueItem}
				setCatalogueItem={setCatalogueItem}
				validationObject={catalogueItemValidation.validationObject}
			/>

			<MediaSection
				catalogueItem={catalogueItem}
				setCatalogueItem={setCatalogueItem}
				catalogueItemValidation={catalogueItemValidation}
			/>

			<LotDescriptionSection
				object={catalogueItem}
				setObject={setCatalogueItem}
				isReadOnly={false}
				objectName={'Item'}
			/>

			{showTagsSection() && (
				<TagsSection
					tags={props.tagsList}
					object={catalogueItem}
					setObject={setCatalogueItem}
					isCampaignClosed={false}
					objectName={'Item'}
					fromVMS={false}
				/>
			)}

			<div className="fixed-action-section">
				<div></div>
				{!catalogueItemValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
				<Button className="gg-button" onClick={saveItem}>Save</Button>
			</div>


		</div >
	)
}

export default NewCatalogueItem
