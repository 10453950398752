import { Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import IValidationObject from '../../../interfaces/validationObject'
import { ICustomPage } from '../../../interfaces/customPage'
import useCustomPageValidation from './useCustomPageValidation'
import useFetch from '../../../hooks/useFetch'
import { CUSTOM_PAGES_API_PATH, CUSTOM_PAGES_PATHS } from '../../../common/CustomPageApiPaths'
import ICampaign from '../../../interfaces/campaign'
import { CampaignContext, CampaignDispatchContext } from '../../../context/CampaignContext'
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignQueryString } from '../../../common/ApiPaths'
import { UserContext } from '../../../context/UserContext'

interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    customPageList: ICustomPage[]
    type: "EDIT" | "NEW"
    customPageToBeEdited?: ICustomPage
}

const CustomPageDialog = (props: InputProps) => {

    const [customPage, setCustomPage] = useState<ICustomPage>({ isHidden: false } as ICustomPage)
    const [isCTA, setIsCTA] = useState<boolean>(false)
    const [error, setError] = useState<string>("")

    const customPageValidation = useCustomPageValidation()
    const campaign: ICampaign = useContext(CampaignContext)
    const setCampaign = useContext(CampaignDispatchContext);

    const customPageApi = useFetch(CUSTOM_PAGES_API_PATH);
    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS);

    const { dBUser } = useContext(UserContext)

    const formatUrlSuffix = (raw: string): string => {
        return raw
            .replaceAll(" ", "-")
            .toLowerCase()
            .replaceAll(/[^\w\s-]/g, "")
            .replaceAll(/-+/g, "-")
    }

    const createCustomPage = async (): Promise<void> => {
        setError("")
        if (customPageValidation.validateInputs(customPage, props.customPageList, props.type, isCTA)) {

            try {
                await customPageApi.post(CUSTOM_PAGES_PATHS.CREATE_CUSTOM_PAGE, {
                    ...customPage,
                    campaignId: campaign._id,
                    orderNumber: props.customPageList.sort((a, b) => a.orderNumber - b.orderNumber)[props.customPageList.length - 1].orderNumber + 1
                    // orderNumber: props.customPageList.length + 1
                })

                const updatedCampaign = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(undefined, campaign._id)}`)

                setCampaign(updatedCampaign)

                setError("")
                setCustomPage({} as ICustomPage)
                props.handleClose()
            }
            catch (err: any) {
                setError(err.message)
            }
        }
    }

    const updateCustomPage = async (): Promise<void> => {
        setError("")
        if (customPageValidation.validateInputs(customPage, props.customPageList, props.type, isCTA)) {

            if (customPage.isLotpage) {
                try {

                    const updatedCampaign = await campaignsApi.put(`${CAMPAIGN_API_PATHS.UPDATE_CAMPAIGN.replace('{campaignId}', campaign._id)}`,
                        { ...campaign, buttonText: isCTA ? customPage.ctaText : "", isLotsPageHidden: customPage.isHidden, lotsPageOrderNumber: customPage.orderNumber }, dBUser._id)

                    //unset cta from all custom pages
                    await customPageApi.put(`${CUSTOM_PAGES_PATHS.UNSET_ALL_CTAS.replace('{campaignId}', campaign._id)}`, {})

                    setCampaign(updatedCampaign)
                } catch (err: any) {
                    setError(err.message)
                }
            } else {
                try {
                    await customPageApi.put(CUSTOM_PAGES_PATHS.UPDATE_CUSTOM_PAGE, {
                        ...customPage
                    })

                    const updatedCampaign = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(undefined, customPage.campaignId)}`)
                    setCampaign(updatedCampaign)
                }
                catch (err: any) {
                    setError(err.message)
                }
            }

            handleClose()
        }
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        customPageValidation.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        })
        return returnValue
    }

    const handleBlur = (event) => {
        const { name, value } = event.target
        if (name === "isHidden") {
            if (value === "true") {
                setCustomPage({ ...customPage, [name]: true })
            } else {
                setCustomPage({ ...customPage, [name]: false })
            }

        } else {
            setCustomPage({ ...customPage, [name]: value })
        }
    }

    const handleIsCTAChange = (event) => {
        const { name, value } = event.target
        if (value === "true") {
            setIsCTA(true)
        } else {
            setIsCTA(false)
        }

    }

    const handleClose = () => {
        props.handleClose()
        setCustomPage({ isHidden: false } as ICustomPage)
        setError("")
        setIsCTA(false)
        customPageValidation.resetValidation()
    }

    useEffect(() => {
        if (props.customPageToBeEdited?._id) {
            setCustomPage(props.customPageToBeEdited)
            setIsCTA(props.customPageToBeEdited.ctaText ? true : false)
        }
    }, [props.customPageToBeEdited])


    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >

            <DialogContent>
                <div className='coloured-section' >
                    <h2 className='title'>{props.type === "NEW" ? 'Create New' : 'Edit'} Custom Page</h2>
                </div>
                <div className='content-wrapper'>
                    <TextField
                        id="label"
                        name="label"
                        label="Label"
                        variant="outlined"
                        defaultValue={customPage.label || ""}
                        onBlur={event => {
                            setCustomPage({ ...customPage, label: event.target.value, path: formatUrlSuffix(event.target.value) })
                        }}
                        required
                        fullWidth
                        disabled={props.customPageToBeEdited?.isLotpage ? true : false}
                        error={!getValidation("label").isValid}
                        helperText={getValidation("label").isValid ? "The label of the page that will be shown in the menu bar" : getValidation("label").validationMessage}
                    />
                    <TextField
                        id="path"
                        name="path"
                        label="Path"
                        variant="outlined"
                        value={customPage.path || ""}
                        onChange={event => {
                            setCustomPage({ ...customPage, path: formatUrlSuffix(event.target.value) })
                        }}
                        required
                        disabled={props.customPageToBeEdited?.isLotpage ? true : false}
                        fullWidth
                        error={!getValidation("path").isValid}
                        helperText={getValidation("path").isValid ? "The path that the page will sit under. Eg www.goinggone.io/summer-ball/<path>" : getValidation("path").validationMessage}
                    />
                    <FormControl
                        variant="outlined"
                        required
                        fullWidth
                    >
                        <InputLabel id="visibility-label">Page Visibility</InputLabel>
                        <Select
                            labelId="visibility-label"
                            id="isHidden"
                            name="isHidden"
                            defaultValue={customPage.isHidden ? "true" : "false"}
                            onChange={handleBlur}
                            label="Page Visibility"
                        >
                            <MenuItem value={"false"}>Visible</MenuItem>
                            <MenuItem value={"true"}>Hidden</MenuItem>
                        </Select>
                        <FormHelperText>Option to hide the page without deleting it.</FormHelperText>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        required
                        fullWidth
                    >
                        <InputLabel id="is-cta-label">Is CTA?</InputLabel>
                        <Select
                            labelId="is-cta-label"
                            id="isCTA"
                            name="isCTA"
                            defaultValue={isCTA ? "true" : "false"}
                            onChange={handleIsCTAChange}
                            label="Is CTA?"
                        >
                            <MenuItem value={"true"}>TRUE</MenuItem>
                            <MenuItem value={"false"}>FALSE</MenuItem>
                        </Select>
                        <FormHelperText>Set this page to be accessed from the CTA (call to action) button in the menu bar.</FormHelperText>
                    </FormControl>
                    {isCTA &&
                        <TextField
                            id="ctaText"
                            name="ctaText"
                            label="CTA Text"
                            variant="outlined"
                            defaultValue={customPage.ctaText || ""}
                            onBlur={handleBlur}
                            required
                            fullWidth
                            error={!getValidation("ctaText").isValid}
                            helperText={getValidation("ctaText").isValid ? "The text that will show in the CTA button. Eg 'BID NOW!'" : getValidation("ctaText").validationMessage}
                        />
                    }

                </div>

            </DialogContent>
            <DialogActions>
                <div className="action-section">
                    {!customPageValidation.isValidationPassed && <p className="validation-text">Something's not right, please check highlighted fields above.</p>}
                    {error && <p className="validation-text">{error}</p>}

                    <div className="save-button-wrapper">

                        <div className='buttons'>

                            <Button onClick={handleClose} id="cancel-button">
                                Cancel
                            </Button>

                            <Button
                                onClick={() => props.type === "EDIT" ? updateCustomPage() : createCustomPage()}
                                className="gg-button"
                                id="save-button"
                            >
                                {props.type === "EDIT" ? <span>SAVE</span> : <span>CREATE</span>}
                            </Button>
                        </div>

                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default CustomPageDialog