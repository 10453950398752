import React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";

const HistoryDetails = ({ communicationLogs, messageType }) => {
  return (
    <>
      <Box sx={[{ margin: "10px 0px" }]} />
      <TableContainer component={Paper} sx={[{ boxShadow: "none" }]}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead sx={[{ backgroundColor: "primary" }]}>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell align="left">
                <b>First name</b>
              </TableCell>
              <TableCell align="left">
                <b>Surname </b>
              </TableCell>
              <TableCell align="left">
                {messageType === "Email" && <b>Email</b>}
                {messageType === "SMS" && <b>Phone</b>}
              </TableCell>
              <TableCell align="left">
                <b>Success</b>
              </TableCell>
              <TableCell align="left">
                <b>Error Message</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {communicationLogs?.dataRows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {moment(row.date).format("HH:mm DD/MM/YYYY")}
                </TableCell>
                <TableCell align="left">{row?.firstName}</TableCell>
                <TableCell align="left">{row?.surname}</TableCell>
                <TableCell align="left">{row?.contact}</TableCell>
                <TableCell align="left">
                  {row?.success ? "true" : "false"}
                </TableCell>
                <TableCell align="left">{row?.errorMessage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default HistoryDetails;
