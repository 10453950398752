import React, { useState, useContext, useCallback, useEffect } from "react"

import { Box, Button, Paper, Tab, Typography } from "@mui/material"

import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

import IValidationObject from "../../../interfaces/validationObject"
import IRecipientsList from "../../../interfaces/fetchedRecipientsList"
import ITestMessageModalState from "../../../interfaces/testMessageModal"
import ICommunicationUser from "../../../interfaces/communicationUserInfo"
import ITestMessageResponseState from "../../../interfaces/testMessageResponse"

import { CommunicationTabs } from "../../../common/enums/CommunicationTabs"
import { API_PATHS, BID_API_PATHS, COMMUNICATIONS_API_PATHS, USER_API_PATHS, getFilteredUserListQueryString } from "../../../common/ApiPaths"
import { RecipientCategories } from "../../../common/enums/CommunicationRecipientCategories"

import useFetch from "../../../hooks/useFetch"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import useCommunicationValidation from "./useCommunicationValidation"

import { CampaignContext } from "../../../context/CampaignContext"

import History from "./history/History"
import Messaging from "./messaging/Messaging"
import SMS from "./messaging/services/SMS/SMS"
import Email from "./messaging/services/Email/Email"
import CustomTabs from "../../shared/customTabs/CustomTabs"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import TestMessageDialog from "./messaging/test/TestMessageDialog"

import TickImage from "../../../images/tick.gif"
import CrossImage from "../../../images/cross.png"

import "./communications.scss"
import useTestCommunicationValidation from "./useTestCommunicationValidation"
import { Oval } from "react-loader-spinner"
import striptags from "striptags"
import { isValidCommunicationFromField, isValidName } from "../../../utils/validators"
import CopyToClipboardLink from "../../shared/CopyToClipboardLink"
import { UserContext } from "../../../context/UserContext"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { ICampaignUser } from "../../../interfaces/campaignUser"
import { CAMPAIGN_USERS_API_PATH, CAMPAIGN_USERS_PATHS } from "../../../common/CampaignUserApiPaths"

interface ISMSState {
	phoneNumber: string
	countryPhoneCode: string
	firstName: string
	surname: string
}

interface IEmailState {
	to: string
	firstName: string
	surname: string
}

interface ISMSFormSchema {
	from: string
	message: string
}

const smsFormSchema = z.object({
	from: z
		.string()
		.min(4, "Please enter a valid 'From' field.")
		.max(11, "Please enter a valid 'From' field.")
		.refine(value => {
			const alphanumericRegex = /^[a-zA-Z0-9]+$/
			const phoneNumberRegex = /^[^+]\d+$/
			const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/

			if (emojiRegex.test(value)) {
				return false
			}

			if (/[a-zA-Z]/.test(value)) {
				if (alphanumericRegex.test(value)) {
					return true
				}
			}

			if (phoneNumberRegex.test(value)) {
				return false
			}
		}, "Please enter a valid 'From' field."),
	message: z.string().min(1, "Please enter a valid message in order to dispatch an SMS"),
})

interface IEmailFormSchema {
	from: string
}

const emailFormSchema = z.object({
	from: z.string().refine(value => {
		const alphanumericRegex = /^[a-zA-Z0-9]+$/
		const phoneNumberRegex = /^[^+]\d+$/
		const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/

		if (emojiRegex.test(value)) {
			return false
		}

		if (/[a-zA-Z]/.test(value)) {
			if (alphanumericRegex.test(value)) {
				return true
			}
		}

		if (phoneNumberRegex.test(value)) {
			return false
		}
	}, "Please enter a valid 'From' field."),
})

const Communications = () => {
	// custom hooks:
	// validation
	const smsFormState = useForm<ISMSFormSchema>({
		defaultValues: {
			from: "",
			message: "",
		},
		resolver: zodResolver(smsFormSchema),
		mode: "onChange",
	})

	const emailFormState = useForm<IEmailFormSchema>({
		defaultValues: {
			from: "",
		},
		resolver: zodResolver(emailFormSchema),
		mode: "onChange",
	})

	const smsFrom = smsFormState.watch("from")
	const smsMessage = smsFormState.watch("message")

	const onSMSFormSubmit = (isCalledFrom: string) => {
		if (isCalledFrom === "test") {
			smsFormState.handleSubmit(({ from, message }) => {
				const isFromValid = isValidCommunicationFromField(from)
				const isMessageValid = message.length > 0
				setMessageModalState({
					...messageModalState,
					messageType: "Message",
					visible: isFromValid && isMessageValid ? true : false,
				})
			})()
		}

		if (isCalledFrom === "dispatch") {
			smsFormState.handleSubmit(({ from, message }) => {
				let selectedUsersCount = 0
				switch (selectedRecipientsCategory) {
					case RecipientCategories.ALLUSERS:
						selectedUsersCount = fetchedRecipients.allUsers.length
						break
					case RecipientCategories.CURRENTWINNERS:
						selectedUsersCount = fetchedRecipients.campaignWinners.length
						break
					case RecipientCategories.CURRENTOUTBIDUSERS:
						selectedUsersCount = fetchedRecipients.usersWithLosingBids.length
						break
					default:
						break
				}

				const isMessageValid = message.length > 0
				const isFromValid = isValidCommunicationFromField(from)
				const isRecipientsValid = getValidation("recipients-category").isValid

				if (isFromValid && isMessageValid && isRecipientsValid && selectedUsersCount > 0) {
					setConfirmationDialogProperties({
						isOpen: true,
						type: "AREYOUSURE",
						title: `Confirm Sending ${selectedMessageService}`,
						message: `Are you sure you want to send the message to ${selectedUsersCount} users?`,
					})
				}
			})()
		}
	}

	const emailFrom = emailFormState.watch("from")

	const onEmailFormSubmit = (isCalledFrom: string) => {
		if (isCalledFrom === "test") {
			emailFormState.handleSubmit(({ from }) => {
				const isFromValid = from.length
				const isMessageValid = striptags(messageModalState.emailData.message).length > 0
				setMessageModalState({
					...messageModalState,
					messageType: "Email",
					visible: isFromValid && isMessageValid ? true : false,
				})
			})()
		}

		if (isCalledFrom === "dispatch") {
			emailFormState.handleSubmit(({ from }) => {
				let selectedUsersCount = 0
				switch (selectedRecipientsCategory) {
					case RecipientCategories.ALLUSERS:
						selectedUsersCount = fetchedRecipients.allUsers.length
						break
					case RecipientCategories.CURRENTWINNERS:
						selectedUsersCount = fetchedRecipients.campaignWinners.length
						break
					case RecipientCategories.CURRENTOUTBIDUSERS:
						selectedUsersCount = fetchedRecipients.usersWithLosingBids.length
						break
					default:
						break
				}

				const isFromValid = from.length
				const isMessageValid = striptags(messageModalState.emailData.message).length > 0
				const isRecipientsValid = getValidation("recipients-category").isValid

				if (isFromValid && isMessageValid && isRecipientsValid && selectedUsersCount > 0) {
					setConfirmationDialogProperties({
						isOpen: true,
						type: "AREYOUSURE",
						title: `Confirm Sending ${selectedMessageService}`,
						message: `Are you sure you want to send the message to ${selectedUsersCount} users?`,
					})
				}
			})()
		}
	}

	// common functions:
	const commonFunctions = useCommonFunctions()
	// fetch:
	// users:
	const usersApi = useFetch(API_PATHS.USERS)
	const campaignUsersApi = useFetch(CAMPAIGN_USERS_API_PATH)
	
	// bids:
	const bidsApi = useFetch(API_PATHS.BIDS)
	// communications:
	const communicationsApi = useFetch(API_PATHS.COMMUNICATIONS)

	// react hooks:
	// context:
	const organisation = useContext(OrganisationContext)
	const campaign = useContext(CampaignContext)
	const currentUser = useContext(UserContext)

	// states:
	// tab:
	const [tabValue, setTabValue] = useState<number>(0)

	// test button:
	const [isTestServiceSelected, setIsTestServiceSelected] = useState(false)

	// send button:
	const [isDispatchServiceSeletected, setIsDispatchServiceSeletected] = useState(false)

	// recipients:
	const [fetchedRecipients, setFetchedRecipients] = useState<IRecipientsList>({
		allUsers: [],
		allUsersLoading: false,
		allUsersErrorMessage: "",
		campaignWinners: [],
		campaignWinnersLoading: false,
		campaignWinnersErrorMessage: "",
		usersWithLosingBids: [],
		usersWithLosingBidsLoading: false,
		usersWithLosingBidsErrorMessage: "",
	})

	const [selectedRecipientsCategory, setSelectedRecipientCategory] = useState<string>("")

	// communications state:
	const [selectedMessageService, setSelectedMessageService] = useState<string>("SMS")
	const [messageModalState, setMessageModalState] = useState<ITestMessageModalState>({
		visible: false,
		messageType: "",
		smsData: {
			from: "",
			message: "",
			recipients: [],
			messageType: "",
			campaignName: "",
		},
		emailData: {
			bcc: "",
			from: "",
			message: "",
			subject: "",
			recipients: [],
			messageType: "",
			campaignName: "",
		},
	})
	const [communicationResponse, setCommunicationResponse] = useState({
		loading: false,
		smsResponse: {},
		emailResponse: {},
	})

	// check if dispatch message was called
	const [isDispatchCalled, setIsDispatchCalled] = useState(false)

	// test email
	const [emailValues, setEmailValues] = useState<IEmailState>({
		to: "",
		surname: "",
		firstName: "",
	})
	// test sms
	const [smsValues, setSMSValues] = useState<ISMSState>({
		surname: "",
		firstName: "",
		phoneNumber: "",
		countryPhoneCode: "",
	})

	// confirmation modal state:
	const [confirmationDialogProperties, setConfirmationDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "" })

	// test message:
	const [testMessageResponse, setTestMessageResponse] = useState<ITestMessageResponseState>({
		icon: <></>,
		successMessage: "",
		errorMessage: "",
		loading: false,
	})

	// email react quill message
	const [resetReactQuillMessage, setResetReactQuillMessage] = useState<boolean>(false)

	// custom hook:
	// validation for communication:
	const communicationValidation = useCommunicationValidation(messageModalState, selectedMessageService, selectedRecipientsCategory)

	useEffect(() => {
		if (selectedRecipientsCategory?.length > 0) {
			communicationValidation.getFieldValidation("recipients-category", selectedRecipientsCategory)
		}
	}, [selectedRecipientsCategory])

	// sms form validation effect
	useEffect(() => {
		if (!smsFrom) {
			const clearMessageError = () => {
				smsFormState.clearErrors("from")
			}

			const animationFrameId = requestAnimationFrame(clearMessageError)

			return () => {
				cancelAnimationFrame(animationFrameId)
			}
		}
	}, [smsFormState, smsFrom])

	useEffect(() => {
		if (!smsMessage) {
			const clearMessageError = () => {
				smsFormState.clearErrors("message")
			}

			const animationFrameId = requestAnimationFrame(clearMessageError)

			return () => {
				cancelAnimationFrame(animationFrameId)
			}
		}
	}, [smsFormState, smsMessage])

	useEffect(() => {
		if (smsFormState.formState.isValid) {
			setIsTestServiceSelected(false)
		}
	}, [smsFormState.formState.isValid])

	// useEffect(() => {
	// 	let res = document.getElementsByTagName("footer")[0]
	// 	if (res){
	// 		if (tabValue === 1) {
	// 			res.style["padding-bottom"] = "0px"
	// 		} else {
	// 			res.style["padding-bottom"] = "61px"
	// 		}
	// 	}
	// }, [tabValue])

	// email form validation effect
	useEffect(() => {
		if (!emailFrom) {
			const clearMessageError = () => {
				emailFormState.clearErrors("from")
			}

			const animationFrameId = requestAnimationFrame(clearMessageError)

			return () => {
				cancelAnimationFrame(animationFrameId)
			}
		}
	}, [emailFormState, emailFrom])

	useEffect(() => {
		const isFromValid = emailFormState.formState.isValid
		const isMessageValid = striptags(messageModalState.emailData.message).length > 0
		if (isFromValid && isMessageValid) {
			setIsTestServiceSelected(false)
		}
	}, [emailFormState.formState.isValid, messageModalState.emailData.message])

	// validation for test communication:
	const testCommunicationValidation = useTestCommunicationValidation(
		{
			smsData: {
				...smsValues,
				from: messageModalState.smsData.from,
				message: messageModalState.smsData.message,
			},
			emailData: {
				...emailValues,
				from: messageModalState.emailData.from,
				message: messageModalState.emailData.message,
			},
		},
		selectedMessageService
	)

	// get all campaign users
	const getCampaignTotalUsers = useCallback(async () => {
		if (campaign._id) {
			setFetchedRecipients({ ...fetchedRecipients, allUsersLoading: true })
			try {
				// const allCampaignUsers = await usersApi.get(`${USER_API_PATHS.GET_USERS_BY_CAMPAIGN_ID.replace("{campaignId}", campaign._id)}`)
				
				const allCampaignUsers: ICampaignUser[] = await campaignUsersApi.get(`${CAMPAIGN_USERS_PATHS.GET_CAMPAIGN_USERS_LIST}${getFilteredUserListQueryString(campaign._id)}`)
            

				setFetchedRecipients({
					...fetchedRecipients,
					campaignWinners: [...fetchedRecipients.campaignWinners],
					allUsers: allCampaignUsers.map(campaignUser => {
						return {
							email: campaignUser?.email,
							userId: campaignUser?._id,
							lastName: campaignUser?.surname,
							firstName: campaignUser?.firstName,
							fullName: `${campaignUser.firstName} ${campaignUser.surname}`,
							phone: campaignUser.phone
						}
					}),
					allUsersLoading: false,
				})
			} catch (err: any) {
				setFetchedRecipients({
					...fetchedRecipients,
					allUsersLoading: false,
					allUsersErrorMessage: err.message,
				})
			}
		}
	}, [campaign._id, fetchedRecipients, usersApi])

	// get all campaign winning and losing bid users conditionally for sms
	const getWinningandLosingBiddersOfCampaign = useCallback(
		async (category: string) => {
			if (campaign._id) {
				if (category === "winningBids") {
					setFetchedRecipients({
						...fetchedRecipients,
						campaignWinnersLoading: true,
					})
				} else if (category === "losingBids") {
					setFetchedRecipients({
						...fetchedRecipients,
						usersWithLosingBidsLoading: true,
					})
				} else if (category === "allBids") {
					setFetchedRecipients({ ...fetchedRecipients, allUsersLoading: true })
				}
				try {
					const fetchedUsers = await bidsApi.get(
						`${BID_API_PATHS.GET_WINNING_AND_LOSING_BIDDERS_BY_CAMPAIGNID.replace("{campaignId}", campaign._id).replace(
							"{category}",
							category
						)}`
					)

					if (category === "winningBids") {
						setFetchedRecipients({
							...fetchedRecipients,
							allUsers: [...fetchedRecipients.allUsers],
							campaignWinners: fetchedUsers.map(user => {
								return {
									email: user?.email,
									phone: user?.phone,
									userId: user?.userId,
									lastName: user?.surname,
									firstName: user?.firstName,
									fullName: `${user.firstName} ${user.surname}`,
								}
							}),
							campaignWinnersLoading: false,
						})
					}
					if (category === "losingBids") {
						setFetchedRecipients({
							...fetchedRecipients,
							allUsers: [...fetchedRecipients.allUsers],
							usersWithLosingBids: fetchedUsers.map(user => {
								return {
									email: user?.email,
									phone: user?.phone,
									userId: user?.userId,
									lastName: user?.surname,
									firstName: user?.firstName,
									fullName: `${user.firstName} ${user.surname}`,
								}
							}),
							usersWithLosingBidsLoading: false,
						})
					}
					if (category === "allBids") {
						setFetchedRecipients({
							...fetchedRecipients,
							// allUsers: [...fetchedRecipients.allUsers],
							allUsers: fetchedUsers.map(user => {
								return {
									email: user?.email,
									phone: user?.phone,
									userId: user?.userId,
									lastName: user?.surname,
									firstName: user?.firstName,
									fullName: `${user.firstName} ${user.surname}`,
								}
							}),
							allUsersLoading: false,
						})
					}
				} catch (err: any) {
					if (category === "winningBids") {
						setFetchedRecipients({
							...fetchedRecipients,
							campaignWinnersLoading: false,
							campaignWinnersErrorMessage: err.message,
						})
					}
					if (category === "losingBids") {
						setFetchedRecipients({
							...fetchedRecipients,
							usersWithLosingBidsLoading: false,
							usersWithLosingBidsErrorMessage: err.message,
						})
					}
					if (category === "allBids") {
						setFetchedRecipients({
							...fetchedRecipients,
							allUsersLoading: false,
							allUsersErrorMessage: err.message,
						})
					}
				}
			}
		},
		[bidsApi, campaign._id, fetchedRecipients]
	)

	// conditionally fetch and update recipients list
	const fetchRecipientsCategoryHandler = (selectedRecipientsCategory: string) => {
		switch (selectedRecipientsCategory) {
			// case RecipientCategories.ALLBIDDERS:
			// 	getWinningandLosingBiddersOfCampaign("allBids")
			// 	setSelectedRecipientCategory(selectedRecipientsCategory)
			// 	break
			case RecipientCategories.ALLUSERS:
				getCampaignTotalUsers()
				setSelectedRecipientCategory(selectedRecipientsCategory)
				break
			case RecipientCategories.CURRENTWINNERS:
				getWinningandLosingBiddersOfCampaign("winningBids")
				setSelectedRecipientCategory(selectedRecipientsCategory)
				break
			case RecipientCategories.CURRENTOUTBIDUSERS:
				getWinningandLosingBiddersOfCampaign("losingBids")
				setSelectedRecipientCategory(selectedRecipientsCategory)
				break
			default:
				return null
		}
	}

	// props
	// mui components
	// TODO: add href for toggle and useEffect for updating on default load
	function a11yProps(index: number, tab: string) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
			//   href: `#${tab}`,
		}
	}

	// get validation of inputs
	const getValidation = (name: string): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		communicationValidation.validationFields.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	// get test validation of inputs
	const getTestValidation = (name: string): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		testCommunicationValidation.validationFields.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	// switch tabs for message/history
	const switchTabs = (tabName: string) => {
		switch (tabName) {
			case CommunicationTabs.MESSAGING:
				return (
					<Messaging
						messageLabel="Message Type"
						recipientsLabel="Recipients"
						getValidation={getValidation}
						fetchedRecipients={fetchedRecipients}
						selectedMessageService={selectedMessageService}
						setSelectedMessageService={setSelectedMessageService}
						selectedRecipientsCategory={selectedRecipientsCategory}
						fetchRecipientsCategoryHandler={fetchRecipientsCategoryHandler}
						communicationValidation={communicationValidation}
						testCommunicationValidation={testCommunicationValidation}
					/>
				)
			case CommunicationTabs.HISTORY:
				return <History campaignId={campaign._id} />
			default:
				return null
		}
	}

	// update message state to dispatch on request
	const messageStateUpdateHandler = (name: string, value: string, type: string) => {
		if (isDispatchServiceSeletected) {
			communicationValidation.getFieldValidation(name, value)
		}

		if (isTestServiceSelected) {
			testCommunicationValidation.getFieldValidation(name, value)
		}

		switch (type) {
			case "SMS":
				setMessageModalState({
					...messageModalState,
					smsData: {
						...messageModalState.smsData,
						[name]: value,
					},
				})

				if (isTestServiceSelected) {
					setIsTestServiceSelected(value.length === 0 ? false : true)
				}
				if (isDispatchServiceSeletected) {
					setIsDispatchServiceSeletected(value.length === 0 ? false : true)
				}
				break
			case "Email":
				setMessageModalState({
					...messageModalState,
					emailData: {
						...messageModalState.emailData,
						[name]: value,
					},
				})

				if (name === "message") {
					if (isTestServiceSelected) {
						setIsTestServiceSelected(striptags(value).length === 0 ? false : true)
					}
					if (isDispatchServiceSeletected) {
						setIsDispatchServiceSeletected(striptags(value).length === 0 ? false : true)
					}
				} else {
					if (isTestServiceSelected) {
						setIsTestServiceSelected(value.length === 0 ? false : true)
					}
					if (isDispatchServiceSeletected) {
						setIsDispatchServiceSeletected(value.length === 0 ? false : true)
					}
				}
				break
			default:
				break
		}
	}

	// check for selected recipients list
	const isValidRecipientsList = (selectedRecipients: string) => {
		let hasRecipient = false
		switch (selectedRecipients) {
			case RecipientCategories.ALLUSERS:
				if (fetchedRecipients.allUsers.length > 0) {
					hasRecipient = true
				}
				break
			case RecipientCategories.CURRENTWINNERS:
				if (fetchedRecipients.campaignWinners.length > 0) {
					hasRecipient = true
				}
				break
			case RecipientCategories.CURRENTOUTBIDUSERS:
				if (fetchedRecipients.usersWithLosingBids.length > 0) {
					hasRecipient = true
				}
				break
			default:
				return false
		}
		return hasRecipient
	}

	// handle test message response
	const handleTestMessageResponse = async response => {
		if (response?.message === "Success") {
			setTestMessageResponse({
				...testMessageResponse,
				icon: <img style={{ width: "8%" }} alt="success" src={TickImage} />,
				successMessage: "Successfully sent test message",
				loading: false,
			})
		} else {
			setTestMessageResponse({
				...testMessageResponse,
				icon: <img style={{ width: "8%" }} alt="failed" src={CrossImage} />,
				successMessage:
					response?.message && response?.message?.length ? response?.message : "Failed to send test message, please try again later",
				loading: false,
			})
		}
	}

	// send test message
	const dispatchTestMessageHandler = async (fieldValues, messageType: string) => {
		// build data to dispatch
		let dataToDispatch: any = {}

		const emailData = {
			from: emailFrom,
			bcc: messageModalState.emailData.bcc,
			message: messageModalState.emailData.message,
			subject: messageModalState.emailData.subject,
		}

		const smsData = {
			from: smsFrom,
			message: smsMessage,
		}

		switch (messageType) {
			case "Email":
				dataToDispatch = {
					...emailData,
					...fieldValues,
					campaignName: campaign.name,
					messageType: messageType,
					campaignSlug: campaign.slug,
					url: process.env.REACT_APP_CAMPAIGN_URL,
					sendgridTemplateId: organisation.sendgridTemplateId,
					sendgridVerifiedEmail: organisation.sendgridVerifiedEmail,
				}
				break
			case "Message":
				dataToDispatch = {
					...smsData,
					...fieldValues,
					campaignName: campaign.name,
					messageType: messageType,
					campaignSlug: campaign.slug,
					url: process.env.REACT_APP_CAMPAIGN_URL,
				}
				break
			default:
				break
		}

		// conditionally dispatch an api call
		if (messageType === "Email") {
			const emailDataIsValid =
				isValidName(dataToDispatch.firstName) &&
				isValidName(dataToDispatch.surname) &&
				dataToDispatch.from.length > 0 &&
				striptags(dataToDispatch.message).length > 0 &&
				dataToDispatch.to.length > 0

			if (emailDataIsValid) {
				setTestMessageResponse({
					...testMessageResponse,
					loading: true,
				})
				communicationsApi
					.post(COMMUNICATIONS_API_PATHS.SEND_TEST_EMAIL, dataToDispatch)
					.then(async responseData => await handleTestMessageResponse(responseData))
					.catch(error => {
						setTestMessageResponse({
							...testMessageResponse,
							icon: <img style={{ width: "8%" }} alt="failed" src={CrossImage} />,
							successMessage:
								error?.message && error?.message?.length ? error?.message : "Failed to send test email, please try again later",
							loading: false,
						})
					})
			}
		}
		if (messageType === "Message") {
			const smsDataIsValid =
				isValidName(dataToDispatch.firstName) &&
				isValidName(dataToDispatch.surname) &&
				dataToDispatch.from.length > 0 &&
				dataToDispatch.message.length > 0 &&
				dataToDispatch.phoneNumber.length > 0

			if (smsDataIsValid) {
				setTestMessageResponse({
					...testMessageResponse,
					loading: true,
				})
				communicationsApi
					.post(COMMUNICATIONS_API_PATHS.SEND_TEST_SMS, dataToDispatch)
					.then(async responseData => await handleTestMessageResponse(responseData))
					.catch(error => {
						setTestMessageResponse({
							...testMessageResponse,
							icon: <img style={{ width: "8%" }} alt="failed" src={CrossImage} />,
							successMessage:
								error?.message && error?.message?.length ? error?.message : "Failed to send test message, please try again later",
							loading: false,
						})
					})
			}
		}
		// }
	}

	// handle message response
	const handleMessageResponse = async (response, messageType) => {
		console.log("response", response)
		if (response?.message === "Success") {
			setConfirmationDialogProperties({
				isOpen: true,
				type: "SUCCESS",
				title: `${messageType} sent successfully.`,
				message: `Your ${messageType.toLowerCase()} was sent to ${selectedRecipientsCategory}.`,
			})
		} else if (response?.message === "Partial") {
			setConfirmationDialogProperties({
				isOpen: true,
				type: "PARTIAL",
				title: `${messageType} partially successful.`,
				message: `Your ${messageType.toLowerCase()} was partially sent to ${selectedRecipientsCategory}, with a success rate of ${(
					response.successRate * 100
				).toFixed(2)}%.
        Check history for more details.
        `,
			})
		} else if (response?.message === "Error") {
			if (response?.invalidFields.length > 0) {
				setConfirmationDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: `Failed to send ${messageType}.`,
					message: `Your ${messageType.toLowerCase()} was not delivered to ${selectedRecipientsCategory}. The provided fields were invalid: ${response?.invalidFields
						.map((field: string) => field)
						.join(", ")}`,
				})
			} else {
				setConfirmationDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: `Failed to send ${messageType}.`,
					message: `Your ${messageType.toLowerCase()} was not delivered to ${selectedRecipientsCategory}.`,
				})
			}
		} else {
			setConfirmationDialogProperties({
				isOpen: false,
				type: "",
				title: "",
				message: "",
			})
		}
	}

	// message dispatch confirmation
	const renderConfirmationDialog = () => {
		setIsTestServiceSelected(false)

		setIsDispatchCalled(true)
		setIsDispatchServiceSeletected(true)

		communicationValidation.validateInputs()

		if (selectedMessageService === "SMS") {
			onSMSFormSubmit("dispatch")
		}
		if (selectedMessageService === "Email") {
			onEmailFormSubmit("dispatch")
		}
	}

	// clear form after dispatch
	const resetForm = async (messageType: string) => {
		if (messageType === "Email") {
			setMessageModalState({
				...messageModalState,
				emailData: {
					bcc: "",
					from: "",
					message: "",
					subject: "",
					recipients: [],
					messageType: "",
					campaignName: "",
				},
			})
			emailFormState.reset()
			setSelectedRecipientCategory("")
			setResetReactQuillMessage(true)
			communicationValidation.resetValidationFields()
			testCommunicationValidation.resetValidationFields()
		}

		if (messageType === "SMS") {
			setMessageModalState({
				...messageModalState,
				smsData: {
					from: "",
					message: "",
					recipients: [],
					messageType: "",
					campaignName: "",
				},
			})
			smsFormState.reset()
			setSelectedRecipientCategory("")
			communicationValidation.resetValidationFields()
			testCommunicationValidation.resetValidationFields()
		}
		setIsDispatchCalled(false)
	}

	// send message
	const dispatchMessgeHandler = async (messageType: string) => {
		try {
			setConfirmationDialogProperties({
				...confirmationDialogProperties,
				isOpen: false,
			})
			let selectedRecipientsList: ICommunicationUser[] = []

			// update selected recipients list
			// if (selectedRecipientsCategory === SMSRecipientCategories.ALLBIDDERS) {
			// 	selectedRecipientsList = fetchedRecipients.allUsers
			// }
			if (selectedRecipientsCategory === RecipientCategories.ALLUSERS) {
				selectedRecipientsList = fetchedRecipients.allUsers
			}
			if (selectedRecipientsCategory === RecipientCategories.CURRENTWINNERS) {
				selectedRecipientsList = fetchedRecipients.campaignWinners
			}
			if (selectedRecipientsCategory === RecipientCategories.CURRENTOUTBIDUSERS) {
				selectedRecipientsList = fetchedRecipients.usersWithLosingBids
			}

			// build data to dispatch
			let dataToDispatch = {}
			switch (messageType) {
				case "Email":
					const emailData = {
						...messageModalState.emailData,
						from: emailFormState.getValues("from"),
					}
					dataToDispatch = {
						...emailData,
						recipients: selectedRecipientsList,
						messageType: messageType,
						campaignId: campaign._id,
						campaignName: campaign.name,
						sentDate: new Date(),
						recipientsCategory: selectedRecipientsCategory,
						campaignSlug: campaign.slug,
						url: process.env.REACT_APP_CAMPAIGN_URL,
						currentUserId: currentUser.dBUser._id,
						sendgridTemplateId: organisation.sendgridTemplateId,
						sendgridVerifiedEmail: organisation.sendgridVerifiedEmail,
					}
					break
				case "SMS":
					const smsData = {
						...messageModalState.smsData,
						from: smsFormState.getValues("from"),
						message: smsFormState.getValues("message"),
					}
					dataToDispatch = {
						...smsData,
						bcc: "",
						recipients: selectedRecipientsList,
						messageType: messageType,
						campaignId: campaign._id,
						campaignName: campaign.name,
						sentDate: new Date(),
						recipientsCategory: selectedRecipientsCategory,
						campaignSlug: campaign.slug,
						url: process.env.REACT_APP_CAMPAIGN_URL,
						currentUserId: currentUser.dBUser._id,
					}
					break
				default:
					break
			}

			let responseData: any = null

			const recipientsExist = isValidRecipientsList(selectedRecipientsCategory)

			if (!recipientsExist) {
				setConfirmationDialogProperties({
					isOpen: true,
					type: "ERROR",
					title: `Failed to send ${messageType}.`,
					message: `
        Your ${messageType.toLowerCase()} was not delivered to ${selectedRecipientsCategory}.
        As there were no recipients found for this category`,
				})
				setIsDispatchCalled(false)
				return
			}

			if (recipientsExist) {
				setCommunicationResponse({ ...communicationResponse, loading: true })

				// conditionally dispatch an api call
				if (messageType === "Email") {
					responseData = await communicationsApi.post(COMMUNICATIONS_API_PATHS.SEND_EMAIL, dataToDispatch)
				}
				if (messageType === "SMS") {
					responseData = await communicationsApi.post(COMMUNICATIONS_API_PATHS.SEND_SMS, dataToDispatch)
				}
			}

			// set communication response state conditionally
			if (messageType === "Email") {
				setCommunicationResponse({
					...communicationResponse,
					emailResponse: responseData,
					loading: false,
				})
			}
			if (messageType === "SMS") {
				setCommunicationResponse({
					...communicationResponse,
					smsResponse: responseData,
					loading: false,
				})
			}

			// handle message response
			await handleMessageResponse(responseData, messageType)

			// clear form data and validation
			resetForm(messageType)
		} catch (error: any) {
			if (messageType === "Email") {
				setCommunicationResponse({
					...communicationResponse,
					loading: false,
				})
			}
			if (messageType === "SMS") {
				setCommunicationResponse({
					...communicationResponse,
					loading: false,
				})
			}
			setConfirmationDialogProperties({
				isOpen: true,
				type: "ERROR",
				title: `Failed to send ${messageType}.`,
				message: `Your ${messageType.toLowerCase()} was not delivered to ${selectedRecipientsCategory} due to: '${error.message}'`,
			})
			resetForm(messageType)
		}
	}

	// render message service component
	const renderMessageService = () => {
		switch (selectedMessageService) {
			case "SMS":
				return (
					<SMS
						control={smsFormState.control}
						communicationValidation={communicationValidation}
						messageStateUpdateHandler={messageStateUpdateHandler}
						testCommunicationValidation={testCommunicationValidation}
					/>
				)
			case "Email":
				return (
					<Email
						getValidation={getValidation}
						control={emailFormState.control}
						getTestValidation={getTestValidation}
						emailData={messageModalState.emailData}
						resetReactQuillMessage={resetReactQuillMessage}
						isTestServiceSelected={isTestServiceSelected}
						communicationValidation={communicationValidation}
						setResetReactQuillMessage={setResetReactQuillMessage}
						messageStateUpdateHandler={messageStateUpdateHandler}
						isDispatchServiceSeletected={isDispatchServiceSeletected}
						testCommunicationValidation={testCommunicationValidation}
					/>
				)
			default:
				return null
		}
	}

	// update tab value
	const tabChangeHandler = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue)
	}

	// render message service form
	const renderTestMessageServiceFormHandler = (messageService: string) => {
		communicationValidation.resetValidationFields()
		setIsTestServiceSelected(true)

		setIsDispatchCalled(false)
		setIsDispatchServiceSeletected(false)

		testCommunicationValidation.preValidateInputs()

		if (messageService === "SMS") {
			onSMSFormSubmit("test")
		} else if (messageService === "Email") {
			onEmailFormSubmit("test")
		} else {
			return null
		}
	}

	// confirmation modal close handler
	const confrimationModalCloseHandler = async (type: string) => {
		if ((!isDispatchCalled && type === "close") || type === "close") {
			setConfirmationDialogProperties({
				...confirmationDialogProperties,
				isOpen: false,
			})
		}

		if (isDispatchCalled && type === "submit") {
			await dispatchMessgeHandler(selectedMessageService)
		}
	}

	return (
		<div className="communications">
			<h1>Communications</h1>

			<div className="communications__list">
				<Box sx={{ width: "100%" }}>
					<CustomTabs tabValue={tabValue} route={"communications"} tabChangeHandler={tabChangeHandler}>
						{Object.values(CommunicationTabs)?.map((tab, index) => (
							<Tab key={index} label={tab === "MESSAGING" ? "SMS/EMAIL" : tab} {...a11yProps(index, tab)} />
						))}
					</CustomTabs>
					{/* add tab pannel and handle conditional rendering on top in a function based on tabValue */}

					<Paper
						className="input-wrapper"
						sx={[
							tabValue === 1
								? {
									boxShadow: "none",
									borderRadius: "0px",
									backgroundColor: "transparent",
									padding: "0px",
								}
								: {},
						]}
					>
						{tabValue === 0 && switchTabs(CommunicationTabs.MESSAGING)}
						{tabValue === 1 && switchTabs(CommunicationTabs.HISTORY)}
					</Paper>

					<Paper
						className="input-wrapper"
						sx={[
							tabValue === 1
								? {
									boxShadow: "none",
									borderRadius: "0px",
									backgroundColor: "transparent",
									padding: "0px",
								}
								: {},
						]}
						style={{ marginBottom: 0 }}
					>
						{tabValue === 0 && renderMessageService()}

						<Box sx={[{ display: "grid", justifyItems: "end" }]}>
							{tabValue === 0 && selectedMessageService === "Email" && (
								<>
									{!isDispatchCalled &&
										isTestServiceSelected &&
										(emailFormState.formState.errors.from?.message || !getTestValidation("message").isValid) ? (
										<Box sx={[{ marginTop: "10px", color: "#d32f2f" }]}>
											<Typography variant="caption">Errors highlighted in form - please resolve before you proceed.</Typography>
										</Box>
									) : (
										<></>
									)}
									<Button variant="text" onClick={() => renderTestMessageServiceFormHandler(selectedMessageService)}>
										Send Test Email
									</Button>
								</>
							)}

							{tabValue === 0 && selectedMessageService === "SMS" && (
								<>
									{!isDispatchCalled &&
										isTestServiceSelected &&
										(smsFormState.formState.errors.from?.message || smsFormState.formState.errors.message?.message) ? (
										<Box sx={[{ color: "#d32f2f" }]}>
											<Typography variant="caption">Errors highlighted in form - please resolve before you proceed.</Typography>
										</Box>
									) : null}
									<Button variant="text" onClick={() => renderTestMessageServiceFormHandler(selectedMessageService)}>
										Send Test Message
									</Button>
								</>
							)}
						</Box>
					</Paper>

					{tabValue === 0 && (
						<div className="smart-tags-wrapper">
							<span>
								Smart tags:
								<ul className="no-margin">
									<li>
										Guest first name:&nbsp;
										<span>
											<CopyToClipboardLink textToCopy={"{{firstName}}"} />
										</span>
									</li>
									<li>
										Guest surname:&nbsp;
										<span>
											<CopyToClipboardLink textToCopy={"{{surname}}"} />
										</span>
									</li>
									<li>
										Link to the campaign lots page:&nbsp;
										<span>
											<CopyToClipboardLink textToCopy={"{{lotListLink}}"} />
										</span>
									</li>
								</ul>
							</span>
						</div>
					)}
				</Box>
			</div>
			<TestMessageDialog
				smsValues={smsValues}
				emailValues={emailValues}
				setSMSValues={setSMSValues}
				setEmailValues={setEmailValues}
				getTestValidation={getTestValidation}
				messageModalState={messageModalState}
				testMessageResponse={testMessageResponse}
				setMessageModalState={setMessageModalState}
				setTestMessageResponse={setTestMessageResponse}
				selectedMessageService={selectedMessageService}
				setIsTestServiceSelected={setIsTestServiceSelected}
				dispatchTestMessageHandler={dispatchTestMessageHandler}
				selectedRecipientsCategory={selectedRecipientsCategory}
				testCommunicationValidation={testCommunicationValidation}
			/>
			<ConfirmationDialog
				handleClose={() => confrimationModalCloseHandler("close")}
				isDialogOpen={confirmationDialogProperties.isOpen}
				message={confirmationDialogProperties.message}
				title={confirmationDialogProperties.title}
				type={confirmationDialogProperties.type}
				label={confirmationDialogProperties.label}
				primaryButton={
					<Button
						onClick={() => confrimationModalCloseHandler(isDispatchCalled ? "submit" : "close")}
						className="gg-button"
						style={commonFunctions.determineButtonColor(confirmationDialogProperties.type)}
					>
						Ok
					</Button>
				}
			/>
			{tabValue === 0 && (
				<div className="fixed-action-section">
					<div></div>
					<Button onClick={() => renderConfirmationDialog()} className="gg-button">
						{communicationResponse.loading === true ? (
							<Oval ariaLabel="loading-indicator" height={20} width={20} strokeWidth={5} color="white" secondaryColor="white" />
						) : (
							<>
								<span>Send</span>
							</>
						)}
					</Button>
				</div>
			)}
		</div>
	)
}

export default Communications
