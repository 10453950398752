import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AdminMenuBar from "../components/admin/shared/AdminMenuBar"
import AdminFooter from "../components/admin/shared/AdminFooter"
import { Drawer, List, MenuItem, ListItemIcon, ListItemText, IconButton, AppBar, Toolbar } from "@mui/material/"
import MenuIcon from "@mui/icons-material/Menu"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import GavelIcon from "@mui/icons-material/Gavel"
import RedeemIcon from "@mui/icons-material/Redeem"
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import PeopleIcon from "@mui/icons-material/People"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import "./layoutAuthenticated.scss"
import { useParams } from "react-router-dom"
import ListAltIcon from "@mui/icons-material/ListAlt"
import ICampaign from "../interfaces/campaign"
import PresentToAllIcon from "@mui/icons-material/PresentToAll"
import { CampaignContext, CampaignProvider } from "../context/CampaignContext"
import { OrganisationProvider } from "../context/OrganisationContext"
import { RouteComponentProps } from "react-router"
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined"
import RoleProtectedRoute from "../auth/RoleProtectedRoute"
import useFetch from "../hooks/useFetch"
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignQueryString, getFilteredLotsQueryString, LOT_API_PATHS } from "../common/ApiPaths"
import ILot from "../interfaces/lot"
import { LotType } from "../common/enums/LotType"
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import BallotIcon from "@mui/icons-material/Ballot";
import GGLogo from "../images/GG Logo New - White.svg"
import { UserContext } from "../context/UserContext"
import { UserRole } from "../common/enums/UserRole"
import MenuBookIcon from '@mui/icons-material/MenuBook';
import moment from "moment"

interface InputProps {
	children?: any;
	title?: string;
	paddingForMenuLayoutRoutes: number
}

const LayoutAuthenticated: React.FunctionComponent<InputProps & RouteComponentProps<any>> = props => {
	const { campaignSlug, organisationSlug } = useParams()
	const [mobileOpen, setMobileOpen] = useState(false)
	const [showWinnerReport, setShowWinnerReport] = useState<boolean>(false)
	const [showPurchaseReport, setShowPurchaseReport] = useState<boolean | undefined>(undefined)

	const lotApi = useFetch(API_PATHS.LOTS)
	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	const campaign: ICampaign = useContext(CampaignContext)
	const { dBUser, auth0User, logout } = useContext(UserContext)


	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		getLots()
	}, [campaign])

	useEffect(() => {
	}, [props.paddingForMenuLayoutRoutes])

	const getLots = async (): Promise<void> => {
		try {
			let campaignData: ICampaign = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(campaignSlug)}`)
			let lots: ILot[] = await lotApi.get(`${LOT_API_PATHS.GET_LOTS.replace('{campaignId}', campaignData._id)}${getFilteredLotsQueryString(undefined, true)}`)
			setShowWinnerReport(lots.filter(x => x.type !== LotType.BUYITNOW).length > 0)
			setShowPurchaseReport(lots.filter(x => x.type === LotType.BUYITNOW).length > 0)
		}
		catch (err: any) {
			console.log(err.message)
		}
	}

	useEffect(() => {
		getLots()
	}, [campaign, props.children])


	const drawer = (
		<div className="drawer-container">
			<AppBar position="fixed" className="menu-bar">
				<Toolbar>
					<Link
						className="clickable-icon"
						to={dBUser && dBUser.role === UserRole.SUPER_ADMIN ? "/organisations" : `/organisations/${organisationSlug}`}
					>
						<img className="logo" alt="logo" src={GGLogo} />
					</Link>
				</Toolbar>
			</AppBar>

			{showPurchaseReport !== undefined && (
				<div className="list-container">
					<List>
						<MenuItem component={Link} to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}`}>
							<ListItemIcon>
								<GavelIcon />
							</ListItemIcon>
							<ListItemText primary={`Campaign Settings`} />
						</MenuItem>
						<MenuItem component={Link} to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/lots`}>
							<ListItemIcon>
								<RedeemIcon />
							</ListItemIcon>
							<ListItemText primary="Lot Settings" />
						</MenuItem>
						<MenuItem component={Link} to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/custom-pages`}>
							<ListItemIcon>
								<MenuBookIcon />
							</ListItemIcon>
							<ListItemText primary="Custom Pages" />
						</MenuItem>
						<MenuItem component={Link} to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/bid-report`}>
							<ListItemIcon>
								<ListAltIcon />
							</ListItemIcon>
							<ListItemText primary="Bid Report" />
						</MenuItem>
						<MenuItem component={Link} to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/lot-report`}>
							<ListItemIcon>
								<FormatListBulletedIcon />
							</ListItemIcon>
							<ListItemText primary="Lot Report" />
						</MenuItem>
						{showWinnerReport && (
							<MenuItem
								component={Link}
								to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/winner-report`}
							>
								<ListItemIcon>
									<EmojiPeopleIcon />
								</ListItemIcon>
								<ListItemText primary="Winner Report" />
							</MenuItem>
						)}
						{showPurchaseReport && (
							<MenuItem
								component={Link}
								to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/purchase-report`}
							>
								<ListItemIcon>
									<CurrencyPoundIcon />
								</ListItemIcon>
								<ListItemText primary="Purchaser Report" />
							</MenuItem>
						)}
						{campaign.paymentMethod === "PAYMENT-LINK" ? (
							<MenuItem
								component={Link}
								to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/bidder-report`}
							>
								<ListItemIcon>
									<PeopleIcon />
								</ListItemIcon>
								<ListItemText primary="Bidder Report" />
							</MenuItem>
						) : (
							<MenuItem
								component={Link}
								to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/user-report`}

							>
								<ListItemIcon>
									<PeopleIcon />
								</ListItemIcon>
								<ListItemText primary="User Report" />
							</MenuItem>
						)}
						<MenuItem component={Link} to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/big-screen/settings`}>
							<ListItemIcon>
								<PresentToAllIcon />
							</ListItemIcon>
							<ListItemText primary="Big Screen" />
						</MenuItem>


						<MenuItem
							component={Link}
							to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/communications`}
							className="hide-on-mobile"
						>
							<ListItemIcon>
								<MarkUnreadChatAltOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Communications" />
						</MenuItem>
						<MenuItem
							component={Link}
							to={`/organisations/${organisationSlug}/campaigns/${campaignSlug}/audit-log`}
						>
							<ListItemIcon>
								<BallotIcon />
							</ListItemIcon>
							<ListItemText primary="Audit Log" />
						</MenuItem>
					</List>
					<div className="bottom-section" >

						<List>
							<MenuItem href={`${process.env.REACT_APP_CAMPAIGN_URL}${campaignSlug}`} target="_blank" component="a">
								<ListItemIcon>
									<OpenInNewIcon />
								</ListItemIcon>
								<ListItemText primary={`View Campaign`} />
							</MenuItem>
						</List>
						<div style={{ 
							display: "flex", flexDirection: "column", fontSize: "0.8rem", gap: 6, marginBottom: 6,
							 marginTop: 6, borderTopStyle: "solid", borderWidth: 1, borderBlockColor: "lightgray", alignItems: "center", padding: 6}}>
						<a href={`/organisations/${organisationSlug}/privacyPolicy`} rel="noreferrer" className="hyperlink">Privacy Policy</a>
						<span>© {moment().format('YYYY')} Going Gone</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);

	return (
		<div className="layout-authenticated">
			<CampaignProvider>
				{/* <UserProvider> */}
				<OrganisationProvider>
					<RoleProtectedRoute>
						<AdminMenuBar />
						<div className="root">
							<Drawer
								variant="temporary"
								className="mobile-drawer"
								open={mobileOpen}
								onClose={handleDrawerToggle}
								classes={{
									paper: "drawer-paper",
								}}
								ModalProps={{
									keepMounted: true, // Better open performance on mobile.
								}}
							>
								{drawer}
							</Drawer>
							<Drawer
								classes={{
									paper: "drawer-paper",
								}}
								variant="permanent"
								className="desktop-drawer"
								open
							>
								{drawer}
							</Drawer>
							<main>
								<div className="header-bar">
									<IconButton
										color="inherit"
										aria-label="open drawer"
										edge="start"
										onClick={handleDrawerToggle}
									>
										<MenuIcon />
									</IconButton>
									<span>{props.title}</span>
								</div>
								<div
									className="full-height-content"
								>
									{props.children}
								</div>
								{/* <footer style={{ paddingBottom: footerPadding }}>
									<AdminFooter />
								</footer> */}
							</main>
						</div>
					</RoleProtectedRoute>
				</OrganisationProvider>
			</CampaignProvider>
		</div>
	);
};

export default LayoutAuthenticated;
