import React, { useState } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material"
import useSocialLinkValidation from "./useSocialLinkValidation";
import '../../dialog.scss'
import ICampaign from "../../../../interfaces/campaign";
import ISocialLink from "../../../../interfaces/socialLink";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    setCampaign: (campaign: ICampaign) => void,
    socialLink: ISocialLink,
    index: number,
    campaign: ICampaign
}

const SocialLinkDialog = (props: InputProps) => {

    const [socialLink, setSocialLink] = useState<ISocialLink>({ ...props.socialLink });

    const socialLinkValidation = useSocialLinkValidation(socialLink)

    const handleChange = (event) => {
        const { name, value } = event.target
        setSocialLink({ ...socialLink, [name]: value })
    }

    const saveSocialLink = () => {

        if (socialLinkValidation.validateInputs()) {
            let tempSocialLinkObject: ISocialLink[] = [...props.campaign.socialLinkList]

            if (props.index > -1) {
                tempSocialLinkObject[props.index] = socialLink
                props.setCampaign({ ...props.campaign, socialLinkList: tempSocialLinkObject })
                setSocialLink({} as ISocialLink)
                props.handleClose()

            } else {
                tempSocialLinkObject.push(socialLink)
                props.setCampaign({ ...props.campaign, socialLinkList: tempSocialLinkObject })
                setSocialLink({} as ISocialLink)
                props.handleClose()
            }
        }
    }

    React.useEffect(() => {
        if (props.index > -1) {
            setSocialLink({ ...props.socialLink })
        } else {
            setSocialLink({
                platform: "",
                url: ""
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.socialLink]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Social Link</DialogTitle>
                <DialogContent>

                    <FormControl variant="outlined" fullWidth required
                        error={!socialLinkValidation.getValidation("platform").isValid}
                    >
                        <InputLabel id="platform-label">Platform</InputLabel>
                        <Select
                            labelId="platform-label"
                            id="platform"
                            name="platform"
                            value={socialLink.platform}
                            onChange={handleChange}
                            label="Platform"

                        >
                            <MenuItem value={'facebook'}>Facebook</MenuItem>
                            <MenuItem value={'twitter'}>Twitter</MenuItem>
                            <MenuItem value={'youtube'}>Youtube</MenuItem>
                            <MenuItem value={'instagram'}>Instagram</MenuItem>
                            <MenuItem value={'linkedIn'}>LinkedIn</MenuItem>

                        </Select>
                        {!socialLinkValidation.getValidation("platform").isValid && <FormHelperText>{socialLinkValidation.getValidation("platform").validationMessage} </FormHelperText>}
                    </FormControl>
                    <TextField
                        variant="outlined"
                        id="url"
                        name="url"
                        label="URL"
                        type="text"
                        fullWidth
                        value={socialLink.url}
                        onChange={handleChange}
                        required
                        helperText={!socialLinkValidation.getValidation("url").isValid && socialLinkValidation.getValidation("url").validationMessage}
                        error={!socialLinkValidation.getValidation("url").isValid}
                    />
                </DialogContent>
                <DialogActions>
                    <div className="action-section">
                        <div className="save-button-wrapper">
                            {!socialLinkValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                            <div className="buttons">
                                <Button id="cancel-button" onClick={props.handleClose} >
                                    Cancel
                                </Button>
                                <Button id="save-button" onClick={saveSocialLink} className="gg-button">
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SocialLinkDialog