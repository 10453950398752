import { Card } from "@mui/material"
import React, { useEffect, useState } from "react"
import "./loginSignup.scss"
import LoginSignupTabs from "./LoginSignupTabs"
import GGLogo from "../../images/GG Logo New - White.svg"
import GGWatermark from "../../images/logo-only-white.svg"

const LoginSignup = () => {
	const [isForgotPasswordView, setIsForgotPasswordView] = useState<boolean>(false)

	useEffect(() => {
		localStorage.setItem('redirectUri', '')
	}, [])

	return (
		<div>
			<div className="login flex-container">
				<img className="watermark" alt="logo" src={GGWatermark} />
				<div className="header-wrapper">
					<img alt="logo" src={GGLogo} />
				</div>
				<Card className="login-card">
					<LoginSignupTabs
						isForgotPasswordView={isForgotPasswordView}
						setIsForgotPasswordView={setIsForgotPasswordView}
						isAdminView={true}
						handleClose={() => localStorage.setItem('redirectUri', '')}
					/>
				</Card>
				<span className="small-text white">
					Please contact the site administrator or email <a href="mailto:hello@goinggone.io">hello@goinggone.io</a> if you require access to
					the portal.
				</span>
			</div>
		</div>
	)
}

export default LoginSignup
