import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { auth } from "../auth/auth0.service"
import { API_PATHS, USER_API_PATHS, getUserQueryString } from "../common/ApiPaths";
import { IUser } from "../interfaces/user";
import { IUserContext } from "../interfaces/userContext";
import { Auth0UserProfile, Auth0Error } from 'auth0-js'

const UserContext = createContext({} as IUserContext);
const UserDispatchContext = createContext({} as Dispatch<SetStateAction<IUser>>);


const UserProvider = ({ children }) => {
    const [dBuser, setDbUser] = useState<IUser>({} as IUser)
    const [auth0User, setAuth0User] = useState<any>({} as any)
    const [userLoading, setUserLoading] = useState<boolean>(true)

    const usersApi = useFetch(API_PATHS.USERS);
    const campaignSlug = localStorage.getItem('redirectUri')?.split('/')[1] || null


    const getUser = async (auth0Id: string) => {
        try {
            const data: IUser = await usersApi.get(`${USER_API_PATHS.GET_USER}${getUserQueryString(undefined, auth0Id)}`)
            setDbUser(data)
            setUserLoading(false)
            if (!window.location.href.includes('/') && campaignSlug !== null) {
                updateUserWithCampaignLogin()
            }
        }
        catch (err: any) {
            console.error(err)
            setUserLoading(false)
        }
    }

    const updateUserWithCampaignLogin = async () => {
        try {
            await usersApi.post(`${USER_API_PATHS.UPDATE_USER_WITH_LATEST_LOGIN.replace('{auth0Id}', auth0User.sub)}`, { campaignSlug: campaignSlug })
        }
        catch (err: any) {
            console.error(err)
        }
    }

    const isBigScreen = (): boolean => {
        return (window.location.href.includes('/big-screen') && !window.location.href.includes('/settings'))
    }

    const login = (currentUser) => {
        setAuth0User(currentUser)
    }

    const logout = () => {
        setAuth0User({})
        localStorage.setItem('accessToken', '')
        setDbUser({} as IUser)
    }

    useEffect(() => {
        // console.log("auth0User", auth0User)
        if (auth0User && auth0User?.sub) {
            getUser(auth0User?.sub)
        } else {

            if (window.location.pathname !== '/authenticate') {
                let accessToken = localStorage.getItem('accessToken')
                // console.log("accessToken", accessToken)
                if (accessToken && accessToken.length) {
                    auth.client.userInfo(accessToken, (error: Auth0Error | null, result: Auth0UserProfile) => {
                        if (error) {
                            // console.log(error)
                            if (window.location.href.includes('/') && window.location.search !== '?unauthorised' && !(['/'].includes(window.location.pathname))) {
                                window.location.href = `/?unauthorised`
                            }
                            logout()
                        } else {
                            login(result)
                        }
                    })
                } else {
                    if (window.location.search !== '?unauthorised' && !isBigScreen() && window.location.href !== process.env.REACT_APP_PUBLIC_URL) {
                        window.location.href = `/?unauthorised`
                    }
                }
            }
        }
    }, [auth0User])


    return (
        <UserContext.Provider value={{
            dBUser: dBuser,
            auth0User: auth0User,
            userLoading: userLoading,
            login: login,
            logout: logout
        }}>
            <UserDispatchContext.Provider value={setDbUser}>
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    );
}

export { UserProvider, UserContext, UserDispatchContext };