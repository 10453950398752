import { Button, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material"
import moment from "moment"
import React, { useContext, useMemo, useState } from "react"
import IOrganisation from "../../../interfaces/organisation"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import RevokeLicenceBundleDialog from "./RevokeLicenceBundleDialog"
import ICampaign from "../../../interfaces/campaign"
import LicencePurchaseDialog from "../../shared/LicencePurchaseDialog"
import { LicenceStatus } from "../../../common/enums/LicenceStatus"
import ILicenceBundle from "../../../interfaces/licenceBundle"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, ORGANISATIONS_API_PATHS } from "../../../common/ApiPaths"
import { OrganisationContext, OrganisationDispatchContext } from "../../../context/OrganisationContext"
import { UserContext } from "../../../context/UserContext"
import { UserRole } from "../../../common/enums/UserRole"
import './licenceBundleSection.scss'

interface InputProps {
	campaignList: ICampaign[]
	setDialogProperties: (data: any) => void
}

interface RevokeDialogProperties {
	isOpen: boolean
	licenceBundle?: ILicenceBundle
}

const LicenceBundleSection = (props: InputProps) => {
	const [revokeDialogProperties, setRevokeDialogProperties] = useState<RevokeDialogProperties>({ isOpen: false })
	const [isLicencePurchaseDialogOpen, setIsLicencePurchaseDialogOpen] = useState<boolean>(false)
	const [paginationPage, setPaginationPage] = useState<number>(0)
	const startIndex = useMemo(() => paginationPage * 5, [paginationPage])
	const endIndex = useMemo(() => startIndex + 5, [startIndex])

	const organisation: IOrganisation = useContext(OrganisationContext)
	const setOrganisation = useContext(OrganisationDispatchContext)
	const { dBUser } = useContext(UserContext)

	const openRevokeDialog = licenceBundle => {
		setRevokeDialogProperties({ isOpen: true, licenceBundle: licenceBundle })
	}

	const closeRevokeDialog = () => {
		setRevokeDialogProperties({ isOpen: false })
	}

	const organisationsApi = useFetch(API_PATHS.ORGANISATIONS)

	const revokeLicenceBundle = async () => {
		try {
			let updatedOrganisation = await organisationsApi.del(ORGANISATIONS_API_PATHS.DELETE_LICENCE_BUNDLE
				.replace("{organisationId}", organisation._id)
				.replace("{licenceBundleId}", revokeDialogProperties.licenceBundle!.id), { userId: dBUser._id })
			setOrganisation(updatedOrganisation)
			setRevokeDialogProperties({ isOpen: false })
			props.setDialogProperties({
				isOpen: true,
				type: "SUCCESS",
				title: "Licence Bundle Revoked.",
				message: "The licence bundle has been revoked successfully.",
			})
		} catch (err: any) {
			props.setDialogProperties({
				isOpen: true,
				type: "ERROR",
				title: "Licence Bundle Revoke Failed",
				message: `There was a problem revoking the licence bundle.`,
				errorDetails: err.message,
			})
		}
	}

	const getSortedLicenceList = (): ILicenceBundle[] => {
		let licenceList =
			organisation && organisation.licenceBundleList
				? organisation.licenceBundleList.filter(o => o.status === LicenceStatus.SUCCEEDED && o.isActive)
				: []
		licenceList = licenceList.sort(function (d1, d2) {
			return moment(d1.purchaseDate).isAfter(moment(d2.purchaseDate)) ? -1 : moment(d2.purchaseDate).isAfter(moment(d1.purchaseDate)) ? 1 : 0
		})
		return licenceList
	}

	const filteredLicenceBundleList = useMemo(() => getSortedLicenceList(), [organisation])

	return (
		<section className="licence-bundle-section">
			<h2>Licence Bundles</h2>
			<Paper className="input-wrapper">
				{filteredLicenceBundleList.length > 0 ? (
					<>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<div className="table-header-cell">
											<span>Purchase Date</span>
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile">
										<div className="table-header-cell center">
											<span>Bundle Price</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center">
											<span>Licences Bought</span>
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile">
										<div className="table-header-cell center">
											<span>Discount</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center">
											<span>Total Cost</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center hide-on-mobile">
											<span>Receipt</span>
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile">
										<div className="table-header-cell"></div>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredLicenceBundleList.slice(startIndex, endIndex).map((licenceBundle, index) => {
									return (
										<TableRow key={licenceBundle.id}>
											<TableCell>{moment(licenceBundle.purchaseDate).format("DD/MM/YYYY")}</TableCell>
											<TableCell className="hide-on-mobile">
												<div className="center">
													{new Intl.NumberFormat("en-US", {
														style: "currency",
														currency: licenceBundle.currency,
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}).format(licenceBundle.bundlePrice)}
												</div>
											</TableCell>
											<TableCell>
												<div className="center">{licenceBundle.licenceCount}</div>
											</TableCell>
											<TableCell className="hide-on-mobile">
												<div className="center">
													{licenceBundle.discountPercentage ? `${licenceBundle.discountPercentage}%` : `0`}
												</div>
											</TableCell>
											<TableCell>
												<div className="center">
													{new Intl.NumberFormat("en-US", {
														style: "currency",
														currency: licenceBundle.currency,
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}).format(licenceBundle.bundlePrice * (1 - licenceBundle.discountPercentage / 100))}
												</div>
											</TableCell>
											<TableCell className="hide-on-mobile">
												<div className="center">
													{licenceBundle.receiptUrl ? (
														<a target="_blank" className='hyperlink' href={licenceBundle.receiptUrl} rel="noreferrer">View Receipt</a>
													) : '-'}
												</div>
											</TableCell>
											{dBUser.role === UserRole.SUPER_ADMIN && (
												<TableCell className="hide-on-mobile">
													<div className="action-cell">
														<div onClick={() => openRevokeDialog(licenceBundle)}>
															<Tooltip title="Revoke Bundle" enterDelay={500}>
																<HighlightOffIcon />
															</Tooltip>
														</div>
													</div>
												</TableCell>
											)}
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
						<TablePagination
							component="div"
							count={filteredLicenceBundleList.length}
							rowsPerPage={5}
							page={paginationPage}
							onPageChange={(event: unknown, newPage: number) => setPaginationPage(newPage)}
							rowsPerPageOptions={[]}
						/>
					</>
				) : (
					<div className="no-entries">No licence bundles purchased.</div>
				)}
				{dBUser.role !== UserRole.OPERATOR && (
					<div className="button-wrapper">
						<Button className="gg-button" onClick={() => setIsLicencePurchaseDialogOpen(true)}>
							Purchase Licences
						</Button>
					</div>
				)}
			</Paper>

			{dBUser.role !== UserRole.OPERATOR && (
				<>
					<LicencePurchaseDialog
						handleClose={() => setIsLicencePurchaseDialogOpen(false)}
						isDialogOpen={isLicencePurchaseDialogOpen}
						setDialogProperties={props.setDialogProperties}
					/>
					<RevokeLicenceBundleDialog
						handleClose={() => closeRevokeDialog()}
						isDialogOpen={revokeDialogProperties.isOpen}
						licenceBundle={revokeDialogProperties.licenceBundle || ({} as ILicenceBundle)}
						campaignList={props.campaignList}
						revokeLicenceBundle={revokeLicenceBundle}
					/>
				</>
			)}
		</section>
	)
}

export default LicenceBundleSection
