import React, { useCallback, useEffect, useState } from "react";

import ReactQuill from "react-quill";

import { Box, TextField, Typography } from "@mui/material";

import "react-quill/dist/quill.snow.css";

import "./email.scss";
import { isValidCommunicationFromField } from "../../../../../../utils/validators";
import { Controller } from "react-hook-form";

const Email = ({
  control,
  emailData,
  getValidation,
  getTestValidation,
  isTestServiceSelected,
  resetReactQuillMessage,
  communicationValidation,
  setResetReactQuillMessage,
  messageStateUpdateHandler,
  testCommunicationValidation,
  isDispatchServiceSeletected,
}) => {
  // react hooks:
  // states:
  // email settings:
  const [emailSettingState, setEmailSettingState] = useState({
    formValues: {
      from: "",
      message: "",
      subject: "",
    },
    formValidation: {
      isFromValid: false,
      isMessageValid: false,
      isSubjectValid: false,
    },
    formTouched: {
      from: false,
      message: false,
      subject: false,
    },
  });

  useEffect(() => {
    communicationValidation.resetValidationFields();
    testCommunicationValidation.resetValidationFields();
  }, []);

  useEffect(() => {
    if (resetReactQuillMessage) {
      let element = document.getElementsByClassName("ql-editor");
      element[0].innerHTML = "";
      setResetReactQuillMessage(false);
    }
  }, [resetReactQuillMessage]);

  // update email settings handler
  const updateEmailSettingsHandler = (name: string, value: string) => {
    // update from value
    if (name === "from") {
      setEmailSettingState({
        ...emailSettingState,
        formValues: {
          ...emailSettingState.formValues,
          from: value,
        },
        formTouched: {
          ...emailSettingState.formTouched,
          from: false,
        },
      });
    }
    // update message value
    if (name === "message") {
      setEmailSettingState({
        ...emailSettingState,
        formValues: {
          ...emailSettingState.formValues,
          message: value,
        },
        formTouched: {
          ...emailSettingState.formTouched,
          message: false,
        },
      });
    }
    // update subject value
    if (name === "subject") {
      setEmailSettingState({
        ...emailSettingState,
        formValues: {
          ...emailSettingState.formValues,
          subject: value,
        },
        formTouched: {
          ...emailSettingState.formTouched,
          subject: false,
        },
      });
    }

    messageStateUpdateHandler(name, value, "Email");
  };

  // validation on blur handler
  const onBlurHandler = (name, value) => {
    // validate from
    if (name === "from") {
      const isFromValid = isValidCommunicationFromField(value);
      setEmailSettingState({
        ...emailSettingState,
        formValidation: {
          ...emailSettingState.formValidation,
          isFromValid: isFromValid,
        },
        formTouched: {
          ...emailSettingState.formTouched,
          from: true,
        },
      });
    }

    // validate message
    if (name === "message") {
      const isMessageValid = value.length ? true : false;
      setEmailSettingState({
        ...emailSettingState,
        formValidation: {
          ...emailSettingState.formValidation,
          isMessageValid: isMessageValid,
        },
        formTouched: {
          ...emailSettingState.formTouched,
          message: true,
        },
      });
    }

    // validate subject
    if (name === "subject") {
      const isSubjectValid = value.length ? true : false;
      setEmailSettingState({
        ...emailSettingState,
        formValidation: {
          ...emailSettingState.formValidation,
          isMessageValid: isSubjectValid,
        },
        formTouched: {
          ...emailSettingState.formTouched,
          subject: true,
        },
      });
    }
  };

  // validate email message
  const emailValidationRenderer = useCallback(() => {
    if (isDispatchServiceSeletected && !getValidation("message").isValid) {
      return (
        <Typography
          sx={[
            {
              color: "#d32f2f",
              fontWeight: 400,
              fontSize: "0.75rem",
              lineHeight: 1.66,
              letterSpacing: "0.03333em",
              textAlign: "left",
              marginTop: "3px",
              marginRight: "14px",
              marginBottom: 0,
              marginLeft: 2,
            },
          ]}
          variant="caption"
        >
          {isDispatchServiceSeletected &&
            getValidation("message").validationMessage}
        </Typography>
      );
    } else if (isTestServiceSelected && !getTestValidation("message").isValid) {
      return (
        <Typography
          sx={[
            {
              color: "#d32f2f",
              fontWeight: 400,
              fontSize: "0.75rem",
              lineHeight: 1.66,
              letterSpacing: "0.03333em",
              textAlign: "left",
              marginTop: "3px",
              marginRight: "14px",
              marginBottom: 0,
              marginLeft: 2,
            },
          ]}
          variant="caption"
        >
          {isTestServiceSelected &&
            getTestValidation("message").validationMessage}
        </Typography>
      );
    } else if (
      (isTestServiceSelected && !getTestValidation("message").isValid) ||
      (isDispatchServiceSeletected && !getValidation("message").isValid)
    ) {
      return (
        <Typography
          sx={[
            {
              color: "#d32f2f",
              fontWeight: 400,
              fontSize: "0.75rem",
              lineHeight: 1.66,
              letterSpacing: "0.03333em",
              textAlign: "left",
              marginTop: "3px",
              marginRight: "14px",
              marginBottom: 0,
              marginLeft: 2,
            },
          ]}
          variant="caption"
        >
          Please enter a valid message in order to dispatch an email.
        </Typography>
      );
    } else {
      return <></>;
    }
  }, [
    getValidation,
    getTestValidation,
    isTestServiceSelected,
    isDispatchServiceSeletected,
  ]);

  return (
    <Box>
      <Typography sx={[{ fontWeight: "bold" }]} variant="h6" gutterBottom>
        Email Settings
      </Typography>
      <div>
        <TextField
          sx={[
            {
              width: "50%",
            },
          ]}
          name="subject"
          label="Subject"
          inputProps={{
            autoComplete: "off",
          }}
          variant="outlined"
          id="outlined-basic"
          value={emailData.subject || ""}
          onChange={(e) =>
            updateEmailSettingsHandler(e.target.name, e.target.value)
          }
          onBlur={(e) => onBlurHandler(e.target.name, e.target.value)}
        />
      </div>
      <br />
      <div>
        <Controller
          name="from"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              sx={[
                {
                  width: "50%",
                },
              ]}
              name={field.name}
              label="From"
              inputProps={{
                autoComplete: "off",
              }}
              variant="outlined"
              id="outlined-basic"
              value={field.value}
              onChange={field.onChange}
              helperText={fieldState.invalid ? fieldState.error?.message : ""}
              error={fieldState.invalid}
              onBlur={field.onBlur}
              inputRef={field.ref}
            />
          )}
        />
      </div>
      <br />
      <div>
        <TextField
          sx={[
            {
              width: "50%",
            },
          ]}
          name="bcc"
          label="Bcc"
          variant="outlined"
          id="outlined-basic"
          value={emailData.bcc || ""}
          onChange={(e) =>
            updateEmailSettingsHandler(e.target.name, e.target.value)
          }
        />
      </div>
      <br />

      <div className="email-message">
        <ReactQuill
          id="quill-email-message"
          placeholder="Type your message here"
          onChange={(value) => updateEmailSettingsHandler("message", value)}
          onBlur={(value) => onBlurHandler("message", value)}
          modules={{
            toolbar: [
              [{ font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link"],

              ["clean"],
              [{ color: [] }, { background: [] }],
              [{ align: "" }, { align: "center" }, { align: "right" }],
              [{ direction: "rtl" }],
            ],
          }}
          formats={[
            "header",
            "font",
            "size",
            "color",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "align",
            "width",
          ]}
          style={{
            backgroundColor: "#fff",
            color: "#000",
          }}
        />
        {/* message body content validation */}
        {emailValidationRenderer()}
      </div>
    </Box>
  );
};

export default Email;
