import React from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import './backHyperLink.scss'

interface InputProps {
    onClickFunction: (value: any) => void
    text: string
}

const BackHyperlink = (props: InputProps) => {
    return (
        <div className="back-hyperlink-wrapper">
            <span className="hyperlink" onClick={props.onClickFunction}>
                <ArrowBackIcon />
                <span>{props.text}</span>
            </span>
        </div>
    )
}

export default BackHyperlink