import "./homePageSection.scss";
import { InputAdornment, Paper, TextField } from "@mui/material";
import CloudinaryUploadWidget from "../../shared/CloudinaryUploadWidget";
import ICampaign from "../../../../interfaces/campaign";
import Tiptap from "../../shared/tiptap/Tiptap";

interface InputProps {
	campaign: ICampaign;
	setCampaign: (campaign: ICampaign) => void;
}

const HomePageSection = (props: InputProps) => {

	const handleChange = (name, value) => {
		props.setCampaign({ ...props.campaign, [name]: value });
	};

	return (
		<div className="home-page-section">
			<h2 className="subtitle">Home Page Content.</h2>
			<span className="description">
				The content contained within the home page of the campaign.
			</span>
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="title"
						name="title"
						label="Title"
						variant="outlined"
						defaultValue={props.campaign.title || ""}
						onBlur={(event) =>
							handleChange(event.target.name, event.target.value)
						}
						helperText="The title on the homepage"
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="subtitle"
						name="subtitle"
						label="Subtitle"
						variant="outlined"
						defaultValue={props.campaign.subtitle || ""}
						onBlur={(event) =>
							handleChange(event.target.name, event.target.value)
						}
						helperText="The subtitle on the home page."
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="logoUrl"
						name="logoUrl"
						label="Logo URL"
						variant="outlined"
						value={props.campaign.logoUrl || ""}
						onChange={(event) =>
							handleChange(event.target.name, event.target.value)
						}
						helperText={
							"The url for the logo image located in the header section. Either use the icon to upload a new file or provide the url of an existing file hosted online. Suggested resolution 6:4"
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<CloudinaryUploadWidget
										allowMultipleUploads={false}
										folderName={props.campaign.slug}
										functionOnComplete={(uploadedUrlList) => {
											if (uploadedUrlList.length) {
												handleChange(
													"logoUrl",
													uploadedUrlList[uploadedUrlList.length - 1]
												);
											}
										}}
										uploadPreset="unsigned"
									/>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="bannerUrl"
						name="bannerUrl"
						label="Banner URL (mobile devices)"
						variant="outlined"
						value={props.campaign.bannerUrl || ""}
						onChange={(event) =>
							handleChange(event.target.name, event.target.value)
						}
						helperText="The url for the banner image. Best suited for mobile devices, however if no desktop banner is uploaded, this will be used for both. Either use the icon to upload a new file or provide the url of an existing file hosted online. Suggested size: w980px x h240px."
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<CloudinaryUploadWidget
										allowMultipleUploads={false}
										folderName={props.campaign.slug}
										functionOnComplete={(uploadedUrlList) => {
											if (uploadedUrlList.length) {
												handleChange(
													"bannerUrl",
													uploadedUrlList[uploadedUrlList.length - 1]
												);
											}
										}}
										uploadPreset="unsigned"
									/>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="bannerUrlWide"
						name="bannerUrlWide"
						label="Banner URL (desktop devices)"
						variant="outlined"
						value={props.campaign.bannerUrlWide || ""}
						onChange={(event) =>
							handleChange(event.target.name, event.target.value)
						}
						helperText="The url for the banner image. Best suited for latops / desktops, with wider screen sizes. Either use the icon to upload a new file or provide the url of an existing file hosted online. Suggested size: w1600px x h500px."
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<CloudinaryUploadWidget
										allowMultipleUploads={false}
										folderName={props.campaign.slug}
										functionOnComplete={(uploadedUrlList) => {
											if (uploadedUrlList.length) {
												handleChange(
													"bannerUrlWide",
													uploadedUrlList[uploadedUrlList.length - 1]
												);
											}
										}}
										uploadPreset="unsigned"
									/>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="videoUrl"
						name="videoUrl"
						label="Youtube Video Url"
						variant="outlined"
						defaultValue={props.campaign.videoUrl || ""}
						onBlur={(event) =>
							handleChange(event.target.name, event.target.value)
						}
						helperText="The link to a video on Youtube"
					/>
				</div>
			</Paper>
			<Paper className="input-wrapper">

				<div className="tiptap">
					<Tiptap
						value={props.campaign.introText}
						onChange={(value) => {
							props.setCampaign({ ...props.campaign, introText: value, isTipTapIntroText: true });
						}}
						backgroundColor={props.campaign.bodyBackgroundColor}
					/>
					<span>The background color is set by "Home Page Body Background Color" setting in Branding section</span>
				</div>
			</Paper>
		</div>
	);
};

export default HomePageSection;
