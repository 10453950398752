import { useState } from "react";
import IMedia from "../../../../interfaces/media";
import IValidationObject from "../../../../interfaces/validationObject";
import getYouTubeID from 'get-youtube-id';

const useMediaValidation = (media: IMedia) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([
        { name: "src", isValid: true, validationMessage: "" },
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)
    

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "src", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (media.src === "" || typeof (media.src) === "undefined") {
            setFailedValidation("src", "Please enter a value.")
        }

        if (media.mediaType === "YOUTUBE" && !getYouTubeID(media.src)) {
            setFailedValidation("src", "Invalid Youtube URL")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    const generateInitialValidationObject = (): void => {

        let tempValidationObject: IValidationObject[] = [
            { name: "src", isValid: true, validationMessage: "" },
        ]

        setValidationObject(tempValidationObject)
        setIsValidationPassed(true)

    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation,
        generateInitialValidationObject
    }
}

export default useMediaValidation