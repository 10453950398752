import { useState } from "react";
import { IUser } from "../../../interfaces/user";
import IValidationObject from "../../../interfaces/validationObject";


const useStaffValidation = (user: IUser) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const clearValidation = () => {
        setValidationObject([
            { name: "firstName", isValid: true, validationMessage: "" },
            { name: "surname", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "role", isValid: true, validationMessage: "" },
            { name: "password", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
        ])
    }

    const validateInputs = (isCampaignView: boolean=false): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "firstName", isValid: true, validationMessage: "" },
            { name: "surname", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "role", isValid: true, validationMessage: "" },
            { name: "password", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (user.firstName === "" || typeof (user.firstName) === "undefined") {
            setFailedValidation("firstName", "Please enter the first name of the staff member.")
        }

        if (user.surname === "" || typeof (user.surname) === "undefined") {
            setFailedValidation("surname", "Please enter the surname of the staff member.")
        }

        if (user.email === "" || typeof (user.email) === "undefined") {
            setFailedValidation("email", "Please enter the email address of the staff member.")
        } else {
            if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(user.email)) {
            } else {
                setFailedValidation("email", "Invalid email address.")
            }
        }
        if (user.phone === "" || typeof user.phone === "undefined") {
            setFailedValidation("phone", "Please enter a valid phone number.")
        } else {

            let phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

            try {
                let number = phoneUtil.parseAndKeepRawInput(user.phone);
                let code = phoneUtil.getRegionCodeForNumber(number);
                if (!phoneUtil.isValidNumberForRegion(number, code)) {
                    setFailedValidation("phone", "Invalid phone number")
                }
            }
            catch (err) {
                setFailedValidation("phone", "Invalid phone number")
            }
        }

        var ValidatePassword = require('validate-password')
        var validator = new ValidatePassword();
        var passwordData = validator.checkPassword(user.password);
        if (!passwordData.isValid) {
            setFailedValidation("password", passwordData.validationMessage)
        }

        if (!isCampaignView && typeof (user.role) === "undefined") {
            setFailedValidation("role", "Please select a role for the staff member.")
        }

        console.log(tempValidationObject)

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        clearValidation,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default useStaffValidation