import "./auditLog.scss"
import React, { useState, useEffect, useContext, useMemo } from "react"
import useFetch from "../../../hooks/useFetch"
import { Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent, TablePagination } from "@mui/material"
import { Link } from "react-router-dom"
import moment from "moment"
import _ from "lodash"
import { ILogEntry } from "../../../interfaces/logEntry"
import IOrganisation from "../../../interfaces/organisation"
import { API_PATHS, AUDIT_LOG_API_PATHS, getPaginatedAuditLogQueryString } from "../../../common/ApiPaths"
import IAuditLogFilters from "../../../interfaces/auditLogFilters"
import AuditLogFilterSection from "./AuditLogFilterSection"
import ICampaign from "../../../interfaces/campaign"
import { CampaignContext } from "../../../context/CampaignContext"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { useParams } from "react-router-dom"
import ErrorIcon from "@mui/icons-material/Error"
import Loading from "../../shared/Loading"
import { UserContext } from "../../../context/UserContext"
import { UserRole } from "../../../common/enums/UserRole"
import useCommonFunctions from "../../../hooks/useCommonFunctions"

const AuditLog = (props) => {
	const [auditLogList, setAuditLogList] = useState<ILogEntry[]>([])
	const [paginationPage, setPaginationPage] = useState<number>(0)
	const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(25)
	const [totalAuditLogCount, setTotalAuditLogCount] = useState<number>(0)
	const [auditLogFilters, setAuditLogFilters] = useState<IAuditLogFilters>({} as IAuditLogFilters)
	const [loading, setLoading] = useState<boolean>(true)

	const auditLogApi = useFetch(API_PATHS.AUDITLOG)

	const campaign: ICampaign = useContext(CampaignContext)
	const organisation: IOrganisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)
	const commonFunctions = useCommonFunctions()

	let { campaignSlug } = useParams()
	const canGetAuditLog = useMemo(() => {
		if (campaignSlug && organisation && organisation._id) {
			return (campaign && campaign._id && organisation && organisation._id && dBUser && dBUser._id) ? true : false
		}
		else {
			return organisation && organisation._id && dBUser && dBUser._id ? true : false
		}
	}, [campaignSlug, campaign, organisation, dBUser])

	const getAuditLog = async () => {
		setLoading(true)
		let paginatedAuditLogList = await auditLogApi.get(
			`${AUDIT_LOG_API_PATHS.GET_PAGINATED_AUDIT_LOG_LIST}${getPaginatedAuditLogQueryString(
				paginationRowsPerPage,
				paginationPage,
				organisation._id,
				auditLogFilters.category,
				campaignSlug ? campaign._id : auditLogFilters.campaignId,
				auditLogFilters.userId,
				auditLogFilters.fromDate,
				auditLogFilters.toDate,
				dBUser && dBUser.role === UserRole.OPERATOR ? dBUser._id : undefined
			)}`
		)
		setAuditLogList(paginatedAuditLogList.auditLogList)
		setTotalAuditLogCount(paginatedAuditLogList.totalRecords)
		if (auditLogList.length === 0 && props.setPadding) {
			props.setPadding()
		}
		setLoading(false)
	}

	const handlePaginationChangePage = (event: unknown, newPage: number) => {
		setPaginationPage(newPage)
	}

	const handlePaginationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPaginationRowsPerPage(parseInt(event.target.value, 10))
		setPaginationPage(0)
	}

	const checkLocation = () => {
		if (campaignSlug) {
			setAuditLogFilters({ fromDate: null, toDate: null, category: undefined, campaignId: campaign._id, userId: undefined })
		} else {
			setAuditLogFilters({ fromDate: null, toDate: null, category: undefined, campaignId: undefined, userId: undefined })
		}
	}

	useEffect(() => {
		checkLocation()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (canGetAuditLog) {
			getAuditLog()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canGetAuditLog])

	useEffect(() => {
		if (canGetAuditLog) {
			getAuditLog()
		}
		// eslint-disable-next-line
	}, [paginationPage, paginationRowsPerPage])

	useEffect(() => {
		if (canGetAuditLog) {
			getAuditLog()
		}
		// eslint-disable-next-line
	}, [auditLogFilters])

	if (loading) {
		return (
			<Loading />
		)
	}

	return (
		<div className="audit-log">
			{/* <h1>Audit Log.</h1> */}
			<AuditLogFilterSection auditLogFilters={auditLogFilters} setAuditLogFilters={setAuditLogFilters} isCampaign={campaignSlug} />
			<Card>
				<CardContent>
					{auditLogList.length > 0 ? (
						<>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<span>Timestamp</span>
										</TableCell>
										<TableCell>
											<span>User (Email)</span>
										</TableCell>
										<TableCell>
											<span>Campaign</span>
										</TableCell>
										<TableCell>
											<span>Type</span>
										</TableCell>
										<TableCell>
											<span>Action</span>
										</TableCell>
										<TableCell>
											<span>Reference</span>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{auditLogList.map((log: ILogEntry, index) => (
										<TableRow key={index}>
											<TableCell>{moment(log.timestamp).format("HH:mm:ss DD/MM/YYYY")}</TableCell>
											<TableCell>{log.userName && log.userEmail ? `${commonFunctions.titleCase(log.userName)} (${log.userEmail})` : 'Unknown User'}</TableCell>
											<TableCell>
												<Link to={`/organisations/${organisation.slug}/campaigns/${log.campaignSlug}`} className="hyperlink">
													{log.campaignName}
												</Link>
											</TableCell>
											{/* <TableCell>{log.campaignName}</TableCell> */}
											<TableCell>
												<span className="type-wrapper">
													{log.isError && <ErrorIcon className="error-icon" />}
													{log.category}
												</span>
											</TableCell>
											<TableCell>{log.description}</TableCell>
											<TableCell>{log.reference && log.reference.length > 70 ? `${log.reference.substring(0, 70)}...` : log.reference}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							<TablePagination
								rowsPerPageOptions={[10, 25, 50]}
								component="div"
								count={totalAuditLogCount}
								rowsPerPage={paginationRowsPerPage}
								page={paginationPage}
								onPageChange={handlePaginationChangePage}
								onRowsPerPageChange={handlePaginationChangeRowsPerPage}
								labelRowsPerPage="Rows/page"
							/>
						</>
					) : (
						<div className="no-entries">No logs match the applied filters.</div>
					)}
				</CardContent>
			</Card>
		</div>
	)
}

export default AuditLog
