import React, { useState } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import useFAQValidation from "./useFAQValidation";
import '../../dialog.scss'
import ICampaign from "../../../../interfaces/campaign";
import IFaq from "../../../../interfaces/faq";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    setCampaign: (campaign: ICampaign) => void,
    faq: IFaq,
    index: number,
    campaign: ICampaign
}

const FAQDialog = (props: InputProps) => {

    const [faq, setFaq] = useState<IFaq>({ ...props.faq });

    const faqValidation = useFAQValidation(faq)

    const handleChange = (event) => {
        const { name, value } = event.target
        setFaq({ ...faq, [name]: value })
    }

    const saveFaq = () => {
        if (faqValidation.validateInputs()) {
            let tempFaqObject: IFaq[] = [...props.campaign.faqList]

            if (props.index > -1) {
                tempFaqObject[props.index] = faq
                props.setCampaign({ ...props.campaign, faqList: tempFaqObject })
                setFaq({} as IFaq)
                props.handleClose()
            } else {
                tempFaqObject.push(faq)
                props.setCampaign({ ...props.campaign, faqList: tempFaqObject })
                setFaq({} as IFaq)
                props.handleClose()
            }
        }

    }

    React.useEffect(() => {
        if (props.index > -1) {
            setFaq({ ...props.faq })
        } else {
            setFaq({
                question: "",
                answer: ""
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.faq]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Frequently Asked Question</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        id="question"
                        name="question"
                        label="Question"
                        type="text"
                        fullWidth
                        value={faq.question}
                        onChange={handleChange}
                        required
                        multiline
                        // rowsMax={4}
                        rows={4}
                        helperText={!faqValidation.getValidation("question").isValid && faqValidation.getValidation("question").validationMessage}
                        error={!faqValidation.getValidation("question").isValid}
                    />
                    <TextField
                        variant="outlined"
                        id="answer"
                        name="answer"
                        label="Answer"
                        type="text"
                        fullWidth
                        value={faq.answer}
                        onChange={handleChange}
                        required
                        multiline
                        // rowsMax={4}
                        rows={4}
                        helperText={!faqValidation.getValidation("answer").isValid && faqValidation.getValidation("answer").validationMessage}
                        error={!faqValidation.getValidation("answer").isValid}
                    />
                </DialogContent>
                <DialogActions>
                    <div className="action-section">
                        <div className="save-button-wrapper">
                            {!faqValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                            <div className="buttons">
                                <Button id="cancel-button" onClick={props.handleClose}>
                                    Cancel
                                </Button>
                                <Button id="save-button" className="gg-button" onClick={saveFaq}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FAQDialog