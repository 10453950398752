import { Avatar } from "@mui/material"
import React, { useContext } from "react"
import { UserRole } from "../../../common/enums/UserRole"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { UserContext } from "../../../context/UserContext"
import useOrganisationCommonFunctions from "../../../hooks/useOrganisationCommonFunctions"
import ICampaign from "../../../interfaces/campaign"
import IOrganisation from "../../../interfaces/organisation"
import { IUser } from "../../../interfaces/user"

interface InputProps {
	campaignList: ICampaign[]
	setIsLicencePurchaseDialogOpen: (boolean: boolean) => void
	totalEvents: number
}

const OrganisationDetailsSection = (props: InputProps) => {
	let organisationCommonFunctions = useOrganisationCommonFunctions()
	let organisation: IOrganisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)

	return (
		<section className="organisation-details-section">
			<div className="organisation-logo-wrapper">
				{/* <img src={organisation.logoUrl ? organisation.logoUrl : '/placeholder_image.jpg'} alt="logo" /> */}
				<Avatar
					alt="organisation logo"
					src={organisation.logoUrl ? organisation.logoUrl : "/placeholder_image.jpg"}
					sx={{ width: 128, height: 128 }}
				/>
			</div>
			<div className="organisation-name-wrapper">
				<h2>{organisation.name}</h2>
				<h4>
					Active Licences:{" "}
					{props.campaignList && organisation && organisationCommonFunctions.determineActiveLicences(props.totalEvents, organisation)}
				</h4>
				{dBUser.role !== UserRole.OPERATOR && (
					<a className="hyperlink" style={{ fontSize: "0.8rem" }} href="#" onClick={() => props.setIsLicencePurchaseDialogOpen(true)}>
						Purchase licences...
					</a>
				)}
			</div>
		</section>
	)
}

export default OrganisationDetailsSection
