import React from "react"
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'


interface ProgressBarProps {
    progress: number
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 10,
        backgroundColor: '#4793A3' 
    },
}));


const ProgressBar = (props: ProgressBarProps) => {

    return (
        <BorderLinearProgress variant="determinate" value={props.progress} sx={{ height: 20, borderRadius: 10 }} />
    )
}

export default ProgressBar