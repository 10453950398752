import React from 'react'
import IBidWithData from '../../../interfaces/bidWithData'
import './mobileBidCard.scss'
import ICampaign from '../../../interfaces/campaign'
import { BidStatus } from '../../../common/enums/BidStatus'
import DoneIcon from '@mui/icons-material/Done';
import LoopIcon from '@mui/icons-material/Loop';
import CloseIcon from '@mui/icons-material/Close';
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import moment from 'moment'
import DeviceIcon from '../shared/DeviceIcon'
import PersonIcon from '@mui/icons-material/Person'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'

interface InputProps {
    bid: IBidWithData
    campaign: ICampaign
}

const MobileBidCard = (props: InputProps) => {

    const commonFunctions = useCommonFunctions()

    const BidStatusIndicator = () => {
        if (props.bid.status === BidStatus.FAILED) {
            return (
                <div className='bid-status-indicator failed'>
                    <CloseIcon />
                </div>
            )
        } else if (props.bid.status === BidStatus.PENDING) {
            return (
                <div className='bid-status-indicator pending'>
                    <LoopIcon />
                </div>
            )
        } else {
            return (
                <div className='bid-status-indicator succeeded'>
                    <DoneIcon />
                </div>
            )
        }

    }



    return (
        <div className='mobile-bid-card'>
            <div className='value-row'>
                <BidStatusIndicator />
                <div className='value-status-wrapper'>

                    <div className='value' style={{marginBottom: 6}}>
                        {/* {props.bid.count > 1 ?
                            <span>
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: props.campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.bid.value * props.bid.count)}
                                &nbsp;({new Intl.NumberFormat('en-US', { style: 'currency', currency: props.campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.bid.value)} x {props.bid.count})
                            </span>
                            :
                            new Intl.NumberFormat('en-US', { style: 'currency', currency: props.campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.bid.value)
                        } */}
                        {commonFunctions.getDisplayedBid(props.bid, props.campaign.currency)}
                    </div>
                    <span className='status'>
                        {props.bid.status === BidStatus.FAILED && <span className='failed'>Failed</span>}
                        {props.bid.status === BidStatus.PENDING && <span className='pending'>Pending</span>}
                        {props.bid.status === BidStatus.SUCCEEDED && <span className='succeeded'>Succeeded</span>}
                    </span>
                </div>
            </div>
            <span className='lot-title'>{props.bid.lot.title}</span>
            {props.bid.campaignUser &&
                <div className='detail-row'>
                    <div className='detail-icon'>
                        <PersonIcon />
                    </div>
                    <div className='detail-text'>
                        {props.bid.campaignUser ? (props.bid.campaignUser.firstName ? `${commonFunctions.capitalize(props.bid.campaignUser.firstName)} ${commonFunctions.capitalize(props.bid.campaignUser.surname)}` : props.bid.campaignUser.email) : null}
                        {props.bid.isAnonymous && <span style={{ fontSize: "0.7rem" }}> (Anon.)</span>}
                    </div>
                </div>
            }
            <div className='detail-row'>
                <div className='detail-icon'>
                    <AccessAlarmIcon />
                </div>
                <div className='detail-text'>
                    {moment(props.bid.createdAt).format("HH:mm:ss DD/MM/YYYY")}
                </div>
            </div>
            {props.bid.device &&
                <div className='detail-row'>
                    <div className='detail-icon'>
                        <DeviceIcon device={props.bid.device} color="#FFFFFF" />
                    </div>
                    <div className='detail-text'>
                        {commonFunctions.capitalize(props.bid.device)}
                    </div>
                </div>
            }
        </div>
    )
}

export default MobileBidCard