import React, { useState } from "react"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputAdornment, TextField } from "@mui/material"
import { IBrochureConfig } from "../../../../interfaces/brochureToolPurchaseInfo"
import CloudinaryUploadWidget from "../../shared/CloudinaryUploadWidget"
import usePdfGenerator from '../../../../hooks/usePDFGenerator'
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ILot from "../../../../interfaces/lot"
import ICampaign from "../../../../interfaces/campaign"
import { Oval } from "react-loader-spinner"
import ColorInput from "../../shared/colorInput/ColorInput"

interface InputProps {
    isDialogOpen: boolean,
    setIsDialogOpen: (boolean: boolean) => void,
    lotList: ILot[],
    campaign: ICampaign,
    saveCampaign: (brochureConfig: IBrochureConfig) => void
}

const BrochureConfigDialog = (props: InputProps) => {

    const [updatedConfig, setUpdatedConfig] = useState<IBrochureConfig>(props.campaign.brochureToolPurchaseInfo ? props.campaign.brochureToolPurchaseInfo.brochureConfig : {} as IBrochureConfig)
    const [isDisplayBackgroundColorPicker, setIsDisplayBackgroundColorPicker] = useState<boolean>(false)
    const [isDisplayFontColorPicker, setIsDisplayFontColorPicker] = useState<boolean>(false)
    const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false)
    const [validationText, setValidationText] = useState<string>("")

    const pdfGenerator = usePdfGenerator()

    const generateBrochurePDF = () => {
        setIsGeneratingPdf(true)
        setValidationText("")
        try {
            pdf(pdfGenerator.generateBrochurePdf(
                props.lotList,
                props.campaign.currency,
                updatedConfig.headerText,
                updatedConfig.footerText,
                updatedConfig.backgroundColor,
                updatedConfig.fontColor,
                updatedConfig.frontPageImageUrl,
                updatedConfig.backPageImageUrl,
                updatedConfig.isIncludeTermsAndConditions ? props.campaign.termsAndConditionsHtml : undefined
            )).toBlob().then((blob) => {
                saveAs(blob, `Going Gone Brochure - ${props.campaign.name}.pdf`)
                setIsGeneratingPdf(false)
                props.saveCampaign(updatedConfig)
            })
        } catch (err) {
            setValidationText(String(err))
            setIsGeneratingPdf(false)
        }
    }


    const handleChange = (name, value) => {
        setUpdatedConfig({ ...updatedConfig, [name]: value })
    }

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={() => props.setIsDialogOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Brochure Settings</DialogTitle>
                <DialogContent style={{ paddingTop: 20 }}>
                    <TextField
                        id="frontPageImageUrl"
                        name="frontPageImageUrl"
                        label="Front Page Image URL"
                        variant="outlined"
                        value={updatedConfig?.frontPageImageUrl || ""}
                        onChange={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        allowMultipleUploads={false}
                                        folderName={props.campaign.slug}
                                        functionOnComplete={(uploadedUrlList) => {
                                            if (uploadedUrlList.length) {
                                                handleChange(
                                                    "frontPageImageUrl",
                                                    uploadedUrlList[uploadedUrlList.length - 1]
                                                );
                                            }
                                        }}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        id="backPageImageUrl"
                        name="backPageImageUrl"
                        label="Back Page Image URL"
                        variant="outlined"
                        value={updatedConfig?.backPageImageUrl || ""}
                        onChange={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        allowMultipleUploads={false}
                                        folderName={props.campaign.slug}
                                        functionOnComplete={(uploadedUrlList) => {
                                            if (uploadedUrlList.length) {
                                                handleChange(
                                                    "backPageImageUrl",
                                                    uploadedUrlList[uploadedUrlList.length - 1]
                                                );
                                            }
                                        }}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className="field-wrapper">
                        <ColorInput
                            name="backgroundColor"
                            label="Background Colour"
                            value={updatedConfig?.backgroundColor || ""}
                            handleChange={handleChange}
                        />
                    </div>

                    <div className="field-wrapper">
                        <ColorInput
                            name="fontColor"
                            label="Font Colour"
                            value={updatedConfig?.fontColor || ""}
                            handleChange={handleChange}
                        />
                    </div>

                    <TextField
                        id="headerText"
                        name="headerText"
                        label="Header Text"
                        variant="outlined"
                        defaultValue={updatedConfig?.headerText || ""}
                        onBlur={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        fullWidth
                    />

                    <TextField
                        id="footerText"
                        style={{ marginBottom: 12 }}
                        name="footerText"
                        label="Footer Text"
                        variant="outlined"
                        defaultValue={updatedConfig?.footerText || ""}
                        onBlur={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        fullWidth
                    />



                    <FormControlLabel
                        className="include-tscs-checkbox"
                        disabled={props.campaign.termsAndConditionsHtml ? false : true}
                        control={
                            <Checkbox
                                checked={updatedConfig && updatedConfig.isIncludeTermsAndConditions ? true : false}
                                onChange={(event) => {
                                    handleChange("isIncludeTermsAndConditions", event.target.checked ? true : false)
                                }}
                            />
                        }
                        label={`Include Terms and Conditions on penultimate page? ${!props.campaign.termsAndConditionsHtml ? "Diabled: No Ts&Cs defined in campaign settings." : ""}`}
                    />

                </DialogContent>
                <DialogActions>
                    <div className="action-section">

                        {validationText && <p className="validation-text">{validationText}</p>}


                        <div className="save-button-wrapper">
                            <div className='buttons'>
                                <Button onClick={() => props.setIsDialogOpen(false)} >
                                    Close
                                </Button>
                                <Button
                                    onClick={() => { generateBrochurePDF() }}
                                    className="gg-button"
                                    style={{ width: 220 }}
                                    disabled={isGeneratingPdf}
                                >
                                    {isGeneratingPdf ? <Oval ariaLabel="loading-indicator" height={20} width={20} strokeWidth={5} color="white" secondaryColor="white" />
                                        :
                                        'Generate Brochure'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BrochureConfigDialog