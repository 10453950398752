import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import React, { useContext, useState } from "react"
import IOrganisation from "../../../interfaces/organisation"
import { IUser } from "../../../interfaces/user"
import EditIcon from "@mui/icons-material/Edit"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import StaffDialog from "./StaffDialog"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, ORGANISATIONS_API_PATHS, USER_API_PATHS } from "../../../common/ApiPaths"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import RemoveStaffDialog from "./RemoveStaffDialog"
import { OrganisationContext } from "../../../context/OrganisationContext"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import { UserContext } from "../../../context/UserContext"
import { UserRole } from "../../../common/enums/UserRole"

interface InputProps {
	userList: IUser[]
	getUserList: () => void
	isCampaignView: boolean
	editCampaignOwnerList?: (userId: string, added: boolean) => void
}

interface IStaffDialogProperties {
	isOpen: boolean
	user: IUser
}

interface RemoveStaffDialogProperties {
	isOpen: boolean
	userId?: string
}

const StaffSection = (props: InputProps) => {
	// const [isStaffDialogOpen, setIsStaffDialogOpen] = useState<boolean>(false)
	const [staffDialogProperties, setStaffDialogProperties] = useState<IStaffDialogProperties>({ isOpen: false, user: {} } as IStaffDialogProperties)
	const [removeStaffDialogProperties, setRemoveStaffDialogProperties] = useState<RemoveStaffDialogProperties>({ isOpen: false })
	const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "", errorDetails: "" })
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);

	const usersApi = useFetch(API_PATHS.USERS)
	const organisationApi = useFetch(API_PATHS.ORGANISATIONS)
	const commonFunctions = useCommonFunctions()
	const organisation: IOrganisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)

	const openRemoveStaffDialog = userId => {
		setRemoveStaffDialogProperties({ isOpen: true, userId: userId })
	}

	const closeRemoveStaffDialog = (userId: string = '') => {
		setRemoveStaffDialogProperties({ isOpen: false })
		if (userId && userId.length) {
			removeStaffMember(userId)
		}
	}


	const removeStaffMember = async (userId: string) => {
		try {
			if (props.isCampaignView && props.editCampaignOwnerList) {
				props.editCampaignOwnerList(userId, false)
			}
			else {
				await organisationApi.del(`${ORGANISATIONS_API_PATHS.REMOVE_STAFF.replace('{userId}', userId)}`, undefined, dBUser._id)
			}
			props.getUserList()
		}
		catch (err: any) {
			console.log(err)
		}
	}

	const addEditStaffMember = async (user: IUser) => {
		try {
			if (props.isCampaignView && props.editCampaignOwnerList) {
				props.editCampaignOwnerList(user._id, true)
			}
			else {
				if (user._id) {
					await usersApi.put(`${USER_API_PATHS.UPDATE_USER.replace('{userId}', user._id)}`, user, dBUser._id)
				}
				else {
					const userInfo = {
						organisationId: organisation._id,
						role: user.role,
						auth0Id: '',
						firstName: user.firstName,
						surname: user.surname,
						email: user.email,
						campaignId: null,
						password: user.password,
						currentUserId: dBUser._id,
						stripeConnectedAccountId: organisation.stripeConnectedAccountId,
						phone: user.phone
					}
					await usersApi.post(`${USER_API_PATHS.CREATE_USER}`, userInfo)
				}
			}
			props.getUserList()
		}
		catch (err: any) {
			setDialogProperties({ type: "ERROR", title: "Adding/Editing Staff failed", message: `Adding/Editing staff failed with some error`,	errorDetails: err.message })
			setIsConfirmationDialogOpen(true)
			props.getUserList()
			// console.error('err:', (err.message.split('"message":"')[1].split('"')[0]))
		}
	}

	return (
		<section className="staff-section">
			<h2>Staff</h2>
			<Paper className="input-wrapper">
				{props.userList.length > 0 ? (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<div className="table-header-cell">
										<span>Name</span>
									</div>
								</TableCell>
								<TableCell className="hide-on-mobile">
									<div className="table-header-cell">
										<span>Email</span>
									</div>
								</TableCell>
								{!props.isCampaignView && (
									<TableCell>
										<div className="table-header-cell">
											<span>Role</span>
										</div>
									</TableCell>
								)}
								<TableCell>
									<div className="table-header-cell"></div>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.userList.map((user, index) => {
								return (
									<TableRow key={index}>
										<TableCell>{`${user.firstName} ${user.surname}`}</TableCell>
										<TableCell className="hide-on-mobile">{user.email}</TableCell>
										{!props.isCampaignView && (
											<TableCell>{commonFunctions.enumToText(user.role)}</TableCell>
										)}
										{dBUser.role !== UserRole.OPERATOR && (
											<TableCell>
												<div className="action-cell">
													{!props.isCampaignView && (
														<div onClick={() => setStaffDialogProperties({ isOpen: true, user: user })}>
															<Tooltip title="Edit Staff Member" enterDelay={500}>
																<EditIcon />
															</Tooltip>
														</div>
													)}
													<div onClick={() => openRemoveStaffDialog(user._id)}>
														<Tooltip title="Remove Staff Member" enterDelay={500}>
															<HighlightOffIcon />
														</Tooltip>
													</div>
												</div>
											</TableCell>
										)}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				) : (
					<div className="no-entries">No staff configured.</div>
				)}

				{dBUser.role !== UserRole.OPERATOR && (
					<div className="button-wrapper">
						<Button className="gg-button" onClick={() => setStaffDialogProperties({ isOpen: true, user: {} as IUser })}>
							Add Staff Member
						</Button>
					</div>
				)}
			</Paper>

			{dBUser.role !== UserRole.OPERATOR && (
				<>
					<StaffDialog
						handleClose={() => setStaffDialogProperties({ isOpen: false, user: {} as IUser })}
						isDialogOpen={staffDialogProperties.isOpen}
						user={staffDialogProperties.user}
						handleSave={addEditStaffMember}
						organisationId={organisation._id}
						isCampaignView={props.isCampaignView}
						campaignUsers={props.userList}
					/>
					<RemoveStaffDialog
						handleClose={closeRemoveStaffDialog}
						isDialogOpen={removeStaffDialogProperties.isOpen}
						userId={removeStaffDialogProperties.userId || ''}
						isCampaignView={props.isCampaignView}
					/>

					<ConfirmationDialog
						handleClose={() => setIsConfirmationDialogOpen(false)}
						isDialogOpen={isConfirmationDialogOpen}
						message={dialogProperties.message}
						errorDetails={dialogProperties.errorDetails}
						title={dialogProperties.title}
						type={dialogProperties.type}
						label={dialogProperties.label}
						primaryButton={<Button onClick={() => setIsConfirmationDialogOpen(false)} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
							Ok
						</Button>}
					/>
				</>
			)}
		</section>
	)
}

export default StaffSection
