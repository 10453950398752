import React, { useState } from "react"
import "./loginSignup.scss"
// import "../admin/login/login.scss"
import { Button, TextField } from "@mui/material"
import { auth } from "../../auth/auth0.service"
import { Auth0Error } from "auth0-js"

interface IForgotPasswordProps {
	handleClose: () => void
}

const ForgotPassword = (props: IForgotPasswordProps) => {
	const [email, setEmail] = useState<string>("")
	const [emailValidation, setEmailValidation] = useState<string>("")
	const [isEmailSent, setIsEmailSent] = useState<boolean>(false)

	const getValidationForEmail = () => {
		if (email === "" || typeof email === "undefined") {
			setEmailValidation("Please enter your email.")
			return false
		} else {
			setEmailValidation("")
			return true
		}
	}

	const sendForgotPasswordEmail = () => {
		if (getValidationForEmail()) {
			auth.changePassword(
				{
					email: email,
					connection: "Username-Password-Authentication",
				},
				(error: Auth0Error | null, result: any) => {
					if (error) {
						console.log(error.description)
					} else {
						setIsEmailSent(true)
						// currentUser.login(result)
						// localStorage.setItem('currentUser', JSON.stringify(result))
					}
				}
			)
		}
	}

	return (
		<div className="forgot-password">
			<h2>Forgot Password</h2>
			{isEmailSent ? (
				<span className="intro-text">
					Thanks, if that email address exists in our system a reset password link will be sent. <br />
					<br />
					Didn't recieve an email? Please contact the email administrator.
				</span>
			) : (
				<>
					<p className="intro-text">
						Please enter the email address you signed up with. We will then send you a link that will allow you to update your password.
					</p>

					<form>
						<div className="field-wrapper">
							<TextField
								id="email"
								name="email"
								label="Email Address"
								variant="outlined"
								fullWidth
								onChange={event => setEmail(event.target.value)}
								required
								helperText={emailValidation}
								error={!!emailValidation.length}
							/>
						</div>

						<div className="button-wrapper">
							<Button id="cancel-button" onClick={props.handleClose}>
								Cancel
							</Button>
							<Button id="save-button" className="gg-button" onClick={sendForgotPasswordEmail}>
								Send
							</Button>

						</div>

					</form>
				</>
			)}
		</div>
	)
}

export default ForgotPassword
