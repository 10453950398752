import React from 'react';
import { useHistory } from "react-router-dom";
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const Logout = () => {

    const history = useHistory()
    const { search } = useLocation()
    const values = queryString.parse(search)
    history.push(values.returnTo)

    return (
        <p>
            Logging Out...
        </p>
    )
}

export default Logout
