import { Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material"
import React, { useContext, useState } from "react"
import { API_PATHS, ORGANISATIONS_API_PATHS, STRIPE_API_PATHS } from "../../common/ApiPaths"
import useFetch from "../../hooks/useFetch"
import IOrganisation from "../../interfaces/organisation"
import "./licencePurchaseDialog.scss"
import ILicenceBundle from "../../interfaces/licenceBundle"
import { OrganisationContext, OrganisationDispatchContext } from "../../context/OrganisationContext"
import { UserContext } from "../../context/UserContext"
import { UserRole } from "../../common/enums/UserRole"
import { LicenceBundlePricing } from "../../common/enums/LicenceBundlePricing"

interface InputProps {
	isDialogOpen: boolean
	handleClose: () => void
	setDialogProperties: (data: any) => void
}

const LicencePurchaseDialog = (props: InputProps) => {
	const [selectedBundleIndex, setSelectedBundleIndex] = useState<number | null>(null)
	const [isTermsAndConditionsAgreed, setIsTermsAndConditionsAgreed] = useState<boolean>(false)

	const stripeApi = useFetch(API_PATHS.STRIPE)
	const organisationsApi = useFetch(API_PATHS.ORGANISATIONS)
	const organisation: IOrganisation = useContext(OrganisationContext)
	const setOrganisation = useContext(OrganisationDispatchContext)
	const { dBUser } = useContext(UserContext)

	let licenceBundleOptions = [
		{
			licenceCount: 1,
			label: "1 licence",
			totalCost: LicenceBundlePricing.SINGLE,
			// quote: "Softly, softly, catchee monkey...",
		},
		{
			licenceCount: 5,
			label: "5 licences",
			totalCost: LicenceBundlePricing.BUNDLE,
			// quote: "Can't decide? Shoot down the middle.",
		},
		{
			licenceCount: 15,
			label: "15 licences",
			totalCost: LicenceBundlePricing.BULK,
			// quote: "Hey big spender!",
		},
	]

	const calculatePrice = (basePrice: number): number => {
		if (dBUser.role === UserRole.SUPER_ADMIN) {
			return 0
		}
		else if (organisation.licenceFeeDiscountPercentage) {
			return basePrice * (1 - organisation.licenceFeeDiscountPercentage / 100)
		} else {
			return basePrice
		}
	}

	const calculatePerLicenceBreakdown = (basePrice: number, multiple: number): number => {
		return calculatePrice(basePrice) / multiple
	}

	const determineBundleCardClass = (index: number) => {
		if (selectedBundleIndex === index) {
			return "pricing-card selected"
		} else {
			return "pricing-card"
		}
	}

	const handleCancel = () => {
		setSelectedBundleIndex(null)
		setIsTermsAndConditionsAgreed(false)
		props.handleClose()
	}

	const handleBundleClick = (index: number) => {
		if (index === selectedBundleIndex) {
			setSelectedBundleIndex(null)
		} else {
			setSelectedBundleIndex(index)
		}
	}

	const purchaseLicences = async () => {


		//TODO - update this, to new add pending licence route

		// id: uuidv4(),
		// bundlePrice: licenceBundleOptions[selectedBundleIndex!].totalCost,
		// licenceCount: licenceBundleOptions[selectedBundleIndex!].licenceCount,
		// discountPercentage: props.organisation.licenceFeeDiscountPercentage || 0,
		// purchaseDate: new Date(),
		// currency: props.organisation.currency,
		// status: LicenceStatus.PENDING,
		// paymentRefId: null,
		// isActive: true,

		let pendingLicenceBundle: ILicenceBundle = await organisationsApi.put(
			`${ORGANISATIONS_API_PATHS.ADD_PENDING_ORGANISATION.replace("{organisationId}", organisation._id)}`,
			{
				...organisation,
				bundlePrice: licenceBundleOptions[selectedBundleIndex!].totalCost,
				licenceCount: licenceBundleOptions[selectedBundleIndex!].licenceCount,
				userId: dBUser._id
			}
		)

		if (dBUser.role !== UserRole.SUPER_ADMIN) {
			let data = await stripeApi.post(STRIPE_API_PATHS.CREATE_CHECKOUT_SESSION, {
				productName: `${licenceBundleOptions[selectedBundleIndex!].label} ${organisation.licenceFeeDiscountPercentage > 0 ? `(${organisation.licenceFeeDiscountPercentage}% discount)` : ''}`,
				price: calculatePrice(licenceBundleOptions[selectedBundleIndex!].totalCost),
				currency: organisation.currency,
				organisationId: organisation._id,
				organisationSlug: organisation.slug,
				organisationEmail: organisation.contactEmail,
				pendingLicenceBundleId: pendingLicenceBundle.id,
				userId: dBUser._id,
				baseUrl: window.location.origin,
				tabNumber: '1',
				description: `Going Gone - Licence Purchase`
			})

			window.location.href = data.url
		}
		else {
			setOrganisation({ ...organisation, licenceBundleList: [...organisation.licenceBundleList, pendingLicenceBundle] })
			props.setDialogProperties({
				isOpen: true,
				type: "SUCCESS",
				title: "Licence Bundle Purchased.",
				message: "The licence bundle has been purchased successfully.",
			})
			handleCancel()
		}
	}

	return (
		<Dialog open={props.isDialogOpen} onClose={handleCancel} className="licence-purchase-dialog">
			<DialogTitle>Purchase Going Gone Licences</DialogTitle>
			{organisation.licenceBundleList && (
				<DialogContent>
					<div className="intro-wrapper">
						<div className="intro">
							<span>Please select how many licences you would like to buy.</span>{" "}
						</div>
						{organisation.licenceFeeDiscountPercentage > 0 && <div className="discount">
							<span>
								{dBUser.role === UserRole.SUPER_ADMIN ? 100 : organisation.licenceFeeDiscountPercentage}% <br /> discount applied
							</span>
						</div>}
					</div>
					<div className="pricing-wrapper">
						<Card className={determineBundleCardClass(0)} onClick={() => handleBundleClick(0)}>
							<div className="top-group">
								<span className="label">{licenceBundleOptions[0].label}</span>
								{organisation.licenceFeeDiscountPercentage > 0 && (
									<span className="discounted-price">
										{new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: organisation.currency ?? "gbp",
											minimumFractionDigits: 0,
											maximumFractionDigits: 2,
										}).format(LicenceBundlePricing.SINGLE)}
									</span>
								)}
								<span className="price" style={organisation.licenceFeeDiscountPercentage > 0 ? {} : { marginTop: 24 }}>
									{new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: organisation.currency ?? "gbp",
										minimumFractionDigits: 0,
										maximumFractionDigits: 2,
									}).format(calculatePrice(licenceBundleOptions[0].totalCost))}
								</span>
								<span className="per-licence-breakdown"></span>
							</div>
							{/* <span className="quote">{selectedBundleIndex === 0 && <span>{licenceBundleOptions[0].quote}</span>}</span> */}
						</Card>
						<Card className={determineBundleCardClass(1)} onClick={() => handleBundleClick(1)}>
							<div className="top-group">
								<span className="label">{licenceBundleOptions[1].label}</span>
								{organisation.licenceFeeDiscountPercentage > 0 && (
									<span className="discounted-price">
										{new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: organisation.currency ?? "gbp",
											minimumFractionDigits: 0,
											maximumFractionDigits: 2,
										}).format(LicenceBundlePricing.BUNDLE)}
									</span>
								)}
								<span className="price" style={organisation.licenceFeeDiscountPercentage > 0 ? {} : { marginTop: 24 }}>
									{new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: organisation.currency ?? "gbp",
										minimumFractionDigits: 0,
										maximumFractionDigits: 2,
									}).format(calculatePrice(licenceBundleOptions[1].totalCost))}
								</span>
								<span className="per-licence-breakdown">
									(
									{new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: organisation.currency ?? "gbp",
										minimumFractionDigits: 0,
										maximumFractionDigits: 2,
									}).format(
										calculatePerLicenceBreakdown(licenceBundleOptions[1].totalCost, licenceBundleOptions[1].licenceCount)
									)}{" "}
									per licence)
								</span>
							</div>
							{/* <span className="quote">{selectedBundleIndex === 1 && <span>{licenceBundleOptions[1].quote}</span>}</span> */}
						</Card>
						<Card className={determineBundleCardClass(2)} onClick={() => handleBundleClick(2)}>
							<div className="top-group">
								<span className="label">{licenceBundleOptions[2].label}</span>
								{organisation.licenceFeeDiscountPercentage > 0 && (
									<span className="discounted-price">
										{new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: organisation.currency ?? "gbp",
											minimumFractionDigits: 0,
											maximumFractionDigits: 2,
										}).format(LicenceBundlePricing.BULK)}
									</span>
								)}
								<span className="price" style={organisation.licenceFeeDiscountPercentage > 0 ? {} : { marginTop: 24 }}>
									{new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: organisation.currency ?? "gbp",
										minimumFractionDigits: 0,
										maximumFractionDigits: 2,
									}).format(calculatePrice(licenceBundleOptions[2].totalCost))}
								</span>
								<span className="per-licence-breakdown">
									(
									{new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: organisation.currency ?? "gbp",
										minimumFractionDigits: 0,
										maximumFractionDigits: 2,
									}).format(
										calculatePerLicenceBreakdown(licenceBundleOptions[2].totalCost, licenceBundleOptions[2].licenceCount)
									)}{" "}
									per licence)
								</span>
							</div>
							{/* <span className="quote">{selectedBundleIndex === 2 && <span>Hey big spender!</span>}</span> */}
						</Card>
					</div>
				</DialogContent>
			)}
			<DialogActions>
				<div className="actions-wrapper">
					<FormControlLabel
						className="tsandcs-checkbox"
						labelPlacement="start"
						control={
							<Checkbox
								checked={isTermsAndConditionsAgreed}
								onChange={() => {
									setIsTermsAndConditionsAgreed(!isTermsAndConditionsAgreed)
								}}
							/>
						}
						label={
							<span>
								I agree to the{" "}
								<a className="hyperlink" target="_blank" href={"#"} rel="noreferrer">
									terms and conditions
								</a>{" "}
								of this site.
							</span>
						}
					/>
					<div className="buttons-wrapper">
						<Button id="cancel-button" onClick={() => handleCancel()}>Cancel</Button>
						<Button
							id="next-button"
							onClick={purchaseLicences}
							className="gg-button"
							disabled={selectedBundleIndex === null || !isTermsAndConditionsAgreed}
						>
							Next
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default LicencePurchaseDialog
