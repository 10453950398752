import { useState } from "react";
import ICustomBidderDetail from "../../../../interfaces/customBidderDetail";
import IValidationObject from "../../../../interfaces/validationObject";


const useCustomBidderDetailValidation = (customBidderDetail: ICustomBidderDetail) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([
        { name: "label", isValid: true, validationMessage: "" },
        { name: "isRequired", isValid: true, validationMessage: "" },
        { name: "type", isValid: true, validationMessage: "" },
        { name: "optionList", isValid: true, validationMessage: "" }
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "label", isValid: true, validationMessage: "" },
            { name: "isRequired", isValid: true, validationMessage: "" },
            { name: "type", isValid: true, validationMessage: "" },
            { name: "optionList", isValid: true, validationMessage: "" }
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (customBidderDetail.label === "" || typeof (customBidderDetail.label) === "undefined") {
            setFailedValidation("label", "Please enter a label for the custom field.")
        }

        if (typeof (customBidderDetail.isRequired) === "undefined") {
            setFailedValidation("isRequired", "Please select if the field is required.")
        }

        if (customBidderDetail.type === "" || typeof (customBidderDetail.type) === "undefined") {
            setFailedValidation("type", "Please select the type of the custom field.")
        }

        if (customBidderDetail.type === "SELECT" && (customBidderDetail.optionList === "" || typeof (customBidderDetail.optionList) === "undefined")) {
            setFailedValidation("optionList", "Please enter the options for the dropdownlist (separated with commas)")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default useCustomBidderDetailValidation