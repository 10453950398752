import { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";

interface InputProps {
	isDialogOpen: boolean;
	handleClose: (boolean: boolean, manualVoucherRef: string) => void;
}


const ManualVoucherDialog = (props: InputProps) => {

	const [manualVoucherRef, setManualVoucherRef] = useState<string>("")

	return (

		<Dialog
			open={props.isDialogOpen}
			onClose={() => props.handleClose(false, "")}
			aria-labelledby="dialog-title"
			className="manual-payment-dialog"
		>
			<DialogTitle id="form-dialog-title">Manual Voucher</DialogTitle>
			<DialogContent>
				<TextField
					variant="outlined"
					id="manualVoucherReference"
					name="manualVoucherReference"
					label="Voucher Reference"
					type="text"
					fullWidth
					value={manualVoucherRef}
					onChange={(event) => setManualVoucherRef(event.target.value)}
					helperText="Enter a reference to the voucher, eg the voucher ID from the VMS."
				/>
			</DialogContent>
			<DialogActions>
				<div className="action-section">
					<div className="save-button-wrapper">
						<div className="buttons">
							<Button id="cancel-button" onClick={() => props.handleClose(false, "")}  >
								Cancel
							</Button>
							<Button id="save-button" className="gg-button" onClick={() => props.handleClose(true, manualVoucherRef)} >
								Save
							</Button>
						</div>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default ManualVoucherDialog;
