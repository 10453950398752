import { ToggleButton, Tooltip } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ColorPopover from "../colorPopover/ColorPopover";
import { useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/react';
import InsertLinkPopover from './InsertLinkPopover';

const LinkButton = ({ editor }: { editor: Editor | null }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [linkConfig, setLinkConfig] = useState({ link: "/%%campaign-slug%%/lots", isOpenInNewTab: "true" });

	const divRef: any = useRef();

	function handleClick() {
		console.log(JSON.stringify(editor!.getAttributes('link')))
		setLinkConfig({ link: editor!.getAttributes('link').href, isOpenInNewTab: editor!.getAttributes('link').target === "_blank" ? "true" : "false" })
		setAnchorEl(divRef.current);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	const save = () => {
		editor!.chain().setLink({ href: linkConfig.link, target: linkConfig.isOpenInNewTab === "true" ? "_blank" : "_self" }).run()
		setAnchorEl(null);
	}

	const open = Boolean(anchorEl);

	return (
		<>
			<Tooltip title="Hyperlink" enterDelay={600}>
				<ToggleButton ref={divRef} value="" aria-label="color" onClick={handleClick}>
					<InsertLinkIcon />
					<ArrowDropDownIcon />
				</ToggleButton>
			</Tooltip>
			<InsertLinkPopover
				open={open}
				handleClose={handleClose}
				anchorEl={anchorEl}
				linkConfig={linkConfig}
				setLinkConfig={setLinkConfig}
				save={save}
				saveButtonText={editor!.getAttributes('link').href ? "Update Link" : "Insert Link"}
			/>
		</>
	)
}

export default LinkButton