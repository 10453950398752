import { Button, Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import ICampaign from '../../../interfaces/campaign'
import './closeCampaignSection.scss'

interface InputProps {
    closeCampaign: () => void
    isSendWinnerNotifications: boolean
    setIsSendWinnerNotifications: (boolean: boolean) => void
    campaign: ICampaign
}

const CloseCampaignSection = (props: InputProps) => {

    return (
        <div className='close-campaign-section'>
            <h3>The campaign is currently: <span className='status open'>Open</span>.</h3>
            <span>Closing the campaign will close any open lots, optionally notify each of the winners and generate payment details.</span>
            <span className='warning-text'>Please only close the campaign if you are absolutely sure you will not need to reopen as this action is irreversible.</span>
            <div className='actions-wrapper'>
                {props.campaign.notificationType !== "NONE" &&
                <FormControlLabel control={
                    <Checkbox 
                    checked={props.isSendWinnerNotifications}
                    onChange={() => props.setIsSendWinnerNotifications(!props.isSendWinnerNotifications)}
                     />
                } label={`Send winner notifications?`} />
            }
                <Button onClick={props.closeCampaign} className="gg-button">Close Campaign</Button>
                {props.campaign.notificationType === "NONE" ?
                <span className='notification-settings-text error'>Warning: Campaign notification setting set to "None" - <u>no notifications will be sent</u> when closing the campaign.</span> :
                <span className='notification-settings-text'>Campaign notification setting: {props.campaign.notificationType}</span> 
        }
            </div>
        </div>
    )
}

export default CloseCampaignSection