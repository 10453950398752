import React, { useState } from "react"
import "./itemCatalogue.scss"
import ClearIcon from "@mui/icons-material/Clear"
import { Tooltip } from "@mui/material"
import { useHistory } from "react-router-dom"
import ICampaign from "../../../interfaces/campaign"
import DefaultCampaignDialog from "./DefaultCampaignDialog"

interface InputProps {
	defaultCampaignName: string | null
	setDefaultCampaign: (defaultCampaign: ICampaign | null) => void
	// setDefaultCampaignId: (defaultCampaignId: string | null | undefined) => void
	organisationSlug: string
	getCampaignList: (input: string) => void
	defaultCampaign: ICampaign | null
	campaignList: ICampaign[]
}

const DefaultCampaign = (props: InputProps) => {
	let history = useHistory()
	const [isDefaultCampaignDialogOpen, setIsDefaultCampaignDialogOpen] = useState<boolean>(false)

	const clearDefaultCampaign = () => {
		props.setDefaultCampaign(null)
		// props.setDefaultCampaignId("")
		history.push(`${props.organisationSlug}?tabNumber=4`)
	}

	return (
		<>
			<div className="default-campaign-wrapper">
				{props.defaultCampaignName ? (
					<div className="default-campaign-row">
						<span>
							Default Campaign:<b> {props.defaultCampaignName}</b>
						</span>
						<Tooltip title="Clear default campaign" enterDelay={500} onClick={clearDefaultCampaign}>
							<ClearIcon />
						</Tooltip>
					</div>
				) : (
					<span className="hyperlink" style={{ fontSize: "0.8rem" }} onClick={() => setIsDefaultCampaignDialogOpen(true)}>
						Select default campaign...
					</span>
				)}
			</div>
			<DefaultCampaignDialog
				campaignList={props.campaignList}
				defaultCampaign={props.defaultCampaign}
				getCampaignList={props.getCampaignList}
				handleClose={() => setIsDefaultCampaignDialogOpen(false)}
				isDialogOpen={isDefaultCampaignDialogOpen}
				setDefaultCampaign={props.setDefaultCampaign}
				// setDefaultCampaignId={props.setDefaultCampaignId}
			/> 
		</>
	)
}

export default DefaultCampaign
