import { FormHelperText, InputAdornment, InputLabel } from '@mui/material'
import React, { useEffect, useState } from "react"
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import PinInput from "react-pin-input"
import './pinField.scss'

interface IPinInputProps {
    label: string
    pin: string
    setPin: (val: string) => void
    pinId: string
    isValid?: boolean
    helperText?: string
}

const PinField = (props: IPinInputProps) => {
    const [showPin, setShowPin] = useState<boolean>(false)

    useEffect(() => {
        var pinFields = document.getElementById(props.pinId)?.getElementsByClassName("pincode-input-text")
        if (pinFields && pinFields.length > 0) {
            for (let x of pinFields) {
                x.setAttribute('type', 'tel')
            }
        }
    }, [])

    const togglePinVisibility = () => {
        var pinFields = document.getElementById(props.pinId)?.getElementsByClassName("pincode-input-text")
        if (pinFields && pinFields.length > 0) {
            setShowPin(!showPin)
            for (let x of pinFields) {
                if (!showPin) {
                    (x as HTMLElement).style["-webkit-text-security"] = "unset";
                    (x as HTMLElement).style["-moz-text-security"] = "unset";
                }
                else {
                    (x as HTMLElement).style["-webkit-text-security"] = "disc";
                    (x as HTMLElement).style["-moz-text-security"] = "disc";
                }

                // if (x.getAttribute('type') === "password") {
                //     x.setAttribute('type', 'tel')
                // } else {
                //     x.setAttribute('type', 'password')
                // }
            }
        }
    }


    return (
        <div className="pin-wrapper" id={props.pinId}>
            <InputLabel id="pin-label">{props.label}</InputLabel>
            <div className='center-aligned'>
                <PinInput
                    length={4}
                    initialValue=""
                    type="numeric"
                    inputMode="number"
                    inputStyle={{ border: 'solid 1px #0000004a', borderRadius: 4 }}
                    inputFocusStyle={{ border: 'solid 2px #1976d2' }}
                    onComplete={(value, index) => { props.setPin(value) }}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                    secretDelay={1000}
                />
                <InputAdornment position="end">
                    {!showPin ?
                        <VisibilityIcon onClick={togglePinVisibility} style={{ cursor: 'pointer' }} />
                        :
                        <VisibilityOffIcon onClick={togglePinVisibility} style={{ cursor: 'pointer' }} />
                    }
                </InputAdornment>
            </div>
            {props.helperText && (
                <>
                    {props.isValid ? (
                        <FormHelperText>{props.helperText}</FormHelperText>

                    ) : (
                        <FormHelperText style={{ color: "#d32f2f" }}>{props.helperText}</FormHelperText>
                    )}
                </>
            )}
        </div>
    )

}

export default PinField
