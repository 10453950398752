import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";

import { Controller } from "react-hook-form";

import { isValidCommunicationFromField } from "../../../../../../utils/validators";

const SMS = ({
  control,
  communicationValidation,
  messageStateUpdateHandler,
  testCommunicationValidation,
}) => {
  // react hooks:
  // states:
  // sms settings:
  const [smsSettingState, setSMSSettingState] = useState({
    formValues: {
      from: "",
      message: "",
    },
    formValidation: {
      isFromValid: false,
      isMessageValid: false,
    },
    formTouched: {
      from: false,
      message: false,
    },
  });

  useEffect(() => {
    communicationValidation.resetValidationFields();
    testCommunicationValidation.resetValidationFields();
  }, []);

  // form fields update handler
  const updateSmsSettingsHandler = (name: string, value: string) => {
    // update from value
    if (name === "from") {
      setSMSSettingState({
        ...smsSettingState,
        formValues: {
          ...smsSettingState.formValues,
          from: value,
        },
        formTouched: {
          ...smsSettingState.formTouched,
          from: false,
        },
      });
    }
    // update message value
    if (name === "message") {
      setSMSSettingState({
        ...smsSettingState,
        formValues: {
          ...smsSettingState.formValues,
          message: value,
        },
        formTouched: {
          ...smsSettingState.formTouched,
          message: false,
        },
      });
    }
    messageStateUpdateHandler(name, value, "SMS");
  };

  // validation on blur handler
  const onBlurHandler = (e) => {
    // validate from
    if (e.target.name === "from") {
      const isFromValid = isValidCommunicationFromField(e.target.value);
      setSMSSettingState({
        ...smsSettingState,
        formValidation: {
          ...smsSettingState.formValidation,
          isFromValid: isFromValid,
        },
        formTouched: {
          ...smsSettingState.formTouched,
          from: true,
        },
      });
    }
    // validate message
    if (e.target.name === "message") {
      const isMessageValid = e.target.value.length;
      setSMSSettingState({
        ...smsSettingState,
        formValidation: {
          ...smsSettingState.formValidation,
          isMessageValid: isMessageValid,
        },
        formTouched: {
          ...smsSettingState.formTouched,
          message: true,
        },
      });
    }
  };

  return (
    <Box>
      <Typography sx={[{ fontWeight: "bold" }]} variant="h6" gutterBottom>
        SMS Settings
      </Typography>
      <div>
        <Controller
          name="from"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              sx={[
                {
                  width: "50%",
                },
              ]}
              inputProps={{
                autoComplete: "off",
              }}
              id="from"
              name={field.name}
              label="From"
              variant="outlined"
              value={field.value}
              helperText={
                fieldState.invalid
                  ? fieldState.error?.message
                  : "Must be between 4 to 11 alphanumeric characters including at least one letter."
              }
              onChange={field.onChange}
              error={fieldState.invalid}
              onBlur={field.onBlur}
              inputRef={field.ref}
            />
          )}
        />
      </div>
      <br />
      <div>
        <Controller
          name="message"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              multiline
              rows={2}
              id="message"
              fullWidth
              name={field.name}
              maxRows={350}
              label="Message"
              // inputProps={{ maxLength: 100 }}
              placeholder="Type your message here"
              value={field.value}
              helperText={
                fieldState.invalid
                  ? fieldState.error?.message
                  : `Characters: ${field.value.length}`
              }
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={fieldState.invalid}
              inputRef={field.ref}
            />
          )}
        />
      </div>
    </Box>
  );
};

export default SMS;
