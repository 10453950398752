import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, ListSubheader } from "@mui/material"
import React, { useContext, useEffect, useMemo, useState } from "react"
import ReactCountryFlag from "react-country-flag"
import IOrganisation from "../../../interfaces/organisation"
import CloudinaryUploadWidget from "../shared/CloudinaryUploadWidget"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import IValidationObject from "../../../interfaces/validationObject"
import { OrganisationContext, OrganisationDispatchContext } from "../../../context/OrganisationContext"
import { UserContext } from "../../../context/UserContext"
import { IUser } from "../../../interfaces/user"
import { UserRole } from "../../../common/enums/UserRole"
import SearchIcon from "@mui/icons-material/Search"

interface InputProps {
	validationObject: IValidationObject[]
	handleChange: (name: string, value: string) => void
	isNewOrganisation: boolean
}

const TechnicalConfigurationSection = (props: InputProps) => {
	const organisation: IOrganisation = useContext(OrganisationContext)
	const setOrganisation = useContext(OrganisationDispatchContext)
	const { dBUser } = useContext(UserContext)

	const commonFunctions = useCommonFunctions()
	const allowedCountries = commonFunctions.getAllowedCountries()
	const allCountriesData = commonFunctions.getAllCountriesData()

	const [phoneNumber, setPhoneNumber] = useState<string>("")
	const [selectedCountryCode, setSelectedCountryCode] = useState<string>(commonFunctions.findIsdCodeByCountryCode("GB"))
	const [countryCodeAdded, setCountryCodeAdded] = useState<boolean>(false)
	const [selectedCurrency, setSelectedCurrency] = useState(organisation.currency || "GBP")
	const [searchText, setSearchText] = useState("")
	const [phoneError, setPhoneError] = useState<boolean>(false)
	const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>("")

	const displayedCountries = useMemo(() => {
		let lowercaseSearchText = searchText.toLowerCase()
		return allCountriesData.filter(
			option =>
				option.name.toLowerCase().includes(lowercaseSearchText) ||
				option.isd_code.includes(lowercaseSearchText) ||
				`${option.name} (${option.isd_code})`.toLowerCase().includes(lowercaseSearchText)
		)
	}, [searchText])

	const handlePhoneChange = e => {
		let phone = selectedCountryCode + e.target.value
		let parsed = commonFunctions.getParsedPhone(phone)

		if (!commonFunctions.isNumberCountryCode(phone)) {
			props.handleChange(e.target.name, parsed.phone)
			setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
			setPhoneError(parsed.error)
			setPhoneErrorMessage(parsed.errorMessage)
		} else {
			setPhoneError(false)
			setPhoneErrorMessage("")
			setCountryCodeAdded(false)
			props.handleChange(e?.target.name, selectedCountryCode + e.target.value)
			setPhoneNumber(commonFunctions.getNumberWithoutCountryCode(parsed.phone))
		}
	}

	// TODO move this into reusable service
	const renderCurrencyDropdown = () => {
		let finalCurrencyCountries = allowedCountries.filter(x => x.currencyCode !== "EUR")
		finalCurrencyCountries.push({
			name: "European Union",
			countryCode: "EU",
			currencyCode: "EUR",
			isd_code: "",
			currency: "European Euro",
		})
		finalCurrencyCountries = finalCurrencyCountries.sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1))
		return finalCurrencyCountries.map((country, index) => (
			<MenuItem value={country.currencyCode} key={index}>
				<ReactCountryFlag countryCode={country.countryCode} style={{ marginRight: "8px" }} />
				{` ${country.currency} (${country.currencyCode})`}
			</MenuItem>
		))
	}

	const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		props.validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	return (
		<section className="technical-configuration-section">
			<h2 className="subtitle">Technical Configuration</h2>
			<Paper className="input-wrapper">
				{/* <div className="field-wrapper">
					<TextField
						id="name"
						name="name"
						label="Organisation Name"
						variant="outlined"
						required
						value={organisation.name || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText={
							getValidation("organisationName").isValid
								? "The name of the organisation."
								: getValidation("organisationName").validationMessage
						}
						error={!getValidation("organisationName").isValid}
						disabled={currentUser.role === UserRole.OPERATOR}
					/>
				</div> */}

				<div className="field-wrapper">
					<TextField
						id="stripeConnectedAccountId"
						name="stripeConnectedAccountId"
						label="Stripe Connected Account Id"
						variant="outlined"
						required
						value={organisation.stripeConnectedAccountId || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText={
							getValidation("stripeConnectedAccountId").isValid
								? "The Stripe connected account that all payment actions will be associated with."
								: getValidation("stripeConnectedAccountId").validationMessage
						}
						error={!getValidation("stripeConnectedAccountId").isValid}
						disabled={dBUser.role === UserRole.OPERATOR || !props.isNewOrganisation}
					/>
				</div>

				<div className="field-wrapper">
					<TextField
						id="sendgridVerifiedEmail"
						name="sendgridVerifiedEmail"
						label="Sendgrid Verified Email"
						variant="outlined"
						value={organisation.sendgridVerifiedEmail || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText="The verified email address that all comms emails will be sent from."
					/>
				</div>

				<div className="field-wrapper">
					<TextField
						id="sendgridTemplateId"
						name="sendgridTemplateId"
						label="Sendgrid Template Id"
						variant="outlined"
						value={organisation.sendgridTemplateId || ""}
						onChange={event => props.handleChange(event.target.name, event.target.value)}
						helperText="The ID if the email template to be used in Sendgrid."
					/>
				</div>
			</Paper>
		</section>
	)
}

export default TechnicalConfigurationSection
