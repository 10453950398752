import React from "react"
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Autocomplete,
	Paper,
} from "@mui/material"
import "../dialog.scss"
import ICampaign from "../../../interfaces/campaign"

interface InputProps {
	isDialogOpen: boolean
	handleClose: () => void
	campaignList: ICampaign[]
	getCampaignList: (input: string) => void
	defaultCampaign: ICampaign | null
	setDefaultCampaign: (campaign: ICampaign | null) => void
}

const DefaultCampaignDialog = (props: InputProps) => {
	const handleClose = () => {
		props.handleClose()
	}

	return (
		<>
			<Dialog open={props.isDialogOpen} onClose={handleClose} className="default-campaign-dialog">
				<DialogTitle id="form-dialog-title">Select Default Campaign</DialogTitle>
				<DialogContent>
					<span className="intro-text">Select the campaign that will be pre-populated in the destination campaign input when transferring items.</span>

					<Autocomplete
						style={{ width: "100%", marginTop: "12px" }}
						disablePortal
						options={props.campaignList}
						noOptionsText="Please enter 3 characters to search..."
						getOptionLabel={option => option.name}
						renderInput={params => (
							<TextField {...params} label="Default Campaign" helperText={"Enter 3 characters to see a list of available campaigns"} />
						)}
						onInputChange={(e, value) => props.getCampaignList(value)}
						onChange={(event: any, newValue: ICampaign | null) => {
							props.setDefaultCampaign(newValue)
							props.handleClose()
						}}
						value={props.defaultCampaign}
						clearOnEscape
						PaperComponent={(props) => {
							return <Paper {...props} style={{ width: "100%" }} />;
						}}
					/>
				</DialogContent>
				<DialogActions>
					<div className="action-section">
						<div className="button-wrapper">
							<Button onClick={() => handleClose()} className="cancel">
								Cancel
							</Button>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default DefaultCampaignDialog
