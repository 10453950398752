import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material"
import React from "react"
import { ISupporterSummary } from "../../../common/interfaces/OrganisationSummary"
import useCommonFunctions from "../../../hooks/useCommonFunctions"

interface InputProps {
	topSupporters: ISupporterSummary[]
	currency: string
}

const TopSupportersSection = (props: InputProps) => {
	const isMobile = useMediaQuery("(max-width:600px)")
	const commonFunctions = useCommonFunctions()
	let currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: props.currency ?? "GBP",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
	return (
		<section className="top-supporters-section">
			<h2>Top 3 Supporters</h2>
			{props.topSupporters && props.topSupporters.length > 0 ? (
				<Card>
					<CardContent>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<div className="table-header-cell">
											<span>Name</span>
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile center">
										<div className="table-header-cell">
											<span>Bid / Purchase / Pledge Count</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center">
											<span>Gross Funds Collected</span>
										</div>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.topSupporters.sort((a, b) => b.totalBidValue - a.totalBidValue).map((supporter: ISupporterSummary) => (
									<TableRow key={supporter.userId}>
										<TableCell>{commonFunctions.titleCase(supporter.userName)}</TableCell>
										<TableCell className="hide-on-mobile">
											<div className="center">{supporter.totalBidCount}</div>
										</TableCell>										
										<TableCell>
										<div className="center">{currencyFormatter.format(supporter.totalBidValue)}</div>
											
											</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			) : (
				<Card>
					<CardContent>
						<div className="no-entries">No data found</div>
					</CardContent>
				</Card>
			)}
		</section>
	)
}

export default TopSupportersSection
