import { useState } from "react";
import ILot from "../../../interfaces/lot";
import IValidationObject from "../../../interfaces/validationObject";

const useLotValidation = (lot: ILot, orderNumberSetting: string) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([
        { name: "title", isValid: true, validationMessage: "" },
        { name: "type", isValid: true, validationMessage: "" },
        { name: "orderNumber", isValid: true, validationMessage: "" },
        { name: "reservePrice", isValid: true, validationMessage: "" },
        { name: "costPrice", isValid: true, validationMessage: "" },
        { name: "availabilityCount", isValid: true, validationMessage: "" },
        { name: "bidIncrement", isValid: true, validationMessage: "" },
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const validateInputs = (fromVMS: boolean): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "title", isValid: true, validationMessage: "" },
            { name: "type", isValid: true, validationMessage: "" },
            { name: "orderNumber", isValid: true, validationMessage: "" },
            { name: "reservePrice", isValid: true, validationMessage: "" },
            { name: "costPrice", isValid: true, validationMessage: "" },
            { name: "availabilityCount", isValid: true, validationMessage: "" },
            { name: "bidIncrement", isValid: true, validationMessage: "" }
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (lot.title === "" || typeof (lot.title) === "undefined") {
            setFailedValidation("title", "Please provide a lot title.")
        }

        if (typeof (lot.type) === "undefined") {
            setFailedValidation("type", "Please select a lot type.")
        }

        if (lot.type !== "PARENT" && (typeof (lot.costPrice) === "undefined" || lot.costPrice.toString().length < 1)) {
            setFailedValidation("costPrice", "Please enter a cost price.")
        }

        if (orderNumberSetting === "NEW" && typeof (lot.orderNumber) === "undefined") {
            setFailedValidation("orderNumber", "Please select a lot number.")
        }

        if (lot.type !== "PARENT" && (typeof (lot.reservePrice) === "undefined" || lot.reservePrice.toString().length < 1 || lot.reservePrice < 1) ){
            setFailedValidation("reservePrice", "Please set a reserve price for the lot.")
        }
        else if (fromVMS && lot.reservePrice < lot.costPrice) {
            setFailedValidation("reservePrice", "Reserve price of the lot should be greater than its cost price.")
        }

        if (lot.type !== "BUYITNOW" && lot.type !== "PARENT" && lot.type !== "SEALED" && (typeof (lot.bidIncrement) === "undefined" || lot.bidIncrement.toString().length < 1 || lot.bidIncrement < 1)) {
            setFailedValidation("bidIncrement", "Please set a bid increment for the lot.")
        }

        if ((lot.type === "BUYITNOW" || lot.type === "SEALED" || lot.type === "COMPETITIVE") && (typeof (lot.availabilityCount) === "undefined" || lot.availabilityCount && lot.availabilityCount.toString().length < 1 || lot.availabilityCount < 1)) {
            setFailedValidation("availabilityCount", "Please select the number of items available to sell.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs
    }
}

export default useLotValidation