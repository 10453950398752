import React from 'react'
import ICampaign from '../../../../interfaces/campaign'
import IValidationObject from '../../../../interfaces/validationObject'
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from 'dayjs'
import { TotaliserType } from '../../../../common/enums/TotaliserType'
import ColorInput from '../../shared/colorInput/ColorInput'

interface InputProps {
	campaign: ICampaign
	setCampaign: (campaign: ICampaign) => void
	validationObject: IValidationObject[]
}

const TotaliserSettingsSection = (props: InputProps) => {

	const handleChange = (name, value) => {
		props.setCampaign({ ...props.campaign, [name]: value })
	}

	const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		props.validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	return (
		<div className="core-settings-section">
			<h2 className="subtitle">Totaliser Settings.</h2>
			<span className="description">The settings for the floating totaliser section.</span>
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="totaliserLabel"
						name="totaliserLabel"
						label="Label"
						variant="outlined"
						defaultValue={props.campaign.totaliserLabel || ""}
						onBlur={event => handleChange(event.target.name, event.target.value)}
						required
					/>
				</div>
				
				
				<div className="field-wrapper">
					<FormControl variant="outlined">
						<InputLabel id="totaliser-type-label">Type</InputLabel>
						<Select
							labelId="totaliser-type-label"
							id="totaliserType"
							name="totaliserType"
							defaultValue={props.campaign.totaliserType || ""}
							onBlur={event => handleChange(event.target.name, event.target.value)}
							label="Type"
						>
							<MenuItem value={TotaliserType.PROFIT}>The profit made from each bid / purchase</MenuItem>
							<MenuItem value={TotaliserType.REVENUE}>The total bid / purchase value (revenue)</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="totaliserFontColor"
						label="The font colour of the totaliser"
						value={props.campaign.totaliserFontColor || ""}
						handleChange={handleChange}
						helperText="This will set the font colour of the floating totaliser section."
					/>
				</div>
				<div className='field-wrapper'>
					<ColorInput
						name="totaliserBackgroundColor"
						label="The background colour of the totaliser"
						value={props.campaign.totaliserBackgroundColor || ""}
						handleChange={handleChange}
						helperText="This will set the background colour of the floating totaliser section."
					/>
				</div>
			</Paper>
		</div>
	)
}

export default TotaliserSettingsSection