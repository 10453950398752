import React, { useContext, useEffect, useMemo, useState } from "react"
import { AppBar, Button, Divider, Drawer, MenuItem, Toolbar } from "@mui/material"
import { Link, useLocation, useHistory } from "react-router-dom"
import "./campaignMenuBar.scss"
import ICampaign from "../../interfaces/campaign"
import LoginButton from "./LoginButton"
import "./hamburgers/hamburgers.scss"
import useCommonFunctions from "../../hooks/useCommonFunctions"
import { UserContext } from "../../context/UserContext"
import LoginDialog from "../loginSignup/LoginDialog"
import ConfirmationDialog from "./ConfirmationDialog"
import AutoLogoutDialog from "../admin/shared/AutoLogoutDialog"
import { Link as MuiLink } from '@mui/material'
import { isTablet } from "react-device-detect"
import { CampaignContext } from "../../context/CampaignContext"

interface InputProps {
	campaign: ICampaign
}

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export enum DialogVariant {
	LOGGED_OUT = 'LOGGED_OUT',
	LOGGED_IN = 'LOGGED_IN',
	SIGNED_UP = 'SIGNED_UP',
}

const CampaignMenuBar = (props: InputProps) => {
	const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "SUCCESS", title: "Logged Out", message: "User successfully logged out!", errorDetails: "", variant: DialogVariant.LOGGED_OUT })
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [isLoginDialogOpen, setIsLoginDialogOpen] = useState<boolean>(false)
	const [isEnterPinDialogOpen, setIsEnterPinDialogOpen] = useState<boolean>(false)

	const campaign = useContext(CampaignContext)
	const { dBUser, auth0User, logout } = useContext(UserContext)
	const isAuthenticated = useMemo(() => (dBUser && dBUser._id), [dBUser])

	// const { loginWithRedirect } = useAuth0()

	const commonFunctions = useCommonFunctions()
	let query = useQuery()
	const history = useHistory()

	const toggleDrawer = () => event => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return
		}

		setIsDrawerOpen(!isDrawerOpen)
	}

	const determineLogoutReturnToPath = (): string => {
		let currentRoute = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]

		if (currentRoute === "my-account") {
			return `/${props.campaign.slug}`
		} else {
			return window.location.pathname
		}
	}

	useEffect(() => {
		if (props.campaign.slug) {
			//set the favicon and theme for all campaign pages
			commonFunctions.setFaviconAndThemeColor(props.campaign.faviconUrl, props.campaign.menuBarBackgroundColor, props.campaign.name)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.campaign])


	useEffect(() => {
		if (query.get('logout') !== null) {
			setDialogProperties({ type: "SUCCESS", title: "Logged Out", variant: DialogVariant.LOGGED_OUT, message: "User successfully logged out!", errorDetails: "", isOpen: true })
		}
		if (query.get('loggedIn') !== null) {
			setDialogProperties({ type: "SUCCESS", title: "Logged In", variant: DialogVariant.LOGGED_IN, message: "User successfully logged in!", errorDetails: "", isOpen: true })
		}
		if (query.get('signedUp') !== null) {
			setDialogProperties({ type: "SUCCESS", title: "Signed Up", variant: DialogVariant.SIGNED_UP, message: "User successfully registered!", errorDetails: "", isOpen: true })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query])

	return (
		<div className="campaign-menu-bar-wrapper">
			{props.campaign && props.campaign.slug && (
				<div
					className="campaign-menu-bar"
					style={
						{
							"--menu-bar-font-color": props.campaign.menuBarFontColor,
							"--menu-bar-background-color": props.campaign.menuBarBackgroundColor,
						} as React.CSSProperties
					}
				>
					<AppBar>
						<Toolbar style={{ backgroundColor: props.campaign.menuBarBackgroundColor }}>
							<div className="logo-wrapper">
								{props.campaign.hostingOrganisationLogoUrl && (
									<Link to={`/${props.campaign.slug}`}>
										<img alt="logo" src={props.campaign.hostingOrganisationLogoUrl} />
									</Link>
								)}
							</div>
							<div className="menu-items">
								<ul>
									<li>
										<Link to={`/${props.campaign.slug}/`} style={{ color: props.campaign.menuBarFontColor }}>
											Home
										</Link>
									</li>
									{props.campaign.slug === "xmas23" &&
										<li>
											<Link to={`/${props.campaign.slug}/lotto`} style={{ color: props.campaign.menuBarFontColor }}>
												Lotto
											</Link>
										</li>
									}
									{props.campaign.faqList && props.campaign.faqList.length > 0 && (
										<li>
											<Link to={`/${props.campaign.slug}/faqs`} style={{ color: props.campaign.menuBarFontColor }}>
												FAQs
											</Link>
										</li>
									)}
									<li>
										<LoginButton
											campaignId={props.campaign._id}
											campaignSlug={props.campaign.slug}
											organisationId={props.campaign.organisationId}
											color={props.campaign.menuBarFontColor}
											determineLogoutReturnToPath={determineLogoutReturnToPath}
											setIsLoginDialogOpen={setIsLoginDialogOpen}
										/>
									</li>
								</ul>

								<div className="lots-button-wrapper">
									<Link to={`/${props.campaign.slug}/lots`}>
										<Button
											className="gg-button"
											style={
												{
													color: props.campaign.buttonFontColor,
													"--background-color": props.campaign.buttonBackgroundColor,
												} as React.CSSProperties
											}
										>
											{props.campaign.buttonText ? props.campaign.buttonText : "View the lots"}
										</Button>
									</Link>
								</div>
							</div>
							<div className="hamburger-menu-wrapper">
								<div className="lots-button-wrapper">
									<Link to={`/${props.campaign.slug}/lots`}>
										<Button
											className="gg-button"
											style={
												{
													color: props.campaign.buttonFontColor,
													"--background-color": props.campaign.buttonBackgroundColor,
												} as React.CSSProperties
											}
										>
											{props.campaign.buttonText ? props.campaign.buttonText : "View the lots"}
										</Button>
									</Link>
								</div>
								<div className="clickable-icon hamburger-menu" onClick={toggleDrawer()}>
									<button
										className={isDrawerOpen ? "hamburger hamburger--spin is-active" : "hamburger hamburger--squeeze"}
										type="button"
									>
										<span className="hamburger-box" style={{ color: props.campaign.menuBarFontColor }}>
											<span className="hamburger-inner" style={{ color: props.campaign.menuBarFontColor }}></span>
										</span>
									</button>
								</div>
							</div>
						</Toolbar>
					</AppBar>
					<Drawer
						anchor="top"
						open={isDrawerOpen}
						onClose={toggleDrawer()}
						className="menu-drawer"
						style={{ "--menu-bar-background-color": props.campaign.menuBarBackgroundColor } as React.CSSProperties}
					>
						<Link to={`/${props.campaign.slug}/`} style={{ color: props.campaign.menuBarFontColor }}>
							<MenuItem
								onClick={() => {
									setIsDrawerOpen(false)
								}}
							>
								Home
							</MenuItem>
						</Link>
						<Link to={`/${props.campaign.slug}/lots`} style={{ color: props.campaign.menuBarFontColor }}>
							<MenuItem
								onClick={() => {
									setIsDrawerOpen(false)
								}}
							>
								Silent Auction
							</MenuItem>
						</Link>
						{props.campaign.slug === "xmas23" &&
							<Link to={`/${props.campaign.slug}/lotto`} style={{ color: props.campaign.menuBarFontColor }}>
								<MenuItem
									onClick={() => {
										setIsDrawerOpen(false)
									}}
								>
									Lotto
								</MenuItem>
							</Link>
						}
						<Divider variant="middle" style={{ borderColor: props.campaign.menuBarFontColor }} />
						{props.campaign.faqList && props.campaign.faqList.length > 0 && (
							<Link to={`/${props.campaign.slug}/faqs`} style={{ color: props.campaign.menuBarFontColor }}>
								<MenuItem
									onClick={() => {
										setIsDrawerOpen(false)
									}}
								>
									FAQs
								</MenuItem>
							</Link>
						)}

						{isAuthenticated ? (
							<>
								<MuiLink onClick={() => {
									if (isTablet) {
										setIsEnterPinDialogOpen(true)
									} else {
										history.push(`/${props.campaign.slug}/my-account`)
									}
								}} style={{ color: props.campaign.menuBarFontColor }}>
									<MenuItem
										onClick={() => {
											setIsDrawerOpen(false)
										}}
									>
										My Account
									</MenuItem>
								</MuiLink>
								<a style={{ color: props.campaign.menuBarFontColor }}>
									<MenuItem
										onClick={() => {
											setDialogProperties({ ...dialogProperties, isOpen: true })
											setIsDrawerOpen(false)
											logout()
										}
										}
									>
										Log Out
									</MenuItem>
								</a>
							</>
						) : (
							<a style={{ color: props.campaign.menuBarFontColor }}>
								<MenuItem
									onClick={() => {
										setIsDrawerOpen(false)
										setIsLoginDialogOpen(true)
										localStorage.setItem('redirectUri', window.location.pathname)
									}}
								>
									Log In / Sign Up
								</MenuItem>
							</a>
						)}
					</Drawer>
				</div>

			)}

			<LoginDialog
				isDialogOpen={isLoginDialogOpen}
				handleClose={() => setIsLoginDialogOpen(false)}
			/>

			{dialogProperties.isOpen && (
				<ConfirmationDialog
					handleClose={() => setDialogProperties({ ...dialogProperties, isOpen: false })}
					isDialogOpen={dialogProperties.isOpen}
					message={dialogProperties.message}
					title={dialogProperties.title}
					type={dialogProperties.type}
					label={dialogProperties.label}
					primaryButton={
						<Button onClick={() => {
							setDialogProperties({ ...dialogProperties, isOpen: false })
							history.push(window.location.pathname.split(/[?#]/)[0])
						}} id="timer-button" className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
							{`Ok${[DialogVariant.LOGGED_IN, DialogVariant.LOGGED_OUT].includes(dialogProperties.variant) ? ' (3)' : ''}`}
						</Button>
					}
					countdownTimerSeconds={[DialogVariant.LOGGED_IN, DialogVariant.LOGGED_OUT].includes(dialogProperties.variant) ? 3 : null}
					redirectUrl={[DialogVariant.LOGGED_OUT].includes(dialogProperties.variant) ? `/${campaign.slug}/lots` : null}
				/>
			)}


			{isTablet && (
				<AutoLogoutDialog
					isDialogOpen={isEnterPinDialogOpen}
					handleClose={(isPINCorrect?: boolean) => {
						setIsEnterPinDialogOpen(false)
						if (isPINCorrect) {
							history.push(`/${props.campaign.slug}/my-account`)
						}
					}}
					secondsRemaining={0}
					handleStillHere={() => { }}
					isAutoLogout={false}
				/>
			)}
		</div>
	)
}

export default CampaignMenuBar
