import React, { useContext, useState } from 'react'
import { IWinnerBidAndLot } from '../../../../../interfaces/winningBidResponse'
import { Button, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Tooltip } from '@mui/material'
import ICampaign from '../../../../../interfaces/campaign'
import PaidIcon from "@mui/icons-material/Paid"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"
import InfoIcon from "@mui/icons-material/Info"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"
import useFetch from '../../../../../hooks/useFetch'
import { API_PATHS, BID_API_PATHS, STRIPE_API_PATHS } from '../../../../../common/ApiPaths'
import { IWinner } from '../../../../../interfaces/winner'
import IBid from '../../../../../interfaces/bid'
import moment from 'moment'
import BidDetailsDialog from '../../../../shared/BidDetailsDialog'
import ConfirmationDialog from '../../../../shared/ConfirmationDialog'
import useCommonFunctions from '../../../../../hooks/useCommonFunctions'
import { UserContext } from '../../../../../context/UserContext'
import { OrganisationContext } from '../../../../../context/OrganisationContext'
import MoneyIcon from '@mui/icons-material/Money';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ManualPaymentDialog from './ManualPaymentDialog'
import IStripePayment from '../../../../../interfaces/stripePayment'
import ManualVoucherDialog from './ManualVoucherDialog'
import ICatalogueDetails from '../../../../../interfaces/catalogueDetails'
import { AuditLogCategories } from '../../../../../common/enums/AuditLogCategories'
import { AuditLogDescriptions } from '../../../../../common/enums/AuditLogDescriptions'

const LotsWonActions = (
    { bidAndLot,
        isPaymentInProgress,
        campaign,
        isPurchaseReport,
        isVoucherInProgress,
        setIsVoucherInProgress,
        winner,
        winnerList,
        setWinnerList,
        setDialogProperties,
        generateWinnerList,
        setIsPaymentInProgress
    }:
        {
            bidAndLot: IWinnerBidAndLot,
            isPaymentInProgress: boolean,
            campaign: ICampaign,
            isPurchaseReport: boolean,
            isVoucherInProgress: boolean,
            setIsVoucherInProgress: (isVoucherInProgress: boolean) => void,
            winner: IWinner,
            winnerList: IWinner[],
            setWinnerList: (winnerList: IWinner[]) => void
            setDialogProperties: (dialogProperties: any) => void
            generateWinnerList: () => Promise<IWinner[]>
            setIsPaymentInProgress: (boolean: boolean) => void


        }
) => {

    const [bidDetailsDialogObject, setBidDetailsDialogObject] = useState<any>({ isOpen: false })
    const [isManualPaymentDialogOpen, setIsManualPaymentDialogOpen] = useState<boolean>(false)
    const [confirmationDialogProperties, setConfirmationDialogProperties] = useState<any>({
        isOpen: false,
        type: "",
        version: "",
        title: "",
        message: "",
    })
    const [itemToBeCancelled, setItemToBeCancelled] = useState<IWinnerBidAndLot>({} as IWinnerBidAndLot)
    const [itemToBePaidManually, setItemToBePaidManually] = useState<IWinnerBidAndLot>({} as IWinnerBidAndLot)
    const [isItemActionsSelectOpen, setIsItemActionsSelectOpen] = useState<boolean>(false);
    const [isManualVoucherDialogOpen, setIsManualVoucherDialogOpen] = useState<boolean>(false)
    const [itemToBeVoucherGeneratedManually, setItemToBeVoucherGeneratedManually] = useState<IWinnerBidAndLot>({} as IWinnerBidAndLot)



    const voucherApi = useFetch(API_PATHS.VOUCHER)
    const stripeApi = useFetch(API_PATHS.STRIPE)
    const bidsApi = useFetch(API_PATHS.BIDS)
    const auditLogApi = useFetch(API_PATHS.AUDITLOG)
    const commonFunctions = useCommonFunctions()
    const { dBUser } = useContext(UserContext)
    const organisation = useContext(OrganisationContext)

    const determineTooltip = (bidAndLot: IWinnerBidAndLot, type: "CANCEL" | "PAY" | "MANUAL_PAY"): string => {
        let tooltip = ""

        if (type === "CANCEL") {
            tooltip = "Cancel Item - "
        } else if (type === "PAY") {
            tooltip = "Take Payment - "
        } else if (type === "MANUAL_PAY") {
            tooltip = "Mark as Paid - "
        }

        if (bidAndLot.winningBid && bidAndLot.winningBid.paymentRefId) {
            tooltip += "Payment Already Taken."
        } else if (bidAndLot.isLoading) {
            tooltip += "Loading."
        } else if (bidAndLot.winningBid.isCancelled) {
            tooltip += "Item Cancelled."
        } else if (isPaymentInProgress) {
            tooltip += "Payment in Progress."
        }

        return tooltip
    }

    const generateVoucher = async (bidAndLot: IWinnerBidAndLot) => {
        let bidsWithVoucherIds = bidAndLot.winningBid.bidList.filter(b => b.catalogue && b.catalogue?.voucherId && b.catalogue.voucherId !== null)

        if (bidsWithVoucherIds.length === 0) {
            setIsVoucherInProgress(true)
            let updatedWinnerItems = winner.winnerItems.map(w => {
                w.bidAndLotList.forEach(b => {
                    if (b.winningLot._id === bidAndLot.winningLot._id) {
                        b.isVoucherLoading = true
                    }
                })
                return w
            })
            let updatedWinnerList = winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
            setWinnerList(updatedWinnerList)

            let catalogue = organisation.integrationList.find(integration => integration.id === bidAndLot.winningLot.catalogue.integrationId)

            if (catalogue) {
                try {
                    let response = await voucherApi.post(``, {
                        itemId: bidAndLot.winningLot.catalogue.itemId,
                        firstName: winner.campaignUser.firstName,
                        lastName: winner.campaignUser.surname,
                        email: winner.campaignUser.email,
                        telephone: winner.campaignUser.phone,
                        amountBid: bidAndLot.winningBid.totalValue,
                        apiUrl: catalogue.apiUrl,
                        apiKey: catalogue.apiKey,
                        organisationId: organisation._id,
                        campaignId: campaign._id,
                        eventName: `Going Gone - ${organisation.name} - ${campaign.name} - ${moment(campaign.campaignDate).format("DD/MM/yyyy")}`,
                        userId: bidAndLot.winningBid.userId,
                    })

                    if (response.voucher_id) {
                        let updatedBid: IBid = await bidsApi.put(BID_API_PATHS.UPDATE_BID.replace("{bidId}", bidAndLot.winningBid.bidList[0]._id), [{
                            ...bidAndLot.winningBid.bidList[0],
                            catalogue: {
                                ...bidAndLot.winningBid.bidList[0].catalogue,
                                voucherId: response.voucher_id,
                            },
                        }])
                    } else {
                        let updatedWinnerItems = winner.winnerItems.map(w => {
                            w.bidAndLotList.forEach(b => {
                                if (b.winningLot._id === bidAndLot.winningLot._id) {
                                    b.isVoucherLoading = false
                                }
                            })
                            return w
                        })

                        let updatedWinnerList = winnerList.map(x => (x.id === winner.id ? { ...x, winnerItems: updatedWinnerItems } : x))
                        setWinnerList(updatedWinnerList)
                        setDialogProperties({
                            isOpen: true,
                            type: "ERROR",
                            title: "Error",
                            message: "Voucher Generation Failed. Check the audit log for details.",
                        })
                    }
                    setIsVoucherInProgress(false)
                } catch (err) {
                    setDialogProperties({
                        isOpen: true,
                        type: "ERROR",
                        version: "",
                        title: "Error",
                        message: err,
                    })

                    let updatedWinnerItems = winner.winnerItems.map(w => {
                        w.bidAndLotList.forEach(b => {
                            if (b.winningLot._id === bidAndLot.winningLot._id) {
                                b.isVoucherLoading = false
                            }
                        })
                        return w
                    })
                    let updatedWinnerList = winnerList.map(x =>
                        x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x
                    )
                    setWinnerList(updatedWinnerList)
                    setDialogProperties({ isOpen: true, type: "ERROR", title: "Error", message: "Check the audit log for details." })
                    setIsVoucherInProgress(false)
                } finally {
                    generateWinnerList()
                }
            } else {
                let updatedWinnerItems = winner.winnerItems.map(w => {
                    w.bidAndLotList.forEach(b => {
                        if (b.winningLot._id === bidAndLot.winningLot._id) {
                            b.isVoucherLoading = false
                        }
                    })
                    return w
                })
                let updatedWinnerList = winnerList.map(x =>
                    x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x
                )
                setWinnerList(updatedWinnerList)
                setIsVoucherInProgress(false)

                setDialogProperties({
                    isOpen: true,
                    type: "ERROR",
                    version: "",
                    title: "Integration Error",
                    message: "The system from which this lot was copied cannot be found. No voucher can be created.",
                })

            }
        }
    }

    const takePayment = async (bidAndLot: IWinnerBidAndLot) => {
        if (!bidAndLot.winningBid.isCancelled && !isPaymentInProgress) {
            setIsPaymentInProgress(true)
            let updatedWinnerItems = winner.winnerItems.map(w => {
                w.bidAndLotList.forEach(b => {
                    if (b.winningLot._id === bidAndLot.winningLot._id) {
                        b.isLoading = true
                    }
                })
                return w
            })
            let updatedWinnerList = winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
            setWinnerList(updatedWinnerList)

            try {
                let selectedBidIds = bidAndLot.winningBid.bidList.map(x => x._id)
                let updatedWinner: IWinner = await stripeApi.post(STRIPE_API_PATHS.CHARGE_SAVED_CARDS, {
                    paymentRequestBidIdList: selectedBidIds,
                    winner: winner,
                    campaignId: campaign._id,
                    campaignName: campaign.name,
                    organisationId: campaign.organisationId,
                }, dBUser._id, true)
                if (updatedWinner && updatedWinner.id) {
                    await generateWinnerList()
                    setIsPaymentInProgress(false)
                } else {
                    let updatedWinnerItems = winner.winnerItems.map(w => {
                        w.bidAndLotList.forEach(b => {
                            if (b.winningLot._id === bidAndLot.winningLot._id) {
                                b.isLoading = false
                            }
                        })
                        return w
                    })
                    let updatedWinnerList = winnerList.map(x =>
                        x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x
                    )
                    setWinnerList(updatedWinnerList)
                    setDialogProperties({
                        isOpen: true,
                        type: "ERROR",
                        title: "Error",
                        message: (updatedWinner as any).statusCode === 400 ? "There was an issue in taking payment. Please contact the administrator." : "Payment Failed! Check the audit log for details",
                    })
                    setIsPaymentInProgress(false)
                }
            } catch (err) {
                let updatedWinnerItems = winner.winnerItems.map(w => {
                    w.bidAndLotList.forEach(b => {
                        if (b.winningLot._id === bidAndLot.winningLot._id) {
                            b.isLoading = false
                        }
                    })
                    return w
                })
                let updatedWinnerList = winnerList.map(x =>
                    x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x
                )
                setWinnerList(updatedWinnerList)
                setDialogProperties({ isOpen: true, type: "ERROR", title: "Error", message: "Check the audit log for details" })
                setIsPaymentInProgress(false)
            }
        }
    }

    const openBidDetailDialog = (bidAndLot: IWinnerBidAndLot) => {
        setIsItemActionsSelectOpen(false)
        setBidDetailsDialogObject({
            isOpen: true,
            bid: bidAndLot.winningBid.bidList[0],
            lot: bidAndLot.winningLot,
        })
    }

    const openConfirmationDialog = (bidAndLot: IWinnerBidAndLot) => {
        setItemToBeCancelled(bidAndLot)
        setConfirmationDialogProperties({
            isOpen: true,
            type: "AREYOUSURE",
            version: "Cancel",
            title: "Are You Sure?",
            message: "Are you sure you want to cancel this item. This action cannot be undone.",
        })
    }

    const handleCloseConfirmationDialog = (isConfirmed: boolean) => {
        if (confirmationDialogProperties.version === "Cancel" && isConfirmed) {
            cancelItem(itemToBeCancelled)
        }

        setConfirmationDialogProperties({ isOpen: false, type: "", version: "", title: "", message: "" })
    }

    const cancelItem = async (bidAndLot: IWinnerBidAndLot) => {
        let updatedWinnerItems = winner.winnerItems.map(w => {
            w.bidAndLotList.forEach(b => {
                if (b.winningLot._id === bidAndLot.winningLot._id) {
                    b.isLoading = true

                    b.winningBid.isCancelled = true
                    b.winningBid.bidList.forEach(bid => {
                        bid.isCancelled = true
                    })
                }
            })
            return w
        })
        let updatedWinnerList = winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
        setWinnerList(updatedWinnerList)

        try {
            await bidsApi.put(
                BID_API_PATHS.UPDATE_BID,
                bidAndLot.winningBid.bidList.map(x => {
                    return { ...x, isCancelled: true }
                }),
                dBUser._id
            )

            if (campaign.paymentMethod === "PAYMENT-LINK") {
                const connectedAccountId = dBUser.stripeConnectedAccountList.find(x => x.organisationId === organisation._id)?.connectedAccountId || null

                if (connectedAccountId) {
                    let data = await stripeApi.put(`${STRIPE_API_PATHS.UPDATE_PAYMENT_LINK}`, {
                        winner: updatedWinnerList.find(x => x.id === winner.id),
                        campaignId: campaign._id,
                        currency: campaign.currency,
                        paymentLinkId: winner.stripePaymentLink.paymentLinkId,
                        organisationId: organisation._id,
                        revenueFeePercentage: organisation.revenueFeePercentage,
                        stripeConnectedAccountId: connectedAccountId
                    })

                    updatedWinnerItems = winner.winnerItems.map(w => {
                        w.bidAndLotList.forEach(b => {
                            b.winningBid.stripePaymentLink.paymentLinkId = data.stripePaymentLink.paymentLinkId
                            b.winningBid.stripePaymentLink.paymentLinkUrl = data.stripePaymentLink.paymentLinkUrl
                            
                            b.winningBid.bidList.forEach(bid => {
                                bid.stripePayment!.stripePaymentLink.paymentLinkId = data.stripePaymentLink.paymentLinkId
                                bid.stripePayment!.stripePaymentLink.paymentLinkUrl = data.stripePaymentLink.paymentLinkUrl
                            })
                        })
                        return w
                    })
                                        
                    updatedWinnerList = winnerList.map(x => (x.id === winner.id ? { ...winner, stripePaymentLink: { ...winner.stripePaymentLink, paymentLinkId: data.stripePaymentLink.paymentLinkId, paymentLinkUrl: data.stripePaymentLink.paymentLinkUrl }, winnerItems: updatedWinnerItems } : x))
                    setWinnerList(updatedWinnerList)
                }
            }

            const auditLogEntry = await auditLogApi.post("", {
                userId: bidAndLot.winningBid.userId,
                campaignId: campaign._id,
                organisationId: organisation._id,
                category: AuditLogCategories.PAYMENT,
                description: AuditLogDescriptions.WINNER_ITEM_CANCELLED,
                reference: `Lot: ${bidAndLot.winningLot.title}, Actioned by: ${dBUser.firstName} ${dBUser.surname}`,
                timestamp: new Date(),
                isError: false
            })

            //add the audit log entry to memory
            winner.auditLog.push(auditLogEntry)
            let updatedWinnerListWithAuditLog = winnerList.map(x => (x.id === winner.id ? { ...winner } : x))
            setWinnerList(updatedWinnerListWithAuditLog)

        } catch (err: any) {
            setDialogProperties({ isOpen: true, type: "ERROR", title: "Error", message: err.message })
        } finally {
            updatedWinnerItems = winner.winnerItems.map(w => {
                w.bidAndLotList.forEach(b => {
                    if (b.winningLot._id === bidAndLot.winningLot._id) {
                        b.isLoading = false
                    }
                })
                return w
            })
            updatedWinnerList = updatedWinnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
            setWinnerList(updatedWinnerList)
        }
    }

    const openManualPaymentDialog = (bidAndLot: IWinnerBidAndLot) => {
        setIsItemActionsSelectOpen(false)
        setItemToBePaidManually(bidAndLot)
        setIsManualPaymentDialogOpen(true)
    }

    const handleCloseManualPaymentDialog = (boolean: boolean, manualPaymentRef: string) => {
        if (boolean) {
            markItemPaidManually(itemToBePaidManually, manualPaymentRef)
        }

        setIsManualPaymentDialogOpen(false)
    }

    const markItemPaidManually = async (bidAndLot: IWinnerBidAndLot, manualPaymentRef: string) => {

        const fullManualPaymentRef = `MANUAL_${new Date().getTime()}${manualPaymentRef && `_${manualPaymentRef}`}`

        bidAndLot.winningBid.paymentRefId = fullManualPaymentRef
        bidAndLot.winningBid.bidList = bidAndLot.winningBid.bidList.map(x => { return { ...x, stripePayment: { ...x.stripePayment, paymentRefId: fullManualPaymentRef } as IStripePayment } })

        let updatedWinnerItems = winner.winnerItems.map(w => {
            w.bidAndLotList.forEach(b => {
                if (b.winningLot._id === bidAndLot.winningLot._id) {
                    b.isLoading = true
                    b.winningBid = bidAndLot.winningBid
                }
            })
            return w
        })
        let updatedWinnerList = winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
        setWinnerList(updatedWinnerList)

        try {

            await bidsApi.put(
                BID_API_PATHS.UPDATE_BID,

                bidAndLot.winningBid.bidList.map(x => {
                    return { ...x, stripePayment: { ...x.stripePayment, paymentRefId: fullManualPaymentRef } }
                }),
                dBUser._id
            )

            if (campaign.paymentMethod === "PAYMENT-LINK") {
                const connectedAccountId = dBUser.stripeConnectedAccountList.find(x => x.organisationId === organisation._id)?.connectedAccountId || null

                if (connectedAccountId) {
                    let data = await stripeApi.put(`${STRIPE_API_PATHS.UPDATE_PAYMENT_LINK}`, {
                        winner: updatedWinnerList.find(x => x.id === winner.id),
                        campaignId: campaign._id,
                        currency: campaign.currency,
                        paymentLinkId: winner.stripePaymentLink.paymentLinkId,
                        organisationId: organisation._id,
                        revenueFeePercentage: organisation.revenueFeePercentage,
                        stripeConnectedAccountId: connectedAccountId
                    })

                    updatedWinnerItems = winner.winnerItems.map(w => {
                        w.bidAndLotList.forEach(b => {
                            b.winningBid.stripePaymentLink.paymentLinkId = data.stripePaymentLink.paymentLinkId
                            b.winningBid.stripePaymentLink.paymentLinkUrl = data.stripePaymentLink.paymentLinkUrl
                            
                            b.winningBid.bidList.forEach(bid => {
                                bid.stripePayment!.stripePaymentLink.paymentLinkId = data.stripePaymentLink.paymentLinkId
                                bid.stripePayment!.stripePaymentLink.paymentLinkUrl = data.stripePaymentLink.paymentLinkUrl
                            })
                        })
                        return w
                    })

                    console.log("updatedWinnerItems", updatedWinnerItems)
                }
            }


            const auditLogEntry = await auditLogApi.post("", {
                userId: bidAndLot.winningBid.userId,
                campaignId: campaign._id,
                organisationId: organisation._id,
                category: AuditLogCategories.PAYMENT,
                description: AuditLogDescriptions.PAYMENT_MANUAL_CREATE,
                reference: `Manual Payment Ref: ${fullManualPaymentRef}, Lot: ${bidAndLot.winningLot.title}, Actioned by: ${dBUser.firstName} ${dBUser.surname}`,
                timestamp: new Date(),
                isError: false
            })

            //add the audit log entry to memory
            winner.auditLog.push(auditLogEntry)
            let updatedWinnerListWithAuditLog = winnerList.map(x => (x.id === winner.id ? { ...winner } : x))

            setWinnerList(updatedWinnerListWithAuditLog)

        } catch (err: any) {
            setDialogProperties({ isOpen: true, type: "ERROR", title: "Error", message: err.message })
        } finally {
            updatedWinnerItems = winner.winnerItems.map(w => {
                w.bidAndLotList.forEach(b => {
                    if (b.winningLot._id === bidAndLot.winningLot._id) {
                        b.isLoading = false
                    }
                })
                return w
            })
            updatedWinnerList = updatedWinnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))
            setWinnerList(updatedWinnerList)
        }
    }

    const handleClose = () => {
        setIsItemActionsSelectOpen(false);
    };

    const handleOpen = () => {
        setIsItemActionsSelectOpen(true);
    };

    const openManualVoucherDialog = (bidAndLot: IWinnerBidAndLot) => {
        setIsItemActionsSelectOpen(false)
        setItemToBeVoucherGeneratedManually(bidAndLot)
        setIsManualVoucherDialogOpen(true)
    }


    const handleCloseManualVoucherDialog = (boolean: boolean, manualVoucherRef: string) => {
        if (boolean) {
            markItemVoucherGeneratedManually(itemToBeVoucherGeneratedManually, manualVoucherRef)
        }

        setIsManualVoucherDialogOpen(false)
    }

    const markItemVoucherGeneratedManually = async (bidAndLot: IWinnerBidAndLot, manualVoucherRef: string) => {

        const fullManualVoucherRef = `MANUAL_${new Date().getTime()}${manualVoucherRef && `_${manualVoucherRef}`}`

        bidAndLot.winningBid.bidList = bidAndLot.winningBid.bidList.map(x => { return { ...x, catalogue: { ...x.catalogue, voucherId: fullManualVoucherRef } as ICatalogueDetails } })

        let updatedWinnerItems = winner.winnerItems.map(w => {
            w.bidAndLotList.forEach(b => {
                if (b.winningLot._id === bidAndLot.winningLot._id) {
                    b.winningBid = bidAndLot.winningBid
                }
            })
            return w
        })
        let updatedWinnerList = winnerList.map(x => (x.id === winner.id ? { ...winner, winnerItems: updatedWinnerItems } : x))

        try {

            await bidsApi.put(
                BID_API_PATHS.UPDATE_BID,
                bidAndLot.winningBid.bidList,
                dBUser._id
            )

            const auditLogEntry = await auditLogApi.post("", {
                userId: bidAndLot.winningBid.userId,
                campaignId: campaign._id,
                organisationId: organisation._id,
                category: AuditLogCategories.VOUCHER,
                description: AuditLogDescriptions.VOUCHER_MANUAL_CREATE,
                reference: `Ref: ${fullManualVoucherRef}, Lot: ${bidAndLot.winningLot.title}, Actioned By: ${dBUser.firstName} ${dBUser.surname}`,
                timestamp: new Date(),
                isError: false
            })

            //add the audit log entry to memory
            winner.auditLog.push(auditLogEntry)
            updatedWinnerList = updatedWinnerList.map(x => (x.id === winner.id ? { ...winner } : x))

            setWinnerList(updatedWinnerList)

        } catch (err: any) {
            setDialogProperties({ isOpen: true, type: "ERROR", title: "Error", message: err.message })
        }
    }


    return (
        <>
            <span className="center">
                <div className="action-cell" style={{ paddingBlock: 6 }}>
                    <FormControl className="item-actions hide-on-mobile">
                        <InputLabel>{`Item Actions`}</InputLabel>
                        <Select
                            id="itemActions"
                            name="itemActions"
                            value={""}
                            label={`Item Actions`}
                            style={{ width: 180 }}
                            open={isItemActionsSelectOpen}
                            onClose={handleClose}
                            onOpen={handleOpen}
                        >
                            {(bidAndLot.winningBid && bidAndLot.winningBid.paymentRefId) ||
                                bidAndLot.isLoading ||
                                bidAndLot.winningBid.isCancelled ||
                                isPaymentInProgress ? (
                                <div>
                                    {campaign.paymentMethod === "PRE-AUTH" && !isPurchaseReport && (
                                        <div>
                                            <MenuItem disabled>
                                                <ListItemIcon>
                                                    <PaidIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={determineTooltip(bidAndLot, "PAY")} />
                                            </MenuItem>
                                        </div>
                                    )}
                                    {!isPurchaseReport && (
                                        <div>
                                            <MenuItem disabled>
                                                <ListItemIcon>
                                                    <MoneyIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={determineTooltip(bidAndLot, "MANUAL_PAY")} />
                                            </MenuItem>
                                        </div>
                                    )}

                                    {bidAndLot.winningLot.catalogue &&
                                        bidAndLot.winningLot.catalogue.itemId &&
                                        bidAndLot.winningLot.catalogue.integrationId &&

                                        <div>
                                            <MenuItem onClick={() => generateVoucher(bidAndLot)}>
                                                <ListItemIcon>
                                                    <ConfirmationNumberIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Generate Voucher" />
                                            </MenuItem>
                                            <MenuItem onClick={() => openManualVoucherDialog(bidAndLot)}>
                                                <ListItemIcon>
                                                    <EditNoteIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Mark Voucher as Generated Manually" />
                                            </MenuItem>
                                        </div>
                                    }
                                    <MenuItem onClick={() => openBidDetailDialog(bidAndLot)}>
                                        <ListItemIcon>
                                            <InfoIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="View Bid Details" />
                                    </MenuItem>
                                    <div>
                                        <MenuItem disabled>
                                            <ListItemIcon>
                                                <PaidIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={determineTooltip(bidAndLot, "CANCEL")} />
                                        </MenuItem>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {campaign.paymentMethod === "PRE-AUTH" && !isPurchaseReport && (
                                        <div onClick={() => takePayment(bidAndLot)}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <PaidIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Take Stripe Payment for Item" />
                                            </MenuItem>
                                        </div>
                                    )}
                                    {!isPurchaseReport && (
                                        <div onClick={() => openManualPaymentDialog(bidAndLot)}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <MoneyIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Mark Item as Paid Manually" />
                                            </MenuItem>
                                        </div>
                                    )}

                                    {bidAndLot.winningLot.catalogue &&
                                        bidAndLot.winningLot.catalogue.itemId &&
                                        bidAndLot.winningLot.catalogue.integrationId &&
                                        <div>
                                            <MenuItem disabled>
                                                <ListItemIcon>
                                                    <ConfirmationNumberIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Generate Voucher (Payment must be complete)" />
                                            </MenuItem>
                                            <MenuItem disabled>
                                                <ListItemIcon>
                                                    <EditNoteIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Mark Voucher as Generated Manually (Payment must be complete)" />
                                            </MenuItem>
                                        </div>
                                    }
                                    {/*  ))} */}
                                    <div onClick={() => openBidDetailDialog(bidAndLot)}>
                                        <MenuItem >
                                            <ListItemIcon>
                                                <InfoIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="View Bid Details" />
                                        </MenuItem>
                                    </div>
                                    <div onClick={() => openConfirmationDialog(bidAndLot)}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <DoDisturbOnIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Cancel Item" />
                                        </MenuItem>
                                    </div>
                                </div>
                            )}


                        </Select>
                    </FormControl>
                </div>
            </span>
            {bidDetailsDialogObject.bid && bidDetailsDialogObject.lot && (
                <BidDetailsDialog
                    isDialogOpen={bidDetailsDialogObject.isOpen}
                    handleClose={() =>
                        setBidDetailsDialogObject({
                            isOpen: false,
                        })
                    }
                    bid={bidDetailsDialogObject.bid}
                    lot={bidDetailsDialogObject.lot}
                    campaign={campaign}
                />
            )}

            <ConfirmationDialog
                handleClose={handleCloseConfirmationDialog}
                isDialogOpen={confirmationDialogProperties.isOpen}
                message={confirmationDialogProperties.message}
                title={confirmationDialogProperties.title}
                type={confirmationDialogProperties.type}
                label={confirmationDialogProperties.label}
                primaryButton={<Button onClick={() => handleCloseConfirmationDialog(true)} className="gg-button" style={commonFunctions.determineButtonColor(confirmationDialogProperties.type)}>
                    Ok
                </Button>}
            />
            <ManualPaymentDialog
                isDialogOpen={isManualPaymentDialogOpen}
                handleClose={handleCloseManualPaymentDialog}
            />
            <ManualVoucherDialog
                isDialogOpen={isManualVoucherDialogOpen}
                handleClose={handleCloseManualVoucherDialog}
            />
        </>
    )
}

export default LotsWonActions