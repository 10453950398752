import React, { useEffect, useState } from 'react'
import "./home.scss"
import GGLogo from "../../images/GG_RGB_Teal&Navy.png"
import { Button, Card } from '@mui/material'
import ConfirmationDialog from '../shared/ConfirmationDialog'
import useCommonFunctions from '../../hooks/useCommonFunctions'

const Home = ({ location }) => {

    const [confirmationDialogProperties, setConfirmationDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "" })
    const commonFunctions=useCommonFunctions()
    
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get("email-confirmed") === 'true') {
            setConfirmationDialogProperties({
                isOpen: true,
                type: "SUCCESS",
                title: "Email Address Confirmed",
                message: "Your email address has been confirmed. You may now close this page and return to the campaign."
            })
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    return (
        <>
            <div className="home flex-container">
                <Card className="home-card">
                    <Card className="image-card">
                        <img className="logo" alt="logo" src={GGLogo} />
                    </Card>
                    <h2>Welcome to Going Gone Campaigns</h2>
                    <span>Please navigate to the correct campaign using the link provided by the campaign organiser.</span>
                </Card>
            </div>
            <ConfirmationDialog
                handleClose={() => setConfirmationDialogProperties({ isOpen: false })}
                isDialogOpen={confirmationDialogProperties.isOpen}
                message={confirmationDialogProperties.message}
                title={confirmationDialogProperties.title}
                type={confirmationDialogProperties.type}
                label={confirmationDialogProperties.label}
                primaryButton={<Button onClick={() => setConfirmationDialogProperties({ isOpen: false })} className="gg-button" style={commonFunctions.determineButtonColor(confirmationDialogProperties.type)}>
					Ok
				</Button>}
            />
        </>
    )
}

export default Home