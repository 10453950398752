import React from 'react'
import ICampaign from '../../../../interfaces/campaign'
import { Paper, TextField } from '@mui/material'

interface InputProps {
	campaign: ICampaign
	setCampaign: (campaign: ICampaign) => void
}

const FooterContentSection = (props: InputProps) => {

	const handleChange = (name, value) => {
		props.setCampaign({ ...props.campaign, [name]: value })
	}


	return (
		<div className="footer-content-section">
			<h2 className="subtitle">Footer Content.</h2>
			<span className="description">The content included in the footer section.</span>
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="hostingOrganisationName"
						name="hostingOrganisationName"
						label="Company Name"
						variant="outlined"
						defaultValue={props.campaign.hostingOrganisationName || ""}
						onBlur={(event) => handleChange(event.target.name, event.target.value)}
						helperText="Displayed in the bottom right of the footer."
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="hostingOrganisationWebsite"
						name="hostingOrganisationWebsite"
						label="Website"
						variant="outlined"
						defaultValue={props.campaign.hostingOrganisationWebsite || ""}
						onBlur={(event) => handleChange(event.target.name, event.target.value)}
						helperText="Displayed in the bottom right of the footer."
					/>
				</div>
				<div className="field-wrapper">
					<TextField
						id="hostingOrganisationEmail"
						name="hostingOrganisationEmail"
						label="Email Address"
						variant="outlined"
						defaultValue={props.campaign.hostingOrganisationEmail || ""}
						onBlur={(event) => handleChange(event.target.name, event.target.value)}
						helperText="Displayed in the bottom left of the footer."

					/>
				</div>
			</Paper>
		</div>
	)
}

export default FooterContentSection