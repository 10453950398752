import { ToggleButton, Tooltip } from '@mui/material'
import { useRef, useState } from 'react';
import { Editor } from '@tiptap/react';
import InsertButtonPopover from './InsertButtonPopover';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const InsertButtonButton = ({ editor }: { editor: Editor | null }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [buttonConfig, setButtonConfig] = useState({
		text: 'Button',
		link: '/%%campaign-slug%%/lots',
		backgroundColor: 'red',
		color: 'white',
		border: 'none',
		borderRadius: '100px',
		padding: '0px 12px',
		fontSize: '16px',
		fontWeight: '700',
		fontFamily: 'arial',
		alignment: 'center',
		width: '200px',
		isOpenInNewTab: "false"
	});

	const divRef: any = useRef();

	function handleClick() {
		console.log(JSON.stringify(editor!.getAttributes('customisableButtonReactComponent')))

		if (editor!.getAttributes('customisableButtonReactComponent').text) {
			setButtonConfig({
				text: editor!.getAttributes('customisableButtonReactComponent').text,
				link: editor!.getAttributes('customisableButtonReactComponent').link,
				backgroundColor: editor!.getAttributes('customisableButtonReactComponent').backgroundColor,
				color: editor!.getAttributes('customisableButtonReactComponent').color,
				border: editor!.getAttributes('customisableButtonReactComponent').border,
				borderRadius: editor!.getAttributes('customisableButtonReactComponent').borderRadius,
				padding: editor!.getAttributes('customisableButtonReactComponent').padding,
				fontSize: editor!.getAttributes('customisableButtonReactComponent').fontSize,
				fontWeight: editor!.getAttributes('customisableButtonReactComponent').fontWeight,
				fontFamily: editor!.getAttributes('customisableButtonReactComponent').fontFamily,
				alignment: editor!.getAttributes('customisableButtonReactComponent').alignment,
				width: editor!.getAttributes('customisableButtonReactComponent').width,
				isOpenInNewTab: editor!.getAttributes('customisableButtonReactComponent').isOpenInNewTab
			})
		} else {
			setButtonConfig({
				text: 'Button',
				link: '',
				backgroundColor: 'red',
				color: 'white',
				border: 'none',
				borderRadius: '100px',
				padding: '10px 20px',
				fontSize: '16px',
				fontWeight: '700',
				fontFamily: 'arial',
				alignment: 'center',
				width: '200px',
				isOpenInNewTab: "false"
			})
		}
		setAnchorEl(divRef.current);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	const save = () => {
		console.log("buttonConfig.link", buttonConfig.link)
		editor!.chain().insertContent(`
		<customisable-button 
			backgroundColor='${buttonConfig.backgroundColor}'
			color='${buttonConfig.color}'
			text='${buttonConfig.text}'
			link='${buttonConfig.link ? buttonConfig.link : '/%%campaign-slug%%/lots'}'
			border='${buttonConfig.border}'
			borderRadius='${buttonConfig.borderRadius}'
			padding='${buttonConfig.padding}'
			fontSize='${buttonConfig.fontSize}'
			fontWeight='${buttonConfig.fontWeight}'
			fontFamily='${buttonConfig.fontFamily}'
			alignment='${buttonConfig.alignment}'
			width='${buttonConfig.width}'
			isOpenInNewTab='${buttonConfig.isOpenInNewTab}'
		/>`)
			.run()

		setAnchorEl(null);
	}

	const open = Boolean(anchorEl);

	return (
		<>
			<Tooltip title="Insert Button" enterDelay={600}>
				<ToggleButton ref={divRef} value="" aria-label="color" onClick={handleClick}>
					<CallToActionIcon />
					<ArrowDropDownIcon />
				</ToggleButton>
			</Tooltip>
			<InsertButtonPopover
				open={open}
				handleClose={handleClose}
				anchorEl={anchorEl}
				save={save}
				buttonConfig={buttonConfig}
				setButtonConfig={setButtonConfig}
				saveButtonText={editor!.getAttributes('customisableButtonReactComponent').text ? "Update Button" : "Insert Button"}
			/>
		</>
	)
}

export default InsertButtonButton