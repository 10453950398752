import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import useFetch from '../../hooks/useFetch';
import { IUser } from '../../interfaces/user';
import { UserRole } from '../../common/enums/UserRole';
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignQueryString, ORGANISATIONS_API_PATHS, USER_API_PATHS } from '../../common/ApiPaths';
import IOrganisation from '../../interfaces/organisation';
import ICampaign from '../../interfaces/campaign';

const Callback = () => {
    const [returnTo, setReturnTo] = useState<string>("")
    const [campaignId, setCampaignId] = useState<string>("")
    const [organisationId, setOrganisationId] = useState<string | null>(null)
    const [dbUser, setDbUser] = useState<IUser>({} as IUser)
    const [organisation, setOrganisation] = useState<IOrganisation>({} as IOrganisation)
    const { handleRedirectCallback, user, logout } = useAuth0()
    const history = useHistory()

    const userApi = useFetch(API_PATHS.USERS)
    const organisationApi = useFetch(API_PATHS.ORGANISATIONS)
    const campaignApi = useFetch(API_PATHS.CAMPAIGNS)

    const retrieveAppStateVars = async () => {
        handleRedirectCallback()
            .then(appState => {
                // console.log(`appState.appState.returnTo: ${appState.appState.returnTo}`)
                // console.log(`appState.appState.campaignId: ${appState.appState.campaignId}`)
                // console.log(`appState.appState.organisationId: ${appState.appState.organisationId}`)
                if (appState.appState.returnTo && appState.appState.campaignId) {
                    setReturnTo(appState.appState.returnTo)
                    setCampaignId(appState.appState.campaignId)
                    setOrganisationId(appState.appState.organisationId ?? '')
                } else {
                    history.push(`/`)
                }
            })
            .catch(err => {
                console.log(err)
                history.push(`/`)
            })
    }

    const manageLogin = async (organisationId: string | null) => {
        try {
            let org: IOrganisation = {} as IOrganisation
            let campaign: ICampaign = {} as ICampaign
            if (organisationId) {
                org = await organisationApi.get(`${ORGANISATIONS_API_PATHS.GET_ORGANISATION}?organisationId=${organisationId}`)
            }
            else if (campaignId && campaignId !== 'admin') {
                campaign = await campaignApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(undefined, campaignId)}`)
                org = await organisationApi.get(`${ORGANISATIONS_API_PATHS.GET_ORGANISATION}?organisationId=${campaign.organisationId}`)
            }
            const result = await userApi.post(`${USER_API_PATHS.GET_OR_CREATE_STRIPE_USER.replace('{auth0Id}', user?.sub ?? '')}`, {
                organisationId: organisationId ?? campaign.organisationId,
                role: UserRole.NONE,
                firstName: user?.given_name ?? '',
                surname: user?.family_name ?? '',
                email: user?.email ?? '',
                phone: user?.phone ?? '',
                signUpCampaignId: campaignId,
                currentUserId: '',
                stripeConnectedAccountId: org.stripeConnectedAccountId
            })
            if (result.role === UserRole.ADMIN || result.role === UserRole.OPERATOR) {
                setOrganisation(org)
            }
            setDbUser(result)
        }
        catch (err) {
            console.log(err)
            logout({ returnTo: `${window.location.origin}/${returnTo}?unknown-user` })
        }
    }

    useEffect(() => {
        retrieveAppStateVars()
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [])

    useEffect(() => {
        const getDBUser = async () => {
            const result = await userApi.get(`${USER_API_PATHS.GET_USER}?auth0Id=${user?.sub ?? ""}`)
            setOrganisationId(result.organisationId)
            manageLogin(result.organisationId)
        }

        if (user && campaignId) {
            getDBUser().catch(error => {
                console.log(error)
            })
        }
    }, [user, campaignId])


    useEffect(() => {
        if (returnTo && dbUser.auth0Id) {
            if (returnTo === "admin") {
                if (dbUser.role === UserRole.SUPER_ADMIN) {
                    history.push(`/organisations`)
                } else if (dbUser.role === UserRole.ADMIN || dbUser.role === UserRole.OPERATOR) {
                    history.push(`/organisations/${organisation.slug}?tabNumber=0`)
                } else {
                    logout({
                        returnTo: `${window.location.origin}/logout?returnTo=${'/?not-admin'}`,
                    })
                }
            } else {
                history.push(`${returnTo}`)
            }
        } else {
            console.log(`dont have one of: returnTo: ${returnTo}, dbUser.auth0Id: ${dbUser.auth0Id}`)
        }
    }, [returnTo, dbUser])

    return (
        <p>
            Logging In...
        </p>
    );
};

export default Callback;
