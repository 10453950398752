import { NodeViewWrapper, NodeViewContent } from '@tiptap/react'
import './customisableButton.scss'

const CustomisableButton = (props: any) => {

    return (
        <NodeViewWrapper className="customisable-button">
                <div className='button-div'
                    style={{
                        backgroundColor: props.node.attrs.backgroundColor,
                        color: props.node.attrs.color,
                        border: props.node.attrs.border,
                        borderRadius: props.node.attrs.borderRadius,
                        padding: props.node.attrs.padding,
                        fontSize: props.node.attrs.fontSize,
                        fontWeight: props.node.attrs.fontWeight,
                        fontFamily: props.node.attrs.fontFamily,
                        cursor: 'pointer',
                        maxWidth: '100%',
                        width: props.node.attrs.width,
                        textAlign: 'center',
                        margin: props.node.attrs.alignment === 'center' ? '6px auto' : props.node.attrs.alignment === 'right' ? '6px 0 6px auto' : '6px auto 6px 0'
                    }}
                >
                    {props.node.attrs.text}
                </div>
        </NodeViewWrapper>
    )
}

export default CustomisableButton