import React, { useEffect, useRef } from "react"
import "./mediaCarousel.scss"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"
import SwiperCore from "swiper"
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Lazy } from "swiper"
import IMedia from "../../interfaces/media"
import "swiper/swiper.min.css"
import "swiper/modules/pagination/pagination.min.css"
import "swiper/modules/navigation/navigation.min.css"
import "swiper/modules/autoplay/autoplay.min.css"
import "swiper/modules/lazy/lazy.min.css"
import ReactPlayer from "react-player"
import YouTubeThumbnail from "./YouTubeThumbnail"
import getYouTubeID from "get-youtube-id"
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { useMediaQuery } from "@mui/material"

interface InputProps {
	accentColor: string
	mediaList: IMedia[]
	index: number
	isShowYouTubeThumbnails: boolean
	isDisabled: boolean
	setMediaScrollIndex?: (x: number) => void
	mediaScrollIndex?: number
	isVideoPlaying: boolean
	setIsVideoPlaying: (x: boolean) => void
	// filter?: string
	carouselOnLoadHandler?: () => void
	carouselRef?: any
}

const MediaCarousel = (props: InputProps) => {
	const swiper = useRef() as any

	const determineBullet = (index, className) => {
		if (props.mediaList[index] && props.mediaList[index].mediaType === "YOUTUBE") {
			return `<svg class="${className} youtube" width="11" height="11" viewBox="-50 -50 300 300"><polygon stroke-linejoin="round" points="0,0 0,200 180,100"/></svg>`
		} else {
			return `<span class="${className}"></span>`
		}
	}

	const swiperImageOnLoadHandler = (index: number) => {
		//do this only when the first swiper slide image loads
		if (index === 0 && props.carouselOnLoadHandler){
			props.carouselOnLoadHandler()
		}
	}

	useEffect(() => {
			swiper.current.swiper.lazy.load()
	}, [props.mediaList])

	return (
		<div ref={props.carouselRef} className="media-carousel" style={{ "--header-background-color": props.accentColor } as React.CSSProperties}>
			<div style={{position: "relative"}}></div>
			<Swiper
				id="main"
				className="swiper-container"
				modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, Lazy]}
				slidesPerView={1}
				centeredSlides={true}
				pagination={
					props.isDisabled
						? false
						: {
							el: ".swiper-pagination",
							clickable: true,
							renderBullet: determineBullet,
						}
				}
				autoHeight={true}
				loop
				enabled={props.mediaList.length > 1}
				autoplay={false}
				navigation={props.isDisabled ? false : true}
				preloadImages={false}
				lazy={{
					enabled: true,
					checkInView: true,
					loadOnTransitionStart: true,
					loadPrevNext: true,
					preloaderClass: "swiper-lazy-preloader-custom",
				}}
				onInit={(core: SwiperCore) => {
					swiper.current = core.el
				}}
				onActiveIndexChange={swiperCore => {
					props.setMediaScrollIndex && props.setMediaScrollIndex(swiperCore.activeIndex - 1)
					props.setIsVideoPlaying(false)
				}}
				initialSlide={props.mediaScrollIndex ? props.mediaScrollIndex : 0}
			>
				{props.mediaList.map((media, index) => {
					if (media.mediaType === "YOUTUBE") {
						return (
							<SwiperSlide key={index}>
								{props.isShowYouTubeThumbnails ? (
									<YouTubeThumbnail
										imageSrc={`http://i3.ytimg.com/vi/${getYouTubeID(media.src)}/maxresdefault.jpg`}
										isInSwiper={true}
										className=""
									/>
								) : (
									<ReactPlayer
										className="swiper-lazy"
										width="100%"
										height="400px"
										url={`https://www.youtube.com/embed/${getYouTubeID(media.src)}`}
										playing={props.isVideoPlaying}
										muted
										controls
										onPause={() => props.setIsVideoPlaying(false)}
										onPlay={() => props.setIsVideoPlaying(true)}
									/>
								)}
								<div className="swiper-lazy-preloader-custom"></div>
							</SwiperSlide>
						)
					} else {
						return (
							<SwiperSlide key={`${media.src}-${index}`}>
								<img key={`${media.src}-${index}`} data-src={media.src} alt={`lot-media`} className="swiper-lazy" onLoad={() => swiperImageOnLoadHandler(index)}/>
								<div className="swiper-lazy-preloader-custom"></div>
							</SwiperSlide>
						)
					}
				})}
				{!props.isDisabled && <div className="swiper-pagination"></div>}
			</Swiper>
		</div>
	)
}

export default MediaCarousel
