import React, { useContext, useState } from "react"
import { AppBar, Menu, MenuItem, Toolbar } from "@mui/material"
import { Link, useHistory } from "react-router-dom"
import "./adminMenuBar.scss"
import GGLogo from "../../../images/GG Logo New - White.svg"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { UserRole } from "../../../common/enums/UserRole"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { UserContext } from "../../../context/UserContext"

const AdminMenuBar = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	// const [dbUser, setDbUser] = useState({} as IUser)
	// const [organisation, setOrganisation] = useState({} as IOrganisation)
	// const { logout, user } = useAuth0()

	const { dBUser, auth0User, logout } = useContext(UserContext)
	// let usersApi = useFetch(API_PATHS.USERS)
	// let organisationsApi = useFetch(API_PATHS.ORGANISATIONS)
	const organisation = useContext(OrganisationContext)
	let history = useHistory();

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	//TODO update - getUser should return an object not an array
	// const getUser = async () => {
	// 	console.log('--------- user =>',user)
	// 	const data = await usersApi.get(`${USER_API_PATHS.GET_USER}?auth0Id=${user?.sub ?? ""}`)
	// 	setDbUser(data)

	// 	// let organisation

	// 	// if (data.organisationId) {
	// 	// 	organisation = await organisationsApi.get(ORGANISATIONS_API_PATHS.GET_ORGANISATION.replace("{organisationId}", data.organisationId))
	// 	// 	setOrganisation(organisation)
	// 	// }
	// }

	// useEffect(() => {
	// 	getUser()
	// }, [])

	return (
		<>
			<div className="menu-bar">
				<AppBar>
					<Toolbar>
						<div className="logo-wrapper">
							{dBUser.role && (
								<Link
									to={
										dBUser && dBUser.role === UserRole.SUPER_ADMIN
											? "/organisations"
											: `/organisations/${organisation.slug}?tabNumber=0`
									}
								>
									<img alt="logo" src={GGLogo} />
								</Link>
							)}
						</div>
						<div className="menu-items">
							{auth0User && (
								<div className="authorised-email" onClick={handleClick}>
									<span className="username">{auth0User.name} ({auth0User.email})</span>
									<AccountCircleIcon />
								</div>
							)}
						</div>
					</Toolbar>
				</AppBar>
				<Menu
					id="logout-menu"
					anchorEl={anchorEl}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "center" }}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem
						onClick={() => {
							history.push(`/`)
							logout()
						}}
					>
						Log Out
					</MenuItem>
				</Menu>
			</div>
		</>
	)
}

export default AdminMenuBar
