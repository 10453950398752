import React, { useState } from "react"
import './lotNumberDialog.scss'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"

interface InputProps {
    isDialogOpen: boolean,
    handleClose: (boolean: boolean, sequenceStartNumber: number) => void,
}

const LotNumberDialog = (props: InputProps) => {

    const [sequenceStartNumber, setSequenceStartNumber] = useState<number>(1)

    const handleChange = (event) => {
        setSequenceStartNumber(event.target.value)
    }

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={() => props.handleClose(false, sequenceStartNumber)}
                aria-labelledby="form-dialog-title"
                className="lot-number-dialog"
            >
                <DialogTitle id="form-dialog-title">Set Lot Numbers</DialogTitle>
                <DialogContent>
                <span className="intro-text">Please enter the number from which the lot numbers for the selected lots will start in sequence.</span>
                    <TextField
                        id="sequenceStartNumber"
                        name="sequenceStartNumber"
                        label="Sequence Start Number"
                        variant="outlined"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        value={sequenceStartNumber}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{ inputProps: { min: 1 } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.handleClose(false, sequenceStartNumber)} >
                        Cancel
                    </Button>
                    <Button onClick={() => props.handleClose(true, sequenceStartNumber)} className="gg-button">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LotNumberDialog