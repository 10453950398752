import "./organisationList.scss"
import React, { useState, useEffect, useContext } from "react"
import { Table, TableBody, TableCell, TableHead, TableRow, Card, Button, CardContent, Tooltip, Avatar, useMediaQuery } from "@mui/material"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import { Link } from "react-router-dom"
import _ from "lodash"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import ICampaign from "../../../interfaces/campaign"
import IOrganisation from "../../../interfaces/organisation"
import useOrganisationCommonFunctions from "../../../hooks/useOrganisationCommonFunctions"
import { API_PATHS, CAMPAIGN_API_PATHS, ORGANISATIONS_API_PATHS } from "../../../common/ApiPaths"
import useFetch from "../../../hooks/useFetch"
import useExcel from "../../../hooks/useExcel"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import { UserContext } from "../../../context/UserContext"
import { IUser } from "../../../interfaces/user"
import LoadingSpinner from "../../shared/LoadingSpinner"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import CreateOrganisationDialog from "./CreateOrganisationDialog"
import { useErrorBoundary } from 'react-error-boundary'

const OrganisationList = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string>("")
	const [organisationList, setOrganisationList] = useState<IOrganisation[]>([])
	const [campaignList, setCampaignList] = useState<ICampaign[]>([])
	const [columnToSort, setColumnToSort] = useState("fees")
	const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc")
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false)
	const [organisationIdToBeDeleted, setOrganisationIdToBeDeleted] = useState<string>("")
	const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", label: "", message: "" })
	const [isCreateOrganisationDialogOpen, setIsCreateOrganisationDialogOpen] = useState<boolean>(false)

	const commonOrganisationFunctions = useOrganisationCommonFunctions()

	const organisationsApi = useFetch(API_PATHS.ORGANISATIONS)
	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	const { dBUser } = useContext(UserContext)
	const commonFunctions = useCommonFunctions()
	const { showBoundary } = useErrorBoundary();

	const excel = useExcel()
	const isMobile = useMediaQuery("(max-width:600px)")

	const getOrganisationList = async (): Promise<void> => {
		try {
			const result = await organisationsApi.get(`${ORGANISATIONS_API_PATHS.GET_ORGANISATION_LIST}`)
			setOrganisationList(_.orderBy(
				result,
				function (organisation: IOrganisation) {
					return commonOrganisationFunctions.determineTotalFees(organisation, campaignList.filter(x => x.organisationId === organisation._id))
				},
				sortDirection
			))
		}
		catch (err: any) {
			showBoundary(err)
		}
	}

	const getCampaignList = async (): Promise<void> => {
		try {
			const result = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_VALID_CAMPAIGNS}`)
			setCampaignList(result)
		}
		catch (err: any) {
			showBoundary(err)
		}
	}

	const invertDirection = (currentDirection: string) => {
		if (currentDirection === "asc") {
			return "desc"
		} else if (currentDirection === "desc") {
			return "asc"
		}
	}

	const handleSort = columnName => {
		setColumnToSort(columnName)
		let sortDirect: any = columnToSort === columnName ? invertDirection(sortDirection) : "desc"
		setSortDirection(sortDirect)
	}

	const handleCloseDialog = (isConfirmed: boolean) => {
		if (dialogProperties.type === "AREYOUSURE") {
			if (isConfirmed) {
				deleteOrganisation(organisationIdToBeDeleted)
				setOrganisationIdToBeDeleted("")
			}
		}
		setDialogProperties({})
		setIsConfirmationDialogOpen(false)
	}

	const openAreYouSureDialog = (organisationId: string) => {
		setOrganisationIdToBeDeleted(organisationId)
		setDialogProperties({
			type: "AREYOUSURE",
			title: "Confirm Organisation Delete",
			message: "Are you sure you want to delete this organisation?",
		})
		setIsConfirmationDialogOpen(true)
	}

	const openDeleteSuccessDialog = () => {
		setDialogProperties({ type: "SUCCESS", title: "Organisation Deleted", message: "Organisation deleted successfully." })
		setIsConfirmationDialogOpen(true)
	}

	const deleteOrganisation = async (organisationId: string) => {
		const result = await organisationsApi.del(`${ORGANISATIONS_API_PATHS.DELETE_ORGANISATION.replace("{organisationId}", organisationId)}`, undefined, dBUser._id)
		getOrganisationList()
		openDeleteSuccessDialog()
	}

	useEffect(() => {
		if (columnToSort === "activeLicences") {
			setOrganisationList(
				_.orderBy(
					organisationList,
					function (organisation: IOrganisation) {
						let orgCampaigns = campaignList.filter(c => c.organisationId === organisation._id)
						return commonOrganisationFunctions.determineActiveLicences(orgCampaigns.length ?? 0, organisation)
					},
					sortDirection
				)
			)
		} else if (columnToSort === "totalLicences") {
			setOrganisationList(
				_.orderBy(
					organisationList,
					function (organisation: IOrganisation) {
						return commonOrganisationFunctions.determineTotalLicences(organisation.licenceBundleList)
					},
					sortDirection
				)
			)
		} else if (columnToSort === "revenue") {
			setOrganisationList(
				_.orderBy(
					organisationList,
					function (organisation: IOrganisation) {
						return organisation.totalRevenue ?? 0
					},
					sortDirection
				)
			)
		} else if (columnToSort === "fees") {
			setOrganisationList(
				_.orderBy(
					organisationList,
					function (organisation: IOrganisation) {
						return commonOrganisationFunctions.determineTotalFees(organisation, campaignList.filter(x => x.organisationId === organisation._id))
					},
					sortDirection
				)
			)
		} else {
			setOrganisationList(_.orderBy(organisationList, columnToSort, sortDirection))
		}
	}, [columnToSort, sortDirection])

	useEffect(() => {
		getOrganisationsAndCampaigns()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getOrganisationsAndCampaigns = () => {
		setLoading(true)
		let promises: Promise<any>[] = []
		promises.push(getOrganisationList())
		promises.push(getCampaignList())
		Promise.all(promises).then(() => {
			setLoading(false)
		})
	}

	// if (error) {
	// 	return <p>{error}</p>
	// }

	return (
		<div className="organisation-list-page">
			<h2 className="subtitle">Organisation List.</h2>
			<span className="description">A list of the organisations that have a Going Gone account.</span>

			{!loading && (
				<>
					{organisationList.length > 0 ? (
						<Card>
							<CardContent>
								<Table>
									{!isMobile && (
										<TableHead>
											<TableRow>
												<TableCell></TableCell>
												<TableCell>
													<div onClick={() => handleSort("name")} className="table-header-cell sortable">
														<span>Name</span>
														{columnToSort === "name" ? (
															sortDirection === "asc" ? (
																<KeyboardArrowUpIcon />
															) : (
																<KeyboardArrowDownIcon />
															)
														) : null}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div onClick={() => handleSort("activeLicences")} className="table-header-cell sortable center">
														<span>Active Licences</span>
														{columnToSort === "activeLicences" ? (
															sortDirection === "asc" ? (
																<KeyboardArrowUpIcon />
															) : (
																<KeyboardArrowDownIcon />
															)
														) : null}
													</div>
												</TableCell>

												<TableCell className="hide-on-mobile">
													<div onClick={() => handleSort("totalLicences")} className="table-header-cell sortable center">
														<span>Total Licences Purchased</span>
														{columnToSort === "totalLicences" ? (
															sortDirection === "asc" ? (
																<KeyboardArrowUpIcon />
															) : (
																<KeyboardArrowDownIcon />
															)
														) : null}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div onClick={() => handleSort("revenue")} className="table-header-cell sortable center">
														<span>Gross Funds Collected</span>
														{columnToSort === "revenue" ? (
															sortDirection === "asc" ? (
																<KeyboardArrowUpIcon />
															) : (
																<KeyboardArrowDownIcon />
															)
														) : null}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div onClick={() => handleSort("fees")} className="table-header-cell sortable center">
														<span>Total Fees Paid</span>
														{columnToSort === "fees" ? (
															sortDirection === "asc" ? (
																<KeyboardArrowUpIcon />
															) : (
																<KeyboardArrowDownIcon />
															)
														) : null}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile"></TableCell>
											</TableRow>
										</TableHead>
									)}

									<TableBody>
										{organisationList.map((organisation: IOrganisation) => (
											<TableRow key={organisation._id}>
												<TableCell>
													<Link to={`/organisations/${organisation.slug}`}>
														<Avatar
															alt="organisation logo"
															src={organisation.logoUrl ? organisation.logoUrl : "/placeholder_image.jpg"}
															sx={{ width: 48, height: 48 }}
														/>
													</Link>
												</TableCell>
												<TableCell>
													<Link to={`/organisations/${organisation.slug}`}>{organisation.name}</Link>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div className="center">
														{commonOrganisationFunctions.determineActiveLicences(
															campaignList.filter(c => c.organisationId === organisation._id).length ?? 0,
															organisation
														)}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div className="center">
														{commonOrganisationFunctions.determineTotalLicences(organisation.licenceBundleList)}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div className="center">
														{new Intl.NumberFormat("en-US", {
															style: "currency",
															currency: "GBP",
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}).format(organisation.totalRevenue ?? 0)}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div className="center">
														{new Intl.NumberFormat("en-US", {
															style: "currency",
															currency: "GBP",
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}).format(commonOrganisationFunctions.determineTotalFees(organisation, campaignList.filter(x => x.organisationId === organisation._id)))}
													</div>
												</TableCell>
												<TableCell className="hide-on-mobile">
													<div className="action-cell">
														<Link to={`/organisations/${organisation.slug}`}>
															<Tooltip title="Edit Organisation" enterDelay={500}>
																<CreateIcon />
															</Tooltip>
														</Link>
														<div onClick={() => openAreYouSureDialog(organisation._id)}>
															<Tooltip title="Delete Organisation" enterDelay={500}>
																<DeleteIcon />
															</Tooltip>
														</div>
													</div>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</CardContent>
						</Card>
					) : (
						<Card>
							<CardContent>
								<div className="no-entries">No organisations found</div>
							</CardContent>
						</Card>
					)}
				</>
			)}

			{loading && (
				<Card>
					<CardContent>
						<LoadingSpinner text="Retrieving Organisations" />
					</CardContent>
				</Card>
			)}

			{!isMobile && (
				<div className="fixed-action-section">
					<div></div>
					<div className="button-wrapper space-between">
						<Button
							onClick={() => {
								excel.generateOrganisationList(organisationList, campaignList)
							}}
						>
							Export to Excel
						</Button>
						<Button className="gg-button" onClick={() => setIsCreateOrganisationDialogOpen(true)}>
							New Organisation
						</Button>
					</div>
				</div>
			)}

			<ConfirmationDialog
				handleClose={handleCloseDialog}
				isDialogOpen={isConfirmationDialogOpen}
				message={dialogProperties.message}
				title={dialogProperties.title}
				type={dialogProperties.type}
				label={dialogProperties.label}
				primaryButton={
					<Button
						onClick={() => handleCloseDialog(true)}
						className="gg-button"
						style={commonFunctions.determineButtonColor(dialogProperties.type)}
					>
						Ok
					</Button>
				}
			/>

			<CreateOrganisationDialog isDialogOpen={isCreateOrganisationDialogOpen} handleClose={() => setIsCreateOrganisationDialogOpen(false)} />
		</div>
	)
}

export default OrganisationList
