import React from 'react'
import ICampaign from '../../../../interfaces/campaign'
import IValidationObject from '../../../../interfaces/validationObject'
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from 'dayjs'
import { TotaliserType } from '../../../../common/enums/TotaliserType'

interface InputProps {
	campaign: ICampaign
	setCampaign: (campaign: ICampaign) => void
	validationObject: IValidationObject[]
}

const CoreSettingsSection = (props: InputProps) => {

	const handleChange = (name, value) => {
		props.setCampaign({ ...props.campaign, [name]: value })
	}

	const handleDateChange = (name, date) => {
		props.setCampaign({ ...props.campaign, [name]: date })
	}

	const formatUrlSuffix = (raw: string): string => {
		return raw
			.replaceAll(" ", "-")
			.toLowerCase()
			.replaceAll(/[^\w\s-]/g, "")
			.replaceAll(/-+/g, "-")
	}

	const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		props.validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	return (
		<div className="core-settings-section">
			<h2 className="subtitle">Core Settings.</h2>
			<span className="description">The core details of the campaign.</span>
			<Paper className="input-wrapper">
				<div className="field-wrapper">
					<TextField
						id="name"
						name="name"
						label="Campaign Name"
						variant="outlined"
						defaultValue={props.campaign.name || ""}
						onBlur={event => handleChange(event.target.name, event.target.value)}
						helperText={
							getValidation("name").isValid
								? "The name of the campaign - this will be displayed at the top of invoices."
								: getValidation("name").validationMessage
						}
						required
						error={!getValidation("name").isValid}
					/>
				</div>
				<div className="field-wrapper">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Campaign Date"
							value={dayjs(props.campaign.campaignDate)}
							onChange={date => handleDateChange("campaignDate", date)}
							format="DD/MM/YYYY"
							slotProps={{
								textField: {
									required: true,
									error: !getValidation("campaignDate").isValid,
									helperText: (getValidation("campaignDate").isValid
										? "The date of the campaign"
										: getValidation("campaignDate").validationMessage)
								}
							}}
						/>
					</LocalizationProvider>
				</div>
				<div className="field-wrapper">
					<TextField
						id="slug"
						name="slug"
						label="URL-Suffix"
						variant="outlined"
						value={props.campaign.slug || ""}
						onChange={event => handleChange(event.target.name, formatUrlSuffix(event.target.value))}
						// onBlur={(event) => handleChange(event.target.name, event.target.value.toLowerCase())}
						helperText={
							getValidation("slug").isValid
								? "The suffix that identifies the auction. Eg www.auction.com/<url-suffix>"
								: getValidation("slug").validationMessage
						}
						required
						InputProps={{
							startAdornment: <InputAdornment position="start">{process.env.REACT_APP_CAMPAIGN_URL}</InputAdornment>,
						}}
						error={!getValidation("slug").isValid}
					/>
				</div>
				<div className="field-wrapper">
					<FormControl variant="outlined">
						<InputLabel id="payment-type-label">Payment Method</InputLabel>
						<Select
							labelId="payment-type-label"
							id="paymentMethod"
							name="paymentMethod"
							defaultValue={props.campaign.paymentMethod || ""}
							onBlur={event => handleChange(event.target.name, event.target.value)}
							label="Payment Method"
							error={!getValidation("paymentMethod").isValid}
						>
							<MenuItem value={"PAYMENT-LINK"}>Payment Link (Sent on auction close)</MenuItem>
							<MenuItem value={"PRE-AUTH"}>Pre-Authorisation (Before bid)</MenuItem>
						</Select>
						{getValidation("paymentMethod").isValid ? (
							<FormHelperText>How bidders will pay for their items.</FormHelperText>
						) : (
							<FormHelperText style={{ color: "#d32f2f" }}>{getValidation("paymentMethod").validationMessage}</FormHelperText>
						)}
					</FormControl>
				</div>
				<div className="field-wrapper">
					<TextField
						id="cardFee"
						name="cardFee"
						label="Card Fee"
						variant="outlined"
						type="number"
						InputProps={{
							inputProps: { max: 10 },
						}}
						onWheel={e => (e.target as any).blur()}
						defaultValue={props.campaign.cardFee}
						onBlur={event => handleChange(event.target.name, Number(event.target.value))}
						helperText="The percentage the customer will be asked to optionally cover for card fees."
					/>
				</div>
				
				<div className="field-wrapper">
					<FormControl variant="outlined">
						<InputLabel id="isLotNumberHidden-label">Hide Lot Numbers?</InputLabel>
						<Select
							labelId="isLotNumberHidden-label"
							id="isLotNumberHidden"
							name="isLotNumberHidden"
							defaultValue={props.campaign.isLotNumberHidden || false}
							onBlur={event => handleChange(event.target.name, event.target.value)}
							label="Hide Lot Numbers?"
						>
							<MenuItem value={true as any}>Yes</MenuItem>
							<MenuItem value={false as any}>No</MenuItem>
						</Select>
						<FormHelperText>Should the lot numbers be hidden?</FormHelperText>
					</FormControl>
				</div>
				<div className="field-wrapper">
					<FormControl variant="outlined">
						<InputLabel id="isShowTotaliser-label">Show Totaliser?</InputLabel>
						<Select
							labelId="isShowTotaliser-label"
							id="isShowTotaliser"
							name="isShowTotaliser"
							defaultValue={props.campaign.isShowTotaliser}
							onChange={event => handleChange(event.target.name, event.target.value)}
							label="Show Totaliser?"
						>
							<MenuItem value={true as any}>True</MenuItem>
							<MenuItem value={false as any}>False</MenuItem>
						</Select>
						<FormHelperText>Display a floating totaliser section on home / lot pages?</FormHelperText>
					</FormControl>
				</div>
				<div className="field-wrapper">
					<FormControl variant="outlined">
						<InputLabel id="isCompetitiveMaxBidding-label">Auto Bidding for Competitive Lots?</InputLabel>
						<Select
							labelId="isCompetitiveMaxBidding-label"
							id="isCompetitiveMaxBidding"
							name="isCompetitiveMaxBidding"
							defaultValue={props.campaign.isCompetitiveMaxBidding}
							onChange={event => handleChange(event.target.name, event.target.value)}
							label="Auto Bidding for Competitive Lots?"
						>
							<MenuItem value={true as any}>True</MenuItem>
							<MenuItem value={false as any}>False</MenuItem>
						</Select>
						<FormHelperText>Should auto bidding be enabled for competitive items?</FormHelperText>
					</FormControl>
				</div>
			</Paper>
		</div>
	)
}

export default CoreSettingsSection