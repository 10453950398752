export function removeSpecialCharacters(inputString: string) {
  var pattern = /[^\w\s]/gi;

  var hasSpecialCharacters = pattern.test(inputString);

  if (hasSpecialCharacters) {
    var result = inputString.replace(pattern, "");
    return result;
  } else {
    return inputString;
  }
}
