import './faqSection.scss'
import React, { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import FAQDialog from '../dialogs/FAQDialog'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ICampaign from '../../../../interfaces/campaign'
import IFaq from '../../../../interfaces/faq'

interface InputProps {
    campaign: ICampaign
    setCampaign: (campaign: ICampaign) => void,
}

const FAQSection = (props: InputProps) => {

    const [isFAQDialogOpen, setIsFAQDialogOpen] = useState<boolean>(false);
    const [tempFAQ, setTempFAQ] = useState<IFaq>({} as IFaq);
    const [tempFAQIndex, setTempFAQIndex] = useState<number>(-1);

    const closeDialog = () => {
        setIsFAQDialogOpen(false)
    }
    const openDialog = (FAQ: IFaq, index: number) => {
        setTempFAQ(FAQ)
        setTempFAQIndex(index)
        setIsFAQDialogOpen(true)
    }

    const deleteFAQ = (index: number) => {
        let tempFAQObject: any[] = [...props.campaign.faqList]
        tempFAQObject.splice(index, 1)
        props.setCampaign({ ...props.campaign, faqList: tempFAQObject })
    }

    const promote = (index: number) => {
        // let index = props.campaign.faqList.findIndex(e => e.faqId === faqId);
        if (index > 0) {
            let tempFaqs = [...props.campaign.faqList]
            let el = tempFaqs[index];
            tempFaqs[index] = tempFaqs[index - 1];
            tempFaqs[index - 1] = el;
            props.setCampaign({ ...props.campaign, faqList: tempFaqs })
        }
    }

    const demote = (index : number) => {
        // let index = props.campaign.faqList.findIndex(e => e.faqId === faqId);
        if (index !== -1 && index < props.campaign.faqList.length - 1) {
            let tempFaqs = [...props.campaign.faqList]
            let el = tempFaqs[index];
            tempFaqs[index] = tempFaqs[index + 1];
            tempFaqs[index + 1] = el;
            props.setCampaign({ ...props.campaign, faqList: tempFaqs })
        }
    }

    return (
        <div className="home-page-section">
            <h2 className="subtitle">FAQs.</h2>
            <span className="description">Please define a list of questions and answers to be included in the FAQ page.</span>
            <Paper className="input-wrapper">
                {props.campaign.faqList.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                <div className="table-header-cell">
                                        <span>Question</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Answer</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.campaign.faqList.map((faq, index) => (
                                <TableRow key={new Date().getTime() + index}>
                                    <TableCell onClick={() => openDialog(faq, index)}>{faq.question}</TableCell>
                                    <TableCell className="hide-on-mobile" >{faq.answer}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => promote(index)}>
                                                <Tooltip title="Promote"><ExpandLessIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => demote(index)}>
                                                <Tooltip title="Demote"><ExpandMoreIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => openDialog(faq, index)}><Tooltip title="Edit FAQ"><CreateIcon /></Tooltip></div>
                                            <div onClick={() => deleteFAQ(index)}><Tooltip title="Delete FAQ"><DeleteIcon /></Tooltip></div>
                                        </div>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table> :
                    <div className="no-entries">No FAQs defined.</div>
                }
                <div className="button-wrapper">
                    <Button className="gg-button" onClick={() => openDialog({} as IFaq, -1)}>Add New FAQ</Button>
                </div>
            </Paper>
            <FAQDialog
                handleClose={closeDialog}
                isDialogOpen={isFAQDialogOpen}
                setCampaign={props.setCampaign}
                campaign={props.campaign}
                faq={tempFAQ}
                index={tempFAQIndex}
            />
        </div>
    )
}

export default FAQSection