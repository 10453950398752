interface Options {
    method: string;
    headers: {};
    body: any;
}

const useFetch = (collection: string) => {
    const stub = `${process.env.REACT_APP_BACKEND_URL}${collection}`;

    const defaultHeader = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    // TODO: Add interface for function parameters
    const customFetch = (url, method, body, headers, showBadReqError = false) => {
        const options: Options = {
            method,
            headers,
            body,
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        try {


            return fetch(url, options)
                .then((response) => {
                    if (!response.ok) {
                        return response.text().then((text) => {
                            throw new Error(text);
                        });
                    } else {
                        return response.json();
                    }
                })
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    if (isJsonString(err.message)) {
                        if (JSON.parse(err.message).response) { // in case of custom error fields the error object is sent in 'response'
                            throw new Error(JSON.stringify(JSON.parse(err.message).response))
                        }
                        else {
                            if (JSON.parse(err.message).message === 'Not Found') {
                                return {}
                            }
                            else {
                                if (showBadReqError && JSON.parse(err.message).statusCode === 400) {
                                    return ({ statusCode: 400, error: 'Bad Request' })
                                }
                                else {
                                    throw new Error(JSON.parse(err.message).message ?? err.message ?? err)
                                }
                            }
                        }
                    }
                    else {
                        console.log(err.status, err.code, err.message)
                        throw new Error(err.message)
                    }

                });
        } catch (err) {
            console.log(err)
            return new Promise((value) => value)
        }

    };

    const get = (id) => {
        const url = `${stub}${id ? `${id}` : ""}`;
        return customFetch(url, "GET", null, defaultHeader);
    };

    const post = (id, body, currentUserId = "", showBadReqError = false) => {
        if (!body) throw new Error("to make a post you must provide a body");
        const url = `${stub}${id ? `${id}` : ""}`;
        const headers = currentUserId.length
            ? { ...defaultHeader, currentUserId: currentUserId }
            : defaultHeader;
        return customFetch(url, "POST", body, headers, showBadReqError);
    };

    const put = (id, body, currentUserId = "") => {
        if (id === null || !body)
            throw new Error("to make a put you must provide the id and the   body");
        const url = `${stub}${id}`;
        const headers = currentUserId.length
            ? { ...defaultHeader, currentUserId: currentUserId }
            : defaultHeader;
        return customFetch(url, "PUT", body, headers);
    };

    const del = (id, body = {}, currentUserId = "") => {
        if (id === null)
            throw new Error("to make a delete you must provide the id");
        const url = `${stub}${id}`;
        const headers = currentUserId.length
            ? { ...defaultHeader, currentUserId: currentUserId }
            : defaultHeader;
        return customFetch(url, "DELETE", body, headers);
    };

    return {
        get,
        post,
        put,
        del,
        customFetch,
    };
};
export default useFetch;
