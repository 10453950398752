import { Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material"
import { useContext, useMemo, useState } from "react"
import ICampaign from "../../../interfaces/campaign"
import './licenceBundleSection.scss'
import moment from "moment"
import IOrganisation from "../../../interfaces/organisation"
import { OrganisationContext } from "../../../context/OrganisationContext"

interface InputProps {
	campaignList: ICampaign[]
}

const BrochureToolPurchasesSection = (props: InputProps) => {
	const organisation: IOrganisation = useContext(OrganisationContext)
	const [paginationPage, setPaginationPage] = useState<number>(0)
	const startIndex = useMemo(() => paginationPage * 5, [paginationPage])
	const endIndex = useMemo(() => startIndex + 5, [startIndex])

	const filteredBrochureToolsPurchasedList = useMemo(() => {
		let brochureCampaigns = props.campaignList.filter(x => x.brochureToolPurchaseInfo && x.brochureToolPurchaseInfo.paymentRefId && x.brochureToolPurchaseInfo.paymentRefId.length > 0)
		const brochureCampaignsWithUniquePaymentRefIds: ICampaign[] =
			Object.values(
				brochureCampaigns.reduce(
					(acc, obj) => ({
						...acc,
						[obj.brochureToolPurchaseInfo?.paymentRefId]: obj,
					}),
					{},
				),
			)
		let infoToShow = brochureCampaignsWithUniquePaymentRefIds.map(x => {
			return {
				campaignName: x.brochureToolPurchaseInfo?.allCampaignsInfo?.map(x => x.name).join(', ') || '-',
				paymentRefId: x.brochureToolPurchaseInfo.paymentRefId,
				receiptUrl: x.brochureToolPurchaseInfo.receiptUrl,
				timestamp: x.brochureToolPurchaseInfo.timestamp,
				totalCost: x.brochureToolPurchaseInfo.totalCost
			}
		})
		infoToShow = infoToShow.sort(function (d1, d2) {
			return moment(d1.timestamp).isAfter(moment(d2.timestamp)) ? -1 : moment(d2.timestamp).isAfter(moment(d1.timestamp)) ? 1 : 0
		})
		return infoToShow
	}, [props.campaignList])

	return (
		<section className="licence-bundle-section">
			<h2>Brochure Tool Purchases</h2>
			<Paper className="input-wrapper">
				{filteredBrochureToolsPurchasedList.length > 0 ? (
					<>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<div className="table-header-cell">
											<span>Purchase Date</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center">
											<span>Campaign Name</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center">
											<span>Total Cost</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center">
											<span>Receipt</span>
										</div>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredBrochureToolsPurchasedList.slice(startIndex, endIndex).map((purchase, index) => {
									return (
										<TableRow key={index}>
											<TableCell>{moment(purchase.timestamp).format("DD/MM/YYYY")}</TableCell>
											<TableCell className="center">{purchase.campaignName}</TableCell>
											<TableCell>
												<div className="center">
													{new Intl.NumberFormat("en-US", {
														style: "currency",
														currency: organisation.currency,
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}).format(purchase.totalCost ?? 0)}
												</div>
											</TableCell>
											<TableCell>
												<div className="center">
													{purchase.receiptUrl ? (
														<a target="_blank" className='hyperlink' href={purchase.receiptUrl} rel="noreferrer">View Receipt</a>
													) : '-'}
												</div>
											</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
						<TablePagination
							component="div"
							count={filteredBrochureToolsPurchasedList.length}
							rowsPerPage={5}
							page={paginationPage}
							onPageChange={(event: unknown, newPage: number) => setPaginationPage(newPage)}
							rowsPerPageOptions={[]}
						/>
					</>
				) : (
					<div className="no-entries">No brochure generation tools purchased.</div>
				)}
			</Paper>
		</section>
	)
}

export default BrochureToolPurchasesSection
