import { useEffect } from 'react'
import Error from '../../shared/Error'

const ErrorPage = ({ error, resetErrorBoundary }) => {


    return (
        <div className='error-page-wrapper'>
            {window.location.host.startsWith('localhost') && (
                <p>Cannot refresh Error boundary page on localhost because of built in react-overlay iframe</p>
            )}
            <Error buttonAction={resetErrorBoundary} error={typeof error === 'string' ? error : error.stack} campaignSlug={''} />
        </div>
    )
}

export default ErrorPage