import "./wysiwygEditor.scss";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

interface InputProps {
	value: any;
	placeholder: string;
	onChange: (value: any) => void;
	onBlur?: () => void;
}

const WysiwygEditor = (props: InputProps) => {



	var Link = Quill.import('formats/link');

	//removing the target=_blank from links
	class MyLink extends Link {
		static create(value) {
			let node = super.create(value);
			value = this.sanitize(value);
			node.setAttribute('href', value);
			node.removeAttribute('target');
			return node;
		}
	}

	Quill.register(MyLink);

	return (
		<ReactQuill
			theme={"snow"}
			value={props.value || ""}
			onBlur={() => props.onBlur ? props.onBlur() : {}}
			onChange={(value) => props.onChange ? props.onChange(value) : {}}
			placeholder={props.placeholder}
			modules={{
				toolbar: [
					[{ font: [] }],
					[{ size: [] }],
					["bold", "italic", "underline", "strike", "blockquote"],
					[
						{ list: "ordered" },
						{ list: "bullet" },
						{ indent: "-1" },
						{ indent: "+1" },
					],
					["link"],
					// ['link', 'image'],
					["clean"],
					[{ color: [] }, { background: [] }],
					[{ align: "" }, { align: "center" }, { align: "right" }],
				],
				// imageResize: {
				//     parchment: Quill.import('parchment'),
				//     modules: ['Resize', 'DisplaySize']
				// }
			}}
			formats={[
				"header",
				"font",
				"size",
				"color",
				"bold",
				"italic",
				"underline",
				"strike",
				"blockquote",
				"list",
				"bullet",
				"indent",
				"link",
				"align",
				"width",
			]}
			// formats={[
			//     'header', 'font', 'size', 'color',
			//     'bold', 'italic', 'underline', 'strike', 'blockquote',
			//     'list', 'bullet', 'indent',
			//     'link', 'image', 'video',
			//     'align','width'
			// ]}
			style={{
				backgroundColor: "#fff",
				color: "#000",
				// minHeight: '300px',
			}}
		/>
	);
};

export default WysiwygEditor;
