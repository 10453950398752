import React, { useContext, useEffect, useState } from 'react'
import { ICustomPage } from '../../../interfaces/customPage'
import { Button, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomPageDialog from './CustomPageDialog'
import { CUSTOM_PAGES_API_PATH, CUSTOM_PAGES_PATHS } from '../../../common/CustomPageApiPaths'
import useFetch from '../../../hooks/useFetch'
import ICampaign from '../../../interfaces/campaign'
import { CampaignContext, CampaignDispatchContext } from '../../../context/CampaignContext'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create'
// import LotsPageTableRow from './LotsPageTableRow'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API_PATHS, CAMPAIGN_API_PATHS } from '../../../common/ApiPaths'
import { UserContext } from '../../../context/UserContext'

const CustomPages = () => {

    const [customPageList, setCustomPageList] = useState<ICustomPage[]>([])
    const [customPageToBeDeleted, setCustomPageToBeDeleted] = useState<ICustomPage>({} as ICustomPage)
    const [customPageToBeEdited, setCustomPageToBeEdited] = useState<ICustomPage>({} as ICustomPage)
    const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "", label: "", errorDetails: "" })
    const [customPagedialogProperties, setCustomPageDialogProperties] = useState<any>({ isOpen: false, type: "NEW" })

    const commonFunctions = useCommonFunctions()
    const customPageApi = useFetch(CUSTOM_PAGES_API_PATH);
    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS);
    const campaign: ICampaign = useContext(CampaignContext)
    const setCampaign = useContext(CampaignDispatchContext);
    const { dBUser } = useContext(UserContext)

    const openDeleteConfirmationDialog = (customPage: ICustomPage) => {
        setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Delete", message: "Are you sure you want to delete this page?", label: "DELETE" })
        setCustomPageToBeDeleted(customPage)
    }

    const editCustomPage = (customPage: ICustomPage) => {
        setCustomPageToBeEdited(customPage)
    }

    useEffect(() => {
        if (customPageToBeEdited._id) {
            setCustomPageDialogProperties({ isOpen: true, type: "EDIT" })
        }
    }, [customPageToBeEdited])

    const handleCloseDialog = async (isConfirmed: boolean) => {
        if (isConfirmed) {
            try {
                await customPageApi.del(CUSTOM_PAGES_PATHS.DELETE_CUSTOM_PAGE.replace("{customPageId}", customPageToBeDeleted._id))
                getCustomPages()
            } catch (err: any) {
                setDialogProperties({ isOpen: true, type: "ERROR", title: "Error", message: err.message })
            }
        } else {
            setCustomPageToBeDeleted({} as ICustomPage)
        }

        setDialogProperties({ isOpen: false })
    }

    const handleCloseCreateCustomPageDialog = () => {
        setCustomPageDialogProperties({ isOpen: false })
    }

    const getCustomPages = async () => {
        try {
            const customPageList: ICustomPage[] = await customPageApi.get(CUSTOM_PAGES_PATHS.GET_CUSTOM_PAGES.replace("{campaignId}", campaign._id))

            const lotPage: ICustomPage = {
                orderNumber: campaign.lotPageOrderNumber || 0,
                label: "Lot List",
                path: "lots",
                isHidden: campaign.isLotsPageHidden,
                ctaText: campaign.buttonText,
                _id: "0",
                campaignId: campaign._id,
                isLotpage: true
            }

            if (customPageList.length > 0) {

                //insert the lot list page into the custom page list based on order number
                customPageList.splice(lotPage.orderNumber - 1, 0, lotPage)
                setCustomPageList(customPageList)
            } else {
                setCustomPageList([lotPage])
            }

        } catch (err: any) {
            console.log(err.message)
        }
    }

    const onDragEnd = async ({ destination, source }) => {
        if (!destination) {
            return;
        }
        if (destination.index === source.index) {
            return;
        }

        const draggedCustomPage = customPageList[source.index];
        const newCustomPages = [...customPageList];
        newCustomPages.splice(source.index, 1);
        newCustomPages.splice(destination.index, 0, draggedCustomPage);

        const lotsToBeUpdated = destination.index < source.index ? newCustomPages.slice(destination.index, newCustomPages.length) : newCustomPages.slice(source.index, destination.index + 1);
        const sequenceStart = destination.index < source.index ? customPageList[destination.index].orderNumber : customPageList[source.index].orderNumber;

        let currentOrderNumber = sequenceStart;
        lotsToBeUpdated.forEach((lot) => {
            newCustomPages[newCustomPages.findIndex(x => x._id === lot._id)].orderNumber = currentOrderNumber;
            lot.orderNumber = currentOrderNumber;
            currentOrderNumber++;
        });

        setCustomPageList(newCustomPages);

        try {
            await customPageApi.put(CUSTOM_PAGES_PATHS.UPDATE_MULTIPLE_CUSTOM_PAGES, newCustomPages.filter(x => !x.isLotpage))

            const updatedCampaign = await campaignsApi.put(`${CAMPAIGN_API_PATHS.UPDATE_CAMPAIGN.replace('{campaignId}', campaign._id)}`,
                { ...campaign, lotPageOrderNumber: newCustomPages.find(x => x.isLotpage)?.orderNumber }, dBUser._id)

                setCampaign(updatedCampaign)


        } catch (err: any) {
            setDialogProperties({
                type: "ERROR",
                title: "Error",
                message: `Error in updating custom page order`,
                label: "FAILURE"
            })
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#DEEBFE" : "white",
        display: isDragging ? "table" : "",
        ...draggableStyle
    });

    useEffect(() => {
        if (campaign._id) {
            getCustomPages()
        }
    }, [campaign])

    return (
        <div className="custom-pages">
            <h2 className="subtitle">Custom Pages.</h2>
            <div className="description">A list of all the custom pages that have been defined. Custom pages are new pages that will display
                in the menu bar, which allow for different sets of lots to be displayed in their own page, such as raffle items or live auction items.
            </div>
            <Card >
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>
                                    <div className="table-header-cell">
                                        <span>Order</span>
                                    </div>
                                </TableCell> */}
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Label</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Path</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                    <div className="table-header-cell" style={{ display: "flex", justifyContent: "center" }}>
                                        <span>Visibility</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                    <div className="table-header-cell" style={{ display: "flex", justifyContent: "center" }}>
                                        <span>Is CTA?</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                    <div className="table-header-cell"
                                    // style={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <span>CTA Text</span>
                                    </div>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="custom-page-dnd">
                                {(provided) => (
                                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>

                                        {customPageList.length > 0 && customPageList.sort((a, b) => a.orderNumber - b.orderNumber).map((customPage: ICustomPage, index) => (
                                            <Draggable
                                                key={customPage._id}
                                                draggableId={`custom-page-${customPage._id}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (

                                                    <TableRow key={index}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        {/* <TableCell >{customPage.orderNumber}</TableCell> */}
                                                        <TableCell >{customPage.label}</TableCell>
                                                        <TableCell >{customPage.path}</TableCell>
                                                        <TableCell className="hide-on-mobile">
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                {customPage.isHidden ?
                                                                    <VisibilityOffIcon style={{ color: "darkred" }} />
                                                                    :
                                                                    <VisibilityIcon style={{ color: "green" }} />
                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="hide-on-mobile">
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                {customPage.ctaText &&
                                                                    <CheckCircleIcon style={{ color: "green" }} />
                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="hide-on-mobile">{customPage.ctaText}</TableCell>
                                                        <TableCell style={{ display: "flex", justifyContent: "center" }}>
                                                            <div className='action-cell'>
                                                                <div onClick={() => editCustomPage(customPage)}>
                                                                    <Tooltip title="Edit Page"><CreateIcon /></Tooltip>
                                                                </div>
                                                                {customPage.isLotpage ?
                                                                    <div>
                                                                        <DeleteIcon style={{ color: "gray", cursor: "unset" }} />
                                                                    </div>
                                                                    :
                                                                    <div onClick={() => openDeleteConfirmationDialog(customPage)}>
                                                                        <Tooltip title="Delete Page" enterDelay={500}><DeleteIcon /></Tooltip>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                </CardContent>
            </Card >

            <div className="fixed-action-section">
                <div></div>
                <Button className="gg-button"
                    onClick={() => setCustomPageDialogProperties({ isOpen: true, type: "NEW" })}
                >New Custom Page</Button>
            </div>


            <ConfirmationDialog
                handleClose={handleCloseDialog}
                isDialogOpen={dialogProperties.isOpen}
                message={dialogProperties.message}
                errorDetails={dialogProperties.errorDetails}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
                primaryButton={<Button onClick={() => handleCloseDialog(true)} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
                    Ok
                </Button>}
            />

            <CustomPageDialog
                isDialogOpen={customPagedialogProperties.isOpen}
                type={customPagedialogProperties.type}
                handleClose={() => handleCloseCreateCustomPageDialog()}
                customPageList={customPageList}
                customPageToBeEdited={customPageToBeEdited}
            />
        </div >
    )
}

export default CustomPages