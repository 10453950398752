import "./itemCatalogue.scss"
import React, { useState, useEffect, useContext } from "react"
import useFetch from "../../../hooks/useFetch"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Card,
	CardContent,
	TableContainer,
	Checkbox,
	Tooltip,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Avatar,
	TablePagination,
} from "@mui/material"
import _ from "lodash"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import InfoIcon from '@mui/icons-material/Info'
import ILotCatalogueFilters from "../../../interfaces/lotCatalogueFilters"
import ItemDetailsDialog from "./ItemDetailsDialog"
import { OrganisationContext } from "../../../context/OrganisationContext"
import NewCatalogueItem from "./NewCatalogueItem"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import {
	API_PATHS,
	CAMPAIGN_API_PATHS,
	CATALOGUE_API_PATHS,
	getCampaignQueryString,
	getCampaignsBySearchStringQueryString,
} from "../../../common/ApiPaths"
import ICatalogueItem from "../../../interfaces/catalogueItem"
import { UserContext } from "../../../context/UserContext"
import IPaginatedCatalogueItems from "../../../interfaces/paginatedCatalogueItems"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import LoadingSpinner from "../../shared/LoadingSpinner"
import TransferDetailsDialog from "./TransferDetailsDialog"
import { ITransferDetail } from "../../../interfaces/transferDetails"
import ItemCatalogueFilterSection from "./ItemCatalogueFilterSection"
import CreateIcon from "@mui/icons-material/Create"
import getYouTubeID from "get-youtube-id"
import YouTubeThumbnail from "../../shared/YouTubeThumbnail"
import { useLocation, useHistory } from "react-router-dom"
import ICampaign from "../../../interfaces/campaign"
import DefaultCampaign from "./DefaultCampaign"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
interface ITransferDialogProperties {
	isOpen: boolean
	items: ICatalogueItem[]
}

function useQuery() {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}

const ItemCatalogue = () => {
	const [vmsItemsList, setVmsItemsList] = useState<ICatalogueItem[]>([])
	const [paginationPage, setPaginationPage] = useState<number>(0)
	const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(25)
	const [totalCatalogueItemsCount, setTotalCatalogueItemsCount] = useState<number>(0)
	const [columnToSort, setColumnToSort] = useState("title")
	const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
	const [lotCatalogueFilters, setLotCatalogueFilters] = useState<ILotCatalogueFilters>({
		name: null,
		costPrice: null,
		sources: ["all"],
		tags: ["all"],
	} as ILotCatalogueFilters)
	const [selected, setSelected] = useState<ICatalogueItem[]>([])
	const [sourcesList, setSourcesList] = useState<string[]>([])
	const [tagsList, setTagsList] = useState<string[]>([])
	const [selectedBulkAction] = useState<string>("")
	const [itemNameList, setItemNameList] = useState<string[]>([])
	const [itemDetailsDialogObject, setItemDetailsDialogObject] = useState<any>({
		isOpen: false,
	})
	const [transferDialogProperties, setTransferDialogProperties] = useState<ITransferDialogProperties>({
		isOpen: false,
		items: [],
	} as ITransferDialogProperties)
	const [isNewItemView, setIsNewItemView] = useState<boolean>(false)
	const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "", errorDetails: "" })
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [startVmsCatalogueId, setStartVmsCatalogueId] = useState<string>("")
	const [startOrgCatalogueId, setStartOrgCatalogueId] = useState<string>("")
	const [paginationCatalogueIds, setPaginationCatalogueIds] = useState<string[]>([""])
	const [minMaxCP, setMinMaxCP] = useState<number[]>([0, 0])
	const [catalogueItemToBeEdited, setCatalogueItemToBeEdited] = useState<ICatalogueItem | null>(null)
	const [isFirstFetch, setIsFirstFetch] = useState<boolean>(true)
	const [disableFilters, setDisableFilters] = useState<boolean>(false)
	const [campaignList, setCampaignList] = useState<ICampaign[]>([])
	// const [defaultCampaignId, setDefaultCampaignId] = useState<string | null | undefined>(undefined)
	const [defaultCampaign, setDefaultCampaign] = useState<ICampaign | null>(null)
	const [transferredToCampaignSlug, setTransferredToCampaignSlug] = useState<string>('')

	const organisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)
	const catalogueApi = useFetch(API_PATHS.CATALOGUE)
	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	let query = useQuery()
	let history = useHistory()
	const commonFunctions = useCommonFunctions()

	useEffect(() => {
		const defaultCampaignParamValue = query.get("defaultCampaign")
		if (defaultCampaignParamValue) {
			getCampaign(defaultCampaignParamValue)
		}
	}, [query])

	const getCampaign = async (slug: string) => {
		try {
			let data: ICampaign = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(slug)}`)
			setCampaignList([data])
			// setDefaultCampaignId(data._id)
			setDefaultCampaign(data)
		} catch (err: any) {
			console.error(err)
		}
	}

	const getCampaignList = async (input: string) => {
		if (input.length > 2) {
			let filteredCampaignList: ICampaign[] = await campaignsApi.get(
				`${CAMPAIGN_API_PATHS.GET_BY_SEARCH_STRING.replace('{searchString}', input)}${getCampaignsBySearchStringQueryString(dBUser._id, dBUser.role, true, undefined, dBUser.organisationId)}`
			)

			setCampaignList([...filteredCampaignList])
		} else {
			setCampaignList([])
		}
	}

	const getLotCatalogueItems = async (setMinAndMaxCostPrices: boolean = false) => {
		if (organisation._id && lotCatalogueFilters.sources.length > 0) {
			try {
				setIsLoading(true)
				let result: IPaginatedCatalogueItems = await catalogueApi.get(
					`${CATALOGUE_API_PATHS.GET_CATALOGUE_ITEMS.replace(
						"{organisationId}",
						organisation._id
					)}?limit=${paginationRowsPerPage}&pageNo=${paginationPage}&startVmsCatalogueId=${startVmsCatalogueId}&startOrgCatalogueId=${startOrgCatalogueId}&name=${encodeURIComponent(
						lotCatalogueFilters.name ?? ""
					)}&sources=${lotCatalogueFilters.sources.join(",")}&tags=${encodeURIComponent(lotCatalogueFilters.tags.join(","))}&costPrice=${lotCatalogueFilters.costPrice ?? [].join(",")
					}&columnToSort=${columnToSort}&sortDirection=${sortDirection}`
				)
				setTotalCatalogueItemsCount(result.totalRecords)
				if (isFirstFetch) {
					setIsFirstFetch(false)
					if (result.totalRecords === 0) {
						setDisableFilters(true)
					}
				}
				let allItems = result.items
				if (setMinAndMaxCostPrices && minMaxCP[0] === 0 && minMaxCP[1] === 0) {
					setMinMaxCP(result.costPriceRange)
					setTagsList(result.allTags)
					setSourcesList(result.allSources)
				}
				setVmsItemsList(allItems)
				setStartVmsCatalogueId(result.startVmsCatalogueId)
				setStartOrgCatalogueId(result.startOrgCatalogueId)
				setIsLoading(false)
				window.scrollTo(0, 0)
			} catch (err: any) {
				setIsLoading(false)
				console.error(err)
			}
		} else {
			setVmsItemsList([])
		}
	}

	const deleteCatalogueItems = async () => {
		let itemsToDelete = selected.filter(x => x.source === "Org Catalogue")
		try {
			await catalogueApi.del(CATALOGUE_API_PATHS.DELETE_ITEMS, {
				itemIds: itemsToDelete.map(x => x._id),
				userId: dBUser._id,
				organisationId: organisation._id,
			})
			setDialogProperties({
				isOpen: true,
				type: "SUCCESS",
				title: `${itemsToDelete.length > 1 ? 'Items' : 'Item'} Deleted.`,
				message: `Selected catalogue ${itemsToDelete.length > 1 ? 'items have' : 'item has'} successfully been deleted.`,
			})
			let deletedIds = itemsToDelete.map(x => x._id)
			setVmsItemsList(vmsItemsList.filter(x => !deletedIds.includes(x._id)))
			setSelected([])
		} catch (err: any) {
			setDialogProperties({
				isOpen: true,
				type: "ERROR",
				title: "Deletion failed.",
				message: `Items deletion failed with some error`,
				errorDetails: err.message
			})
		}
	}

	const openItemDetailDialog = (item: ICatalogueItem) => {
		setItemDetailsDialogObject({
			isOpen: true,
			item: item,
		})
	}

	const handleSort = columnName => {
		setStartVmsCatalogueId("")
		setStartOrgCatalogueId("")
		setColumnToSort(columnName)
		let sortDirect: any = columnToSort === columnName ? (sortDirection === "asc" ? "desc" : "asc") : "desc"
		setSortDirection(sortDirect)
	}

	const handlePaginationChangePage = (event: unknown, newPage: number) => {
		if (newPage < paginationPage) {
			setPaginationCatalogueIds(paginationCatalogueIds.slice(0, paginationCatalogueIds.length - 1))
			setStartVmsCatalogueId(paginationCatalogueIds[paginationCatalogueIds.length - 2 >= 0 ? paginationCatalogueIds.length - 2 : 0] ?? 0)
		} else {
			setPaginationCatalogueIds([...paginationCatalogueIds, startVmsCatalogueId])
			setStartVmsCatalogueId(startVmsCatalogueId)
		}
		setPaginationPage(newPage)
	}

	const handlePaginationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPaginationRowsPerPage(parseInt(event.target.value, 10))
		setPaginationPage(0)
		setStartVmsCatalogueId("")
		setStartOrgCatalogueId("")
	}

	const handleClick = name => {
		const selectedIndex = selected.indexOf(name)
		let newSelected: any[] = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}

		setSelected(newSelected)
	}

	const handleCloseConfirmationDialog = (isConfirm: boolean) => {
		if (dialogProperties.type === "AREYOUSURE" && isConfirm) {
			deleteCatalogueItems()
		}
		setDialogProperties({ isOpen: false })
	}

	const handleBulkActionsChange = event => {
		if (event.target.value === "TRANSFER") {
			setTransferDialogProperties({ isOpen: true, items: selected })
		} else if (event.target.value === "BULKDELETE") {
			setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Items Delete", message: "Are you sure you want to delete the selected items?" })
		}
	}

	const transferToCampaign = async (transferDetails: ITransferDetail, items: ICatalogueItem[]) => {
		try {
			await catalogueApi.post(
				`${CATALOGUE_API_PATHS.TRANSFER_CATALOGUE_ITEMS_TO_CAMPAIGN.replace("{campaignId}", transferDetails.campaignId)}`,
				{ ...transferDetails, userId: dBUser._id, organisationId: organisation._id, items: items }
			)
			setDialogProperties({
				isOpen: true,
				type: "SUCCESS",
				title: "Items transferred to campaign.",
				message: "Selected catalogue items have successfully been transferred.",
			})
			setSelected([])
			setTransferredToCampaignSlug(transferDetails.campaignSlug)
		} catch (err: any) {
			setDialogProperties({
				isOpen: true,
				type: "ERROR",
				title: "Transferring items failed.",
				message: `Items transferring failed with some error`,
				errorDetails: err.message
			})
		}
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelected = vmsItemsList.map(item => item)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const editCatalogueItem = (item: ICatalogueItem) => {
		setCatalogueItemToBeEdited(item)
		setIsNewItemView(true)
	}

	const updateItem = (item: ICatalogueItem) => {
		let updatedVmsItems = vmsItemsList.map(x => x._id === item._id ? item : x)
		setVmsItemsList(updatedVmsItems)
		let addedTags = item.tagList.filter(x => !tagsList.includes(x))
		setTagsList([...tagsList, ...addedTags])
	}

	useEffect(() => {
		getLotCatalogueItems()
	}, [lotCatalogueFilters])

	useEffect(() => {
		getLotCatalogueItems(false)
	}, [paginationPage, paginationRowsPerPage, columnToSort, sortDirection])

	useEffect(() => {
		getLotCatalogueItems(true)
	}, [organisation])

	// useEffect(() => {
	// 	if (!isNewItemView) {
	// 		getLotCatalogueItems(false)
	// 	}
	// }, [isNewItemView])

	return (
		<>
			{isNewItemView ? (
				<NewCatalogueItem
					organisation={organisation}
					setIsNewItemView={setIsNewItemView}
					setDialogProperties={setDialogProperties}
					tagsList={tagsList}
					catalogueItemToBeEdited={catalogueItemToBeEdited}
					updateItem={updateItem}
				/>
			) : (
				<div className="item-catalogue">
					{/* <h1>Item Catalogue.</h1> */}

					<DefaultCampaign
						defaultCampaignName={defaultCampaign && defaultCampaign.name}
						setDefaultCampaign={setDefaultCampaign}
						organisationSlug={organisation.slug}
						campaignList={campaignList}
						defaultCampaign={defaultCampaign}
						getCampaignList={getCampaignList}
					// setDefaultCampaignId={setDefaultCampaignId}

					/>

					{/* {query.get("defaultCampaign") && (
						<Autocomplete
							style={{ width: "50%", marginBottom: "10px" }}
							disablePortal
							options={campaignList}
							getOptionLabel={option => option.name}
							renderInput={params => (
								<TextField
									{...params}
									label="Default Campaign"
									helperText={"Enter 3 characters to see a list of available campaigns"}
								/>
							)}
							onInputChange={(e, value) => getCampaignList(value)}
							onChange={(event: any, newValue: ICampaign | null) => {
								setDefaultCampaignId(newValue?._id)
								setDefaultCampaign(newValue)
							}}
							value={defaultCampaign}
							clearOnEscape
						/>
					)} */}

					<ItemCatalogueFilterSection
						lotCatalogueFilters={lotCatalogueFilters}
						setLotCatalogueFilters={setLotCatalogueFilters}
						sourcesList={sourcesList}
						tagsList={tagsList}
						minAndMaxCostPrice={minMaxCP}
						itemNameList={itemNameList}
						setItemNameList={setItemNameList}
						currency={organisation.currency}
						disableFilters={disableFilters}
						resetStartIds={() => {
							setStartVmsCatalogueId("")
							setStartOrgCatalogueId("")
						}}
					/>
					{!isLoading && (
						<>
							{vmsItemsList.length > 0 ? (
								<>
									<Card>
										<CardContent>
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell padding="checkbox" className="hide-on-mobile">
																<Checkbox
																	indeterminate={selected.length > 0 && selected.length < vmsItemsList.length}
																	checked={vmsItemsList.length > 0 && selected.length === vmsItemsList.length}
																	onChange={handleSelectAllClick}
																	inputProps={{ "aria-label": "select all items" }}
																/>
															</TableCell>

															<TableCell></TableCell>
															<TableCell>
																<div className="table-header-cell">
																	<span>Source</span>
																</div>
															</TableCell>
															<TableCell>
																<div className="table-header-cell">
																	<div onClick={() => handleSort("title")} className="table-header-cell sortable">
																		<span>Title</span>
																		{columnToSort === "title" ? (
																			sortDirection === "asc" ? (
																				<KeyboardArrowUpIcon />
																			) : (
																				<KeyboardArrowDownIcon />
																			)
																		) : null}
																	</div>
																</div>
															</TableCell>
															<TableCell>
																<div
																	onClick={() => handleSort("costPrice")}
																	className="table-header-cell sortable center"
																>
																	<span>Cost Price</span>
																	{columnToSort === "costPrice" ? (
																		sortDirection === "asc" ? (
																			<KeyboardArrowUpIcon />
																		) : (
																			<KeyboardArrowDownIcon />
																		)
																	) : null}
																</div>
															</TableCell>
															<TableCell>
																<div className="table-header-cell">
																	<div
																		onClick={() => handleSort("typicalSurplus")}
																		className="table-header-cell sortable"
																	>
																		<span>Typical Surplus</span>
																		{columnToSort === "typicalSurplus" ? (
																			sortDirection === "asc" ? (
																				<KeyboardArrowUpIcon />
																			) : (
																				<KeyboardArrowDownIcon />
																			)
																		) : null}
																	</div>
																</div>
															</TableCell>
															<TableCell className="hide-on-mobile"></TableCell>
														</TableRow>
													</TableHead>

													<TableBody>
														{vmsItemsList.map((item: ICatalogueItem, index) => (
															<TableRow key={index}>
																<TableCell padding="checkbox" className="hide-on-mobile">
																	<Checkbox
																		checked={selected.find(x => x._id === item._id) !== undefined}
																		inputProps={{ "aria-labelledby": item._id }}
																		onClick={() => handleClick(item)}
																	/>
																</TableCell>

																<TableCell>
																	{item.mediaList && item.mediaList.length > 0 ? (
																		item.mediaList && item.mediaList[0].mediaType === "YOUTUBE" ? (
																			<div
																				onClick={() => openItemDetailDialog(item)}
																				style={{ cursor: "pointer" }}
																			>
																				<YouTubeThumbnail
																					imageSrc={`http://i3.ytimg.com/vi/${getYouTubeID(
																						item.mediaList[0].src
																					)}/hqdefault.jpg`}
																					isInSwiper={false}
																					className=""
																				/>
																			</div>
																		) : (
																			<Avatar
																				alt={item.title}
																				src={item.mediaList[0].src}
																				style={{ borderRadius: 0, width: "100%" }}
																				onClick={() => openItemDetailDialog(item)}
																			/>
																		)
																	) : (
																		<Avatar
																			alt={item.title}
																			src={"/placeholder_image.jpg"}
																			style={{ borderRadius: 0, width: "100%" }}
																			onClick={() => openItemDetailDialog(item)}
																		/>
																	)}
																</TableCell>
																<TableCell>{item.source}</TableCell>
																<TableCell>
																	<div style={{ cursor: "pointer" }} onClick={() => openItemDetailDialog(item)}>
																		{item.title}
																	</div>
																</TableCell>
																<TableCell>
																	<div className="center">
																		<span>
																			{`${new Intl.NumberFormat("en-US", {
																				style: "currency",
																				currency: organisation.currency,
																				minimumFractionDigits: 0,
																				maximumFractionDigits: 0,
																			}).format(item.costPrice)} `}
																			<span style={{ fontSize: "0.7rem" }}>
																				{item.isVatApplicable === true ? " (VAT inclusive)" : " (+ VAT)"}
																			</span>
																		</span>
																	</div>
																</TableCell>
																<TableCell>
																	<div className="center">
																		{`${new Intl.NumberFormat("en-US", {
																			style: "currency",
																			currency: organisation.currency,
																			minimumFractionDigits: 0,
																			maximumFractionDigits: 0,
																		}).format(item.typicallySellsFor - item.costPrice)}`}
																	</div>
																</TableCell>
																<TableCell className="hide-on-mobile">
																	<div className="action-cell">
																		<div onClick={() => openItemDetailDialog(item)}>
																			<Tooltip title="View Item" enterDelay={500}>
																				<InfoIcon />
																			</Tooltip>
																		</div>
																		{item.source === "Org Catalogue" && (
																			<div onClick={() => editCatalogueItem(item)}>
																				<Tooltip title="Edit Item" enterDelay={500}>
																					<CreateIcon />
																				</Tooltip>
																			</div>
																		)}
																		<div
																			onClick={() => {
																				setTransferDialogProperties({ isOpen: true, items: [item] })
																			}}
																		>
																			<Tooltip title="Move to Campaign" enterDelay={500}>
																				<OpenInNewIcon />
																			</Tooltip>
																		</div>
																	</div>
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
												<TablePagination
													rowsPerPageOptions={[25, 50, 100]}
													component="div"
													count={totalCatalogueItemsCount}
													rowsPerPage={paginationRowsPerPage}
													page={paginationPage}
													onPageChange={handlePaginationChangePage}
													onRowsPerPageChange={handlePaginationChangeRowsPerPage}
													labelRowsPerPage="Rows/page"
												/>
											</TableContainer>
										</CardContent>
									</Card>

									{itemDetailsDialogObject.item && (
										<ItemDetailsDialog
											isDialogOpen={itemDetailsDialogObject.isOpen}
											handleClose={() =>
												setItemDetailsDialogObject({
													isOpen: false,
												})
											}
											item={itemDetailsDialogObject.item}
											currency={organisation.currency}
											setTransferDialogProperties={setTransferDialogProperties}
										/>
									)}
								</>
							) : (
								<Card>
									<CardContent>
										<div className="no-entries">No lots found</div>
									</CardContent>
								</Card>
							)}
						</>
					)}
					{isLoading && (
						<Card>
							<CardContent>
								<LoadingSpinner text="Retrieving Lots" />
							</CardContent>
						</Card>
					)}

					<div className="fixed-action-section">
						{/* <div className="button-wrapper space-between"> */}
						<div style={{ display: "flex" }}>
							<FormControl className="bulk-actions hide-on-mobile" disabled={selected.length < 1}>
								<InputLabel id="bulk-actions-label">Bulk Actions</InputLabel>
								<Select
									labelId="bulk-actions-label"
									id="bulkActions"
									name="bulkActions"
									value={selectedBulkAction}
									onChange={event => handleBulkActionsChange(event)}
									label="Bulk Actions"
								>
									<MenuItem value={"TRANSFER"}>Transfer Selection to Campaign</MenuItem>
									<MenuItem value={"BULKDELETE"} disabled={selected.filter(x => x.source === "Org Catalogue").length === 0}>
										Delete Selected
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="right-hand-buttons">
							<Button className="gg-button" onClick={() => setIsNewItemView(true)}>
								New Item
							</Button>
						</div>
					</div>
				</div>
			)}

			<ConfirmationDialog
				handleClose={handleCloseConfirmationDialog}
				isDialogOpen={dialogProperties.isOpen}
				message={dialogProperties.message}
				errorDetails={dialogProperties.errorDetails}
				title={dialogProperties.title}
				type={dialogProperties.type}
				label={dialogProperties.label}
				secondaryButton={dialogProperties.title === "Items transferred to campaign."
					?
					<Button onClick={() => setDialogProperties({ isOpen: false })} className="w-100">
						Close
					</Button>
					:
					undefined
				}
				primaryButton={dialogProperties.title === "Items transferred to campaign." ?
					<Button id="go-to-campaign-button" className="gg-button w-100" style={commonFunctions.determineButtonColor(dialogProperties.type)} onClick={() => history.push(`/organisations/${organisation.slug}/campaigns/${transferredToCampaignSlug}/lots`)}>
						View Campaign
					</Button>
					:
					<Button onClick={() => handleCloseConfirmationDialog(true)} className={`gg-button ${dialogProperties.type === 'AREYOUSURE' ? '' : 'w-100'}`} style={commonFunctions.determineButtonColor(dialogProperties.type)}>
						Ok
					</Button>
				}
			/>

			<TransferDetailsDialog
				handleClose={() => setTransferDialogProperties({ isOpen: false, items: [] })}
				isDialogOpen={transferDialogProperties.isOpen}
				items={transferDialogProperties.items}
				handleSave={transferToCampaign}
				defaultCampaign={defaultCampaign}
			/>
		</>
	)
}

export default ItemCatalogue
