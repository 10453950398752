import { ToggleButton, Tooltip } from '@mui/material'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ColorPopover from "../colorPopover/ColorPopover";
import { useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/react';

const ColorButton = ({ editor }: { editor: Editor | null }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [color, setColor] = useState("#000000");

	const divRef: any = useRef();

	function handleClick() {
		setAnchorEl(divRef.current);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	useEffect(() => {
		editor!.chain().setColor(color).run()
	}, [color]);

	const open = Boolean(anchorEl);

	return (
		<>
		  <Tooltip title="Font Colour" enterDelay={600}>
			<ToggleButton ref={divRef} value="" aria-label="color" onClick={handleClick}>
				<FormatColorTextIcon />
				<ArrowDropDownIcon />
			</ToggleButton>
		  </Tooltip>
			<ColorPopover open={open} handleClose={handleClose} anchorEl={anchorEl} color={color} setColor={setColor} />
		</>
	)
}

export default ColorButton