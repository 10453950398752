import React, { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import IUpdatedSettings from "../../../../interfaces/updatedSettings";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import CustomPageSelect from "../../../shared/customPageSelect/CustomPageSelect";
interface InputProps {
    isDialogOpen: boolean,
    handleClose: (boolean: boolean, updatedSettings: IUpdatedSettings) => void,
}

const BulkSettingsDialog = (props: InputProps) => {

    const [updatedSettings, setUpdatedSettings] = useState<IUpdatedSettings>({} as IUpdatedSettings)

    const handleDateChange = (name, date) => {
        setUpdatedSettings({ ...updatedSettings, [name]: new Date(date) })
    }

    const handleNumberChange = (event) => {
        let value = Number(event.target.value) > 0 ? Number(event.target.value).toString() : null
        setUpdatedSettings({ ...updatedSettings, [event.target.name]: value })
        event.target.value = value
    }

    const handleCustomPageChange = (event) => {
        // if (event.target.value === "lot-page") {
            // setUpdatedSettings({ ...updatedSettings, customPageId: undefined})
        // }else{
            setUpdatedSettings({ ...updatedSettings, customPageId: event.target.value as string })
        // }
    }

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={() => props.handleClose(false, updatedSettings)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Update Settings</DialogTitle>
                <DialogContent style={{ paddingTop: 20 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Start Date/Time"
                            format="HH:mm DD/MM/YYYY"
                            ampm={false}
                            value={updatedSettings.startDate ? updatedSettings.startDate : null}
                            onChange={(date: any) => handleDateChange("startDate", date)}
                            slotProps={{
                                textField: {
                                    fullWidth: true
                                }
                            }}
                        />
                        <DateTimePicker
                            label="End Date/Time"
                            format="HH:mm DD/MM/YYYY"
                            ampm={false}
                            value={updatedSettings.endDate ? updatedSettings.endDate : null}
                            onChange={(date: any) => handleDateChange("endDate", date)}
                            slotProps={{
                                textField: {
                                    fullWidth: true
                                }
                            }}
                        />

                    </LocalizationProvider>
                    <TextField
                        id="availabilityCount"
                        name="availabilityCount"
                        label="Availability Count"
                        variant="outlined"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        defaultValue={updatedSettings.availabilityCount}
                        value={updatedSettings.availabilityCount ?? null}
                        onChange={(e) => handleNumberChange(e)}
                        fullWidth
                        InputProps={{ inputProps: { min: 1 } }}
                    />
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="isHidden-label">Hide Lot?</InputLabel>
                        <Select
                            labelId="isHidden-label"
                            id="isHidden"
                            name="isHidden"
                            value={updatedSettings.isHidden ?? null}
                            onChange={(event) => {
                                const { value } = event.target
                                if (value === "true") {
                                    setUpdatedSettings({ ...updatedSettings, isHidden: true })
                                } else {
                                    setUpdatedSettings({ ...updatedSettings, isHidden: false })
                                }
                            }}
                            label="Hide Lot??"
                        >
                            <MenuItem value={'false'}>No</MenuItem>
                            <MenuItem value={'true'}>Yes</MenuItem>
                        </Select>
                    </FormControl>
                    <CustomPageSelect
                        handleChange={handleCustomPageChange}
                        isNewLot={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.handleClose(false, updatedSettings)} >
                        Cancel
                    </Button>
                    <Button onClick={() => props.handleClose(true, updatedSettings)} className="gg-button">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BulkSettingsDialog