import { Slider, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import './ggSlider.scss'

interface InputProps {
    id: string
    name: string
    defaultValue: number
    valueLabelDisplay: "on" | "off" | "auto"
    step: number
    marks: any[]
    min: number
    max: number
    label: string
    helperText: string
    onChange: (event: any, value: any) => void
}

const GGSlider = (props: InputProps) => {

    const isCancelled = useRef(false)

    useEffect(() => {

        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='gg-slider'>
            <Typography id="input-slider">
               {props.label}
            </Typography>
            <Slider
            id={props.id}
            name={props.name}
                defaultValue={props.defaultValue}
                valueLabelDisplay={props.valueLabelDisplay}
                step={props.step}
                marks={props.marks}
                min={props.min}
                max={props.max}
                aria-labelledby="input-slider"
                onChangeCommitted={(event, value) => props.onChange(props.name, value)}    
            />
            <Typography>
                {props.helperText}
            </Typography>
        </div>
    )
}

export default GGSlider