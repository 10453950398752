import { useState } from "react";
import IValidationObject from "../../../interfaces/validationObject";
import striptags from "striptags";
import {
  isValidCommunicationFromField,
  isValidEmail,
  isValidName,
} from "../../../utils/validators";

const useTestCommunicationValidation = (
  communication: any,
  messageType: string
) => {
  const [validationFields, setValidationFields] = useState<IValidationObject[]>(
    [
      { name: "to", isValid: true, validationMessage: "" },
      { name: "from", isValid: true, validationMessage: "" },
      { name: "message", isValid: true, validationMessage: "" },
      { name: "surname", isValid: true, validationMessage: "" },
      { name: "firstName", isValid: true, validationMessage: "" },
      { name: "phoneNumber", isValid: true, validationMessage: "" },
    ]
  );
  const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true);

  // Individually validate each field
  const getFieldValidation = (fieldName: string, value: string) => {
    const updatedValidationFields = validationFields.map((field) => {
      if (field.name === fieldName) {
        // Perform field-specific validation and update the error message
        let validationMessage = "";

        if (messageType === "Email") {
          if (fieldName === "from" && (!value || !value.length)) {
            validationMessage = "Please enter a valid 'From' field.";
          }

          if (
            fieldName === "message" &&
            (!value || !(striptags(value).length > 0))
          ) {
            validationMessage = `Please enter a valid message in order to dispatch an ${messageType.toLocaleLowerCase()}.`;
          }

          if (
            fieldName === "firstName" &&
            value.length > 0 &&
            !isValidName(value)
          ) {
            validationMessage = `Please enter recipients first name.`;
          }

          if (
            fieldName === "surname" &&
            value.length > 0 &&
            !isValidName(value)
          ) {
            validationMessage = `Please enter recipients surname.`;
          }
        }

        if (messageType === "SMS") {
          if (
            fieldName === "from" &&
            (!value || !isValidCommunicationFromField(value))
          ) {
            validationMessage = "Please enter a valid 'From' field.";
          }

          if (fieldName === "message" && (!value || !value.length)) {
            validationMessage = `Please enter a valid message in order to dispatch a ${messageType}.`;
          }

          if (
            fieldName === "firstName" &&
            value.length > 0 &&
            !isValidName(value)
          ) {
            validationMessage = `Please enter recipients first name.`;
          }

          if (
            fieldName === "surname" &&
            value.length > 0 &&
            !isValidName(value)
          ) {
            validationMessage = `Please enter recipients surname.`;
          }
        }

        return {
          ...field,
          isValid: validationMessage === "",
          validationMessage,
        };
      }

      return field;
    });

    setValidationFields(updatedValidationFields);
  };

  // validate input fields
  const preValidateInputs = () => {
    let tempValidationObject: IValidationObject[] = [
      { name: "from", isValid: true, validationMessage: "" },
      { name: "message", isValid: true, validationMessage: "" },
    ];

    const setFailedValidation = (name: string, message: string) => {
      tempValidationObject?.forEach((element) => {
        if (element?.name === name) {
          element.isValid = false;
          element.validationMessage = message;
        }
      });
    };

    // email validation:
    if (messageType === "Email") {
      if (
        !communication.emailData.from ||
        !communication.emailData.from.length
      ) {
        setFailedValidation("from", `Please enter a valid 'From' field.`);
      }

      if (
        !communication.emailData.message ||
        !(striptags(communication.emailData.message).length > 0)
      ) {
        setFailedValidation(
          "message",
          `Please enter a valid message in order to dispatch an email.`
        );
      }
    }

    if (messageType === "SMS") {
      if (
        !communication.smsData.from ||
        !isValidCommunicationFromField(communication.smsData.from)
      ) {
        setFailedValidation("from", `Please enter a valid from.`);
      }

      if (
        !communication.smsData.message ||
        !communication.smsData.message.length
      ) {
        setFailedValidation(
          "message",
          `Please enter a valid message in order to dispatch a SMS.`
        );
      }
    }

    const updatedVerifiedList = [
      ...tempValidationObject,
      { name: "to", isValid: true, validationMessage: "" },
      { name: "surname", isValid: true, validationMessage: "" },
      { name: "firstName", isValid: true, validationMessage: "" },
      { name: "phoneNumber", isValid: true, validationMessage: "" },
    ];

    setValidationFields(updatedVerifiedList);
  };

  // validate input fields
  const validateInputs = (): boolean => {
    let tempValidationObject: IValidationObject[] = [
      { name: "to", isValid: true, validationMessage: "" },
      { name: "from", isValid: true, validationMessage: "" },
      { name: "surname", isValid: true, validationMessage: "" },
      { name: "message", isValid: true, validationMessage: "" },
      { name: "firstName", isValid: true, validationMessage: "" },
      { name: "phoneNumber", isValid: true, validationMessage: "" },
    ];

    let tempIsValidationPassed = true;

    const setFailedValidation = (name: string, message: string) => {
      tempIsValidationPassed = false;
      tempValidationObject?.forEach((element) => {
        if (element?.name === name) {
          element.isValid = false;
          element.validationMessage = message;
        }
      });
    };

    // email validation:
    if (messageType === "Email") {
      if (
        !communication.emailData.to ||
        !isValidEmail(communication.emailData.to)
      ) {
        setFailedValidation(
          "to",
          `Please enter a valid recipient in order to dispatch an ${messageType}.`
        );
      }

      if (
        !communication.emailData.firstName ||
        communication.emailData.firstName === "" ||
        !isValidName(communication.emailData.firstName)
      ) {
        setFailedValidation("firstName", `Please enter recipients first name.`);
      }

      if (
        !communication.emailData.surname ||
        communication.emailData.surname === "" ||
        !isValidName(communication.emailData.surname)
      ) {
        setFailedValidation("surname", `Please enter recipients surname.`);
      }

      if (
        !communication.emailData.from ||
        communication.emailData.from === ""
      ) {
        setFailedValidation("from", `Please enter a valid 'From' field.`);
      }

      if (
        !communication.emailData.message ||
        !(striptags(communication.emailData.message).length > 0)
      ) {
        setFailedValidation(
          "message",
          `Please enter a valid message in order to dispatch an email.`
        );
      }
    }

    if (messageType === "SMS") {
      if (communication.smsData.phoneNumber.length === 0) {
        setFailedValidation(
          "phoneNumber",
          `Please enter a valid phone number in order to dispatch a ${messageType}`
        );
      }

      if (
        communication.smsData.firstName.length === 0 ||
        !isValidName(communication.smsData.firstName)
      ) {
        setFailedValidation("firstName", `Please enter recipients first name.`);
      }

      if (
        communication.smsData.surname.length === 0 ||
        !isValidName(communication.smsData.surname)
      ) {
        setFailedValidation("surname", `Please enter recipients surname.`);
      }

      if (!communication.smsData.from || communication.smsData.from === "") {
        setFailedValidation("from", `Please enter a valid 'From' field.`);
      }

      if (
        !communication.smsData.message ||
        communication.smsData.message === ""
      ) {
        setFailedValidation(
          "message",
          `Please enter a valid message in order to dispatch a SMS.`
        );
      }
    }

    setValidationFields(tempValidationObject);
    setIsValidationPassed(tempIsValidationPassed);
    return tempIsValidationPassed;
  };

  // reset validation
  const resetValidationFields = () => {
    setValidationFields([
      { name: "to", isValid: true, validationMessage: "" },
      { name: "from", isValid: true, validationMessage: "" },
      { name: "surname", isValid: true, validationMessage: "" },
      { name: "message", isValid: true, validationMessage: "" },
      { name: "firstName", isValid: true, validationMessage: "" },
      { name: "phoneNumber", isValid: true, validationMessage: "" },
    ]);
    setIsValidationPassed(true);
  };

  return {
    validationFields,
    setValidationFields,
    isValidationPassed,
    setIsValidationPassed,
    validateInputs,
    resetValidationFields,
    getFieldValidation,
    preValidateInputs,
  };
};

export default useTestCommunicationValidation;
