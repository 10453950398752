import { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";

interface InputProps {
	isDialogOpen: boolean;
	handleClose: (boolean: boolean, manualPaymentRef: string) => void;
}


const ManualPaymentDialog = (props: InputProps) => {

	const [manualPaymentRef, setManualPaymentRef] = useState<string>("")

	return (

		<Dialog
			open={props.isDialogOpen}
			onClose={() => props.handleClose(false, "")}
			aria-labelledby="dialog-title"
			className="manual-payment-dialog"
		>
			<DialogTitle id="form-dialog-title">Manual Payment</DialogTitle>
			<DialogContent>
				<TextField
					variant="outlined"
					id="manualPaymentReference"
					name="manualPaymentReference"
					label="Payment Reference"
					type="text"
					fullWidth
					value={manualPaymentRef}
					onChange={(event) => setManualPaymentRef(event.target.value)}
					helperText="Enter a reference to the payment, eg a PDQ Id or an invoice number."
				/>
			</DialogContent>
			<DialogActions>
				<div className="action-section">
					<div className="save-button-wrapper">
						<div className="buttons">
							<Button id="cancel-button" onClick={() => props.handleClose(false, "")}  >
								Cancel
							</Button>
							<Button id="save-button" className="gg-button" onClick={() => props.handleClose(true, manualPaymentRef)} >
								Save
							</Button>
						</div>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default ManualPaymentDialog;
