import React, { useContext, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, InputAdornment, Link, TextField } from "@mui/material"
import '../../admin/dialog.scss'
import '../../loginSignup/loginSignup.scss'
import '../../admin/login/login.scss'
import { UserContext } from "../../../context/UserContext"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import { useHistory } from "react-router-dom";
import { CampaignContext } from "../../../context/CampaignContext"
import ICampaign from "../../../interfaces/campaign"
import PinField from "./PinField"
import { Oval } from "react-loader-spinner"
import { API_PATHS, USER_API_PATHS } from "../../../common/ApiPaths"
import useFetch from "../../../hooks/useFetch"
import BackHyperlink from "../../shared/BackHyperLink"
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import './autoLogout.scss'


interface InputProps {
    isDialogOpen: boolean
    handleClose: (isPINCorrect?: boolean) => void
    secondsRemaining: number
    handleStillHere: () => void
    isAutoLogout: boolean
}

const AutoLogoutDialog = (props: InputProps) => {
    const [pin, setPin] = useState('')
    const [newPIN, setNewPIN] = useState('')
    const [validatePINError, setValidatePINError] = useState<string>('')
    const [isInProgress, setIsInProgress] = useState<boolean>(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
    const [isForgotPINView, setIsForgotPINView] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "" })
    const [changePINError, setChangePINError] = useState<string>('')
    const [isExistingPinValid, setIsExistingPinValid] = useState<boolean>(true)
    const [isNewPinValid, setIsNewPinValid] = useState<boolean>(true)
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true)

    const { dBUser, logout } = useContext(UserContext)
    const campaign: ICampaign = useContext(CampaignContext)
    const commonFunctions = useCommonFunctions()
    const history = useHistory()
    const usersApi = useFetch(API_PATHS.USERS)

    const handleClose = (loggedOut: boolean = false) => {
        setIsForgotPINView(false)
        setIsExistingPinValid(true)
        setIsNewPinValid(true)
        if (loggedOut) {
            props.handleClose()
        }
    }

    const save = async () => {
        if (!isForgotPINView) {
            const validPin = (pin && pin.length === 4)
            if (validPin) {
                setIsInProgress(true)

                try {
                    let response = await usersApi.post(`${USER_API_PATHS.VALIDATE_USER_PIN.replace('{userId}', dBUser._id)}`, { pin: pin })
                    setIsInProgress(false)

                    if (response === false) {
                        setValidatePINError('PIN incorrect.')
                    }
                    else {
                        if (props.isAutoLogout) {
                            props.handleStillHere()
                            handleClose()
                        }
                        else {
                            props.handleClose(true)
                        }
                    }
                }
                catch (error: any) {
                    setValidatePINError(error.message)
                    setIsInProgress(false)
                }
            }
            else {
                setIsExistingPinValid(false)
            }
        }
        else {
            if (!(newPIN && newPIN.length === 4)) {
                setIsNewPinValid(false)
            }
            if (!(password && password.length > 0)) {
                setIsPasswordValid(false)
            }
            if (newPIN && newPIN.length === 4 && password && password.length > 0) {
                setIsInProgress(true)

                try {
                    let response = await usersApi.put(`${USER_API_PATHS.UPDATE_USER_PIN.replace('{userId}', dBUser._id)}`, { auth0Id: dBUser.auth0Id, email: dBUser.email, newPIN: newPIN, password: password, validatePassword: true })
                    setIsInProgress(false)

                    if (response === false) {
                        setChangePINError('Password incorrect.')
                    }
                    else {
                        setDialogProperties({
                            isOpen: true,
                            type: "SUCCESS",
                            title: "PIN changed successfully.",
                            message: "Your PIN has been updated successfully.",
                        })
                        setChangePINError('')
                        setValidatePINError('')
                        setIsForgotPINView(false)
                        props.handleClose()
                    }
                }
                catch (error: any) {
                    setChangePINError(error.message)
                    setIsInProgress(false)
                }
            }
        }

    }

    const togglePasswordVisibility = (passwordField: 'password') => {
        var x = document.getElementById(passwordField)
        if (x) {
            setIsPasswordVisible(!isPasswordVisible)

            if (x.getAttribute('type') === "password") {
                x.setAttribute('type', 'text')
            } else {
                x.setAttribute('type', 'password')
            }
        }
    }


    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={() => handleClose(false)}
                className="login-dialog auto-logout-dialog"
            >
                <DialogContent>
                    {isForgotPINView ? (
                        <>
                            <BackHyperlink onClickFunction={() => {
                                setChangePINError('')
                                setValidatePINError('')
                                setIsForgotPINView(false)
                            }}
                                text={'Back to Enter Your PIN'}
                            />
                            <h2>Forgot PIN</h2>
                            <div className="field-wrapper">
                                <TextField
                                    variant="outlined"
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    // value={existingPassword}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    helperText={!isPasswordValid ? 'Please enter a valid password' : ''}
                                    error={!isPasswordValid}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="start">
                                                {!isPasswordVisible ?
                                                    <VisibilityIcon onClick={() => togglePasswordVisibility('password')} style={{ cursor: 'pointer' }} />
                                                    :
                                                    <VisibilityOffIcon onClick={() => togglePasswordVisibility('password')} style={{ cursor: 'pointer' }} />
                                                }
                                            </InputAdornment>
                                    }}
                                />
                            </div>

                            <div className="field-wrapper mt-30">
                                <PinField
                                    label={"New PIN"}
                                    pin={newPIN}
                                    setPin={setNewPIN}
                                    pinId={'newPin'}
                                    helperText={isNewPinValid ? '' : 'Please enter a valid PIN'}
                                    isValid={isNewPinValid}
                                />
                            </div>
                        </>

                    ) : (
                        <>
                            <h2>{props.isAutoLogout ? 'Auto Logout' : 'Enter Your PIN'}</h2>
                            <span className="intro-text">
                                You are currently logged in as: <b>{commonFunctions.titleCase(`${dBUser.firstName} ${dBUser.surname}`)}</b>
                                <br />
                                Not you?{" "}
                                <Link href="#" onClick={() => {
                                    logout()
                                    history.push(`/${campaign.slug}?logout`)
                                    handleClose(true)
                                }}>Click here to log out</Link>
                                <br />
                                <br />
                                {props.isAutoLogout ? (
                                    <>
                                        You will be logged out in <b>{props.secondsRemaining}</b>s due to inactivity.
                                        <br />
                                        Please enter your 4 digit PIN below to stay logged in.
                                    </>
                                ) : (
                                    <>
                                        For added security please enter your four digit PIN below.
                                    </>
                                )}

                            </span>
                            <div className="field-wrapper">
                                <PinField
                                    label={"PIN"}
                                    pin={pin}
                                    setPin={setPin}
                                    pinId={'pin'}
                                    helperText={isExistingPinValid ? '' : 'Please enter a valid PIN'}
                                    isValid={isExistingPinValid}
                                />
                            </div>

                            <br />
                            <div className="field-wrapper forgot-password-link">
                                <Link style={{ cursor: 'pointer' }} onClick={() => {
                                    setIsForgotPINView(true)
                                    setValidatePINError('')
                                }}>Forgot my PIN</Link>
                            </div>
                        </>

                    )}


                </DialogContent>
                <DialogActions>
                    <div className="save-button-wrapper action-section">
                        {changePINError && changePINError.length > 0 && <span className="validation-text">{changePINError}</span>}
                        {validatePINError && validatePINError.length > 0 && <span className="validation-text">{validatePINError}</span>}

                        <div className="button-wrapper">
                            {!props.isAutoLogout && (
                                <Button onClick={() => {
                                    setIsExistingPinValid(true)
                                    setIsNewPinValid(true)
                                    setIsPasswordValid(true)
                                    setIsForgotPINView(false)
                                    props.handleClose(false)
                                    setChangePINError('')
                                    setValidatePINError('')
                                }} className="cancel" id="confirm-pin-dialog-cancel-btn">
                                    Cancel
                                </Button>
                            )}

                            <Button
                                onClick={() => save()}
                                className="gg-button"
                            >
                                {isInProgress ? (
                                    <Oval ariaLabel="loading-indicator" height={20} width={20} strokeWidth={5} color="white" secondaryColor="white" />
                                )
                                    :
                                    (
                                        <span>Confirm</span>
                                    )}
                            </Button>

                        </div>
                    </div>
                </DialogActions>
            </Dialog>

            <ConfirmationDialog
                handleClose={() => setDialogProperties({ ...dialogProperties, isOpen: false })}
                isDialogOpen={dialogProperties.isOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
                primaryButton={<Button onClick={() => setDialogProperties({ ...dialogProperties, isOpen: false })} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
                    Ok
                </Button>}
            />
        </>
    )
}

export default AutoLogoutDialog