import CircleIcon from '@mui/icons-material/Circle';
import { InputAdornment, TextField } from '@mui/material';
import ColorPopover from '../colorPopover/ColorPopover';
import { useEffect, useRef, useState } from 'react';

const ColorInput = ({ name, label, value, handleChange, helperText }: { name: string, label: string, value: string, handleChange: (name: string, value: string) => void, helperText?: string }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const divRef: any = useRef();

    function handleClick() {
        setAnchorEl(divRef.current);
    }

    function handleClose() {
        setAnchorEl(null);
    }


    const open = Boolean(anchorEl);


    return (
        <>
            <TextField
                name={name}
                label={label}
                variant="outlined"
                value={value || ""}
                onChange={event => handleChange(event.target.name, event.target.value)}
                helperText={helperText && helperText}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" onClick={handleClick}>
                            <CircleIcon htmlColor={value} fontSize='large' sx={{
                                stroke: "#cccccc",
                                strokeWidth: 0.5,
                                cursor: "pointer"
                            }}  ref={divRef} />
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
            <ColorPopover open={open} handleClose={handleClose} anchorEl={anchorEl} color={value} setColor={(color: string) => handleChange(name, color)} />
        </>
    )
}

export default ColorInput