import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import { API_PATHS, ORGANISATIONS_API_PATHS } from "../common/ApiPaths";
import IOrganisation from "../interfaces/organisation";

const OrganisationContext = createContext({} as IOrganisation);
const OrganisationDispatchContext = createContext({} as Dispatch<SetStateAction<IOrganisation>>);

const OrganisationProvider = ({ children }) => {
    const [organisation, setOrganisation] = useState<IOrganisation>({} as IOrganisation)
    const organisationApi = useFetch(API_PATHS.ORGANISATIONS);
    let { organisationSlug } = useParams();

    const getOrganisation = async () => {
        try {
            const result = await organisationApi.get(`${ORGANISATIONS_API_PATHS.GET_ORGANISATION}?organisationSlug=${organisationSlug}`)
            setOrganisation(result)
        }
        catch (err: any) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (organisationSlug && organisationSlug.length) {
            getOrganisation()
        }
    }, [organisationSlug])


    return (
        <OrganisationContext.Provider value={organisation}>
            <OrganisationDispatchContext.Provider value={setOrganisation}>
                {children}
            </OrganisationDispatchContext.Provider>
        </OrganisationContext.Provider>
    );
}

export { OrganisationProvider, OrganisationContext, OrganisationDispatchContext };