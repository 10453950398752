import React, { useEffect, useState } from 'react'
import './bigScreenCountdownTimer.scss'
import Countdown from 'react-countdown'
import moment from 'moment'
import { IBigScreenPayload } from '../../../interfaces/bigScreenPayload'

interface InputProps {
    // bigScreenPayload: IBigScreenPayload
    countdownStartDate: Date
    countdownEndDate: Date
    campaignNotYetOpenTitle: string
    countdownClosedMessage: string
    campaignLiveTitle: string
}

const BigScreenCountdownTimer = (props: InputProps) => {

    const [isClosed, setIsClosed] = useState<boolean>(false)
    const [isNotYetOpen, setIsNotYetOpen] = useState<boolean>(false)

    const checkTimerStatus = () => {
        if (moment(props.countdownStartDate).isAfter(moment())) {
            setIsNotYetOpen(true)
        } else {
            setIsNotYetOpen(false)
            
            if (moment(props.countdownEndDate).isBefore(moment())) {
                setIsClosed(true)
            } else {
                setIsClosed(false)
            }
        }

    }

    const CountdownTimer = () => {
        if (isNotYetOpen) {
            return (
                // <span> Opens: {moment(props.countdownStartDate).format("hh:mm DD/MM/yyyy")}</span>
                <>
                    <span className='title'>{props.campaignNotYetOpenTitle}</span>
                    <div className='timer'>
                        <Countdown
                            date={props.countdownStartDate}
                            renderer={countdownProps => {
                                return (
                                    <div className="countdown-timer">
                                        {countdownProps.days > 0 && <span>{countdownProps.days}d&nbsp;</span>}
                                        {countdownProps.hours > 0 && <span>{countdownProps.hours}h&nbsp;</span>}
                                        {countdownProps.days < 1 && countdownProps.minutes > 0 && <span>{countdownProps.minutes}m&nbsp;</span>}
                                        <span>{countdownProps.seconds}s&nbsp;</span>
                                    </div>
                                )
                            }}
                            onComplete={() => setIsNotYetOpen(false)}
                        />
                    </div>
                </>
            )
        } else if (isClosed) {
            return (
                <span className='closed'>{props.countdownClosedMessage}</span>
            )
        } else {
            return (
                <>
                    <span className='title'>{props.campaignLiveTitle}</span>
                    <div className='timer'>
                        <Countdown
                            date={props.countdownEndDate}
                            renderer={countdownProps => {
                                return (
                                    <div className="countdown-timer">
                                        {countdownProps.days > 0 && <span>{countdownProps.days}d&nbsp;</span>}
                                        {countdownProps.hours > 0 && <span>{countdownProps.hours}h&nbsp;</span>}
                                        {countdownProps.days < 1 && countdownProps.minutes > 0 && <span>{countdownProps.minutes}m&nbsp;</span>}
                                        <span>{countdownProps.seconds}s&nbsp;</span>
                                    </div>
                                )
                            }}
                            onComplete={() => setIsClosed(true)}
                        />
                    </div>
                </>
            )
        }
    }

    useEffect(() => {

        checkTimerStatus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <div className='bigscreen-countdown-timer'>
            <CountdownTimer />
        </div>
    )
}

export default BigScreenCountdownTimer