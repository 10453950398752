import React from 'react'
import ICampaign from '../../../../interfaces/campaign'
import { FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import IValidationObject from '../../../../interfaces/validationObject'

interface InputProps {
    campaign: ICampaign
    setCampaign: (campaign: ICampaign) => void
    validationObject: IValidationObject[]
}

const NotificationsSection = (props: InputProps) => {

    const handleChange = (name, value) => {
        props.setCampaign({ ...props.campaign, [name]: value })
    }

    const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		props.validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}


    return (
        <div className="notifications-section">
            <h2 className="subtitle">Notifications.</h2>
            {/* <span className="description"></span> */}
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <FormControl variant="outlined">
                        <InputLabel id="notification-type-label">Notification Type</InputLabel>
                        <Select
                            labelId="notification-type-label"
                            id="notificationType"
                            name="notificationType"
                            defaultValue={props.campaign.notificationType || ""}
                            onBlur={event => handleChange(event.target.name, event.target.value)}
                            label="Notification Type"
                        >
                            <MenuItem value={"NONE"}>None</MenuItem>
                            <MenuItem value={"SMS"}>SMS</MenuItem>
                            <MenuItem value={"EMAIL"}>Email</MenuItem>
                            <MenuItem value={"BOTH"}>Both</MenuItem>
                        </Select>
                        <FormHelperText>How will bidders receive auction notifications. Eg bid confirmation notifications.</FormHelperText>
                    </FormControl>
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="smsSender"
                        name="smsSender"
                        label="SMS/Email Sender"
                        variant="outlined"
                        defaultValue={props.campaign.smsSender || ""}
                        onBlur={event => handleChange(event.target.name, event.target.value)}
                        helperText={getValidation("smsSender").isValid ?
                            "Custom Sender name in case of SMS & Email Notifications. Must be between 4 to 11 alphanumeric characters including at least one letter." :
                            getValidation("smsSender").validationMessage}
                        multiline
                        rows={1}
                        error={!getValidation("smsSender").isValid}
                    />
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="customBidConfirmationSuffix"
                        name="customBidConfirmationSuffix"
                        label="Custom Bid Confirmation Text"
                        variant="outlined"
                        defaultValue={props.campaign.customBidConfirmationSuffix || ""}
                        onBlur={event => handleChange(event.target.name, event.target.value)}
                        helperText="Custom text that will appear at the end of bid confirmation SMS / email notifications."
                        multiline
                        rows={1}
                    />
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="customPurchaseConfirmationSuffix"
                        name="customPurchaseConfirmationSuffix"
                        label="Custom Purchase Confirmation Text"
                        variant="outlined"
                        defaultValue={props.campaign.customPurchaseConfirmationSuffix || ""}
                        onBlur={event => handleChange(event.target.name, event.target.value)}
                        helperText="Custom text that will appear at the end of purchase confirmation SMS / email notifications."
                        multiline
                        rows={1}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="customWinnerConfirmationSuffix"
                        name="customWinnerConfirmationSuffix"
                        label="Custom Winner Confirmation Text"
                        variant="outlined"
                        defaultValue={props.campaign.customWinnerConfirmationSuffix || ""}
                        onBlur={event => handleChange(event.target.name, event.target.value)}
                        helperText="Custom text that will appear at the end of winner SMS / email notifications."
                        multiline
                        rows={1}
                    />
                </div>
            </Paper>
        </div>
    )
}

export default NotificationsSection