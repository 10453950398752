import { Container } from "@mui/material";
import React, { useContext, useEffect } from "react"
import { CampaignContext } from "../../context/CampaignContext";
import CampaignFooter from "./CampaignFooter";
import CampaignMenuBar from "./CampaignMenuBar";

const PrivacyPolicy = () => {
    const campaign = useContext(CampaignContext)

    useEffect(() => {
        document.title = 'Privacy Policy';
    }, [])

    const renderPrivacyPolicy = () => {
        return (
            <Container>
                <h1>Privacy Policy</h1>
                <p>
                    This Privacy Policy sets out our commitment to protecting the privacy of
                    personal information provided to us, or otherwise collected by us, offline
                    or online, including via our mobile application (Site). In this Privacy
                    Policy we, us or our means Uptick Group Limited, Company Registration No:
                    13598280.
                </p>
                <h2>Our commitment to you</h2>
                <p>Your personal information will:</p>
                <ul>
                    <li>Be processed lawfully, fairly and in a transparent manner by us;</li>
                    <li>
                        only be collected for the specific purposes we have identified in this
                        Privacy Policy and personal information will not be further processed in a
                        manner that is incompatible with the purposes we have identified;
                    </li>
                    <li>
                        be collected in a way that is adequate, relevant and limited to what is
                        necessary in relation to the purpose for which the personal information is
                        processed;
                    </li>
                    <li>
                        be kept up to date, where it is possible and within our control to do so;
                    </li>
                    <li>
                        be kept in a form which permits us to identify you, but only for so long
                        as necessary for the purposes for which the personal information was
                        collected;
                    </li>
                    <li>
                        be processed securely and in a way that protects against unauthorised or
                        unlawful processing and against accidental loss, destruction or damage.
                    </li>
                </ul>
                <h2>Personal information we collect when you register for a campaign</h2>
                <p>
                    The types of personal information we may collect about you when you
                    register, include:
                </p>
                <ul>
                    <li>your first and last name;</li>
                    <li>your mobile phone number;</li>
                    <li>your contact details, including email address and postal address;</li>
                    <li>
                        bids, donations, pledges and purchases that you make through our platform;
                    </li>
                </ul>
                <p>
                    You may also be asked to provide credit card information. When this
                    information is provided it is collected and processed via our payment
                    partner. We do not store or have access to any of this billing information
                    and you should visit the provider’s website for details of how they use and
                    process your information.{" "}
                    <a href="https://stripe.com">https://stripe.com</a>
                </p>
                <p>
                    We may collect these types of personal information directly from you or from
                    third parties.
                </p>
                <h2>Cookies</h2>
                <p>
                    Cookies are text files placed in your computer's browser to store your
                    preferences. Cookies, by themselves, do not tell us your email address or
                    other personally identifiable information.
                </p>
                <ul>
                    <li>
                        We use cookies to improve the running of the services, such as remembering
                        your login credentials so that you do not need to login every time you use
                        the platform. Stripe also use cookies to facilitate their service via our
                        site.
                    </li>
                    <li>
                        We also use Google Analytics cookies. These cookies collect standard
                        information about visitors to the sites and their behaviour (e.g. what
                        pages they viewed). The data provided by Google Analytics is anonymised
                        and in no way enables us to identify individual visitors, however, Google
                        Analytics will place a cookie on your device to enable the service. For
                        more information about how Google Analytics cookies work on websites
                        visit:{" "}
                        <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                            https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
                        </a>
                    </li>
                </ul>
                <h2>Collection and use of personal information</h2>
                <p>
                    The personal information we collect, store and use is required for the
                    following purposes:
                </p>
                <ul>
                    <li>
                        to enable you to access and use our Site, associated applications and
                        associated social media platforms;
                    </li>
                    <li>to fulfil the supply of prizes purchased and won by you;</li>
                    <li>
                        to contact and communicate with you for the purposes of operating the
                        service;
                    </li>
                    <li>
                        for internal record keeping and administrative purposes, invoicing and
                        billing;
                    </li>
                    <li>
                        for analytics, market research and business development, including to
                        operate and improve our Site, associated applications and associated
                        social media platforms;
                    </li>
                    <li>
                        to comply with our legal obligations and resolve any disputes that we may
                        have.
                    </li>
                </ul>
                <p>
                    We will generally only process your personal information for the purposes of
                    delivering the service to you. Where we may process your data for other
                    purposes we will always seek your consent.
                </p>
                <h2>Disclosure of personal information to third parties</h2>
                <p>
                    We will only share personal information with third parties, if required by
                    law or if you provide us with permission to do so. To provide the service to
                    you, we may disclose your personal information to:
                </p>
                <ul>
                    <li>
                        fundraising organisations that use our platform to conduct their
                        fundraising activities.
                    </li>
                    <li>
                        third party service providers for the purpose of enabling them to provide
                        their services, including (without limitation) IT service providers, prize
                        suppliers, delivery personnel, data storage, web-hosting and server
                        providers, debt collectors, maintenance or problem-solving providers,
                        professional advisors and payment systems operators;
                    </li>
                    <li>
                        anyone to whom our business or assets (or any part of them) are, or may
                        (in good faith) be, transferred; courts, tribunals, regulatory authorities
                        and law enforcement officers, as required by law, in connection with any
                        actual or prospective legal proceedings, or in order to establish,
                        exercise or defend our legal rights;
                    </li>
                </ul>
                <p>
                    Upon written request, we may provide you with a list of the third parties we
                    use to process your personal information.
                </p>
                <h2>Your rights and controlling your personal information</h2>
                <p>
                    Choice and consent: Where we are processing your data and needed to ask your
                    permission to do so, you are able to withdraw your consent at any time. We
                    will immediately stop processing your data. You can contact us, using the
                    contact details below.
                </p>
                <p>
                    If you wish to raise concerns about the way we are processing your data or
                    would like to raise an objection, then please contact us using the contact
                    details below, with your concerns.
                </p>
                <p>
                    Access to your data: You have the right to ask us about what data we hold
                    about you, how we process it and provide you with a copy of the information,
                    free of charge and within one month of your request.
                </p>
                <p>
                    To make a request for any personal information we hold and process about
                    you, we would prefer it if you could put it in writing or in an email to the
                    addresses below. We will need to verify your identity before providing the
                    information and where necessary may contact you further to ensure we
                    understand what data you are requesting.
                </p>
                <p>
                    Correction: If you believe that any information, we hold about you is
                    inaccurate, out of date, incomplete, irrelevant or misleading, please
                    contact us using the details below. We will take reasonable steps to correct
                    any information found to be inaccurate, incomplete, irrelevant, misleading
                    or out of date.
                </p>
                <p>
                    Complaints: If you wish to make a complaint, please contact us using the
                    details below and provide us with full details of the complaint. We will
                    promptly investigate your complaint and respond to you, in writing, setting
                    out the outcome of our investigation and the steps we will take to deal with
                    your complaint. You also have the right to make a complaint to the relevant
                    data protection authority in the country in which you are based, such as the
                    Information Commissioner’s Office in the United Kingdom.
                </p>
                <p>
                    Erasure of your data (the “right to be forgotten”): Under some circumstances
                    you may request us to delete your data from our systems. Where this is
                    possible (e.g. we don’t have any legal purpose for continuing to process
                    your data) we will erase it from our systems.
                </p>
                <p>
                    If you wish to exercise your right to be forgotten, please contact us via
                    the contact details below.
                </p>
                <p>
                    Portability: Your right to portability allows you to request a
                    machine-readable format of the data you supplied to us and associated
                    service logs (where we store them). Please contact us, using the contact
                    details below, if you wish to receive a CSV export of your data.
                </p>
                <h2>Storage and security</h2>
                <p>
                    We are committed to ensuring that the personal information we collect is
                    secure. In order to prevent unauthorised access or disclosure, we have put
                    in place suitable physical, electronic and managerial procedures, to
                    safeguard and secure personal information and protect it from misuse,
                    interference, loss and unauthorised access, modification and disclosure.
                    Additionally, we ensure our staff are appropriately trained in and are
                    responsible for following good data protection practices.
                </p>
                <p>
                    We cannot guarantee the security of any information that is transmitted to
                    or by us over the Internet. The transmission and exchange of information is
                    carried out at your own risk. Although we take measures to safeguard against
                    unauthorised disclosures of information, we cannot assure you that the
                    personal information we collect will not be disclosed in a manner that is
                    inconsistent with this Privacy Policy.
                </p>
                <h2>Links to other websites</h2>
                <p>
                    Our Site may contain links to other websites. We do not have any control
                    over those websites and we are not responsible for the protection and
                    privacy of any personal information which you provide whilst visiting those
                    websites. Those websites are not governed by this Privacy Policy.
                </p>
                <h2>How to contact us</h2>
                <p>
                    For any questions or notices, please contact us at:{" "}
                    <a href="mailto:hello@uptick.group">hello@uptick.group</a>
                </p>
                <h2>Amendments</h2>
                <p>
                    We may change or update elements of this privacy notice from time to time or
                    as required by law.
                </p>
            </Container>
        )
    }

    return (
        <>
            <div>
                {campaign && campaign._id ? (
                    <>
                        <CampaignMenuBar campaign={campaign} />
                        <div className="header-section" style={{ backgroundColor: campaign.headerBackgroundColor, color: campaign.headerFontColor }}>
                            <span>Privacy Policy</span>
                        </div>
                        <div className="content" style={{ paddingBottom: '120px' }}>
                            {renderPrivacyPolicy()}
                        </div>
                        <footer>
                            <CampaignFooter campaign={campaign} isFixedBidSection={false} />
                        </footer>
                    </>
                ) :
                    renderPrivacyPolicy()
                }


            </div>


        </>
    )
}

export default PrivacyPolicy
