import { useState } from "react";
import IValidationObject from "../../../interfaces/validationObject";
import { ICustomPage } from "../../../interfaces/customPage";

const useCustomPageValidation = () => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([
        { name: "label", isValid: true, validationMessage: "" },
        { name: "path", isValid: true, validationMessage: "" },
        { name: "ctaText", isValid: true, validationMessage: ""}
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const resetValidation = () => {
        setValidationObject([
            { name: "label", isValid: true, validationMessage: "" },
            { name: "path", isValid: true, validationMessage: "" },
            { name: "ctaText", isValid: true, validationMessage: ""}
        ])
        setIsValidationPassed(true)
    }

    const validateInputs = (newCustomPage: ICustomPage, existingCustomPageList: ICustomPage[], type: "NEW" | "EDIT", isCTA: boolean): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "label", isValid: true, validationMessage: "" },
            { name: "path", isValid: true, validationMessage: "" },
            { name: "ctaText", isValid: true, validationMessage: ""}
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (newCustomPage.label === "" || typeof (newCustomPage.label) === "undefined") {

            setFailedValidation("label", "Please provide a label for the page.")
        }else if (type === "NEW"){
            existingCustomPageList.forEach(existingCustomPage => {
                if (newCustomPage.label === existingCustomPage.label) {
                    setFailedValidation("label", "A page with this label already exists.")
                }
            });
        }

        if (newCustomPage.path === "" || typeof (newCustomPage.path) === "undefined") {
            setFailedValidation("path", "Please provide a path for the page.")
        }else if (type === "NEW"){
            existingCustomPageList.forEach(existingCustomPage => {
                if (newCustomPage.path === existingCustomPage.path) {
                    setFailedValidation("path", "A page with this path already exists.")
                }
            });
        }

        if (isCTA && (newCustomPage.ctaText === "" || typeof (newCustomPage.ctaText) === "undefined")) {
            setFailedValidation("ctaText", "Please provide CTA text.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        resetValidation
    }
}

export default useCustomPageValidation