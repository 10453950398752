import { Page, Text, View, Document, StyleSheet, Image, Font, Svg, Polygon, Rect, Path } from '@react-pdf/renderer';
import ILot from '../interfaces/lot';
import Html from 'react-pdf-html';
import MasterCardLogo from '../images/mastercard-logo-official.png'
import VisaLogo from '../images/visa-logo-official.png'
import PlaceholderImage from '../images/placeholder_image.png'

const usePdfGenerator = () => {
    let allFontFamilies = [
        'OpenSans'
    ]

    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
      });

    allFontFamilies.forEach((fontFamily) => {
        Font.register({
            family: fontFamily,
            fonts: [
                {
                    src: `/fonts/${fontFamily}/${fontFamily}-Regular.ttf`,
                    fontWeight: 400,
                },
                {
                    src: `/fonts/${fontFamily}/${fontFamily}-Bold.ttf`,
                    fontWeight: 700,
                },
                {
                    src: `/fonts/${fontFamily}/${fontFamily}-Italic.ttf`,
                    fontStyle: 'italic',
                },
            ],
        });
    });

    const styles = StyleSheet.create({
        header: {
            display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", padding: 4,
            margin: "6px 0", borderStyle: 'solid', borderTopWidth: 1, borderBottomWidth: 1
        },
        blankHeaderSpacer: {
            height: '20px'
        },
        body: {
            paddingLeft: 36, paddingRight: 36, display: "flex", flexDirection: "column", justifyContent: "space-between",
            flexGrow: 1
        },
        footer: {
            display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center"
        },
        lotWrapper: {
            display: "flex", flexDirection: "row", overflow: "hidden", height: 245, 
        },
        imageWrapper: {
            width: 350,
            height: 245,
            display: "flex", flexDirection: "column", position: "relative"
        },
        bidWrapper: {
            display: "flex", width: "95%", flexDirection: "row", flexGrow: 1
        },

        minimumBidWrapper: {
            display: "flex", flexDirection: "column", fontSize: 8, fontFamily: "OpenSans", fontWeight: 400,
            padding: '6px', justifyContent: "space-evenly", flex: "1 1"
        },
        yourBidWrapper: {
            display: "flex", flexGrow: 1, fontSize: 8, fontFamily: "OpenSans", fontWeight: 400,
            padding: 6, justifyContent: "center", borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1, borderStyle: "solid", flex: "1 1"
        },

        detailsWrapper: {
            display: "flex", width: 355, maxHeight: 300,
        },
        lotDivider: { width: '100%', height: "1px", marginTop: "12px", marginBottom: "5px" },

        content: { display: "flex", flexGrow: 1, justifyContent: "space-between" },
        numberOfPeople: {
            fontSize: 8, padding: "6px", display: "flex", position: "absolute", right: 0
        },
        cropMark: {
            position: "absolute",
            borderStyle: "solid",
            borderColor: "black",
            zIndex: 1000
        }
    });

    const lotDescriptionStylesheet = {
        p: {
            fontSize: 7,
            fontFamily: 'OpenSans',
            fontWeight: 400,
            whiteSpace: "pre-line",
            margin: 0
        },
        span: {
            fontSize: 7,
            fontFamily: 'OpenSans',
            fontWeight: 400,
            whiteSpace: "pre-line",
            margin: 0
        },
        ul: {
            fontSize: 7,
            fontFamily: 'OpenSans',
            fontWeight: 400,
            whiteSpace: "pre-line",
        },
        ol: {
            fontSize: 7,
            fontFamily: 'OpenSans',
            fontWeight: 400,
        },
        br: {
            fontSize: 2
        }
    };

    const generateBrochurePdf = (
        lotList: ILot[],
        currency: string,
        headerText: string,
        footerText: string,
        backgroundColor: string,
        fontColor: string,
        frontPageImageUrl?: string,
        backPageImageUrl?: string,
        termsAndConfitionsHtml?: string
    ): any => {

        // Tidy up messy html from VMS
        let tidyLotList: ILot[] = []

        lotList.forEach(lot => {
            let tidyDescription = ""

            // console.log(lot.description)

            if (lot.description) {
                tidyDescription = lot.description
                    .replaceAll('&lsquo;', "'")
                    .replaceAll('&rsquo;', "'")
                    .replaceAll('</p>', "</p><p><br></p>")
                    .replaceAll('</li>', "</li><br>")
            }

            console.log(tidyDescription)

            const htmlNode = document.createElement('div');
            htmlNode.innerHTML = tidyDescription;
            htmlNode.querySelectorAll('*').forEach(function (node) {
                node.removeAttribute('style');
            });

            tidyLotList.push({ ...lot, description: htmlNode.innerHTML })
        });

        const numberOfLotPages = Math.ceil(lotList.length / 2)

        const determineLotNumber = (pageIndex: number, lotPerPageIndex: number): number => {
            return ((2 * pageIndex) + lotPerPageIndex)
        }

        const determineNumberOfLotsPerPage = (totalLotCount: number, lotsPerPageCount: number, pageIndex: number) => {
            if (pageIndex + 1 < numberOfLotPages) {
                return lotsPerPageCount
            } else {
                return Math.round(lotsPerPageCount * (1 - (Math.ceil(totalLotCount / lotsPerPageCount) - (totalLotCount / lotsPerPageCount))))
            }
        }

        return (
            <Document>
                {frontPageImageUrl ?
                    <Page size={[618, 618]}  >

                        <View style={{zIndex: 1000}}>
                            <View id="top-left-left" style={[styles.cropMark, { width: 15, top: 0, left: -23, borderBottomWidth: 1 }]}></View>
                            <View id="top-left-top" style={[styles.cropMark, { height: 15, top: -23, left: 0, borderRightWidth: 1 }]}></View>
                            <View id="top-right-right" style={[styles.cropMark, { width: 15, top: 0, right: -23, borderBottomWidth: 1 }]}></View>
                            <View id="top-right-top" style={[styles.cropMark, { height: 15, top: -23, right: 0, borderRightWidth: 1 }]}></View>
                            <View id="bottom-right-right" style={[styles.cropMark, { width: 15, top: 572, right: -23, borderTopWidth: 1 }]}></View>
                            <View id="bottom-right-bottom" style={[styles.cropMark, { height: 15, top: 580, right: 0, borderLeftWidth: 1 }]}></View>
                            <View id="bottom-left-left" style={[styles.cropMark, { width: 15, top: 572, left: -23, borderBottomWidth: 1 }]}></View>
                            <View id="bottom-left-bottom" style={[styles.cropMark, { height: 15, top: 580, left: 0, borderLeftWidth: 1 }]}></View>
                        </View>


                        <Image
                            src={frontPageImageUrl}
                            style={{ width: '100%', height: '100%', zIndex: -1 }}
                        />
                    </Page>
                    :
                    null
                }
                {new Array(numberOfLotPages).fill("").map((_, pageIndex) => {
                    return (
                        <Page key={pageIndex} size={[618, 618]} style={{ padding: 23 }}>

                            <View id="crop-marks">
                                <View id="top-left-left" style={[styles.cropMark, { width: 15, top: 0, left: -23, borderBottomWidth: 1 }]}></View>
                                <View id="top-left-top" style={[styles.cropMark, { height: 15, top: -23, left: 0, borderRightWidth: 1 }]}></View>
                                <View id="top-right-right" style={[styles.cropMark, { width: 15, top: 0, right: -23, borderBottomWidth: 1 }]}></View>
                                <View id="top-right-top" style={[styles.cropMark, { height: 15, top: -23, right: 0, borderRightWidth: 1 }]}></View>
                                <View id="bottom-right-right" style={[styles.cropMark, { width: 15, top: 572, right: -23, borderTopWidth: 1 }]}></View>
                                <View id="bottom-right-bottom" style={[styles.cropMark, { height: 15, top: 580, right: 0, borderLeftWidth: 1 }]}></View>
                                <View id="bottom-left-left" style={[styles.cropMark, { width: 15, top: 572, left: -23, borderBottomWidth: 1 }]}></View>
                                <View id="bottom-left-bottom" style={[styles.cropMark, { height: 15, top: 580, left: 0, borderLeftWidth: 1 }]}></View>
                            </View>

                            <View style={styles.body}>

                                {headerText ?
                                    <View style={[styles.header, { color: backgroundColor, borderColor: backgroundColor }]}>
                                        <Text style={{
                                            fontSize: 10, fontFamily: "OpenSans", fontWeight: 700
                                        }}>
                                            {headerText}
                                        </Text>
                                    </View>
                                    :
                                    <View style={styles.blankHeaderSpacer}></View>
                                }

                                <View style={styles.content}>

                                    {new Array(determineNumberOfLotsPerPage(lotList.length, 2, pageIndex)).fill("").map((_, lotPerPageIndex) => {
                                        if ((lotPerPageIndex + 1) % 2 == 0) {
                                            //even numbers
                                            return (
                                                <View key={lotPerPageIndex} style={styles.lotWrapper}>
                                                    <View style={styles.detailsWrapper}>
                                                        <Text style={{ fontSize: 12, paddingBottom: 6, fontFamily: "OpenSans", fontWeight: 700, lineHeight: '1.1rem' }}>
                                                            {lotList[determineLotNumber(pageIndex, lotPerPageIndex)].title}
                                                        </Text>
                                                        {lotList[determineLotNumber(pageIndex, lotPerPageIndex)].description ?
                                                            <Text style={{ overflow: "hidden" }}>
                                                                <Html stylesheet={lotDescriptionStylesheet} style={{ whiteSpace: "pre-line" }}>
                                                                    {tidyLotList[determineLotNumber(pageIndex, lotPerPageIndex)].description}
                                                                </Html>
                                                            </Text>
                                                            :
                                                            null
                                                        }
                                                    </View>
                                                    <View style={[styles.imageWrapper, { alignItems: 'flex-end' }]}>
                                                        <View style={{ width: '95%', position: "relative" }}>
                                                            <Image
                                                                src={lotList[determineLotNumber(pageIndex, lotPerPageIndex)].mediaList[0] ?
                                                                    lotList[determineLotNumber(pageIndex, lotPerPageIndex)].mediaList[0].src
                                                                    :
                                                                    PlaceholderImage
                                                                }
                                                                style={{ zIndex: "unset" }}
                                                            />
                                                        </View >
                                                        {lotList[determineLotNumber(pageIndex, lotPerPageIndex)].numberOfPeople ?
                                                            <View style={{ width: '95%', position: "absolute" }}>
                                                                <Text style={[styles.numberOfPeople, { backgroundColor: backgroundColor, color: fontColor }]}>
                                                                    {`For ${lotList[determineLotNumber(pageIndex, lotPerPageIndex)].numberOfPeople} ${lotList[determineLotNumber(pageIndex, lotPerPageIndex)].numberOfPeople === 1 ? "person" : "people"}`}
                                                                </Text>
                                                            </View>
                                                            :
                                                            null
                                                        }
                                                        <View style={styles.bidWrapper}>
                                                            <View style={{
                                                                display: 'flex', flexDirection: "column",
                                                                width: "100%"
                                                                // width: "60%" //put pack in with QR codes
                                                            }}>
                                                                <View style={[styles.minimumBidWrapper, { backgroundColor: backgroundColor, color: fontColor }]}>
                                                                    <Text>{`Minimum bid: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency ?? 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(lotList[determineLotNumber(pageIndex, lotPerPageIndex)].reservePrice)}`}</Text>
                                                                </View>
                                                                <View style={[styles.yourBidWrapper, { borderColor: backgroundColor }]}>
                                                                    <Text>Your bid:</Text>
                                                                </View>
                                                            </View>
                                                            <View id="QR Code Placeholder">

                                                            </View>
                                                        </View>
                                                    </View>

                                                </View>
                                            )
                                        } else {
                                            //odd numbers
                                            return (
                                                <View key={lotPerPageIndex}>
                                                    <View style={styles.lotWrapper}>
                                                        <View style={styles.imageWrapper}>
                                                            <View style={{ width: '95%', position: "relative" }}>
                                                                <Image
                                                                    src={lotList[determineLotNumber(pageIndex, lotPerPageIndex)].mediaList[0] ?
                                                                        lotList[determineLotNumber(pageIndex, lotPerPageIndex)].mediaList[0].src
                                                                        :
                                                                        PlaceholderImage
                                                                    }
                                                                />
                                                            </View >

                                                            {lotList[determineLotNumber(pageIndex, lotPerPageIndex)].numberOfPeople ?
                                                                <View style={{ width: '95%', position: "absolute" }}>
                                                                    <Text style={[styles.numberOfPeople, { backgroundColor: backgroundColor, color: fontColor }]}>
                                                                        {`For ${lotList[determineLotNumber(pageIndex, lotPerPageIndex)].numberOfPeople} ${lotList[determineLotNumber(pageIndex, lotPerPageIndex)].numberOfPeople === 1 ? "person" : "people"}`}
                                                                    </Text>
                                                                </View>
                                                                :
                                                                null
                                                            }
                                                            <View style={styles.bidWrapper}>
                                                                <View style={{
                                                                    display: 'flex', flexDirection: "column",
                                                                    width: "100%"
                                                                    //  width: "60%"
                                                                }}>
                                                                    <View style={[styles.minimumBidWrapper, { backgroundColor: backgroundColor, color: fontColor }]}>
                                                                        <Text>{`Minimum bid: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency ?? 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(lotList[determineLotNumber(pageIndex, lotPerPageIndex)].reservePrice)}`}</Text>
                                                                    </View>
                                                                    <View style={[styles.yourBidWrapper, { borderColor: backgroundColor }]}>
                                                                        <Text>Your bid:</Text>
                                                                    </View>
                                                                </View>
                                                                <View id="QR Code Placeholder">

                                                                </View>
                                                            </View>

                                                        </View>

                                                        <View style={styles.detailsWrapper}>
                                                            <Text style={{ fontSize: 12, paddingBottom: 6, fontFamily: "OpenSans", fontWeight: 700, lineHeight: '1.1rem' }}>
                                                                {lotList[determineLotNumber(pageIndex, lotPerPageIndex)].title}
                                                            </Text>

                                                            {lotList[determineLotNumber(pageIndex, lotPerPageIndex)].description ?
                                                                <Text style={{ overflow: "hidden" }}>
                                                                    <Html stylesheet={lotDescriptionStylesheet} style={{ whiteSpace: "pre-line" }}>
                                                                        {tidyLotList[determineLotNumber(pageIndex, lotPerPageIndex)].description}
                                                                    </Html>
                                                                </Text>
                                                                :
                                                                null
                                                            }
                                                        </View>
                                                    </View>
                                                    <View style={[styles.lotDivider, { backgroundColor: backgroundColor }]}></View>
                                                </View>
                                            )
                                        }
                                    })}


                                </View>
                                <View style={styles.footer}>
                                    <Image
                                        src={MasterCardLogo}
                                        style={{ width: 30 }}
                                    />
                                    {footerText ?
                                        <Text style={{ fontSize: 10, fontWeight: 'bold', margin: "0 36px", color: backgroundColor }}>
                                            {footerText}
                                        </Text>
                                        :
                                        null
                                    }

                                    <Image
                                        src={VisaLogo}
                                        style={{ width: 30 }}
                                    />
                                </View>
                            </View>
                        </Page>
                    )
                })}
                {termsAndConfitionsHtml ?
                    <Page size={[618, 618]} style={{ padding: 23 }}>
                        <View id="crop-marks">
                                <View id="top-left-left" style={[styles.cropMark, { width: 15, top: 0, left: -23, borderBottomWidth: 1 }]}></View>
                                <View id="top-left-top" style={[styles.cropMark, { height: 15, top: -23, left: 0, borderRightWidth: 1 }]}></View>
                                <View id="top-right-right" style={[styles.cropMark, { width: 15, top: 0, right: -23, borderBottomWidth: 1 }]}></View>
                                <View id="top-right-top" style={[styles.cropMark, { height: 15, top: -23, right: 0, borderRightWidth: 1 }]}></View>
                                <View id="bottom-right-right" style={[styles.cropMark, { width: 15, top: 572, right: -23, borderTopWidth: 1 }]}></View>
                                <View id="bottom-right-bottom" style={[styles.cropMark, { height: 15, top: 580, right: 0, borderLeftWidth: 1 }]}></View>
                                <View id="bottom-left-left" style={[styles.cropMark, { width: 15, top: 572, left: -23, borderBottomWidth: 1 }]}></View>
                                <View id="bottom-left-bottom" style={[styles.cropMark, { height: 15, top: 580, left: 0, borderLeftWidth: 1 }]}></View>
                            </View>

                        <View style={[styles.body, { overflow: "hidden" }]}>
                        {/* <View style={[styles.body, { maxHeight: 585, overflow: "hidden" }]}> */}
                            {headerText ?
                                <View style={[styles.header, { color: backgroundColor, borderColor: backgroundColor }]}>
                                    <Text style={{
                                        fontSize: 10, fontFamily: "OpenSans", fontWeight: 700
                                    }}>
                                        {headerText}
                                    </Text>
                                </View>
                                :
                                <View style={styles.blankHeaderSpacer}></View>
                            }
                            <Text style={{ overflow: "hidden" }}>
                                <Html stylesheet={lotDescriptionStylesheet}>
                                    {termsAndConfitionsHtml.replaceAll('</p>', "</p><p><br></p>")}
                                </Html>
                            </Text>
                            <View style={styles.footer}>
                                <Image
                                    src={MasterCardLogo}
                                    style={{ width: 30 }}
                                />
                                {footerText ?
                                    <Text style={{ fontSize: 10, fontWeight: 'bold', margin: "0 36px", color: backgroundColor }}>
                                        {footerText}
                                    </Text>
                                    :
                                    null
                                }

                                <Image
                                    src={VisaLogo}
                                    style={{ width: 30 }}
                                />
                            </View>
                        </View>
                    </Page>
                    :
                    null
                }
                {backPageImageUrl ?
                    <Page size={[618, 618]}>
                        <Image
                            src={backPageImageUrl}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Page>
                    :
                    null
                }
            </Document>
        )
    }

    return {
        generateBrochurePdf
    }

}

export default usePdfGenerator