import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React, { useContext, useMemo } from "react"
import { OrganisationContext } from "../../../context/OrganisationContext"
import useOrganisationCommonFunctions from "../../../hooks/useOrganisationCommonFunctions"
import ICampaign from "../../../interfaces/campaign"
import ILicenceBundle from "../../../interfaces/licenceBundle"
import IOrganisation from "../../../interfaces/organisation"
import './revokeLicenceBundleDialog.scss'
import QuestionMarkImage from "../../../../src/images/question.png"

interface InputProps {
	isDialogOpen: boolean
	handleClose: () => void
	licenceBundle: ILicenceBundle
	campaignList: ICampaign[]
	revokeLicenceBundle: () => void
}

const RevokeLicenceBundleDialog = (props: InputProps) => {
	const organisationCommonFunctions = useOrganisationCommonFunctions()
	const organisation: IOrganisation = useContext(OrganisationContext)
	const remainingLicenceBundle = useMemo(() => (organisationCommonFunctions.determineActiveLicences(props.campaignList.filter(c => c.organisationId === organisation._id).length ?? 0, organisation) -
		props.licenceBundle.licenceCount), [props.campaignList, props.licenceBundle.licenceCount]);

	return (
		<Dialog open={props.isDialogOpen} onClose={() => props.handleClose()} className="revoke-licence-bundle-dialog">
			{/* <DialogTitle>Revoke Going Gone Licence Bundle</DialogTitle> */}
			{organisation.licenceBundleList && organisation.licenceBundleList.length &&
				<DialogContent>
					<div className="coloured-section" style={{ backgroundColor: "#8896AB" }}>
						<img src={QuestionMarkImage} />
					</div>
					<div className="content-section">
						<h1>Revoke Going Gone Licence Bundle</h1>
						<div className="intro">
							<span>Are you sure you want to revoke this bundle?</span>
						</div>
						<span className="licences-to-revoke">
							Licences to revoke:{" "}
							<span style={{ color: "red" }}>{props.licenceBundle.licenceCount}</span>
						</span>
						<span className="remaining-licences">
							This organisation will be left with{" "}
							{isNaN(remainingLicenceBundle) ? '-' : remainingLicenceBundle}{" "}
							remaining licences
						</span>
					</div>
				</DialogContent>
			}
			<DialogActions>
				<Button onClick={() => props.handleClose()}>Cancel</Button>
				<Button onClick={() => props.revokeLicenceBundle()} className="gg-button">
					Revoke
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default RevokeLicenceBundleDialog