import { Popover } from "@mui/material";
import { HexColorPicker, HexColorInput } from "react-colorful";
import './colorPopover.scss'
import {useDebouncedCallback } from "use-debounce";


interface InputProps {
	open: boolean;
	handleClose: () => void;
	anchorEl: any
	color: string;
	setColor: (color: string) => void;
}

const ColorPopover = (props: InputProps) => {
	const { handleClose, open, anchorEl, color, setColor } = props;

	const debounced = useDebouncedCallback((value) => {
		setColor(value);
	}, 50);


	return (
		<Popover className="color-popover" onClose={handleClose} open={open} anchorEl={anchorEl} anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}}>
			<HexColorPicker color={color} onChange={debounced} />
			<HexColorInput  color={color} onChange={setColor} prefixed className="hex-input" />
		</Popover>
	)
}

export default ColorPopover