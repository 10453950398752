import './mediaSection.scss'
import React, { useContext } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Link } from 'react-router-dom'
import ILot from '../../../../interfaces/lot'
import IOrganisation from '../../../../interfaces/organisation'
import { OrganisationContext } from '../../../../context/OrganisationContext'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
interface InputProps {
    lot: ILot
    childLots: ILot[]
    parentLotSlug: string
    campaignSlug: string
    setLot: (lot: ILot) => void
    // isNewLot: boolean
    decreaseChildLotOrder: (childLot: ILot) => void
    increaseChildLotOrder: (childLot: ILot) => void
    deleteChildLot: (lotId: string, lotTitle: string) => void
    duplicateChildLot: (lotId: string) => void
    isCampaignClosed: boolean
    childLotOnDragEnd: (obj: any) => void
}

const ChildLotsSection = (props: InputProps) => {
    const organisation: IOrganisation = useContext(OrganisationContext)

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#DEEBFE" : "white",
        display: isDragging ? "table" : "",
        ...draggableStyle
    })

    return (
        <div className="media-section">
            <h2 className="subtitle">Child Lots.</h2>
            <span className="description">The sub lots of the current parent.</span>
            <Paper className="input-wrapper">
                {props.childLots && props.childLots.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Order Number</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Lot Name</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Lot Type</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span># Available</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Visibility</span>
                                    </div>
                                </TableCell>
                                {!props.isCampaignClosed && (
                                    <TableCell>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={props.childLotOnDragEnd}>
                            <Droppable droppableId="lot-dnd">
                                {(provided) => (
                                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                        {props.childLots.sort((a, b) => a.orderNumber - b.orderNumber).map((childLot: ILot, index: number) => (
                                            <Draggable
                                                key={childLot.slug}
                                                draggableId={`lot-${childLot.slug}`}
                                                index={index}
                                                isDragDisabled={props.isCampaignClosed}
                                            >
                                                {(provided, snapshot) => (
                                                    <TableRow
                                                        key={childLot.slug}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <TableCell>{childLot.orderNumber}</TableCell>
                                                        <TableCell>
                                                            {!props.isCampaignClosed ?
                                                                childLot.title
                                                                :
                                                                <Link to={`/organisations/${organisation.slug}/campaigns/${props.campaignSlug}/lots/${props.parentLotSlug}/children/${childLot.slug}`}>{childLot.title}</Link>
                                                            }
                                                        </TableCell>
                                                        <TableCell>{childLot.type}</TableCell>
                                                        <TableCell>{childLot.availabilityCount}</TableCell>
                                                        <TableCell>{childLot.isHidden ?
                                                            <VisibilityOffIcon />
                                                            :
                                                            <VisibilityIcon />
                                                        }</TableCell>

                                                        {!props.isCampaignClosed && (
                                                            <TableCell className="action-cell">
                                                                <div onClick={() => props.decreaseChildLotOrder(childLot)}>
                                                                    <Tooltip title="Promote Child Lot"><ExpandLessIcon /></Tooltip>
                                                                </div>
                                                                <div onClick={() => props.increaseChildLotOrder(childLot)}>
                                                                    <Tooltip title="Demote Child Lot"><ExpandMoreIcon /></Tooltip>
                                                                </div>
                                                                <Link to={`/organisations/${organisation.slug}/campaigns/${props.campaignSlug}/lots/${props.parentLotSlug}/children/${childLot.slug}`}><Tooltip title="Edit Child Lot"><CreateIcon /></Tooltip></Link>

                                                                <div onClick={() => props.duplicateChildLot(childLot._id ?? '')}>
                                                                    <Tooltip title="Duplicate Child Lot"><FileCopyIcon /></Tooltip>
                                                                </div>
                                                                <div onClick={() => props.deleteChildLot(childLot._id ?? '', childLot.title ?? '')}>
                                                                    <Tooltip title="Delete Child Lot"><DeleteIcon /></Tooltip>
                                                                </div>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                    :
                    <div className="no-entries">No child lots defined.</div>
                }
                {!props.isCampaignClosed && (
                    <div className="button-wrapper">
                        <Link to={`/organisations/${organisation.slug}/campaigns/${props.campaignSlug}/lots/${props.lot.slug}/children/new`}>
                            <Button className="gg-button">Add New Child Lot</Button>
                        </Link>
                    </div>
                )}
            </Paper>
        </div >
    )
}

export default ChildLotsSection