import React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ICommunicationUser from "../../../../interfaces/communicationUserInfo";
import useCommonFunctions from "../../../../hooks/useCommonFunctions";

interface IRecipientsTableProps {
  recipients: ICommunicationUser[];
}

const RecipientsTable = (props: IRecipientsTableProps) => {
  const commonFunctions = useCommonFunctions()
  return (
    <>
      <Box sx={[{ margin: "10px 0px" }]} />
      <TableContainer component={Paper} sx={[{ boxShadow: "none" }]}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead sx={[{ backgroundColor: "primary" }]}>
            <TableRow>
              <TableCell align="left">
                <b>First name</b>
              </TableCell>
              <TableCell align="left">
                <b>Surname </b>
              </TableCell>
              <TableCell align="left">
                <b>Phone</b>
              </TableCell>
              <TableCell align="left">
                <b>Email</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.recipients.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="left">{commonFunctions.capitalize(row?.firstName) || "N/A"}</TableCell>
                <TableCell align="left">{commonFunctions.capitalize(row?.lastName) || "N/A"}</TableCell>
                <TableCell align="left">{row?.phone || "N/A"}</TableCell>
                <TableCell align="left">{row?.email || "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RecipientsTable;
