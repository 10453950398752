import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Popover, Select, TextField } from "@mui/material";
import './insertPopover.scss'
import ColorInput from "../colorInput/ColorInput";
import { useState } from "react";



interface InputProps {
	open: boolean;
	handleClose: () => void;
	save: () => void;
	anchorEl: any
	buttonConfig: any,
	setButtonConfig: (buttonConfig: any) => void
	saveButtonText: string
}

const InsertButtonPopover = (props: InputProps) => {
	const { handleClose, open, anchorEl, save, buttonConfig, setButtonConfig, saveButtonText } = props;
	const [linkType, setLinkType] = useState<"INTERNAL" | "EXTERNAL">("INTERNAL");




	return (
		<Popover className="insert-popover" onClose={handleClose} open={open} anchorEl={anchorEl} anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}}>
			<div className='popover-content'>
				<span><b>Button Settings</b></span>
				<div className="input-wrapper">

					<div className="field-wrapper">
						<TextField
							label="Button Text"
							variant="outlined"
							defaultValue={buttonConfig.text || ""}
							onBlur={event => setButtonConfig({ ...buttonConfig, text: event.target.value })}
						/>
					</div>
					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="width-label">Width</InputLabel>
							<Select
								labelId="width-label"
								defaultValue={buttonConfig.width || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, width: event.target.value })}
								label="Width"
							>
								<MenuItem value={"fit-content"} >Fit Content</MenuItem>
								<MenuItem value={"100%"} >Full Page Width</MenuItem>
								<MenuItem value={"50%"} >Half Page Width</MenuItem>
								<MenuItem value={"200px"} >Small</MenuItem>
								<MenuItem value={"300px"} >Medium</MenuItem>
								<MenuItem value={"400px"} >Large</MenuItem>

							</Select>
						</FormControl>
					</div>





					<div className="field-wrapper">
						<ColorInput
							name="backgroundColor"
							label="Background Colour"
							value={buttonConfig.backgroundColor}
							handleChange={(name, value) => setButtonConfig({ ...buttonConfig, backgroundColor: value })}
						/>
					</div>
					<div className="field-wrapper">
						<ColorInput
							name="color"
							label="Font Colour"
							value={buttonConfig.color}
							handleChange={(name, value) => setButtonConfig({ ...buttonConfig, color: value })}
						/>
					</div>

					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="corners-label">Corners</InputLabel>
							<Select
								labelId="corners-label"
								defaultValue={buttonConfig.borderRadius || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, borderRadius: event.target.value })}
								label="Corners"
							>
								<MenuItem value={"0px"}>Square</MenuItem>
								<MenuItem value={"4px"}>Slightly Rounded</MenuItem>
								<MenuItem value={"12px"}>Rounded</MenuItem>
								<MenuItem value={"100px"}>Fully Rounded</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="alignment-label">Alignment</InputLabel>
							<Select
								labelId="alignment-label"
								defaultValue={buttonConfig.alignment || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, alignment: event.target.value })}
								label="Alignment"
							>
								<MenuItem value={"left"}>Left</MenuItem>
								<MenuItem value={"center"}>Center</MenuItem>
								<MenuItem value={"right"}>Right</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="font-size-label">Font Size</InputLabel>
							<Select
								labelId="font-size-label"
								defaultValue={buttonConfig.fontSize || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, fontSize: event.target.value })}
								label="Font Size"
							>
								<MenuItem value={"12px"} style={{ fontSize: 8 }}>8px</MenuItem>
								<MenuItem value={"12px"} style={{ fontSize: 12 }}>12px</MenuItem>
								<MenuItem value={"13px"} style={{ fontSize: 13 }}>13px</MenuItem>
								<MenuItem value={"14px"} style={{ fontSize: 14 }}>14px</MenuItem>
								<MenuItem value={"16px"} style={{ fontSize: 16 }}>16px</MenuItem>
								<MenuItem value={"18px"} style={{ fontSize: 18 }}>18px</MenuItem>
								<MenuItem value={"24px"} style={{ fontSize: 24 }}>24px</MenuItem>
								<MenuItem value={"32px"} style={{ fontSize: 32 }}>32px</MenuItem>
								<MenuItem value={"48px"} style={{ fontSize: 48 }}>48px</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="font-weight-label">Font Weight</InputLabel>
							<Select
								labelId="font-weight-label"
								defaultValue={buttonConfig.fontWeight || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, fontWeight: event.target.value })}
								label="Font Weight"
							>
								<MenuItem value={"400"} style={{ fontWeight: 400 }}>Regular</MenuItem>
								<MenuItem value={"700"} style={{ fontWeight: 700 }}>Bold</MenuItem>

							</Select>
						</FormControl>
					</div>
					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="font-family-label">Font</InputLabel>
							<Select
								labelId="font-family-label"
								defaultValue={buttonConfig.fontFamily || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, fontFamily: event.target.value })}
								label="Font"
							>
							

								<MenuItem value={"arial"} style={{ fontFamily: "Arial" }}> Arial </MenuItem>
								<MenuItem value={"arial black, sans-serif"} style={{ fontFamily: "arial black, sans-serif" }}>
									Arial Black
								</MenuItem>
								<MenuItem
									value={"Comic Sans MS, Comic Sans"}
									style={{ fontFamily: "Comic Sans MS, Comic Sans" }}
								>
									Comic Sans
								</MenuItem>

								<MenuItem
									value={"Helvetica, sans-serif"}
									style={{ fontFamily: "Helvetica, sans-serif" }}
								>
									Helvetica
								</MenuItem>

								<MenuItem
									value={"impact, sans-serif"}
									style={{ fontFamily: "impact, sans-serif" }}
								>
									Impact
								</MenuItem>
								<MenuItem
									value={"monospace"}
									style={{ fontFamily: "monospace" }}
								>
									Monospace
								</MenuItem>
								<MenuItem
									value={"poppins, helvetica, sans-serif"}
									style={{ fontFamily: "poppins, helvetica, sans-serif" }}
								>
									Poppins
								</MenuItem>
								<MenuItem
									value={"times new roman, sans-serif"}
									style={{ fontFamily: "times new roman, sans-serif" }}
								>
									Times New Roman
								</MenuItem>
								<MenuItem
									value={"verdana, sans-serif"}
									style={{ fontFamily: "verdana, sans-serif" }}
								>
									Verdana
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="link-type-label">Link Type</InputLabel>
							<Select
								labelId="link-type-label"
								defaultValue={linkType || ""}
								onChange={event => setLinkType(event.target.value as "INTERNAL" | "EXTERNAL")}
								label="Link Type"
							>
								<MenuItem value={"INTERNAL"}>Internal (this campaign)</MenuItem>
								<MenuItem value={"EXTERNAL"}>External (3rd party website)</MenuItem>
							</Select>
						</FormControl>
					</div>
					{linkType === "INTERNAL" ?
						<div className="field-wrapper">
							<FormControl variant="outlined">
								<InputLabel id="page-label">Page</InputLabel>
								<Select
									labelId="page-label"
									defaultValue={"lots"}
									onBlur={event => setButtonConfig({ ...buttonConfig, link: `/%%campaign-slug%%/${event.target.value}`})}
									label="Page"
								>
									<MenuItem value={"faqs"}>FAQs</MenuItem>
									<MenuItem value={"lots"}>Lots Page</MenuItem>
									<MenuItem value={"privacy-policy"}>Privacy Policy</MenuItem>
									<MenuItem value={"terms-and-conditions"}>Terms and Conditions</MenuItem>
								</Select>
							</FormControl>
						</div>
						:
						<div className="field-wrapper">
							<TextField
								label="URL"
								variant="outlined"
								defaultValue={buttonConfig.link || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, link: `https://${event.target.value}` })}
								InputProps={{
									startAdornment: <InputAdornment position="start">https://</InputAdornment>,
								}}
							// helperText="Use %%campaign-slug%% to insert the campaign slug into the link."
							/>
						</div>

					}
					<div className="field-wrapper">
						<FormControl variant="outlined">
							<InputLabel id="open-in-new-tab-label">Open Link In New Tab?</InputLabel>
							<Select
								labelId="open-in-new-tab-label"
								defaultValue={String(buttonConfig.isOpenInNewTab) || ""}
								onBlur={event => setButtonConfig({ ...buttonConfig, isOpenInNewTab: event.target.value })}
								label="Open Link In New Tab?"
							>
								<MenuItem value={"true"}>Yes</MenuItem>
								<MenuItem value={"false"}>No</MenuItem>
							</Select>
						</FormControl>
					</div>

				</div>

				<div className="action-section">
					<div className="save-button-wrapper">
						<div className="buttons">
							<Button id="cancel-button" onClick={handleClose} >
								Cancel
							</Button>
							<Button className="gg-button" onClick={() => save()}>{saveButtonText}</Button>
						</div>
					</div>
				</div>
			</div>
		</Popover>
	)
}

export default InsertButtonPopover