import React from 'react'
import { DeviceTypes } from '../../../common/enums/DeviceTypes'
import ComputerIcon from '@mui/icons-material/Computer';
import { Tooltip } from '@mui/material';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TabletIcon from '@mui/icons-material/Tablet';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';

interface InputProps {
    device: string
    color?: string
}

const DeviceIcon = (props: InputProps) => {

    if (props.device === DeviceTypes.BROWSER) {
        return (
            <Tooltip title="Computer">
                <ComputerIcon style={props.color ? {color: props.color} : {}}/>
            </Tooltip>
        )
    } else if (props.device === DeviceTypes.MOBILE) {
        return (
            <Tooltip title="Phone">
                <SmartphoneIcon style={props.color ? {color: props.color} : {}}/>
            </Tooltip>
        )
    } else if (props.device === DeviceTypes.TABLET) {
        return (
            <Tooltip title="Tablet">
                <TabletIcon style={props.color ? {color: props.color} : {}}/>
            </Tooltip>
        )
    } else if (props.device === "" || props.device === undefined) {
        return (
            <></>
        )
    } else {
        return (
            <Tooltip title={`Unknown Device${props.device ? `: ${props.device}` : ""}`}>
                <DeviceUnknownIcon style={props.color ? {color: props.color} : {}}/>
            </Tooltip>
        )
    }


}

export default DeviceIcon
