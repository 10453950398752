import { Button } from "@mui/material"
import React from "react"
import './tagButton.scss'

interface InputProps {
    isSelected: boolean
    isDiabled: boolean
    onClick: (any: any) => void
    text: string
}

const TagButton = (props: InputProps) => {
	return (
		<Button
			className={props.isSelected ? "tag-button filled" : "tag-button"}
			disabled={props.isDiabled}
			onClick={props.onClick}
		>
			{props.text}
		</Button>
	)
}

export default TagButton
