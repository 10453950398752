export const Auth0ClientIdToDomainMap = [
    {clientid: "IttveFzH2iwpSEZH930zRGH5odXbPI7u", domain: "http://localhost:3000"},
    {clientid: "IttveFzH2iwpSEZH930zRGH5odXbPI7u", domain: "https://regular-rodent-verbally.ngrok-free.app"},
    {clientid: "fxmiiZC3syR9fYEcJkONtiks0PmFWbEB", domain: "https://www.makeabid.live"},
    {clientid: "Gd1QBCxfLBHrRk4mxG98lm6tLuYKLB9G", domain: "https://www.lighthousecharityauction.com"},
    {clientid: "x7pATy2INVm7fPnyj3yE3JHVyB2OzAiC", domain: "https://www.elitefr.co.uk"},
    {clientid: "MPyli3slNkBdAw2MdA069z50o5HV6VSY", domain: "https://appv2.goinggone.io"},
    {clientid: "anCZl7fFIdda0ggIR6KAo2JPOEOh75bY", domain: "https://test.goinggone.io"},
    {clientid: "anCZl7fFIdda0ggIR6KAo2JPOEOh75bY", domain: "https://admin-test.goinggone.io"},
    {clientid: "FZhQB7OdgHFaqIaUxjtthiH8bIJuk33q", domain: "https://admin.goinggone.io"},
]