import React, { useContext, useEffect, useMemo, useState } from "react"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import "./App.scss"
import HttpsRedirect from "react-https-redirect"
import { createBrowserHistory } from "history"
import LayoutAuthenticated from "./layouts/LayoutAuthenticated"
import LayoutAuthenticatedNoMenu from "./layouts/LayoutAuthenticatedNoMenu"
import LayoutAnonymous from "./layouts/LayoutAnonymous"
import Callback from "./components/callback/Callback"
import Home from "./components/home/Home"
import ReactGA from "react-ga4"
import Logout from "./components/logout/Logout"
import BigScreen from "./components/admin/bigScreen/BigScreen"
import { organisationRoutes, adminRoutes } from "./common/Routes"
import { UserContext } from "./context/UserContext"
import LoginSignup from "./components/loginSignup/LoginSignup"
import PostAuthenticate from "./auth/PostAuthenticate"
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from "./components/admin/shared/ErrorPage"

const history = createBrowserHistory();
ReactGA.initialize("UA-232423962-1");

history.listen(location => {
  ReactGA.send({
    hitType: "pageview",
    page: location.pathname + location.search,
  })
})

const App = () => {
  const [paddingForMenuLayoutRoutes, setPaddingForMenuLayoutRoutes] = useState(1)
  const { dBUser } = useContext(UserContext)
  const isAuthenticated = useMemo(() => (dBUser && dBUser._id ? true : false), [dBUser])

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={() => {
        // reset the state of your app here
      }}
      resetKeys={['someKey']}
    >
      <HttpsRedirect>
        <Router history={history}>
          <Switch>
            <Route path="/" exact={true}>
              <LayoutAnonymous>
                <Route path="/" component={LoginSignup} exact={true} />
              </LayoutAnonymous>
            </Route>
            <Route path="/authenticate">
              <Route path="/authenticate" component={PostAuthenticate} />
            </Route>
            <Route path="/organisations/:organisationSlug/campaigns/:campaignSlug/big-screen" exact={true}>
              <LayoutAnonymous>
                <Route path="/organisations/:organisationSlug/campaigns/:campaignSlug/big-screen" component={BigScreen} exact={true} />
              </LayoutAnonymous>
            </Route>
            {organisationRoutes.map((route, i) =>
              <Route key={i} exact={route.exact} path={route.path}>
                <LayoutAuthenticatedNoMenu >
                  <Route
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (isAuthenticated ? <route.component {...props} /> : <Redirect to={{ pathname: "/", search: "unauthorised" }} />)}
                  />
                </LayoutAuthenticatedNoMenu>
              </Route>
            )}
            {adminRoutes.map((route, i) =>
              <Route key={i} exact={route.exact} path={route.path}>
                <LayoutAuthenticated title={route.title} paddingForMenuLayoutRoutes={paddingForMenuLayoutRoutes} >
                  <Route
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (isAuthenticated ? <route.component {...props} setPadding={() => setPaddingForMenuLayoutRoutes(paddingForMenuLayoutRoutes + 1)} /> : <Redirect to={{ pathname: "/", search: "unauthorised" }} />)}
                  />
                </LayoutAuthenticated>
              </Route>
            )}
            <Route path="/" exact={true}>
              <LayoutAnonymous>
                <Route path="/" exact={true} component={Home} />
              </LayoutAnonymous>
            </Route>
            <Route path="/callback" component={Callback} />
            <Route path="/logout" component={Logout} />
          </Switch>
        </Router>
      </HttpsRedirect>

    </ErrorBoundary>

  );



}

export default App;
