import "./campaignList.scss"
import React, { useState, useEffect, useContext, useMemo } from "react"
import useFetch from "../../../hooks/useFetch"
import Loading from "../../shared/Loading"
import { Table, TableBody, TableCell, TableHead, TableRow, Card, Button, CardContent, Tooltip, TablePagination, useMediaQuery, Checkbox, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import moment from "moment"
import CreateIcon from "@mui/icons-material/Create"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import { Link } from "react-router-dom"
import _ from "lodash"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import ICampaign from "../../../interfaces/campaign"
import { IBigScreenSettings } from "../../../interfaces/bigScreenSettings"
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignQueryString, getPaginatedCampaignsQueryString, LOT_API_PATHS, STRIPE_API_PATHS } from "../../../common/ApiPaths"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { UserContext } from "../../../context/UserContext"
import { UserRole } from "../../../common/enums/UserRole"
import useOrganisationCommonFunctions from "../../../hooks/useOrganisationCommonFunctions"
import CreateCampaignDialog from "./CreateCampaignDialog"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import { useErrorBoundary } from 'react-error-boundary'
import MenuBookIcon from '@mui/icons-material/MenuBook'

enum DialogOperation {
	DUPLICATE = 'DUPLICATE',
	DELETE = 'DELETE',
	CREATE = 'CREATE',
	PURCHASEBROCHURETOOL = 'PURCHASEBROCHURETOOL'
}

const CampaignList = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const [campaignList, setCampaignList] = useState<ICampaign[]>([])
	const [columnToSort, setColumnToSort] = useState("campaignDate")
	const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc")
	// const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false)
	const [isCreateCampaignDialogOpen, setIsCreateCamaignDialogOpen] = useState<boolean>(false)
	const [campaignToBeCopied, setCampaignToBeCopied] = useState<ICampaign>({} as ICampaign)
	const [campaignToBeDeleted, setCampaignToBeDeleted] = useState<string>("")
	const [campaignToBeDuplicated, setCampaignToBeDuplicated] = useState<string>("")
	const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", label: "", message: "", operation: "" })
	const [paginationPage, setPaginationPage] = useState<number>(0)
	const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(25)
	const [totalCampaignCount, setTotalCampaignCount] = useState<number>(0)
	const [selected, setSelected] = useState<ICampaign[]>([])
	const [selectedBulkAction, setSelectedBulkAction] = useState<string>("")

	const orgCommonFunctions = useOrganisationCommonFunctions()

	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	const lotApi = useFetch(API_PATHS.LOTS)
	const bigScreenApi = useFetch(API_PATHS.BIGSCREENS)
	const stripeApi = useFetch(API_PATHS.STRIPE)

	const organisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)
	const commonFunctions = useCommonFunctions()
	const { showBoundary } = useErrorBoundary();

	const hasActiveLicences = useMemo(() => orgCommonFunctions.determineActiveLicences(totalCampaignCount, organisation) > 0, [totalCampaignCount]);

	const getCampaigns = async (): Promise<void> => {
		setLoading(true)

		try {
			// const user = await commonFunctions.getUserIdFromAuth0Id(encodeURIComponent(String(user!.sub)))
			const result = await campaignsApi.get(
				`${CAMPAIGN_API_PATHS.GET_PAGINATED_CAMPAIGNS}${getPaginatedCampaignsQueryString(
					dBUser._id,
					dBUser.role,
					paginationRowsPerPage,
					paginationPage,
					organisation._id
				)}`
			)
			setTotalCampaignCount(result.totalRecords)
			setCampaignList(result.campaigns)
			setLoading(false)
		} catch (err: any) {
			showBoundary(err)
			setLoading(false)
		}
	}

	const openDialogForDuplicateCampaign = async (campaignSlug: string) => {
		let campaignToBeCopied: ICampaign = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(campaignSlug)}`)
		setIsCreateCamaignDialogOpen(true)
		setCampaignToBeCopied(campaignToBeCopied)
	}

	const duplicateCampaign = async (campaignToDuplicate: ICampaign) => {
		// campaignToBeCopied.name += `-copy-${uuidv4()}`

		let promises: Promise<any>[] = []
		promises.push(
			new Promise(async (resolve: (result) => void, reject: (error: Error) => void) => {
				try {
					const createdCampaign: ICampaign = await campaignsApi.post(`${CAMPAIGN_API_PATHS.CREATE_CAMPAIGN}`, { ...campaignToDuplicate, brochureToolPurchaseInfo: { ...campaignToDuplicate.brochureToolPurchaseInfo, paymentRefId: null, receiptUrl: null } }, dBUser._id)
					await lotApi.post(`${LOT_API_PATHS.DUPLICATE_LOTS}`, { fromCampaignId: campaignToDuplicate._id, toCampaignId: createdCampaign._id })
					resolve("done")
				} catch (err: any) {
					console.log(err)
					showBoundary(err)
					reject(err)
				}
			})
		)

		promises.push(
			new Promise((resolve: (result) => void, reject: (error: Error) => void) => {
				bigScreenApi
					.get(`/${campaignToBeCopied._id}/settings`)
					.then(bigScreenSettings => {
						delete bigScreenSettings._id

						bigScreenApi
							.post(`/${campaignToDuplicate._id}/settings`, { ...bigScreenSettings, auctionId: campaignToDuplicate.slug })
							.then((bigScreenSettings: IBigScreenSettings) => {
								resolve("done")
							})
							.catch((err: Error) => {
								console.log(err)
								showBoundary(err)
								reject(err)
							})
					})
					.catch((err: Error) => {
						console.log(err)
						showBoundary(err)
						reject(err)
					})
			})
		)

		Promise.all(promises).then(() => {
			setCampaignToBeCopied({} as ICampaign)
			setDialogProperties({ isOpen: true, type: "SUCCESS", title: "Campaign Duplicated", message: "Campaign duplicated successfully." })

			getCampaigns()
		})
	}

	const deleteCampaign = async (campaignId: string): Promise<void> => {
		try {
			await campaignsApi.del(`${CAMPAIGN_API_PATHS.DELETE_CAMPAIGN.replace("{campaignId}", campaignId)}`, {}, dBUser._id)
			openDeleteSuccessDialog()
			getCampaigns()
		} catch (err: any) {
			showBoundary(err)
		}
	}

	const invertDirection = (currentDirection: string) => {
		if (currentDirection === "asc") {
			return "desc"
		} else if (currentDirection === "desc") {
			return "asc"
		}
	}

	const handleSort = columnName => {
		setColumnToSort(columnName)
		let sortDirect: any = columnToSort === columnName ? invertDirection(sortDirection) : "desc"
		setSortDirection(sortDirect)
	}

	const handleCloseDialog = (isConfirmed: boolean) => {
		if (dialogProperties.type === "AREYOUSURE") {
			if (isConfirmed && dialogProperties.operation === DialogOperation.DELETE) {
				deleteCampaign(campaignToBeDeleted)
			}
			else if (isConfirmed && dialogProperties.operation === DialogOperation.DUPLICATE) {
				openDialogForDuplicateCampaign(campaignToBeDuplicated)
			}
			else if (isConfirmed && dialogProperties.operation === DialogOperation.CREATE) {
				setIsCreateCamaignDialogOpen(true)
			}
			else if (isConfirmed && dialogProperties.operation === DialogOperation.PURCHASEBROCHURETOOL) {
				purchaseBrochureToolForSelectedCampaigns()
			}
		} else if (dialogProperties.type === "SUCCESS") {
			if (isConfirmed && dialogProperties.operation === DialogOperation.PURCHASEBROCHURETOOL) {
				getCampaigns()
			}
		}
		setDialogProperties({ isOpen: false })
	}

	const openAreYouSureDialog = (dialogOperation: DialogOperation, campaignSlug: string) => {
		if (dialogOperation === DialogOperation.DELETE) {
			setCampaignToBeDeleted(campaignSlug)
			setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Campaign Delete", message: "Are you sure you want to delete this campaign?\nThis operation will free one of your licenses.", operation: dialogOperation })
		}
		else if (dialogOperation === DialogOperation.DUPLICATE) {
			setCampaignToBeDuplicated(campaignSlug)
			setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Campaign Duplicate", message: "Are you sure you want to duplicate this campaign?\nThis operation will use one of your licenses.", operation: dialogOperation })
		}
		else if (dialogOperation === DialogOperation.CREATE) {
			setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Campaign Creation", message: "Are you sure you want to create a new campaign?\nThis operation will use one of your licenses.", operation: dialogOperation })
		}

	}

	const openDeleteSuccessDialog = () => {
		setDialogProperties({ isOpen: true, type: "SUCCESS", title: "Campaign Deleted", message: "Campaign deleted successfully." })
	}

	const handlePaginationChangePage = (event: unknown, newPage: number) => {
		setPaginationPage(newPage)
		setSelected([])
	}

	const handlePaginationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPaginationRowsPerPage(parseInt(event.target.value, 10))
		setPaginationPage(0)
		setSelected([])
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = campaignList.map((lot) => lot);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: any[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const purchaseBrochureToolForSelectedCampaigns = async () => {
		const campaignsToPurchaseBrochureTool = selected.filter(x => !x.brochureToolPurchaseInfo?.paymentRefId)

		try {

			if (dBUser.role === UserRole.SUPER_ADMIN) {
				await campaignsApi.put(CAMPAIGN_API_PATHS.UPDATE_CAMPAIGNS_WITH_BROCHURE_INFO, {
					campaignIds: campaignsToPurchaseBrochureTool.map(x => x._id),
					brochureInfo:
					{
						paymentRefId: "Super_Admin_Free",
						receiptUrl: null,
						brochureConfig: {
							backgroundColor: '#26c0b9',
							backPageImageUrl: '',
							fontColor: '#ffffff',
							footerText: '',
							frontPageImageUrl: '',
							headerText: '',
						},
						allCampaignsInfo: [],
						timestamp: new Date(),
						totalCost: 0,
					},
					organisationId: organisation._id,
					currentUserId: dBUser._id
				})
				setSelected([]);
				setSelectedBulkAction("")
				setDialogProperties({
					isOpen: true, type: "SUCCESS", title: "Success",
					message: "Brochure tool purchased for selected campaigns (No charge for Super Admin user)",
					operation: DialogOperation.PURCHASEBROCHURETOOL
				})

			} else {
				let data = await stripeApi.post(STRIPE_API_PATHS.CREATE_CHECKOUT_SESSION, {
					productName: `${campaignsToPurchaseBrochureTool.map(x => x.name).join(',')}`,
					price: campaignsToPurchaseBrochureTool.length * 60,
					currency: organisation.currency,
					organisationId: organisation._id,
					organisationSlug: organisation.slug,
					organisationEmail: organisation.contactEmail,
					campaignIdsForBrochure: campaignsToPurchaseBrochureTool.map(x => x._id),
					userId: dBUser._id,
					baseUrl: window.location.origin,
					tabNumber: '2',
					description: `Going Gone - Brochure Generation Tool Purchase x ${campaignsToPurchaseBrochureTool.length}`
				})

				window.location.href = data.url
			}
		} catch (err) {
			setSelected([]);
			setSelectedBulkAction("")
			setDialogProperties({ isOpen: true, type: "ERROR", title: "Something went wrong", message: String(err) })
		}

	}

	const handleBulkActionsChange = async (event) => {
		setSelectedBulkAction(event.target.value)
		if (event.target.value === "PURCHASEBROCHURETOOL") {
			if (selected.some(x => x.brochureToolPurchaseInfo && x.brochureToolPurchaseInfo.paymentRefId)) {
				setDialogProperties({ isOpen: true, type: "AREYOUSURE", title: "Confirm Brochure Tool Purchase", message: "Brochure tool won't be purchased for some of the selected campaigns as it has already been purchased. Do you want to proceed?", operation: DialogOperation.PURCHASEBROCHURETOOL })
			}
			else {
				purchaseBrochureToolForSelectedCampaigns()
			}
		}
	}

	useEffect(() => {
		setCampaignList(_.orderBy(campaignList, columnToSort === "status" ? "isClosed" : columnToSort, sortDirection))
	}, [columnToSort, sortDirection])

	useEffect(() => {
		if (organisation._id && dBUser._id) {
			getCampaigns()
		}
		// eslint-disable-next-line
	}, [paginationPage, paginationRowsPerPage, organisation, dBUser])



	if (loading) {
		return <Loading />
	}

	// if (error) {
	// 	return <p>{error}</p>
	// }

	return (
		<div className="campaign-list-page">
			{/* <h1>Campaign List.</h1> */}
			{campaignList.length > 0 ? (
				<Card>
					<CardContent>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell padding="checkbox" className="hide-on-mobile">
										<Checkbox
											indeterminate={selected.length > 0 && selected.length < campaignList.length}
											checked={campaignList.length > 0 && selected.length === campaignList.length}
											onChange={handleSelectAllClick}
											inputProps={{ 'aria-label': 'select all campaigns' }}
										/>
									</TableCell>
									<TableCell>
										<div onClick={() => handleSort("name")} className="table-header-cell sortable">
											<span>Campaign</span>
											{columnToSort === "name" ? (
												sortDirection === "asc" ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)
											) : null}
										</div>
									</TableCell>
									<TableCell>
										<div
											onClick={() => handleSort("campaignDate")}
											className="table-header-cell sortable center"
											style={{ minWidth: 128 }}
										>
											<span>Campaign Date</span>
											{columnToSort === "campaignDate" ? (
												sortDirection === "asc" ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)
											) : null}
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile">
										<div onClick={() => handleSort("lotCount")} className="table-header-cell sortable center">
											<span>Lot Count</span>
											{columnToSort === "lotCount" ? (
												sortDirection === "asc" ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)
											) : null}
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile">
										<div onClick={() => handleSort("bidCount")} className="table-header-cell sortable center">
											<span>Bid Count</span>
											{columnToSort === "bidCount" ? (
												sortDirection === "asc" ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)
											) : null}
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile">
										<div className="table-header-cell center">
											<span>Brochure</span>
										</div>
									</TableCell>
									<TableCell>
										<div onClick={() => handleSort("status")} className="table-header-cell sortable center">
											<span>Status</span>
											{columnToSort === "status" ? (
												sortDirection === "asc" ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)
											) : null}
										</div>
									</TableCell>

									<TableCell className="hide-on-mobile"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{campaignList &&
									campaignList.map((campaign: ICampaign) => (
										<TableRow key={campaign.slug}>
											<TableCell padding="checkbox" className="hide-on-mobile">
												<Checkbox
													checked={selected.indexOf(campaign) !== -1}
													inputProps={{ 'aria-labelledby': campaign.slug }}
													onClick={() => handleClick(campaign)}
												/>
											</TableCell>
											<TableCell style={{ maxWidth: 300 }}>
												<Link to={`/organisations/${organisation.slug}/campaigns/${campaign.slug}`}>
													{campaign.name}
												</Link>
											</TableCell>
											<TableCell>
												<div className="center">{moment(campaign.campaignDate).format("DD/MM/YYYY")}</div>
											</TableCell>
											<TableCell className="hide-on-mobile">
												<div className="center">{campaign.lotCount}</div>
											</TableCell>
											<TableCell className="hide-on-mobile">
												<div className="center">{campaign.bidCount}</div>
											</TableCell>
											<TableCell className="hide-on-mobile">
												<div className="center">
													{campaign.brochureToolPurchaseInfo && campaign.brochureToolPurchaseInfo.paymentRefId &&
														<Tooltip title="Brochure Tool Purchased" enterDelay={500}>
															<MenuBookIcon style={{ color: "grey" }} />
														</Tooltip>
													}
												</div>
											</TableCell>
											<TableCell>
												{campaign.isClosed ? (
													<div className="status center closed">Closed</div>
												) : (
													<div className="status center open">Open</div>
												)}
											</TableCell>

											<TableCell className="hide-on-mobile">
												<div className="action-cell">
													<a href={`${process.env.REACT_APP_CAMPAIGN_URL}${campaign.slug}`} target="_blank" rel="noreferrer">
														<Tooltip title="Launch Campaign (in new tab)" enterDelay={500}>
															<OpenInNewIcon />
														</Tooltip>
													</a>
													<Link to={`/organisations/${organisation.slug}/campaigns/${campaign.slug}`}>
														<Tooltip title="Edit Campaign" enterDelay={500}>
															<CreateIcon />
														</Tooltip>
													</Link>
													{dBUser.role !== UserRole.OPERATOR && (
														<div onClick={() => hasActiveLicences ? openAreYouSureDialog(DialogOperation.DUPLICATE, campaign.slug) /*duplicateCampaign(campaign.slug) */ : {}}>
															<Tooltip title={`${hasActiveLicences ? 'Duplicate Campaign' : 'Not enough licenses to duplicate'}`} enterDelay={500}>
																<FileCopyIcon className={`${hasActiveLicences ? '' : 'disabled'}`} />
															</Tooltip>
														</div>
													)}
													{dBUser.role !== UserRole.OPERATOR && (
														<div onClick={() => campaign.isClosed ? {} : openAreYouSureDialog(DialogOperation.DELETE, campaign._id)}>
															<Tooltip title={`${campaign.isClosed ? 'Cannot delete a closed campaign' : 'Delete Campaign'}`} enterDelay={500}>
																<DeleteIcon className={`${campaign.isClosed ? 'disabled' : ''}`} />
															</Tooltip>
														</div>
													)}
												</div>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={totalCampaignCount}
							rowsPerPage={paginationRowsPerPage}
							page={paginationPage}
							onPageChange={handlePaginationChangePage}
							onRowsPerPageChange={handlePaginationChangeRowsPerPage}
							labelRowsPerPage="Rows/page"
						/>
					</CardContent>
				</Card>
			) : (
				<Card>
					<CardContent>
						<div className="no-entries">
							No campaigns configured or you do not have permission to view any campaigns. <br />
							<br />
							{dBUser.role !== UserRole.OPERATOR && (
								<>
									Use the "New Campaign" button below to create your first campaign.
								</>
							)}
						</div>
					</CardContent>
				</Card>
			)}

			{dBUser.role !== UserRole.OPERATOR && (
				<div className="fixed-action-section">
					<div style={{ display: "flex" }}>
						<FormControl
							className="bulk-actions hide-on-mobile"
							disabled={selected.length < 1}
						>
							<InputLabel id="bulk-actions-label">Bulk Actions</InputLabel>
							<Select
								labelId="bulk-actions-label"
								id="bulkActions"
								name="bulkActions"
								value={selectedBulkAction}
								onChange={(event) => handleBulkActionsChange(event)}
								label="Bulk Actions"
							>
								<MenuItem value={'PURCHASEBROCHURETOOL'}>Purchase Brochure Generation Tool</MenuItem >
							</Select>
						</FormControl>
					</div>
					{hasActiveLicences ?
						<Button className="gg-button" onClick={() => openAreYouSureDialog(DialogOperation.CREATE, '')}>New Campaign</Button>
						:
						<span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<span className="validation-text">Not enough licenses to create a new campaign.</span>
							<Button style={{ width: '180px', marginTop: '4px' }} className="gg-button" disabled>New Campaign</Button>
						</span>
					}

				</div>
			)}

			<ConfirmationDialog
				handleClose={handleCloseDialog}
				isDialogOpen={dialogProperties.isOpen}
				message={dialogProperties.message}
				title={dialogProperties.title}
				type={dialogProperties.type}
				label={dialogProperties.label}
				primaryButton={<Button id="save-button" onClick={() => handleCloseDialog(true)} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
					Ok
				</Button>}
			/>

			<CreateCampaignDialog
				isDialogOpen={isCreateCampaignDialogOpen}
				handleClose={() => setIsCreateCamaignDialogOpen(false)}
				campaignToBeCopied={campaignToBeCopied}
				setCampaignToBeCopied={setCampaignToBeCopied}
				duplicateCampaign={duplicateCampaign}
			/>
		</div>
	)
}

export default CampaignList
