import React, { useEffect, useRef } from 'react'
import ICampaign from '../../interfaces/campaign'
import ILot from '../../interfaces/lot'
import './lotNumber.scss'

interface InputProps {
    orderNumber: number
    backgroundColor: string
    fontColor: string
}

const LotNumber = (props: InputProps) => {

    const isCancelled = useRef(false)

    useEffect(() => {

        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='lot-number-wrapper' style={{ "--background-color": props.backgroundColor, "--font-color": props.fontColor } as React.CSSProperties}>
            <div className='lot-number'>{props.orderNumber}</div>
        </div>
    )
}

export default LotNumber