import { useState } from "react";
import ICampaign from "../../../interfaces/campaign";
import IValidationObject from "../../../interfaces/validationObject";

const useCampaignValidation = (auction: ICampaign, otherAuctionList: ICampaign[], createDialog: boolean = false) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([
        { name: "hostingOrganisationName", isValid: true, validationMessage: "" },
        { name: "hostingOrganisationLogoUrl", isValid: true, validationMessage: "" },
        { name: "name", isValid: true, validationMessage: "" },
        { name: "slug", isValid: true, validationMessage: "" },
        { name: "paymentMethod", isValid: true, validationMessage: "" },
        { name: "campaignDate", isValid: true, validationMessage: "" },
        { name: "smsSender", isValid: true, validationMessage: "" },
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "hostingOrganisationName", isValid: true, validationMessage: "" },
            { name: "hostingOrganisationLogoUrl", isValid: true, validationMessage: "" },
            { name: "name", isValid: true, validationMessage: "" },
            { name: "slug", isValid: true, validationMessage: "" },
            { name: "paymentMethod", isValid: true, validationMessage: "" },
            { name: "campaignDate", isValid: true, validationMessage: "" },
            { name: "smsSender", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        const alphanumericRegex = /^[a-zA-Z0-9]+$/;
        const phoneNumberRegex = /^[^+]\d+$/;
        const emojiRegex =
            /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

        if (auction.slug === "" || typeof (auction.slug) === "undefined") {
            setFailedValidation("slug", "Please enter a URL-Suffix")
        } else {
            otherAuctionList.forEach(element => {
                if (auction.slug === element.slug) {
                    setFailedValidation("slug", "URL-Suffix must be unique - please try something different.")
                }
            });
        }

        if (auction.name === "" || typeof (auction.name) === "undefined") {
            setFailedValidation("name", "Please give the auction a name.")
        }

        if (!createDialog) {
            if (auction.smsSender === "" || typeof (auction.smsSender) === "undefined") {
                setFailedValidation("smsSender", "Please set the SMS Sender.")
            } else if (auction.smsSender.length < 4 || auction.smsSender.length > 11) {
                setFailedValidation("smsSender", "Please set valid SMS Sender. Must be between 4 to 11 alphanumeric characters including at least one letter.")
            } else {
                let isValid = false
                // Check if the input contains an emoji
                if (emojiRegex.test(auction.smsSender)) {
                    isValid = false
                }
                // Check if the input is between 4 to 11 characters and contains at least one letter
                if (/[a-zA-Z]/.test(auction.smsSender)) {
                    // Check if the input consists of alphanumeric characters only
                    if (alphanumericRegex.test(auction.smsSender)) {
                        isValid = true; // Valid input
                    }
                }
                // Check if the input is a phone number without a leading '+'
                if (phoneNumberRegex.test(auction.smsSender)) {
                    isValid = true; // Valid input
                }
                if (!isValid) {
                    setFailedValidation("smsSender", "Please set valid SMS Sender. Must be between 4 to 11 alphanumeric characters including at least one letter.")
                }
            }
        }

        if (!auction.campaignDate || typeof (auction.campaignDate) === "undefined") {
            setFailedValidation("campaignDate", "Please set the campaign date.")
        }

        if (typeof (auction.paymentMethod) === "undefined") {
            setFailedValidation("paymentMethod", "Please provide a payment method for the auction.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs
    }

}

export default useCampaignValidation