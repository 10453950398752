import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

import ICommunicationUser from "../../../../../interfaces/communicationUserInfo";

import RecipientsTable from "../../../../shared/communicationDialogs/recipientsTable/RecipientsTable";

import "./recipientsDialog.scss";

interface IRecipientsDialogProps {
  visible: boolean;
  handleClose: () => void;
  recipients: ICommunicationUser[];
  selectedRecipientsCategory: string;
}

const RecipientsDialog = (props: IRecipientsDialogProps) => {
  const [recipientsDialogState, setRecipientsDialogState] = useState({
    title: "",
  });

  useEffect(() => {
    setRecipientsDialogState({
      ...recipientsDialogState,
      title: props?.selectedRecipientsCategory,
    });
  }, [props.selectedRecipientsCategory]);

  // no recipients found
  const renderRecipientsFound = () => {
    if (props?.recipients?.length) {
      return <RecipientsTable recipients={props.recipients} />;
    } else {
      return (
        <Typography className="no-entries" variant="subtitle1" gutterBottom>
          No Recipients Found
        </Typography>
      );
    }
  };

  return (
    <>
      <Dialog
        onClose={props.handleClose}
        open={props.visible}
        className={
          props?.recipients?.length
            ? "recipients-dialog-full-width"
            : "recipients-dialog-half-width"
        }
      >
        <DialogTitle>
          <b>{recipientsDialogState?.title}</b>
        </DialogTitle>
        <DialogContent>{renderRecipientsFound()}</DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecipientsDialog;
