import "./tagsSection.scss"
import React, { useEffect, useState } from "react"
import { Paper, TextField, Button, Tooltip } from "@mui/material"
import TagButton from "../../../shared/TagButton"

interface InputProps {
	object: any
	setObject: (object: any) => void
	tags: string[]
	isCampaignClosed: boolean
	objectName: string
	fromVMS: boolean
}

interface TagObject {
	name: string
	isSelected: boolean
}

const TagsSection = (props: InputProps) => {
	const [fixedTags, setFixedTags] = useState<TagObject[]>([
		{ name: "Live Auction", isSelected: false },
		{ name: "Featured Lots", isSelected: false },
		{ name: "Pledge", isSelected: false },
		{ name: "Donated Lots", isSelected: false },
	])

	const [existingTags, setExistingTags] = useState<TagObject[]>([])
	const [newTags, setNewTags] = useState<string[]>([])
	const [newTag, setNewTag] = useState<string>("")
	const [errorMessage, setErrorMessage] = useState<string>("")

	const updateLotTags = () => {
		let allTags: string[] = props.object.tagList && props.object.tagList.length > 0 ? [...props.object.tagList] : []

		fixedTags.forEach(element => {
			if (element.isSelected && !props.object.tagList.includes(element.name)) {
				allTags.push(element.name)
			}
			else if (!element.isSelected) {
				allTags = allTags.filter(x => x !== element.name)
			}
		})

		existingTags.forEach(element => {
			if (element.isSelected && !props.object.tagList.includes(element.name)) {
				allTags.push(element.name)
			}
			else if (!element.isSelected) {
				allTags = allTags.filter(x => x !== element.name)
			}
		})

		newTags.forEach(element => {
			allTags.push(element)
		})

		props.setObject({ ...props.object, tagList: allTags })
	}

	const selectTag = (tag: TagObject, tagList: TagObject[], setTagList: (tagList) => void) => {
		var tempTagList: any = Object.assign([], tagList)

		tempTagList.forEach(element => {
			if (tag.name === element.name) {
				element.isSelected = !element.isSelected
			}
		})

		setTagList(tempTagList)
	}

	const addNewTag = () => {
		let tempNewTagList: any = Object.assign([], newTags)

		let isTagAlreadyExists = false

		if (newTag) {
			if (props.objectName === "Lot") {
				fixedTags.forEach(element => {
					if (element.name.toLowerCase() === newTag.toLowerCase()) {
						isTagAlreadyExists = true
					}
				})
			}

			existingTags.forEach(element => {
				if (element.name.toLowerCase() === newTag.toLowerCase()) {
					isTagAlreadyExists = true
				}
			})
			newTags.forEach(element => {
				if (element.toLowerCase() === newTag.toLowerCase()) {
					isTagAlreadyExists = true
				}
			})

			if (isTagAlreadyExists) {
				setErrorMessage("Tag already exists")
			} else {
				tempNewTagList.push(newTag)
				setNewTags(tempNewTagList)
				setNewTag("")
			}
		} else {
			setErrorMessage("Please enter a value")
		}
	}

	const removeTag = index => {
		let tempNewTagList: any = Object.assign([], newTags)
		tempNewTagList.splice(index, 1)
		setNewTags(tempNewTagList)
	}

	const handleChange = event => {
		const { value } = event.target
		setNewTag(value)
		setErrorMessage("")
	}

	useEffect(() => {
		let tempLotTagList: string[] = props.object.tagList ? [...props.object.tagList] : []
		let tempFixedTagList: TagObject[] = Object.assign([], fixedTags)
		let tempExistingTagList: TagObject[] = []
		props.tags &&
			props.tags.forEach(tag => {
				if (!tempExistingTagList.map(t => t.name).includes(tag)) {
					tempExistingTagList.push({ name: tag, isSelected: false })
				}
			})
		let tempNewTagList: string[] = []

		for (var fixedTagIndex = 0; fixedTagIndex < tempFixedTagList.length; fixedTagIndex++) {
			for (var existingTagIndex = 0; existingTagIndex < tempExistingTagList.length; existingTagIndex++) {
				if (
					tempFixedTagList[fixedTagIndex].name &&
					tempExistingTagList[existingTagIndex].name &&
					tempFixedTagList[fixedTagIndex].name.toLowerCase() === tempExistingTagList[existingTagIndex].name.toLowerCase()
				) {
					tempExistingTagList.splice(existingTagIndex, 1)
					existingTagIndex--
				}
			}

			for (var lotTagIndex = 0; lotTagIndex < tempLotTagList.length; lotTagIndex++) {
				if (
					tempFixedTagList[fixedTagIndex].name &&
					tempLotTagList[lotTagIndex] &&
					tempFixedTagList[fixedTagIndex].name.toLowerCase() === tempLotTagList[lotTagIndex].toLowerCase()
				) {
					tempLotTagList.splice(lotTagIndex, 1)
					lotTagIndex--
					tempFixedTagList[fixedTagIndex].isSelected = true
				}
			}
		}

		tempLotTagList.forEach(lotTag => {
			tempExistingTagList.forEach((existingTag, existingTagIndex) => {
				if (existingTag.name.toLowerCase() === lotTag.toLowerCase()) {
					tempExistingTagList[existingTagIndex].isSelected = true
				}
			})
		})

		setFixedTags(tempFixedTagList)
		setExistingTags(tempExistingTagList)
		setNewTags(tempNewTagList)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		updateLotTags()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fixedTags, newTags, existingTags])

	return (
		<div className="tags-section">
			<h2 className="subtitle">Tags.</h2>
			<span className="description">Set the tags used for filtering {props.objectName.toLowerCase()}s.</span>

			{/* {props.fromVMS ? (
				<Paper className="input-wrapper">
					<div className="sub-section-wrapper">
						<span className="sub-title">Selected Tags</span>
						{props.object.tagList && props.object.tagList.length > 0 ? (
							<>
								<span className="description">Tags selected for this lot.</span>
								<div className="tag-wrapper">
									{props.object.tagList.map((tag, index) => {
										return (
											<TagButton
												key={index}
												isSelected={true}
												isDiabled={true}
												onClick={() => { }}
												text={tag}
											/>
										)
									})}
								</div>
							</>
						) : (
							<div className="no-tags">No selected tags found.</div>
						)}
					</div>
				</Paper>
			) : ( */}
				<Paper className="input-wrapper">
					{props.objectName === "Lot" && (
						<div className="sub-section-wrapper">
							<span className="sub-title">Fixed Tags</span>
							<span className="description">If selected these tags will display first in the list.</span>
							<div className="tag-wrapper">
								{fixedTags.map((tag, index) => {
									return (
										<TagButton
											key={index}
											isSelected={tag.isSelected}
											isDiabled={props.isCampaignClosed}
											onClick={() => selectTag(tag, fixedTags, setFixedTags)}
											text={tag.name}
										/>
									)
								})}
							</div>
						</div>
					)}

					<div className="sub-section-wrapper">
						<span className="sub-title">Existing Tags</span>
						<span className="description">Select a tag that has been used for an existing {props.objectName.toLowerCase()}.</span>
						<div className="tag-wrapper">
							{existingTags.length > 0 ? (
								existingTags.map((tag, index) => {
									return (
										<TagButton
											key={index}
											isSelected={tag.isSelected}
											isDiabled={props.isCampaignClosed}
											onClick={() => selectTag(tag, existingTags, setExistingTags)}
											text={tag.name}
										/>
										// <Button key={index} className={tag.isSelected ? "filter-button filled" : "filter-button"} disabled={props.isCampaignClosed} onClick={() => selectTag(tag, existingTags, setExistingTags)}>{tag.name}</Button>
									)
								})
							) : (
								<div className="no-tags">No existing tags found.</div>
							)}
						</div>
					</div>

					{!props.isCampaignClosed && (
						<>
							<div className="sub-section-wrapper">
								<span className="sub-title">New Tags</span>
								<span className="description">Create a new tag to be used for this {props.objectName.toLowerCase()}.</span>
								<div className="tag-wrapper new-tag-list">
									{newTags.length > 0 ? (
										newTags.map((tag, index) => {
											return (
												<div className="new-tag-wrapper" key={index}>
													{/* <Button key={index} className="filter-button filled">
														{tag}
													</Button> */}
													<TagButton key={index} isSelected={true} isDiabled={false} onClick={() => { }} text={tag} />

													<Tooltip title="Delete tag">
														<div className="remove-button" onClick={() => removeTag(index)}>
															x
														</div>
													</Tooltip>
												</div>
											)
										})
									) : (
										<div className="no-tags">No new tags added.</div>
									)}
								</div>
							</div>
							<div className="sub-section-wrapper">
								<div className="new-tag-input-wrapper">
									<TextField
										label="New Tag"
										variant="outlined"
										value={newTag}
										onChange={handleChange}
										helperText={errorMessage}
										error={errorMessage ? true : false}
									/>

									<Button variant="contained" className="gg-button" onClick={addNewTag}>
										Add
									</Button>
								</div>
							</div>
						</>
					)}
				</Paper>
			{/* )} */}
		</div>
	)
}

export default TagsSection
