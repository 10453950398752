import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import './revokeLicenceBundleDialog.scss'

interface InputProps {
	isDialogOpen: boolean
	handleClose: (userId?: string) => void
	userId: string
	isCampaignView: boolean
}

const RemoveStaffDialog = (props: InputProps) => {

	return (
		<Dialog open={props.isDialogOpen} onClose={() => props.handleClose()} className="remove-staff-dialog">
			<DialogTitle>{`Remove Staff from ${props.isCampaignView ? 'Campaign' : 'Organisation'}`}</DialogTitle>
			<DialogContent>
				<div className="intro">
					<span>{`Are you sure you want to remove this user from ${props.isCampaignView ? 'campaign' : 'organisation'}?`}</span>
				</div>
			</DialogContent>
			
			<DialogActions>
				<Button onClick={() => props.handleClose()}>Cancel</Button>
				<Button onClick={() => props.handleClose(props.userId)} className="gg-button">
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default RemoveStaffDialog