import React, { useContext, useMemo, useState } from 'react'
import { Link, Menu, MenuItem } from '@mui/material'
// import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import './loginButton.scss'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { CampaignContext } from '../../context/CampaignContext'
import AutoLogoutDialog from '../admin/shared/AutoLogoutDialog'
import { isTablet } from 'react-device-detect'

interface InputProps {
    campaignId: string
    campaignSlug: string
    organisationId: string
    color: string
    determineLogoutReturnToPath: () => void
    setIsLoginDialogOpen: (value: boolean) => void
}

const LoginButton = (props: InputProps) => {
    // const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0()
    const { dBUser, auth0User, logout } = useContext(UserContext)
    const isAuthenticated = useMemo(() => (dBUser && dBUser._id), [dBUser])
    const campaign = useContext(CampaignContext)
    let history = useHistory()

    const [isEnterPinDialogOpen, setIsEnterPinDialogOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const getUserName = () => {
        // let userName = auth0User?.given_name && auth0User?.family_name ? `${auth0User.given_name} ${auth0User.family_name}` : `${auth0User?.name || ''}`;
        let userName = dBUser?.firstName && dBUser?.surname ? `${dBUser.firstName} ${dBUser.surname}` : `${dBUser?.email || ''}`;
        


        return userName && userName.length > 20 ? `${userName.substring(0, 20)}...` : userName;
    }

    return (
        <>
            <div className='login-button-wrapper'>
                {isAuthenticated ?
                    <a onClick={handleClick} style={{ color: props.color }}>
                        {auth0User &&
                            <div className="user-wrapper">
                                {getUserName()}
                            </div>
                        }
                    </a>

                    :
                    <a
                        onClick={() => {
                            props.setIsLoginDialogOpen(true)
                            localStorage.setItem('redirectUri', window.location.pathname)
                        }}
                        style={{ color: props.color }}
                    >
                        LOG IN
                    </a>
                }
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="basic-menu"
            >
                <Link onClick={() => {
                    if (isTablet) {
                        setIsEnterPinDialogOpen(true)
                    }
                    else {
                        history.push(`/${props.campaignSlug}/my-account`)
                    }
                }}>
                    <MenuItem onClick={handleClose}>My Account</MenuItem>
                </Link>
                <a>
                    <MenuItem onClick={() => {
                        handleClose()
                        history.push(`/${campaign.slug}?logout`)
                        logout()
                    }}>Log Out</MenuItem>
                </a>
            </Menu>

            {isTablet && (
                <AutoLogoutDialog
                    isDialogOpen={isEnterPinDialogOpen}
                    handleClose={(isPINCorrect?: boolean) => {
                        setIsEnterPinDialogOpen(false)
                        if (isPINCorrect) {
                            history.push(`/${props.campaignSlug}/my-account`)
                        }
                    }}
                    secondsRemaining={0}
                    handleStillHere={() => { }}
                    isAutoLogout={false}
                />
            )}
        </>
    )
}

export default LoginButton