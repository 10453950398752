import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, TextField } from "@mui/material"
import React, { useContext, useMemo, useState } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import IAuditLogFilters from "../../../interfaces/auditLogFilters"
import ICampaign from "../../../interfaces/campaign"
import { IUser } from "../../../interfaces/user"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignsBySearchStringQueryString, USER_API_PATHS } from "../../../common/ApiPaths"
import { AuditLogCategories } from "../../../common/enums/AuditLogCategories"
import { UserContext } from "../../../context/UserContext"
import { OrganisationContext } from "../../../context/OrganisationContext"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { CampaignContext } from "../../../context/CampaignContext"
import useCommonFunctions from "../../../hooks/useCommonFunctions"

interface InputProps {
	auditLogFilters: IAuditLogFilters
	setAuditLogFilters: (obj: IAuditLogFilters) => void
	isCampaign: boolean
}

const AuditLogFilterSection = (props: InputProps) => {
	const [userList, setUserList] = useState<IUser[]>([])
	const [campaignList, setCampaignList] = useState<ICampaign[]>([])
	const [auditLogCategoryList, setAuditLogCategoryList] = useState<string[]>(Object.values(AuditLogCategories))
	const [isClearFilters, setIsClearFilters] = useState<boolean>(false)

	const usersApi = useFetch(API_PATHS.USERS)
	const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
	const { dBUser } = useContext(UserContext)
	const organisation = useContext(OrganisationContext)
	const campaign = useContext(CampaignContext)
	const commonFunctions = useCommonFunctions()

	const showClearFilters = useMemo(() => {
		if (!props.isCampaign) {
			return (props.auditLogFilters.campaignId || props.auditLogFilters.category || props.auditLogFilters.fromDate || props.auditLogFilters.toDate || props.auditLogFilters.userId) ? true : false
		}
		else {
			return (props.auditLogFilters.category || props.auditLogFilters.fromDate || props.auditLogFilters.toDate || props.auditLogFilters.userId) ? true : false
		}
	}, [props.auditLogFilters])

	const getUserList = async (input: string) => {
		if (input.length > 2) {

			let filteredUserList: IUser[] = []
			if (!props.isCampaign) {
				filteredUserList = await usersApi.get(`${USER_API_PATHS.GET_BY_SEARCH_STRING.replace("{searchString}", input)}?organisationId=${organisation._id}&currentUserId=${dBUser._id}&userRole=${dBUser.role}`)
			}
			else {
				filteredUserList = await usersApi.get(`${USER_API_PATHS.GET_BY_SEARCH_STRING.replace("{searchString}", input)}?organisationId=${organisation._id}&currentUserId=${dBUser._id}&userRole=${dBUser.role}&campaignId=${campaign._id}`)
			}
			setUserList([...filteredUserList])
		} else {
			setUserList([])
		}
	}

	const getCampaignList = async (input: string) => {
		if (input.length > 2) {
			let filteredCampaignList: ICampaign[] = await campaignsApi.get(
				`${CAMPAIGN_API_PATHS.GET_BY_SEARCH_STRING.replace("{searchString}", input)}${getCampaignsBySearchStringQueryString(dBUser._id, dBUser.role, false, true, dBUser.organisationId)}`
			)

			setCampaignList([...filteredCampaignList])
		} else {
			setCampaignList([])
		}
	}

	const clearFilters = () => {
		//if campaign auditlog, leave campaign filter as is. Otherwise blank all filters.
		if (props.isCampaign) {
			props.setAuditLogFilters({ ...props.auditLogFilters, category: undefined, userId: undefined, fromDate: null, toDate: null } as IAuditLogFilters)
		} else {
			props.setAuditLogFilters({ fromDate: null, toDate: null } as IAuditLogFilters)
		}
		setCampaignList([])
		setUserList([])
		setIsClearFilters(!isClearFilters)
	}

	return (
		<div className="accordion-wrapper">
			<Accordion className="filter-accordian">
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
					<span className="title">Filters</span>
				</AccordionSummary>
				<AccordionDetails className="input-wrapper" style={{ marginBottom: 0 }}>
					<div className="field-wrapper">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								label="From Date"
								value={props.auditLogFilters.fromDate}
								onChange={date => props.setAuditLogFilters({ ...props.auditLogFilters, fromDate: date ? date : undefined })}
								ampm={false}
								// hideTabs
								// showTodayButton
								format="HH:mm DD/MM/YYYY"
							// renderInput={params => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
					<div className="field-wrapper">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								label="To Date"
								value={props.auditLogFilters.toDate}
								onChange={date => props.setAuditLogFilters({ ...props.auditLogFilters, toDate: date ? date : undefined })}
								ampm={false}
								// hideTabs
								// showTodayButton
								format="HH:mm DD/MM/YYYY"
							// renderInput={params => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
					<div className="field-wrapper">
						<Autocomplete
							key={String(isClearFilters)} //changing this value causes the element to rerender and clear itself
							style={{ width: "100%" }}
							disablePortal
							options={userList}
							noOptionsText="Please enter 3 characters to search..."
							getOptionLabel={option => `${commonFunctions.titleCase(`${option.firstName} ${option.surname}`)} (${option.email})`}
							renderInput={params => <TextField {...params} label="User Name" helperText="Enter 3 characters to display options." />}
							onInputChange={(e, value) => getUserList(value)}
							onChange={(event: any, newValue: IUser | null) => {
								props.setAuditLogFilters({ ...props.auditLogFilters, userId: newValue?._id })
							}}
							clearOnEscape
						/>
					</div>
					{!props.isCampaign && (
						<div className="field-wrapper">
							<Autocomplete
								key={String(isClearFilters)} //changing this value causes the element to rerender and clear itself
								style={{ width: "100%" }}
								disablePortal
								options={campaignList}
								noOptionsText="Please enter 3 characters to search..."
								getOptionLabel={option => `${option.name} ${option.isActive ? '' : '(Deleted)'}`}
								renderInput={params => <TextField {...params} label="Campaign" helperText="Enter 3 characters to display options." />}
								onInputChange={(e, value) => getCampaignList(value)}
								onChange={(event: any, newValue: ICampaign | null) => {
									props.setAuditLogFilters({ ...props.auditLogFilters, campaignId: newValue?._id })
								}}
								clearOnEscape
							/>
						</div>
					)}
					<div className="field-wrapper">
						<Autocomplete
							key={String(isClearFilters)} //changing this value causes the element to rerender and clear itself
							style={{ width: "100%" }}
							disablePortal
							options={auditLogCategoryList}
							renderInput={params => <TextField {...params} label="Type" />}
							onChange={(event: any, newValue: any) => {
								props.setAuditLogFilters({ ...props.auditLogFilters, category: newValue })
							}}
							clearOnEscape
						/>
					</div>
				</AccordionDetails>
			</Accordion>
			{showClearFilters && (
				<div className="clear-filter-wrapper">
					<div className="hyperlink" style={{ fontSize: "0.8rem" }} onClick={() => clearFilters()}>
						Clear filters
					</div>
				</div>
			)}
		</div>
	)
}

export default AuditLogFilterSection
