import React, { useContext, useEffect, useState } from "react"
import "./organisationSummary.scss"
import TopEventsSection from "./TopEventsSection"
import TopSupportersSection from "./TopSupportersSection"
import LicencePurchaseDialog from "../../shared/LicencePurchaseDialog"
import OrganisationDetailsSection from "./OrganisationDetailsSection"
import ICampaign from "../../../interfaces/campaign"
import { IOrganisationSummary } from "../../../common/interfaces/OrganisationSummary"
import useFetch from "../../../hooks/useFetch"
import { API_PATHS, ORGANISATIONS_API_PATHS } from "../../../common/ApiPaths"
import { OrganisationContext } from "../../../context/OrganisationContext"
import ConfirmationDialog from "../../shared/ConfirmationDialog"
import { UserContext } from "../../../context/UserContext"
import { IUser } from "../../../interfaces/user"
import { UserRole } from "../../../common/enums/UserRole"
import Button from "@mui/material/Button/Button"
import useCommonFunctions from "../../../hooks/useCommonFunctions"
import SummaryFiguresSection from "./SummaryFiguresSection"

interface InputProps {
	campaignList: ICampaign[]
}

const OrganisationSummary = (props: InputProps) => {
	const [isLicencePurchaseDialogOpen, setIsLicencePurchaseDialogOpen] = useState<boolean>(false)
	const [orgSummary, setOrgSummary] = useState<IOrganisationSummary>({} as IOrganisationSummary)
	const [dialogProperties, setDialogProperties] = useState<any>({ isOpen: false, type: "", title: "", message: "" })

	let organisationApi = useFetch(API_PATHS.ORGANISATIONS)
	const organisation = useContext(OrganisationContext)
	const { dBUser } = useContext(UserContext)
	const commonFunctions = useCommonFunctions()

	let currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: organisation.currency ?? "GBP",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})

	const getOrganisationSummary = async () => {
		try {
			let summary: IOrganisationSummary = await organisationApi.get(`${ORGANISATIONS_API_PATHS.GET_ORGANISATION_SUMMARY.replace('{organisationId}', organisation._id)}`)
			setOrgSummary(summary)
		}
		catch (err: any) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (organisation._id) {
			getOrganisationSummary()
		}
	}, [organisation])

	return (
		<div className="organisation-summary-page">
			<OrganisationDetailsSection
				setIsLicencePurchaseDialogOpen={setIsLicencePurchaseDialogOpen}
				campaignList={props.campaignList}
				totalEvents={orgSummary.totalEvents}
			/>
			<SummaryFiguresSection nameValuePairList={[
				{name: "Events Completed", value: String(orgSummary.eventsCompleted || 0)},
				{name: "Total Winning Bid / Purchase / Pledge Value", value: `${currencyFormatter.format(orgSummary.totalBidValue || 0)}` },
				{name: "Total Winning Bid / Purchase / Pledge Count", value: String(orgSummary.totalBidCount || 0)},
				{name: "Users Registered", value: String(orgSummary.usersRegistered || 0)},
				{name: "Gross Funds Collected", value: `${currencyFormatter.format(orgSummary.grossFundsCollected || 0)}`},
				{name: "Net Funds Collected", value: `${currencyFormatter.format(orgSummary.netFundsCollected || 0)}`}
			]}/>
			
			{/* <SummaryFiguresSection currency={organisation.currency} campaignList={props.campaignList} orgSummary={orgSummary} /> */}
			<TopEventsSection topCampaigns={orgSummary.topCampaigns} currency={organisation.currency} />
			<TopSupportersSection topSupporters={orgSummary.topSupporters} currency={organisation.currency} />
			{dBUser.role !== UserRole.OPERATOR && (
				<>
					<LicencePurchaseDialog
						handleClose={() => setIsLicencePurchaseDialogOpen(false)}
						isDialogOpen={isLicencePurchaseDialogOpen}
						setDialogProperties={setDialogProperties}
					/>
					<ConfirmationDialog
						handleClose={() => setDialogProperties({ isOpen: false })}
						isDialogOpen={dialogProperties.isOpen}
						message={dialogProperties.message}
						title={dialogProperties.title}
						type={dialogProperties.type}
						label={dialogProperties.label}
						primaryButton={<Button onClick={() => setDialogProperties({ isOpen: false })} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
							Ok
						</Button>}
					/>
				</>
			)}

		</div>
	)
}

export default OrganisationSummary
