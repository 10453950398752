import { useState } from "react";
import ITestMessageModalState from "../../../interfaces/testMessageModal";
import IValidationObject from "../../../interfaces/validationObject";
import striptags from "striptags";
import { isValidCommunicationFromField } from "../../../utils/validators";

const useCommunicationValidation = (
  communication: ITestMessageModalState,
  messageType: string,
  selectedRecipientsCategory: string
) => {
  const [validationFields, setValidationFields] = useState<IValidationObject[]>(
    [
      { name: "from", isValid: true, validationMessage: "" },
      { name: "message", isValid: true, validationMessage: "" },
      { name: "recipients-category", isValid: true, validationMessage: "" },
    ]
  );
  const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true);

  // Individually validate each field
  const getFieldValidation = (fieldName: string, value: string) => {
    const updatedValidationFields = validationFields.map((field) => {
      if (field.name === fieldName) {
        // Perform field-specific validation and update the error message
        let validationMessage = "";

        if (messageType === "Email") {
          if (fieldName === "from" && (!value || !value.length)) {
            validationMessage = "Please enter a valid 'From' field.";
          }

          if (
            fieldName === "message" &&
            (!value || !(striptags(value).length > 0))
          ) {
            validationMessage = `Please enter a valid message in order to dispatch an ${messageType.toLocaleLowerCase()}.`;
          }

          if (
            fieldName === "recipients-category" &&
            (!selectedRecipientsCategory ||
              selectedRecipientsCategory.length === 0)
          ) {
            validationMessage = `Please select recipients category in order to dispatch a ${messageType.toLocaleLowerCase()}.`;
          }
        }

        if (messageType === "SMS") {
          if (fieldName === "from" && !isValidCommunicationFromField(value)) {
            validationMessage = "Please enter a valid 'From' field.";
          }

          if (fieldName === "message" && (!value || !value.length)) {
            validationMessage = `Please enter a valid message in order to dispatch a ${messageType}.`;
          }

          if (
            fieldName === "recipients-category" &&
            (!selectedRecipientsCategory ||
              selectedRecipientsCategory.length === 0)
          ) {
            validationMessage = `Please select recipients category in order to dispatch a ${messageType}.`;
          }
        }

        return {
          ...field,
          isValid: validationMessage === "",
          validationMessage,
        };
      }

      return field;
    });

    setValidationFields(updatedValidationFields);
  };

  // validate input fields
  const validateInputs = (): boolean => {
    let tempValidationObject: IValidationObject[] = [
      { name: "from", isValid: true, validationMessage: "" },
      { name: "message", isValid: true, validationMessage: "" },
      { name: "recipients-category", isValid: true, validationMessage: "" },
    ];

    let tempIsValidationPassed = true;

    const setFailedValidation = (name: string, message: string) => {
      tempIsValidationPassed = false;
      tempValidationObject?.forEach((element) => {
        if (element?.name === name) {
          element.isValid = false;
          element.validationMessage = message;
        }
      });
    };

    // email validation:
    if (messageType === "Email") {
      if (
        !communication.emailData.from ||
        !communication.emailData.from.length
      ) {
        setFailedValidation("from", "Please enter a valid 'From' field.");
      }

      if (
        !communication.emailData.message ||
        !(striptags(communication?.emailData?.message).length > 0)
      ) {
        setFailedValidation(
          "message",
          `Please enter a valid message in order to dispatch an ${messageType.toLocaleLowerCase()}.`
        );
      }

      if (
        selectedRecipientsCategory === "" ||
        !selectedRecipientsCategory.length
      ) {
        setFailedValidation(
          "recipients-category",
          `Please select recipients category to dispatch an ${messageType.toLocaleLowerCase()}.`
        );
      }
    }

    if (messageType === "SMS") {
      // sms validation:
      if (
        !communication.smsData.from ||
        !isValidCommunicationFromField(communication.smsData.from)
      ) {
        setFailedValidation("from", "Please enter a valid 'From' field.");
      }

      if (
        !communication.smsData.message ||
        !communication.smsData.message.length
      ) {
        setFailedValidation(
          "message",
          `Please enter a valid message in order to dispatch a ${messageType}.`
        );
      }

      if (
        selectedRecipientsCategory === "" ||
        !selectedRecipientsCategory.length
      ) {
        setFailedValidation(
          "recipients-category",
          `Please select recipients category to dispatch a ${messageType}.`
        );
      }
    }
    setValidationFields(tempValidationObject);
    setIsValidationPassed(tempIsValidationPassed);
    return tempIsValidationPassed;
  };

  // reset validation
  const resetValidationFields = () => {
    setValidationFields([
      { name: "from", isValid: true, validationMessage: "" },
      { name: "message", isValid: true, validationMessage: "" },
      { name: "recipients-category", isValid: true, validationMessage: "" },
    ]);
    setIsValidationPassed(true);
  };

  return {
    validationFields,
    setValidationFields,
    isValidationPassed,
    setIsValidationPassed,
    validateInputs,
    resetValidationFields,
    getFieldValidation,
  };
};

export default useCommunicationValidation;
