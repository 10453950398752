import React, { ChangeEvent, FocusEvent } from "react";

import { Box, TextField, Typography } from "@mui/material";
import IEmailFormValues from "../../../../../interfaces/emailFormValues";
import IEmailFormError from "../../../../../interfaces/emailFormError";
import ITestMessageResponseState from "../../../../../interfaces/testMessageResponse";
import IValidationObject from "../../../../../interfaces/validationObject";

interface IEmailFormProps {
  emailError: IEmailFormError;
  emailValues: IEmailFormValues;
  getTestValidation: (args: string) => IValidationObject;
  testMessageResponse: ITestMessageResponseState;
  onBlurHandler: (
    event: FocusEvent<HTMLInputElement>,
    messageType: string
  ) => void;
  handleEmailChange(event: ChangeEvent<HTMLInputElement>): void;
}

const EmailForm = (props: IEmailFormProps) => {
  // validation on blur handler
  const validateFormFieldsOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    props.onBlurHandler(event, "Email");
  };

  return (
    <>
      <div style={{ marginTop: "6px" }}>
        <TextField
          id="to"
          name="to"
          label="To"
          variant="outlined"
          required
          fullWidth
          onBlur={validateFormFieldsOnBlur}
          value={props.emailValues.to || ""}
          onChange={props.handleEmailChange}
          error={
            !!props.emailError.display || !props.getTestValidation("to").isValid
          }
          helperText={
            props.emailError.display
              ? props.emailError.message
              : props.getTestValidation("to").isValid
              ? "The email address of the test recipient."
              : props.getTestValidation("to").validationMessage
          }
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <TextField
          id="firstName"
          name="firstName"
          label="User First Name"
          fullWidth
          onChange={props.handleEmailChange}
          helperText={
            props.getTestValidation("firstName").isValid
              ? "This is the value that will be inserted instead of the tag {{firstName}} if used."
              : props.getTestValidation("firstName").validationMessage
          }
          error={!props.getTestValidation("firstName").isValid}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <TextField
          id="surname"
          name="surname"
          label="User Surname"
          fullWidth
          onChange={props.handleEmailChange}
          helperText={
            props.getTestValidation("surname").isValid
              ? "This is the value that will be inserted instead of the tag {{surname}} if used."
              : props.getTestValidation("surname").validationMessage
          }
          error={!props.getTestValidation("surname").isValid}
        />
      </div>
      {/* render success or fail response */}
      {props?.testMessageResponse?.successMessage?.length ? (
        <>
          <Typography variant="subtitle1">Send Status</Typography>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            {props?.testMessageResponse?.icon}
            <Box sx={{ width: "10px" }} />
            <Typography variant="subtitle2">
              {props?.testMessageResponse?.successMessage}
            </Typography>
          </div>
        </>
      ) : (
        <></>
      )}
      {props?.testMessageResponse?.errorMessage?.length ? (
        <>
          <Typography variant="subtitle1">Send Status</Typography>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            {props?.testMessageResponse?.icon}
            <Box sx={{ width: "10px" }} />
            <Typography variant="subtitle2">
              {props?.testMessageResponse?.errorMessage}
            </Typography>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EmailForm;
