import { useState } from "react";
import { IUser } from "../../interfaces/user";
import IValidationObject from "../../interfaces/validationObject";
import { isValidEmail } from "../../utils/validators";

const useLoginSignupValidation = (user: IUser) => {
    const [validationObject, setValidationObject] = useState<IValidationObject[]>([
        { name: "firstName", isValid: true, validationMessage: "" },
        { name: "surname", isValid: true, validationMessage: "" },
        { name: "email", isValid: true, validationMessage: "" },
        { name: "password", isValid: true, validationMessage: "" },
        { name: "phone", isValid: true, validationMessage: "" },
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const generateInitialValidationObject = (): void => {

        let tempValidationObject: IValidationObject[] = [
            { name: "firstName", isValid: true, validationMessage: "" },
            { name: "surname", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "password", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
        ]

        setValidationObject(tempValidationObject)
        setIsValidationPassed(true)

    }

    const validateInputs = (tab: string): boolean => {

        let tempValidationObject: IValidationObject[] = [
            { name: "firstName", isValid: true, validationMessage: "" },
            { name: "surname", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "password", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (!user.email || (user.email.trim() === "" || typeof (user.email) === "undefined" || !isValidEmail(user.email))) {
            setFailedValidation("email", "Please enter a valid email.")
        }
        if (user.password === "" || typeof (user.password) === "undefined") {
            setFailedValidation("password", "Please enter your password.")
        }
        if (tab === 'SIGNUP') {
            if (!user.firstName || (user.firstName.trim() === "" || typeof (user.firstName) === "undefined")) {
                setFailedValidation("firstName", "Please enter your firstName.")
            }
            if (!user.surname || (user.surname.trim() === "" || typeof (user.surname) === "undefined")) {
                setFailedValidation("surname", "Please enter your surname.")
            }
            if (user.phone === "" || typeof user.phone === "undefined") {
                setFailedValidation("phone", "Please enter a valid phone number.")
            } else {

                let phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

                try {
                    let number = phoneUtil.parseAndKeepRawInput(user.phone);
                    let code = phoneUtil.getRegionCodeForNumber(number);
                    if (!phoneUtil.isValidNumberForRegion(number, code)) {
                        setFailedValidation("phone", "Invalid phone number")
                    }
                }
                catch (err) {
                    setFailedValidation("phone", "Invalid phone number")
                }
            }
            var ValidatePassword = require('validate-password')
            var validator = new ValidatePassword();
            var passwordData = validator.checkPassword(user.password);
            if (!passwordData.isValid) {
                setFailedValidation("password", passwordData.validationMessage)
            }
        }

        // console.log(tempValidationObject)
        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }


    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation,
        generateInitialValidationObject
    }

}

export default useLoginSignupValidation