import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import './adHocDialog.scss'
import BigScreenCountdownTimer from './BigScreenCountdownTimer'

interface InputProps {
    isShowAdHocPopup: boolean
    adHocPopupWidthPercentage: number
    adHocPopupTitle: string
    adHocPopupImageUrl: string
    adHocPopupImageSize: number
    adHocPopupisShowCountdown: boolean
    adHocPopupBody: string
    countdownStartDate: Date
    countdownEndDate: Date
    campaignNotYetOpenTitle: string
    countdownClosedMessage: string
    campaignLiveTitle: string
    closeAction: () => void
}

const AdHocDialog = (props: InputProps) => {

    return (
        <Dialog
            open={props.isShowAdHocPopup}
            aria-labelledby="title"
            className="ad-hoc-dialog"
            style={{ "--dialog-width": `${props.adHocPopupWidthPercentage}%` } as React.CSSProperties}
            onClose={() => props.closeAction()}
        >
            <DialogContent>
                {props.adHocPopupTitle && props.adHocPopupTitle.replace(/<(.|\n)*?>/g, '').trim().length !== 0 &&
                    <div className="ql-editor">
                        <h1 className='title' dangerouslySetInnerHTML={{ __html: props.adHocPopupTitle }}></h1>
                    </div>
                }

                {props.adHocPopupImageUrl && <img src={props.adHocPopupImageUrl} alt="Popup image" style={{ width: props.adHocPopupImageSize }} />}

                {props.adHocPopupisShowCountdown &&
                    <BigScreenCountdownTimer
                        // bigScreenPayload={props.bigScreenPayload}
                        countdownStartDate= {props.countdownStartDate}
                        countdownEndDate= {props.countdownEndDate}
                        campaignNotYetOpenTitle= {props.campaignNotYetOpenTitle}
                        countdownClosedMessage= {props.countdownClosedMessage}
                        campaignLiveTitle= {props.campaignLiveTitle}
                    />
                }


                {props.adHocPopupBody && props.adHocPopupBody.replace(/<(.|\n)*?>/g, '').trim().length !== 0 &&
                    <div className="ql-editor">
                        <h2 className='body' dangerouslySetInnerHTML={{ __html: props.adHocPopupBody }}></h2>
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default AdHocDialog