import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Auth0Provider } from '@auth0/auth0-react'
import { UserProvider } from './context/UserContext';

ReactDOM.render(
	// <Auth0Provider
	// 	domain="going-gone.eu.auth0.com"
	// 	clientId='MPyli3slNkBdAw2MdA069z50o5HV6VSY'
	// 	redirectUri={`${window.location.origin}/callback`}
	// 	useRefreshTokens
	// 	cacheLocation="localstorage"
	// 	audience="https://going-gone.eu.auth0.com/api/v2/"
	// 	scope="read:current_user update:current_user_metadata create:users"
	// >
	<UserProvider>
		<App />
	</UserProvider>,
	// </Auth0Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
