import React, { ReactNode } from "react";
import ILot from "../interfaces/lot";
import { IWinner } from "../interfaces/winner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import ICountryData from "../interfaces/countryData";
import IPhoneValidation from "../interfaces/phoneValidation";
import getSymbolFromCurrency from "currency-symbol-map";
import useFetch from "./useFetch";
import {
    API_PATHS,
    getUserQueryString,
    USER_API_PATHS,
} from "../common/ApiPaths";
import { IUser } from "../interfaces/user";
import IBidWithData from "../interfaces/bidWithData";
import { BidStatus } from "../common/enums/BidStatus";
import INextBidInfoForLot from "../interfaces/nextBidInfoForLot";
import IGetWinnerResponse, {
    IWinningBid,
} from "../interfaces/winningBidResponse";
import IBid from "../interfaces/bid";
import { Tooltip } from "@mui/material";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

const useCommonFunctions = () => {
    const userApi = useFetch(API_PATHS.USERS);

    const deriveBidData = (
        lot: ILot,
        bids: IBidWithData[]
    ): INextBidInfoForLot => {
        let result: INextBidInfoForLot = {
            winningBid: 0,
            nextValidBidValue: 0,
            cumulativeTotal: 0,
            winningBidder: "",
        };
        let succeededBids: IBidWithData[] = bids.filter(
            (b) => b.status === BidStatus.SUCCEEDED
        );

        if (lot.type === "SEALED") {
            result.nextValidBidValue = lot.reservePrice;
        } else if (lot.type === "COMPETITIVE") {
            result.winningBid = lot.reservePrice;
            result.nextValidBidValue = lot.reservePrice;

            let winningBidValue = Math.max(...succeededBids.map((x) => x.value));
            let winningBidData: IBidWithData | undefined = winningBidValue
                ? succeededBids.find((b) => b.value === winningBidValue)
                : undefined;
            if (winningBidData) {
                result.winningBid = winningBidValue;
                result.winningBidder = winningBidData.isAnonymous
                    ? "Anonymous"
                    : winningBidData.campaignUser.firstName
                        ? `${winningBidData.campaignUser.firstName} ${winningBidData.campaignUser.surname}`
                        : winningBidData.campaignUser?.email || winningBidData.campaignUser._id!;
                result.nextValidBidValue = winningBidValue + lot.bidIncrement;
            }
        } else if (lot.type === "BUYITNOW") {
            result.nextValidBidValue = 1;
        }
        return result;
    };

    const calculateServiceFee = (unitCost, serviceFee): number => {
        if (!serviceFee) serviceFee = 0;
        return Math.floor((serviceFee / 100) * unitCost * 100) / 100;
    };

    const calculateTotalWinningBidValue = (
        winner: IWinner,
        isIncludeServiceFee: boolean
    ): number => {
        let totalWinningBidValue = 0;
        winner.winnerItems.forEach((winnerItem) => {
            winnerItem.bidAndLotList.forEach((bidAndLot) => {
                if (!bidAndLot.winningBid.isCancelled) {
                    if (isIncludeServiceFee) {
                        let sum = bidAndLot.winningBid.bidList
                            .map((x) => x.value * x.count)
                            .reduce((a, b) => a + b);
                        totalWinningBidValue +=
                            sum +
                            calculateServiceFee(
                                sum,
                                bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage
                            );
                    } else {
                        totalWinningBidValue += bidAndLot.winningBid.totalValue;
                    }
                }
            });
        });

        return totalWinningBidValue;
    };

    const isArray = (object: any): boolean => {
        return Object.prototype.toString.call(object) === '[object Array]'
    }

    const isAuditLogErrors = (winner: IWinner) => {
        let isErrors = false;

        if (winner && winner.auditLog && isArray(winner.auditLog)) {
            winner.auditLog.forEach((log) => {
                if (log.isError) {
                    isErrors = true
                }
            })
        }

        return isErrors;
    };

    const determinePurchaseCount = (bids: any[]): number => {
        let count = 0;
        if (bids) {
            let succeededBids: any[] = bids.filter(
                (b) => b.status === BidStatus.SUCCEEDED
            );
            count =
                bids && bids.length > 0
                    ? succeededBids.map((x) => x.count).reduce((a, b) => a + b)
                    : 0;
        }
        return count;
    };

    const determineWinnerPaidStatus = (
        winnerItems: IGetWinnerResponse[]
    ): "PAID" | "NOT PAID" | "PARTIALLY PAID" => {
        let paidCount = 0;

        //remove cancelled from the list
        let filteredBidsAndLots = winnerItems
            .flatMap((x) => x.bidAndLotList)
            .filter((winnerItem) => !winnerItem.winningBid.isCancelled);

        filteredBidsAndLots.forEach((bidAndLot) => {
            if (bidAndLot.winningBid && bidAndLot.winningBid.paymentRefId)
                paidCount++;
        });

        if (paidCount === filteredBidsAndLots.length) {
            return "PAID";
        } else if (paidCount === 0) {
            return "NOT PAID";
        } else {
            return "PARTIALLY PAID";
        }
    };

    const determineAllWinnersPaidStatus = (
        winners: IWinner[]
    ): "PAID" | "NOT PAID" | "PARTIALLY PAID" => {
        let paidCount = 0;
        for (let winner of winners) {
            if (determineWinnerPaidStatus(winner.winnerItems) === "PAID") paidCount++;
        }
        if (paidCount === winners.length) {
            return "PAID";
        } else if (paidCount === 0) {
            return "NOT PAID";
        } else {
            return "PARTIALLY PAID";
        }
    };

    const determineAllWinnersVoucherToProcessCount = (
        winners: IWinner[]
    ): number => {
        let vouchersToProcessCount = 0;
        for (let winner of winners) {
            if (
                !(["Resolved", "Not Applicable"].includes(determineWinnerVoucherGeneratedStatus(winner.winnerItems)))
            ) {
                vouchersToProcessCount++;
            }
        }
        return vouchersToProcessCount;
    };

    const determineWinnerPaidStatusIcon = (
        winnerItems: IGetWinnerResponse[]
    ): any => {
        if (determineWinnerPaidStatus(winnerItems) === "PAID") {
            return (
                <>
                    <CheckCircleIcon className="tick-icon" />
                    <span className="status-text all-paid">Resolved</span>
                </>
            );
        } else if (determineWinnerPaidStatus(winnerItems) === "NOT PAID") {
            return (
                <>
                    <CancelIcon className="cross-icon" />
                    <span className="status-text none-paid">Incomplete</span>
                </>
            );
        } else {
            return (
                <>
                    <DoDisturbOnIcon className="mid-icon" />
                    <span className="status-text some-paid">Partially Resolved</span>
                </>
            );
        }
    };

    const determineLotPaidStatus = (
        winnerBid: IBid
    ): "PAID" | "PAID - MANUAL" | "NOT PAID" | "CANCELLED" => {
        if (winnerBid.isCancelled) {
            return "CANCELLED";
        } else if (winnerBid.stripePayment && winnerBid.stripePayment.paymentRefId) {
            if (winnerBid.stripePayment.paymentRefId.split("_")[0].toLocaleUpperCase() === "MANUAL") {
                return "PAID - MANUAL"
            } else {
                return "PAID";
            }
        } else {
            return "NOT PAID";
        }
    };

    const determineLotPaidStatusIcon = (winnerBid: IBid): any => {
        if (determineLotPaidStatus(winnerBid) === "PAID") {
            return (
                <>
                    <CheckCircleIcon className="tick-icon" />
                    <span className="status-text all-paid">Paid</span>
                </>
            );
        } else if (determineLotPaidStatus(winnerBid) === "PAID - MANUAL") {
            return (
                <>
                    <CheckCircleIcon className="tick-icon" />
                    <span className="status-text all-paid">Paid (Manual)</span>
                </>
            );
        } else if (determineLotPaidStatus(winnerBid) === "NOT PAID") {
            return (
                <>
                    <CancelIcon className="cross-icon" />
                    <span className="status-text none-paid">Not Paid</span>
                </>
            );
        } else if (determineLotPaidStatus(winnerBid) === "CANCELLED") {
            return (
                <>
                    <DoDisturbOnIcon className="cancel-icon" style={{ color: "gray" }} />
                    <span className="status-text item-cancelled">Cancelled</span>
                </>
            );
        }
    };

    const determineVoucherGeneratedStatus = (
        winnerBid: IBid
    ): "Cancelled" | "Not Applicable" | "Generated" | "Generated (Manual)" | "Not Generated" => {
        if (determineLotPaidStatus(winnerBid) === "CANCELLED") {
            return "Cancelled";
        } else if (!winnerBid.catalogue) {
            return "Not Applicable";
        } else if (winnerBid.catalogue.voucherId) {
            if (winnerBid.catalogue.voucherId.split("_")[0].toLocaleUpperCase() === "MANUAL") {
                return "Generated (Manual)"
            } else {
                return "Generated";
            }
        } else {
            return "Not Generated";
        }
    };

    const determineVoucherGeneratedStatusIcon = (winnerBid: IBid): any => {
        if (determineLotPaidStatus(winnerBid) === "CANCELLED") {
            return (
                <>
                    <DoDisturbOnIcon className="cancel-icon" style={{ color: "gray" }} />
                    <span className="status-text item-cancelled">Cancelled</span>
                </>
            );
        } else if (
            !winnerBid.catalogue ||
            (winnerBid.catalogue &&
                winnerBid.catalogue.integrationId === null)
        ) {
            return (
                <>
                    <DoDisturbOnIcon className="cancel-icon" style={{ color: "gray" }} />
                    <span className="status-text item-cancelled">Not Applicable</span>
                </>
            );
        } else if (winnerBid.catalogue.voucherId) {
            return (
                <>
                    <CheckCircleIcon className="tick-icon" />
                    <span className="status-text all-paid">Generated {winnerBid.catalogue.voucherId.split("_")[0].toLocaleUpperCase() === "MANUAL" && "(Manual)"}</span>
                </>
            );
        } else {
            return (
                <>
                    <CancelIcon className="cross-icon" />
                    <span className="status-text none-paid">Not Generated</span>
                </>
            );
        }
    };

    const determineWinnerVoucherGeneratedStatusIcon = (
        winnerItems: IGetWinnerResponse[]
    ): any => {
        let voucherCount = 0;

        //remove cancelled from the list
        let filteredBidsAndLots = winnerItems
            .flatMap((x) => x.bidAndLotList)
            .filter((winnerItem) => !winnerItem.winningBid.isCancelled);

        //remove non voucher items from the list
        filteredBidsAndLots = filteredBidsAndLots.filter(
            (winnerItem) =>
                winnerItem.winningBid.bidList[0].catalogue &&
                winnerItem.winningBid.bidList[0].catalogue?.integrationId !== null
        );

        filteredBidsAndLots.forEach((bidAndLot) => {
            if (
                bidAndLot.winningBid &&
                bidAndLot.winningBid.bidList[0].catalogue &&
                bidAndLot.winningBid.bidList[0].catalogue.voucherId
            )
                voucherCount++;
        });

        if (voucherCount === 0 && filteredBidsAndLots.length === 0) {
            return (
                <>
                    <DoDisturbOnIcon className="cancel-icon" style={{ color: "gray" }} />
                    <span className="status-text item-cancelled">Not Applicable</span>
                </>
            );
        } else if (voucherCount === filteredBidsAndLots.length) {
            return (
                <>
                    <CheckCircleIcon className="tick-icon" />
                    <span className="status-text all-paid">Resolved</span>
                </>
            );
        } else if (voucherCount === 0) {
            return (
                <>
                    <CancelIcon className="cross-icon" />
                    <span className="status-text none-paid">Incomplete</span>
                </>
            );
        } else {
            return (
                <>
                    <DoDisturbOnIcon className="mid-icon" />
                    <span className="status-text some-paid">Partially Resolved</span>
                </>
            );
        }
    };

    const determineWinnerVoucherGeneratedStatus = (
        winnerItems: IGetWinnerResponse[]
    ): "Resolved" | "Incomplete" | "Partially Resolved" | "Not Applicable" => {
        let voucherCount = 0;

        //remove cancelled from the list
        let filteredBidsAndLots = winnerItems
            .flatMap((x) => x.bidAndLotList)
            .filter((winnerItem) => !winnerItem.winningBid.isCancelled);

        //remove non paid & non voucher items from the list
        filteredBidsAndLots = filteredBidsAndLots.filter(
            (winnerItem) =>
                winnerItem.winningBid.bidList[0].stripePayment &&
                winnerItem.winningBid.bidList[0].stripePayment?.paymentRefId &&
                winnerItem.winningBid.bidList[0].catalogue &&
                winnerItem.winningBid.bidList[0].catalogue.integrationId !== null
        );

        filteredBidsAndLots.forEach((bidAndLot) => {
            if (
                bidAndLot.winningBid &&
                bidAndLot.winningBid.bidList[0].catalogue &&
                bidAndLot.winningBid.bidList[0].catalogue.voucherId
            )
                voucherCount++;
        });

        if (filteredBidsAndLots.length === 0) {
            return "Not Applicable";
        }
        else if (voucherCount === filteredBidsAndLots.length) {
            return "Resolved";
        } else if (voucherCount === 0) {
            return "Incomplete";
        } else {
            return "Partially Resolved";
        }
    };

    const determineWinnerVoucherCount = (
        winnerItems: IGetWinnerResponse[]
    ): { voucherCount: number; totalVouchers: number; canBeProcessedVoucherCount: number } => {
        let voucherCount = 0;
        let canBeProcessedVoucherCount = 0;

        //remove cancelled from the list
        let filteredBidsAndLots = winnerItems
            .flatMap((x) => x.bidAndLotList)
            .filter((winnerItem) => !winnerItem.winningBid.isCancelled);

        //remove non voucher items from the list
        filteredBidsAndLots = filteredBidsAndLots.filter(
            (winnerItem) =>
                winnerItem.winningBid.bidList[0].catalogue &&
                winnerItem.winningBid.bidList[0].catalogue.integrationId !== null
        );

        filteredBidsAndLots.forEach((bidAndLot) => {
            if (
                bidAndLot.winningBid &&
                bidAndLot.winningBid.bidList[0].catalogue &&
                bidAndLot.winningBid.bidList[0].catalogue.voucherId
            )
                voucherCount++;
        });

        filteredBidsAndLots.forEach((bidAndLot) => {
            if (
                bidAndLot.winningBid &&
                bidAndLot.winningBid.bidList[0].stripePayment &&
                bidAndLot.winningBid.bidList[0].stripePayment.paymentRefId
            )
                canBeProcessedVoucherCount++;
        });

        return {
            voucherCount: voucherCount,
            totalVouchers: filteredBidsAndLots.length,
            canBeProcessedVoucherCount: canBeProcessedVoucherCount
        }

    };

    const setFaviconAndThemeColor = (
        faviconUrl: string,
        color: string,
        title: string
    ) => {
        if (faviconUrl)
            (document.getElementById("favicon") as HTMLAnchorElement)!.href =
                faviconUrl;
        if (color) (document.getElementById("theme-color") as any)!.content = color;
        if (title) {
            document.title = title;
        }
    };

    const getAllowedCountries = (): ICountryData[] => {
        // const { getCode } = require('country-list');
        // const allowed = allowedCountryNames.map((a)=>{
        // 	return {
        // 		code: getCode(a),
        // 		name: a
        // 	}
        // });

        const allowedCountryNames = [
            "United Kingdom",
            "United States",
            "Hong Kong",
            "United Arab Emirates",
            "Portugal",
            "France",
            "Germany",
            "Spain",
            "Monaco",
            "Switzerland",
            "Pakistan",
            "India",
        ].sort();

        var findByCountryName = require("all-country-data/dist/findByCountryName");
        const allowed: ICountryData[] = allowedCountryNames.map((a) => {
            const countryData = findByCountryName(a);
            return {
                name: a,
                countryCode: countryData.country_code,
                currencyCode: countryData.currency_code,
                isd_code: countryData.isd_code[0],
                currency: countryData.currency,
            } as ICountryData;
        });

        return allowed;
    };

    const getAllCountriesData = (): ICountryData[] => {
        var all = require("all-country-data/dist/all");
        const allCountries: ICountryData[] = all().flatMap((a) => {
            let nonEmptyIsdCodes = a.isd_code.filter((x) => x !== "");
            let allCodesForThisCountry = nonEmptyIsdCodes.map((code) => {
                return {
                    name: a.country,
                    countryCode: a.country_code,
                    currencyCode: a.currency_code,
                    isd_code: code,
                } as ICountryData;
            });

            return allCodesForThisCountry;
        });

        return allCountries;
    };

    const findIsdCodeByCountryCode = (countryCode): string => {
        var findByCountryCode = require("all-country-data/dist/findByCountryCode");
        const countryData = findByCountryCode(countryCode);
        return countryData.isd_code && countryData.isd_code.length > 0
            ? countryData.isd_code[0]
            : "";
    };

    const getValidPhone = (phone: string): string => {
        try {
            if (phone) {
                let phoneUtil =
                    require("google-libphonenumber").PhoneNumberUtil.getInstance();
                const PNF = require("google-libphonenumber").PhoneNumberFormat;

                let number = phoneUtil.parseAndKeepRawInput(phone);
                return phoneUtil.format(number, PNF.INTERNATIONAL).replaceAll(" ", "");
            } else {
                return ''
            }

        } catch (err) {
            console.error(err);
            return phone;
        }
    };

    const canNumberBeParsed = (phone: string): boolean => {
        try {
            if (phone && phone.length > 0) {
                let phoneUtil =
                    require("google-libphonenumber").PhoneNumberUtil.getInstance();
                let number = phoneUtil.parseAndKeepRawInput(phone);
                return true
            } else {
                return false
            }
        } catch (err) {
            return false
        }

    }

    const getNumberWithoutCountryCode = (phone: string): string => {
        try {
            if (phone && phone.length > 0) {
                let phoneUtil =
                    require("google-libphonenumber").PhoneNumberUtil.getInstance();
                let number = phoneUtil.parseAndKeepRawInput(phone);
                let countryCode = number.getCountryCode();
                return phone.replace(`+${countryCode}`, "").trim();
            } else {
                return ''
            }
        } catch (err) {
            console.error(err);
            return phone;
        }
    };

    const getIsdCode = (phone: string): string => {
        try {
            let phoneUtil =
                require("google-libphonenumber").PhoneNumberUtil.getInstance();
            let number = phoneUtil.parseAndKeepRawInput(phone);
            let countryCode = number.getCountryCode();
            return countryCode ? `+${countryCode}` : findIsdCodeByCountryCode("GB");
        } catch (err) {
            console.error(err);
            return findIsdCodeByCountryCode("GB");
        }
    };

    const getParsedPhone = (phone: string): IPhoneValidation => {
        try {
            let phoneUtil =
                require("google-libphonenumber").PhoneNumberUtil.getInstance();
            const PNF = require("google-libphonenumber").PhoneNumberFormat;
            let number = phoneUtil.parseAndKeepRawInput(phone);
            let isInvalid = false;
            let code = phoneUtil.getRegionCodeForNumber(number);
            isInvalid =
                code === null || !phoneUtil.isValidNumberForRegion(number, code);

            return {
                phone: phoneUtil.format(number, PNF.INTERNATIONAL),
                error: isInvalid,
                errorMessage: isInvalid ? "Invalid phone number" : "",
            } as IPhoneValidation;
        } catch (err) {
            return {
                phone: phone,
                error: true,
                errorMessage: "Invalid phone number",
            } as IPhoneValidation;
        }
    };

    const getCurrencySymbol = (currencyCode): string | undefined => {
        return getSymbolFromCurrency(currencyCode);
    };

    const isNumberCountryCode = (phone: string): boolean => {
        var findByCountryISD = require("all-country-data/dist/findByCountryISD");
        const countryData = findByCountryISD(phone.trim());
        return countryData.country ? true : false;
    };

    const getUserIdFromAuth0Id = async (auth0Id: string): Promise<string> => {
        const user: IUser = await userApi.get(
            `${USER_API_PATHS.GET_USER}${getUserQueryString(undefined, auth0Id)}`
        );
        if (user) {
            return user._id;
        } else {
            return "";
        }
    };

    const enumToText = (str: string): string => {
        if (str) {
            return capitalize(str.replaceAll("_", " "));
        } else {
            return "";
        }
    };

    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    function titleCase(str) {
        if (str) {
            var newStr = str.toLowerCase().replace(/./, (x) => x.toUpperCase()).replace(/[^']\b\w/g, (y) => y.toUpperCase());
            return newStr;
        }
        else {
            return '';
        }
    }

    const isJsonString = (str: string) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const getClickableLink = (link: string) => {
        return link.startsWith("http://") || link.startsWith("https://")
            ? link
            : `https://${link}`;
    };

    const determineButtonColor = (type): any => {
        if (type === "SUCCESS") {
            return { backgroundColor: "#44CF6C" };
        } else if (type === "ERROR") {
            return { backgroundColor: "#FF6B6B" };
        } else if (type === "PARTIAL") {
            return { backgroundColor: "#F9AF52" };
        } else {
            return { backgroundColor: "#44CF6C" };
        }
    };

    const getDisplayedBid = (bid: IBidWithData | IBid, currency: string): ReactNode => {

        let displayedBid = ""
        if (bid.count > 1) {
            displayedBid = `${currencyFormatter(currency).format(bid.value * bid.count)} (${currencyFormatter(currency).format(bid.value)} x ${bid.count})`
        } else {
            displayedBid = `${currencyFormatter(currency).format(bid.value)}`
        }
        return (
            <span style={{ display: 'flex', alignItems: "flex-end", lineHeight: "1rem" }}>
                <span style={{ display: "flex", alignItems: "flex-end" }}>{displayedBid}</span>
                {bid.maxValue ? <span style={{ fontSize: "0.75rem", fontStyle: "italic", marginLeft: 4 }}>{`(Max: ${currencyFormatter(currency).format(bid.maxValue)})`}</span> : null}
                {bid.isAuto ?
                    <Tooltip title="Auto Bid">
                        <MiscellaneousServicesIcon style={{ marginLeft: 4, fontSize: "1rem"}} />
                    </Tooltip> : null
                }
            </span>
        )
    }

    const currencyFormatter = (currency: string): Intl.NumberFormat => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency ?? "GBP",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }

    return {
        deriveBidData,
        calculateServiceFee,
        calculateTotalWinningBidValue,
        isAuditLogErrors,
        determinePurchaseCount,
        determineWinnerPaidStatus,
        determineWinnerPaidStatusIcon,
        determineLotPaidStatus,
        determineLotPaidStatusIcon,
        setFaviconAndThemeColor,
        getAllowedCountries,
        getValidPhone,
        getNumberWithoutCountryCode,
        getParsedPhone,
        getCurrencySymbol,
        isNumberCountryCode,
        getAllCountriesData,
        findIsdCodeByCountryCode,
        getIsdCode,
        getUserIdFromAuth0Id,
        enumToText,
        capitalize,
        titleCase,
        isJsonString,
        determineVoucherGeneratedStatusIcon,
        determineWinnerVoucherGeneratedStatusIcon,
        determineVoucherGeneratedStatus,
        determineWinnerVoucherGeneratedStatus,
        determineAllWinnersPaidStatus,
        determineAllWinnersVoucherToProcessCount,
        getClickableLink,
        determineButtonColor,
        determineWinnerVoucherCount,
        canNumberBeParsed,
        getDisplayedBid,
        currencyFormatter
    };
};
export default useCommonFunctions;
