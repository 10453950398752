import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { API_PATHS, CAMPAIGN_API_PATHS } from '../../../common/ApiPaths'
import { OrganisationContext } from '../../../context/OrganisationContext'
import useFetch from '../../../hooks/useFetch'
import ICampaign from '../../../interfaces/campaign'
import { useHistory } from "react-router-dom"
import { UserContext } from '../../../context/UserContext'
import useCampaignValidation from '../campaignSettings/useCampaignValidation'
import IValidationObject from '../../../interfaces/validationObject'
import IFaq from '../../../interfaces/faq'
import ISocialLink from '../../../interfaces/socialLink'
import ICustomBidderDetail from '../../../interfaces/customBidderDetail'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from 'dayjs'
import '../dialog.scss'


interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    campaignToBeCopied: ICampaign
    setCampaignToBeCopied: (campaign: ICampaign) => void
    duplicateCampaign: (campaignToDuplicate: ICampaign) => void
}

const CreateCampaignDialog = (props: InputProps) => {

    const [campaign, setCampaign] = useState<ICampaign>({
        menuBarBackgroundColor: "#8CC084",
        menuBarFontColor: "#FFFFFF",
        headerBackgroundColor: "#8CC084",
        headerFontColor: "#FFFFFF",
        buttonBackgroundColor: "#EF3E36",
        buttonFontColor: "#FFFFFF",
        footerBackgroundColor: "#2E282A",
        footerFontColor: "#FFFFFF",
        notificationType: "BOTH",
        customBidConfirmationSuffix: "Thanks for your bid.",
        customPurchaseConfirmationSuffix: "Thanks for your purchase.",
        customWinnerConfirmationSuffix: "Many thanks for supporting our cause.",
        paymentMethod: "PRE-AUTH",
        cardFee: 2.5,
        title: "Online Silent Auction",
        subtitle: "Supporting a great cause",
        introText: "Welcome to our online auction. Please click on the 'View Lots' button to view some of the fantastic items on offer.",
        logoUrl: "https://res.cloudinary.com/going-gone/image/upload/v1673170093/_%20DO%20NOT%20DELETE/Elephant_Logo_White_600x400_uxupt5.png",
        hostingOrganisationLogoUrl: "https://res.cloudinary.com/going-gone/image/upload/v1673170449/_%20DO%20NOT%20DELETE/Elephant_Logo_White_120_40px_t4jhuw.png",
        faviconUrl: "https://res.cloudinary.com/going-gone/image/upload/v1673178048/_%20DO%20NOT%20DELETE/Elephant_Logo_Red_40_40px_b3velg.png",
        faqList: [] as IFaq[],
        socialLinkList: [] as ISocialLink[],
        customBidDetailList: [] as ICustomBidderDetail[],
        campaignDate: new Date(),
        smsSender: 'AUCTION',
        brochureToolPurchaseInfo: {
            brochureConfig:{
                backgroundColor: "#26c0b9",
                backPageImageUrl: "",
                fontColor: "#ffffff",
                footerText: "",
                frontPageImageUrl: "",
                headerText: ""
            }
        }
    } as ICampaign)

    const organisation = useContext(OrganisationContext)
    const { dBUser } = useContext(UserContext)
    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
    let history = useHistory()
    const campaignValidation = useCampaignValidation(campaign, [], true)

    useEffect(() => {
        if (props.campaignToBeCopied && props.campaignToBeCopied.slug) {
            setCampaign({ ...props.campaignToBeCopied, slug: '', name: '', campaignDate: new Date() })
        }
    }, [props.campaignToBeCopied])

    const determineOwnerList = async (): Promise<string[]> => {
        // TODO check these with userIds instead of auth0Ids
        //always include bill, kam as user
        let ownerList: string[] = ['62ea2fabd5b8d5112906aeae', '63033a4d8d4df21e102ccfbd']// ['google-oauth2|107201638953183223161', 'auth0|62b07b21b690199c1c8a3b6a', 'auth0|62d6be64551c9652d7bd370c']
        if (dBUser && !ownerList.find(element => element === dBUser._id)) {
            ownerList.push(dBUser._id)
        }
        return ownerList
    }

    const createCampaign = async (): Promise<void> => {
        if (campaignValidation.validateInputs()) {
            try {
                const ownerList: string[] = await determineOwnerList()
                const data: ICampaign = await campaignsApi.post(`${CAMPAIGN_API_PATHS.CREATE_CAMPAIGN}`, { ...campaign, ownerList: ownerList, organisationId: organisation._id }, dBUser._id)
                history.push(`/organisations/${organisation.slug}/campaigns/${data.slug}`)
            }
            catch (err: any) {
                console.log(err)
            }
        }
    }

    const formatUrlSuffix = (raw: string): string => {
        return raw
            .replaceAll(" ", "-")
            .toLowerCase()
            .replaceAll(/[^\w\s-]/g, "")
            .replaceAll(/-+/g, "-")
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        campaignValidation.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        })
        return returnValue
    }

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
        >

            <DialogContent>
                <div className='coloured-section' >
                    <h2 className='title'>Campaign Details</h2>
                </div>
                <div className='content-wrapper'>
                    <div className="field-wrapper">
                        <TextField
                            id="name"
                            name="name"
                            label="Campaign Name"
                            variant="outlined"
                            defaultValue={campaign.name || ""}
                            onBlur={event => {
                                setCampaign({ ...campaign, name: event.target.value, slug: formatUrlSuffix(event.target.value) })
                            }}
                            helperText={
                                getValidation("name").isValid
                                    ? "The name of the campaign - this will be displayed at the top of invoices."
                                    : getValidation("name").validationMessage
                            }
                            required
                            fullWidth
                            error={!getValidation("name").isValid}
                        />
                    </div>
                    <div className="field-wrapper">
                        <TextField
                            id="slug"
                            name="slug"
                            label="URL-Suffix"
                            variant="outlined"
                            value={campaign.slug || ""}
                            onChange={event => setCampaign({ ...campaign, slug: formatUrlSuffix(event.target.value) })}
                            // onBlur={(event) => handleChange(event.target.name, event.target.value.toLowerCase())}
                            helperText={
                                getValidation("slug").isValid
                                    ? "The suffix that identifies the auction. Eg www.auction.com/<url-suffix>"
                                    : getValidation("slug").validationMessage
                            }
                            required
                            fullWidth
                            error={!getValidation("slug").isValid}
                        />
                    </div>
                    <div className="field-wrapper">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Campaign Date"
                                value={dayjs(campaign.campaignDate)}
                                onChange={date => setCampaign({ ...campaign, campaignDate: date?.toDate() || undefined })}
                                // showTodayButton
                                format="DD/MM/YYYY"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        required: true,
                                        error: !getValidation("campaignDate").isValid,
                                        helperText: getValidation("campaignDate").isValid
                                            ? "The date of the campaign"
                                            : getValidation("campaignDate").validationMessage

                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                <div className="action-section">

                    {!campaignValidation.isValidationPassed && <p className="validation-text">Something's not right, please check highlighted fields above.</p>}


                    <div className="save-button-wrapper">
                        <div className='buttons'>
                        <Button onClick={props.handleClose} id="cancel-button">
                            Cancel
                        </Button>

                        {props.campaignToBeCopied.slug ? (
                            <Button
                            id="save-button"
                                onClick={() => {
                                    if (campaignValidation.validateInputs()) {
                                        props.duplicateCampaign(campaign)
                                        props.handleClose()
                                    }
                                }}
                                className="gg-button"
                            >
                                <span>DUPLICATE</span>
                            </Button>
                        ) : (
                            <Button
                            id="save-button"
                                onClick={() => createCampaign()}
                                className="gg-button"
                            >
                                <span>CREATE</span>
                            </Button>
                        )}
                        </div>



                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default CreateCampaignDialog