import { useState } from "react"
import IOrganisation from "../../../interfaces/organisation"
import IValidationObject from "../../../interfaces/validationObject"

const useOrganisationValidation = (organisation: IOrganisation) => {
	const [validationObject, setValidationObject] = useState<IValidationObject[]>([
		{ name: "organisationName", isValid: true, validationMessage: "" },
		{ name: "stripeConnectedAccountId", isValid: true, validationMessage: "" },
		{ name: "contactEmail", isValid: true, validationMessage: "" },
		{ name: "contactPhone", isValid: true, validationMessage: "" },
	])
	const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

	const clearValidation = () => {
		setValidationObject([
			{ name: "organisationName", isValid: true, validationMessage: "" },
			{ name: "stripeConnectedAccountId", isValid: true, validationMessage: "" },
			{ name: "contactEmail", isValid: true, validationMessage: "" },
			{ name: "contactPhone", isValid: true, validationMessage: "" },
		])
	}

	const validateInputs = (): boolean => {
		let tempValidationObject: IValidationObject[] = [
			{ name: "organisationName", isValid: true, validationMessage: "" },
			{ name: "stripeConnectedAccountId", isValid: true, validationMessage: "" },
			{ name: "contactEmail", isValid: true, validationMessage: "" },
			{ name: "contactPhone", isValid: true, validationMessage: "" },
		]

		let tempIsValidationPassed = true

		const setFailedValidation = (name, message) => {
			tempIsValidationPassed = false
			tempValidationObject.forEach(element => {
				if (element.name === name) {
					element.isValid = false
					element.validationMessage = message
				}
			})
		}

		if (organisation.name === "" || typeof organisation.name === "undefined") {
			setFailedValidation("organisationName", "Please enter the name of the organisation.")
		}
		if (organisation.stripeConnectedAccountId === "" || typeof organisation.stripeConnectedAccountId === "undefined") {
			setFailedValidation("stripeConnectedAccountId", "Please enter the Stripe connected account id of the organisation.")
		}
		if (organisation.contactEmail === "" || typeof organisation.contactEmail === "undefined") {
			setFailedValidation("contactEmail", "Please enter the contact email address of the organisation.")
		}
		if (organisation.contactPhone === "" || typeof organisation.contactPhone === "undefined") {
			setFailedValidation("contactPhone", "Please enter the contact phone number of the organisation.")
		}
		if (organisation.contactPhone === "" || typeof organisation.contactPhone === "undefined") {
            setFailedValidation("contactPhone", "Please enter the contact phone number of the organisation.")
        } else {

            let phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

            try {
                let number = phoneUtil.parseAndKeepRawInput(organisation.contactPhone);
                let code = phoneUtil.getRegionCodeForNumber(number);
                if (!phoneUtil.isValidNumberForRegion(number,code)) {
                    setFailedValidation("contactPhone", "Invalid phone number")
                }
            }
            catch (err) {
                setFailedValidation("contactPhone", "Invalid phone number")
            }
        }

		setValidationObject(tempValidationObject)
		setIsValidationPassed(tempIsValidationPassed)
		return tempIsValidationPassed
	}

	const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	return {
		validationObject,
		clearValidation,
		setValidationObject,
		isValidationPassed,
		setIsValidationPassed,
		validateInputs,
		getValidation,
	}
}

export default useOrganisationValidation
