import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import ICampaign from "../interfaces/campaign";
import { useParams } from "react-router-dom";
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignQueryString } from "../common/ApiPaths";
import { useErrorBoundary } from 'react-error-boundary'

const CampaignContext = createContext({} as ICampaign);
const CampaignDispatchContext = createContext({} as Dispatch<SetStateAction<ICampaign>>);
const CampaignLoadingContext = createContext(false);


const CampaignProvider = ({ children }) => {
    const [campaign, setCampaign] = useState<ICampaign>({
        menuBarBackgroundColor: "#8CC084",
        menuBarFontColor: "#FFFFFF",
        headerBackgroundColor: "#8CC084",
        headerFontColor: "#FFFFFF",
        buttonBackgroundColor: "#EF3E36",
        buttonFontColor: "#FFFFFF",
        footerBackgroundColor: "#2E282A",
        footerFontColor: "#FFFFFF",
        notificationType: "BOTH",
        customBidConfirmationSuffix: "Thanks for your bid.",
        customPurchaseConfirmationSuffix: "Thanks for your purchase.",
        customWinnerConfirmationSuffix: "Many thanks for supporting our cause.",
        paymentMethod: "PRE-AUTH",
        cardFee: 2.5,
        title: "Online Silent Auction",
        subtitle: "Supporting a great cause",
        introText: "Welcome to our online auction. Please click on the 'View Lots' button to view some of the fantastic items on offer.",
        logoUrl: "https://res.cloudinary.com/going-gone/image/upload/v1673170093/_%20DO%20NOT%20DELETE/Elephant_Logo_White_600x400_uxupt5.png",
        hostingOrganisationLogoUrl: "https://res.cloudinary.com/going-gone/image/upload/v1673170449/_%20DO%20NOT%20DELETE/Elephant_Logo_White_120_40px_t4jhuw.png",
        faviconUrl: "https://res.cloudinary.com/going-gone/image/upload/v1673178048/_%20DO%20NOT%20DELETE/Elephant_Logo_Red_40_40px_b3velg.png"
    } as ICampaign)

    let { campaignSlug } = useParams()
    const { showBoundary } = useErrorBoundary();
    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
    const [loading, setLoading] = useState<boolean>(true)

    const compressImages = (data: ICampaign) => {
        try {
            data.bannerUrl = data.bannerUrl ? data.bannerUrl.replace('/upload/', '/upload/f_auto,q_auto,fl_lossy/') : ''
            data.faviconUrl = data.faviconUrl ? data.faviconUrl.replace('/upload/', '/upload/f_auto,q_auto,fl_lossy/') : ''
            data.logoUrl = data.logoUrl ? data.logoUrl.replace('/upload/', '/upload/f_auto,q_auto,fl_lossy/') : ''
            return data
        }
        catch (err) {
            return data
        }
    }


    const getCampaign = async () => {
        try {
            setLoading(true)
            if (campaignSlug !== 'new') {
                let data: ICampaign = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(campaignSlug)}`)
                data = compressImages(data)
                setCampaign(data)
            }
            setLoading(false)
        }
        catch (err: any) {
            setLoading(false)
            showBoundary(err)
            console.error(err)
        }
    }

    useEffect(() => {
        getCampaign()
    }, [])


    return (
        <CampaignContext.Provider value={campaign}>
            <CampaignDispatchContext.Provider value={setCampaign}>
                <CampaignLoadingContext.Provider value={loading}>
                        {children}
                </CampaignLoadingContext.Provider>
            </CampaignDispatchContext.Provider>
        </CampaignContext.Provider>
    );
}

export { CampaignProvider, CampaignContext, CampaignDispatchContext, CampaignLoadingContext };