import './lotList.scss'
import React, { useContext, useEffect, useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Table, TableBody, TableCell, TableHead, TableRow, Card, Button, CardContent, Tooltip, Checkbox, FormControl, InputLabel, Select, MenuItem, TableContainer } from "@mui/material"
import moment from 'moment'
import CreateIcon from '@mui/icons-material/Create'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link } from 'react-router-dom'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import BulkSettingsDialog from './dialogs/BulkSettingsDialog'
import LotNumberDialog from './dialogs/LotNumberDialog'
import { useHistory } from "react-router-dom";
import ILot from '../../../interfaces/lot'
import IUpdatedSettings from '../../../interfaces/updatedSettings'
import ICampaign from '../../../interfaces/campaign'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CopyLotsDialog from './dialogs/CopyLotsDialog'
import { CampaignContext, CampaignLoadingContext } from '../../../context/CampaignContext'
import { API_PATHS, getFilteredLotsQueryString, LOT_API_PATHS } from '../../../common/ApiPaths'
import { LotNumberUpdateOperation } from '../../../common/enums/LotNumberUpdateOperation'
import IOrganisation from '../../../interfaces/organisation'
import { OrganisationContext } from '../../../context/OrganisationContext'
import { UserContext } from '../../../context/UserContext'
import IAuditLogEntryInfo from '../../../interfaces/auditLogEntryInfo'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import CreateLotDialog from './CreateLotDialog'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useErrorBoundary } from 'react-error-boundary'
import PdfBrochureButton from './PdfBrochureButton'
import { ICustomPage } from '../../../interfaces/customPage'
import { get } from 'http'
import { CUSTOM_PAGES_API_PATH, CUSTOM_PAGES_PATHS } from '../../../common/CustomPageApiPaths'

const LotList = ({ match, location, ...props }) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    const [lotList, setLotList] = useState<ILot[]>([])
    const [childLotList, setChildLotList] = useState<ILot[]>([])
    const [selected, setSelected] = useState<ILot[]>([])
    const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "", label: "", errorDetails: "" })
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false)
    const [isBulkSettingsDialogOpen, setIsBulkSettingsDialogOpen] = useState<boolean>(false)
    const [isLotNumberDialogOpen, setIsLotNumberDialogOpen] = useState<boolean>(false)
    const [isCopyLotsDialogOpen, setIsCopyLotsDialogOpen] = useState<boolean>(false)
    const [selectedBulkAction] = useState<string>("")
    const [lotToBeDeleted, setLotToBeDeleted] = useState<ILot>({} as ILot)
    const [isCreateLotDialogOpen, setIsCreateLotDialogOpen] = useState<boolean>(false)
    const [customPageList, setCustomPageList] = useState<ICustomPage[]>([])


    const campaign: ICampaign = useContext(CampaignContext)
    const campaignLoading = useContext(CampaignLoadingContext)
    const lotApi = useFetch(API_PATHS.LOTS)
    const organisation: IOrganisation = useContext(OrganisationContext)
    const { dBUser } = useContext(UserContext)
    const commonFunctions = useCommonFunctions()
    const { showBoundary } = useErrorBoundary();
    const customPageApi = useFetch(CUSTOM_PAGES_API_PATH);

    let history = useHistory()


    const getLots = async (): Promise<void> => {
        setLoading(true)
        try {
            let lots: ILot[] = await lotApi.get(`${LOT_API_PATHS.GET_LOTS.replace('{campaignId}', campaign._id)}${getFilteredLotsQueryString(undefined, true)}`)
            if (lotList.length === 0) {
                props.setPadding()
            }
            setLotList(lots.filter(x => !x.parentLotId))
            setChildLotList(lots.filter(x => x.parentLotId))
            setLoading(false)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const checkForChildLots = async (updatedLotList: ILot[]): Promise<void> => {
        let lotsToBeUpdated: ILot[] = []
        for (let lot of updatedLotList) {
            if (lot.type === "PARENT") {
                let childLots = childLotList.filter(x => x.parentLotId === lot._id)
                if (childLots.length) {
                    const splitted = childLots[0].orderNumber.toString().split(".")
                    if (splitted.length === 2) {
                        if (parseInt(splitted[0]) !== lot.orderNumber) {
                            childLots = childLots.map(c => {
                                return {
                                    ...c,
                                    orderNumber: (Math.round((lot.orderNumber + c.orderNumber % 1) * 10) / 10)
                                }
                            })
                            lotsToBeUpdated.push(...childLots)
                        }
                    }
                }

            }
        }
        if (updatedLotList.length) {
            await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT}`, lotsToBeUpdated, dBUser._id)
            //Updating lot numbers in memory rather than GetLots() so no page refresh
            let updatedChildLotList = childLotList.map((obj: ILot) => {
                let foundObj = lotsToBeUpdated.find(x => x._id === obj._id)
                if (foundObj) {
                    return foundObj
                }
                else {
                    return obj
                }
            })

            setChildLotList(updatedChildLotList.sort((a, b) => a.orderNumber - b.orderNumber))
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#DEEBFE" : "white",
        display: isDragging ? "table" : "",
        ...draggableStyle
    });

    const duplicateLot = async (lot: ILot): Promise<void> => {
        let lotToBeCopied: ILot = { ...lot, _id: null };

        try {
            const createdLots = await lotApi.post(LOT_API_PATHS.CREATE_LOT, [lotToBeCopied], dBUser._id)
            let childLots: ILot[] = []

            if (lotToBeCopied.type === "PARENT") {
                childLots = childLotList
                    .filter(x => x.parentLotId === lot._id)
                    .map((childLot) => { return { ...childLot, _id: null, parentLotId: createdLots[0]._id } })
                await lotApi.post(LOT_API_PATHS.CREATE_LOT, childLots, dBUser._id)
            }


            getLots()
        }
        catch (err: any) {
            showBoundary(err)
        }
    }

    const deleteLot = async (lot: ILot): Promise<void> => {
        try {
            await lotApi.del(`${LOT_API_PATHS.DELETE_LOT}`, {
                lotIdAndTitleList: [{ id: lot._id, title: lot.title }],
                campaignId: campaign._id
            }, dBUser._id)
            setDialogProperties({ type: "SUCCESS", title: "Lot Deleted", message: "Lot deleted successfully.", label: "LOT DELETED" })
            setIsConfirmationDialogOpen(true)
            getLots()
            setSelected([])
            setLotToBeDeleted({} as ILot)
        }
        catch (err: any) {
            showBoundary(err)
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = lotList.map((lot) => lot);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleBulkActionsChange = (event) => {
        if (event.target.value === "BULKDELETE") {
            openBulkDeleteConfirmationDialog()
        } else if (event.target.value === "SETTINGS") {
            setIsBulkSettingsDialogOpen(true)
        } else if (event.target.value === "LOTNUMBERS") {
            setIsLotNumberDialogOpen(true)
        } else if (event.target.value === "COPYLOTS") {
            setIsCopyLotsDialogOpen(true)
        } else {
            console.log(`unknown value: ${event.target.value}`)
        }
    };

    const openDeleteConfirmationDialog = (lot: ILot) => {
        sessionStorage.setItem("scrollPosition", window.pageYOffset.toString())
        setDialogProperties({ type: "AREYOUSURE", title: "Confirm Delete", message: "Are you sure you want to delete this lot?", label: "DELETE" })
        setIsConfirmationDialogOpen(true)
        setLotToBeDeleted(lot)
    }
    const openBulkDeleteConfirmationDialog = () => {
        setDialogProperties({ type: "AREYOUSURE", title: "Confirm Bulk Delete", message: "Are you sure you want to delete the selected lots?", label: "BULKDELETE" })
        setIsConfirmationDialogOpen(true)
    }

    const bulkDelete = async () => {
        let lotIdAndTitleList: any[] = []
        selected.forEach(lot => {
            lotIdAndTitleList.push({ id: lot._id, title: lot.title })
        })
        try {
            await lotApi.del(`${LOT_API_PATHS.DELETE_LOT}`, {
                lotIdAndTitleList: lotIdAndTitleList,
                // lotIds: selected.map(x => x._id),
                campaignId: campaign._id
            }, dBUser._id)
            setDialogProperties({ type: "SUCCESS", title: "Lots Deleted", message: "Lots deleted successfully.", label: "SUCCESS" })
            setIsConfirmationDialogOpen(true)
            setSelected([])
            getLots()
        }
        catch (err: any) {
            showBoundary(err)
        }
    };

    const handleCloseDialog = (isConfirmed: boolean) => {
        if (dialogProperties.label === "BULKDELETE") {
            if (isConfirmed) {
                bulkDelete()
            }
        } else if (dialogProperties.label === "DELETE") {
            if (isConfirmed) {
                deleteLot(lotToBeDeleted)
            } else {
                setLotToBeDeleted({} as ILot)
            }
        } else if (dialogProperties.label === "SUCCESS") {
            history.push(`/organisations/${organisation.slug}/campaigns/${match.params.campaignSlug}/lots`)
        }
        setDialogProperties({})
        setIsConfirmationDialogOpen(false)
    }

    const handleCloseBulkSettingsDialog = async (isChanges: boolean, updatedSettings: IUpdatedSettings) => {

        console.log("updatedSettings", updatedSettings)

        if (isChanges) {
            //ensure we are working with the latest version of the lot list.
            let tempLotList: ILot[] = await lotApi.get(`${LOT_API_PATHS.GET_LOTS.replace('{campaignId}', campaign._id)}${getFilteredLotsQueryString(undefined, true)}`)

            const selectedIds = selected.map(x => x._id)
            let lotsToBeUpdated: any[] = tempLotList.filter(x => selectedIds.includes(x._id))

            const determineCustomPageId = (existingCustomPageId: string): string | null => {
                if (updatedSettings.customPageId) {
                    return updatedSettings.customPageId
                } else if (typeof (updatedSettings.customPageId) === "undefined") {
                    return null
                } else {
                    return existingCustomPageId
                }
            }

            let mapObject: any = []

            lotsToBeUpdated.forEach(x => {
                let mapObjectEntry: any = {}
                mapObjectEntry._id = x._id
                mapObjectEntry.campaignId = campaign._id
                mapObjectEntry.startDateTime = updatedSettings.startDate ?? x.startDateTime
                mapObjectEntry.endDateTime = updatedSettings.endDate ?? x.endDateTime
                mapObjectEntry.availabilityCount = updatedSettings.availabilityCount ?? x.availabilityCount

                if (updatedSettings.customPageId === "lot-page") {
                    mapObjectEntry.customPageId = null
                } else if (updatedSettings.customPageId) {
                    mapObjectEntry.customPageId = updatedSettings.customPageId
                } else if (typeof (updatedSettings.customPageId) === "undefined") {
                    //dont indlude custom page id
                } else {
                    mapObjectEntry.customPageId = x.customPageId
                }
                mapObject.push(mapObjectEntry)

            });

            lotsToBeUpdated = mapObject

            const lotsToBeUpdatedIds = lotsToBeUpdated.map(x => x._id)

            let childLots: any[] = []

            tempLotList.filter(x => lotsToBeUpdatedIds.includes(x.parentLotId)).forEach(x => {
                let childLotEntry: any = {}
                childLotEntry._id = x._id
                childLotEntry.campaignId = campaign._id
                childLotEntry.startDateTime = updatedSettings.startDate ?? x.startDateTime
                childLotEntry.endDateTime = updatedSettings.endDate ?? x.endDateTime
                childLotEntry.availabilityCount = updatedSettings.availabilityCount ?? x.availabilityCount

                if (updatedSettings.customPageId === "lot-page") {
                    childLotEntry.customPageId = null
                } else if (updatedSettings.customPageId) {
                    childLotEntry.customPageId = updatedSettings.customPageId
                } else if (typeof (updatedSettings.customPageId) === "undefined") {
                    //dont indlude custom page id
                } else {
                    childLotEntry.customPageId = x.customPageId
                }
                childLots.push(childLotEntry)
            })

            // const childLots: any[] = tempLotList.filter(x => lotsToBeUpdatedIds.includes(x.parentLotId)).map((x) => {
            //     return { _id: x._id, startDateTime: updatedSettings.startDate ?? x.startDateTime, endDateTime: updatedSettings.endDate ?? x.endDateTime, availabilityCount: updatedSettings.availabilityCount ?? x.availabilityCount, isHidden: updatedSettings.isHidden ?? x.isHidden, customPageId: determineCustomPageId(x.customPageId as any), campaignId: campaign._id }
            // })
            lotsToBeUpdated.push(...childLots)

            console.log(lotsToBeUpdated)

            try {
                let result = await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT}?setSpecificValues=true`, lotsToBeUpdated, dBUser._id)
                if ((result.matchedCount && result.matchedCount < lotsToBeUpdated.length) || (result.nMatched && result.nMatched < lotsToBeUpdated.length)) {
                    setDialogProperties({ type: "ERROR", title: "Error", message: "Some buy it now lots cannot be updated because the number of bids is greater than the updated availability count.", label: "FAILURE" });
                    setIsConfirmationDialogOpen(true)
                }
                getLots()
            }
            catch (err: any) {
                showBoundary(err)
            }
        }
        setSelected([])
        setIsBulkSettingsDialogOpen(false)
    }

    const handleCloseLotNumberDialog = async (isChanges: boolean, sequenceStartNumber: number) => {
        if (isChanges) {
            try {
                await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT_ORDER_SEQUENTIALLY}`,
                    {
                        lotIds: selected.map(x => x._id),
                        sequenceStartNumber: (+sequenceStartNumber),
                        auditLogEntryInfo: {
                            currentUserId: dBUser._id,
                            campaignId: campaign._id,
                            organisationId: organisation._id,
                        } as IAuditLogEntryInfo
                    })
                getLots()
            }
            catch (err: any) {
                showBoundary(err)
            }
        }
        setIsLotNumberDialogOpen(false)
        setSelected([])
    }

    const handleCopyLotsToCampaign = (isChanges: boolean, campaignId: string) => {
        if (isChanges) {
            var ObjectID = require("bson-objectid");
            let lotsToCopy = selected.flatMap((lot) => {
                const uniqueLotId = new ObjectID().toString();

                let allLots = [] as ILot[];
                               
                const selectedLotChildLotList: ILot[] = childLotList.filter(x => x.parentLotId === lot._id)

                if (selectedLotChildLotList.length > 0) {
                    allLots.push({
                        ...lot,
                        _id: uniqueLotId,
                        campaignId: campaignId,
                    });
    
                    allLots = allLots.concat(selectedLotChildLotList.map((childLot) => {
                        return {
                            ...childLot,
                            _id: null,
                            parentLotId: uniqueLotId,
                            campaignId: campaignId,
                            nextValidBidValue: 0,
                            winningBidder: '',
                            winningBid: 0,
                            cumulativeTotal: 0
                        }
                    }));

                }else{
                    allLots.push({
                        ...lot,
                        campaignId: campaignId,
                        _id: null
                    });
                }

                return allLots;
            });

            try {
                lotApi.post(`${LOT_API_PATHS.COPY_LOTS_TO_CAMPAIGN.replace('{campaignId}', campaignId)}`, lotsToCopy)
                setDialogProperties({ type: "SUCCESS", title: "Lots Copied", message: "Selected lots copied to the Campaign successfully.", label: "SUCCESS" });
                setIsConfirmationDialogOpen(true);
                setSelected([]);
            }
            catch (err: any) {
                setDialogProperties({ type: "ERROR", title: "Error", message: "Lots copying to Campaign failed.", label: "FAILURE" });
                setIsConfirmationDialogOpen(true);
            }
        }
        setIsCopyLotsDialogOpen(false);
        setSelected([]);
    }

    const isSelected = (name: any) => selected.indexOf(name) !== -1;

    const lotOnDragEnd = async ({ destination, source }) => {
        if (!destination) {
            return;
        }
        if (destination.index === source.index) {
            return;
        }

        const draggedLot = lotList[source.index];
        const newLots = [...lotList];
        newLots.splice(source.index, 1);
        newLots.splice(destination.index, 0, draggedLot);

        const lotsToBeUpdated = destination.index < source.index ? newLots.slice(destination.index, newLots.length) : newLots.slice(source.index, destination.index + 1);
        const sequenceStart = destination.index < source.index ? lotList[destination.index].orderNumber : lotList[source.index].orderNumber;
        // lots updated on view only
        let currentOrderNumber = sequenceStart;
        lotsToBeUpdated.forEach((lot) => {
            newLots[newLots.findIndex(x => x._id === lot._id)].orderNumber = currentOrderNumber;
            lot.orderNumber = currentOrderNumber;
            currentOrderNumber++;
        });
        setLotList(newLots);

        try {
            await lotApi.put(`${LOT_API_PATHS.UPDATE_LOT_ORDER_SEQUENTIALLY}`,
                {
                    lotIds: lotsToBeUpdated.map(x => x._id),
                    sequenceStartNumber: (+sequenceStart),
                    auditLogEntryInfo: {
                        currentUserId: dBUser._id,
                        campaignId: campaign._id,
                        organisationId: organisation._id,
                    } as IAuditLogEntryInfo
                })
            await checkForChildLots(newLots)

        }
        catch (err: any) {
            setDialogProperties({
                type: "ERROR",
                title: "Error",
                message: `Error in updating lot order`,
                label: "FAILURE"
            })
            setIsConfirmationDialogOpen(true);
            getLots();
        }
    }

    const getSource = (integrationId: string) => {
        let source = organisation && organisation.integrationList ? organisation.integrationList.find(i => i.id === integrationId) : undefined
        return source?.name || '-'
    }


    const handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition")
        if (scrollPosition && parseInt(scrollPosition)) {
            window.scrollTo(0, parseInt(scrollPosition))
            sessionStorage.removeItem("scrollPosition")
        }
    }

    const getCustomPages = async () => {
        try {
            const customPageList: ICustomPage[] = await customPageApi.get(CUSTOM_PAGES_PATHS.GET_CUSTOM_PAGES.replace("{campaignId}", campaign._id))
            setCustomPageList(customPageList)
        } catch (err: any) {
            console.log(err.message)
        }
    }

    const getCustomPageName = (lot: ILot): string => {

        let customPageName = "Lot List"

        customPageList.forEach(customPage => {
            if (customPage._id === lot.customPageId) {
                customPageName = customPage.label
            }
        });

        return customPageName
    }

    useEffect(() => {
        if (!campaignLoading && !(campaign && campaign.slug)) {
            showBoundary('Error while fetching campaign')
            setLoading(false)
        }
        else if (campaign.slug) {
            setError('')
            setLoading(false)
            getLots()
            getCustomPages()
        }
    }, [campaign, campaignLoading])

    useEffect(() => {
        if (lotList.length > 0) {
            setTimeout(() => handleScrollPosition())
        }
    }, [lotList])

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get("isLotCreated")) {
            setDialogProperties({ type: "SUCCESS", title: "Lot Created.", message: "Your lot has successfully been created.", label: "SUCCESS" })
            setIsConfirmationDialogOpen(true)
        } else if (query.get("isLotSaved")) {
            setDialogProperties({ type: "SUCCESS", title: "Lot Saved.", message: "Your lot has successfully been saved.", label: "SUCCESS" })
            setIsConfirmationDialogOpen(true)
        } else if (query.get("success")) {
            setDialogProperties({
                type: "SUCCESS", title: "PDF Brochure Generation Purchase Success", message: "Your PDF brochure generation purchase was successful",
            })
            setIsConfirmationDialogOpen(true)
        } else if (query.get("cancelled")) {
            setDialogProperties({
                type: "ERROR", title: "PDF Brochure Generation Purchase Incomplete", message: `Your PDF brochure generation tool purchase was incomplete. Please try again or contact an administrator for support.`,
            })
            setIsConfirmationDialogOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (loading) {
        return (
            <Loading />
        )
    }

    // if (error) {
    //     return (
    //         <p>{error}</p>
    //     )
    // }

    return (
        <div className="lot-list-page">
            <h2 className='subtitle'>Lots{lotList.length > 0 && `: ${lotList.length}`}</h2>
            <div className="description">Drag the lots to change the lot order.</div>
            {campaign.slug && lotList.length > 0 ?
                <>
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table
                                    style={{ tableLayout: 'auto' }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox" className="hide-on-mobile">
                                                <Checkbox
                                                    indeterminate={selected.length > 0 && selected.length < lotList.length}
                                                    checked={lotList.length > 0 && selected.length === lotList.length}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{ 'aria-label': 'select all lots' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-header-cell" style={{ minWidth: 40 }}>
                                                    <span>Lot #</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-header-cell">
                                                    <span>Lot Name</span>
                                                </div>
                                            </TableCell>
                                            <TableCell className="hide-on-mobile">
                                                <div className="table-header-cell">
                                                    <span>Lot Type</span>
                                                </div>
                                            </TableCell>
                                            <TableCell className="hide-on-mobile">
                                                <div className="table-header-cell">
                                                    <span>Page</span>
                                                </div>
                                            </TableCell>
                                            <TableCell className="hide-on-mobile">
                                                <div className="table-header-cell">
                                                    <span># Available </span>
                                                </div>
                                            </TableCell>
                                            <TableCell className="hide-on-mobile">
                                                <div className="table-header-cell">
                                                    <span>End Date</span>
                                                </div>
                                            </TableCell>
                                            <TableCell className="hide-on-mobile">
                                                <div className="table-header-cell">
                                                    <span>Source</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-header-cell">
                                                    <span>Status</span>
                                                </div>
                                            </TableCell>
                                            <TableCell className="hide-on-mobile">
                                                <div className="table-header-cell">
                                                    <span>Visibility</span>
                                                </div>
                                            </TableCell>
                                            {!campaign.isClosed &&
                                                <TableCell>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <DragDropContext onDragEnd={lotOnDragEnd}>
                                        <Droppable droppableId="lot-dnd">
                                            {(provided) => (
                                                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                                    {lotList.map((lot: ILot, index) => (
                                                        <Draggable
                                                            key={lot.slug}
                                                            draggableId={`lot-${lot.slug}`}
                                                            index={index}
                                                            isDragDisabled={campaign.isClosed}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <TableRow
                                                                    key={lot.slug}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <TableCell padding="checkbox" className="hide-on-mobile">
                                                                        <Checkbox
                                                                            checked={isSelected(lot)}
                                                                            inputProps={{ 'aria-labelledby': lot.slug }}
                                                                            onClick={() => handleClick(lot)}
                                                                        />
                                                                    </TableCell>

                                                                    <TableCell>{lot.orderNumber}</TableCell>
                                                                    <TableCell>
                                                                        <Link
                                                                            to={`/organisations/${organisation.slug}/campaigns/${match.params.campaignSlug}/lots/${encodeURIComponent(lot.slug)}`}
                                                                            onClick={() => sessionStorage.setItem("scrollPosition", window.pageYOffset.toString())}
                                                                        >
                                                                            {lot.title}
                                                                        </Link>
                                                                    </TableCell>
                                                                    <TableCell className="hide-on-mobile">{lot.type}</TableCell>
                                                                    <TableCell className="hide-on-mobile">{getCustomPageName(lot)}</TableCell>
                                                                    <TableCell className="hide-on-mobile">{lot.type === "PARENT" ? '-' : lot.availabilityCount}</TableCell>
                                                                    <TableCell className="hide-on-mobile">{moment(lot.endDateTime).format("HH:mm DD/MM/YYYY")}</TableCell>
                                                                    <TableCell className="hide-on-mobile">{lot.catalogue ? lot.catalogue.integrationId === null ? 'Org Catalogue' : getSource(lot.catalogue.integrationId) : '-'}</TableCell>
                                                                    <TableCell>
                                                                        {(moment(lot.startDateTime).isBefore(moment()) && moment(lot.endDateTime).isAfter(moment())) ?
                                                                            <span className='status open'>Open</span>
                                                                            :
                                                                            <span className='status closed'>Closed</span>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className="hide-on-mobile">
                                                                        {lot.isHidden ?
                                                                            <VisibilityOffIcon />
                                                                            :
                                                                            <VisibilityIcon />
                                                                        }
                                                                    </TableCell>
                                                                    {!campaign.isClosed &&
                                                                        <TableCell>
                                                                            <div className="action-cell">
                                                                                <Link
                                                                                    className="hide-on-mobile"
                                                                                    to={`/organisations/${organisation.slug}/campaigns/${match.params.campaignSlug}/lots/${encodeURIComponent(lot.slug)}`}
                                                                                    onClick={() => sessionStorage.setItem("scrollPosition", window.pageYOffset.toString())}
                                                                                ><Tooltip title="Edit Lot"><CreateIcon /></Tooltip></Link>

                                                                                <div onClick={() => duplicateLot(lot)} className="hide-on-mobile">
                                                                                    <Tooltip title="Duplicate Lot" enterDelay={500}><FileCopyIcon /></Tooltip>
                                                                                </div>
                                                                                <div onClick={() => openDeleteConfirmationDialog(lot)}>
                                                                                    <Tooltip title="Delete Lot" enterDelay={500}><DeleteIcon /></Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                    }
                                                                </TableRow>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </TableBody>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card >
                </>
                :
                <Card><CardContent><div className="no-entries">No lots configured</div></CardContent></Card>
            }


            {campaign.isClosed ? (
                <div className="fixed-action-section">
                    <div style={{ display: "flex" }}>
                        <div style={{ width: 240 }} className="hide-on-mobile"></div>
                        <FormControl
                            className="bulk-actions hide-on-mobile"
                            disabled={selected.length < 1}
                        >
                            <InputLabel id="bulk-actions-label">Bulk Actions</InputLabel>
                            <Select
                                labelId="bulk-actions-label"
                                id="bulkActions"
                                name="bulkActions"
                                value={selectedBulkAction}
                                onChange={(event) => handleBulkActionsChange(event)}
                                label="Bulk Actions"
                            >
                                <MenuItem value={'COPYLOTS'}>Copy Lots to Another Campaign</MenuItem >
                            </Select>
                        </FormControl>
                    </div>
                    <div className="one-col">
                        <PdfBrochureButton
                            className='hide-on-mobile'
                            lotList={lotList}
                            isPurchased={(campaign.brochureToolPurchaseInfo && campaign.brochureToolPurchaseInfo.paymentRefId) ? true : false}
                        />
                        <span className='campaign-closed-notice'>Campaign closed - lots can no longer be edited.</span>
                    </div>
                </div>
            ) : (

                <div className="fixed-action-section">
                    {/* <div className="button-wrapper space-between"> */}
                    <div style={{ display: "flex" }}>
                        <div style={{ width: 240 }} className="hide-on-mobile"></div>
                        <FormControl
                            className="bulk-actions hide-on-mobile"
                            disabled={selected.length < 1}
                        >
                            <InputLabel id="bulk-actions-label">Bulk Actions</InputLabel>
                            <Select
                                labelId="bulk-actions-label"
                                id="bulkActions"
                                name="bulkActions"
                                value={selectedBulkAction}
                                onChange={(event) => handleBulkActionsChange(event)}
                                label="Bulk Actions"
                            >
                                <MenuItem value={'BULKDELETE'}>Delete Selected</MenuItem >
                                <MenuItem value={'SETTINGS'}>Update Settings</MenuItem >
                                <MenuItem value={'LOTNUMBERS'}>Update Lot Numbers</MenuItem >
                                <MenuItem value={'COPYLOTS'}>Copy Lots to Another Campaign</MenuItem >
                            </Select>
                        </FormControl>
                    </div>
                    <div className="right-hand-buttons">

                        <PdfBrochureButton
                            className="hide-on-mobile"
                            lotList={lotList}
                            isPurchased={(campaign.brochureToolPurchaseInfo && campaign.brochureToolPurchaseInfo.paymentRefId) ? true : false}
                        />

                        <Link to={`/organisations/${organisation.slug}?tabNumber=3&defaultCampaign=${campaign.slug}`} className="hide-on-mobile">
                            <Button className="gg-button" style={{ marginLeft: '5px', marginRight: '5px' }}>Add Lots from Catalogue</Button>
                        </Link>
                        <Button className="gg-button" onClick={() => setIsCreateLotDialogOpen(true)}>New Lot</Button>
                    </div>
                </div>
            )
            }

            <ConfirmationDialog
                handleClose={handleCloseDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                errorDetails={dialogProperties.errorDetails}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
                primaryButton={<Button onClick={() => handleCloseDialog(true)} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
                    Ok
                </Button>}
            />
            <BulkSettingsDialog
                handleClose={handleCloseBulkSettingsDialog}
                isDialogOpen={isBulkSettingsDialogOpen}
            />
            <LotNumberDialog
                handleClose={handleCloseLotNumberDialog}
                isDialogOpen={isLotNumberDialogOpen}
            />
            <CopyLotsDialog
                handleClose={handleCopyLotsToCampaign}
                isDialogOpen={isCopyLotsDialogOpen}
                currentCampaignId={campaign._id}
            />
            <CreateLotDialog
                isDialogOpen={isCreateLotDialogOpen}
                handleClose={() => setIsCreateLotDialogOpen(false)}
                campaignLotList={lotList}
                setDialogProperties={setDialogProperties}
                setIsConfirmationDialogOpen={setIsCreateLotDialogOpen}
            />

        </div >
    )
}

export default LotList