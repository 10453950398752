import React, { useEffect, useState } from "react"
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import './paymentProgressDialog.scss';
import CrossImage from "../../../images/cross.png";
import AlertImage from "../../../images/alert.png";
import ProgressBar from "../shared/ProgressBar"

interface PaymentProgressDialogProps {
    isDialogOpen: boolean,
    totalUsers: number,
    processedWinners: number,
    currentProcessingWinner: string,
    handleCancel: (cancelled?: boolean) => void,
    isPaymentInProgress: boolean,
    processingVouchers: boolean,
    error: string
    noOfErrors: number
}


const PaymentProgressDialog = (props: PaymentProgressDialogProps) => {

    const [progress, setProgress] = useState(0);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
    }

    const getProgress = () => {
        return ((props.processedWinners !== props.totalUsers ? props.processedWinners + 1 : props.processedWinners) / props.totalUsers) * 100;
    }

    const isProcessing = (): boolean => {
        return (props.isPaymentInProgress && getProgress() < 100);
    }

    useEffect(() => {
        setProgress(getProgress());
    }, [props.processedWinners])

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            className="payment-progress-dialog"
            disableEscapeKeyDown
        >
            <DialogTitle id="form-dialog-title">{`${props.processingVouchers ? 'Generating Vouchers' : 'Taking Payments'} for All Winners`}</DialogTitle>
            <DialogContent>
                <div style={{ margin: '20px 20px 0 20px' }}>
                    <ProgressBar progress={progress} />
                    <div className="small-font blue-text">
                        <span>0</span>
                        <span className="pull-right">{props.totalUsers}</span>
                    </div>
                </div>
                <div className="content-section">
                    {props.isPaymentInProgress && (
                        <span className="small-font"><CircularProgress size='0.7rem' />{`  Processing ${props.processingVouchers ? 'vouchers' : 'payments'} for ${(props.currentProcessingWinner.length > 20 ? `${props.currentProcessingWinner.substring(0, 20)}...` : props.currentProcessingWinner)} (${props.processedWinners + 1} of ${props.totalUsers})`} </span>
                    )}
                    {((!props.isPaymentInProgress) /*|| (getProgress() >= 100)*/) && (
                        <div className='error-text'> {props.noOfErrors > 0 ?
                            <img alt="error" width='50px' height='50px' src={AlertImage} />
                            :
                            <CheckCircleIcon />}
                            {`  Processed ${props.noOfErrors === 0 ? 'successfully' : 'with errors'}`}
                        </div>
                    )}
                    {props.error && props.error.length > 0 && props.isPaymentInProgress && (
                        <div className='margin-20'>
                            <div className="list-item">
                                <img alt="error" width='20px' height='20px' src={CrossImage} />
                                <span>{props.error}</span>
                            </div>

                        </div>
                    )}

                </div>
                <div className="button-section">
                    {props.isPaymentInProgress && (
                        <Button onClick={() => props.handleCancel(true)} >
                            Cancel
                        </Button>
                    )}
                    {(!props.isPaymentInProgress && getProgress() >= 100) && (
                        <Button onClick={() => props.handleCancel()} >
                            Done
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentProgressDialog