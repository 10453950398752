import React, { ChangeEvent, FocusEvent, useContext, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { CampaignContext } from "../../../../../context/CampaignContext";

import useCommonFunctions from "../../../../../hooks/useCommonFunctions";

import { isValidEmail } from "../../../../../utils/validators";

import ITestMessageModalState from "../../../../../interfaces/testMessageModal";

import SMSForm from "../../../../shared/communicationDialogs/forms/smsForm/SMSForm";
import EmailForm from "../../../../shared/communicationDialogs/forms/emailForm/EmailForm";
import ITestMessageResponseState from "../../../../../interfaces/testMessageResponse";
import IValidationObject from "../../../../../interfaces/validationObject";
import { Oval } from "react-loader-spinner";
import { removeSpecialCharacters } from "../../../../../utils/helpers";

interface ISMSState {
  phoneNumber: string;
  countryPhoneCode: string;
  firstName: string;
  surname: string;
}

interface IEmailState {
  to: string;
  firstName: string;
  surname: string;
}

interface IPhoneError {
  display: boolean;
  message: string;
}

interface IEmailError {
  display: boolean;
  message: string;
}

interface ITestMessageModalProps {
  smsValues: ISMSState;
  emailValues: IEmailState;
  setSMSValues: React.Dispatch<React.SetStateAction<ISMSState>>;
  setEmailValues: React.Dispatch<React.SetStateAction<IEmailState>>;
  getTestValidation: (args: string) => IValidationObject;
  testMessageResponse: ITestMessageResponseState;
  messageModalState: ITestMessageModalState;
  setTestMessageResponse: React.Dispatch<
    React.SetStateAction<ITestMessageResponseState>
  >;
  setMessageModalState: React.Dispatch<
    React.SetStateAction<ITestMessageModalState>
  >;
  selectedMessageService: string;
  dispatchTestMessageHandler: (
    formState: ISMSState | IEmailState,
    messageType: string
  ) => void;
  setIsTestServiceSelected: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRecipientsCategory: string;
  testCommunicationValidation: {
    validationFields: IValidationObject[];
    setValidationFields: React.Dispatch<
      React.SetStateAction<IValidationObject[]>
    >;
    isValidationPassed: boolean;
    setIsValidationPassed: React.Dispatch<React.SetStateAction<boolean>>;
    validateInputs: () => boolean;
    resetValidationFields: () => void;
    getFieldValidation: (name: string, value: string) => void;
  };
}

const TestMessageDialog = (props: ITestMessageModalProps) => {
  // custom hooks
  const commonFunctions = useCommonFunctions();

  // custom data
  const allCountriesData = commonFunctions.getAllCountriesData();

  // react hooks
  // SMS states:
  const campaign = useContext(CampaignContext);
  const [searchText, setSearchText] = useState<string>("");

  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(
    commonFunctions.findIsdCodeByCountryCode(campaign.defaultPhone) ||
      commonFunctions.findIsdCodeByCountryCode("GB")
  );
  const [phoneError, setPhoneError] = useState<IPhoneError>({
    message: "",
    display: false,
  });

  //   Email states
  const [emailError, setEmailError] = useState<IEmailError>({
    message: "",
    display: false,
  });

  // Dispatch Service states:
  const [serviceTriggered, setServiceTriggered] = useState<boolean>(false);

  //   custom handlers
  const handleClose = () => {
    props.setMessageModalState({
      ...props.messageModalState,
      visible: false,
    });
    props.setTestMessageResponse({
      icon: <></>,
      errorMessage: "",
      successMessage: "",
      loading: false,
    });
    props.setEmailValues({
      to: "",
      surname: "",
      firstName: "",
    });
    props.setSMSValues({
      surname: "",
      firstName: "",
      phoneNumber: "",
      countryPhoneCode: "",
    });
    props.testCommunicationValidation.resetValidationFields();
    setPhoneError({
      display: false,
      message: "",
    });
    props.setIsTestServiceSelected(false);
  };

  // onBlur handlers
  const onBlurHandler = (
    event: FocusEvent<HTMLInputElement>,
    messageType: string
  ) => {
    // validate email input field
    if (messageType === "Email") {
      // to:
      if (event.target.name === "to") {
        if (event.target.value?.length && !isValidEmail(event.target.value)) {
          setEmailError({ display: true, message: "Email is invalid" });
        } else {
          setEmailError({ display: false, message: "" });
        }
      }
    }
  };

  //   SMS handlers
  const handleSMSChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.testCommunicationValidation.getFieldValidation(
      event.target.name,
      event.target.value
    );
    if (event.target.name === "phoneNumber") {
      const phoneNumber = removeSpecialCharacters(event.target.value);
      props.setSMSValues({
        ...props.smsValues,
        [event.target.name]: phoneNumber,
      });
    } else {
      props.setSMSValues({
        ...props.smsValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  //   Email handlers
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.testCommunicationValidation.getFieldValidation(
      event.target.name,
      event.target.value
    );
    props.setEmailValues({
      ...props.emailValues,
      [event.target.name]: event.target.value,
    });
  };

  // send message data to parent
  const sendMessageDataHandler = (messageType: string) => {
    props.testCommunicationValidation.validateInputs();
    setServiceTriggered(true);
    switch (messageType) {
      case "Email":
        props.dispatchTestMessageHandler(props.emailValues, messageType);
        setServiceTriggered(false);
        break;
      case "Message":
        if (!phoneError.display) {
          props.dispatchTestMessageHandler(
            {
              ...props.smsValues,
              phoneNumber: `${selectedCountryCode} ${props.smsValues.phoneNumber}`,
            },
            messageType
          );
        }
        setServiceTriggered(false);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Dialog open={props.messageModalState.visible} onClose={handleClose}>
        <DialogTitle>Test {props.messageModalState.messageType}</DialogTitle>
        <DialogContent>
          {props.messageModalState.messageType === "Message" && (
            <SMSForm
              smsValues={props.smsValues}
              searchText={searchText}
              phoneError={phoneError}
              setSMSValues={props.setSMSValues}
              setSearchText={setSearchText}
              setPhoneError={setPhoneError}
              handleSMSChange={handleSMSChange}
              allCountriesData={allCountriesData}
              getTestValidation={props.getTestValidation}
              selectedCountryCode={selectedCountryCode}
              setSelectedCountryCode={setSelectedCountryCode}
              testMessageResponse={props.testMessageResponse}
            />
          )}
          {props.messageModalState.messageType === "Email" && (
            <EmailForm
              emailError={emailError}
              emailValues={props.emailValues}
              onBlurHandler={onBlurHandler}
              handleEmailChange={handleEmailChange}
              getTestValidation={props.getTestValidation}
              testMessageResponse={props.testMessageResponse}
            />
          )}
          {/* TODO: add dispatch status once api is linked */}
        </DialogContent>
        <DialogActions>
          <Box sx={[{ display: "grid", justifyItems: "end" }]}>
            <div>
              {phoneError.display ||
              (serviceTriggered &&
                !(
                  props.getTestValidation("surname").isValid &&
                  props.getTestValidation("firstName").isValid &&
                  props.getTestValidation("phoneNumber").isValid
                )) ? (
                <Box sx={[{ color: "#d32f2f" }]}>
                  <Typography variant="caption">
                    Errors highlighted in form - please resolve before you
                    proceed.
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Button onClick={handleClose}>Close</Button>
              <Button
                onClick={() =>
                  sendMessageDataHandler(props.messageModalState.messageType)
                }
                className="gg-button"
              >
                {props.testMessageResponse.loading === true ? (
                  <Oval
                    ariaLabel="loading-indicator"
                    height={20}
                    width={20}
                    strokeWidth={5}
                    color="white"
                    secondaryColor="white"
                  />
                ) : (
                  <>
                    <span>Send</span>
                  </>
                )}
              </Button>
            </div>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TestMessageDialog;
