import React, { useState, useEffect, useContext } from 'react'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent, Button } from "@mui/material"
import _ from "lodash"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IBidderDetail from '../../../interfaces/bidderDetail'
import useExcel from '../../../hooks/useExcel'
import { CampaignContext } from '../../../context/CampaignContext'
import { API_PATHS, BID_API_PATHS, getFilteredBidWithDataQueryString } from '../../../common/ApiPaths'
import IBidWithData from '../../../interfaces/bidWithData'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import { useErrorBoundary } from 'react-error-boundary'

const BidderReport = ({ match }) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [bidderList, setBidderList] = useState<IBidderDetail[]>([])
    const [columnToSort, setColumnToSort] = useState("name");
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const campaign = useContext(CampaignContext);

    const bidsApi = useFetch(API_PATHS.BIDS);
    const excel = useExcel();

    const commonFunctions = useCommonFunctions()
    const { showBoundary } = useErrorBoundary();

    const getBids = async (): Promise<void> => {
        setLoading(true)
        try {
            let bids: IBidWithData[] = await bidsApi.get(`${BID_API_PATHS.GET_BIDS_WITH_USER_AND_LOT}${getFilteredBidWithDataQueryString(campaign._id)}`)
            let allBidderDetails: IBidderDetail[] = []
            for (let bid of bids) {
                let bidderDetail: IBidderDetail = {
                    name: bid.campaignUser.firstName + ' ' + bid.campaignUser.surname,
                    bidderId: bid.campaignUser._id!,
                    phone: bid.phone,
                    campaignUser: bid.campaignUser
                }

                allBidderDetails.push(bidderDetail)
            }

            // Filter to keep only those elements which are the first occurrence of the phone/email combo (in other words, whose index is the same as the index of the first occurrence)
            let uniqueBidderDetails = allBidderDetails.filter((value, index, self) => index === self.findIndex(
                value2 => (value.phone + value.bidderId) === (value2.phone + value2.bidderId)
            ));

            setBidderList(uniqueBidderDetails)
            setLoading(false)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const invertDirection = (currentDirection: string) => {
        if (currentDirection === "asc") {
            return "desc"
        } else if (currentDirection === "desc") {
            return "asc"
        }
    }

    const handleSort = (columnName) => {
        setColumnToSort(columnName)
        let sortDirect: any = columnToSort === columnName ? invertDirection(sortDirection) : 'desc'
        setSortDirection(sortDirect)
    }


    useEffect(() => {
        setBidderList(_.orderBy(bidderList, columnToSort, sortDirection))

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [columnToSort, sortDirection])

    useEffect(() => {
        getBids();
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if (loading) {
        return (
            <Loading />
        )
    }

    // if (error) {
    //     return (
    //         <p>{error}</p>
    //     )
    // }


    return (
        <div className="bidder-report-page">
            {bidderList.length > 0 ?
                <>
                    <h2>Bidders: {bidderList.length}</h2>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div onClick={() => handleSort("name")} className="table-header-cell sortable">
                                                <span>Bidder Name</span>
                                                {
                                                    columnToSort === "name" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div onClick={() => handleSort("bidderId")} className="table-header-cell sortable">
                                                <span>Email</span>
                                                {
                                                    columnToSort === "bidderId" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div onClick={() => handleSort("phone")} className="table-header-cell sortable">
                                                <span>Phone Number</span>
                                                {
                                                    columnToSort === "phone" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bidderList.map((bidder: IBidderDetail, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{bidder.campaignUser ? `${commonFunctions.titleCase(`${bidder.campaignUser.firstName} ${bidder.campaignUser.surname}`)}` : commonFunctions.titleCase(bidder.name)}</TableCell>
                                            <TableCell>{bidder.campaignUser ? bidder.campaignUser.email : bidder.bidderId}</TableCell>
                                            <TableCell>{bidder.phone}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card >
                </>
                : <Card><CardContent><div className="no-entries">No bidders found</div></CardContent></Card>
            }

            {bidderList.length > 0 &&
                <div className="fixed-action-section">
                    <div></div>
                    <Button onClick={() => excel.generateBidderReport(bidderList)} className="gg-button">
                        Download Bidder Report
                    </Button>
                </div>
            }
        </div >
    )
}

export default BidderReport