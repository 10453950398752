import { MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material"
import { Editor } from '@tiptap/react';
import { useEffect, useState } from "react";

const FontSizeComboBox = ({ editor, activeFontSize }: { editor: Editor | null, activeFontSize: string }) => {
    const [fontSize, setFontSize] = useState<string>("14");

    const handleChange = (event: SelectChangeEvent) => {
        setFontSize(event.target.value);
    };

    useEffect(() => {
        if (activeFontSize) {
            setFontSize(activeFontSize)
        }
    }, [activeFontSize]);


    return (
        <Tooltip title="Font Size" enterDelay={600}>
            <Select
                id="select-heading"
                value={fontSize}
                onChange={handleChange}
            >
                <MenuItem value={"8"} style={{ fontSize: 8 }} onClick={() => editor!.chain().focus().setFontSize('8').run()}>8px</MenuItem>
                <MenuItem value={"12"} style={{ fontSize: 12 }} onClick={() => editor!.chain().focus().setFontSize('12').run()}>12px</MenuItem>
                <MenuItem value={"12"} style={{ fontSize: 13 }} onClick={() => editor!.chain().focus().setFontSize('13').run()}>13px</MenuItem>
                <MenuItem value={"14"} style={{ fontSize: 14 }} onClick={() => editor!.chain().focus().setFontSize('14').run()}>14px</MenuItem>
                <MenuItem value={"16"} style={{ fontSize: 16 }} onClick={() => editor!.chain().focus().setFontSize('16').run()}>16px</MenuItem>
                <MenuItem value={"18"} style={{ fontSize: 18 }} onClick={() => editor!.chain().focus().setFontSize('18').run()}>18px</MenuItem>
                <MenuItem value={"24"} style={{ fontSize: 24 }} onClick={() => editor!.chain().focus().setFontSize('24').run()}>24px</MenuItem>
                <MenuItem value={"32"} style={{ fontSize: 32 }} onClick={() => editor!.chain().focus().setFontSize('32').run()}>32px</MenuItem>
                <MenuItem value={"48"} style={{ fontSize: 48 }} onClick={() => editor!.chain().focus().setFontSize('48').run()}>48px</MenuItem>

            </Select>
        </Tooltip>
    )
}

export default FontSizeComboBox