import { useState } from "react"
import ICatalogueItem from "../../../interfaces/catalogueItem"
import IValidationObject from "../../../interfaces/validationObject"

const useCatalogueItemValidation = (catalogueItem: ICatalogueItem) => {
	const [validationObject, setValidationObject] = useState<IValidationObject[]>([
		{ name: "title", isValid: true, validationMessage: "" },
	])
	const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

	const clearValidation = () => {
		setValidationObject([
			{ name: "title", isValid: true, validationMessage: "" },
		])
	}

	const validateInputs = (): boolean => {
		let tempValidationObject: IValidationObject[] = [
			{ name: "title", isValid: true, validationMessage: "" },
		]

		let tempIsValidationPassed = true

		const setFailedValidation = (name, message) => {
			tempIsValidationPassed = false
			tempValidationObject.forEach(element => {
				if (element.name === name) {
					element.isValid = false
					element.validationMessage = message
				}
			})
		}

		if (catalogueItem.title === "" || typeof catalogueItem.title === "undefined") {
			setFailedValidation("title", "Please enter the title of the catalogue item.")
		}

		setValidationObject(tempValidationObject)
		setIsValidationPassed(tempIsValidationPassed)
		return tempIsValidationPassed
	}

	const getValidation = (name): IValidationObject => {
		let returnValue: IValidationObject = {} as IValidationObject
		validationObject.forEach(element => {
			if (name === element.name) {
				returnValue = element
			}
		})
		return returnValue
	}

	return {
		validationObject,
		clearValidation,
		setValidationObject,
		isValidationPassed,
		setIsValidationPassed,
		validateInputs,
		getValidation,
	}
}

export default useCatalogueItemValidation
